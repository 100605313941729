<template>
  <h1 class="component-heading">Label</h1>
  <!-- <p class="component-description">
    The object of the Label class is a component for placing text in a
    container. It is used to display a single line of read only text. The text
    can be changed by a programmer but a user cannot edit it directly.
  </p> -->
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    <!-- Set <b>class</b> to view label in specific theme. <br /> -->
  </p>
  <div class="component-example">
    <div class="example-body">
      <section class="label-wrapper-primary">
        <hlx-button-group
          :values="groupValues"
          value-prop="nameEn"
          display-prop="labelEn"
          default="mobile"
          tab-view
          @change="handleCurrentChange"
        />
      </section>
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span id="label-icon" class="icon" @click="showCode('label')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="label" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="example_code"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python']
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    <!-- Set <b>class</b> to view label in specific theme. <br /> -->
  </p>
  <div class="component-example">
    <div class="example-body">
      <section class="label-wrapper-primary">
        <hlx-button-group
          :values="groupValues"
          value-prop="nameEn"
          display-prop="labelEn"
          default="Tablet"
          @change="handleCurrentChange"
        />
      </section>
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span id="label-icon" class="icon" @click="showCode('label')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="label" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="example_code"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python']
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Label attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in labelAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in labelAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Label slots</h2>
  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in labelSlots"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in slot_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in labelSlots"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
</template>

<script>
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxButtonGroup from "../components/ButtonGroupComponent.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
export default {
  name: "ButtonGroupView",
  components: {
    CodeEditor,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxButtonGroup
  },
  data() {
    return {
      groupValues:[
            {
              nameEn: 'laptop',
              nameFr: '*laptop',
              labelEn: 'Laptop',
              labelFr: '*Laptop'
            },
            {
              nameEn: 'pc',
              nameFr: '*pc',
              labelEn: 'PC',
              labelFr: '*PC'
            },
            {
              nameEn: 'mobile',
              nameFr: '*mobile',
              labelEn: 'Mobile',
              labelFr: '*Mobile'
            },
            {
              nameEn: 'Tablet',
              nameFr: '*Tablet',
              labelEn: 'Tablet',
              labelFr: '*Tablet'
            }
          ],
      editor_theme: "light",
      // table_data: [
      //   {
      //     name: "class",
      //     description: "provide different dark themes and lite theme",
      //     type: "String",
      //     accepted_values:
      //       "primary,success,info,error,warning,link,disabled,primary sm,success sm,info sm,error sm,warning sm,link sm,disabled sm,primary lg,success lg,info lg,error lg,warning lg,link lg,disabled lg",
      //     default: "-",
      //     mandatory: true
      //   },
      //   {
      //     name: "options",
      //     description:
      //       "provide the custom theme for label backaground, border and inner text",
      //     type: "Object",
      //     accepted_values: "{}",
      //     default: "-",
      //     mandatory: false
      //   }
      // ],
      labelAttributes: [
        {
          prop: "name",
          label: "Name",
          width: 50,
          type: "string",
          format: ""
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: ""
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: ""
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 250,
          type: "string",
          format: ""
        },
        {
          prop: "default",
          label: "Default",
          width: 50,
          type: "string",
          format: ""
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 50,
          type: "string",
          format: ""
        }
      ],
      labelSlots: [
        {
          prop: "name",
          label: "Name",
          width: 50,
          type: "string",
          format: ""
        },
        {
          prop: "description",
          label: "Description",
          width: 450,
          type: "string",
          format: ""
        },
        {
          prop: "fallback",
          label: "Fallback",
          width: 50,
          type: "string",
          format: ""
        }
      ],
      // slot_data: [
      //   {
      //     name: "-",
      //     description: `Place any element inside hlx-label component. Like <hlx-label>
      // <i class="icon-human"></i>
      // Primary
      // </hlx-label>`,
      //     fallback: "-"
      //   }
      // ],
      example_code: ` 
    <template>
     <section class="label-wrapper-primary">
          <hlx-label class="primary"> Primary </hlx-label>
          <hlx-label class="success"> Success </hlx-label>
          <hlx-label class="info"> Information </hlx-label>
          <hlx-label class="error"> Error </hlx-label>
          <hlx-label class="warning"> Warning </hlx-label>
          <hlx-label class="link"> link </hlx-label>
          <hlx-label class="disabled"> Disabled </hlx-label>
        </section>
  </template>
  
  <script>
  export default {
  name:"label-wrapper"
  }
  <style lang="scss" scoped>
  .label-wrapper-primary,
  .label-wrapper-lite {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }
  </style>
  <\/script>    
       
     `,

      customLabel: ` 
    <template>
    <section class="label-wrapper-primary">
      <hlx-label class="primary" :options="{'background-color':'#54bd95','text-color':'white','border-color':'#54bd95'}"> Primary </hlx-label>
    </section>
  </template>
  
  <script>
  export default {
  name:"label-wrapper"
  }
  <style lang="scss" scoped>
  .label-wrapper-primary,
  .label-wrapper-lite {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }
  </style>
  <\/script>    
       
     `,

      example_code_size: ` 
    <template>
     <section class="label-wrapper-primary">
          <hlx-label class="primary sm"> Primary </hlx-label>
          <hlx-label class="primary"> Primary </hlx-label>
          <hlx-label class="primary lg"> Primary </hlx-label>
        </section>
  </template>
  
  <script>
  export default {
  name:"label-wrapper"
  }
  <style lang="scss" scoped>
  .label-wrapper-primary,
  .label-wrapper-lite {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
  }
  </style>
  <\/script>    
       
     `
    };
  },
  methods: {
    handleCurrentChange(changedData) {
      console.log(
        "🚀 ~ file: ButtonGroupView.vue:354 ~ handleCurrentChange ~ changedData:",
        changedData
      );
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label-wrapper-primary,
.label-wrapper-lite {
  display: flex;
  gap: 20px;
  align-items: end;
}
</style>
