const routes = [
  {
    name: 'getting_started',
    label: 'Getting started',
    child: [
      {
        name: 'home',
        label: 'Introduction',
        active: true,
      },
      {
        name: 'installation',
        label: 'Installation',
        active: false
      },
      {
        name: 'quickstart',
        label: 'Quickstart',
        active: false
      },
      {
        name: 'accessibility',
        label: 'Accessibility',
        active: false
      },
    ],
  },
  {
    name: 'advanced',
    label: 'Advanced',
    child: [
      {
        name: 'custom_theming',
        label: 'Custom theming',
        active: false
      },
    ],
  },
  {
    name: 'design_elements',
    label: 'Design Element',
    child: [
      {
        name: 'color',
        label: 'Color',
        active: false
      },
      {
        name: 'typography',
        label: 'Typography',
        active: false
      },
      // {
      //   name: 'icon',
      //   label: 'Icon',
      //   active: false
      // },
    ],
  },

  {
    name: 'basic',
    label: 'Basic',
    child: [
      {
        name: 'alert',
        label: 'Alert',
        active: false
      },
      {
        name: 'badge',
        label: 'Badge',
        active: false
      },
      {
        name: 'modal',
        label: 'Modal',
        active: false
      },
      {
        name: 'tag',
        label: 'Tag',
        active: false
      },
      {
        name: 'tooltip',
        label: 'Tooltip',
        active: false
      },
      {
        name: 'label',
        label: 'Label',
        active: false
      },
      {
        name: 'rating',
        label: 'Rating',
        active: false
      },
    ],
  },
  {
    name: 'input',
    label: 'Input',
    child: [
      {
        name: 'inputFields',
        label: 'Input',
        active: false
      },
      {
        name: 'Tagcount',
        label: 'Tagcount',
        active: false
      },
      {
        name: 'status',
        label: 'Status',
        active: false
      },
      {
        name: 'button',
        label: 'Button',
        active: false
      },
      {
        name: 'slider',
        label: 'Slider',
        active: false
      },
      {
        name: 'stepper',
        label: 'Step control',
        active: false
      },
      {
        name: 'select',
        label: 'Select',
        active: false
      },
      {
        name: 'multiselect',
        label: 'Multiselect',
        active: false
      },
      {
        name: 'cascader',
        label: 'Cascader',
        active: false
      },
      {
        name: 'treeSelect',
        label: 'Tree select',
        active: false
      },
      {
        name: 'search',
        label: 'Search',
        active: false
      },
      {
        name: 'switch',
        label: 'Switch',
        active: false
      },
      // {
      //   name: 'buttonGroup',
      //   label: 'ButtonGroup',
      //   active: false
      // },
      {
        name: 'code-editor',
        label: 'Code editor',
        active: false
      },
      {
        name: 'context-menu',
        label: 'Context menu',
        active: false
      },
    ],
  },

  {
    name: 'data',
    label: 'Data',
    child: [
      {
        name: 'accordion',
        label: 'Accordion',
        active: false
      },
      {
        name: 'cards',
        label: 'Card',
        active: false
      },
      {
        name: "email",
        label: "Email",
        active: false,
      },
      {
        name: "swatch",
        label: "Swatch",
        active: false,
      },
      {
        name: "DropdownColorPicker",
        label: "DropdownColorPicker",
        active: false,
      },
      // {
      //     name:'carousel',
      //     label:'Carousel',
      // },
      {
        name: 'imagegallery',
        label: 'Image gallery',
        active: false
      },
      {
        name: 'dataview',
        label: 'Data view',
        active: false
      },
      // {
      //     name:'table',
      //     label:'Table',
      // },
      {
        name: 'table',
        label: 'Table',
        active: false
      },
      {
        name: 'tabs',
        label: 'Tab',
        active: false
      },
      {
        name: 'tree',
        label: 'Tree',
        active: false
      },
      {
        name: 'speeddial',
        label: 'Speed dial',
        active: false
      },
      {
        name: 'Colorpalette',
        label: 'Color palette',
        active: false
      },
      {
        name: 'Texteditor',
        label: 'Text editor',
        active: false
      },
      // {
      //     name:'Visualrulebuilder',
      //     label:'Visual rule builder',
      // }
      {
        name: 'object-viewer',
        label: 'Object viewer',
      },
    ],
  },
  {
    name: 'navigation',
    label: 'Navigation',
    child: [
      {
        name: 'timeline',
        label: 'Timeline',
        active: false
      },
      {
        name: 'breadcrumb',
        label: 'Breadcrumb',
        active: false
      },
      {
        name: 'pagination',
        label: 'Pagination',
        active: false
      },
      {
        name: 'dottedPagination',
        label: 'Dotted pagination',
        active: false
      },
      {
        name: 'sideBar',
        label: 'Sidebar',
        active: false
      },
      {
        name: 'drawer',
        label: 'Drawer',
        active: false
      },
    ],
  },
  {
    name: 'loaders',
    label: 'Loaders',
    child: [
      {
        name: 'loader',
        label: 'Loader',
        active: false
      },
      {
        name: 'importProgressBar',
        label: 'Import progress bar',
        active: false
      },  
    ],
  },
  {
    name: 'layout_and_pages',
    label: 'Layout and Page',
    child: [
      {
        name: 'errorPage',
        label: 'Error page',
        active: false
      },
      {
        name: 'dashboard',
        label: 'Dashboard',
        active: false
      },
      {
        name: 'fileUpload',
        label: 'File upload',
        active: false
      },
      // {
      //   name: 'signup',
      //   label: 'Signup',
      //   active: false
      // },
    ],
  },

  {
    name: 'utilities',
    label: 'Utility',
    child: [
      {
        name: 'draggables',
        label: 'Draggable',
        active: false
      },
    ],
  },
];
export default {
  routes,
};
