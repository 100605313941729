<template>
  <h1 class="component-heading">Modal Window</h1>
  <p class="component-description">
    A modal (also called a modal window or lightbox) is a web page element that
    displays in front of and deactivates all other page content. To return to
    the main content, the user must engage with the modal by completing an
    action or by closing it.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    Modal window is made up of header, body and footer. Header and footer is
    optional, and its content distribution depends on a named slots as below:
  </p>
  <li class="component-description">#header: displays title of modal window</li>
  <li class="component-description">
    #content: displays body content of modal window
  </li>
  <li class="component-description">
    #footer: displays footer of modal window
  </li>
  <p class="component-description">
    Set <b>:modal</b> to <b>true</b> for basic modal window. <br />
    To specify a custom height<b> :height</b><br />
    To specify a custom width <b>:width</b><br />
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-button class="primary sm" @click="modal_active = true;componentkey+=1">Click</hlx-button>
      <hlx-modal :modal-active="modal_active" :height="'200px'" :width="'600px'" :modal="true" :close-icon="true"
        :outer-layer-close-option="false" @close="modal_active = false">
        <template #header>
          <div>Header</div>
        </template>
        <template #content>
          <div class="modal-content">

            <hlx-cascader
  :pre-value="preValue"
  :placeholder-value="'Category'"
  :label="'label'"
  :prop-value="'value'"
  :options="[
  {
    value: 'guide',
    label: 'Guide',
    children: [
      {
        value: 'disciplines',
        label: 'Disciplines',
        children: [
          {
            value: 'consistency',
            label: 'Consistency',
          },
          {
            value: 'feedback',
            label: 'Feedback',
          },
          {
            value: 'efficiency',
            label: 'Efficiency',
          },
          {
            value: 'controllability',
            label: 'Controllability',
          },
        ],
      },
      {
        value: 'navigation',
        label: 'Navigation',
        children: [
          {
            value: 'side nav',
            label: 'Side Navigation',
          },
          {
            value: 'top nav',
            label: 'Top Navigation',
          },
        ],
      },
    ],
  },
  {
    value: 'component',
    label: 'Component',
    children: [
      {
        value: 'basic',
        label: 'Basic',
        children: [
          {
            value: 'layout',
            label: 'Layout',
          },
          {
            value: 'color',
            label: 'Color',
          },
          {
            value: 'typography',
            label: 'Typography',
          },
          {
            value: 'icon',
            label: 'Icon',
          },
          {
            value: 'button',
            label: 'Button',
          },
        ],
      },
      {
        value: 'form',
        label: 'Form',
        children: [
          {
            value: 'radio',
            label: 'Radio',
          },
          {
            value: 'checkbox',
            label: 'Checkbox',
          },
          {
            value: 'input',
            label: 'Input',
          },
          {
            value: 'input-number',
            label: 'InputNumber',
          },
          {
            value: 'select',
            label: 'Select',
          },
          {
            value: 'cascader',
            label: 'Cascader',
          },
          {
            value: 'switch',
            label: 'Switch',
          },
          {
            value: 'slider',
            label: 'Slider',
          },
          {
            value: 'time-picker',
            label: 'TimePicker',
          },
          {
            value: 'date-picker',
            label: 'DatePicker',
          },
          {
            value: 'datetime-picker',
            label: 'DateTimePicker',
          },
          {
            value: 'upload',
            label: 'Upload',
          },
          {
            value: 'rate',
            label: 'Rate',
          },
          {
            value: 'form',
            label: 'Form',
          },
        ],
      },
      {
        value: 'data',
        label: 'Data',
        children: [
          {
            value: 'table',
            label: 'Table',
          },
          {
            value: 'tag',
            label: 'Tag',
          },
          {
            value: 'progress',
            label: 'Progress',
          },
          {
            value: 'tree',
            label: 'Tree',
          },
          {
            value: 'pagination',
            label: 'Pagination',
          },
          {
            value: 'badge',
            label: 'Badge',
          },
        ],
      },
      {
        value: 'notice',
        label: 'Notice',
        children: [
          {
            value: 'alert',
            label: 'Alert',
          },
          {
            value: 'loading',
            label: 'Loading',
          },
          {
            value: 'message',
            label: 'Message',
          },
          {
            value: 'message-box',
            label: 'MessageBox',
          },
          {
            value: 'notification',
            label: 'Notification',
          },
        ],
      },
      {
        value: 'navigation',
        label: 'Navigation',
        children: [
          {
            value: 'menu',
            label: 'Menu',
          },
          {
            value: 'tabs',
            label: 'Tabs',
          },
          {
            value: 'breadcrumb',
            label: 'Breadcrumb',
          },
          {
            value: 'dropdown',
            label: 'Dropdown',
          },
          {
            value: 'steps',
            label: 'Steps',
          },
        ],
      },
      {
        value: 'others',
        label: 'Others',
        children: [
          {
            value: 'dialog',
            label: 'Dialog',
          },
          {
            value: 'tooltip',
            label: 'Tooltip',
          },
          {
            value: 'popover',
            label: 'Popover',
          },
          {
            value: 'card',
            label: 'Card',
          },
          {
            value: 'carousel',
            label: 'Carousel',
          },
          {
            value: 'collapse',
            label: 'Collapse',
          },
        ],
      },
    ],
  },
  {
    value: 'resource',
    label: 'Resource',
    children: [
      {
        value: 'axure',
        label: 'Axure Components',
      },
      {
        value: 'sketch',
        label: 'Sketch Templates',
      },
      {
        value: 'docs',
        label: 'Design Documentation',
      },
    ],
  },
]"
  ></hlx-cascader>
          </div>
        </template>
        <template #footer>
          <hlx-button class="primary sm" @click="modal_active = false">Save</hlx-button>
        </template>
      </hlx-modal>
    </div>
    <div class="example-footer">
      <span id="basic-model-icon" class="icon" @click="showCode('basic-model')"><i class="icon-code-regular"></i></span>
    </div>
  </div>
  <div id="basic-model" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor :id="'editor'" :display_language="false" :value="basic_model" :read_only="true" :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]" />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Modal without header and footer</h2>
  <p class="component-description">
    You can also display or hide the headers and footers by setting
    <b>:contentOnly</b> attribute to true or false
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-button class="primary sm" @click="modal_active1 = true">Click</hlx-button>
      <hlx-modal :modal-active="modal_active1" :height="'300px'" :width="'800px'" :content-only="true"
        @close="modal_active1 = false">
        <template #content>
          <div class="modal-content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Distinctio voluptas aliquid enim esse id adipisci assumenda aut et
              aperiam tempore, possimus, sint debitis! Illo in hic officia
              accusamus mollitia architecto.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              dolores ipsam natus. Sequi dolorum blanditiis error deserunt
              aperiam quae, odit in praesentium autem? Atque voluptatem quis eos
              nihil explicabo accusamus?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum, dicta distinctio vitae necessitatibus ut dolore
              veritatis quaerat id ipsum repellendus nobis eligendi possimus cum
              veniam porro minima modi maiores architecto.
            </p>
          </div>
        </template>
      </hlx-modal>
    </div>
    <div class="example-footer">
      <span id="custom-model-icon" class="icon" @click="showCode('custom-model')"><i class="icon-code-regular"></i></span>
    </div>
  </div>
  <div id="custom-model" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor :id="'editor'" :display_language="false" :value="custom_model" :read_only="true" :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]" />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Modal with header and content</h2>
  <p class="component-description">
    You can also display or hide the footers by setting
    <b>:headercontent</b> attribute to true or false
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-button class="primary sm" @click="modal_active2 = true">
        Click</hlx-button>
      <hlx-modal :modal-active="modal_active2" :height="'400px'" :width="'600px'" :header-content="true"
        @close="modal_active2 = false">
        <template #header>
          <div>Header</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Distinctio voluptas aliquid enim esse id adipisci assumenda aut et
              aperiam tempore, possimus, sint debitis! Illo in hic officia
              accusamus mollitia architecto.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              dolores ipsam natus. Sequi dolorum blanditiis error deserunt
              aperiam quae, odit in praesentium autem? Atque voluptatem quis eos
              nihil explicabo accusamus?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum, dicta distinctio vitae necessitatibus ut dolore
              veritatis quaerat id ipsum repellendus nobis eligendi possimus cum
              veniam porro minima modi maiores architecto.
            </p>
            <!-- <div class="items">Hi item 1</div>
            <div class="items">Hi item 2</div>
            <div class="items">Hi item 2</div>
            <div class="items">Hi item 4</div> -->
          </div>
        </template>
        <template #footer>
          <!-- <hlx-button
            class="bg-disabled cancel"
            @click="this.modal_active = false"
            >Cancel</hlx-button
          > -->
          <hlx-button class="primary sm" @click="modal_active = false">Save</hlx-button>
        </template>
      </hlx-modal>
    </div>
    <div class="example-footer">
      <span id="without-footer-icon" class="icon" @click="showCode('without-footer')"><i
          class="icon-code-regular"></i></span>
    </div>
  </div>
  <div id="without-footer" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor :id="'editor'" :display_language="false" :value="without_footer" :read_only="true"
          :theme="editor_theme" :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]" />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Modal with content and footer</h2>
  <p class="component-description">
    You can also display or hide the headers by setting
    <b>:contentfooter</b> attribute to true or false
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-button class="primary sm" @click="modal_active3 = true">Click</hlx-button>
      <hlx-modal :modal-active="modal_active3" :content-footer="true" @close="modal_active3 = false">
        <template #header>
          <div>Header</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Distinctio voluptas aliquid enim esse id adipisci assumenda aut et
              aperiam tempore, possimus, sint debitis! Illo in hic officia
              accusamus mollitia architecto.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              dolores ipsam natus. Sequi dolorum blanditiis error deserunt
              aperiam quae, odit in praesentium autem? Atque voluptatem quis eos
              nihil explicabo accusamus?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum, dicta distinctio vitae necessitatibus ut dolore
              veritatis quaerat id ipsum repellendus nobis eligendi possimus cum
              veniam porro minima modi maiores architecto.
            </p>
          </div>
        </template>
        <template #footer>
          <!-- <hlx-button
            class="bg-disabled cancel"
            @click="this.modal_active3 = false"
            >Cancel</hlx-button
          > -->
          <hlx-button class="primary sm" @click="modal_active3 = false">Save</hlx-button>
        </template>
      </hlx-modal>
    </div>
    <div class="example-footer">
      <span id="without-header-icon" class="icon" @click="showCode('without-header')"><i
          class="icon-code-regular"></i></span>
    </div>
  </div>
  <div id="without-header" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor :id="'editor'" :display_language="false" :value="without_header" :read_only="true"
          :theme="editor_theme" :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]" />
      </div>
    </section>
  </div>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Modal attributes</h2>
  <!-- <hlx-table-component :tableData="this.data.table_data" :theader="this.data.columns" :border="true"/> -->
  <!-- <hlxTable
    :data="this.table_data"
    :border="true"
    theme="secondary"
    :rowHover="true"
    :ellipses="5"
    :bold_header="true"
  >
    <hlx-column :prop="'name'" :label="'Name'" :type="'string'" />
    <hlx-column :prop="'description'" :label="'Description'" :type="'string'" />
    <hlx-column :prop="'default'" :label="'Default'" :type="'string'" />
    <hlx-column :prop="'type'" :label="'Type'" :type="'string'" :width="200"/>
    <hlx-column :prop="'accepted_values'" :label="'Accepted values'" :type="'string'"/>
    <hlx-column :prop="'mandatory'" :label="'Mandatory'" :type="'string'" />
  </hlxTable>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Modal events</h2>
  <hlx-table
    :data="this.event_data"
    :border="true"
    theme="secondary"
    :rowHover="true"
    :bold_header="true"
  >
    <hlx-column :prop="'Name'" :label="'Name'" :type="'string'" />
    <hlx-column
      :prop="'Description'"
      :label="'Description'"
      :type="'string'"
      :width="400"
    />
    <hlx-column :prop="'Parameters'" :label="'Parameters'" :type="'string'" />
  </hlx-table> -->
  <hlx-table :column-count="6" :border="['table', 'header', 'vertical', 'horizontal']" :bold-headers="false"
    :row-hover="false" theme="grey" :striped-rows="false">
    <template #thead>
      <hlx-table-head v-for="(i, index) in alertAttributes" :key="index" :width="i.width">{{ i.label }}</hlx-table-head>
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell v-for="(j, col_index) in alertAttributes" :key="col_index" :align="'left'">
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Modal events</h2>

  <hlx-table :column-count="3" :border="['table', 'header', 'vertical', 'horizontal']" :bold-headers="false"
    :row-hover="false" theme="grey" :striped-rows="false">
    <template #thead>
      <hlx-table-head v-for="(i, index) in eventdata" :key="index" :width="i.width">{{ i.label }}</hlx-table-head>
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell v-for="(j, col_index) in eventdata" :key="col_index" :align="'left'">
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'badge')"><i
        class="icon-angle-left-regular prev-icon"></i>Badge</span>
    <span class="nxt-btn" @click="prevNextPage('next', 'tag')">Tag <i class="icon-angle-right-regular"></i></span>
  </div>
</template>

<script>
import divider from "../components/DividerComponent.vue";
import hlxModal from "../components/ModalwindowComponent.vue";
import hlxButton from "../components/ButtonComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxCascader from "../components/CascaderNewComponent.vue";

// import hlxTable from "../components/NewTable.vue";
// import hlxColumn from "../components/NewTableColumn.vue";
export default {
  name: "App",
  components: {
    hlxModal,
    divider,
    hlxButton,
    CodeEditor,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxCascader
  },
  data() {
    return {
      componentkey:1,
      dropdown_data: [
        { name: "Php", checked: false, disabled: false, value: "Php", icon: 'icon-database-regular', id: 1 },
        { name: "VueJs", checked: false, disabled: false, value: "VueJs", icon: 'icon-database-regular', id: 2 },
        { name: "ReactJs", checked: false, disabled: false, value: "ReactJs", icon: 'icon-database-regular', id: 3 },
        { name: "Html", checked: false, disabled: false, value: "Html", icon: 'icon-database-regular', id: 4 },
        { name: "Css", checked: false, disabled: false, value: "Css", icon: 'icon-database-regular', id: 5 },
        { name: "Scss", checked: false, disabled: false, value: "Scss", icon: 'icon-database-regular', id: 6 },
        { name: "Sass", checked: false, disabled: false, value: "Sass", icon: 'icon-database-regular', id: 7 },
        { name: "Sql", checked: false, disabled: false, value: "Sql", icon: 'icon-database-regular', id: 8 },
        { name: "MongoDB", checked: false, disabled: false, value: "MongoDB", icon: 'icon-database-regular', id: 9 },
        { name: "Javascript", checked: false, disabled: false, value: "Javascriptq", icon: 'icon-database-regular', id: 10 },
      ],
      editor_theme: "light",
      modal_active: false,
      modal_active1: false,
      modal_active2: false,
      modal_active3: false,
      alertAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      event_data: [
        {
          Name: "close",
          Description:
            "Triggers when the close button is clicked or when clicked outside the modal window",
          Parameters: "-",
        },
      ],
      eventdata: [
        {
          prop: "Name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "Description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "Parameters",
          label: "Parameters",
          width: 150,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          name: "modal",
          description:
            "boolean value describes whether to show header,content and footer",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "content-only",
          description: "boolean value describes whether to show only content",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "header-content",
          description:
            "boolean value describes whether to show header and content",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "content-footer",
          description:
            "boolean value describes whether to show footer and content",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "modal-active",
          description:
            "Define a boolean variable in data section to open and close the modal window",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: "true",
        },
        {
          name: "width",
          description: "displays the width of the model",
          type: "Integer",
          accepted_values: "160px",
          default: "400px",
          mandatory: "false",
        },
        {
          name: "height",
          description: "displays the height of the model",
          type: "Integer",
          accepted_values: "160px",
          default: "600px",
          mandatory: "false",
        },
        {
          name: "close-icon",
          description: "displays the close icon of the model",
          type: "Boolean",
          accepted_values: "true/false",
          default: "true",
          mandatory: "false",
        },
      ],

      without_header: `
      <template>
              <hlx-modal
        :modalActive="modal_active3"
        @close="this.modal_active3 = false"
        :contentfooter="true"
      >
        <template v-slot:header>
          <div>Header</div>
        </template>
        <template v-slot:content>
          <div class="modal-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio voluptas aliquid enim esse id adipisci assumenda aut et aperiam tempore, possimus, sint debitis! Illo in hic officia accusamus mollitia architecto.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam dolores ipsam natus. Sequi dolorum blanditiis error deserunt aperiam quae, odit in praesentium autem? Atque voluptatem quis eos nihil explicabo accusamus?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, dicta distinctio vitae necessitatibus ut dolore veritatis quaerat id ipsum repellendus nobis eligendi possimus cum veniam porro minima modi maiores architecto.</p>
          </div>
        </template>
        <template v-slot:footer>
          <hlx-button class="primary sm" @click="this.modal_active3 = false"
            >Save</hlx-button
          >
        </template>
      </hlx-modal>
       </template>

        <script>
        export default {
          name: "App",
          data() {
            return {
              modal_active3: false,
              }
            }
        `,
      without_footer: `
      <template>
              <hlx-modal
        :modalActive="modal_active2"
        @close="this.modal_active2 = false"
        :height="'400px'"
        :width="'600px'"
        :headercontent="true"
      >
        <template v-slot:header>
          <div>Header</div>
        </template>
        <template v-slot:content>
          <div class="modal-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio voluptas aliquid enim esse id adipisci assumenda aut et aperiam tempore, possimus, sint debitis! Illo in hic officia accusamus mollitia architecto.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam dolores ipsam natus. Sequi dolorum blanditiis error deserunt aperiam quae, odit in praesentium autem? Atque voluptatem quis eos nihil explicabo accusamus?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, dicta distinctio vitae necessitatibus ut dolore veritatis quaerat id ipsum repellendus nobis eligendi possimus cum veniam porro minima modi maiores architecto.</p>
            <!-- <div class="items">Hi item 1</div>
            <div class="items">Hi item 2</div>
            <div class="items">Hi item 2</div>
            <div class="items">Hi item 4</div> -->
          </div>
        </template>
        <template v-slot:footer>
          <hlx-button class="primary sm" @click="this.modal_active = false"
            >Save</hlx-button
          >
        </template>
      </hlx-modal>
      </template>
             <script>
          export default {
            name: "App",
            data() {
              return {
                modal_active2: false,
                }
              }
        `,
      basic_model: `<template>     
       <hlx-modal
        :modalActive="modal_active"
        @close="this.modal_active = false"
        :height="'400px'"
        :width="'600px'"
        :modal="true"
      >
        <template v-slot:header>
          <div>Header</div>
        </template>
        <template v-slot:content>
          <div class="modal-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio voluptas aliquid enim esse id adipisci assumenda aut et aperiam tempore, possimus, sint debitis! Illo in hic officia accusamus mollitia architecto.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam dolores ipsam natus. Sequi dolorum blanditiis error deserunt aperiam quae, odit in praesentium autem? Atque voluptatem quis eos nihil explicabo accusamus?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, dicta distinctio vitae necessitatibus ut dolore veritatis quaerat id ipsum repellendus nobis eligendi possimus cum veniam porro minima modi maiores architecto.</p>
          </div>
        </template>
        <template v-slot:footer>
          <hlx-button class="primary sm" @click="this.modal_active = false"
            >Save</hlx-button
          >
        </template>
      </hlx-modal>
      </template>
      
      <script>
      export default {
        name: "App",
        data() {
          return {
            modal_active: false,
            }
          }`,
      custom_model: `
      <hlx-modal
        :modalActive="modal_active1"
        @close="this.modal_active1 = false"
        :height="'300px'"
        :width="'800px'"
        :contentOnly="true"
      >
        <template v-slot:content>
          <div class="modal-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio voluptas aliquid enim esse id adipisci assumenda aut et aperiam tempore, possimus, sint debitis! Illo in hic officia accusamus mollitia architecto.</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam dolores ipsam natus. Sequi dolorum blanditiis error deserunt aperiam quae, odit in praesentium autem? Atque voluptatem quis eos nihil explicabo accusamus?</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, dicta distinctio vitae necessitatibus ut dolore veritatis quaerat id ipsum repellendus nobis eligendi possimus cum veniam porro minima modi maiores architecto.</p>
          </div>
        </template>
      </hlx-modal>
       <script>
export default {
  name: "App",
  data() {
    return {
      modal_active1: false,
      }
    }}`,
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val)) {

        if (document.getElementById(val).style.display === "none") {
          document.getElementById(val).style.display = "block";
        } else if (document.getElementById(val).style.display === "block") {
          document.getElementById(val).style.display = "none";
        }
      }
    },
    enableModalWindow() {
      this.enable_modal_window = true;
    },
    close() {
      this.modal_active = false;
    },
    cancelModal() {
      this.enable_modal_window = false;
    },
    confirmModal() {
      this.modal_active = false;
    },
    disableModalWindow() {
      this.enable_modal_window = false;
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import '../assets/styles/variables.scss';
.modal-content{
display: flex;
flex-direction: column;

  .head{
    font-family: QuickSand;
    font-size: 24px;
    margin-top: auto;
  }
  .items{
    margin-bottom: auto;
  }
}
.modal-primary-button{
                cursor: pointer;
                box-sizing: border-box;
                height: 30px;
                min-width: 55px;
                padding: 0px 20px;
                background: #f9ac19;
                color: white;
                border: none;
                border-radius: 6px;
                margin-left: 15px;
                font-size: 14px;
                 display: flex;
                  align-items: center;

                }

                .modal-secondary-button{
                    cursor: pointer;
                    box-sizing: border-box;
                    height: 30px;
                    min-width: 55px;
                    padding: 0px 20px;
                    background: #fcfcfc;
                    border: 1px solid #f97a19;
                    color: #f9ac19;
                    border-radius: 6px;
                font-size: 14px;
                  display: flex;
                  align-items: center;

                }
</style> -->
<style lang="scss" scoped>
.cancel {
  margin-right: 10px;
  height: 32px;
  width: 14%;
}

// .save {
//   height: 32px;
//   width: 14%;
// }
</style>
