const icons = {
  iconsOld: [
    'code',
    'hamburger-dots',
    'circle-filled-tick',
    'circle-filled-cross',
    'circle-filled-plus',
    'circle-filled-minus',
    'circle-minus',
    'add',
    'add-file',
    'add-folder',
    'add-security',
    'android-menu',
    'arrow-filled-left',
    'arrow-filled-right',
    'arrow-line-left',
    'arrow-line-up',
    'arrow-line-down',
    'arrow-line-right',
    'arrow-regular-down',
    'arrow-regular-left',
    'arrow-regular-right',
    'arrow-regular-up',
    'arrow-small-left',
    'arrow-small-right',
    'badge',
    'bin',
    'building',
    'bulb',
    'calendar',
    'center-align',
    'circle-cross',
    'circle-plus',
    'clip',
    'clock',
    'cloud',
    'cloud-cancel',
    'cloud-download',
    'cloud-share',
    'cloud-upload',
    'copy',
    'cross',
    'cross-file',
    'cross-small',
    'directory',
    'dollar',
    'download',
    'eye',
    'eye-closed',
    'file',
    'file2',
    'file-badge',
    'file-search',
    'file-sign',
    'arrow-filled-down',
    'arrow-filled-up',
    'filter',
    'fingerprint',
    'folder',
    'font',
    'full-screen',
    'gear',
    'hamburger-menu',
    'heart',
    'home',
    'horizontal-dots',
    'human',
    'human-background',
    'human-cross',
    'human-group',
    'human-minus',
    'human-plus',
    'human-timer',
    'info-circle',
    'info-small',
    'justify-align',
    'kebab-menu',
    'left-align',
    'link',
    'location',
    'lock',
    'lock-open',
    'logout',
    'message',
    'minus-small',
    'not-allowed',
    'pen',
    'plus-small',
    'price-tag',
    'question-circle',
    'reload',
    'reset',
    'right-align',
    'save',
    'search',
    'search-small',
    'setting',
    'share',
    'star',
    'tag-cross',
    'tick',
    'tick-file',
    'tick-small',
    'upload',
    'vertical-dots',
    'warning-circle',
    'www',
    'zoom-in',
    'zoom-out',
    'filled-question',
    'filled-exclamatory',
    'filled-info',
    'search-glow',
    'bell',
    'rocket',
    'dollar2',
    'dollar3',
    'Email',
    'gear-tool',
    'phone',
    'plug',
    'target-arrow',
    'filled-bin',
    'highlighter',
    'undo',
    'redo',
    'file-upload'
  ],
  iconsNew: [
    'activity-circle-filled',
    'activity-heart-circle-filled',
    'activity-heart-square-filled',
    'activity-notification-circle-filled',
    'activity-notification-square-filled',
    'activity-square-filled',
    'activity-star-circle-filled',
    'activity-star-square-filled',
    'address-book-filled',
    'alarm-check-alt-filled',
    'alarm-check-filled',
    'alarm-clock-alt-filled',
    'alarm-clock-empty-filled',
    'alarm-clock-filled',
    'alarm-edit-filled',
    'alarm-minus-alt-filled',
    'alarm-minus-filled',
    'alarm-plus-alt-filled',
    'alarm-plus-filled',
    'alarm-times-alt-filled',
    'alarm-times-filled',
    'amazon-pay-filled',
    'android-filled',
    'angle-down-circle-filled',
    'angle-down-filled',
    'angle-down-small-filled',
    'angle-down-square-filled',
    'angle-left-circle-filled',
    'angle-left-filled',
    'angle-left-small-filled',
    'angle-left-square-filled',
    'angle-right-circle-filled',
    'angle-right-filled',
    'angle-right-small-filled',
    'angle-right-square-filled',
    'angles-down-filled',
    'angles-down-small-filled',
    'angles-down-up-filled',
    'angles-left-filled',
    'angles-left-right-filled',
    'angles-left-small-filled',
    'angles-right-filled',
    'angles-right-small-filled',
    'angles-up-filled',
    'angles-up-small-filled',
    'angle-up-circle-filled',
    'angle-up-filled',
    'angle-up-small-filled',
    'angle-up-square-filled',
    'apple-filled',
    'apple-pay-filled',
    'app-store-filled',
    'archive-filled',
    'archway-filled',
    'arrow-back-alt-filled',
    'arrow-back-circle-alt-filled',
    'arrow-back-circle-filled',
    'arrow-back-filled',
    'arrow-back-square-alt-filled',
    'arrow-back-square-filled',
    'arrow-down-arrow-up-circle-filled',
    'arrow-down-arrow-up-filled',
    'arrow-down-arrow-up-square-filled',
    'arrow-down-circle-filled',
    'arrow-down-filled',
    'arrow-down-from-line-filled',
    'arrow-down-left-circle-filled',
    'arrow-down-left-filled',
    'arrow-down-left-small-filled',
    'arrow-down-left-square-filled',
    'arrow-down-right-circle-filled',
    'arrow-down-right-filled',
    'arrow-down-right-small-filled',
    'arrow-down-right-square-filled',
    'arrow-down-small-filled',
    'arrow-down-square-filled',
    'arrow-down-to-line-filled',
    'arrow-export-filled',
    'arrow-forward-alt-filled',
    'arrow-forward-circle-alt-filled',
    'arrow-forward-circle-filled',
    'arrow-forward-filled',
    'arrow-forward-square-alt-filled',
    'arrow-forward-square-filled',
    'arrow-import-filled',
    'arrow-left-arrow-right-circle-filled',
    'arrow-left-arrow-right-filled',
    'arrow-left-arrow-right-square-filled',
    'arrow-left-circle-filled',
    'arrow-left-filled',
    'arrow-left-from-line-filled',
    'arrow-left-small-filled',
    'arrow-left-square-filled',
    'arrow-left-to-line-filled',
    'arrow-reply-filled',
    'arrow-right-circle-filled',
    'arrow-right-filled',
    'arrow-right-from-line-filled',
    'arrow-right-small-filled',
    'arrow-right-square-filled',
    'arrow-right-to-line-filled',
    'arrows-compress-filled',
    'arrows-expand-filled',
    'arrow-share-filled',
    'arrows-left-right-filled',
    'arrows-move-filled',
    'arrows-repeat-circle-filled',
    'arrows-repeat-filled',
    'arrows-repeat-square-filled',
    'arrows-up-down-filled',
    'arrow-turn-down-left-filled',
    'arrow-turn-down-right-filled',
    'arrow-turn-left-down-filled',
    'arrow-turn-left-up-filled',
    'arrow-turn-right-down-filled',
    'arrow-turn-right-up-filled',
    'arrow-turn-up-left-filled',
    'arrow-turn-up-right-filled',
    'arrow-up-circle-filled',
    'arrow-up-filled',
    'arrow-up-from-line-filled',
    'arrow-up-left-circle-filled',
    'arrow-up-left-filled',
    'arrow-up-left-small-filled',
    'arrow-up-left-square-filled',
    'arrow-up-right-circle-filled',
    'arrow-up-right-filled',
    'arrow-up-right-small-filled',
    'arrow-up-right-square-filled',
    'arrow-up-small-filled',
    'arrow-up-square-filled',
    'arrow-up-to-line-filled',
    'at-filled',
    'badge-check-filled',
    'badge-discount-alt-filled',
    'badge-discount-filled',
    'badge-dollar-filled',
    'badge-filled',
    'badge-like-filled',
    'ban-filled',
    'bank-filled',
    'battery-bolt-filled',
    'battery-empty-filled',
    'battery-full-filled',
    'battery-half-filled',
    'bed-filled',
    'bed-front-filled',
    'behance-filled',
    'bell-exclamation-filled',
    'bell-filled',
    'bell-minus-filled',
    'bell-notification-filled',
    'bell-on-filled',
    'bell-plus-filled',
    'bell-slash-filled',
    'bell-times-filled',
    'bezier-filled',
    'bitcoin-circle-filled',
    'bitcoin-filled',
    'bitcoin-square-filled',
    'blogger-filled',
    'bluetooth-filled',
    'bolt-alt-filled',
    'bolt-circle-alt-filled',
    'bolt-circle-filled',
    'bolt-filled',
    'bolt-slash-alt-filled',
    'bolt-slash-filled',
    'bolt-square-alt-filled',
    'bolt-square-filled',
    'box-check-filled',
    'boxes-filled',
    'box-filled',
    'box-minus-filled',
    'box-plus-filled',
    'box-search-filled',
    'box-times-filled',
    'briefcase-check-filled',
    'briefcase-filled',
    'briefcase-minus-filled',
    'briefcase-money-filled',
    'briefcase-plus-filled',
    'briefcase-times-filled',
    'brush-alt-filled',
    'brush-filled',
    'bug-filled',
    'bug-slash-filled',
    'building-coins-filled',
    'building-filled',
    'buildings-alt-filled',
    'buildings-filled',
    'building-tree-filled',
    'bus-filled',
    'cabinet-filing-filled',
    'calculator-filled',
    'calculator-simple-filled',
    'calendar-check-alt-filled',
    'calendar-check-filled',
    'calendar-clock-filled',
    'calendar-edit-filled',
    'calendar-empty-alt-filled',
    'calendar-empty-filled',
    'calendar-filled',
    'calendar-heart-filled',
    'calendar-lock-filled',
    'calendar-minus-alt-filled',
    'calendar-minus-filled',
    'calendar-plus-alt-filled',
    'calendar-plus-filled',
    'calendar-search-filled',
    'calendar-star-filled',
    'calendar-times-alt-filled',
    'calendar-times-filled',
    'camera-circle-filled',
    'camera-filled',
    'camera-rotate-filled',
    'camera-slash-filled',
    'camera-square-filled',
    'caret-down-circle-filled',
    'caret-down-filled',
    'caret-down-square-filled',
    'caret-left-circle-filled',
    'caret-left-filled',
    'caret-left-square-filled',
    'caret-right-circle-filled',
    'caret-right-filled',
    'caret-right-square-filled',
    'caret-up-circle-filled',
    'caret-up-filled',
    'caret-up-square-filled',
    'castle-filled',
    'chart-bar-alt-filled',
    'chart-bar-circle-filled',
    'chart-bar-filled',
    'chart-bar-horizontal-filled',
    'chart-bar-square-filled',
    'chart-bar-vertical-filled',
    'chart-line-dots-filled',
    'chart-line-down-filled',
    'chart-line-filled',
    'chart-line-up-filled',
    'chart-pie-filled',
    'chart-pyramid-filled',
    'chart-trend-down-filled',
    'chart-trend-up-filled',
    'chat-activity-filled',
    'chat-check-alt-filled',
    'chat-check-filled',
    'chat-clock-filled',
    'chat-code-filled',
    'chat-dots-check-filled',
    'chat-dots-filled',
    'chat-dots-minus-filled',
    'chat-dots-notification-filled',
    'chat-dots-plus-filled',
    'chat-dots-times-filled',
    'chat-edit-filled',
    'chat-exclamation-filled',
    'chat-filled',
    'chat-heart-filled',
    'chat-info-filled',
    'chat-lock-filled',
    'chat-minus-alt-filled',
    'chat-minus-filled',
    'chat-notification-filled',
    'chat-plus-alt-filled',
    'chat-plus-filled',
    'chat-question-filled',
    'chats-dots-filled',
    'chat-search-filled',
    'chat-settings-filled',
    'chats-filled',
    'chat-shield-filled',
    'chat-star-filled',
    'chats-text-filled',
    'chat-text-check-filled',
    'chat-text-filled',
    'chat-text-minus-filled',
    'chat-text-notification-filled',
    'chat-text-plus-filled',
    'chat-text-times-filled',
    'chat-times-alt-filled',
    'chat-times-filled',
    'checkbox-square-fill-filled',
    'check-circle-filled',
    'check-double-filled',
    'check-filled',
    'check-square-filled',
    'clipboard-check-filled',
    'clipboard-edit-filled',
    'clipboard-filled',
    'clipboard-heart-filled',
    'clipboard-list-check-filled',
    'clipboard-list-filled',
    'clipboard-minus-filled',
    'clipboard-plus-filled',
    'clipboard-search-filled',
    'clipboard-star-filled',
    'clipboard-text-filled',
    'clipboard-times-filled',
    'clock-alt-filled',
    'clock-filled',
    'clock-square-alt-filled',
    'clock-square-filled',
    'cloud-check-filled',
    'cloud-connection-filled',
    'cloud-download-filled',
    'cloud-filled',
    'cloud-lock-filled',
    'cloud-minus-filled',
    'cloud-plus-filled',
    'cloud-share-filled',
    'cloud-shield-filled',
    'cloud-slash-filled',
    'cloud-times-filled',
    'cloud-upload-filled',
    'code-circle-filled',
    'code-filled',
    'code-square-filled',
    'coin-card-transfer-filled',
    'coin-convert-filled',
    'coin-filled',
    'coins-filled',
    'comment-activity-filled',
    'comment-check-alt-filled',
    'comment-check-filled',
    'comment-clock-filled',
    'comment-code-filled',
    'comment-dots-check-filled',
    'comment-dots-filled',
    'comment-dots-minus-filled',
    'comment-dots-notification-filled',
    'comment-dots-plus-filled',
    'comment-dots-times-filled',
    'comment-edit-filled',
    'comment-exclamation-filled',
    'comment-filled',
    'comment-heart-filled',
    'comment-info-filled',
    'comment-lock-filled',
    'comment-minus-alt-filled',
    'comment-minus-filled',
    'comment-notification-filled',
    'comment-plus-alt-filled',
    'comment-plus-filled',
    'comment-question-filled',
    'comments-dots-filled',
    'comment-search-filled',
    'comment-settings-filled',
    'comments-filled',
    'comment-shield-filled',
    'comment-star-filled',
    'comments-text-filled',
    'comment-text-check-filled',
    'comment-text-filled',
    'comment-text-minus-filled',
    'comment-text-notification-filled',
    'comment-text-plus-filled',
    'comment-text-times-filled',
    'comment-times-alt-filled',
    'comment-times-filled',
    'compass-filled',
    'compass-needle-filled',
    'contrast-filled',
    'copy-alt-filled',
    'copy-check-filled',
    'copy-filled',
    'copy-plus-filled',
    'copyright-circle-filled',
    'copyright-square-filled',
    'coupon-filled',
    'coupon-heart-filled',
    'coupon-percent-filled',
    'coupon-star-filled',
    'cpu-filled',
    'credit-card-ban-filled',
    'credit-card-change-filled',
    'credit-card-check-filled',
    'credit-card-convert-filled',
    'credit-card-edit-filled',
    'credit-card-filled',
    'credit-card-minus-filled',
    'credit-card-plus-filled',
    'credit-card-slash-filled',
    'credit-card-times-filled',
    'crown-alt-filled',
    'crown-filled',
    'cursor-click-filled',
    'cursor-filled',
    'database-filled',
    'delete-left-filled',
    'delete-right-filled',
    'delivery-cart-arrow-down-filled',
    'delivery-cart-arrow-up-filled',
    'delivery-cart-filled',
    'desktop-code-filled',
    'desktop-filled',
    'desktop-mobile-filled',
    'deviantart-filled',
    'diamond-exclamation-filled',
    'diamond-filled',
    'disc-filled',
    'discord-filled',
    'dislike-circle-filled',
    'dislike-filled',
    'dislike-square-filled',
    'dislike-tag-filled',
    'document-filled',
    'document-list-check-filled',
    'document-list-filled',
    'dollar-circle-filled',
    'dollar-filled',
    'dollar-receive-circle-filled',
    'dollar-receive-square-filled',
    'dollar-send-circle-filled',
    'dollar-send-square-filled',
    'dollar-square-filled',
    'door-closed-filled',
    'door-open-filled',
    'download-alt-filled',
    'download-filled',
    'drag-horizontal-filled',
    'drag-lines-horizontal-filled',
    'drag-lines-vertical-filled',
    'drag-vertical-filled',
    'dribbble-filled',
    'droplet-filled',
    'droplet-slash-filled',
    'edit-filled',
    'envelope-ban-filled',
    'envelope-check-filled',
    'envelope-clock-filled',
    'envelope-edit-filled',
    'envelope-eye-filled',
    'envelope-filled',
    'envelope-heart-filled',
    'envelope-lock-filled',
    'envelope-minus-filled',
    'envelope-notification-filled',
    'envelope-open-filled',
    'envelope-plus-filled',
    'envelope-search-filled',
    'envelope-settings-filled',
    'envelopes-filled',
    'envelope-shield-filled',
    'envelope-star-filled',
    'envelope-times-filled',
    'ethereum-circle-filled',
    'ethereum-filled',
    'ethereum-square-filled',
    'euro-circle-filled',
    'euro-filled',
    'euro-square-filled',
    'exchange-horizontal-circle-filled',
    'exchange-horizontal-filled',
    'exchange-horizontal-square-filled',
    'exchange-vertical-circle-filled',
    'exchange-vertical-filled',
    'exchange-vertical-square-filled',
    'exclamation-circle-filled',
    'exclamation-square-filled',
    'eye-closed-filled',
    'eye-filled',
    'eye-slash-filled',
    'facebook-f-filled',
    'facebook-filled',
    'facebook-messenger-filled',
    'figma-filled',
    'file-ban-filled',
    'file-check-filled',
    'file-clock-filled',
    'file-code-filled',
    'file-copy-filled',
    'file-download-filled',
    'file-edit-filled',
    'file-export-alt-filled',
    'file-export-filled',
    'file-filled',
    'file-heart-filled',
    'file-import-alt-filled',
    'file-import-filled',
    'file-list-ban-filled',
    'file-list-check-filled',
    'file-list-edit-filled',
    'file-list-filled',
    'file-list-heart-filled',
    'file-list-lock-filled',
    'file-list-minus-filled',
    'file-list-plus-filled',
    'file-list-search-filled',
    'file-list-shield-filled',
    'file-list-star-filled',
    'file-list-times-filled',
    'file-lock-filled',
    'file-minus-filled',
    'file-music-filled',
    'file-plus-filled',
    'file-search-filled',
    'file-settings-filled',
    'file-shield-filled',
    'file-star-filled',
    'file-text-ban-filled',
    'file-text-check-filled',
    'file-text-edit-filled',
    'file-text-filled',
    'file-text-heart-filled',
    'file-text-lock-filled',
    'file-text-minus-filled',
    'file-text-plus-filled',
    'file-text-search-filled',
    'file-text-shield-filled',
    'file-text-star-filled',
    'file-text-times-filled',
    'file-times-filled',
    'file-upload-filled',
    'file-user-filled',
    'filter-check-filled',
    'filter-filled',
    'filter-minus-filled',
    'filter-plus-filled',
    'filter-times-filled',
    'fingerprint-filled',
    'fingerprint-viewfinder-filled',
    'fire-filled',
    'flag-filled',
    'flag-triangle-filled',
    'flashlight-filled',
    'floppy-disk-filled',
    'folder-alt-filled',
    'folder-ban-filled',
    'folder-check-filled',
    'folder-clock-filled',
    'folder-code-filled',
    'folder-connection-filled',
    'folder-download-filled',
    'folder-edit-filled',
    'folder-export-filled',
    'folder-filled',
    'folder-heart-filled',
    'folder-import-filled',
    'folder-lock-filled',
    'folder-minus-filled',
    'folder-music-filled',
    'folder-open-filled',
    'folder-plus-filled',
    'folder-search-filled',
    'folder-settings-filled',
    'folder-shield-filled',
    'folder-star-filled',
    'folder-times-filled',
    'folder-upload-filled',
    'folder-user-filled',
    'game-controller-filled',
    'gift-filled',
    'github-filled',
    'globe-earth-filled',
    'globe-filled',
    'google-filled',
    'google-play-filled',
    'grid-1-filled',
    'grid-2-filled',
    'grid-circle-filled',
    'grid-dividers-filled',
    'grid-square-circle-filled',
    'grid-square-filled',
    'grid-square-plus-filled',
    'grid-web-1-filled',
    'grid-web-2-filled',
    'grid-web-3-filled',
    'grid-web-4-filled',
    'grid-web-5-filled',
    'grid-web-6-filled',
    'grid-web-7-filled',
    'hand-holding-dollar-circle-filled',
    'hand-holding-dollar-filled',
    'hand-holding-user-filled',
    'hard-drive-filled',
    'hashtag-filled',
    'headphones-filled',
    'heart-check-filled',
    'heart-circle-filled',
    'heart-filled',
    'heart-minus-filled',
    'heart-plus-filled',
    'heart-slash-filled',
    'heart-square-filled',
    'heart-tag-filled',
    'heart-times-filled',
    'home-activity-filled',
    'home-dash-activity-filled',
    'home-dash-dollar-filled',
    'home-dash-filled',
    'home-dash-heart-filled',
    'home-dash-lock-filled',
    'home-dash-search-filled',
    'home-dash-shield-filled',
    'home-dash-star-filled',
    'home-dash-trend-down-filled',
    'home-dash-trend-up-filled',
    'home-dash-user-filled',
    'home-dash-wifi-filled',
    'home-dollar-filled',
    'home-filled',
    'home-heart-filled',
    'home-lock-filled',
    'home-roof-activity-filled',
    'home-roof-dollar-filled',
    'home-roof-filled',
    'home-roof-heart-filled',
    'home-roof-lock-filled',
    'home-roof-search-filled',
    'home-roof-shield-filled',
    'home-roof-star-filled',
    'home-roof-trend-down-filled',
    'home-roof-trend-up-filled',
    'home-roof-user-filled',
    'home-roof-wifi-filled',
    'home-search-filled',
    'home-shield-filled',
    'home-star-filled',
    'home-trend-down-filled',
    'home-trend-up-filled',
    'home-user-filled',
    'home-wifi-filled',
    'hospital-filled',
    'hourglass-filled',
    'id-badge-filled',
    'id-card-filled',
    'inbox-download-filled',
    'inbox-empty-filled',
    'inbox-filled',
    'inbox-upload-filled',
    'info-circle-filled',
    'info-square-filled',
    'instagram-filled',
    'invoice-filled',
    'kaaba-filled',
    'keyboard-filled',
    'key-circle-filled',
    'key-filled',
    'keyhole-circle-filled',
    'keyhole-square-filled',
    'keypad-filled',
    'key-square-filled',
    'lamp-desk-filled',
    'laptop-code-filled',
    'laptop-filled',
    'layer-group-filled',
    'layout-web-1-filled',
    'layout-web-2-filled',
    'layout-web-3-filled',
    'layout-web-4-filled',
    'layout-web-5-filled',
    'layout-web-6-filled',
    'layout-web-7-filled',
    'layout-web-8-filled',
    'layout-web-9-filled',
    'layout-web-10-filled',
    'layout-web-11-filled',
    'layout-web-12-filled',
    'leaf-filled',
    'life-ring-filled',
    'lightbulb-filled',
    'light-emergency-filled',
    'light-emergency-on-filled',
    'like-circle-filled',
    'like-filled',
    'like-square-filled',
    'like-tag-filled',
    'line-chart-dots-circle-filled',
    'line-chart-dots-filled',
    'line-chart-dots-square-filled',
    'line-down-circle-filled',
    'line-down-square-filled',
    'line-up-circle-filled',
    'line-up-square-filled',
    'link-alt-filled',
    'link-broken-filled',
    'link-broken-horizontal-filled',
    'linkedin-filled',
    'link-filled',
    'link-horizontal-alt-filled',
    'link-horizontal-filled',
    'list-filled',
    'location-arrow-circle-filled',
    'location-arrow-filled',
    'location-arrow-square-filled',
    'location-crosshairs-filled',
    'location-crosshairs-slash-filled',
    'location-pin-alt-filled',
    'location-pin-check-filled',
    'location-pin-edit-filled',
    'location-pin-exclamation-filled',
    'location-pin-eye-filled',
    'location-pin-filled',
    'location-pin-heart-filled',
    'location-pin-lock-filled',
    'location-pin-minus-filled',
    'location-pin-plus-filled',
    'location-pin-question-filled',
    'location-pin-search-filled',
    'location-pin-slash-alt-filled',
    'location-pin-slash-filled',
    'location-pin-star-filled',
    'location-pin-times-filled',
    'lock-filled',
    'lock-viewfinder-filled',
    'log-in-filled',
    'log-out-filled',
    'magicoon-filled',
    'magnet-filled',
    'map-filled',
    'map-location-filled',
    'map-pin-filled',
    'mastercard-filled',
    'maximize-filled',
    'medal-check-filled',
    'medal-filled',
    'medal-star-filled',
    'medium-filled',
    'megaphone-filled',
    'menu-alt-filled',
    'menu-circle-alt-filled',
    'menu-circle-filled',
    'menu-duo-circle-filled',
    'menu-duo-filled',
    'menu-duo-square-filled',
    'menu-filled',
    'menu-left-alt-filled',
    'menu-left-circle-alt-filled',
    'menu-left-circle-filled',
    'menu-left-filled',
    'menu-left-square-alt-filled',
    'menu-left-square-filled',
    'menu-right-alt-filled',
    'menu-right-circle-alt-filled',
    'menu-right-circle-filled',
    'menu-right-filled',
    'menu-right-square-alt-filled',
    'menu-right-square-filled',
    'menu-square-alt-filled',
    'menu-square-filled',
    'message-activity-filled',
    'message-check-alt-filled',
    'message-check-filled',
    'message-clock-filled',
    'message-code-filled',
    'message-dots-check-filled',
    'message-dots-filled',
    'message-dots-minus-filled',
    'message-dots-notification-filled',
    'message-dots-plus-filled',
    'message-dots-times-filled',
    'message-edit-filled',
    'message-exclamation-filled',
    'message-filled',
    'message-heart-filled',
    'message-info-filled',
    'message-lock-filled',
    'message-minus-alt-filled',
    'message-minus-filled',
    'message-notification-filled',
    'message-plus-alt-filled',
    'message-plus-filled',
    'message-question-filled',
    'messages-dots-filled',
    'message-search-filled',
    'message-settings-filled',
    'messages-filled',
    'message-shield-filled',
    'message-star-filled',
    'messages-text-filled',
    'message-text-check-filled',
    'message-text-filled',
    'message-text-minus-filled',
    'message-text-notification-filled',
    'message-text-plus-filled',
    'message-text-times-filled',
    'message-times-alt-filled',
    'message-times-filled',
    'meta-filled',
    'microphone-filled',
    'microphone-slash-filled',
    'microphone-stand-filled',
    'microsoft-filled',
    'minimize-filled',
    'minus-circle-filled',
    'minus-filled',
    'minus-square-filled',
    'mobile-filled',
    'money-bill-ban-filled',
    'money-bill-check-filled',
    'money-bill-clock-filled',
    'money-bill-convert-filled',
    'money-bill-filled',
    'money-bill-minus-filled',
    'money-bill-plus-filled',
    'money-bill-receive-filled',
    'money-bill-send-filled',
    'money-bills-filled',
    'money-bill-times-filled',
    'money-convert-filled',
    'money-withdrawal-filled',
    'moon-filled',
    'more-horizontal-circle-filled',
    'more-horizontal-filled',
    'more-horizontal-square-filled',
    'more-vertical-circle-filled',
    'more-vertical-filled',
    'more-vertical-square-filled',
    'mosque-filled',
    'mouse-filled',
    'newspaper-filled',
    'notebook-filled',
    'note-filled',
    'note-list-check-filled',
    'note-list-check-square-filled',
    'note-list-filled',
    'note-list-square-filled',
    'note-text-filled',
    'note-text-square-filled',
    'nut-filled',
    'octagon-check-filled',
    'octagon-exclamation-filled',
    'octagon-filled',
    'octagon-minus-filled',
    'octagon-plus-filled',
    'octagon-times-filled',
    'package-check-filled',
    'package-dollar-filled',
    'package-filled',
    'package-heart-filled',
    'package-star-filled',
    'palette-filled',
    'pants-filled',
    'paperclip-filled',
    'papers-filled',
    'papers-text-filled',
    'parking-circle-filled',
    'parking-square-filled',
    'paypal-card-filled',
    'paypal-filled',
    'pen-tool-filled',
    'percent-circle-filled',
    'percent-filled',
    'percent-square-filled',
    'percent-tag-filled',
    'phone-1-filled',
    'phone-2-filled',
    'phone-call-filled',
    'phone-filled',
    'phone-flip-filled',
    'phone-hangup-filled',
    'phone-incoming-filled',
    'phone-missed-filled',
    'phone-office-filled',
    'phone-outgoing-filled',
    'phone-plus-filled',
    'phone-slash-filled',
    'phone-times-filled',
    'pinterest-filled',
    'pinterest-p-filled',
    'plug-filled',
    'plus-circle-filled',
    'plus-filled',
    'plus-square-filled',
    'poll-horizontal-circle-filled',
    'poll-horizontal-square-filled',
    'poll-vertical-circle-filled',
    'poll-vertical-square-filled',
    'power-off-circle-filled',
    'power-off-filled',
    'power-off-square-filled',
    'presentation-chart-line-filled',
    'presentation-chart-pie-filled',
    'presentation-dollar-filled',
    'presentation-filled',
    'presentation-lightbulb-filled',
    'presentation-pen-filled',
    'presentation-play-filled',
    'presentation-poll-filled',
    'presentation-text-filled',
    'presentation-trend-down-filled',
    'presentation-trend-up-filled',
    'presentation-user-filled',
    'print-filled',
    'print-slash-filled',
    'puzzle-filled',
    'question-circle-filled',
    'question-square-filled',
    'quote-filled',
    'radar-filled',
    'radio-button-fill-filled',
    'radio-filled',
    'ranking-filled',
    'receipt-alt-filled',
    'receipt-filled',
    'receipt-heart-alt-filled',
    'receipt-heart-filled',
    'receipt-list-alt-filled',
    'receipt-list-filled',
    'receipt-percent-alt-filled',
    'receipt-percent-filled',
    'receipt-star-alt-filled',
    'receipt-star-filled',
    'receipt-text-alt-filled',
    'receipt-text-filled',
    'reddit-filled',
    'registered-circle-filled',
    'registered-square-filled',
    'road-filled',
    'rocket-filled',
    'rocket-launch-filled',
    'rotate-circle-filled',
    'rotate-filled',
    'rotate-left-circle-filled',
    'rotate-left-filled',
    'rotate-left-square-filled',
    'rotate-right-circle-filled',
    'rotate-right-filled',
    'rotate-right-square-filled',
    'rotate-square-filled',
    'route-filled',
    'sack-dollar-filled',
    'scan-circle-filled',
    'scan-filled',
    'scanner-filled',
    'scan-qr-filled',
    'school-filled',
    'scissors-filled',
    'sd-card-filled',
    'search-filled',
    'search-minus-filled',
    'search-plus-filled',
    'search-text-filled',
    'send-filled',
    'send-right-filled',
    'server-filled',
    'settings-filled',
    'share-filled',
    'shield-bolt-filled',
    'shield-check-filled',
    'shield-exclamation-filled',
    'shield-filled',
    'shield-heart-filled',
    'shield-lock-filled',
    'shield-minus-filled',
    'shield-play-filled',
    'shield-plus-filled',
    'shield-question-filled',
    'shield-search-filled',
    'shield-slash-filled',
    'shield-star-filled',
    'shield-times-filled',
    'shield-user-filled',
    'shirt-filled',
    'shoes-filled',
    'shopping-bag-alt-filled',
    'shopping-bag-filled',
    'shopping-basket-arrow-down-filled',
    'shopping-basket-arrow-up-filled',
    'shopping-basket-check-filled',
    'shopping-basket-filled',
    'shopping-basket-heart-filled',
    'shopping-basket-minus-filled',
    'shopping-basket-plus-filled',
    'shopping-basket-star-filled',
    'shopping-basket-times-filled',
    'shopping-cart-arrow-down-filled',
    'shopping-cart-arrow-top-filled',
    'shopping-cart-check-filled',
    'shopping-cart-filled',
    'shopping-cart-heart-filled',
    'shopping-cart-minus-filled',
    'shopping-cart-plus-filled',
    'shopping-cart-star-filled',
    'shopping-cart-times-filled',
    'sign-direction-left-filled',
    'sign-direction-right-filled',
    'signs-direction-filled',
    'sim-card-1-filled',
    'sim-card-2-filled',
    'sim-card-filled',
    'skype-filled',
    'sliders-horizontal-alt-filled',
    'sliders-horizontal-filled',
    'sliders-vertical-alt-filled',
    'sliders-vertical-filled',
    'snapchat-filled',
    'sofa-filled',
    'sort-ascending-circle-filled',
    'sort-ascending-filled',
    'sort-ascending-square-filled',
    'sort-descending-circle-filled',
    'sort-descending-filled',
    'sort-descending-square-filled',
    'sort-filled',
    'sort-horizontal-filled',
    'sort-vertical-filled',
    'soundcloud-filled',
    'sparkle-filled',
    'speaker-filled',
    'speedometer-filled',
    'spotify-filled',
    'star-circle-filled',
    'star-filled',
    'star-half-filled',
    'star-magic-filled',
    'star-shooting-filled',
    'star-shooting-horizontal-filled',
    'star-slash-filled',
    'star-square-filled',
    'star-tag-filled',
    'sticky-note-circle-filled',
    'sticky-note-square-filled',
    'sticky-note-text-circle-filled',
    'sticky-note-text-square-filled',
    'stopwatch-alt-filled',
    'stopwatch-check-alt-filled',
    'stopwatch-check-filled',
    'stopwatch-edit-filled',
    'stopwatch-empty-filled',
    'stopwatch-filled',
    'stopwatch-minus-alt-filled',
    'stopwatch-minus-filled',
    'stopwatch-plus-alt-filled',
    'stopwatch-plus-filled',
    'stopwatch-times-alt-filled',
    'stopwatch-times-filled',
    'store-filled',
    'story-filled',
    'stripe-filled',
    'sun-filled',
    'tablet-filled',
    'tag-filled',
    'tags-filled',
    'target-filled',
    'taxi-filled',
    'telegram-filled',
    'telescope-filled',
    'thumbtack-filled',
    'thumbtack-vertical-filled',
    'tiktok-filled',
    'time-forward-filled',
    'time-past-filled',
    'times-circle-filled',
    'times-filled',
    'times-square-filled',
    'toggle-off-alt-filled',
    'toggle-off-filled',
    'toggle-off-rectangle-filled',
    'toggle-on-alt-filled',
    'toggle-on-filled',
    'toggle-on-rectangle-filled',
    'tool-filled',
    'traffic-cone-filled',
    'trash-check-filled',
    'trash-filled',
    'trash-minus-filled',
    'trash-plus-filled',
    'trash-redo-filled',
    'trash-slash-filled',
    'trash-times-filled',
    'trash-undo-filled',
    'tree-filled',
    'trend-down-circle-filled',
    'trend-down-filled',
    'trend-down-square-filled',
    'trend-up-circle-filled',
    'trend-up-filled',
    'trend-up-square-filled',
    'triangle-exclamation-filled',
    'triangle-filled',
    'trophy-filled',
    'trophy-star-filled',
    'truck-filled',
    'tumbler-filled',
    'tv-filled',
    'tv-play-filled',
    'tv-retro-filled',
    'twitch-filled',
    'twitter-filled',
    'university-filled',
    'unlock-filled',
    'upload-alt-filled',
    'upload-filled',
    'usb-drive-filled',
    'user-alt-filled',
    'user-ban-filled',
    'user-change-filled',
    'user-check-alt-filled',
    'user-check-bottom-filled',
    'user-check-filled',
    'user-circle-filled',
    'user-clock-filled',
    'user-code-filled',
    'user-dollar-filled',
    'user-edit-filled',
    'user-eye-filled',
    'user-filled',
    'user-heart-filled',
    'user-lock-filled',
    'user-minus-alt-filled',
    'user-minus-bottom-filled',
    'user-minus-filled',
    'user-plus-alt-filled',
    'user-plus-bottom-filled',
    'user-plus-filled',
    'users-alt-filled',
    'user-search-filled',
    'user-settings-filled',
    'users-filled',
    'users-group-alt-filled',
    'users-group-filled',
    'users-group-slash-filled',
    'user-shield-filled',
    'user-slash-filled',
    'user-square-filled',
    'users-slash-filled',
    'user-star-filled',
    'user-tag-filled',
    'user-times-alt-filled',
    'user-times-bottom-filled',
    'user-times-filled',
    'user-viewfinder-filled',
    'vector-filled',
    'video-circle-filled',
    'video-eye-filled',
    'video-filled',
    'video-incoming-filled',
    'video-outgoing-filled',
    'video-plus-filled',
    'video-slash-filled',
    'video-square-filled',
    'vimeo-filled',
    'visa-filled',
    'vk-filled',
    'voicemail-filled',
    'wallet-filled',
    'warehouse-filled',
    'watch-circle-filled',
    'watch-square-filled',
    'wechat-filled',
    'weight-scale-filled',
    'whatsapp-filled',
    'wifi-filled',
    'wifi-slash-filled',
    'wordpress-filled',
    'xbox-filled',
    'youtube-filled',
    'activity-circle-regular',
    'activity-heart-circle-regular',
    'activity-heart-square-regular',
    'activity-notification-circle-regular',
    'activity-notification-square-regular',
    'activity-square-regular',
    'activity-star-circle-regular',
    'activity-star-square-regular',
    'address-book-regular',
    'alarm-check-alt-regular',
    'alarm-check-regular',
    'alarm-clock-alt-regular',
    'alarm-clock-empty-regular',
    'alarm-clock-regular',
    'alarm-edit-regular',
    'alarm-minus-alt-regular',
    'alarm-minus-regular',
    'alarm-plus-alt-regular',
    'alarm-plus-regular',
    'alarm-times-alt-regular',
    'alarm-times-regular',
    'amazon-pay-regular',
    'android-regular',
    'angle-down-circle-regular',
    'angle-down-regular',
    'angle-down-small-regular',
    'angle-down-square-regular',
    'angle-left-circle-regular',
    'angle-left-regular',
    'angle-left-small-regular',
    'angle-left-square-regular',
    'angle-right-circle-regular',
    'angle-right-regular',
    'angle-right-small-regular',
    'angle-right-square-regular',
    'angles-down-regular',
    'angles-down-small-regular',
    'angles-down-up-regular',
    'angles-left-regular',
    'angles-left-right-regular',
    'angles-left-small-regular',
    'angles-right-regular',
    'angles-right-small-regular',
    'angles-up-regular',
    'angles-up-small-regular',
    'angle-up-circle-regular',
    'angle-up-regular',
    'angle-up-small-regular',
    'angle-up-square-regular',
    'apple-pay-regular',
    'apple-regular',
    'app-store-regular',
    'archive-regular',
    'archway-regular',
    'arrow-back-alt-regular',
    'arrow-back-circle-alt-regular',
    'arrow-back-circle-regular',
    'arrow-back-regular',
    'arrow-back-square-alt-regular',
    'arrow-back-square-regular',
    'arrow-down-arrow-up-circle-regular',
    'arrow-down-arrow-up-regular',
    'arrow-down-arrow-up-square-regular',
    'arrow-down-circle-regular',
    'arrow-down-from-line-regular',
    'arrow-down-left-circle-regular',
    'arrow-down-left-regular',
    'arrow-down-left-small-regular',
    'arrow-down-left-square-regular',
    'arrow-down-regular',
    'arrow-down-right-circle-regular',
    'arrow-down-right-regular',
    'arrow-down-right-small-regular',
    'arrow-down-right-square-regular',
    'arrow-down-small-regular',
    'arrow-down-square-regular',
    'arrow-down-to-line-regular',
    'arrow-export-regular',
    'arrow-forward-alt-regular',
    'arrow-forward-circle-alt-regular',
    'arrow-forward-circle-regular',
    'arrow-forward-regular',
    'arrow-forward-square-alt-regular',
    'arrow-forward-square-regular',
    'arrow-import-regular',
    'arrow-left-arrow-right-circle-regular',
    'arrow-left-arrow-right-regular',
    'arrow-left-arrow-right-square-regular',
    'arrow-left-circle-regular',
    'arrow-left-from-line-regular',
    'arrow-left-regular',
    'arrow-left-small-regular',
    'arrow-left-square-regular',
    'arrow-left-to-line-regular',
    'arrow-reply-regular',
    'arrow-right-circle-regular',
    'arrow-right-from-line-regular',
    'arrow-right-regular',
    'arrow-right-small-regular',
    'arrow-right-square-regular',
    'arrow-right-to-line-regular',
    'arrows-compress-regular',
    'arrows-expand-regular',
    'arrow-share-regular',
    'arrows-left-right-regular',
    'arrows-move-regular',
    'arrows-repeat-circle-regular',
    'arrows-repeat-regular',
    'arrows-repeat-square-regular',
    'arrows-up-down-regular',
    'arrow-turn-down-left-regular',
    'arrow-turn-down-right-regular',
    'arrow-turn-left-down-regular',
    'arrow-turn-left-up-regular',
    'arrow-turn-right-down-regular',
    'arrow-turn-right-up-regular',
    'arrow-turn-up-left-regular',
    'arrow-turn-up-right-regular',
    'arrow-up-circle-regular',
    'arrow-up-from-line-regular',
    'arrow-up-left-circle-regular',
    'arrow-up-left-regular',
    'arrow-up-left-small-regular',
    'arrow-up-left-square-regular',
    'arrow-up-regular',
    'arrow-up-right-circle-regular',
    'arrow-up-right-regular',
    'arrow-up-right-small-regular',
    'arrow-up-right-square-regular',
    'arrow-up-small-regular',
    'arrow-up-square-regular',
    'arrow-up-to-line-regular',
    'at-regular',
    'badge-check-regular',
    'badge-discount-alt-regular',
    'badge-discount-regular',
    'badge-dollar-regular',
    'badge-like-regular',
    'badge-regular',
    'bank-regular',
    'ban-regular',
    'battery-bolt-regular',
    'battery-empty-regular',
    'battery-full-regular',
    'battery-half-regular',
    'bed-front-regular',
    'bed-regular',
    'behance-regular',
    'bell-exclamation-regular',
    'bell-minus-regular',
    'bell-notification-regular',
    'bell-on-regular',
    'bell-plus-regular',
    'bell-regular',
    'bell-slash-regular',
    'bell-times-regular',
    'bezier-regular',
    'bitcoin-circle-regular',
    'bitcoin-regular',
    'bitcoin-square-regular',
    'blogger-regular',
    'bluetooth-regular',
    'bolt-alt-regular',
    'bolt-circle-alt-regular',
    'bolt-circle-regular',
    'bolt-regular',
    'bolt-slash-alt-regular',
    'bolt-slash-regular',
    'bolt-square-alt-regular',
    'bolt-square-regular',
    'box-check-regular',
    'boxes-regular',
    'box-minus-regular',
    'box-plus-regular',
    'box-regular',
    'box-search-regular',
    'box-times-regular',
    'briefcase-check-regular',
    'briefcase-minus-regular',
    'briefcase-money-regular',
    'briefcase-plus-regular',
    'briefcase-regular',
    'briefcase-times-regular',
    'brush-alt-regular',
    'brush-regular',
    'bug-regular',
    'bug-slash-regular',
    'building-coins-regular',
    'building-regular',
    'buildings-alt-regular',
    'buildings-regular',
    'building-tree-regular',
    'bus-regular',
    'cabinet-filing-regular',
    'calculator-regular',
    'calculator-simple-regular',
    'calendar-check-alt-regular',
    'calendar-check-regular',
    'calendar-clock-regular',
    'calendar-edit-regular',
    'calendar-empty-alt-regular',
    'calendar-empty-regular',
    'calendar-heart-regular',
    'calendar-lock-regular',
    'calendar-minus-alt-regular',
    'calendar-minus-regular',
    'calendar-plus-alt-regular',
    'calendar-plus-regular',
    'calendar-regular',
    'calendar-search-regular',
    'calendar-star-regular',
    'calendar-times-alt-regular',
    'calendar-times-regular',
    'camera-circle-regular',
    'camera-regular',
    'camera-rotate-regular',
    'camera-slash-regular',
    'camera-square-regular',
    'caret-down-circle-regular',
    'caret-down-regular',
    'caret-down-square-regular',
    'caret-left-circle-regular',
    'caret-left-regular',
    'caret-left-square-regular',
    'caret-right-circle-regular',
    'caret-right-regular',
    'caret-right-square-regular',
    'caret-up-circle-regular',
    'caret-up-regular',
    'caret-up-square-regular',
    'castle-regular',
    'chart-bar-alt-regular',
    'chart-bar-circle-regular',
    'chart-bar-horizontal-regular',
    'chart-bar-regular',
    'chart-bar-square-regular',
    'chart-bar-vertical-regular',
    'chart-line-dots-regular',
    'chart-line-down-regular',
    'chart-line-regular',
    'chart-line-up-regular',
    'chart-pie-regular',
    'chart-pyramid-regular',
    'chart-trend-down-regular',
    'chart-trend-up-regular',
    'chat-activity-regular',
    'chat-check-alt-regular',
    'chat-check-regular',
    'chat-clock-regular',
    'chat-code-regular',
    'chat-dots-check-regular',
    'chat-dots-minus-regular',
    'chat-dots-notification-regular',
    'chat-dots-plus-regular',
    'chat-dots-regular',
    'chat-dots-times-regular',
    'chat-edit-regular',
    'chat-exclamation-regular',
    'chat-heart-regular',
    'chat-info-regular',
    'chat-lock-regular',
    'chat-minus-alt-regular',
    'chat-minus-regular',
    'chat-notification-regular',
    'chat-plus-alt-regular',
    'chat-plus-regular',
    'chat-question-regular',
    'chat-regular',
    'chats-dots-regular',
    'chat-search-regular',
    'chat-settings-regular',
    'chat-shield-regular',
    'chats-regular',
    'chat-star-regular',
    'chats-text-regular',
    'chat-text-check-regular',
    'chat-text-minus-regular',
    'chat-text-notification-regular',
    'chat-text-plus-regular',
    'chat-text-regular',
    'chat-text-times-regular',
    'chat-times-alt-regular',
    'chat-times-regular',
    'checkbox-square-fill-regular',
    'check-circle-regular',
    'check-double-regular',
    'check-regular',
    'check-square-regular',
    'clipboard-check-regular',
    'clipboard-edit-regular',
    'clipboard-heart-regular',
    'clipboard-list-check-regular',
    'clipboard-list-regular',
    'clipboard-minus-regular',
    'clipboard-plus-regular',
    'clipboard-regular',
    'clipboard-search-regular',
    'clipboard-star-regular',
    'clipboard-text-regular',
    'clipboard-times-regular',
    'clock-alt-regular',
    'clock-regular',
    'clock-square-alt-regular',
    'clock-square-regular',
    'cloud-check-regular',
    'cloud-connection-regular',
    'cloud-download-regular',
    'cloud-lock-regular',
    'cloud-minus-regular',
    'cloud-plus-regular',
    'cloud-regular',
    'cloud-share-regular',
    'cloud-shield-regular',
    'cloud-slash-regular',
    'cloud-times-regular',
    'cloud-upload-regular',
    'code-circle-regular',
    'code-regular',
    'code-square-regular',
    'coin-card-transfer-regular',
    'coin-convert-regular',
    'coin-regular',
    'coins-regular',
    'comment-activity-regular',
    'comment-check-alt-regular',
    'comment-check-regular',
    'comment-clock-regular',
    'comment-code-regular',
    'comment-dots-check-regular',
    'comment-dots-minus-regular',
    'comment-dots-notification-regular',
    'comment-dots-plus-regular',
    'comment-dots-regular',
    'comment-dots-times-regular',
    'comment-edit-regular',
    'comment-exclamation-regular',
    'comment-heart-regular',
    'comment-info-regular',
    'comment-lock-regular',
    'comment-minus-alt-regular',
    'comment-minus-regular',
    'comment-notification-regular',
    'comment-plus-alt-regular',
    'comment-plus-regular',
    'comment-question-regular',
    'comment-regular',
    'comments-dots-regular',
    'comment-search-regular',
    'comment-settings-regular',
    'comment-shield-regular',
    'comments-regular',
    'comment-star-regular',
    'comments-text-regular',
    'comment-text-check-regular',
    'comment-text-minus-regular',
    'comment-text-notification-regular',
    'comment-text-plus-regular',
    'comment-text-regular',
    'comment-text-times-regular',
    'comment-times-alt-regular',
    'comment-times-regular',
    'compass-needle-regular',
    'compass-regular',
    'contrast-regular',
    'copy-alt-regular',
    'copy-check-regular',
    'copy-plus-regular',
    'copy-regular',
    'copyright-circle-regular',
    'copyright-square-regular',
    'coupon-heart-regular',
    'coupon-percent-regular',
    'coupon-regular',
    'coupon-star-regular',
    'cpu-regular',
    'credit-card-ban-regular',
    'credit-card-change-regular',
    'credit-card-check-regular',
    'credit-card-convert-regular',
    'credit-card-edit-regular',
    'credit-card-minus-regular',
    'credit-card-plus-regular',
    'credit-card-regular',
    'credit-card-slash-regular',
    'credit-card-times-regular',
    'crown-alt-regular',
    'crown-regular',
    'cursor-click-regular',
    'cursor-regular',
    'database-regular',
    'delete-left-regular',
    'delete-right-regular',
    'delivery-cart-arrow-down-regular',
    'delivery-cart-arrow-up-regular',
    'delivery-cart-regular',
    'desktop-code-regular',
    'desktop-mobile-regular',
    'desktop-regular',
    'deviantart-regular',
    'diamond-exclamation-regular',
    'diamond-regular',
    'discord-regular',
    'disc-regular',
    'dislike-circle-regular',
    'dislike-regular',
    'dislike-square-regular',
    'dislike-tag-regular',
    'document-list-check-regular',
    'document-list-regular',
    'document-regular',
    'dollar-circle-regular',
    'dollar-receive-circle-regular',
    'dollar-receive-square-regular',
    'dollar-regular',
    'dollar-send-circle-regular',
    'dollar-send-square-regular',
    'dollar-square-regular',
    'door-closed-regular',
    'door-open-regular',
    'download-alt-regular',
    'download-regular',
    'drag-horizontal-regular',
    'drag-lines-horizontal-regular',
    'drag-lines-vertical-regular',
    'drag-vertical-regular',
    'dribbble-regular',
    'droplet-regular',
    'droplet-slash-regular',
    'edit-regular',
    'envelope-ban-regular',
    'envelope-check-regular',
    'envelope-clock-regular',
    'envelope-edit-regular',
    'envelope-eye-regular',
    'envelope-heart-regular',
    'envelope-lock-regular',
    'envelope-minus-regular',
    'envelope-notification-regular',
    'envelope-open-regular',
    'envelope-plus-regular',
    'envelope-regular',
    'envelope-search-regular',
    'envelope-settings-regular',
    'envelope-shield-regular',
    'envelopes-regular',
    'envelope-star-regular',
    'envelope-times-regular',
    'ethereum-circle-regular',
    'ethereum-regular',
    'ethereum-square-regular',
    'euro-circle-regular',
    'euro-regular',
    'euro-square-regular',
    'exchange-horizontal-circle-regular',
    'exchange-horizontal-regular',
    'exchange-horizontal-square-regular',
    'exchange-vertical-circle-regular',
    'exchange-vertical-regular',
    'exchange-vertical-square-regular',
    'exclamation-circle-regular',
    'exclamation-square-regular',
    'eye-closed-regular',
    'eye-regular',
    'eye-slash-regular',
    'facebook-f-regular',
    'facebook-messenger-regular',
    'facebook-regular',
    'figma-regular',
    'file-ban-regular',
    'file-check-regular',
    'file-clock-regular',
    'file-code-regular',
    'file-copy-regular',
    'file-download-regular',
    'file-edit-regular',
    'file-export-alt-regular',
    'file-export-regular',
    'file-heart-regular',
    'file-import-alt-regular',
    'file-import-regular',
    'file-list-ban-regular',
    'file-list-check-regular',
    'file-list-edit-regular',
    'file-list-heart-regular',
    'file-list-lock-regular',
    'file-list-minus-regular',
    'file-list-plus-regular',
    'file-list-regular',
    'file-list-search-regular',
    'file-list-shield-regular',
    'file-list-star-regular',
    'file-list-times-regular',
    'file-lock-regular',
    'file-minus-regular',
    'file-music-regular',
    'file-plus-regular',
    'file-regular',
    'file-search-regular',
    'file-settings-regular',
    'file-shield-regular',
    'file-star-regular',
    'file-text-ban-regular',
    'file-text-check-regular',
    'file-text-edit-regular',
    'file-text-heart-regular',
    'file-text-lock-regular',
    'file-text-minus-regular',
    'file-text-plus-regular',
    'file-text-regular',
    'file-text-search-regular',
    'file-text-shield-regular',
    'file-text-star-regular',
    'file-text-times-regular',
    'file-times-regular',
    'file-upload-regular',
    'file-user-regular',
    'filter-check-regular',
    'filter-minus-regular',
    'filter-plus-regular',
    'filter-regular',
    'filter-times-regular',
    'fingerprint-regular',
    'fingerprint-viewfinder-regular',
    'fire-regular',
    'flag-regular',
    'flag-triangle-regular',
    'flashlight-regular',
    'floppy-disk-regular',
    'folder-alt-regular',
    'folder-ban-regular',
    'folder-check-regular',
    'folder-clock-regular',
    'folder-code-regular',
    'folder-connection-regular',
    'folder-download-regular',
    'folder-edit-regular',
    'folder-export-regular',
    'folder-heart-regular',
    'folder-import-regular',
    'folder-lock-regular',
    'folder-minus-regular',
    'folder-music-regular',
    'folder-open-regular',
    'folder-plus-regular',
    'folder-regular',
    'folder-search-regular',
    'folder-settings-regular',
    'folder-shield-regular',
    'folder-star-regular',
    'folder-times-regular',
    'folder-upload-regular',
    'folder-user-regular',
    'game-controller-regular',
    'gift-regular',
    'github-regular',
    'globe-earth-regular',
    'globe-regular',
    'google-play-regular',
    'google-regular',
    'grid-1-regular',
    'grid-2-regular',
    'grid-circle-regular',
    'grid-dividers-regular',
    'grid-square-circle-regular',
    'grid-square-plus-regular',
    'grid-square-regular',
    'grid-web-1-regular',
    'grid-web-2-regular',
    'grid-web-3-regular',
    'grid-web-4-regular',
    'grid-web-5-regular',
    'grid-web-6-regular',
    'grid-web-7-regular',
    'hand-holding-dollar-circle-regular',
    'hand-holding-dollar-regular',
    'hand-holding-user-regular',
    'hard-drive-regular',
    'hashtag-regular',
    'headphones-regular',
    'heart-check-regular',
    'heart-circle-regular',
    'heart-minus-regular',
    'heart-plus-regular',
    'heart-regular',
    'heart-slash-regular',
    'heart-square-regular',
    'heart-tag-regular',
    'heart-times-regular',
    'home-activity-regular',
    'home-dash-activity-regular',
    'home-dash-dollar-regular',
    'home-dash-heart-regular',
    'home-dash-lock-regular',
    'home-dash-regular',
    'home-dash-search-regular',
    'home-dash-shield-regular',
    'home-dash-star-regular',
    'home-dash-trend-down-regular',
    'home-dash-trend-up-regular',
    'home-dash-user-regular',
    'home-dash-wifi-regular',
    'home-dollar-regular',
    'home-heart-regular',
    'home-lock-regular',
    'home-regular',
    'home-roof-activity-regular',
    'home-roof-dollar-regular',
    'home-roof-heart-regular',
    'home-roof-lock-regular',
    'home-roof-regular',
    'home-roof-search-regular',
    'home-roof-shield-regular',
    'home-roof-star-regular',
    'home-roof-trend-down-regular',
    'home-roof-trend-up-regular',
    'home-roof-user-regular',
    'home-roof-wifi-regular',
    'home-search-regular',
    'home-shield-regular',
    'home-star-regular',
    'home-trend-down-regular',
    'home-trend-up-regular',
    'home-user-regular',
    'home-wifi-regular',
    'hospital-regular',
    'hourglass-regular',
    'id-badge-regular',
    'id-card-regular',
    'inbox-download-regular',
    'inbox-empty-regular',
    'inbox-regular',
    'inbox-upload-regular',
    'info-circle-regular',
    'info-square-regular',
    'instagram-regular',
    'invoice-regular',
    'kaaba-regular',
    'keyboard-regular',
    'key-circle-regular',
    'keyhole-circle-regular',
    'keyhole-square-regular',
    'keypad-regular',
    'key-regular',
    'key-square-regular',
    'lamp-desk-regular',
    'laptop-code-regular',
    'laptop-regular',
    'layer-group-regular',
    'layout-web-1-regular',
    'layout-web-2-regular',
    'layout-web-3-regular',
    'layout-web-4-regular',
    'layout-web-5-regular',
    'layout-web-6-regular',
    'layout-web-7-regular',
    'layout-web-8-regular',
    'layout-web-9-regular',
    'layout-web-10-regular',
    'layout-web-11-regular',
    'layout-web-12-regular',
    'leaf-regular',
    'life-ring-regular',
    'lightbulb-regular',
    'light-emergency-on-regular',
    'light-emergency-regular',
    'like-circle-regular',
    'like-regular',
    'like-square-regular',
    'like-tag-regular',
    'line-chart-dots-circle-regular',
    'line-chart-dots-regular',
    'line-chart-dots-square-regular',
    'line-down-circle-regular',
    'line-down-square-regular',
    'line-up-circle-regular',
    'line-up-square-regular',
    'link-alt-regular',
    'link-broken-horizontal-regular',
    'link-broken-regular',
    'linkedin-regular',
    'link-horizontal-alt-regular',
    'link-horizontal-regular',
    'link-regular',
    'list-regular',
    'location-arrow-circle-regular',
    'location-arrow-regular',
    'location-arrow-square-regular',
    'location-crosshairs-regular',
    'location-crosshairs-slash-regular',
    'location-pin-alt-regular',
    'location-pin-check-regular',
    'location-pin-edit-regular',
    'location-pin-exclamation-regular',
    'location-pin-eye-regular',
    'location-pin-heart-regular',
    'location-pin-lock-regular',
    'location-pin-minus-regular',
    'location-pin-plus-regular',
    'location-pin-question-regular',
    'location-pin-regular',
    'location-pin-search-regular',
    'location-pin-slash-alt-regular',
    'location-pin-slash-regular',
    'location-pin-star-regular',
    'location-pin-times-regular',
    'lock-regular',
    'lock-viewfinder-regular',
    'log-in-regular',
    'log-out-regular',
    'magicoon-regular',
    'magnet-regular',
    'map-location-regular',
    'map-pin-regular',
    'map-regular',
    'mastercard-regular',
    'maximize-regular',
    'medal-check-regular',
    'medal-regular',
    'medal-star-regular',
    'medium-regular',
    'megaphone-regular',
    'menu-alt-regular',
    'menu-circle-alt-regular',
    'menu-circle-regular',
    'menu-duo-circle-regular',
    'menu-duo-regular',
    'menu-duo-square-regular',
    'menu-left-alt-regular',
    'menu-left-circle-alt-regular',
    'menu-left-circle-regular',
    'menu-left-regular',
    'menu-left-square-alt-regular',
    'menu-left-square-regular',
    'menu-regular',
    'menu-right-alt-regular',
    'menu-right-circle-alt-regular',
    'menu-right-circle-regular',
    'menu-right-regular',
    'menu-right-square-alt-regular',
    'menu-right-square-regular',
    'menu-square-alt-regular',
    'menu-square-regular',
    'message-activity-regular',
    'message-check-alt-regular',
    'message-check-regular',
    'message-clock-regular',
    'message-code-regular',
    'message-dots-check-regular',
    'message-dots-minus-regular',
    'message-dots-notification-regular',
    'message-dots-plus-regular',
    'message-dots-regular',
    'message-dots-times-regular',
    'message-edit-regular',
    'message-exclamation-regular',
    'message-heart-regular',
    'message-info-regular',
    'message-lock-regular',
    'message-minus-alt-regular',
    'message-minus-regular',
    'message-notification-regular',
    'message-plus-alt-regular',
    'message-plus-regular',
    'message-question-regular',
    'message-regular',
    'messages-dots-regular',
    'message-search-regular',
    'message-settings-regular',
    'message-shield-regular',
    'messages-regular',
    'message-star-regular',
    'messages-text-regular',
    'message-text-check-regular',
    'message-text-minus-regular',
    'message-text-notification-regular',
    'message-text-plus-regular',
    'message-text-regular',
    'message-text-times-regular',
    'message-times-alt-regular',
    'message-times-regular',
    'meta-regular',
    'microphone-regular',
    'microphone-slash-regular',
    'microphone-stand-regular',
    'microsoft-regular',
    'minimize-regular',
    'minus-circle-regular',
    'minus-regular',
    'minus-square-regular',
    'mobile-regular',
    'money-bill-ban-regular',
    'money-bill-check-regular',
    'money-bill-clock-regular',
    'money-bill-convert-regular',
    'money-bill-minus-regular',
    'money-bill-plus-regular',
    'money-bill-receive-regular',
    'money-bill-regular',
    'money-bill-send-regular',
    'money-bills-regular',
    'money-bill-times-regular',
    'money-convert-regular',
    'money-withdrawal-regular',
    'moon-regular',
    'more-horizontal-circle-regular',
    'more-horizontal-regular',
    'more-horizontal-square-regular',
    'more-vertical-circle-regular',
    'more-vertical-regular',
    'more-vertical-square-regular',
    'mosque-regular',
    'mouse-regular',
    'newspaper-regular',
    'notebook-regular',
    'note-list-check-regular',
    'note-list-check-square-regular',
    'note-list-regular',
    'note-list-square-regular',
    'note-regular',
    'note-text-regular',
    'note-text-square-regular',
    'nut-regular',
    'octagon-check-regular',
    'octagon-exclamation-regular',
    'octagon-minus-regular',
    'octagon-plus-regular',
    'octagon-regular',
    'octagon-times-regular',
    'package-check-regular',
    'package-dollar-regular',
    'package-heart-regular',
    'package-regular',
    'package-star-regular',
    'palette-regular',
    'pants-regular',
    'paperclip-regular',
    'papers-regular',
    'papers-text-regular',
    'parking-circle-regular',
    'parking-square-regular',
    'paypal-card-regular',
    'paypal-regular',
    'pen-tool-regular',
    'percent-circle-regular',
    'percent-regular',
    'percent-square-regular',
    'percent-tag-regular',
    'phone-1-regular',
    'phone-2-regular',
    'phone-call-regular',
    'phone-flip-regular',
    'phone-hangup-regular',
    'phone-incoming-regular',
    'phone-missed-regular',
    'phone-office-regular',
    'phone-outgoing-regular',
    'phone-plus-regular',
    'phone-regular',
    'phone-slash-regular',
    'phone-times-regular',
    'pinterest-p-regular',
    'pinterest-regular',
    'plug-regular',
    'plus-circle-regular',
    'plus-regular',
    'plus-square-regular',
    'poll-horizontal-circle-regular',
    'poll-horizontal-square-regular',
    'poll-vertical-circle-regular',
    'poll-vertical-square-regular',
    'power-off-circle-regular',
    'power-off-regular',
    'power-off-square-regular',
    'presentation-chart-line-regular',
    'presentation-chart-pie-regular',
    'presentation-dollar-regular',
    'presentation-lightbulb-regular',
    'presentation-pen-regular',
    'presentation-play-regular',
    'presentation-poll-regular',
    'presentation-regular',
    'presentation-text-regular',
    'presentation-trend-down-regular',
    'presentation-trend-up-regular',
    'presentation-user-regular',
    'print-regular',
    'print-slash-regular',
    'puzzle-regular',
    'question-circle-regular',
    'question-square-regular',
    'quote-regular',
    'radar-regular',
    'radio-button-fill-regular',
    'radio-regular',
    'ranking-regular',
    'receipt-alt-regular',
    'receipt-heart-alt-regular',
    'receipt-heart-regular',
    'receipt-list-alt-regular',
    'receipt-list-regular',
    'receipt-percent-alt-regular',
    'receipt-percent-regular',
    'receipt-regular',
    'receipt-star-alt-regular',
    'receipt-star-regular',
    'receipt-text-alt-regular',
    'receipt-text-regular',
    'reddit-regular',
    'registered-circle-regular',
    'registered-square-regular',
    'road-regular',
    'rocket-launch-regular',
    'rocket-regular',
    'rotate-circle-regular',
    'rotate-left-circle-regular',
    'rotate-left-regular',
    'rotate-left-square-regular',
    'rotate-regular',
    'rotate-right-circle-regular',
    'rotate-right-regular',
    'rotate-right-square-regular',
    'rotate-square-regular',
    'route-regular',
    'sack-dollar-regular',
    'scan-circle-regular',
    'scanner-regular',
    'scan-qr-regular',
    'scan-regular',
    'school-regular',
    'scissors-regular',
    'sd-card-regular',
    'search-minus-regular',
    'search-plus-regular',
    'search-regular',
    'search-text-regular',
    'send-regular',
    'send-right-regular',
    'server-regular',
    'settings-regular',
    'share-regular',
    'shield-bolt-regular',
    'shield-check-regular',
    'shield-exclamation-regular',
    'shield-heart-regular',
    'shield-lock-regular',
    'shield-minus-regular',
    'shield-play-regular',
    'shield-plus-regular',
    'shield-question-regular',
    'shield-regular',
    'shield-search-regular',
    'shield-slash-regular',
    'shield-star-regular',
    'shield-times-regular',
    'shield-user-regular',
    'shirt-regular',
    'shoes-regular',
    'shopping-bag-alt-regular',
    'shopping-bag-regular',
    'shopping-basket-arrow-down-regular',
    'shopping-basket-arrow-up-regular',
    'shopping-basket-check-regular',
    'shopping-basket-heart-regular',
    'shopping-basket-minus-regular',
    'shopping-basket-plus-regular',
    'shopping-basket-regular',
    'shopping-basket-star-regular',
    'shopping-basket-times-regular',
    'shopping-cart-arrow-down-regular',
    'shopping-cart-arrow-top-regular',
    'shopping-cart-check-regular',
    'shopping-cart-heart-regular',
    'shopping-cart-minus-regular',
    'shopping-cart-plus-regular',
    'shopping-cart-regular',
    'shopping-cart-star-regular',
    'shopping-cart-times-regular',
    'sign-direction-left-regular',
    'sign-direction-right-regular',
    'signs-direction-regular',
    'sim-card-1-regular',
    'sim-card-2-regular',
    'sim-card-regular',
    'skype-regular',
    'sliders-horizontal-alt-regular',
    'sliders-horizontal-regular',
    'sliders-vertical-alt-regular',
    'sliders-vertical-regular',
    'snapchat-regular',
    'sofa-regular',
    'sort-ascending-circle-regular',
    'sort-ascending-regular',
    'sort-ascending-square-regular',
    'sort-descending-circle-regular',
    'sort-descending-regular',
    'sort-descending-square-regular',
    'sort-horizontal-regular',
    'sort-regular',
    'sort-vertical-regular',
    'soundcloud-regular',
    'sparkle-regular',
    'speaker-regular',
    'speedometer-regular',
    'spotify-regular',
    'star-circle-regular',
    'star-half-regular',
    'star-magic-regular',
    'star-regular',
    'star-shooting-horizontal-regular',
    'star-shooting-regular',
    'star-slash-regular',
    'star-square-regular',
    'star-tag-regular',
    'sticky-note-circle-regular',
    'sticky-note-square-regular',
    'sticky-note-text-circle-regular',
    'sticky-note-text-square-regular',
    'stopwatch-alt-regular',
    'stopwatch-check-alt-regular',
    'stopwatch-check-regular',
    'stopwatch-edit-regular',
    'stopwatch-empty-regular',
    'stopwatch-minus-alt-regular',
    'stopwatch-minus-regular',
    'stopwatch-plus-alt-regular',
    'stopwatch-plus-regular',
    'stopwatch-regular',
    'stopwatch-times-alt-regular',
    'stopwatch-times-regular',
    'store-regular',
    'story-regular',
    'stripe-regular',
    'sun-regular',
    'tablet-regular',
    'tag-regular',
    'tags-regular',
    'target-regular',
    'taxi-regular',
    'telegram-regular',
    'telescope-regular',
    'thumbtack-regular',
    'thumbtack-vertical-regular',
    'tiktok-regular',
    'time-forward-regular',
    'time-past-regular',
    'times-circle-regular',
    'times-regular',
    'times-square-regular',
    'toggle-off-alt-regular',
    'toggle-off-rectangle-regular',
    'toggle-off-regular',
    'toggle-on-alt-regular',
    'toggle-on-rectangle-regular',
    'toggle-on-regular',
    'tool-regular',
    'traffic-cone-regular',
    'trash-check-regular',
    'trash-minus-regular',
    'trash-plus-regular',
    'trash-redo-regular',
    'trash-regular',
    'trash-slash-regular',
    'trash-times-regular',
    'trash-undo-regular',
    'tree-regular',
    'trend-down-circle-regular',
    'trend-down-regular',
    'trend-down-square-regular',
    'trend-up-circle-regular',
    'trend-up-regular',
    'trend-up-square-regular',
    'triangle-exclamation-regular',
    'triangle-regular',
    'trophy-regular',
    'trophy-star-regular',
    'truck-regular',
    'tumbler-regular',
    'tv-play-regular',
    'tv-regular',
    'tv-retro-regular',
    'twitch-regular',
    'twitter-regular',
    'university-regular',
    'unlock-regular',
    'upload-alt-regular',
    'upload-regular',
    'usb-drive-regular',
    'user-alt-regular',
    'user-ban-regular',
    'user-change-regular',
    'user-check-alt-regular',
    'user-check-bottom-regular',
    'user-check-regular',
    'user-circle-regular',
    'user-clock-regular',
    'user-code-regular',
    'user-dollar-regular',
    'user-edit-regular',
    'user-eye-regular',
    'user-heart-regular',
    'user-lock-regular',
    'user-minus-alt-regular',
    'user-minus-bottom-regular',
    'user-minus-regular',
    'user-plus-alt-regular',
    'user-plus-bottom-regular',
    'user-plus-regular',
    'user-regular',
    'users-alt-regular',
    'user-search-regular',
    'user-settings-regular',
    'users-group-alt-regular',
    'users-group-regular',
    'users-group-slash-regular',
    'user-shield-regular',
    'user-slash-regular',
    'user-square-regular',
    'users-regular',
    'users-slash-regular',
    'user-star-regular',
    'user-tag-regular',
    'user-times-alt-regular',
    'user-times-bottom-regular',
    'user-times-regular',
    'user-viewfinder-regular',
    'vector-regular',
    'video-circle-regular',
    'video-eye-regular',
    'video-incoming-regular',
    'video-outgoing-regular',
    'video-plus-regular',
    'video-regular',
    'video-slash-regular',
    'video-square-regular',
    'vimeo-regular',
    'visa-regular',
    'vk-regular',
    'voicemail-regular',
    'wallet-regular',
    'warehouse-regular',
    'watch-circle-regular',
    'watch-square-regular',
    'wechat-regular',
    'weight-scale-regular',
    'whatsapp-regular',
    'wifi-regular',
    'wifi-slash-regular',
    'wordpress-regular',
    'xbox-regular',
    'youtube-regular'
  ],
  iconsNewOld: [
    'add',
    'add-bg',
    'add-file',
    'add-file-bg',
    'add-file-ot',
    'add-folder',
    'add-folder-bg',
    'add-folder-ot',
    'add-ot',
    'add-shield',
    'add-shield-bg',
    'add-shield-ot',
    'android-menu',
    'android-menu-bg',
    'android-menu-ot',
    'arrow-down',
    'arrow-down-bg',
    'arrow-down-ot',
    'arrow-left',
    'arrow-left-bg',
    'arrow-left-ot',
    'arrow-right',
    'arrow-right-bg',
    'arrow-right-ot',
    'arrow-up',
    'arrow-up-bg',
    'arrow-up-ot',
    'badge',
    'badge-bg',
    'badge-ot',
    'bell',
    'bell-bg',
    'bell-ot',
    'bin',
    'bin-bg',
    'bin-ot',
    'bookmark',
    'bookmark-bg',
    'bookmark-ot',
    'building',
    'building-bg',
    'building-ot',
    'bulb',
    'bulb-bg',
    'bulb-ot',
    'calendar',
    'calendar-bg',
    'calendar-ot',
    'cart',
    'cart-bg',
    'cart-ot',
    'center-align',
    'center-align-bg',
    'center-align-ot',
    'clip',
    'clip-bg',
    'clip-ot',
    'clock',
    'clock-bg',
    'clock-ot',
    'cloud',
    'cloud-bg',
    'cloud-cancel',
    'cloud-cancel-bg',
    'cloud-cancel-ot',
    'cloud-download',
    'cloud-download-bg',
    'cloud-download-ot',
    'cloud-ot',
    'cloud-share',
    'cloud-share-bg',
    'cloud-share-ot',
    'cloud-upload',
    'cloud-upload-bg',
    'cloud-upload-ot',
    'code',
    'code-bg',
    'code-ot',
    'copy',
    'copy-bg',
    'copy-ot',
    'cross',
    'cross-bg',
    'cross-ot',
    'directory',
    'directory-bg',
    'directory-ot',
    'dollar',
    'dollar2',
    'dollar2-bg',
    'dollar2-ot',
    'dollar-bg',
    'dollar-ot',
    'double-gear',
    'double-gear-bg',
    'double-gear-ot',
    'double-human',
    'double-human-bg',
    'double-human-ot',
    'download',
    'download-bg',
    'download-ot',
    'email',
    'email-bg',
    'email-ot',
    'eye',
    'eye-bg',
    'eye-closed',
    'eye-closed-bg',
    'eye-closed-ot',
    'eye-ot',
    'file-cross',
    'file-cross-bg',
    'file-cross-ot',
    'file-download',
    'file-download-bg',
    'file-download-ot',
    'file-tick',
    'file-tick-bg',
    'file-tick-ot',
    'file-upload',
    'file-upload-bg',
    'file-upload-ot',
    'filled-arrow-down',
    'filled-arrow-down-bg',
    'filled-arrow-down-ot',
    'filled-arrow-left',
    'filled-arrow-left-bg',
    'filled-arrow-left-ot',
    'filled-arrow-right',
    'filled-arrow-right-bg',
    'filled-arrow-right-ot',
    'filled-arrow-up',
    'filled-arrow-up-bg',
    'filled-arrow-up-ot',
    'filter',
    'filter-bg',
    'filter-ot',
    'fingerprint',
    'fingerprint-bg',
    'fingerprint-ot',
    'folder',
    'folder-bg',
    'folder-ot',
    'font',
    'font-bg',
    'font-ot',
    'full-screen',
    'full-screen-bg',
    'full-screen-ot',
    'full-star',
    'full-star-bg',
    'full-star-ot',
    'gear',
    'gear-bg',
    'gear-ot',
    'gear-tool',
    'gear-tool-bg',
    'gear-tool-ot',
    'half-star',
    'half-star-bg',
    'half-star-ot',
    'hamburger-dots',
    'hamburger-dots-bg',
    'hamburger-dots-ot',
    'hamburger-menu',
    'hamburger-menu-bg',
    'hamburger-menu-ot',
    'heart',
    'heart-bg',
    'heart-ot',
    'highlighter',
    'highlighter-bg',
    'highlighter-ot',
    'home-ot',
    'home-ot-1',
    'home-ot-2',
    'horizontal-dots',
    'horizontal-dots-bg',
    'horizontal-dots-ot',
    'human',
    'human-add',
    'human-add-bg',
    'human-add-ot',
    'human-bg',
    'human-cross',
    'human-cross-bg',
    'human-cross-ot',
    'human-minus',
    'human-minus-bg',
    'human-minus-ot',
    'human-ot',
    'human-timer',
    'human-timer-bg',
    'human-timer-ot',
    'info',
    'info-bg',
    'info-ot',
    'jpg-file',
    'jpg-file-bg',
    'jpg-file-ot',
    'justify-align',
    'justify-align-bg',
    'justify-align-ot',
    'kebab-menu',
    'kebab-menu-bg',
    'kebab-menu-ot',
    'left-align',
    'left-align-bg',
    'left-align-ot',
    'line-arrow-down',
    'line-arrow-down-bg',
    'line-arrow-down-ot',
    'line-arrow-left',
    'line-arrow-left-bg',
    'line-arrow-left-ot',
    'line-arrow-right',
    'line-arrow-right-bg',
    'line-arrow-right-ot',
    'line-arrow-up',
    'line-arrow-up-bg',
    'line-arrow-up-ot',
    'link',
    'link-bg',
    'link-ot',
    'location',
    'location-bg',
    'location-ot',
    'lock',
    'lock-bg',
    'lock-ot',
    'login',
    'login-bg',
    'login-ot',
    'logo',
    'logout',
    'logout-bg',
    'logout-ot',
    'message',
    'message-bg',
    'message-ot',
    'minus',
    'minus-bg',
    'minus-ot',
    'not-allowed',
    'not-allowed-bg',
    'not-allowed-ot',
    'pdf-file',
    'pdf-file-bg',
    'pdf-file-ot',
    'pen',
    'pen-bg',
    'pen-ot',
    'phone',
    'phone-bg',
    'phone-ot',
    'plug',
    'plug-bg',
    'plug-ot',
    'png-file',
    'png-file-bg',
    'png-file-ot',
    'price-tag',
    'price-tag-bg',
    'price-tag-ot',
    'profile',
    'profile-bg',
    'profile-ot',
    'question',
    'question-bg',
    'question-ot',
    'redo',
    'redo-bg',
    'redo-ot',
    'reload',
    'reload-bg',
    'reload-ot',
    'reset',
    'reset-bg',
    'reset-ot',
    'right-align',
    'right-align-bg',
    'right-align-ot',
    'rocket',
    'rocket-bg',
    'rocket-ot',
    'search',
    'search-bg',
    'search-ot',
    'setting',
    'setting-bg',
    'setting-ot',
    'share',
    'share-bg',
    'share-ot',
    'star',
    'star-bg',
    'star-ot',
    'tag-cross',
    'tag-cross-bg',
    'tag-cross-ot',
    'target',
    'target-bg',
    'target-ot',
    'tick',
    'tick-bg',
    'tick-ot',
    'undo',
    'undo-bg',
    'undo-ot',
    'unlock',
    'unlock-bg',
    'unlock-ot',
    'upload',
    'upload-bg',
    'upload-ot',
    'vertical-dots',
    'vertical-dots-bg',
    'vertical-dots-ot',
    'warning',
    'warning-bg',
    'warning-ot',
    'www',
    'www-bg',
    'www-ot',
    'xlsx-file',
    'xlsx-file-bg',
    'xlsx-file-ot',
    'zoom-in',
    'zoom-in-bg',
    'zoom-in-ot',
    'zoom-out',
    'zoom-out-bg',
    'zoom-out-ot',
    'right-indent',
    'right-indent-ot',
    'right-indent-bg',
    'left-indent',
    'left-indent-ot',
    'left-indent-bg',
    'ordered-list',
    'ordered-list-ot',
    'ordered-list-bg',
    'color-fill',
    'color-fill-ot',
    'color-fill-bg',
    'unlink',
    'unlink-ot',
    'unlink-bg'
  ]
}
module.exports = {
  icons
}
