<template>
  <div>
    <input v-model="searchID" placeholder="Enter displayID" />
    <button @click="searchTree">Search</button>
    <pre>{{ result }}</pre>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchID: "",
      result: null,
      treedataorderTree: {
        description: "VP test internet order 001",
        id: "6653fa4c5e207f2a81610ae6",
        displayID: "ODR-0001",
        "@type": "productOrder",
        state:"cancelled",
        child: [
          {
            displayID: "ODR-0002",
            "@type": "productOrderItem",
            specification: {
              _id: "66536dae150db5fc7bf8d15e",
              name: "Internet service for vp testing",
              href: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/catalogManagement/productSpecification",
              bundledProductSpecification: [],
              isBundle: false,
              lifecycleStatus: "Active",
              unitOfMeasure: { name: "" },
              category: ["Connectivity services", "Internet"],
              description: "",
              id: "66536dae150db5fc7bf8d15e",
              brand: "Aruba Networks Inc",
            },
            child: [
              {
                description: "Service order of Access CFS,Internet CFS",
                id: "665974db13bcecd739e27723",
                "@type": "serviceOrder",
                displayID: "ODR-0003",
                state:"acknowledged",
                child: [
                  {
                    id: "665974d813bcecd739e2771b",
                    "@type": "serviceOrderItem",
                    displayID: "ODR-0004",
                    specification: {
                      name: "Access CFS",
                      description: "",
                      category: ["Internet", "Business internet"],
                      "@type": "Customer Facing Service",
                      id: "663633620d187ea20235de5e",
                    },
                    child: [
                      {
                        description:
                          "Service order of Site Access Fibre RFS,Access CPE RFS,Site Access Copper RFS,Access Edge LTE RFS",
                        id: "665974df13bcecd739e27744",
                        "@type": "serviceOrder",
                        displayID: "ODR-0005",
                        child: [
                          {
                            id: "665974de13bcecd739e27736",
                            "@type": "serviceOrderItem",
                            displayID: "ODR-0006",
                            specification: {
                              name: "Site Access Fibre RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66363a650d187ea20235e282",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of Broadband Fiber,Data,Access Circuit",
                                id: "665974e413bcecd739e2778b",
                                "@type": "resourceOrder",
                                displayID: "ODR-0007",
                                child: [
                                  {
                                    id: "665974e213bcecd739e27768",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0008",
                                    specification: {
                                      id: "66367119f321c12bf31982b8",
                                      name: "Broadband Fiber",
                                      description: "",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                      displayID: "ODR-0009",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27769",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-00010",
                                    specification: {
                                      name: "Data",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e2776a",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0011",
                                    specification: {
                                      name: "Access Circuit",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e27737",
                            "@type": "serviceOrderItem",
                            displayID: "ODR-0012",
                            specification: {
                              name: "Access CPE RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "663671cff321c12bf3198357",
                            },
                            child: [
                              {
                                description: "Resource order of CPE",
                                id: "665974e313bcecd739e27788",
                                "@type": "resourceOrder",
                                displayID: "ODR-0013",
                                child: [
                                  {
                                    id: "665974e213bcecd739e2776b",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0014",
                                    specification: {
                                      name: "CPE",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e27738",
                            "@type": "serviceOrderItem",
                            displayID: "ODR-0015",
                            specification: {
                              name: "Site Access Copper RFS",
                              description: "",
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66366fd1f321c12bf31981d1",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of Broadband Copper,Data,Access Circuit",
                                id: "665974e513bcecd739e277a5",
                                "@type": "resourceOrder",
                                displayID: "ODR-0016",
                                child: [
                                  {
                                    id: "665974e213bcecd739e2776c",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0017",
                                    specification: {
                                      name: "Broadband Copper",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e2776d",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0018",
                                    specification: {
                                      name: "Data",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e2776e",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0019",
                                    specification: {
                                      name: "Access Circuit",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e27739",
                            "@type": "serviceOrderItem",
                            displayID: "ODR-0020",
                            specification: {
                              name: "Access Edge LTE RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66363a2a0d187ea20235e22a",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of PDP Context,Static IP,Subscriber Record",
                                id: "665974e513bcecd739e277a8",
                                "@type": "resourceOrder",
                                displayID: "ODR-0021",
                                child: [
                                  {
                                    id: "665974e213bcecd739e2776f",
                                    "@type": "resourceOrderItem",
                                    specification: {
                                      name: "PDP Context",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27770",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0022",
                                    specification: {
                                      id: "66367194f321c12bf3198318",
                                      name: "Static IP",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27771",
                                    "@type": "resourceOrderItem",
                                    displayID: "ODR-0023",
                                    specification: {
                                      name: "Subscriber Record",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "665974d813bcecd739e2771c",
                    "@type": "serviceOrderItem",
                    specification: {
                      name: "Internet CFS",
                      description: null,
                      category: ["Internet", "Business internet"],
                      "@type": "Customer Facing Service",
                      id: "663633890d187ea20235de99",
                    },
                    child: [
                      {
                        description:
                          "Service order of Wireline Internet RFS,Wireline Policy Control  RFS",
                        id: "665974df13bcecd739e27747",
                        "@type": "serviceOrder",
                        child: [
                          {
                            id: "665974de13bcecd739e2773a",
                            "@type": "serviceOrderItem",
                            specification: {
                              name: "Wireline Internet RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66363a020d187ea20235e1f8",
                            },
                            child: [
                              {
                                description: "Resource order of CPE",
                                id: "665974e413bcecd739e2778e",
                                "@type": "resourceOrder",
                                child: [
                                  {
                                    id: "665974e213bcecd739e27772",
                                    "@type": "resourceOrderItem",
                                    specification: {
                                      name: "CPE",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e2773b",
                            "@type": "serviceOrderItem",
                            specification: {
                              name: "Wireline Policy Control  RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66367435f321c12bf31985ea",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of Authentication LDAP,Authorization LDAP,Telephone number",
                                id: "665974e413bcecd739e27791",
                                "@type": "resourceOrder",
                                child: [
                                  {
                                    id: "665974e213bcecd739e27773",
                                    "@type": "resourceOrderItem",
                                    specification: {
                                      name: "Authentication LDAP",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27774",
                                    "@type": "resourceOrderItem",
                                    specification: {
                                      id: "6636707ef321c12bf319822d",
                                      name: "Authorization LDAP",
                                      description: "",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27775",
                                    "@type": "resourceOrderItem",
                                    specification: {
                                      id: "663670b7f321c12bf3198264",
                                      name: "Telephone number",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
  searchTree() {
      console.log("Searching for:", this.searchID);
      this.result = this.findNode(this.treedataProduct, this.searchID);
      console.log("Search result:", this.result);
    },
    findNode(node, displayID) {
      if (!node) {
        return null;
      }
      console.log("Visiting node:", node.displayID);
      if (node.displayID === displayID) {
        return node;
      }
      if (node.child && Array.isArray(node.child)) {
        for (let child of node.child) {
          const result = this.findNode(child, displayID);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
  },
};
</script>
