<template>
  <div class="table-v2-container">
    <table
      :id="'table-v2-' + unique"
      :style="{ height: height + 'px' }"
      :class="{
        hlxTableV2: true,
        headerBorder: border.includes('header') ? true : false,
        tableBorder: border.includes('table') ? true : false,
        horizontalBorder: border.includes('horizontal') ? true : false,
        verticalBorder: border.includes('vertical') ? true : false,
        lightTheme: theme === 'light' ? true : false,
        greyTheme: theme === 'grey' ? true : false,
        primaryTheme: theme === 'primary' ? true : false,
        boldHeaders: boldHeaders,
        rowHover: rowHover,
        stripedRow: stripedRows,
      }"
    >
      <colgroup style="width: 100% !important">
        <col v-for="heads in columnCount" :key="heads" />
      </colgroup>
      <thead>
        <tr :id="'merge-head-' + unique" class="table-v2-merge-head">
          <slot name="mergehead"></slot>
        </tr>
        <tr :id="'head-' + unique" class="table-v2-head">
          <slot name="thead"></slot>
        </tr>
      </thead>
      <tbody class="table-v2-body">
        <slot name="tbody"></slot>
      </tbody>
    </table>
  </div>

  <modal
    :modal-active="modalActive"
    :height="'450px'"
    :width="'400px'"
    :modal="true"
    @close="closeRearrangeModal"
  >
    <template #header>
      <div class="modal-header">
        <p>Re-arrange columns</p>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <draggables
          :key="componentKey"
          :list="duplicateTheads"
          :selectable="true"
          @change="emitFromDraggables"
        />
      </div>
    </template>
    <template #footer>
      <div class="modal-footer">
        <hlx-button
          class="secondary sm"
          style="margin-right: 10px"
          @click="closeRearrangeModal"
        >
          Cancel
        </hlx-button>
        <hlx-button class="primary sm" @click="applyCustomisedColumns"
          >Apply</hlx-button
        >
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "../ModalwindowComponent.vue";
import draggables from "../DraggableComponent.vue";
import HlxButton from "../ButtonComponent.vue";

export default {
  name: "HlxTable",
  components: {
    modal,
    draggables,
    HlxButton,
  },
  props: {
    columnCount: {
      type: [Number, Array],
      default: () => {
        return [];
      },
    },
    reArrangeHeads: {
      type: Array,
      default: () => {
        return [];
      },
    },
    border: {
      type: Array,
      default: () => {
        return [];
      },
    },
    boldHeaders: {
      type: Boolean,
      default: false,
    },
    rowHover: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "light",
    },
    stripedRows: {
      type: Boolean,
      default: false,
    },
    reArrangeColumns: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  emits: ["closeRearrange", "applyRearrangedColumns", "sorting_func"],
  data() {
    return {
      unique: Math.floor(Math.random() * 1000 + 1),
      modalActive: false,
      duplicateTheads: [],
      componentKey: 0,
    };
  },
  computed: {},
  watch: {
    reArrangeColumns: {
      handler(val) {
        if (val === true) {
          this.modalActive = true;
        }
      },
    },
    reArrangeHeads: {
      handler(newValue) {
        if (newValue !== undefined) {
          this.duplicateTheads = JSON.parse(JSON.stringify(newValue));
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (
      document.querySelector("#merge-head-" + this.unique).children.length !== 0
    ) {
      const elems = document.querySelector(
        "#merge-head-" + this.unique
      ).children;
      elems[0].style.borderTopLeftRadius = "5px";
      elems[elems.length - 1].style.borderTopRightRadius = "5px";
      for (let index = 0; index < elems.length; index++) {
        elems[index].style.position = "sticky";
        elems[index].style.top = "0px";
      }
      const elems2 = document.querySelector("#head-" + this.unique).children;
      for (let index = 0; index < elems2.length; index++) {
        elems2[index].style.position = "sticky";
        elems2[index].style.top = "40px";
      }
    } else {
      const elems = document.querySelector("#head-" + this.unique).children;
      elems[0].style.borderTopLeftRadius = "5px";
      elems[elems.length - 1].style.borderTopRightRadius = "5px";
      for (let index = 0; index < elems.length; index++) {
        elems[index].style.position = "sticky";
        elems[index].style.top = "0px";
      }
    }
  },
  methods: {
    closeRearrangeModal() {
      this.componentKey += 1;
      this.$forceUpdate();
      this.duplicateTheads = [...this.reArrangeHeads];
      this.modalActive = false;
      this.$emit("closeRearrange", false);
    },
    emitFromDraggables() {},
    applyCustomisedColumns() {
      this.modalActive = false;
      this.$emit("closeRearrange", false);
      this.$emit("applyRearrangedColumns", this.duplicateTheads);
    },
  },
};
</script>
<style></style>
