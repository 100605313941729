<template>
  <li
    v-if="Object.keys(child).length > 0"
    :ref="child.name + '-ref'"
    :class="{ 'no-icon': child.icon == undefined ? true : false }"
    @click.capture="currentValue(child, $event)"
  >
    <nav
      :ref="child.name"
      class="label-wrapper"
      :class="{
        'only-child':
          child.child == undefined || (child.child && child.child.length == 0)
            ? true
            : false,
        shepherd:
          headSelect == false &&
          (child.child != undefined || (child.child && child.child.length > 0))
            ? true
            : false,
        'expanded-tree': expanded,
        'selected-tree':child.active,
        'selected-tree':
          child.active &&
          ((headSelect == false &&
            (child.child == undefined ||
              (child.child && child.child.length == 0))) ||
            (headSelect == true &&
              (child.child != undefined ||
                (child.child && child.child.length >= 0))))
            ? true
            : false,
        'no-icon': child.icon == undefined ? true : false,
      }"
      @click.stop="handleSelect($event, child.name)"
    >
      <span
        v-if="child.child && child.child.length > 0"
        class="arrow"
        @click.stop="handleChildProcess($event, child.name)"
      >
        <i v-if="!isIconRightOpened" class="icon-angle-right-regular custom-arrow-open" ></i>
        <i v-if="isIconRightOpened" class="icon-angle-down-regular custom-arrow-close" ></i>
      </span>
      <!-- v-if="child.icon != undefined" -->
      <span class="label-icon-wrapper">
        <i :class="child.icon"></i>
      </span>

      <span
        class="child-label"
        :style="{ 'margin-left': child.icon != undefined ? '' : '' }"
      >
        {{ child.label }}
      </span>
      <span v-if="editable == true" class="edit-tool-bar">
        <i class="icon-clipboard-edit-regular" @click="handleEdit(child)"></i>
        <i class="icon-plus-regular" @click.stop="handleAdd(child)"></i>
        <i class="icon-trash-regular" @click="handleDelete(child)"></i>
      </span>
    </nav>
    <ul
      v-if="child.child && child.child.length > 0"
      :child="child.child"
      :class="{
        'show-tree': expanded,
        guide: guide,
      }"
    >
      <hlx-tree-select-child
        v-for="childData in child.child"
        :key="childData.name"
        :guide="guide"
        :child="childData"
        :head-select="headSelect"
        :editable="editable"
        :expanded="expanded"
        @current-value="handleCurrentData"
        @currenteditdata="editCardHierarchy"
        @edit="handleEdit"
        @add="handleAdd"
        @delete="handleDelete"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: "HlxTreeSelectChild",
  props: {
    child: {
      type: Object,
      default: () => {},
    },
    arrow: {
      type: Boolean,
      default: () => true,
    },
    headSelect: {
      type: Boolean,
      default: () => true,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    expanded: {
      type: Boolean,
      default: () => false,
    },
    guide: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["currentValue", "edit", "delete", "add"],
  data(){
    return {

      isIconRightOpened:false
    }
  },
  methods: {
    handleArrow(){
      console.log("🚀 ~ file: TreeSelectChildComponent.vue:124 ~ handleArrow ~ handleArrow:",)
      this.isIconRightOpened = !this.isIconRightOpened
    },
    handleChildProcess($event, name) {
      // console.log("🚀 ~ file: TreeSelectChildComponent.vue:128 ~ handleChildProcess ~ $event, name:", $event, name)
      // console.log($event.target.nodeName);
      // console.log(parent);
      let node = $event.target.nodeName
      let className = [...$event.target.classList]
      // console.log("🚀 ~ file: TreeSelectChildComponent.vue:132 ~ handleChildProcess ~ className:", className)
      if(node == 'I' && (className.includes('custom-arrow-close') || className.includes('custom-arrow-open'))) {
        this.isIconRightOpened = !this.isIconRightOpened
        // return
        // if(node == 'I' && className.includes('custom-arrow-close')) {
        // return
        // }
        $event.target.parentElement.parentElement.parentElement.classList.toggle(
        "expanded"
      );
      return
      }
     
      this.$emit("currentValue", { name, ele: this.$refs[name] });
    },
    emitEdit() {
      // console.log(data,'emit');
    },
    handleSelect($event, name) {
      // console.log($event.target.parentElement,'handle select',this.$refs[name]);
      this.$emit("currentValue", { name, ele: this.$refs[name] });
    },
    handleCurrentData(name) {
      this.$emit("currentValue", name);
    },
   
    currentValue() {
      // console.log(this.$refs);
      // let selected_values = [...document.querySelectorAll('.hlx-tree-select>li .label-wrapper.selected-tree')]
      // // console.log(selected_values);
      // if(selected_values.length > 0){
      //     selected_values.forEach(el => {
      //       el.classList.remove('selected-tree')
      //       el.parentElement.classList.remove('current-selected-tree')
      //       })
      // }
      // this.$refs[data.name].classList.add('selected-tree')
      // this.$refs[data.name].parentElement.classList.add('current-selected-tree')
      // this.$emit('currentValue',data)
      // console.log(data, "data",$event.target);
    },
    handleAdd(data) {
      this.$emit("add", data);
    },
    handleEdit(data) {
      this.$emit("edit", data);
    },
    handleDelete(data) {
      this.$emit("delete", data);
    },
  },
};
</script>
