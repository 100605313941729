<template>

    <h1 class="component-heading">Multi-select</h1>
  <p class="component-description">
    The multi-select component collects user-provided information from a list of
    options. This is also supports an in-built search feature.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    To specify input for the multi-select<b> :options</b><br />
    To display custom placeholder, add <b>:placeholder-value</b> attribute which
    takes in a string value <br />
    You can also toggle label animation to On/Off by setting
    <b>:label-animation</b> attribute to <b>true</b>
    The selected value can be accessed from the
    <b>v-model:value</b> attribute<br />
    The selected name can be accessed from the
    <b>v-model:name</b> attribute<br />
  </p><br>
  <div class="component-example">
    <div class="example-body">
      <span v-if="dropdown == true" :style="'margin-bottom:10px'">
        <hlx-multi-select
      :required="false"
      :options="dropdown_data1"
      :tooltipposition="'left'"
      :placeholder="'Language'"
      :label-animation="true"
      :prop-value="'value'"
      :scroll-classname="'component-body'"
      :label="'name'"
      :disabled="false"
      :filter-select="false"
      :error="errorval"
      :custom-error="errorvalue"
      @custom-error-emit-value="checkval1"
      @add-data="addruleset"
      @selected-array="selectedval"
    ></hlx-multi-select>
     </span>
    </div>
    <div class="example-footer">
      <span
        id="basic-select-icon"
        class="icon"
        @click="showCode('basic-select')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-select" class="source-code" style="display: none" >
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Pre-populate select</h2>
    <p>
    Pre-populate the select with value of the option using the <b>:pre-value</b>
    attribute
  </p>
  <div class="component-example">
    <div class="example-body">
      <span v-if="dropdown == true" :style="'margin-bottom:10px'">
        <hlx-multi-select
      :required="false"
      :pre-value="pre_value.name"
      :options="dropdown_data"
      :tooltipposition="'left'"
      :placeholder="'Language'"
      :label-animation="true"
      :prop-value="'value'"
      :scroll-classname="'component-body'"
      :label="'name'"
      :disabled="false"
      :filter-select="false"
      :error="errorval"
      :custom-error="errorvalue"
      @custom-error-emit-value="checkval1"
      @add-data="addruleset"
      @selected-array="selectedval"
    ></hlx-multi-select>
     </span>
    </div>
    <div class="example-footer">
      <span
        id="basic-select-pre-value-icon"
        class="icon"
        @click="showCode('basic-select-pre-value')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-select-pre-value" class="source-code" style="display: none" >
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_select_pre_value"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>




  <divider :position="'horizontal'" :space="'20px'" />
  <!-- <hlx-table-component :tableData="this.data.table_data" :theader="this.data.columns" :border="true"/> -->
  <h2 class="component-side-heading">Select attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in alertAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in alertAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Events</h2>

<hlx-table
  :column-count="3"
  :border="['table', 'header', 'vertical', 'horizontal']"
  :bold-headers="false"
  :row-hover="false"
  theme="grey"
  :striped-rows="false"
>
  <h2 class="component-side-heading">Events</h2>
  <template #thead>
    <hlx-table-head
      v-for="(i, index) in selectevents"
      :key="index"
      :width="i.width"
      >{{ i.label }}</hlx-table-head
    >
  </template>
  <template #tbody>
    <tr v-for="(i, index) in select_events" id="" :key="index">
      <hlx-table-cell
        v-for="(j, col_index) in selectevents"
        :key="col_index"
        :align="'left'"
      >
        {{ i[j.prop] }}
      </hlx-table-cell>
    </tr>
  </template>
</hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'select')"
      ><i class="icon-angle-left-regular prev-icon"></i>Select</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'treeSelect')"
        >Tree select <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import HlxMultiSelect from "../components/MultiSelectComponent.vue";
import hlxDivider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";


// import axios from "axios";

export default {
  name: "MultiSelect",
  components: {
    HlxMultiSelect,
    hlxDivider,
    CodeEditor,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
  },
  data() {
    return {
      dropdown_data1:[  { name: "Php", value: "Php", id: 1 },
        { name: "VueJs", value: "VueJs", id: 2 },
        { name: "ReactJs", value: "ReactJs", id: 3 },
        { name: "Html", value: "Html", id: 4 },
        { name: "Css", value: "Css", id: 5 },
        { name: "Scss", value: "Scss", id: 6 },
        { name: "Sass", value: "Sass", id: 7 },
        { name: "Sql", value: "Sql", id: 8 },
        { name: "MongoDB", value: "MongoDB", id: 9 },
        { name: "Javascript", value: "Javascriptq", id: 10 }],
      daata:false,
      editor_theme:'light',
      pre_value:{},
      dropdown_data: [
      
      ],
      alertAttributes: [
        {
          prop: "attribute",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],  
      selectevents: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameters",
          label: "Parameters",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          attribute: "options",
          description:
            "Pass the list of items to be added in the dropdown list",
          type: "String",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "pre-value",
          description: "Pre-populate data in multi-select ",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "label",
          description: "The value or name to be displayed for options",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "prop-value",
          description: "Refers to the value to which the options points",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "disabled",
          description: "Disable the dropdown",
          type: "String",
          accepted_values: "true/false",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "error",
          description: "whether to show the error or not",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "custom-error",
          description: "To display the error value",
          type: "String",
          accepted_values: "-",
          default: "",
          mandatory: false,
        },
        {
          attribute: "Placeholder",
          description: "To display the placeholder value",
          type: "String",
          accepted_values: "-",
          default: "",
          mandatory: false,
        },
        {
          attribute: "tooltipposition",
          description: "The disabled value it will show the tooltip the position right or left",
          type: "String",
          accepted_values: "right/left",
          default: "right",
          mandatory: false,
        },
        {
          attribute: "label-animation",
          description: "Adds animation to the labels whenever the input is focused",
          type: "Boolean",
          accepted_values: "true / false",
          default: false,
          mandatory: false,
        },
      ],
      select_events: [
        {
          name: "custom-change",
          description: "Return all the options from dropdown",
          parameters: "-",
        },
        {
          name: "selected-array",
          description: "Emits the selected value",
          parameters: "-",
        },
        {
          name: "add-data",
          description: "Emit the input value when enter key is pressed",
          parameters: "-",
        },
        {
          name: "custom-error-emit-value",
          description: "Emits the selected array when close the dropdown",
          parameters: "-",
        },
      ],
      basic_select:`<template>
                    <hlx-multi-select
                    :required="false"
                    :options="dropdown_data"
                    :tooltipposition="'left'"
                    :placeholder="'Language'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'name'"
                    :disabled="false"
                    :error="show"
                    :custom-error="errorvalue"
                    @custom-error-emit-value="errorHandler"
                    @selected-array="selectedValue"
                  ></hlx-multi-select>
                  </template>
                  export default {
                   data() {
                      return{
                        pre_value: ["Sql", "ReactJs"],
                        dropdown_data: [
                        { name: "Php", value: "Php", id: 1 },
                        { name: "VueJs", value: "VueJs", id: 2 },
                        { name: "ReactJs", value: "ReactJs", id: 3 },
                        { name: "Html", value: "Html", id: 4 },
                        { name: "Css", value: "Css", id: 5 },
                        { name: "Scss", value: "Scss", id: 6 },
                        { name: "Sass", value: "Sass", id: 7 },
                        { name: "Sql", value: "Sql", id: 8 },
                        { name: "MongoDB", value: "MongoDB", id: 9 },
                        { name: "Javascript", value: "Javascriptq", id: 10 },
                        { name: "Csaas", value: "Cdsss", id: 5 },
                        { name: "Scfdsss", value: "Scssws", id: 6 },
                        { name: "Sassas", value: "Sawass", id: 7 },
                        { name: "Sewaql", value: "wdxSql", id: 8 },
                        { name: "MowsangoDB", value: "MongofedsaDB", id: 9 },
                        { name: "Javascripdesxt", value: "Javascridesptq", id: 10 },
                      ]
                      }
                },
                methods:{
                  errorHandler(data)
                  {
                    if(data.length == 0)
                    {
                      this.show = true
                      this.errorvalue = "please fill the field"
                    }
                    else{
                      this.show = "false"
                      this.errorvalue = ""
                    }
                  }
                }
                }   
        `,
        basic_select_pre_value:`<template>
                    <hlx-multi-select
                    :pre-value="prevalue"
                    :required="false"
                    :options="dropdown_data"
                    :placeholder="'Language'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'name'"
                    @selected-array="selectedValue"
                  ></hlx-multi-select>
                  </template>
                  export default {
                   data() {
                      return{
                        pre_value: ["Sql", "ReactJs","MongoDB","Html","VueJs","Php","Css"],
                        dropdown_data: [
                        { name: "Php", value: "Php", id: 1 },
                        { name: "VueJs", value: "VueJs", id: 2 },
                        { name: "ReactJs", value: "ReactJs", id: 3 },
                        { name: "Html", value: "Html", id: 4 },
                        { name: "Css", value: "Css", id: 5 },
                        { name: "Scss", value: "Scss", id: 6 },
                        { name: "Sass", value: "Sass", id: 7 },
                        { name: "Sql", value: "Sql", id: 8 },
                        { name: "MongoDB", value: "MongoDB", id: 9 },
                        { name: "Javascript", value: "Javascriptq", id: 10 },
                        { name: "Csaas", value: "Cdsss", id: 5 },
                        { name: "Scfdsss", value: "Scssws", id: 6 },
                        { name: "Sassas", value: "Sawass", id: 7 },
                        { name: "Sewaql", value: "wdxSql", id: 8 },
                        { name: "MowsangoDB", value: "MongofedsaDB", id: 9 },
                        { name: "Javascripdesxt", value: "Javascridesptq", id: 10 },
                      ]
                      }
                },
                methods:{
                  
                }
                }   
        `,
    errorval :false,
    errorvalue:'',
    dropdown:true
    };
  },
  async mounted() {
    setTimeout(() => {
      this.pre_value = {name: ["Sql", "ReactJs","MongoDB","Html","VueJs","Php","Css"]}
    }, 5);
    setTimeout(() => {
            // this.dropdown = true
      this.dropdown_data = [  { name: "Php", value: "Php", id: 1 },
        { name: "VueJs", value: "VueJs", id: 2 },
        { name: "ReactJs", value: "ReactJs", id: 3 },
        { name: "Html", value: "Html", id: 4 },
        { name: "Css", value: "Css", id: 5 },
        { name: "Scss", value: "Scss", id: 6 },
        { name: "Sass", value: "Sass", id: 7 },
        { name: "Sql", value: "Sql", id: 8 },
        { name: "MongoDB", value: "MongoDB", id: 9 },
        { name: "Javascript", value: "Javascriptq", id: 10 }]
    }, 4);
  },
  methods: {
    selectedddd(val)
    {
       console.log(val);
    },
    //     checkval1(data1) {
    //   // console.log(data1);
    //   // if (data1.length == 0) {
    //   //   this.errorvalue = "please fill the field";
    //   //   this.errorval = true
    //   // } else {
    //   //   this.errorval = false
    //   //   this.errorvalue = "";
    //   // }
    //   //  console.log(data1,'ofsijiugyi');  //valuedata
    // },
    visualrule1(val)
    {
        console.log(val,'val');
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    selectedvalue1(val,val1)
    {
         console.log(val,val1);
    },
    selectedval(val)
    {
      console.log(val,'');
      if(val.length == 0)
      {
        this.errorval = true
         this.errorvalue = "please fill the field"
      }
      else{
        this.errorval = "false"
        this.errorvalue = ""
      }
      // let data=[]
      // val.forEach((e)=>{
      //     //  if(e.checked == true)
      //     //  {
      //       data.push(e.value)
      //     //  }
      // })
      // this.pre_value.name = data
    },
    showCode(val) {
      console.log(val,"dsa")
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    addruleset(data1)
    {
      console.log(data1);
      setTimeout(() => {
        // data2.push(data1)
        this.dropdown_data.push(data1)
      }, 5000);
    }
  },
};
</script>

<style></style>
