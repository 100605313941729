<template>
  <h1 class="component-heading">Timeline</h1>
  <p class="component-description">
    The most effective technique to present chronological events in a precise
    and organized manner is through a timeline.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h1 class="component-side-heading">Basic usage</h1>
  <p class="component-description">
    Set the direction of flow for your timeline view to either vertical or
    horizontal using the <b>:view</b> attribute
  </p>
  <!-- <div class="component-example">
    
    <div class="example-body" style="background: #54bd95">
      <hlx-timeline
        :timeline-data="example_data"
        :view="direction"
        :type="'new'"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-timeline-icon"
        class="icon"
        @click="showCode('basic-timeline')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div> -->
  <div id="basic-timeline" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="timeline_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h1 class="component-side-heading">Example</h1>
  <p class="component-description">
    Set the <b>:type</b> attribute to numbered to change the style of timeline
    attribute
  </p>
  <div class="component-example">
    <!-- <button class="direction1">Change view</button> -->
    <!-- <div class="btn">     <hlx-button class="default border"  @click="myFunction()" style="width:120px;"
        >Change view</hlx-button></div> -->
    <div class="example-body" style="gap: 0px !important">
      <!-- <hlx-timeline
        :timeline-data="example_data1"
        :view="'vertical'"
        :type="'regular'"
      /> -->
      <div style="overflow-y: auto; height: 200px">
        <hlx-timeline
          v-for="(i, index) in options"
          :key="index"
          :timeline-data="example_data1"
          :view="'vertical'"
          :type="'slot'"
          :options="options"
          :curr-index="index"
          :total-length="options.length"
          :data-length="options.length"
          @accordion-open="open(index)"
          @accordion-close="close(index)"
        >
          <template #icon>
            <i class="icon-plus-regular"></i>
          </template>
          <template #first-slot>
            <p>The Salesperson at Hitachi Toronto - logged a email.</p>
          </template>
          <template #title>
            <span  :id="'accordion'+index" class="accordionAdd">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Reiciendis obcaecati, magnam earum tenetur ex sequi consequuntur
              sapiente reprehenderit voluptatem saepe accusamus labore
              cupiditate maxime repudiandae perferendis! Inventore nihil
              blanditiis harum. Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Omnis ex enim officia cum nisi quos eius
              recusandae ducimus ipsam numquam, adipisci voluptatibus asperiores
              tenetur, perspiciatis hic quisquam maiores deleniti. Facere.</span>
          </template>
          <template #footer>
            <!-- <div v-if="i['filesArray'].length > 0" class="container-box"> -->
            <div v-if="i?.filesArray?.length > 0" class="container-box">
              <div class="uplod_inside_email">
                <div :id="'wrapper'+index" ref="containerBox" class="content-wrapper ">
                  <div
                    v-for="(item, col_index) in i?.filesArray"
                    :key="col_index"
                    class="object-name"
                  >
                    <hlx-tag
                      :limited="false"
                      :class="normal"
                      @tag-close="handleClose(tagValueTo, col_index)"
                    >
                      <div style="display: flex; gap: 8px; align-items: center">
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'txt'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/text.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'docx'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/word.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'png'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/png.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'pdf'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/pdf.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'json'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/json.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'jpg'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/jpg.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'jpeg'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/jpeg.svg"
                            alt=""
                        /></span>
                        <span
                          v-if="
                            getFileExtension(item.properties.name) === 'xlsx'
                          "
                          style="height: 16px"
                          ><img
                            class="file-icon"
                            src="@/assets/svg/multiUpload/excel.svg"
                            alt=""
                        /></span>

                        <span>{{ item.properties.name }}</span>
                      </div></hlx-tag
                    >
                  </div>


                </div>
                <p 
                  v-if="i['overflowCount'] > 0"
                  :id="'overflow-count' + index"
                  class="overflow-count"
                  @click="overflowCountHidden(index)"
                >
                  + {{ i["overflowCount"] }}
                </p>
              </div>
            </div>
          </template>
        </hlx-timeline>
      </div>
    </div>
    <div class="example-footer">
      <span
        id="basic-timelinee-icon"
        class="icon"
        @click="showCode('basic-timelinee')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="basic-timelinee" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="timeline_source1"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'object-viewer')"
      ><i class="icon-angle-left-regular prev-icon"></i>Object viewer</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'breadcrumb')"
      >Breadcrumb <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>

<script>
import hlxTimeline from "../components/TimelineComponent.vue";
import divider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxTag from "../components/TagComponent.vue";
// import hlxButton from "../components/ButtonComponent.vue";

export default {
  name: "App",
  components: {
    hlxTimeline,
    divider,
    CodeEditor,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxTag,
    // hlxButton
  },
  data() {
    return {
      overflowCount: 0,
      // filesArray: [
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      //   { properties: { name: "8 photos added.jpeg", size: 283924 } },
      // ],
      options: [
        {
          filesArray: [
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
          ],
        },
        {
          filesArray: [
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
          ],
        },
        {
          filesArray: [
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
          ],
        },
        {
          filesArray: [
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
          ],
        },
        {
          filesArray: [
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
          ],
        },
        {
          filesArray: [
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
            { properties: { name: "8 photos added.jpeg", size: 283924 } },
          ],
        },
      ],
      event_data: [
        {
          name: "emitData",
          description: "emits the chosen data",
          parameter: "",
        },
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      editor_theme: "light",
      table_data: [
        {
          name: "timeline-data",
          description: "Include your data for timeline",
          type: "Array of objects",
          accepted_values: "[{}]",
          default: "-",
          mandatory: true,
        },
        {
          name: "view",
          description: "Choose the direction of flow for your timeline",
          type: "String",
          accepted_values: "horizontal/vertical",
          default: "-",
          mandatory: true,
        },
        {
          name: "type",
          description: "Choose the type of timeline",
          type: "String",
          accepted_values: "regular/numbered",
          default: "-",
          mandatory: true,
        },
      ],
      // example_data:[
      //         {
      //           "title":"Header1",
      //           "description" : "Sample description",
      //           "subtitle":"Subheader1",
      //           "active":true,
      // // "visited":true
      //         },
      //         {
      //           "title":"Header2",
      //           "description" : "Sample description",
      //           "subtitle":"Subheader2",
      //           "active":false,
      // // "visited":true

      //         },
      //         {
      //           "title":"Header3",
      //           "description" : "Sample description",
      //           "subtitle":"Subheader3",
      //           "active":false
      //         },
      //         {
      //           "title":"Header4",
      //           "description" : "Sample description",
      //           "subtitle":"Subheader4",
      //           "active":false
      //         },
      //         {
      //           "title":"Header5",
      //           "description" : "Sample description",
      //           "subtitle":"Subheader5",
      //           "active":false
      //         }
      //       ],
      example_data: [
        {
          title: "1. Business information",

          description: "Sample description",
          disabled: true,

          // "subtitle":"Subheader1",

          active: false,

          visited: true,
        },

        {
          title: "2. Equipment and installation",

          description: "Sample description",
          disabled: false,
          // "subtitle":"Subheader2",

          active: true,

          visited: false,
        },
        {
          title: "3. Programming",

          description: "Sample description",

          // "subtitle":"Subheader3",
          disabled: false,
          active: false,

          visited: false,
        },
        {
          title: "4. Review",

          description: "Sample description",
          disabled: false,
          // "subtitle":"Subheader4",

          active: false,

          visited: false,
        },
        {
          title: "5. Confirmation",

          description: "Sample description",
          disabled: false,
          // "subtitle":"Subheader5",

          active: false,

          visited: false,
        },
      ],
      unique :"",
      example_data1: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: false,
          visited: true,
        },
        {
          title: "Choose plan",
          description: "",
          subtitle: "Subheader2",
          active: true,
        },
        {
          title: "Choose payment method",
          description: "Sample description",
          subtitle: "Subheader3",
          active: false,
        },
        // {
        //   "title":"choose payment method",
        //   "description" : "Sample description",
        //   "subtitle":"Subheader3",
        //   "active":false
        // },
        // {
        //   "title":"Header4",
        //   "description" : "Sample description",
        //   "subtitle":"Subheader4",
        //   "active":false
        // },
        // {
        //   "title":"Header5",
        //   "description" : "Sample description",
        //   "subtitle":"Subheader5",
        //   "active":false
        // }
      ],
      timeline_source: `
<template>
<hlx-timeline :timeline_data="this.example_data" :view = "'vertical'" :type = "'regular'"/>
</template>

<script>
export default{
    data(){
        return{
          [
        {
          "title":"Header1",
          "description" : "Sample description",
          "subtitle":"Subheader1",
          "active":false,
          "visited":true
        },
        {
          "title":"Header2",
          "description" : "Sample description",
          "subtitle":"Subheader2",
          "active":false,
          "visited":true
          
        },
        {
          "title":"Header3",
          "description" : "Sample description",
          "subtitle":"Subheader3",
          "active":true,
          "visited":false
          
        },
        {
          "title":"Header4",
          "description" : "Sample description",
          "subtitle":"Subheader4",
          "active":false,
          "visited":false
        },
        {
          "title":"Header5",
          "description" : "Sample description",
          "subtitle":"Subheader5",
          "active":false,
          "visited":false
        }
      ]

        }
    }
}
}
</\script>`,
      timeline_source1: `
<template>
<hlx-timeline :timeline_data = "this.example_data" :view = "'horizontal'" :type = "'numbered'"/>
</template>

<script>
export default{
    data(){
        return{
          [
        {
          "title":"Header1",
          "description" : "Sample description",
          "subtitle":"Subheader1",
          "active":false,
          "visited":true
        },
        {
          "title":"Header2",
          "description" : "Sample description",
          "subtitle":"Subheader2",
          "active":false,
          "visited":true
          
        },
        {
          "title":"Header3",
          "description" : "Sample description",
          "subtitle":"Subheader3",
          "active":true,
          "visited":true
        },
        {
          "title":"Header4",
          "description" : "Sample description",
          "subtitle":"Subheader4",
          "active":false,
          "visited":true
        },
        {
          "title":"Header5",
          "description" : "Sample description",
          "subtitle":"Subheader5",
          "active":false,
          "visited":false
        }
      ]

        }
    }
}
}
</\script>`,
      direction: "horizontal",
    };
  },
  watch: {
    // filesArray: {
    //     handler(val) {
    //       console.log(val, val.length, "filesArray");
    //       this.filesArray = val;
    //       this.checkOverflow();
    //     },
    //     immediate: true,
    //     deep: true,
    //   },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkOverflow);
  },
  mounted() {
    this.unique = Math.random() * 1000;
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
  },
  methods: {
    open(index){
      console.log('open')
      document.getElementById('accordion'+index).classList.remove('accordionAdd') 
    },
    close(index){
      console.log('close')
      document.getElementById('accordion'+index).classList.add('accordionAdd') 
    },
 overflowCountHidden(index) {
  const element = document.getElementById('overflow-count' + index);
  if (element) {
    element.style.display = 'none';
  }

  const wrapper = document.getElementById('wrapper' + index);

  if (!this.options[index].showOverflowCount) {
    wrapper.classList.add('wrap');
  } else {
    wrapper.classList.remove('wrap');
  }
}


,
  
    handleClose(val, index) {
      this.filesArray.splice(index, 1);

      this.checkOverflow();
      console.log(val, index);
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    myFunction() {
      if (this.direction == "vertical") {
        this.direction = "horizontal";
      } else if (this.direction == "horizontal") {
        this.direction = "vertical";
      }
    },
    checkOverflow() {
      // if (this.filesArray.length > 0) {
        console.log(document.querySelectorAll(".content-wrapper"),"document.querySelectorAll");
      document.querySelectorAll(".content-wrapper").forEach((e, index) => {
        const container = e;
        console.log(container, "container");
        if (!container) {
          return;
        }
        this.$nextTick(() => {
          const horizontalOverflow =
            container.scrollWidth > container.offsetWidth;
          const verticalOverflow =
            container.scrollHeight > container.offsetHeight;

          console.log(
            horizontalOverflow,
            verticalOverflow,
            container.scrollWidth,
            container.offsetWidth,
            container.scrollWidth > container.offsetWidth,
            container.scrollHeight > container.offsetHeight
          );

          if (horizontalOverflow || verticalOverflow) {
            const horizontalCount = horizontalOverflow
              ? Math.ceil(
                  ((container?.scrollWidth - container?.offsetWidth) /
                    container?.scrollWidth) *
                    this.options[index]?.filesArray?.length
                )
              : 0;

            const verticalCount = verticalOverflow
              ? Math.ceil(
                  ((container?.scrollHeight - container?.offsetHeight) /
                    container?.scrollHeight) *
                    this.options[index]?.filesArray?.length
                )
              : 0;
            this.options[index]["overflowCount"] = Math.max(
              horizontalCount,
              verticalCount
            );
            console.log(
              Math.max(horizontalCount, verticalCount),
              "paoaa",
              this.options
            );
            this.overflowCount = Math.max(horizontalCount, verticalCount);
          } else {
            this.overflowCount = 0;
          }
        });
        console.log(this.overflowCount, "col_index");
      });
      // }
    },
    getFileExtension(filename) {
      const parts = filename.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
  },
};
</script>

<style lang="scss">
.uplod_inside_email{
  display: flex;

}
.container-box {
  padding: 12px 20px;
  max-width: 100%;
  /* border: 1px solid #ccc; */
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}
.wrap {
  flex-wrap: wrap;
}
.content-wrapper {
  max-width: 800px;
  max-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.object-name {
  margin-right: 10px;

  .icon-times-regular {
    color: #a6a6a6;
    display: none;
  }
  .hlx-tag {
    border: 1px solid #d4d4d4;
    color: #000000;
    background: none;
    min-width: 150px;
    .file-icon {
      height: 16px;
    }
    &:hover {
      cursor: default;
      background: none;

      border: 1px solid #d4d4d4;
    }
  }
}

.overflow-count {
  cursor: pointer;
  width: fit-content;
  background-color: rgb(255, 255, 255);
  padding: 5px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}


.accordionAdd{
  white-space: nowrap; 
  width: 700px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
</style>
