<template>
  <br />
  {{ view }}

  <hlx-tree-v2
    :node="treedata"
    :first-card-name="'all product'"
    :tree-direction="tree_direction"
    :zoomable="true"
    :zoom="zoom"
    :type="Hierarchy"
    :viewOnly="false"
    :isDraggable="true"
    :customer-facing-service-option="dropdown2data1"
    :resource-facing-service-option="dropdown2data2"
    :physical-resource-option="dropdown2data3"
    :logical-resource-option="dropdown2data4"
    @zoomIn="zoomin"
    @zoomOut="zoomout"
  >
  </hlx-tree-v2>
</template>
<script>
import hlxTreeV2 from "../components/TreeVersion2Component.vue";

export default { 
  components: {
    hlxTreeV2,
    // hlxCard,
  },
  data() {
    return {
      // view: true,
      type: "Product",
      tree_direction: "vertical",
      zoom: 100,
      treedata: {
        service: {
          icones: "icon-box-check-regular tree-icone3",
          name: "service",
          value: "service",
          data: {},
        },
        specification: {
          name: "all product",
        },
      },
      dup_dropdown2data1: [],
      dup_dropdown2data2: [],
      dup_dropdown2data3: [],
      dup_dropdown2data4: [],
      dropdown2data1: [
        {
          name: "IoT Soluvec Ltd",
          description:
            "This service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segment",
          lifecycleStatus: "Rejected",
          serviceSpecCharacteristic: [
            { name: "Resource Interaction " },
            { name: "Resource Abstraction" },
            { name: "Resource Lifecycle Management" },
          ],
        },
        {
          name: "E-commerce Website",
          description:
            "This service catalog entry represents our customer-facing e-commerce website.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Customer Support (Email)",
          description:
            "This service catalog entry covers customer support via email for our clients.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Mobile App",
          description:
            "This service catalog entry represents our customer-facing mobile application.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "In-Person Consultation",
          description:
            "This service catalog entry includes in-person consultations with our clients.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Customer Loyalty Program",
          description:
            "This service catalog entry outlines our customer-facing loyalty program.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Live Chat Support",
          description:
            "This service catalog entry provides customer support via live chat on our website.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Customer Feedback Portal",
          description:
            "This service catalog entry covers our customer-facing feedback portal.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Social Media Engagement",
          description:
            "This service catalog entry represents our customer-facing social media engagement activities.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Online Booking System",
          description:
            "This service catalog entry covers our customer-facing online booking system.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Customer Self-Serice Portal",
          description:
            "This service catalog entry represents our customer-facing self-service portal.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Product Demo Workshops",
          description:
            "This service catalog entry covers our customer-facing product demonstration workshops.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Virtual Tours",
          description:
            "This service catalog entry represents our customer-facing virtual tours of our facilities.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Customer Webinars",
          description:
            "This service catalog entry covers our customer-facing educational webinars.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Product Customization",
          description:
            "This service catalog entry represents our customer-facing product customization options.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Customer Onboarding",
          description:
            "This service catalog entry covers our customer-facing onboarding process for new clients.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
      dropdown2data2: [
        {
          name: "Database Management",
          description:
            "This service catalog entry represents our resource-facing database management service.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Server Monitoring",
          description:
            "This service catalog entry covers our resource-facing server monitoring and maintenance.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Network Security",
          description:
            "This service catalog entry represents our resource-facing network security measures.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Data Backup and Recovery",
          description:
            "This service catalog entry covers resource-facing data backup and recovery processes.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Hardware Procurement",
          description:
            "This service catalog entry represents our resource-facing hardware procurement process.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Cloud Resource Allocation",
          description:
            "This service catalog entry covers resource-facing cloud resource allocation and management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "IT Infrastructure Planning",
          description:
            "This service catalog entry represents our resource-facing IT infrastructure planning service.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "License Management",
          description:
            "This service catalog entry covers resource-facing software license management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Data Center Operations",
          description:
            "This service catalog entry represents resource-facing data center operations and maintenance.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Vendor Management",
          description:
            "This service catalog entry covers resource-facing vendor and supplier management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Capacity Planning",
          description:
            "This service catalog entry represents resource-facing capacity planning for IT resources.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Network Configuration",
          description:
            "This service catalog entry covers resource-facing network configuration and optimization.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Asset Inventory Management",
          description:
            "This service catalog entry represents resource-facing asset inventory tracking.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Software Patch Management",
          description:
            "This service catalog entry covers resource-facing software patching and updates.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Server Virtualization",
          description:
            "This service catalog entry represents resource-facing server virtualization and management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
      dropdown2data3: [
        {
          name: "Silver Loyalty Program",
          description:
            "Exclusive benefits for loyal customers at the silver level.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Gold Rewards Membership",
          description: "Our premium loyalty program with gold-level benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Bronze Loyalty Tier",
          description: "Entry-level loyalty program with bronze-tier perks.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Platinum Rewards Club",
          description:
            "Our top-tier loyalty program for platinum-level members.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Frequent Shopper Program",
          description:
            "Earn rewards for frequent shopping with our loyalty program.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Exclusive VIP Loyalty",
          description: "VIP loyalty program for exclusive customers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Youth Loyalty Program",
          description:
            "Loyalty program designed for our younger customer base.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Family Rewards Membership",
          description:
            "Loyalty program with family-oriented benefits and offers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Travel Enthusiast Program",
          description: "Loyalty program for customers passionate about travel.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Digital Rewards Club",
          description:
            "Loyalty program with digital-exclusive rewards and offers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Sports Fan Loyalty",
          description:
            "Loyalty program for sports enthusiasts with exclusive sports-related benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Green Eco Loyalty",
          description:
            "Loyalty program for environmentally conscious customers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Tech Enthusiast Rewards",
          description:
            "Loyalty program for tech-savvy customers with tech-related rewards.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Health and Wellness Club",
          description:
            "Loyalty program promoting health and wellness with exclusive benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Gourmet Foodie Loyalty",
          description:
            "Loyalty program for food enthusiasts with gourmet-related perks.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Art and Culture Rewards",
          description:
            "Loyalty program for customers interested in art and culture.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Pet Lover Loyalty",
          description: "Loyalty program for customers who love their pets.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Fashionista Rewards",
          description:
            "Loyalty program for fashion-forward customers with exclusive fashion benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Bookworm Loyalty Club",
          description: "Loyalty program for avid readers and book enthusiasts.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
      dropdown2data4: [
        {
          name: "Adventure Seeker Rewards",
          description:
            "Loyalty program for customers seeking adventurous experiences.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Home Decor Enthusiast Club",
          description:
            "Loyalty program for customers passionate about home decor.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Outdoor Fitness Rewards",
          description:
            "Loyalty program for fitness enthusiasts who prefer outdoor activities.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "DIY Project Masters",
          description:
            "Loyalty program for customers who love do-it-yourself (DIY) projects.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Car Enthusiast Rewards",
          description:
            "Loyalty program for customers passionate about cars and automotive culture.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Tech and Gaming Elite",
          description:
            "Loyalty program for customers at the intersection of technology and gaming.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Culinary Connoisseur Club",
          description:
            "Loyalty program for customers with a passion for gourmet cuisine.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Wellness and Mindfulness Rewards",
          description:
            "Loyalty program promoting wellness and mindfulness practices.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Social Media Maven Club",
          description:
            "Loyalty program for customers who are influential on social media.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Smart Home Innovators",
          description:
            "Loyalty program for customers embracing smart home technologies.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Fitness Challenge Champions",
          description:
            "Loyalty program for customers participating in fitness challenges.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Travel Photography Enthusiasts",
          description:
            "Loyalty program for customers passionate about travel photography.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Nature Explorer Rewards",
          description:
            "Loyalty program for customers exploring and appreciating nature.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Fitness Tech Innovators",
          description:
            "Loyalty program for customers adopting cutting-edge fitness technologies.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Gaming Community Rewards",
          description:
            "Loyalty program for customers actively engaged in gaming communities.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Mindful Parenting Loyalty",
          description:
            "Loyalty program for parents dedicated to mindful parenting practices.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Virtual Reality Enthusiasts Club",
          description:
            "Loyalty program for customers exploring virtual reality experiences.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Green Thumb Rewards",
          description:
            "Loyalty program for gardening enthusiasts with green thumbs.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Educational Explorer Club",
          description:
            "Loyalty program for customers dedicated to continuous learning and education.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
    };
  },
  watch: {
    tree_direction: function () {
      this.tree_direction;
    },
  },
  methods: {
    fromAddContextMenuHierarchy(val, node) {
      console.log(val, node, "contxt");
    },
    dataReceived(val) {
      console.log(val);
      const payload = val;
      const result = this.extractSpecifications(payload);

      console.log(
        "Customer Facing Service Specifications:",
        result.customerFacingSpecifications
      );
      console.log(
        "Resource Facing Service Specifications:",
        result.resourceFacingSpecifications
      ); 

      this.dup_dropdown2data1 = this.removeMatchingItems(
        result.customerFacingSpecifications,
        this.dropdown2data1
      );
      this.dup_dropdown2data2 = this.removeMatchingItems(
        result.resourceFacingSpecifications,
        this.dropdown2data2
      );
      this.dup_dropdown2data3 = this.removeMatchingItems(
        result.LogicalResourceSpecification,
        this.dropdown2data3
      );
      this.dup_dropdown2data4 = this.removeMatchingItems(
        result.PhysicalResourceSpecification,
        this.dropdown2data4
      );
    },
    extractSpecifications(payload) {
      const customerFacingSpecifications = [];
      const resourceFacingSpecifications = [];
      const PhysicalResourceSpecification = [];
      const LogicalResourceSpecification = [];
      function traverse(obj) {
        if (obj && obj.service) {
          if (obj.service.name === "Customer Facing Service") {
            customerFacingSpecifications.push(obj.specification);
          } else if (obj.service.name === "Resource Facing Service") {
            resourceFacingSpecifications.push(obj.specification);
          } else if (obj.service.name === "Logical Resource Specification") {
            LogicalResourceSpecification.push(obj.specification);
          } else if (obj.service.name === "Physical Resource Specification") {
            PhysicalResourceSpecification.push(obj.specification);
          }
        }

        if (obj && obj.child && Array.isArray(obj.child)) {
          for (const childObj of obj.child) {
            traverse(childObj);
          }
        }
      }

      traverse(payload);

      return {
        customerFacingSpecifications,
        resourceFacingSpecifications,
        PhysicalResourceSpecification,
        LogicalResourceSpecification,
      };
    },
    removeMatchingItems(receivedData, originalData) {
      return originalData.filter((originalItem) => {
        return !receivedData.some(
          (receivedItem) => receivedItem.name === originalItem.name
        );
      });
    },
    dataNode() {
      // console.log(val, "keerthika");
    },
    zoomin() {
      console.log("zoomin");
      this.zoom += 10;
    },
    zoomout() {
      console.log("zoomout");
      this.zoom -= 10;
    },
  },
};
</script>
