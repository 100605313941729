<template>
    <h1 class="component-heading">Cascader</h1>
  <p class="component-description">
    The select component collects user-provided information from a list of
    options. Selects are usually used in forms where a user submits data and
    chooses one option from a list.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    To specify input for the dropdown<b> :options</b><br />
    To display custom placeholder, add <b>:placeholder-value</b> attribute which
    takes in a string value <br />
    You can also toggle label animation to On/Off by setting
    <b>:label-animation</b> attribute to <b>true</b> To clear the selected value
    use <b>:clearable</b> to <b>true</b><br />
    The selected value can be accessed from the
    <b>v-model:value</b> attribute<br />
    The selected name can be accessed from the
    <b>v-model:name</b> attribute<br />
  </p><br>
  <div class="component-example">
    <div class="example-body">
  <hlx-cascader
  :placeholder-value="'Category'"
  :label="'label'"
  :prop-value="'value'"
  :children="'subCategory'"
  :options="options_data"
  ></hlx-cascader>
  
</div>
    <div class="example-footer">
      <span
        id="basic-select-icon"
        class="icon"
        @click="showCode('basic-select')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-select" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <divider :position="'horizontal'" :space="'20px'" />
  <!-- <hlx-table-component :tableData="this.data.table_data" :theader="this.data.columns" :border="true"/> -->
  <h2 class="component-side-heading">Select attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in alertAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in alertAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>

  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <h2 class="component-side-heading">Events</h2>
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in selectevents"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in select_events" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in selectevents"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'stepper')"
      ><i class="icon-angle-left-regular prev-icon"></i>Step control</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'multiselect')"
      >Multi select <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>

<script>
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import divider from "../components/DividerComponent.vue";
import hlxCascader from '../components/CascaderNewComponent.vue';
import CodeEditor from "simple-code-editor";

export default{
    components:{
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    divider,
    hlxCascader,
    CodeEditor
    },
    data()
    {
      return{
        editor_theme: "light",
        options_data:[
                          {
                            value: 'guide',
                            label: 'Guide',
                            subCategory: [
                              {
                                value: 'disciplines',
                                label: 'Disciplines',
                                subCategory: [
                                  {
                                    value: 'consistency',
                                    label: 'Consistency',
                                  },
                                  {
                                    value: 'feedback',
                                    label: 'Feedback',
                                  },
                                  {
                                    value: 'efficiency',
                                    label: 'Efficiency',
                                  },
                                  {
                                    value: 'controllability',
                                    label: 'Controllability',
                                  },
                                ],
                              },
                              {
                                value: 'navigation',
                                label: 'Navigation',
                                subCategory: [
                                  {
                                    value: 'side nav',
                                    label: 'Side Navigation',
                                  },
                                  {
                                    value: 'top nav',
                                    label: 'Top Navigation',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            value: 'component',
                            label: 'Component',
                            subCategory: [
                              {
                                value: 'basic',
                                label: 'Basic',
                                subCategory: [
                                  {
                                    value: 'layout',
                                    label: 'Layout',
                                  },
                                  {
                                    value: 'color',
                                    label: 'Color',
                                  },
                                  {
                                    value: 'typography',
                                    label: 'Typography',
                                  },
                                  {
                                    value: 'icon',
                                    label: 'Icon',
                                  },
                                  {
                                    value: 'button',
                                    label: 'Button',
                                  },
                                ],
                              },
                              {
                                value: 'form',
                                label: 'Form',
                                subCategory: [
                                  {
                                    value: 'radio',
                                    label: 'Radio',
                                  },
                                  {
                                    value: 'checkbox',
                                    label: 'Checkbox',
                                  },
                                  {
                                    value: 'input',
                                    label: 'Input',
                                  },
                                  {
                                    value: 'input-number',
                                    label: 'InputNumber',
                                  },
                                  {
                                    value: 'select',
                                    label: 'Select',
                                  },
                                  {
                                    value: 'cascader',
                                    label: 'Cascader',
                                  },
                                  {
                                    value: 'switch',
                                    label: 'Switch',
                                  },
                                  {
                                    value: 'slider',
                                    label: 'Slider',
                                  },
                                  {
                                    value: 'time-picker',
                                    label: 'TimePicker',
                                  },
                                  {
                                    value: 'date-picker',
                                    label: 'DatePicker',
                                  },
                                  {
                                    value: 'datetime-picker',
                                    label: 'DateTimePicker',
                                  },
                                  {
                                    value: 'upload',
                                    label: 'Upload',
                                  },
                                  {
                                    value: 'rate',
                                    label: 'Rate',
                                  },
                                  {
                                    value: 'form',
                                    label: 'Form',
                                  },
                                ],
                              },
                              {
                                value: 'data',
                                label: 'Data',
                                subCategory: [
                                  {
                                    value: 'table',
                                    label: 'Table',
                                  },
                                  {
                                    value: 'tag',
                                    label: 'Tag',
                                  },
                                  {
                                    value: 'progress',
                                    label: 'Progress',
                                  },
                                  {
                                    value: 'tree',
                                    label: 'Tree',
                                  },
                                  {
                                    value: 'pagination',
                                    label: 'Pagination',
                                  },
                                  {
                                    value: 'badge',
                                    label: 'Badge',
                                  },
                                ],
                              },
                              {
                                value: 'notice',
                                label: 'Notice',
                                subCategory: [
                                  {
                                    value: 'alert',
                                    label: 'Alert',
                                  },
                                  {
                                    value: 'loading',
                                    label: 'Loading',
                                  },
                                  {
                                    value: 'message',
                                    label: 'Message',
                                  },
                                  {
                                    value: 'message-box',
                                    label: 'MessageBox',
                                  },
                                  {
                                    value: 'notification',
                                    label: 'Notification',
                                  },
                                ],
                              },
                              {
                                value: 'navigation',
                                label: 'Navigation',
                                subCategory: [
                                  {
                                    value: 'menu',
                                    label: 'Menu',
                                  },
                                  {
                                    value: 'tabs',
                                    label: 'Tabs',
                                  },
                                  {
                                    value: 'breadcrumb',
                                    label: 'Breadcrumb',
                                  },
                                  {
                                    value: 'dropdown',
                                    label: 'Dropdown',
                                  },
                                  {
                                    value: 'steps',
                                    label: 'Steps',
                                  },
                                ],
                              },
                              {
                                value: 'others',
                                label: 'Others',
                                subCategory: [
                                  {
                                    value: 'dialog',
                                    label: 'Dialog',
                                  },
                                  {
                                    value: 'tooltip',
                                    label: 'Tooltip',
                                  },
                                  {
                                    value: 'popover',
                                    label: 'Popover',
                                  },
                                  {
                                    value: 'card',
                                    label: 'Card',
                                  },
                                  {
                                    value: 'carousel',
                                    label: 'Carousel',
                                  },
                                  {
                                    value: 'collapse',
                                    label: 'Collapse',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            value: 'resource',
                            label: 'Resource',
                            subCategory: [
                              {
                                value: 'axure',
                                label: 'Axure Components',
                              },
                              {
                                value: 'sketch',
                                label: 'Sketch Templates',
                              },
                              {
                                value: 'docs',
                                label: 'Design Documentation',
                              },
                            ],
                          },
                        ],
        // preValue:[ "Devices" ],
alertAttributes: [
        {
          prop: "attribute",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
selectevents: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameters",
          label: "Parameters",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      basic_select: `
                <template>
                  <hlx-cascader
                      :options="dropdown_data"
                      :required="true"
                      :placeholder-value="'Category'"
                      :label-animation="true"
                      :label="'name'"                         // Give the key for display value in the options
                      :prop-value="'value'"                   // Give the key for match the value in the options
                      @selected-array="returnSelectedObject"  // It returns the selected object
                    >
                    </hlx-cascader>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          data:{key:'', value:''}
                          options_data:[
                          {
                            value: 'guide',
                            label: 'Guide',
                            subCategory: [
                              {
                                value: 'disciplines',
                                label: 'Disciplines',
                                subCategory: [
                                  {
                                    value: 'consistency',
                                    label: 'Consistency',
                                  },
                                  {
                                    value: 'feedback',
                                    label: 'Feedback',
                                  },
                                  {
                                    value: 'efficiency',
                                    label: 'Efficiency',
                                  },
                                  {
                                    value: 'controllability',
                                    label: 'Controllability',
                                  },
                                ],
                              },
                              {
                                value: 'navigation',
                                label: 'Navigation',
                                subCategory: [
                                  {
                                    value: 'side nav',
                                    label: 'Side Navigation',
                                  },
                                  {
                                    value: 'top nav',
                                    label: 'Top Navigation',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            value: 'component',
                            label: 'Component',
                            subCategory: [
                              {
                                value: 'basic',
                                label: 'Basic',
                                subCategory: [
                                  {
                                    value: 'layout',
                                    label: 'Layout',
                                  },
                                  {
                                    value: 'color',
                                    label: 'Color',
                                  },
                                  {
                                    value: 'typography',
                                    label: 'Typography',
                                  },
                                  {
                                    value: 'icon',
                                    label: 'Icon',
                                  },
                                  {
                                    value: 'button',
                                    label: 'Button',
                                  },
                                ],
                              },
                              {
                                value: 'form',
                                label: 'Form',
                                subCategory: [
                                  {
                                    value: 'radio',
                                    label: 'Radio',
                                  },
                                  {
                                    value: 'checkbox',
                                    label: 'Checkbox',
                                  },
                                  {
                                    value: 'input',
                                    label: 'Input',
                                  },
                                  {
                                    value: 'input-number',
                                    label: 'InputNumber',
                                  },
                                  {
                                    value: 'select',
                                    label: 'Select',
                                  },
                                  {
                                    value: 'cascader',
                                    label: 'Cascader',
                                  },
                                  {
                                    value: 'switch',
                                    label: 'Switch',
                                  },
                                  {
                                    value: 'slider',
                                    label: 'Slider',
                                  },
                                  {
                                    value: 'time-picker',
                                    label: 'TimePicker',
                                  },
                                  {
                                    value: 'date-picker',
                                    label: 'DatePicker',
                                  },
                                  {
                                    value: 'datetime-picker',
                                    label: 'DateTimePicker',
                                  },
                                  {
                                    value: 'upload',
                                    label: 'Upload',
                                  },
                                  {
                                    value: 'rate',
                                    label: 'Rate',
                                  },
                                  {
                                    value: 'form',
                                    label: 'Form',
                                  },
                                ],
                              },
                              {
                                value: 'data',
                                label: 'Data',
                                subCategory: [
                                  {
                                    value: 'table',
                                    label: 'Table',
                                  },
                                  {
                                    value: 'tag',
                                    label: 'Tag',
                                  },
                                  {
                                    value: 'progress',
                                    label: 'Progress',
                                  },
                                  {
                                    value: 'tree',
                                    label: 'Tree',
                                  },
                                  {
                                    value: 'pagination',
                                    label: 'Pagination',
                                  },
                                  {
                                    value: 'badge',
                                    label: 'Badge',
                                  },
                                ],
                              },
                              {
                                value: 'notice',
                                label: 'Notice',
                                subCategory: [
                                  {
                                    value: 'alert',
                                    label: 'Alert',
                                  },
                                  {
                                    value: 'loading',
                                    label: 'Loading',
                                  },
                                  {
                                    value: 'message',
                                    label: 'Message',
                                  },
                                  {
                                    value: 'message-box',
                                    label: 'MessageBox',
                                  },
                                  {
                                    value: 'notification',
                                    label: 'Notification',
                                  },
                                ],
                              },
                              {
                                value: 'navigation',
                                label: 'Navigation',
                                subCategory: [
                                  {
                                    value: 'menu',
                                    label: 'Menu',
                                  },
                                  {
                                    value: 'tabs',
                                    label: 'Tabs',
                                  },
                                  {
                                    value: 'breadcrumb',
                                    label: 'Breadcrumb',
                                  },
                                  {
                                    value: 'dropdown',
                                    label: 'Dropdown',
                                  },
                                  {
                                    value: 'steps',
                                    label: 'Steps',
                                  },
                                ],
                              },
                              {
                                value: 'others',
                                label: 'Others',
                                subCategory: [
                                  {
                                    value: 'dialog',
                                    label: 'Dialog',
                                  },
                                  {
                                    value: 'tooltip',
                                    label: 'Tooltip',
                                  },
                                  {
                                    value: 'popover',
                                    label: 'Popover',
                                  },
                                  {
                                    value: 'card',
                                    label: 'Card',
                                  },
                                  {
                                    value: 'carousel',
                                    label: 'Carousel',
                                  },
                                  {
                                    value: 'collapse',
                                    label: 'Collapse',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            value: 'resource',
                            label: 'Resource',
                            subCategory: [
                              {
                                value: 'axure',
                                label: 'Axure Components',
                              },
                              {
                                value: 'sketch',
                                label: 'Sketch Templates',
                              },
                              {
                                value: 'docs',
                                label: 'Design Documentation',
                              },
                            ],
                          },
                        ],
                      }
                },
                methods:{
                  returnSelectedObject(selectedobj)
                  {
                    console.log(selectedobj)
                  }
                }
                }
      `,
      table_data: [
        {
          attribute: "options",
          description:
            "Pass the list of items to be added in the dropdown list",
          type: "String",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "placeholder-value",
          description: "Pass the placeholder",
          type: "String",
          accepted_values: "enter the placeholder",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "pre-value",
          description: "Pre-populate data in cascade select,rectangle select ",
          type: "Array",
          accepted_values: "['guide','discipline','consistency']",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "label",
          description: "The value or name to be displayed for options",
          type: "String",
          accepted_values: "-",
          default: "name",
          mandatory: false,
        },
        {
          attribute: "prop-value",
          description: "Refers to the value to which the options points",
          type: "String",
          accepted_values: "-",
          default: "value",
          mandatory: false,
        },
        {
          attribute: "label-animation",
          description: "Adds animation to the labels whenever the input is focused",
          type: "Boolean",
          accepted_values: "true / false",
          default: false,
          mandatory: false,
        },
        {
          attribute: "required",
          description: "Whether asterik symbol show or not",
          type: "Boolean",
          accepted_values: "true / false",
          default: false,
          mandatory: false,
        },
      ],
      select_events: [
        {
          name: "selected-array",
          description: "Emits the selected array of objects",
          parameters: "-",
        }
      ],
      }
    },
    mounted()
    {
       setTimeout(() => {
        this.preValue = [
    "navigation",
    "guide",
    "nav"
]
//     this.options_data =    [
//   {
//     value: 'guide',
//     label: 'Guide',
//     subCategory: [
//       {
//         value: 'disciplines',
//         label: 'Disciplines',
//         subCategory: [
//           {
//             value: 'consistency',
//             label: 'Consistency',
//           },
//           {
//             value: 'feedback',
//             label: 'Feedback',
//           },
//           {
//             value: 'efficiency',
//             label: 'Efficiency',
//           },
//           {
//             value: 'controllability',
//             label: 'Controllability',
//           },
//         ],
//       },
//       {
//         value: 'navigation',
//         label: 'Navigation',
//         subCategory: [
//           {
//             value: 'side nav',
//             label: 'Side Navigation',
//           },
//           {
//             value: 'top nav',
//             label: 'Top Navigation',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     value: 'component',
//     label: 'Component',
//     subCategory: [
//       {
//         value: 'basic',
//         label: 'Basic',
//         subCategory: [
//           {
//             value: 'layout',
//             label: 'Layout',
//           },
//           {
//             value: 'color',
//             label: 'Color',
//           },
//           {
//             value: 'typography',
//             label: 'Typography',
//           },
//           {
//             value: 'icon',
//             label: 'Icon',
//           },
//           {
//             value: 'button',
//             label: 'Button',
//           },
//         ],
//       },
//       {
//         value: 'form',
//         label: 'Form',
//         subCategory: [
//           {
//             value: 'radio',
//             label: 'Radio',
//           },
//           {
//             value: 'checkbox',
//             label: 'Checkbox',
//           },
//           {
//             value: 'input',
//             label: 'Input',
//           },
//           {
//             value: 'input-number',
//             label: 'InputNumber',
//           },
//           {
//             value: 'select',
//             label: 'Select',
//           },
//           {
//             value: 'cascader',
//             label: 'Cascader',
//           },
//           {
//             value: 'switch',
//             label: 'Switch',
//           },
//           {
//             value: 'slider',
//             label: 'Slider',
//           },
//           {
//             value: 'time-picker',
//             label: 'TimePicker',
//           },
//           {
//             value: 'date-picker',
//             label: 'DatePicker',
//           },
//           {
//             value: 'datetime-picker',
//             label: 'DateTimePicker',
//           },
//           {
//             value: 'upload',
//             label: 'Upload',
//           },
//           {
//             value: 'rate',
//             label: 'Rate',
//           },
//           {
//             value: 'form',
//             label: 'Form',
//           },
//         ],
//       },
//       {
//         value: 'data',
//         label: 'Data',
//         subCategory: [
//           {
//             value: 'table',
//             label: 'Table',
//           },
//           {
//             value: 'tag',
//             label: 'Tag',
//           },
//           {
//             value: 'progress',
//             label: 'Progress',
//           },
//           {
//             value: 'tree',
//             label: 'Tree',
//           },
//           {
//             value: 'pagination',
//             label: 'Pagination',
//           },
//           {
//             value: 'badge',
//             label: 'Badge',
//           },
//         ],
//       },
//       {
//         value: 'notice',
//         label: 'Notice',
//         subCategory: [
//           {
//             value: 'alert',
//             label: 'Alert',
//           },
//           {
//             value: 'loading',
//             label: 'Loading',
//           },
//           {
//             value: 'message',
//             label: 'Message',
//           },
//           {
//             value: 'message-box',
//             label: 'MessageBox',
//           },
//           {
//             value: 'notification',
//             label: 'Notification',
//           },
//         ],
//       },
//       {
//         value: 'navigation',
//         label: 'Navigation',
//         subCategory: [
//           {
//             value: 'menu',
//             label: 'Menu',
//           },
//           {
//             value: 'tabs',
//             label: 'Tabs',
//           },
//           {
//             value: 'breadcrumb',
//             label: 'Breadcrumb',
//           },
//           {
//             value: 'dropdown',
//             label: 'Dropdown',
//           },
//           {
//             value: 'steps',
//             label: 'Steps',
//           },
//         ],
//       },
//       {
//         value: 'others',
//         label: 'Others',
//         subCategory: [
//           {
//             value: 'dialog',
//             label: 'Dialog',
//           },
//           {
//             value: 'tooltip',
//             label: 'Tooltip',
//           },
//           {
//             value: 'popover',
//             label: 'Popover',
//           },
//           {
//             value: 'card',
//             label: 'Card',
//           },
//           {
//             value: 'carousel',
//             label: 'Carousel',
//           },
//           {
//             value: 'collapse',
//             label: 'Collapse',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     value: 'resource',
//     label: 'Resource',
//     subCategory: [
//       {
//         value: 'axure',
//         label: 'Axure Components',
//       },
//       {
//         value: 'sketch',
//         label: 'Sketch Templates',
//       },
//       {
//         value: 'docs',
//         label: 'Design Documentation',
//       },
//     ],
//   },
// ]
       }, 5000);
    },
    methods: {
      showCode(val) {
        console.log(val,'akak');
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    }
    }
}
</script>



