<template>
  <div>
    <div
      v-if="readOnly === true && disabled === false"
      class="readOnly-datepicker"
    >
      <div :id="'poistion' + unique" class="input-container">
        <div
          v-if="labelAnimation"
          class="input-field"
          :style="disabled === true ? 'cursor : not-allowed' : 'cursor:pointer'"
          :class="{
            small: small,
            readOnly: readOnlyProp,
            customError: customError,
          }"
        >
          <div
            v-if="readOnlyProp"
            class="label-overlay"
            style="cursor: default"
            @click.stop
          ></div>
          <div
            v-if="readOnlyProp"
            class="readonly-overlay"
            style="cursor: default"
            @click.stop
          ></div>
          <div
            v-if="!readOnlyProp"
            class="readonly-overlay"
            style="cursor: pointer"
          ></div>
          <input
            :id="'inputdate' + unique"
            :key="modalcomponentkey"
            ref="inputField"
            v-model="combinationValueTemp"
            :name="name"
            type="text"
            :style="{
              background: customBackground,
              cursor: disabled === true ? 'not-allowed' : 'pointer',
            }"
            autocomplete="off"
            required
            @keyup.enter="atEnter($event, value)"
            @close="closeDatePicker"
          />
          <label
            >{{ labelValue
            }}<span v-if="showMandatory" style="color: #ff5050">{{
              " *"
            }}</span></label
          >
          <span
            v-if="
              iconPosition == 'left' || iconPosition == 'right' ? true : false
            "
            class="input-icon"
            ><i :class="'icon-' + icon"></i
          ></span>
        </div>
        <div
          v-if="!labelAnimation"
          :id="'input-field' + unique"
          class="input-field"
          :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
          :class="{
            small: small,
            readOnly: readOnlyProp,
            customError: customError,
          }"
          @click="toggleDropdown"
        >
          <div v-if="readOnlyProp" class="label-overlay"></div>
          <div v-if="readOnlyProp" class="readonly-overlay"></div>
          <input
            :id="'inputdate' + unique"
            ref="inputField"
            v-model="combinationValueTemp"
            :name="name"
            type="text"
            :placeholder="labelValue"
            :style="{
              background: customBackground,
              cursor: disabled === true ? 'not-allowed' : 'pointer',
            }"
            autocomplete="off"
            required
            @keyup.enter="atEnter($event, value)"
          />
          <span
            v-if="
              iconPosition == 'left' || iconPosition == 'right' ? true : false
            "
            class="input-icon"
            ><i :class="'icon-' + icon"></i
          ></span>
        </div>
      </div>
    </div>
    <div
      v-if="disabled === true && readOnly === false"
      class="disable-datepicker"
    >
      <div :id="'poistion' + unique" class="input-container">
        <div
          v-if="labelAnimation"
          class="input-field"
          :style="disabled === true ? 'cursor : not-allowed' : 'cursor:pointer'"
          :class="{
            small: small,
            readOnly: readOnlyProp,
            customError: customError,
          }"
        >
          <div
            v-if="readOnlyProp"
            class="label-overlay"
            style="cursor: default"
            @click.stop
          ></div>
          <div
            v-if="readOnlyProp"
            class="readonly-overlay"
            style="cursor: default"
            @click.stop
          ></div>
          <div
            v-if="!readOnlyProp"
            class="readonly-overlay"
            style="cursor: pointer"
          ></div>
          <input
            :id="'inputdate' + unique"
            :key="modalcomponentkey"
            ref="inputField"
            v-model="combinationValueTemp"
            :name="name"
            type="text"
            :style="{
              background: customBackground,
              cursor: disabled === true ? 'not-allowed' : 'pointer',
            }"
            autocomplete="off"
            required
            @keyup.enter="atEnter($event, value)"
            @close="closeDatePicker"
          />
          <label
            >{{ labelValue
            }}<span v-if="showMandatory" style="color: #ff5050">{{
              " *"
            }}</span></label
          >
          <span
            v-if="
              iconPosition == 'left' || iconPosition == 'right' ? true : false
            "
            class="input-icon"
            ><i :class="'icon-' + icon"></i
          ></span>
        </div>
        <div
          v-if="!labelAnimation"
          :id="'input-field' + unique"
          class="input-field"
          :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
          :class="{
            small: small,
            readOnly: readOnlyProp,
            customError: customError,
          }"
          @click="toggleDropdown"
        >
          <div v-if="readOnlyProp" class="label-overlay"></div>
          <div v-if="readOnlyProp" class="readonly-overlay"></div>
          <input
            :id="'inputdate' + unique"
            ref="inputField"
            v-model="combinationValueTemp"
            :name="name"
            type="text"
            :placeholder="labelValue"
            :style="{
              background: customBackground,
              cursor: disabled === true ? 'not-allowed' : 'pointer',
            }"
            autocomplete="off"
            required
            @keyup.enter="atEnter($event, value)"
          />
          <span
            v-if="
              iconPosition == 'left' || iconPosition == 'right' ? true : false
            "
            class="input-icon"
            ><i :class="'icon-' + icon"></i
          ></span>
        </div>
      </div>
    </div>
    <div v-if="disabled === false && readOnly === false">
      <div
        class="drop-datepicker"
        :class="{ 'active-input': isOpen === true }"
        style="width: 100%; margin: 6px 0"
        :style="disabled === true ? 'cursor : not-allowed' : 'cursor:pointer'"
        @click="drop"
      >
        <div :id="'poistion' + unique" class="input-container">
          <div
            v-if="labelAnimation"
            :id="'input-field' + unique"
            class="input-field"
            :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
            :class="{
              small: small,
              readOnly: readOnlyProp,
            }"
            @click="toggleDropdown"
          >
            <div
              v-if="readOnlyProp"
              class="label-overlay"
              style="cursor: default"
              @click.stop
            ></div>
            <div
              v-if="readOnlyProp"
              class="readonly-overlay"
              style="cursor: default"
              @click.stop
            ></div>
            <div
              v-if="!readOnlyProp"
              class="readonly-overlay"
              style="cursor: pointer"
            ></div>
            <input
              :id="'inputdate' + unique"
              :key="modalcomponentkey"
              ref="inputField"
              v-model="combinationValueTemp"
              :name="name"
              type="text"
              :style="{ background: customBackground }"
              autocomplete="off"
              required
              @focus="focusInput"
              @blur="blurDate"
              @mouseenter="hoverDate"
              @mouseleave="unHoverDate"
              @input="atInput"
              @keyup.enter="atEnter($event, value)"
              @close="closeDatePicker"
            />
            <label
              :id="'label' + unique"
              :style="{ background: customBackground }"
              :for="'input' + unique"
              @mouseenter="hoverDate"
              >{{ labelValue
              }}<span v-if="showMandatory" style="color: #ff5050">{{
                " *"
              }}</span></label
            >
            <span
              v-if="
                iconPosition == 'left' || iconPosition == 'right' ? true : false
              "
              class="input-icon"
              ><i :class="'icon-' + icon"></i
            ></span>
          </div>
          <div
            v-if="!labelAnimation"
            :id="'input-field' + unique"
            class="input-field"
            :style="disabled === true ? 'cursor:not-allowed' : 'cursor:pointer'"
            :class="{
              small: small,
              readOnly: readOnlyProp,
            }"
            @click="toggleDropdown"
          >
            <div v-if="readOnlyProp" class="label-overlay"></div>
            <div v-if="readOnlyProp" class="readonly-overlay"></div>
            <input
              :id="'inputdate' + unique"
              ref="inputField"
              v-model="combinationValueTemp"
              :name="name"
              type="text"
              :placeholder="labelValue"
              :style="{ background: customBackground }"
              autocomplete="off"
              required
              @focus="focusInput"
              @blur="blurDate"
              @mouseenter="hoverDate"
              @mouseleave="unHoverDate"
              @input="atInput"
              @keyup.enter="atEnter($event, value)"
            />
            <span
              v-if="
                iconPosition == 'left' || iconPosition == 'right' ? true : false
              "
              class="input-icon"
              ><i :class="'icon-' + icon"></i
            ></span>
          </div>
        </div>
      </div>

      <hlx-overlay
        v-show="isOpen === true"
        :show="isOpen"
        :top="top"
        :left="left"
        :width="width"
        :conditionvalue="conditionvalue"
        :conditionup="conditionup"
        :conditiondown="conditiondown"
      >
        <template #overlay>
          <div
            :class="{ down: isDropdownOpen, up: isDropdownUp }"
            style="width: 100%"
          >
            <div
              v-if="isOpen"
              :id="'date-picker-container' + unique"
              class="date-picker-container"
            >
           
              <div class="topdiv">
                <span
        
          data-tooltip="Enter a valid date "
          data-tooltip-location="right"
        >
                <div
                  v-if="customError === true"
                  class="input-container"
                  :class="{ customError: customError }"
                  @click="(activeInput = 'input1'), activeInputStatus"
                >
                  <div
                    class="input-field small"
                    :class="{ customError: customError }"
                    style="cursor: pointer"
                  >
                    <!--v-if--><!--v-if--><input
                      v-model="formatvalue1"
                      name="Start"
                      type="text"
                      autocomplete="off"
                      :required="requiredStar"
                      @keyup.enter="startValue"
                    /><label>Start</label>
                  </div>
                </div>
              </span>
                <div
                  v-if="customError === false"
                  class="input-container"
                  :class="{ 'active-input': activeInput === 'input1' }"
                  @click="(activeInput = 'input1'), activeInputStatus"
                >
                  <div class="input-field small" style="cursor: pointer">
                    <!--v-if--><!--v-if--><input
                      v-model="formatvalue1"
                      name="Start"
                      autofocus
                      type="text"
                      autocomplete="off"
                      :required="requiredStar"
                      @keyup.enter="startValue"
                    /><label>Start</label>
                  </div>
                  <!--v-if--><span
                    class="error-message"
                    style="display: none; height: 0px"
                  >
                    {{ input_error_message }}
                  </span>
                </div>
                <svg
                  width="40"
                  height="10"
                  viewBox="0 0 25 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z"
                    fill="#d8d8d8"
                  />
                </svg>
                <span
          data-tooltip="Enter a valid date "
          data-tooltip-location="left"
        >
                <div
                  v-if="customErrorEnd === true"
                  class="input-container"
                  :class="{ customError: customErrorEnd }"
                  @click="(activeInput = 'input2'), activeInputStatus"
                >
                  <!--//activeInput === 'input2' ?show_calendar=true : show_calendar=false -->
                  <div
                    class="input-field small"
                    :class="{ customError: customErrorEnd }"
                    style="cursor: pointer"
                  >
                    
                      <input
                        v-model="formatvalue2"
                        name="End"
                        type="text"
                        autocomplete="off"
                        :required="requiredend"
                        @keyup.enter="endValue"
                      /><label>End</label>
                    
                  </div>

                  <!-- v-if<span class="error-message-tooltip-datepicker"
                        style="display: display; height: 0px">{{datepicker_error_message}}</span> -->
                </div>
                </span>
                <div
                  v-if="customErrorEnd === false"
                  class="input-container"
                  :class="{ 'active-input': activeInput === 'input2' }"
                  @click="(activeInput = 'input2'), activeInputStatus"
                >
                  <!--//activeInput === 'input2' ?show_calendar=true : show_calendar=false -->
                  <div class="input-field small" style="cursor: pointer">
                    <input
                      ref="nextInput"
                      v-model="formatvalue2"
                      name="End"
                      type="text"
                      autocomplete="off"
                      :required="requiredend"
                      @keyup.enter="endValue"
                    /><label>End</label>
                  </div>

                  <!--v-if--><span
                    class="error-message"
                    style="display: none; height: 0px"
                  ></span>
                </div>
              </div>
              <div class="show-calendar">
                <Calendar
                  v-if="show_calendar"
                  :id="'calendar1' + unique"
                  :key="key"
                  :pre-value="value1"
                  @close="closeDatePicker"
                  @selected-date="receivedDate1"
                />

                <div v-if="show_calendar_1">
                  <Calendar
                    :id="'calendar1' + unique"
                    :key="key"
                    :pre-value="value2"
                    :holiday-list="formattedDatesBetween"
                    @close="closeDatePicker"
                    @selected-date="receivedDate2"
                  />
                </div>
              </div>
            
              <div class="date-picker-button-container">
                <hlx-button class="secondary-success sm" @click="resetValues"
                  >Reset</hlx-button
                >
                <hlx-button class="primary sm" @click="printValue"
                  >Apply</hlx-button
                >
              </div>
            </div>
          </div>
          <span v-if="tooltipStart" class="tooltip-datepicker-start">
          <span class="tooltiptext-datepicker-start">{{
            datepicker_error_message
          }}
          </span></span>
          <span v-if="tooltipEnd" class="tooltip-datepicker-end"><span class="tooltiptext-datepicker-end">{{
            datepicker_error_message
          }}
          </span></span>

          
        </template>
      </hlx-overlay>
    </div>
  </div>
</template>

<script>
// import { ref, onMounted } from 'vue';
import HlxButton from "../components/ButtonComponent.vue";
import hlxOverlay from "../components/OverlayComponent.vue";
import Calendar from "../components/CalendarComponent.vue";
import { directive } from "vue3-click-away";
export default {
  name: "HlxDatePicker",
  components: {
    Calendar,
    hlxOverlay,
    HlxButton,
  },
  directives: {
    ClickAway: directive,
  },
  props: {
    preValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fromKey: {
      type: String,
      default: "from",
    },
    toKey: {
      type: String,
      default: "to",
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Choose start and end date",
    },
    labelAnimation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    labelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    customBackground: {
      type: String,
      default: "#ffffff",
    },
    customErrorMessage: {
      type: String,
      default: "",
    },
    customError: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    top: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    conditionvalue: {
      type: Number,
      default: 0,
    },
    conditionup: {
      type: Number,
      default: 0,
    },
    conditiondown: {
      type: Number,
      default: 0,
    },
  },

  emits: [
    "selected-range",
    "atEnter",
    "atInput",
    "selected",
    "update:value",
    "update:name",
    "focusOut",
    "change",
    "checkBoxEvent",
    "radioEvent",
    "iso-date",
    "datepicker-close",
    "datepicker-open",
  ],
  data() {
    return {
      fromdate: "",
      modalcomponentkey: 0,
      add: 0,
      close: 0,
      containerleft: 0,
      containertop: 0,
      containerwidth: 1,
      dates: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      small: true,
      showMandatory: false,
      displayDate: "Validity",
      from: "",
      to: "",
      value1: "",
      value2: "",
      key: 1,
      show_calendar: true,
      show_calendar_1: false,
      formatDate1: "",
      formatDate2: "",
      isOpen: false,
      isConditionTrue: false,
      isDropdownOpen: false,
      isDropdownUp: false,
      dropContainer: "",
      poistion: "",
      combinationValueTemp: "",
      formatvalue1: "",
      formatvalue2: "",
      startDateformate: "01/01/1950",
      disabledinput: true,
      disabledButton: true,
      length: "",
      activeInput: "input1",
      datesBetween: [],
      requiredStart: false,
      requiredend: true,
      customError: false,
      customErrorEnd: false,
      tooltipStart: false,
      tooltipEnd: false,
    };
  },
  computed: {
    formattedDatesBetween() {
      return this.datesBetween.map((date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString();
        const year = date.getFullYear();
        // return `${day}/${month}/${year}`;
        return `${month} ${day}, ${year}`;
      });
    },
    // switchActiveInput() {

    //   return this.formatvalue1 ? "input2" : "input1";
    // },
    isDisabled() {
      return this.disabled;
    },
    showClear() {
      // //console.log(this.clearable, this.value, this.icon)
      if (
        this.clearable === true &&
        this.value !== "" &&
        this.icon === undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    // formatvalue1: {
    //   handler(dateObj) {
    //     console.log(dateObj,"backspace");
    //     if (dateObj && dateObj.length >= 10) {
    //       // this.typetext1=dateObj
    //       this.typetext1(dateObj);
    //     }

    //   },
    //   immediate: true,
    //   deep: true
    // },
    // formatvalue2: {
    //   handler(dateObj) {
    //     if (dateObj && dateObj.length >= 10) {
    //       // this.typetext1=dateObj
    //       this.typetext2(dateObj);
    //     }

    //   },
    //   immediate: true,
    //   deep: true
    // },
    startDateformate: "generateDatesBetween",
    // formatvalue1: "generateDatesBetween",
    // switchActiveInput(newActiveInput) {
    //   this.activeInput = newActiveInput;
    //   if (this.activeInput === "input1") {
    //     // //console.log(this.show_calendar_1, this.activeInput);
    //     this.show_calendar = true;
    //     this.show_calendar_1 = false;
    //     this.requiredStar = false;
    //     this.requiredend = true;
    //   } else if (this.activeInput === "input2") {
    //     // //console.log(this.show_calendar_1, this.activeInput);
    //     this.show_calendar_1 = true;
    //     this.show_calendar = false;
    //     this.requiredend = false;
    //     this.requiredStar = true;
    //   } else {
    //     // //console.log(this.show_calendar_1, this.activeInput);
    //     this.show_calendar = true;
    //     this.show_calendar_1 = false;
    //     this.requiredStar = false;
    //     this.requiredend = true;

    //   }
    // },
    activeInput: {
      handler(val) {
        if (val === "input1") {
          this.show_calendar = true;
          this.show_calendar_1 = false;
          this.requiredStar = false;
          this.requiredend = true;
        } else if (val === "input2") {
          this.show_calendar_1 = true;
          this.show_calendar = false;
          this.requiredend = false;
          this.requiredStar = true;
        }
      },
    },
    customErrorMessage: {
      handler(val) {
        // //console.log(val, 'ce')
        this.input_error_message = val;
      },
      immediate: true,
      deep: true,
    },
    checked: {
      handler(val) {
        if (this.type === "single-checkbox") {
          const ob = {};
          ob.name = this.labelValue || "";
          ob.checked = val;
          // //console.log('hscb', ob)
          this.$emit("update:value", val);
          this.$emit("change", ob);
        }
      },
    },
    customError: {
      handler(val) {
        if (val) {
          this.input_error_message = this.customErrorMessage;
        } else {
          this.input_error_message = "";
        }
      },
      immediate: true,
    },
    required: {
      handler(val) {
        if (val) {
          this.showMandatory = val;
        }
      },
      immediate: true,
      deep: true,
    },
    preValue: {
      handler(dateObj) {
        // //console.log(
        //   "🚀 ~ file: datePickerComponent.vue:148 ~ handler ~ dateObj:",
        //   dateObj
        // );
        if (!dateObj || (dateObj && Object.keys(dateObj).length == 0)) return;
        this.calculatePreVal(dateObj);
      },
      immediate: true,
      deep: true,
    },
    // fromKey:{
    //   handler(dateObj){
    //     if(!dateObj) return
    //     this.calculatePreVal(dateObj)
    //   },
    //   immediate:true,
    //   deep:true
    // },
    // toKey:{
    //   handler(dateObj){
    //     if(!dateObj) return
    //     this.calculatePreVal(dateObj)
    //   },
    //   immediate:true,
    //   deep:true
    // },
  },
  // watch:{
  //   prevalue: {
  //     handler(val){
  //       if(val){
  //         this.formatDate1 = val[Object.keys(val)[0]]
  //         //console.log(this.formatDate1,"dafe2")
  //         this.formatDate2 = val[Object.keys(val)[1]]
  //   this.$nextTick(() => {
  //     this.printValue()
  //     // if(this.$refs.inputField) {

  //     //   this.$refs.inputField.value = this.combinationValueTemp;
  //     // }
  //   }),
  //   //console.log(this.prevalue);
  //       }
  //     },

  //     deep: true,
  //     immediate: true

  // },
  //   resetValues() {
  //       this.formatDate1 = this.from;
  //       this.formatDate2 = this.to;
  //     },
  // },

  // mounted() {
  //   this.formatDate1 = this.from;
  //     this.formatDate2 = this.to;

  // },
  // updated(){
  //   if(this.value1.length >= 1 && this.value2 >=1){
  //     this.disabledButton=false
  //   }
  // },
  mounted() {
    if (this.iconPosition === "left" && this.icon !== undefined) {
      if (document.getElementById(this.icon)) {
        document.getElementById(this.icon).style.display = "none";
      }
      document.querySelector("#input-field" + this.unique).style.flexDirection =
        "row-reverse";
      document.querySelector("#label" + this.unique).style.left = "38px";
      document.querySelector("#input" + this.unique).style.padding =
        "0 15px 0 0";
    }
    if (this.iconPosition === "right" && this.icon !== undefined) {
      if (document.getElementById(this.icon)) {
        document.getElementById(this.icon).style.display = "none";
      }
      if (this.type === "text") {
        document.querySelector("#input" + this.unique).style.padding =
          "0 0 0 15px";
      }
      // if (this.type === 'date') {
      //   document.querySelector('#inputdate' + this.unique).style.padding =
      //     '0 0 0 15px'
    }

    document.addEventListener("click", (e) => {
      // //console.log(e,"element clode ");
      if (this.isOpen == true) {
        const elem = document
          .getElementById("poistion" + this.unique)
          .contains(e.target);

        const elem2 = document
          .getElementById("date-picker-container" + this.unique)
          .contains(e.target);

        // const elem3= document
        // .getElementById('calendar1'+ this.unique )
        // .contains(e.target);
        const elem4 = e.target.id;

        // //console.log(elem,elem2,elem3,elem4);
        if (elem === false && elem2 === false && elem4 != "calendey-day") {
          this.isOpen = false;
        } else {
          this.isOpen = true;
        }
      }
    });
  },
  methods: {
    startValue() {
      console.log(new Date(this.formatvalue1),this.formatvalue1,"this.formatvalue1");
      if (new Date(this.formatvalue1) == "Invalid Date") {
        // document.getElementsByClassName('active-input').style.display='none';
        this.customError = true;
        this.tooltipStart = true;
          setTimeout(() => {
          this.tooltipStart = false;
        }, 3000);
      } else {
        this.customError = false;
        this.tooltipStart = false;
      }

      if (new Date(this.formatvalue1) == "Invalid Date") {
        this.datepicker_error_message = "Enter a valid date ";
        this.tooltipStart = true;
      } else {
        this.datepicker_error_message = "";
        this.tooltipStart = false;
      }
      if (new Date(this.formatvalue1) != "Invalid Date")
        this.typetext1(this.formatvalue1);
      this.key += 1;
      this.$refs.nextInput.focus();
    },
    endValue() {
      if (new Date(this.formatvalue2) == "Invalid Date") {
        // document.getElementsByClassName('active-input').style.display='none';
        this.customErrorEnd = true;
        this.tooltipEnd = true;
        setTimeout(() => {
          this.tooltipEnd = false;
        }, 3000);
      } else {
        this.customErrorEnd = false;
        this.tooltipEnd = false;
      }

      if (new Date(this.formatvalue2) == "Invalid Date") {
        // document.getElementsByClassName('active-input').style.display='none';
        this.datepicker_error_message = "Enter a valid date";
        this.tooltipEnd = true;
      } else {
        this.datepicker_error_message = "";
        this.tooltipEnd = false;
      }
      if (new Date(this.formatvalue1) != "Invalid Date") {
        this.typetext2(this.formatvalue2);
        this.key += 1;
      }
    },
    activeInputStatus() {
      // //console.log(this.activeInput, "✌️");
      if (this.activeInput === "input1") {
        this.show_calendar = true;
        this.show_calendar_1 = false;
        this.requiredStar = false;
        this.requiredend = true;
      } else if (this.activeInput === "input2") {
        this.show_calendar_1 = true;
        this.show_calendar = false;
        this.requiredend = false;
        this.requiredStar = true;
      }
    },
    generateDatesBetween() {
      this.datesBetween = []; // Clear the existing array

      const [startMonth, startDay, startYear] =
        this.startDateformate.split("/");
      const [endMonth, endDay, endYear] = this.formatvalue1.split("/");
      const startDate = new Date(startYear, startMonth - 1, startDay);
      const endDate = new Date(endYear, endMonth - 1, endDay);

      if (!isNaN(startDate) && !isNaN(endDate) && startDate <= endDate) {
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          this.datesBetween.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1); // Increment the date by one day
        }
      }
    },
    typetext1(dateObj) {
      let to = dateObj;
      if (to) {
        to = new Date(to);
        to.setDate(to.getDate() + 1);
        to = new Date(to)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        const arr = to.split("-");

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = parseInt(arr[1], 10) - 1; // Subtract 1 as months are 0-indexed
        const monthName = monthNames[monthIndex];
        const date = parseInt(arr[0]);
        this.formatDate1 = monthName + " " + date + ", " + arr[2];

        this.value1 = to;
        this.receivedDate1(to);
        this.key += 1;
        this.formatDate1 = monthName + " " + arr[0] + ", " + arr[2];
        this.formatvalue1 = `${this.formatDate1}`;
      }
      this.combinationValueTemp = `${this.formatDate1} - ${this.formatDate2}`;
      this.formatvalue1 = `${this.formatDate1}`;
      this.formatvalue2 = `${this.formatDate2}`;
    },
    typetext2(dateObj) {
      let to = dateObj;
      if (to) {
        to = new Date(to);
        to.setDate(to.getDate() + 1);
        to = new Date(to)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        const arr = to.split("-");

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = parseInt(arr[1], 10) - 1; // Subtract 1 as months are 0-indexed
        const monthName = monthNames[monthIndex];
        const date = parseInt(arr[0]);
        this.formatDate2 = monthName + " " + date + ", " + arr[2];

        this.value2 = to;
        this.key += 1;
        this.formatDate2 = monthName + " " + arr[0] + ", " + arr[2];
        this.formatvalue2 = `${this.formatDate2}`;
      }
      this.combinationValueTemp = `${this.formatDate1} - ${this.formatDate2}`;
    },
    calculatePreVal(dateObj) {
      let from = dateObj[this.fromKey];
      // console.log(this.fromKey);
      // //console.log(
      //   "🚀 ~ file: datePickerComponent.vue:193 ~ calculatePreVal ~ from: sdfgdhfj",
      //   from
      // );
      let to = dateObj[this.toKey];
      if (from) {
        from = new Date(from);
        from.setDate(from.getDate() + 1);
        from = new Date(from)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        const arr = from.split("-");

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = parseInt(arr[1], 10) - 1; // Subtract 1 as months are 0-indexed
        const monthName = monthNames[monthIndex];
        const date = parseInt(arr[0]);
        this.formatDate1 = monthName + " " + date + ", " + arr[2];

        this.value1 = from;

        this.key += 1;
        this.formatDate1 = monthName + " " + arr[0] + ", " + arr[2];
      }
      if (to) {
        to = new Date(to);
        to.setDate(to.getDate() + 1);
        to = new Date(to)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        const arr = to.split("-");

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = parseInt(arr[1], 10) - 1; // Subtract 1 as months are 0-indexed
        const monthName = monthNames[monthIndex];
        const date = parseInt(arr[0]);
        this.formatDate2 = monthName + " " + date + ", " + arr[2];

        this.value2 = to;
        this.key += 1;
        this.formatDate2 = monthName + " " + arr[0] + ", " + arr[2];
      }
      this.combinationValueTemp = `${this.formatDate1} - ${this.formatDate2}`;
      this.formatvalue1 = `${this.formatDate1}`;
      this.formatvalue2 = `${this.formatDate2}`;
    },
    toggleActiveInput() {
      this.activeInput = this.activeInput === "input1" ? "input2" : "input1";

      if (this.activeInput === "input1") {
        this.show_calendar = true;
        this.show_calendar_1 = false;
        this.requiredStar = false;
        this.requiredend = true;
      } else if (this.activeInput === "input2") {
        this.show_calendar_1 = true;
        this.show_calendar = false;
        this.requiredend = false;
        this.requiredStar = true;
      }
      // You can add an else block if there's another case you want to handle

      // You may also want to perform additional actions here based on the toggled input.
    },
    receivedDate1(val1) {
      console.log(val1, "val1");

      this.value1 = val1;
      this.toggleActiveInput();
      console.log(this.value1);
      this.key += 1;
      const arr = val1.split("-");

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthIndex = parseInt(arr[1], 10) - 1; // Subtract 1 as months are 0-indexed
      const monthName = monthNames[monthIndex];

      this.formatDate1 = monthName + " " + arr[0] + ", " + arr[2];
      this.combinationValueTemp = `${this.formatDate1} - ${this.formatDate2}`;
      this.formatvalue1 = `${this.formatDate1}`;
      this.formatvalue2 = `${this.formatDate2}`;
    },
    receivedDate2(val2) {
      this.value2 = val2;
      this.key += 1;
      const arr = val2.split("-");
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthIndex = parseInt(arr[1], 10) - 1; // Subtract 1 as months are 0-indexed
      const monthName = monthNames[monthIndex];

      this.formatDate2 = monthName + " " + arr[0] + ", " + arr[2];
      //       this.combinationValueTemp = `${this.formatDate1} - ${this.formatDate2}`;
      // this.isOpen = false
      this.printValue();
    },
    formatDateTime() {
      const currentDateTime = new Date();

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[currentDateTime.getMonth()];
      // Adding 1 because months are 0-indexed
      //   //console.log(month, "dae1");
      const day = this.formatTwoDigits(currentDateTime.getDate());
      const year = currentDateTime.getFullYear();

      // let hours = currentDateTime.getHours();
      // const amPM = hours >= 12 ? 'PM' : 'AM';
      // hours = hours % 12 || 12; // Convert to 12-hour format
      // const minutes = currentDateTime.getMinutes();
      this.from = `${month}/${day}/${year}`;
      this.formatDate1 = `${month}/${day}/${year}`; //${hours}:${this.formatTwoDigits(minutes)} ${amPM}
      this.value1 = `${day}-${month}-${year}`; //${hours}:${this.formatTwoDigits(minutes)} ${amPM}
    },
    formatTwoDigits(number) {
      return number < 10 ? `0${number}` : number;
    },
    formatDateTime2() {
      const currentDateTime = new Date("August 19, 2050");

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[currentDateTime.getMonth()];
      // Adding 1 because months are 0-indexed
      const day = this.formatTwoDigits(currentDateTime.getDate());
      const year = currentDateTime.getFullYear();
      //  //console.log(month, "date2");
      // let hours = currentDateTime.getHours();
      // const amPM = hours >= 12 ? 'PM' : 'AM';
      // hours = hours % 12 || 12; // Convert to 12-hour format
      // const minutes = currentDateTime.getMinutes();
      this.to = `${month}/${day}/${year}`;
      this.formatDate2 = `${month}/${day}/${year}`; //${hours}:${this.formatTwoDigits(minutes)} ${amPM}
      this.value2 = `${day}-${month}-${year}`; //${hours}:${this.formatTwoDigits(minutes)} ${amPM}
    },
    formatTwoDigits2(number) {
      return number < 10 ? `0${number}` : number;
    },

    printValue() {
      //  //console.log("datepicker close", (this.close += 1));
      this.$emit("datepicker-close", (this.close += 1));

      this.isOpen = false;

      // Create Date objects from formatted dates
      const date1 = new Date(this.formatDate1);
      const date2 = new Date(this.formatDate2);

      date1.setDate(date1.getDate() + 1);
      date2.setDate(date2.getDate() + 1);

      const valObj = {};
      valObj[this.fromKey] = date1.toJSON();
      valObj[this.toKey] = date2.toJSON();
      // Add one day to each date

      //  //console.log(valObj,"fromeKey date formate ");

      // Optionally, you can set the input field value here if needed
      this.$refs.inputField.value = this.combinationValueTemp;

      this.$refs.inputField.value = this.combinationValueTemp;
      this.combinationValueTemp = `${this.formatDate1} - ${this.formatDate2}`;
      this.formatvalue1 = `${this.formatDate1}`;
      this.formatvalue2 = `${this.formatDate2}`;

      // Emit the event when Apply is clicked
      this.$emit("selected-range", valObj);
    },

    resetValues() {
      // this.formatDate1=  ;
      // this.formatDate2 formatDateTime2();
      this.value1 = "";
      this.value2 = "";
      this.formatDate1 = "";
      this.formatDate2 = "";
      this.formatvalue1 = "";
      this.formatvalue2 = "";
      this.combinationValueTemp = "";
      //   //console.log("datepicker close", (this.close += 1));
      this.$emit("datepicker-close", (this.close += 1));
      this.activeInput = "input1";
      this.requiredStar = false;
      this.requiredend = true;
      this.show_calendar_1 = false;
      this.show_calendar = true;
      this.isOpen = false;
      this.modalcomponentkey += 1;
    },
    // drop() {
    //   this.dropContainer = document.querySelector(".input-container");
    //   this.position = this.dropContainer?.getBoundingClientRect();
    //   this.isDropdownOpen = !this.isDropdownOpen;
    //   this.isDropdownUp = !this.isDropdownUp;
    //   //  //console.log(this.position.top, "iii");
    //   if (this.position.top >= 310) {
    //     //  //console.log("up"); //in top we put 420 is css the java script calulate exta 16px
    //     this.isDropdownUp = true;
    //     this.isDropdownOpen = false;
    //   } else {
    //     this.isDropdownOpen = true;
    //     this.isDropdownUp = false;
    //   }
    // },
    toggleDropdown() {
      // //console.log("datepicker open", (this.add += 1));
      this.$emit("datepicker-open", (this.add += 1));

      // this.$nextTick(() => {
      //   this.containerleft = document
      //     .querySelector("#date-picker-container")
      //     .getBoundingClientRect().left;
      //   this.containertop = document
      //     .querySelector("#date-picker-container")
      //     .getBoundingClientRect().top;
      //   this.containerwidth = document
      //     .querySelector("#date-picker-container")
      //     .getBoundingClientRect().width;
      // });
      //  //console.log(this.containerleft, "kkk🐕");
      this.isOpen = !this.isOpen;
      //    //console.log(this.activeInput, "✌️");
      if (this.activeInput === "input2") {
        this.activeInput = "input1";
        this.requiredStar = false;
        this.requiredend = true;
        this.show_calendar = true;
        this.show_calendar_1 = false;
      }
    },
    // calender(){

    //   if (this.show_calendar === true) {
    //     //console.log("log click");
    //     document.querySelector("#color").style.border ="2px solid " + "#54bd95";
    //   this.containerleft = document
    //     .querySelector("#color")
    //     .getBoundingClientRect().left;
    //   this.containertop = document
    //     .querySelector("#color")
    //     .getBoundingClientRect().top;
    //   this.containerwidth = document
    //     .querySelector("#color")
    //     .getBoundingClientRect().width;
    //   }
    // },
    closeDatePicker() {
      //   //console.log("datepicker close", (this.close += 1));
      this.$emit("datepicker-close", (this.close += 1));
      this.activeInput = "input1";
      this.requiredStar = false;
      this.requiredend = true;
      this.show_calendar_1 = false;
      this.show_calendar = true;
      this.isOpen = false;
    },
    handleTextareaKeydown($event) {
      if (this.readOnlyProp === true) {
        $event.preventDefault();
        $event.stopPropagation();
        // Get the current cursor position
      }
    },
    // openCalendar() {

    //   this.show_calendar = !this.show_calendar;
    //   if (this.show_calendar === true) {
    //     this.focusDate();
    //     // document.getElementById('label' + this.unique).style.color = "blue";
    //   } else {
    //     this.blurDate();
    //   }
    // },
    atEnter(event, value) {
      const obj = {};
      obj.event = event;
      obj.value = value;
      // //console.log(obj);
      this.$emit("atEnter", obj);
    },
    hoverSelectAll() {
      document
        .getElementById("select-all-checkmark" + this.unique)
        .classList.add("select-all-theme-hover");
    },
    unHoverSelectAll() {
      if (this.select_all === false && this.partially_checked === false) {
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("select-all-theme-hover");
      }
    },
    selectAll($event) {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      this.select_all = $event.target.checked;
      if (this.select_all === true) {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = true;
            }
          });
        this.optionsD.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = true;
          }
        });
        // const r = document.querySelector(':root')
        // const rs = getComputedStyle(r)
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("select-all-grey");
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("select-all-theme");
      } else {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = false;
            }
            if (ele.checked === true) {
              document
                .getElementById("select-all-checkmark" + this.unique)
                .classList.add("select-all-theme");
              this.partially_checked = true;
            }
          });
        this.optionsD.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = false;
          }
        });
        // //console.log(this.partially_checked)
        if (this.partially_checked === false) {
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-grey");
        } else if (this.partially_checked === true) {
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("select-all-theme");
        }
      }
      this.$emit("update:value", this.optionsD);
      this.$emit("checkBoxEvent", this.optionsD);
    },

    atInput($event) {
      // //console.log("at");
      if (this.characterLimit !== null) {
        if (this.value.length >= this.characterLimit) {
          // //console.log(this.value);
          $event.preventDefault();
          this.value = this.value.slice(0, this.characterLimit);
        }
      }
      if (this.maxLength >= 0) {
        if (this.value.length > this.maxLength) {
          this.input_error_message =
            "Maximum character limit is " + this.maxLength;
        } else if (this.customError === true) {
          this.input_error_message = this.customErrorMessage;
        } else {
          this.input_error_message = "";
        }
      }
      if (this.minLength >= 0) {
        if (this.value.length < this.minLength) {
          this.input_error_message =
            "Minimum character limit is " + this.minLength;
        } else if (this.customError === true) {
          this.input_error_message = this.customErrorMessage;
        } else {
          this.input_error_message = "";
        }
      }
      this.$emit("update:value", this.value);
      this.$emit("atInput", this.value);
    },
    hoverInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // const elem = document.querySelector('#input' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + rs.getPropertyValue('--theme')
      // }
    },
    unHoverInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // const elem = document.querySelector('#input' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + rs.getPropertyValue('--border')
      // }
    },
    focusInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // document.querySelector('#input-field' + this.unique).style.border =
      //   '1px solid ' + rs.getPropertyValue('--theme')
      // if (this.iconPosition === 'left' && this.icon !== undefined) {
      //   document.querySelector('#label' + this.unique).style.left = '5px'
      // }

      if (this.required === true) {
        this.showMandatory = true;
      }
    },
    blurInput() {
      // const r = document.querySelector(':root')
      // const rs = getComputedStyle(r)
      // document.querySelector('#input-field' + this.unique).style.border =
      //   '1px solid ' + rs.getPropertyValue('--border')
      if (this.iconPosition === "left" && this.icon !== undefined) {
        document.querySelector("#label" + this.unique).style.left = "38px";
      }
      // this.$emit('blurEvent', this.value)
      if (this.required === true) {
        this.showMandatory = true;
      }
      this.$emit("focusOut", this.value);
    },
    // Following functions are for validating Date input functionalities
    focusDate() {
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#54bd95";
      this.containerleft = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().left;
      this.containertop = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().top;
      this.containerwidth = document
        .querySelector("#input-field" + this.unique)
        .getBoundingClientRect().width;
    },
    blurDate() {
      document.querySelector("#input-field" + this.unique).style.border =
        "1px solid " + "#d8d8d8";
      this.$emit("focusOut", this.value);
    },
    hoverDate() {
      // const elem = document.querySelector('#inputdate' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + '#54bd95'
      // }
    },
    unHoverDate() {
      // const elem = document.querySelector('#inputdate' + this.unique)
      // if (elem !== document.activeElement) {
      //   document.querySelector('#input-field' + this.unique).style.border =
      //     '1px solid ' + '#d8d8d8'
      // }
    },
    validateNumber() {
      if (this.minLimit && this.maxLimit !== undefined) {
        if (this.value > this.maxLimit) {
          document.querySelector(".max").style.color = "#ff5050";
        } else {
          document.querySelector(".max").style.color = "#02b55c";
        }
        if (this.value < this.minLimit) {
          document.querySelector(".min").style.color = "#ff5050";
        } else {
          document.querySelector(".min").style.color = "#02b55c";
        }
        if (this.value === "") {
          document.querySelector(".max").style.color = "#02b55c";
          document.querySelector(".min").style.color = "#02b55c";
        }
      }
    },
  },
};
</script>

<style lang="scss">
.active-input {
  border: 1px solid #54bd95;
  border-radius: 6px;

  .input-field.small input:valid + label {
    color: var(--hlx-color-primary);
  }
}
</style>
