<template>
  <h1 class="component-heading">Switch</h1>

  <p class="component-description">
    A switch allows the user to change a setting among any number of states.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h1 class="component-side-heading">Basic usage</h1>
  <p class="component-description">
    Supply the options for your switch component using the
    <b>:switch-items</b> attribute.
  </p>
  <p class="component-description">
    Choose the type of your switch group using <b>:tab-view</b> attribute.
  </p>
  <div class="component-example">
    <div class="example-body">
      <!-- <span>Regular switch:</span> -->
      <!-- <div class="temp-container" v-for="i in this.arr" :key="i">  -->
        <hlx-switch
        v-model="selectedValue"
        tab-view
        :switch-items="groupValues"
        value-prop="nameEn"
        display-prop="labelEn"
        @change="handleCurrentChange"
        />
        <!-- tab-view -->
        <!-- read-only -->
        <!-- </div>  -->
      <!-- <span>Tab switch:</span> -->

      <!-- <hlx-switch :switch_items="this.switch_items" @chosen="chosen1($event)"  :type="'tab'" :position="'bottom'"/>   -->
    </div>
    <div class="example-footer">
      <span
        id="basic-switch-icon"
        class="icon"
        @click="showCode('basic-switch')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="basic-switch" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="switch_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python']
          ]"
        />
      </div>
    </section>
  </div>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'search')"
      ><i class="icon-angle-left-regular prev-icon"></i>Search</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'code-editor')"
        >Code editor <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import hlxSwitch from "../components/SwitchComponent.vue";
import divider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
export default {
  components: {
    hlxSwitch,
    divider,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    CodeEditor
  },
  data() {
    return {
      selectedValue: "mobile",
      groupValues: [
        {
          nameEn: "laptop",
          nameFr: "*laptop",
          labelEn: "Laptop",
          labelFr: "*Laptop"
        },
        {
          nameEn: "pc",
          nameFr: "*pc",
          labelEn: "PC",
          labelFr: "*PC"
        },
        {
          nameEn: "mobile",
          nameFr: "*mobile",
          labelEn: "Mobile",
          labelFr: "*Mobile"
        },
        {
          nameEn: "Tablet",
          nameFr: "*Tablet",
          labelEn: "Tablet",
          labelFr: "*Tablet"
        }
      ],
      arr: [
        { name: "Monthly billing" },
        { name: "annual billing" },
        { name: "Custom billing" }
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: ""
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: ""
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: ""
        }
      ],
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: ""
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: ""
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: ""
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: ""
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: ""
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: ""
        }
      ],
      editor_theme: "light",

      //        switch_items: [
      //   {
      //     name: "Manual",
      //     icon: "",
      //     checked: true,
      //   },
      //   {
      //     name: "API",
      //     checked: false,
      //   },
      //   {
      //     name: "Upload",
      //     icon: "icon-file-tick",
      //     checked: false,
      //   },
      // ],
      switch_items: [
        { name: "Monthly billing" },
        { name: "Annual billing", checked: true },
        { name: "Custom billing" }
      ],
      switch_source: `
   <template>
      <hlx-switch
        v-model="selectedValue"
        :switch-items="groupValues"
        value-prop="nameEn"
        display-prop="labelEn"
        @change="handleCurrentChange"
      />
   </template>
   
   <script>
   export default{
       data(){
           return{
            selectedValue:{ "nameEn": "mobile", "nameFr": "*mobile", "labelEn": "Mobile", "labelFr": "*Mobile" },

            groupValues:[
            {
              nameEn: 'laptop',
              nameFr: '*laptop',
              labelEn: 'Laptop',
              labelFr: '*Laptop'
            },
            {
              nameEn: 'pc',
              nameFr: '*pc',
              labelEn: 'PC',
              labelFr: '*PC'
            },
            {
              nameEn: 'mobile',
              nameFr: '*mobile',
              labelEn: 'Mobile',
              labelFr: '*Mobile'
            },
            {
              nameEn: 'Tablet',
              nameFr: '*Tablet',
              labelEn: 'Tablet',
              labelFr: '*Tablet'
            }
          ]

             }
           },
           methods: {
    handleCurrentChange(changedData) {
      console.log(
        "🚀 ~ file: ButtonGroupView.vue:354 ~ handleCurrentChange ~ changedData:",
        changedData
      );
    }},
         }
       }
       <\/script>`,
      table_data: [
        {
          name: "switch-items",
          description: "Include your list for switch items",
          type: "Array of objects",
          accepted_values: "[{}]",
          default: "-",
          mandatory: true
        },
        {
          name: "value-prop",
          description:
            "To specify the value field in given switch item to handle current selection",
          type: "String",
          accepted_values: "Any prop as string in your object",
          default: "value",
          mandatory: true
        },
        {
          name: "v-model",
          description:
            "Get or pre-populate(set default) value to the switch group",
          type: "String",
          accepted_values: "Any string and passed switch item value",
          default: "",
          mandatory: false
        },
        {
          name: "display-prop",
          description: "To display label for the switch buttons",
          type: "String",
          accepted_values: "Any prop as string in your object",
          default: "label",
          mandatory: true
        },
        {
          name: "read-only",
          description: "To make switch not selectable",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: false
        },
        {
          name: "tab-view",
          description: "To make your switch group as tab group",
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: false
        }
        // {
        //   name: "position",
        //   description: "Choose the position of switch",
        //   type: "String",
        //   accepted_values: "top / bottom",
        //   default: "-",
        //   mandatory: true,
        // },
      ],
      event_data: [
        {
          name: "change",
          description: "Emits the option chosen",
          parameters: "-"
        }
      ]
    };
  },
  mounted(){
    setTimeout(() => {
      this.selectedValue ='laptop'
    }, 2000);
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },  
    handleCurrentChange(changedData) {
      console.log(
        "🚀 ~ file: ButtonGroupView.vue:354 ~ handleCurrentChange ~ changedData:",
        changedData
      );
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    chosen(val) {
      console.log("hello", val);
    }
  }
};
</script>

<style lang="scss">
.temp-container {
  width: 500px;
}
</style>
