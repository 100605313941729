<template>
  <h1 class="component-heading">Data view</h1>
  <p class="component-description">
    A DataView is a representation of a full table or a small section of rows.
    It is used to sort and find data within Datatable.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">Define your data in array of objects</p>
  <div class="component-example">
    <div class="example-body">
      <hlx-dataview
        :card-data="card_data"
        :title-prop="'name'"
        :sub-title-prop="'brand'"
        :description-prop="'description'"
        :category-prop="'category'"
        :label-prop="'status'"
        :footer-icon="'icon-plus-circle-regular'"
        :footer-text="'Add'"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-data-view-icon"
        class="icon"
        @click="showCode('basic-data-view')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="basic-data-view" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="data_view_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!-- <hlxTable :data="this.data_viewAttributes"
    :border="true"
    theme="secondary"
    :rowHover="true"
    :ellipses="5"
    :bold_header="true">
    <hlx-column :prop="'name'" :label="'Name'" :type="'string'" />
    <hlx-column :prop="'description'" :label="'Description'" :type="'string'" />
    <hlx-column :prop="'default'" :label="'Default'" :type="'string'" />
    <hlx-column :prop="'type'" :label="'Type'" :type="'string'" :width="200"/>
    <hlx-column :prop="'accepted_values'" :label="'Accepted values'" :type="'string'"/>
    <hlx-column :prop="'mandatory'" :label="'Mandatory'" :type="'string'" />
</hlxTable> -->
  <divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'imagegallery')"
      ><i class="icon-angle-left-regular prev-icon"></i>Image gallery</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'table')"
      >Table <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>

<script>
import hlxDataview from "../components/DataviewComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import divider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
export default {
  components: {
    hlxDataview,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    divider,
    CodeEditor,
  },
  data() {
    return {
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      editor_theme: "light",
      card_data: [
        {
          status: "active",
          category: "Mobile",
          name: "IPhone 15 Pro Max",
          brand: 'Apple',
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
          price: { whole: "1356", fraction: "32" },
          currency: "USD",
          img_url:
            "",
        },
        {
          status: "active",
          category: "ABC category",
          name: "Product offer name",
          brand: 'Brand details',
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
          price: { whole: "655678", fraction: "32" },
          currency: "USD",
          img_url:
            "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
        },
      ],
      data_view_source: `
<template>
          <hlx-dataview :card_data = "this.card_data"/>
</template>

<script>
export default{
    data(){
        return{
                  card_data: [
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex boardd",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "low stock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "abc",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: "60",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: "63",
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
        {
          card_title: "Accessories",
          card_body: {
            product_img_url:
              "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
            product_title: "Webex board",
            product_description: "Webex board",
            product_rating: "4",
            product_stock: "instock"
          },
          card_footer: {
            price: {whole:"65",
fraction:"32"},
            currency:"USD"
          },
        },
      ],
    }
}
}
</\script>`,

      table_data: [
        {
          name: "card-data",
          description: "Include your data",
          type: "Array of objects",
          accepted_values: "[{}]",
          default: "-",
          mandatory: true,
        },
      ],
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>

<style></style>
