<template>
  <div v-if="showArrows" class="tabs">
    <div class="tab-arrows">
      <i class="icon-angle-left-small-regular" @click="scrollLeft"></i>
      <ul :class="'tabs_header tabs_header' + unique">
        <li
          v-for="title in tabTitles"
          :id="border"
          :key="title"
          :class="{ selected: title == selectedTitle }"
          @click="selectedTitle = title"
        >
          {{ title }}
        </li>
      </ul>
      <i class="icon-angle-right-small-regular" @click="scrollRight"></i>
    </div>
    <slot />
  </div>
  <div v-else class="tabs">
    <ul :class="'tabs_header tabs_header' + unique">
      <li
        v-for="title in tabTitles"
        :id="border"
        :key="title"
        :class="{ selected: title == selectedTitle }"
        @click="selectedTitle = title"
      >
        {{ title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { ref, provide } from "vue";

export default {
  name: "HlxTabs",
  props: {
    border: {
      type: String,
      default: "bottom",
    },
  },
  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(tabTitles.value[0]);

    provide("selectedTitle", selectedTitle);
    return {
      selectedTitle,
      tabTitles,
    };
  },
  data() {
    return {
      unique: Math.floor(Math.random() * 1000 + 1),
      showArrows: false,
    };
  },
  mounted() {
    const div = document.querySelector(".tabs_header" + this.unique);
    this.showArrows = div.scrollWidth > div.clientWidth;
  },
  methods: {
    scrollLeft() {
      const container = document.querySelector(".tabs_header" + this.unique);
      this.sideScroll(container, "left", 25, 100, 50);
    },
    scrollRight() {
      const container = document.querySelector(".tabs_header" + this.unique);
      this.sideScroll(container, "right", 25, 100, 50);
    },
    sideScroll(element, direction, speed, distance, step) {
      let scrollAmount = 0;
      const slideTimer = setInterval(function () {
        if (direction === "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
  },
};
</script>

<style lang="scss" scoped></style>
