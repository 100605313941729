<template>
  <h1 class="component-heading">Object viewer</h1>
  <p class="component-description">
    The Object Viewer component is a versatile and user-friendly interface
    designed specifically to view and manipulate JSON files in a readable format
    within the application. It offers an intuitive and interactive experience,
    allowing users to edit the JSON, expand and collapse nested objects for easy
    navigation and exploration.
  </p>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Read-only mode</h2>
  <p class="component-description">
    Object viewer's read-only mode is used to view the JSON data in a compact an
    user readable format. read-only mode is enabled by default.
  </p>
  <br />
  <div class="notes">
    <p class="note-title">Note:</p>
    <p class="note-body">The payload must always be in a key-value pair.</p>
  </div>
  <div class="component-example">
    <div class="example-body3">
      <hlx-object-viewer :json-data="supplyJSON()" :ref-data="rData" />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="sample1_code-icon"
        class="icon"
        @click="showCode('sample1_code')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="sample1_code" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="sample1_code"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Editable mode</h2>
  <p class="component-description">
    Object viewer's editable mode is used to edit the JSON data from the UI
    itself. Enable editable mode by setting <b>type="editable"</b>.
  </p>
  <div class="component-example">
    <div class="example-body3">
      <hlx-object-viewer
        :json-data="supplyJSON()"
        :ref-data="rData"
        type="add-data-from-schema"
        @updated-json="log"
      />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="sample2_code-icon"
        class="icon"
        @click="showCode('sample2_code')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="sample2_code" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="sample2_code"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider
    v-if="$route.params.pw === 'one'"
    :position="'horizontal'"
    :space="'20px'"
  />
  <h2 v-if="$route.params.pw === 'one'" class="component-side-heading">
    Add data from schema mode
  </h2>
  <p v-if="$route.params.pw === 'one'" class="component-description">
    Object viewer's add-data-from-schema mode is a developer version to add JSON
    data built from the schema. Enable editable mode by setting
    <b>type="add-data-from-schema"</b>.
  </p>
  <br v-if="$route.params.pw === 'one'" />
  <div v-if="$route.params.pw === 'one'" class="notes">
    <p class="note-title">Note:</p>
    <p class="note-body">
      Use <b>addListItems(schema)</b> and
      <b>generateJSONFromSchema(schema)</b> to generate JSON from the schema and
      then supply the payload to the Object viewer component
    </p>
  </div>
  <div v-if="$route.params.pw === 'one'" class="component-example">
    <div class="example-body">
      <hlx-object-viewer :json-data="supplyJSON()" type="editable" />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="sample3_code-icon"
        class="icon"
        @click="showCode('sample3_code')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="sample3_code" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="sample3_code"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Object viewer attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in OvAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in OvAttributes"
          :key="col_index"
          :align="'left'"
          :width="i.width"
          style="word-wrap: break-word"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :position="'horizontal'" :space="'20px'" />

  <h2 class="component-side-heading">Object viewer events</h2>

  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in OvEvents"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in Ov_events_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in OvEvents"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'Texteditor')"
      ><i class="icon-angle-left-regular prev-icon"></i>Text editor</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'timeline')"
      >Timeline <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>

<script>
import CodeEditor from "simple-code-editor";
import hlxObjectViewer from "../components/ObjectViewerComponent.vue";
import HlxDivider from "../components/DividerComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";

export default {
  components: {
    CodeEditor,
    hlxObjectViewer,
    HlxDivider,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
  },
  data() {
    return {
      x: null,
      item: "2023-07-03T16:39:00.734+00:00",
      editor_theme: "light",
      example_code: "`your code goes here`",
      rData: {
        Location: [
          { name: "Chennai", value: "chennai" },
          { name: "Villupuram", value: "villupuram" },
          { name: "Coimbatore", value: "Coimbatore" },
          { name: "tirupur", value: "tirupur" },
          { name: "erode", value: "erode" },
          { name: "salem", value: "salem" },
        ],
        Date: [
          {
            name: "03/07/2023 10:09 PM",
            value: "2023-07-03T16:39:00.734+00:00",
          },
          {
            name: "03/07/2024 10:09 PM",
            value: "2024-07-03T16:39:00.734+00:00",
          },
        ],
        sampleObject: [
          {
            value: '{},{ "name": "val1","age": "11","sex": "male" }',
            name: "val1",
          },
          {
            value: '{},{ "name": "val2","age": "12","sex": "female" }',
            name: "val2",
          },
          {
            value: '{},{ "name": "val3","age": "13","sex": "shemale" }',
            name: "val3",
          },
          {
            value: '{},{ "name": "val4","age": "14","sex": "chair" }',
            name: "val4",
          },
        ],
        sampleObject2: [
          {
            value:
              '[],[{ "name": "val1","age": "11","sex": "male" }, { "name": "val1","age": "11","sex": "male" } ,{ "name": "val1","age": "11","sex": "male" }]',
            name: "val1",
          },
          {
            value:
              '[],[{ "name": "val2","age": "12","sex": "female" },{ "name": "val2","age": "12","sex": "female" },{ "name": "val2","age": "12","sex": "female" }]',
            name: "val2",
          },
          {
            value:
              '[],[{ "name": "val3","age": "13","sex": "shemale" },{ "name": "val3","age": "13","sex": "shemale" },{ "name": "val3","age": "13","sex": "shemale" }]',
            name: "val3",
          },
          {
            value:
              '[],[{ "name": "val4","age": "14","sex": "chair" },{ "name": "val4","age": "14","sex": "chair" },{ "name": "val4","age": "14","sex": "chair" }]',
            name: "val4",
          },
        ],
      },
      sample1: {
        QuoteTest: {
          "price (Number)": {},
          "tax (Number)": {},
          "totalPrice (Number)": {},
          "quoteItem (Array)": {
            "tax (String)": {},
            "productOffering (Object)": {
              "* name (String)": { required: true },
              "bundledProductOffering (Array)": {},
              "productSpecification (Object)": {
                "name (String)": {},
                "bundledProductSpecification (Boolean)": { default: true },
                "description (String)": {},
                "brand (String)": {},
                "isBundle (String)": {},
                "lastUpdate (String)": {},
                "productRef (String)": {
                  "id (String)": {},
                  "* name (String)": { required: true },
                  "description (String)": {},
                  "lastUpdate (String)": {},
                  "lifecycleStatus (String)": {},
                  "catlogtype (String)": {},
                  "validFor (Object)": {},
                  "category (Array)": {},
                  "relatedParty (Array)": {},
                  "unitPrice (String)": {},
                },
                "validFor (Object)": {},
                "version (String)": {},
                "relatedParty (Array)": {},
                "productSpecCharacteristic (Array)": {},
                "serviceSpecification (Object)": {},
                "productSpecificationRelationship (Array)": {},
                "resourceSpecification (Array)": {},
                "attachment (Array)": {},
              },
              "description (String)": {},
              "isBundle (Boolean)": { default: false },
              "isSellable (Boolean)": { default: true },
              "lastUpdate (String)": {},
              "lifecycleStatus (String)": {},
              "validFor (Object)": {},
              "version (String)": {},
              "place (Array)": {},
              "serviceLevelAgreement (Object)": {},
              "channel (Array)": {},
              "serviceCandidate (Object)": {},
              "attachment (Array)": {},
              "category (Array)": {},
              "resourceCandidate (Object)": {},
              "productOfferingTerm (Array)": {},
              "marketSegment (Array)": {},
              "productOfferingPrice (Array)": {},
              "agreement (Array)": {},
              "prodSpecCharValueUse (Array)": {},
            },
            "quantity (String)": {},
            "unitPrice (String)": {},
            "price (String)": {},
          },
          "externalId (String)": { default: "N/A" },
          "version (String)": { default: "1" },
          "description (String)": { default: "N/A" },
          "category (String)": { default: "uncategorized" },
          "state (String)": { default: "Inprogress" },
          "quoteDate (Date)": { default: "Date.now()" },
          "expectedQuoteCompletionDate (Date)": {},
          "expectedFulfillmentStartDate (Date)": {},
          "effectiveQuoteCompletionDate (Date)": {},
          "validFor (Object)": {},
          "note (Array)": {},
          "billingAccount (Array)": {},
          "userId (String)": {},
          "relatedParty (Array)": {},
          "productRef (Object)": {},
          "authorization (Array)": {},
          "contactMedium (Array)": {},
          "quoteTotalPrice (Array)": {},
          "productOfferingQualification (Array)": {},
          "agreement (Array)": {},
          "quoteHistory (Array)": {},
          "attachment (Array)": {},
        },
      },
      OvAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          name: "json-data",
          description:
            "Supply the JSON data in a key-value pair format. Refer code examples above for better understanding",
          type: "Object",
          accepted_values: "key: {}",
          default: "-",
          mandatory: true,
        },
        {
          name: "type",
          description: "Sets the type of the object viewer component",
          type: "String",
          accepted_values: "read-only / editable",
          default: "read-only",
          mandatory: false,
        },
      ],
      OvEvents: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      Ov_events_data: [
        {
          name: "updated-json",
          description: "Emits the updated JSON after edit in editable mode",
          parameter: "(JSON)",
        },
      ],
      sample1_code: `
<template>
    <hlx-object-viewer
      :json-data="this.sample"
    />
</template>

<script>

export default {
  data () {
    return {
        sample: {
        Data: {
        name: 'John Doe',
        age: 30,
        email: 'john.doe@example.com',
        address: {
          street: '123 Main Street',
          city: 'New York',
          state: 'NY',
          zipcode: '10001'
        },
        orders: [
          {
            id: 1,
            date: '2023-05-10',
            total: 59.99,
            items: [
              {
                name: 'Widget A',
                quantity: 2
              },
              {
                name: 'Widget B',
                quantity: 1
              }
            ]
          },
          {
            id: 2,
            date: '2023-05-09',
            total: 79.99,
            items: [
              {
                name: 'Widget C',
                quantity: 3
              }
            ]
          }
        ]
        }
      }
    }
  }
}

</\script>

      `,
      sample2_code: `
<template>
    <hlx-object-viewer
      :json-data="this.sample"
      type="editable"
    />
</template>

<script>

export default {
  data () {
    return {
        sample: {
        Data: {
        name: 'John Doe',
        age: 30,
        email: 'john.doe@example.com',
        address: {
          street: '123 Main Street',
          city: 'New York',
          state: 'NY',
          zipcode: '10001'
        },
        orders: [
          {
            id: 1,
            date: '2023-05-10',
            total: 59.99,
            items: [
              {
                name: 'Widget A',
                quantity: 2
              },
              {
                name: 'Widget B',
                quantity: 1
              }
            ]
          },
          {
            id: 2,
            date: '2023-05-09',
            total: 79.99,
            items: [
              {
                name: 'Widget C',
                quantity: 3
              }
            ]
          }
        ]
        }
      }
    }
  }
}

</\script>

      `,
      sample3_code: `
<template>
    <hlx-object-viewer
      :json-data="supplyJSON()"
      type="add-data-from-schema"
    />
</template>

<script>

export default {
  data () {
    return {
    }
  },
  methods: {
    supplyJSON () {
      const payload = {
        TotalMRC: {
          type: 'Integer'
        },
        TotalNRC: {
          type: 'Integer'
        },
        EI: {
          type: 'Object',
          href: 'http://localhose:port//schema/EI',
          Speed: {
            type: 'String'
          },
          PostalCode: {
            type: 'Integer'
          },
          AccessType: {
            type: 'String'
          },
          Band: {
            type: 'String'
          },
          InstallationNRC: {
            type: 'Integer'
          },
          Term: {
            type: 'Integer'
          },
          MRC: {
            type: 'Integer'
          },
          isLAG: {
            type: 'Boolean'
          },
          LAG: {
            type: 'Array',
            href: 'http://localhose:port//schema/LAG',
            InstallationNRC: {
              type: 'Array',
              href: 'http://localhose:port//schema/ulla',
              ref: true,
              MRC: {
                type: 'Integer'
              }
            },
            MRC: {
              type: 'Integer'
            }
          }
        }
      }

      const copyOfPayload = JSON.parse(JSON.stringify(payload))
      return { EIOP: this.addListItems(copyOfPayload) }
    },
    addListItems (schema) {
      for (const key in schema) {
        if (typeof schema[key] === 'object' && schema[key] !== null) {
          if (schema[key].type === 'Array') {
            schema[key].list_items = []
            const newObj = {}
            newObj.type = 'Object'
            for (const items in schema[key]) {
              if (items !== 'list_items' &&
              items !== 'type' &&
              items !== 'href' &&
              items !== 'ref'
              ) {
                newObj[items] = schema[key][items]
                delete schema[key][items]
              }
            }
            schema[key].list_items.push(newObj)
          // console.log(newObj, 'check obj')
          }
          this.addListItems(schema[key])
        }
      }

      return this.generateJSONFromSchema(schema)
    },
    generateJSONFromSchema (schema) {
      const result = {}

      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key]
          const valueType = value.type

          switch (valueType) {
            case 'String':
              result[key] = '(empty)'
              break
            case 'Date':
              result[key] = '(empty)'
              break
            case 'Boolean':
              result[key] = 'false'
              break
            case 'Integer':
              result[key] = 0
              break
            case 'Object':
              result[key] = this.generateJSONFromSchema(value)
              break
            case 'Array':
              if (value.list_items) {
                const arrayLength = value.list_items.length
                const arrayResult = []

                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(this.generateJSONFromSchema(value.list_items[i]))
                }

                result[key] = arrayResult
              } else {
                result[key] = []
              }
              break
            default:
              break
          }
        }
      }

      return result
    },
  }
}

</\script>

      `,
    };
  },
  methods: {
    log() {
      // console.log(e);
    },
    isDateType(item) {
      const regex =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;
      return typeof item === "string" && regex.test(item);
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    supplyJSON() {
      const payload = {
        NewLocation: {
          type: "String",
          required: false,
          ref: "",
          schema: "Location",
          list: false,
          reference: false,
        },
        Location: {
          type: "String",
          required: false,
          ref: "",
          schema: "Location",
          list: false,
          reference: false,
        },
        Date: {
          type: "Date",
          required: false,
          ref: "",
          schema: "province",
          list: false,
          reference: false,
        },
        NewDate: {
          type: "Date",
          required: false,
          ref: "",
          schema: "province",
          list: false,
          reference: false,
        },
        Province: {
          type: "Number",
          required: false,
          ref: "",
          schema: "province",
          list: false,
          reference: false,
        },
        NewProvince: {
          type: "Number",
          required: false,
          ref: "",
          schema: "province",
          list: false,
          reference: false,
        },
        sampleObject: {
          type: "Object",
          required: false,
          ref: "",
          schema: "sampleObject",
          list: false,
          reference: false,
        },
        sampleObject2: {
          type: "Array",
          required: false,
          ref: "",
          schema: "sampleObject",
          list: false,
          reference: false,
        },
      };

      const copyOfPayload = JSON.parse(JSON.stringify(payload));
      return { Data: this.addListItems(copyOfPayload) };
    },
    addListItems(schema) {
      for (const key in schema) {
        if (typeof schema[key] === "object" && schema[key] !== null) {
          if (schema[key].type === "Array") {
            schema[key].list_items = [];
            const newObj = {};
            newObj.type = "Object";
            for (const items in schema[key]) {
              if (
                items !== "list_items" &&
                items !== "type" &&
                items !== "href" &&
                items !== "ref"
              ) {
                newObj[items] = schema[key][items];
                delete schema[key][items];
              }
            }
            schema[key].list_items.push(newObj);
            // console.log(newObj, 'check obj')
          }
          this.addListItems(schema[key]);
        }
      }

      return this.generateJSONFromSchema(schema);
    },
    generateDate() {
      const currentDate = new Date();
      const year = currentDate.getUTCFullYear();
      const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getUTCDate()).padStart(2, "0");
      const hours = String(currentDate.getUTCHours()).padStart(2, "0");
      const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(currentDate.getUTCMilliseconds()).padStart(
        3,
        "0"
      );

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
    },
    generateJSONFromSchema(schema) {
      const result = {};

      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key];
          const valueType = value.type;
          switch (valueType) {
            case "String":
              result[key] = "(empty)";
              break;
            case "Date":
              result[key] = this.generateDate();
              break;
            case "Boolean":
              result[key] = "false";
              break;
            case "Integer":
              result[key] = 0;
              break;
            case "Number":
              result[key] = 0;
              break;
            case "Object":
              result[key] = Object.keys(this.rData).includes(key)
                ? "{ }"
                : this.generateJSONFromSchema(value);
              break;
            case "Array":
              if (value.list_items) {
                const arrayLength = value.list_items.length;
                const arrayResult = [];

                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(
                    this.generateJSONFromSchema(value.list_items[i])
                  );
                }

                result[key] = Object.keys(this.rData).includes(key)
                  ? "[ ]"
                  : arrayResult;
              } else {
                result[key] = Object.keys(this.rData).includes(key)
                  ? "[ ]"
                  : [];
              }
              break;
            default:
              break;
          }
        }
      }

      return result;
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>

<style></style>
