<template>
  <div
    :style="
      position === 'horizontal'
        ? 'display: flex; flex-direction: row; flex-flow: row wrap;'
        : 'display: flex; flex-direction: column'
    "
    style="gap: 10px"
  >
    <div
      v-for="(option, index) in options"
      :key="index"
      class="rectangle-label"
      @click="selectOption(option[propValue], option)"
    >
      <div
        class="rectangle-custom"
        :class="[
          { selected: selectedOption === option[propValue] },
        ]"
        @mouseenter="hoverOption(option[propValue], option)"
      >
        <span
          class="rectangle-text"
          :class="{
            ellipsis: showEllipsis,
            selected: selectedOption === option[propValue],
          }"
        >
          <slot :option="option">{{ option[propValue] }}</slot>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HlxRectangleSelect",
  props: {
    propValue: {
      type: String,
      default: "name",
    },
    position: {
      type: String,
      default: "horizontal",
    },
    options: {
      type: Array,
      default: () => [],
    },
    preValue: {
      type: String,
      default: "",
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["selectedOption", "hoveredOption"],
  data() {
    return {
      selectedOption: "",
    };
  },
  watch: {
    
    preValue: {
      handler(val) {
        // Find the object in options array where value matches preValue
        const selectedObject = this.options.find(
          (option) => option[this.propValue] === val
        );
        if (selectedObject) {
          this.selectedOption = selectedObject[this.propValue];
        } else {
          // If no match is found, set selectedOption to empty string
          this.selectedOption = "";
        }
      },
      immediate: true, // This ensures that the watcher runs immediately when the component is created
    },
  },
  methods: {
    selectOption(option, optionVal) {
      this.selectedOption = option;
      this.$emit("selectedOption", optionVal);
      console.log("Selected option:", this.selectedOption, optionVal);
    },
    hoverOption(option, optionVal) {
      this.$emit("hoveredOption", optionVal);
      console.log("Hovered option:", optionVal);
    },
  },
};
</script>

<style lang="scss">
.rectangle-label {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;

  .rectangle-custom {
    min-height: 40px;
    border: 1px solid #d8d8d8;
    width: fit-content;
    border-radius: 3px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover {
      border-color: #54bd95;
    }

    &.selected {
      border-color: transparent;
      box-shadow: 0 0 0 2px #54bd95;
      font-weight: 600;
      color: #54bd95 !important;
    }

    .rectangle-text {
      color: #000;
      white-space: nowrap;
      overflow: hidden;

      &.ellipsis {
        text-overflow: ellipsis;
        max-width: 100px;
      }

      &.selected {
        color: #54bd95 !important;
        font-weight: 600;
      }
    }
  }
}
</style>
