<template>
  <div class="ui-kit-layout">
    <div class="left-panel">
      <section class="title">
        <router-link to="/"
          ><img
            class="logo"
            src="./assets/img/Halleyx-Logo-Line-Lite.png"
            alt="Halleyx"
            srcset=""
        /></router-link>
        <!-- <i class="icon-full-rated-star"></i> -->
      </section>
      <section class="search-container-wrapper">
        <hlx-search
          v-model:modal-value="searchValue"
          :search-style="'style1'"
          @search-key="handleSearchData"
        />
        <div ref="search-result-container" class="search-result">
          <p
            v-for="data in searchData"
            :key="data"
            @click="searchedRouting(data)"
          >
            {{ data.label }}
          </p>
        </div>
      </section>
      <section class="body">
        <hlx-tree-select
          :tree-data="side_nav_data"
          :editable="false"
          :head-select="false"
          :expanded="true"
          :background="false"
          @current-value="handleCurrentData"
        />
      </section>
    </div>
    <div class="right-panel">
      <div
        v-show="show_side_panel"
        class="side-panel-backdrop"
        @click="closePanel"
      >
        <div v-show="show_side_panel" id="mySidePanel" class="side-panel">
          <div
            v-for="(obj, key) in mobile_side_nav"
            :id="'collapsible' + key"
            :key="key"
            class="collapsible"
          >
            <div class="main-title" @click="expandCollapse($event, key)">
              {{ obj.title }}
              <i class="icon-angle-right-regular expand-icon"></i>
            </div>
            <div :id="'collapsed' + key" class="parent-collapsed-container">
              <div
                v-for="(item, key2) in obj.child"
                :key="key2"
                class="child-item-container"
              >
                <div
                  v-show="item.subTitle !== ''"
                  class="child-items-sub-title"
                >
                  {{ item.subTitle }}
                </div>
                <div
                  v-show="
                    obj.title.toLowerCase() === 'components' &&
                    typeof item.label !== 'undefined'
                  "
                  class="child-items-2"
                  :class="{ 'active-menu-item': item.active }"
                  @click="handleCurrentData(item.name)"
                >
                  {{ item.label }}
                </div>
                <div
                  v-show="
                    obj.title.toLowerCase() !== 'components' &&
                    typeof item.label !== 'undefined'
                  "
                  class="child-items"
                  :class="{ 'active-menu-item': item.active }"
                  @click="handleCurrentData(item.name)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header mobile-view-logo">
        <img
          class="mobile-logo"
          src="./assets/img/Halleyx-Logo-Line-Lite.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="header mobile-header">
        <span class="mobile-view-menu"
          ><i class="icon-menu-regular" @click="toggleSidePanel"></i
        ></span>
        <span class="search-container-wrapper1">
          <hlx-search
            v-model:model-value="searchValue"
            :search-style="'style1'"
            @search-key="handleSearchData"
          />
          <div ref="search-result-container1" class="search-result1">
            <p
              v-for="data in searchData"
              :key="data"
              @click="searchedRouting(data)"
            >
              {{ data.label }}
            </p>
          </div>
        </span>
        <!-- <section class="icon-search-area">
    <span class="search-bar">
      <hlx-search @searchkey="search" />
    </span>
  </section> -->
      </div>
      <div id="comp-body" class="component-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import hlxSearch from "./components/SearchComponent.vue";
import hlxTreeSelect from "./components/TreeSelectComponent.vue";
import routes from "../routes";
export default {
  name: "App",
  components: { hlxSearch, hlxTreeSelect },
  data() {
    return {
      searchValue: "",
      show_side_panel: false,
      search: "",
      side_nav_data: [],
      searchData: [],
      allData: [],
      lastdata: {},
      mobile_side_nav: [
        {
          title: "Getting started",
          child: [
            {
              name: "home",
              label: "Introduction",
              active: true,
            },
            {
              name: "installation",
              label: "Installation",
              active: false,
            },
            {
              name: "quickstart",
              label: "Quickstart",
              active: false,
            },
            {
              name: "accessibility",
              label: "Accessibility",
              active: false,
            },
          ],
        },
        {
          title: "Advanced",
          child: [
            {
              name: "custom_theming",
              label: "Custom theming",
              active: false,
            },
          ],
        },
        {
          title: "Design element",
          child: [
            {
              name: "color",
              label: "Color",
              active: false,
            },
            {
              name: "typography",
              label: "Typography",
              active: false,
            },
            // {
            //   name: "icon",
            //   label: "Icon",
            //   active: false,
            // },
          ],
        },
        {
          title: "Components",
          child: [
            {
              subTitle: "Basic",
            },
            {
              name: "alert",
              label: "Alert",
              active: false,
            },
            {
              name: "badge",
              label: "Badge",
              active: false,
            },
            {
              name: "modal",
              label: "Modal",
              active: false,
            },
            {
              name: "tag",
              label: "Tag",
              active: false,
            },
            {
              name: "tooltip",
              label: "Tooltip",
              active: false,
            },
            {
              name: "label",
              label: "Label",
              active: false,
            },
            {
              name: "rating",
              label: "Rating",
              active: false,
            },
            {
              subTitle: "Input",
            },
            {
              name: "inputFields",
              label: "Input",
              active: false,
            },
            {
              name: "button",
              label: "Button",
              active: false,
            },
            {
              name: "slider",
              label: "Slider",
              active: false,
            },
            {
              name: "stepper",
              label: "Step control",
              active: false,
            },
            {
              name: "select",
              label: "Select",
              active: false,
            },
            {
              name: "multiselect",
              label: "Multiselect",
              active: false,
            },
            {
              name: "cascader",
              label: "Cascader",
              active: false,
            },
                        {
              name: "calendarfilter",
              label: "Calendarfilter",
              active: false,
            },
            {
              name: "treeSelect",
              label: "Tree select",
              active: false,
            },
            {
              name: "search",
              label: "Search",
              active: false,
            },
            {
              name: "switch",
              label: "Switch",
              active: false,
            },
            {
              name: "code-editor",
              label: "Code editor",
              active: false,
            },
            {
              name: "context-menu",
              label: "Context menu",
              active: false,
            },
            {
              subTitle: "Data",
            },
            {
              name: "accordion",
              label: "Accordion",
              active: false,
            },
            {
              name: "email",
              label: "Email",
              active: false,
            },
            {
              name: "swatch",
              label: "Swatch",
              active: false,
            },
            {
              name: "DropdownColorPicker",
              label: "DropdownColorPicker",
              active: false,
            },
            {
              name: "cards",
              label: "Card",
              active: false,
            },
            {
              name: "imagegallery",
              label: "Image gallery",
              active: false,
            },
            {
              name: "dataview",
              label: "Data view",
              active: false,
            },
            {
              name: "table",
              label: "Table",
              active: false,
            },
            {
              name: "tabs",
              label: "Tab",
              active: false,
            },
            {
              name: "tree",
              label: "Tree",
              active: false,
            },
            {
              name: "speeddial",
              label: "Speed dial",
              active: false,
            },
            {
              name: "Colorpalette",
              label: "Color palette",
              active: false,
            },
            {
              name: "Texteditor",
              label: "Text editor",
              active: false,
            },
            {
              name: "object-viewer",
              label: "Object viewer",
            },
            {
              subTitle: "Navigation",
            },
            {
              name: "timeline",
              label: "Timeline",
              active: false,
            },
            {
              name: "breadcrumb",
              label: "Breadcrumb",
              active: false,
            },
            {
              name: "pagination",
              label: "Pagination",
              active: false,
            },
            {
              name: "dottedPagination",
              label: "Dotted pagination",
              active: false,
            },
            {
              name: "sideBar",
              label: "Sidebar",
              active: false,
            },
            {
              name: "drawer",
              label: "Drawer",
              active: false,
            },
            {
              subTitle: "Loader",
            },
            {
              name: "loader",
              label: "Loader",
              active: false,
            },
            {
              name: "importProgressBar",
              label: "Import progress bar",
              active: false,
            },
            {
              subTitle: "Utility",
            },
            {
              name: "draggables",
              label: "Draggable",
              active: false,
            },
          ],
        },
        {
          title: "Layout and page",
          child: [
            {
              name: "errorPage",
              label: "Error page",
              active: false,
            },
            {
              name: "dashboard",
              label: "Dashboard",
              active: false,
            },
            // {
            //   name: "signup",
            //   label: "Signup",
            //   active: false,
            // },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to) {
      // console.log(to,from);
      const a = to.name;
      this.allData.forEach((e) => {
        if (e.name === a) {
          e.active = true;
        } else {
          e.active = false;
        }
      });
      this.mobile_side_nav.forEach((item) => {
        item.child.forEach((childItem) => {
          if (childItem.name === a) {
            childItem.active = true;
          } else {
            childItem.active = false;
          }
        });
      });
    },
  },
  mounted() {
    this.isConsole(true)
    this.side_nav_data = routes.routes;
    this.side_nav_data.filter((routeData) => {
      this.allData = this.allData.concat(routeData.child);
      // routeData.
    });

    const a = sessionStorage.getItem("lastname");
    //  console.log(a);
    this.allData.forEach((e) => {
      if (e.name === a) {
        e.active = true;
      } else {
        e.active = false;
      }
    });
    this.mobile_side_nav.forEach((item) => {
      item.child.forEach((childItem) => {
        if (childItem.name === a) {
          childItem.active = true;
        } else {
          childItem.active = false;
        }
      });
    });
  },
  methods: {
    expandCollapse(e, key) {
      if (e.target.children[0]) {
        if (e.target.children[0].classList.contains("expand-icon")) {
        e.target.children[0].style.transform = "rotate(90deg)";
        document.getElementById("collapsed" + key).style.display = "block";
        e.target.children[0].classList.add("expand-icon-active");
        e.target.children[0].classList.remove("expand-icon");
      } else if (
        e.target.children[0].classList.contains("expand-icon-active")
      ) {
        e.target.children[0].style.transform = "rotate(0deg)";
        document.getElementById("collapsed" + key).style.display = "none";
        e.target.children[0].classList.add("expand-icon");
        e.target.children[0].classList.remove("expand-icon-active");
      }
      } else {
        if (e.target.classList.contains("expand-icon")) {
        e.target.style.transform = "rotate(90deg)";
        document.getElementById("collapsed" + key).style.display = "block";
        e.target.classList.add("expand-icon-active");
        e.target.classList.remove("expand-icon");
      } else if (
        e.target.classList.contains("expand-icon-active")
      ) {
        e.target.style.transform = "rotate(0deg)";
        document.getElementById("collapsed" + key).style.display = "none";
        e.target.classList.add("expand-icon");
        e.target.classList.remove("expand-icon-active");
      }
      }
      
    },
    toggleSidePanel() {
      this.show_side_panel = true;
    },
    closePanel(event) {
      if (event.target.className === "side-panel-backdrop") {
        this.show_side_panel = false;
      }
    },
    handleCurrentData(data) {
      sessionStorage.setItem("lastname", data);

      this.$router.push({ name: data });
      this.mobile_side_nav.forEach((item) => {
        item.child.forEach((childItem) => {
          if (childItem.name === data) {
            childItem.active = true;
          } else {
            childItem.active = false;
          }
        });
      });
      this.show_side_panel = false;
    },
    handleSearchData(searchData) {
      console.log(
        "🚀 ~ file: App.vue:105 ~ handleSearchData ~ searchData:",
        searchData
      );
      if (searchData !== "") {
        searchData = searchData.toLowerCase();
        this.$refs["search-result-container"].style.display = "block";
        this.$refs["search-result-container1"].style.display = "block";
        this.searchData = this.allData.filter(
          (data) =>
            data.name.toLowerCase().includes(searchData) ||
            data.label.toLowerCase().includes(searchData)
        );
      } else {
        this.$refs["search-result-container"].style.display = "none";
        this.$refs["search-result-container1"].style.display = "none";
      }
    },
    searchedRouting(data) {
      console.log(this.$refs[data.name + "-ref"]);
      this.allData.forEach((e) => {
        if (e.name === data.name) {
          e.active = true;
        } else {
          e.active = false;
        }
      });
      this.mobile_side_nav.forEach((item) => {
        item.child.forEach((childItem) => {
          if (childItem.name === data.name) {
            childItem.active = true;
          } else {
            childItem.active = false;
          }
        });
      });
      // console.log(this.allData);
      sessionStorage.setItem("lastname", data.name);
      // this.lastdata = data
      this.$router.push({ name: data.name });
      this.$refs["search-result-container"].style.display = "none";
      this.$refs["search-result-container1"].style.display = "none";
    },
  },
};
</script>

<!-- @import "./assets/icon-style.css"; -->
<style lang="scss">
@import "./assets/styles/index.scss";

body {
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #191a15;
}

// ::-webkit-scrollbar {
//   width: 4px;
//   height: 4px;
// }
// ::-webkit-scrollbar-track {
//   background-color: transparent;
//   border-radius: 20px;
// }
// ::-webkit-scrollbar-thumb {
//   background: $grey-dark;
//   border-radius: 10px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: $grey-dark;
// }

/*.right-panel>.component-body:has(div.dashboard-right-container){
  height: 90vh;
  padding: 0 25px;
}
.right-panel>.header +.component-body:has(div.dashboard-right-container){
  background: red;
}*/
.component-description code {
  margin: 0;
  border-radius: 4px;
  padding: 0.15rem 0.5rem;
  font-size: 14px;
  color: black;
  line-height: 1.4;
  background-color: #f5f7fa;
}
.left-panel {
  position: fixed;
  width: 251px;
  width: 251px;
  height: 100vh;
  z-index: 11;
  background: #001723;
  border-right: 1px solid #f0eeec;
  .search-container-wrapper {
    padding: 25px 19px;
    color: $primary-text-color;
    position: relative;
    .search-result {
      min-height: auto;
      right: 25px;
      width: 80%;
      z-index: 1;
      overflow: auto;
      height: auto;
      background: white;
      max-height: 235px;
      position: absolute;
      color: #191a15;
      padding: 10px 0;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display: none;
      p {
        margin: 0;
        padding: 10px 20px;
        color: $font-color;
        font-weight: 500;
        font-size: 14px;
        &:hover {
          color: #191a15;
          font-weight: bold;
          cursor: pointer;
          user-select: none;
          background: #a8dec9;
        }
      }
    }
  }
  .title {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -20px -19px 42px -8px #000000;
    .logo {
      width: 150px;
      margin-top: 5px;
      margin-right: 5px;
    }
  }

  .body {
    height: 75%;
    overflow-y: hidden;
    //margin: 10px 5px 10px 20px;
    &:hover {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .group {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      .side-heading {
        font-family: Quicksand;
        font-size: 16px;
        color: #f0eeec;
        margin-bottom: 10px;
      }

      .items {
        font-size: 14px;
        padding: 5px 0 5px 30px;
        margin: 0 5px;

        a {
          text-decoration: none;
          color: #d8d6d3;

          &:hover {
            color: $primary-color;
            border-radius: 2px;
          }

          &.router-link-exact-active {
            color: $primary-color-dark;
          }
        }
      }
    }
  }
}

.right-panel {
  position: absolute;
  width: 100%;
  height: 100vh;
  padding-left: 250px;
  overflow: hidden;
  background: linear-gradient(181deg, #f7f7f7, transparent);
  background: -moz-linear-gradient(
    top,
    #f7f7f7 0%,
    transparent 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f7f7f7),
    color-stop(100%, transparent)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #f7f7f7 0%,
    transparent 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #f7f7f7 0%,
    transparent 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    #f7f7f7 0%,
    transparent 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    #f7f7f7 0%,
    transparent 100%
  ); /* W3C */
  .header {
    height: 60px;
    display: flex;
    border-bottom: 1px solid $grey;
    padding: 10px 10px;
    align-items: center;
    .search-bar {
      width: 30%;
      margin-left: auto;
    }
    .mobile-view-menu {
      font-size: 1.75rem;
      padding-left: 15px;
    }
    .mobile-logo {
      width: 170px;
      display: none;
    }
    .mobile-view-menu,
    .mobile-view-logo {
      display: none;
    }
  }

  .mobile-header {
    justify-content: space-between;
    .search-container-wrapper {
      display: none;
    }
  }
  .header.mobile-view-logo {
    display: none;
    background-color: #001723;
  }
  .component-body {
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 25px;
    height: 90vh;
    padding-bottom: 60px;
  }
}
.code_editor.hljs.read_only.atom_one_light {
  .header > .copy_code[data-v-76b5d460] {
    position: absolute;
    top: 49px;
    right: 12px;
  }
}
.code_editor {
  width: 100% !important;
  // height: 600px !important;
  & .code_area {
    // height: 600px !important;
    overflow: auto !important;
  }
  & .header {
    padding: 20px 10px !important;
  }
}
.source .code_editor > .code_area {
  overflow: auto !important;
}
.el-table th.el-table__cell > .cell {
  color: #191a15;
}
.el-table td.el-table__cell div {
  color: #191a15;
}
.search-container-wrapper1,
.search-result1 {
  display: none;
}
.mobile-nav-divider {
  display: none;
}
.mobile-next-page-nav {
  display: none;
  align-items: center;
  justify-content: space-between;
}
.side-panel-backdrop {
  display: none;
}
@media only screen and (max-width: 600px) {
  .side-panel-backdrop {
    display: block;
  }
  .mobile-nav-divider {
    display: block;
  }
  .mobile-next-page-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .nxt-btn,
    .prev-btn {
      cursor: pointer;
      color: #2879b7;
      height: 40px;
      display: flex;
      align-items: center;
      .next-icon {
        margin-left: 5px;
        margin-top: 2px;
      }
      .prev-icon {
        margin-right: 5px;
        margin-top: 2px;
      }
    }
  }
  .left-panel {
    display: none;
  }
  .right-panel {
    padding-left: 0px;
    .header {
      .mobile-view-menu,
      .mobile-view-logo {
        display: block;
      }
      .mobile-view-menu {
        cursor: pointer;
      }
      .mobile-logo {
        width: 170px;
        display: flex;
      }
    }
    .mobile-header {
      justify-content: space-between;
      .search-container-wrapper1 {
        display: block;
      }
    }
    .header.mobile-view-logo {
      display: flex;
    }
  }

  .mobile-header {
    .search-container-wrapper1 {
      padding: 25px 19px;
      color: $primary-text-color;
      position: relative;
      .search-result1 {
        min-height: auto;
        right: 25px;
        width: 80%;
        z-index: 1;
        overflow: auto;
        height: auto;
        background: white;
        max-height: 235px;
        position: absolute;
        color: #191a15;
        padding: 10px 0;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        // display: none;
        p {
          margin: 0;
          padding: 10px 20px;
          color: $font-color;
          font-weight: 500;
          font-size: 14px;
          &:hover {
            color: #191a15;
            font-weight: bold;
            cursor: pointer;
            user-select: none;
            background: #a8dec9;
          }
        }
      }
    }
  }
  .side-panel-backdrop {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 2010;
    .side-panel {
      height: calc(100% - 58px);
      position: relative;
      top: 58px;
      width: 80%;
      overflow: auto;
      overflow-x: hidden;
      background: #001723;
      animation: myfirst 0.3s 1;
      color: white;
      box-sizing: border-box;

      .collapsible {
        padding: 15px;
        .main-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          animation: mysecond 1.5s 1;
          cursor: pointer;
        }
        .child-items-sub-title {
          margin-left: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
          animation: mysecond 1.5s 1;
        }
        .child-items {
          padding: 5px 10px;
          margin-left: 10px;
          border-radius: 5px;
          cursor: pointer;
          animation: mysecond 1.5s 1;
        }
        .child-items-2 {
          padding: 5px 10px;
          margin-left: 30px;
          border-radius: 5px;
          cursor: pointer;
          animation: mysecond 1.5s 1;
        }
        .child-items:hover,
        .child-items-2:hover {
          background: $primary-color;
          color: #000000;
        }
        .active-menu-item {
          background: $primary-color-dark;
          color: #fff;
        }
        .active-menu-item:hover {
          background: $primary-color-dark;
          color: #fff;
        }
        .expand-icon,
        .expand-icon-active {
          cursor: pointer;
        }
        .parent-collapsed-container {
          display: none;
          animation: mysecond 0.2s 1;
        }
      }
    }
    @keyframes myfirst {
      0% {
        width: 0%;
      }
      100% {
        width: 80%;
      }
    }
    @keyframes mysecond {
      0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
