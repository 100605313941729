<template>
  <h1 class="component-heading">Context menu</h1>
  <p class="component-description">
    A contextual menu is a type of menu that appears on demand and contains a
    small set of relevant actions related to a control
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h1 class="component-side-heading">Basic usage</h1>
  <p class="component-description">
    Supply the options for your context-menu component using the
    <b>:options</b> attribute.
  </p>
  <div class="component-example">
    <div class="example-body">
      <span v-for="(item,i) in 8" :id="'Context-menu'+i" :key="item+i" class="temppp-container" >
        <hlx-button class="primary temppp-container"  @click="display(i,'Context-menu'+i)"
          >Click me</hlx-button
        >
        <hlx-context-menu
          :options="options"
           :show="clickInd == i"
           :top="containertop"
           :left="containerleft"
           :conditionup="55"
           :conditionvalue="150"
           @chosen="receivedVal($event,i)"
           @close-context-menu="closeOuterOverlay"
        />
      </span>
    </div>
    <div class="example-footer">
      <span
        id="basic-context-menu-icon"
        class="icon"
        @click="showCode('basic-context-menu')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="basic-context-menu" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="contextmenu_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!-- <hlxTable :data="this.contextmenuAttributes"
       :border="true"
       theme="secondary"
       :rowHover="true"
       :ellipses="5"
       :bold_header="true">
       <hlx-column :prop="'name'" :label="'Name'" :type="'string'" />
       <hlx-column :prop="'description'" :label="'Description'" :type="'string'" />
       <hlx-column :prop="'default'" :label="'Default'" :type="'string'" />
       <hlx-column :prop="'type'" :label="'Type'" :type="'string'" :width="200"/>
       <hlx-column :prop="'accepted_values'" :label="'Accepted values'" :type="'string'"/>
       <hlx-column :prop="'mandatory'" :label="'Mandatory'" :type="'string'" />
   </hlxTable> -->
  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!-- <hlx-table
       :data="this.contextmenuEvents"
       :border="true"
       theme="secondary"
       :rowHover="true"
       :bold_header="true"
     >
       <hlx-column :prop="'Name'" :label="'Name'" :type="'string'" />
       <hlx-column :prop="'Description'" :label="'Description'" :type="'string'" :width="400"/>
       <hlx-column :prop="'Parameters'" :label="'Parameters'" :type="'string'" />
     </hlx-table> -->
     <divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'code-editor')"
      ><i class="icon-angle-left-regular prev-icon"></i>Code editor</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'accordion')"
        >Accordion <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import hlxButton from "../components/ButtonComponent.vue";
import hlxContextMenu from "../components/ContextMenuComponent.vue";
import divider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
export default {
  components: {
    hlxButton,
    hlxContextMenu,
    divider,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    CodeEditor,
  },
  data() {
    return {
      containertop:0,
      containerleft:0,
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      editor_theme: "light",
      clicked: false,
      options: [
        {
          label: "Add",
          icon: "icon-plus-circle-regular",
          checked: false,
        },
        {
          label: "Edit",
          icon: 'icon-edit-regular',
          checked: false,
        },
      ],
      contextmenu_source: `
   <template>
           <span v-for="(item,i) in 8" :id="'Context-menu'+i" :key="item+i" class="temppp-container" >
              <hlx-button class="primary temppp-container"  @click="display(i,'Context-menu'+i)"
                >Click me</hlx-button
              >
              <hlx-context-menu
                :options="options"
                :show="click == i"
                :top="containertop"
                :left="containerleft"
                :conditionup="55"
                :conditionvalue="150"
                @chosen="selectedValue"
                @close-context-menu="closeOuterOverlay"
              />
            </span>
         </template>
   
   <script>
   export default{
       data(){
           return{
            click:-1,
            options: [
              {
                label: "Add",
                icon: "icon-plus-circle-regular",
                checked: false,
              },
              {
                label: "Edit",
                icon: 'icon-edit-regular',
                checked: false,
              },
            ],
             }
           },
           methods:{
            display(i,id) {
              this.containerleft = document.getElementById(id).getBoundingClientRect().left +5 
              this.containertop = document.getElementById(id).getBoundingClientRect().top + 50
              this.click = i
              },
              closeOuterOverlay()
              {
                  this.click = -1         // Click the Outer overlay
              },
              selectedValue(value)
              {
                 this.click = -1 
                 console.log(value)         // click the selected value
              }
           }

         }
       `,
      table_data: [
        {
          name: "options",
          description: "Include your list for context menu items",
          type: "Array of objects",
          accepted_values: "[{}]",
          default: "-",
          mandatory: true,
        },
        {
          name: "show",
          description: "Toggle visibility of the context menu",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: false,
        },
        {
          name: "top",
          description: "Position the context menu using top offset",
          type: "Number",
          accepted_values: "",
          default: "0",
          mandatory: false,
        },
        {
          name: "left",
          description: "Position the context menu using left offset",
          type: "Number",
          accepted_values: "",
          default: "0",
          mandatory: false,
        },
        {
          name: "conditionvalue",
          description: "",
          type: "Number",
          accepted_values: "",
          default: "0",
          mandatory: false,
        },
        {
          name: "conditionup",
          description: "",
          type: "Number",
          accepted_values: "",
          default: "0",
          mandatory: false,
        },
      ],
      clickedVal:"",
      clickInd:-1,
      event_data: [
        {
          name: "chosen",
          description: "Emits the option chosen",
          parameter: "-",
        },
        {
          name: "close-context-menu",
          description: "Emits the close outer overlay",
          parameter: "-",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("click", this.hide);
  },
  methods: {
    closeOuterOverlay()
    {
        this.clickInd = -1
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    hide() {
      if (event.target.classList.contains("temppp-container") == false) {
        this.clicked = false;
      }
      // }
    },

    receivedVal(v,i) {
      console.log("🚀 ~ file: ContextMenuView.vue:365 ~ receivedVal ~ v,i:", v,i)
      this.clickedVal = i
      this.clickInd = -1
      // this.clicked = false
      this.clicked = false;
    },

    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    display(i,id) {
      this.containerleft = document.getElementById(id).getBoundingClientRect().left +5 
      this.containertop = document.getElementById(id).getBoundingClientRect().top + 50
      this.clickInd = i
    },
  },
};
</script>

<style lang="scss"></style>
