<template>
    <p>aa</p>
    <!-- <div v-for="(value, key) in accoditonData" :key="key">
      {{ key }} 
      {{ value }}
    </div> -->
    <hlx-filter-search 
    :filter-search-options="dropdownData" 
    :module="'ProductOffering'"
    :filter-data="accordionData"
    :filter-checked-data="filterChecked" 
    :key-value-from-query-array="'key'" 
    :filter-value-object-name="'value'" 
    @searched-value="getSearchedValue">
    
    </hlx-filter-search>
    <!-- <hlx-checkbox :options="this.options2" :type="'checkbox'" :show-select-all="true"/> -->
</template>

<script>
import HlxFilterSearch from '../components/FilterSearchComponent.vue'
// import hlxCheckbox from'../components/InputComponent.vue'
export default{
    data(){
        return{
            filterChecked:
                [
                    {
                    key:"Multi device feature",
                    fieldtoFilter:"multidevicefeature.mediumtype"
                    },
                    {
                     key:"Environment",
                     fieldtoFilter:"environment.mediumtype"
                    },
                    {
                        key:"color",
                        fieldtoFilter:"color.mediumtype"
                    },
                    {
                        key:"Ports",
                        fieldtoFilter:"ports.mediumtype"
                    }

                ],
            accordionData: {
        Environment: [
          {
            count: 1,
                value: {
                value:
                "Operating Temperature",
              type: "String",
              valueSuffix: "Each",
            },
          },
        ],
        "Multi device feature": [
          {
            count: 1,
            value: {
              value: "TV to Mobile",
              id: "",
              type: "String",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "TV Sound to Mobile",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "Mobile to TV",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "Lminiariaturn proiorum.",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        "Wireless Transmission Rate:": [
          {
            count: 1,
            value: {
              value: "up to 300 Mbit/s over a 2.4 GHz Wi-Fi network",
              type: "String",
            },
          },
        ],
        ModelType: [
          {
            count: 1,
            value: {
              value: "",
            },
          },
        ],
        "WiFi Speeds": [
          {
            count: 1,
            value: {
              value: "N300 2.4 GHz: 300 Mbps (802.11n)",
              type: "String",
              valueSuffix: "Each",
            },
          },
        ],
        Name: [
          {
            count: 1,
            value: {
              value: "",
            },
          },
          {
            count: 1,
            value: {
              value: "Modem",
              type: "String",
            },
          },
        ],
        EquipmentType: [
          {
            count: 2,
            value: {
              value: "",
            },
          },
        ],
        ModelFamily: [
          {
            count: 1,
            value: {
              value: "Modern",
            },
          },
        ],
        color: [
          {
            count: 1,
            value: {
              value: "Others",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "green",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "red",
              valueSuffix: "",
            },
          },
        ],
        Processor: [
          {
            count: 1,
            value: {
              value: "Single-Core CPU",
              type: "String",
              valueSuffix: "Each",
            },
          },
        ],
        "Bridge-like Mode": [
          {
            count: 1,
            value: {
              value: "false",
              id: "",
              type: "string",
            },
          },
          {
            count: 1,
            value: {
              value: "true",
              checked: false,
              disabled: false,
            },
          },
        ],
        WiFi: [
          {
            count: 1,
            value: {
              value: "off",
              id: "",
              type: "string",
            },
          },
          {
            count: 1,
            value: {
              value: "on",
              type: "String",
            },
          },
        ],
        test: [
          {
            count: 1,
            value: {
              value: "test",
              valueSuffix: "",
            },
          },
        ],
        ModelNumber: [
          {
            count: 1,
            value: {
              value: "",
            },
          },
        ],
        "Operating System": [
          {
            count: 1,
            value: {
              value: "Linux",
              type: "String",
              valueSuffix: "",
            },
          },
        ],
        OrgUniqueId: [
          {
            count: 1,
            value: {
              value: "",
            },
          },
        ],
        Ports: [
          {
            count: 1,
            value: {
              value: "Others",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Console",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "LAN",
              id: "",
              type: "String",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "WAN",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
        ],
        "WiFi Range": [
          {
            count: 1,
            value: {
              value: "2× Fixed Antennas",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "2 Bedroom Houses",
              id: "",
              type: "String",
              valueSuffix: "Each",
            },
          },
        ],
        "Upload speed": [
          {
            count: 1,
            value: {
              value: "2 Mbps",
              type: "String",
              valueSuffix: "",
            },
          },
          {
            count: 1,
            value: {
              value: "12 Mbps",
              id: "",
              type: "string",
              valueSuffix: "",
            },
          },
          {
            count: 1,
            value: {
              value: "6 Mbps",
              id: "",
              type: "string",
              valueSuffix: "",
            },
          },
        ],
        "Network Security": [
          {
            count: 1,
            value: {
              value: "Application Layer Gateway",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "SPI Firewall",
              valueSuffix: "",
            },
          },
          {
            count: 1,
            value: {
              value: "Access Control",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "IP & MAC Binding",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        NoOfEmailAddresses: [
          {
            count: 1,
            value: {
              value: "",
              checked: false,
              disabled: false,
            },
          },
        ],
        Series: [
          {
            count: 1,
            value: {
              value: "9",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        Model: [
          {
            count: 1,
            value: {
              value: "",
            },
          },
        ],
        "Dimensions (W×D×H)": [
          {
            count: 1,
            value: {
              value: "6.9",
              id: "",
              type: "String",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "1.3",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "4.6 ",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        "test feature": [
          {
            count: 1,
            value: {
              value: "B",
              id: "",
              type: "string",
            },
          },
          {
            count: 1,
            value: {
              value: "C",
              id: "",
              type: "string",
            },
          },
          {
            count: 1,
            value: {
              value: "A",
              type: "String",
            },
          },
        ],
        Size: [
          {
            count: 1,
            value: {
              value: "6.5",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "7.5",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "6.7",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "6.1",
              valueSuffix: "Each",
            },
          },
        ],
        "Working Modes": [
          {
            count: 1,
            value: {
              value: "Range Extender Mode",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "Router Mode",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "Access Point Mode",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "WISP Mode",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        testchar: [
          {
            count: 1,
            value: {
              value: "test",
              type: "String",
              valueSuffix: "Each",
            },
          },
        ],
        Colors: [
          {
            count: 1,
            value: {
              value: "others",
              id: "",
              type: "String",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "red",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        "Dimensions and Weight": [
          {
            count: 1,
            value: {
              value:
                "Height 29 mm  Width 257.6 mm  Depth 163.5 mm  Weight About 216 g (without packaging)",
              type: "String",
            },
          },
        ],
        "Wireless standards": [
          {
            count: 1,
            value: {
              value: "Wi-Fi 4 IEEE 802.11n/b/g 2.4 GHz",
              type: "String",
              valueSuffix: "Each",
            },
          },
        ],
        Standards: [
          {
            count: 1,
            value: {
              value:
                "802.3u, 802.1D, 802.1p, 802.1X (security authentication), 802.1Q (VLAN), IPv4 (RFC 791), IPv6 (RFC 2460), Routing Information Protocol (RIP) v1 (RFC 1058), RIP v2 (RFC 1723)",
              type: "String",
              valueSuffix: "",
            },
          },
        ],
        "WAN Types": [
          {
            count: 1,
            value: {
              value: "PPTP",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "L2TP",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "PPPoE",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "Dynamic IP",
              valueSuffix: "Each",
            },
          },
          {
            count: 1,
            value: {
              value: "Static IP",
              id: "",
              type: "string",
              valueSuffix: "Each",
            },
          },
        ],
        "Number of devices": [
          {
            count: 1,
            value: {
              value: "2",
              id: "",
              type: "string",
              valueSuffix: "",
            },
          },
          {
            count: 1,
            value: {
              value: "3",
              id: "",
              type: "string",
              valueSuffix: "",
            },
          },
        ],
        Color: [
          {
            count: 1,
            value: {
              value: "Grey",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Black",
              id: "",
              type: "String",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Silver",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Blue",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Gold",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
        ],
        EOMProductId: [
          {
            count: 7,
            value: {
              value: "",
            },
          },
        ],
        Memory: [
          {
            count: 1,
            value: {
              value: "512",
              id: "",
              type: "string",
              valueSuffix: "GB",
            },
          },
          {
            count: 1,
            value: {
              value: "128",
              id: "",
              type: "String",
              valueSuffix: "GB",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "256",
              id: "",
              type: "string",
              valueSuffix: "GB",
            },
          },
        ],
        Ownership: [
          {
            count: 2,
            value: {
              value: "",
            },
          },
        ],
        "Transmission Standard:": [
          {
            count: 1,
            value: {
              value: "802.11ax/ac/a/n 2 x 2 & 802.11b/g/n 2 x 2, MIMO",
              type: "String",
            },
          },
        ],
        Winback: [
          {
            count: 1,
            value: {
              value: "Good Copper - FTTN Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Take the Hill - FTTB Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Good Copper - ATM Winback",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Fibe Internet 940 - FTTB Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Best Fibre Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Fibe Internet 5 - FTTN Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Gigabit Fibe Internet 3.0 Gbps - Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Fibe Internet 300 - FTTB Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Better Copper - ATM Winback",
              id: "",
              type: "string",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Good Fibre Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Gigabit Fibe Internet 5.0 Gbps - Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Fibe Internet 500 - FTTB Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Better Fibre Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Gigabit Fibe Internet 8.0 Gbps - Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Generic Winback Product",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
          {
            count: 1,
            value: {
              value: "Business Gigabit Fibe Internet 1.5 Gbps - Winback",
              id: "",
              type: "string",
              valueSuffix: "Each",
              checked: false,
              disabled: false,
            },
          },
        ],
      },
            dropdownData: [
        { name: 'Racheal'},
        { name: 'Joey' },
        { name: 'Ross' },
        { name: 'Phoebe' },
        { name: 'Monica' },
        { name: 'Gunther' },
        {name:"cena"},
        {name:"taker"},
        {name:"ajstyle"},
        {name:"roman"},
        {name:"ambrose"},
        {name:"seth"},
        {name:"tripelh"},
        {name:"bigshow"},
        {name:"Mark"},
        {name:"Antony"},
        {name:"kane"}
      ]
        }
    },
//    async mounted(){
//         const loginUser = await MLTAxiosInstance.get(
//           "partymanagement/individual/auth"
//         );
//         const obj = {
//           company: loginUser.data.company,
//           layoutFor: "accounts",
//           layout: [
//             {
//               prop: "status",
//               label: "Status",
//               type: "String",
//               fieldToFilter: "",
//             },
//             {
//               prop: "contactMedium",
//               label: "Contact medium",
//               type: "Array",
//               fieldToFilter: "mediumType",
//             },
//           ],
//         };
//         const supplierFilterRes = await MLTAxiosInstance.post(
//           "util/filterLayout",
//           obj
//         );
//         state.suppliersFilterData = supplierFilterRes.data;
//     },
    components:{
        HlxFilterSearch,
        // hlxCheckbox
    },
    methods:{
        getSearchedValue(value){
            console.log("I am from child :",value);
        }
    }
    
}
</script>