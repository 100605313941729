<template>
  <h1 class="component-heading">Overlay</h1>
    <p class="component-description">
      The main objective of overlay component is to eliminate the components from overlapping one over the other.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    The overlay component uses slots which makes it more rowbust and can we utilize by any other component from halleyx-ui-framework.
  </p>
    <div class="component-example">
    <div class="example-body">
       <hlx-button class="primary" @click="open">Click</hlx-button>
       <hlx-overlay v-show="container === true" :show ="container" :top ="700" :left="800" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="close">
        <template #overlay>
          <div class="overlay-container" style="width:300px;height:300px;background-color:white;border-radius:6px;">
             <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi, id. Perspiciatis accusantium suscipit perferendis asperiores mollitia dignissimos facilis veniam magni, fugit maiores voluptates, nulla, vel tenetur nemo tempora aperiam ullam.</p>
          </div>
        </template>
       </hlx-overlay>
    </div>

    <div class="example-footer">
      <span
        id="basic-slider1-icon"
        class="icon"
        @click="showCode('basic-slider1')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
    <div id="basic-slider1" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="minmaxslider_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
</template>

<script>
import hlxOverlay from '../components/OverlayComponent.vue'
import hlxButton from '../components/ButtonComponent.vue'
export default {
components:{
    hlxOverlay,
    hlxButton
},
  data() {
    return {
       container:false,
    }
  },
  methods:
  {
    open()
    {
      this.container = true
    },
    close()
    {
      this.container = false
    }
  },
}
</script>

<style>

</style>