<template>
  <button id="badge" type="button" class="icon-button" :aria-label="icon">
    <span :class="icon"></span>
    <span class="icon-button__badge" style="font-size: 10px">{{ count }}</span>
  </button>
</template>

<script>
export default {
  name: "HlxBadge",
  props: {
    icon: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    }
  }
};
</script>
