<template>
  <div class="text-editor-container" :style="{ height: modal_height,border: border==true ?'1px solid #d8d8d8' : 'none',minHeight:emailComponent === true ? '350px' : '',maxHeight:emailComponent === true ? '100%' : ''}" >
    <!--working-->
    <div id="content" :class="'content'+unique">

       <div class="icon-container">
        <button id="undo" class="functionality icon" @click="executeCommand('undo')">   
        <i class="icon-arrow-back-regular"></i>
        </button>
        <button id="redo" class="functionality icon" @click="executeCommand('redo')"> 
        <i class="icon-arrow-forward-regular"></i>
        </button>

        <span class="font-name-functionality icon">
        <hlxselect
          :options="fontName"
          :label-animation="false"
          :text-editor="true"
          @custom-change="selectFontName"
        ></hlxselect>
        </span>
        <span class="font-size-functionality icon">
        <hlxselect
          :options="fontSize"
          :label-animation="false"
          :text-editor="true"
          @custom-change="selectFontSize"
        ></hlxselect>
        </span>

        <span class="highligtfunctionality icon">
          <colorpalette @custom-change="highlightValue"></colorpalette>
        </span>
        <span class="colorfunctionality icon">
          <colorpalette
            :highlight-color="true"
            @custom-change="colorValue"
          ></colorpalette>
        </span>

      <button id="b" class="functionality icon" @click="executeCommand('bold')">
            B
      </button>
      <button id="i" class="functionality icon" @click="executeCommand('italic')">
            I
      </button>
      <button id="u" class="functionality icon" @click="executeCommand('underline')">
            U
      </button>
      <button id="s" class="functionality icon" @click="executeCommand('strikethrough')">S</button>

      <button id="ul" class="functionality icon" @click="executeCommand('insertUnorderedList')"><i class="icon-list-regular"></i></button>
      <button id="ol" class="functionality icon" @click="executeCommand('insertOrderedList')"><i class="icon-list-filled"></i></button>

      <button id="justifyLeft" class="functionality icon" @click="executeCommand('justifyLeft')"><i class="icon-menu-left-alt-regular"></i></button>
      <button id="justifyCenter" class="functionality icon" @click="executeCommand('justifyCenter')"><i class="icon-menu-regular"></i></button>
      <button id="justifyRight" class="functionality icon" @click="executeCommand('justifyRight')"><i class="icon-menu-right-alt-regular"></i></button>
      <button id="justifyFull" class="functionality icon" @click="executeCommand('justifyFull')"><i class="icon-menu-regular"></i></button>

      <button id="sup" class="functionality icon" @click="executeCommand('superscript')"> X<sup>2</sup></button>
      <button id="sub" class="functionality icon" @click="executeCommand('subscript')"> X<sub>2</sub></button>

      <!-- <button id="link" class="functionality icon" @click="createlink">
          <i class="icon-link-alt-regular"></i>
      </button> -->


        <div :id="'context-menu'+unique" class="context-menu">
            <button class="context-menu-button"><i :id="'icon-more-vertical-filled'+unique" class="icon-more-vertical-filled" @click="displayContextmenu('icon-more-vertical-filled'+unique)"></i></button>
            <div v-if="showContextmenu" :id="'display-context-menu'+unique" class="display-context-menu" :style="{ top:containertop+'px', left:(containerleft-200) +'px'}">
              <span v-for="(i,index) in contextmenuOptions"  :key="index"  class="context-menu-value-container">
                  <span class="context-menu-each-value" :style="'display:flex;align-items;center'" @click="applyValues(i)">
                    <i v-if="i.icon" :class="i.icon" style="width:30px;"></i>
                    <p v-else-if="i.id == 'bold'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;font-weight:bold">B</p>
                    <p v-else-if="i.id == 'italic'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;font-style: italic;">I</p>
                    <p v-else-if="i.id == 'underline'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;text-decoration:underline">U</p>
                    <p v-else-if="i.id == 'strikethrough'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;text-decoration: line-through;">S</p>
                    <p v-else-if="i.id == 'superscript'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;"> X<sup>2</sup></p>
                    <p v-else-if="i.id == 'subscript'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;"> X<sub>2</sub></p>

                    <p style="padding-left:8px">{{i.value}}</p>
                  </span>
              </span>
            </div>
        </div>
       </div>
    </div>

    <!--read ony-->


    <!--Texteditor content-->
    <div
    :id="'textEditor'+unique"
    ref="editor"
      class="textEditor"
      :style="{ overflowY : emailComponent != true ? 'auto' : 'none'}"
      :contenteditable="!readOnly"
      @input="updateContent"
      @mouseup="cursorevent()"
    ></div>
    <slot name="slotValue">
      
    </slot>
  </div>
  <hlx-modal
    :text-editor="modal_active"
    :height="'216px'"
    :width="'400px'"
    :modal="true"
    @close="modal_active = false"
  >
    <template #header>
      <div class="texteditor-head-title">Link</div>
    </template>
    <template #content>
      <div class="modal-content">
        <button class="url-link">
          <input id="link-input" v-model="url" type="text" class="link-input" />
        </button>
      </div>
    </template>
    <template #footer>
      <button class="link-save-button" @click="link">Save</button>
    </template>
  </hlx-modal>
</template>

<script>
import hlxselect from "../components/SelectComponent.vue";
import colorpalette from "../components/ColorpaletteComponent.vue";
import hlxModal from "../components/ModalwindowComponent.vue";


export default {
  name: "HlxTextEditor",
  components: {
    colorpalette,
    hlxselect,
    hlxModal,
    // hlxButton,
  },
  props:{
    emailComponent:{
      type: Boolean,
      default:false
    },
    height: {
      type: String,
      default: "100%",
    },
    direction:{
      type: String,
      default:'top'
    },
    prevalue:{
      type: String,
      default:''
    },
    border:{
      type: Boolean,
      default:true
    },
    readOnly:{
      type: Boolean,
      default:false
    },
  },
  emits: ["current-change", "custom-value"],
  data() {
    return {
      selectedtagValue:[],
      containerleft:0,
      containertop:0,
      startContainer:null,
      startOffset:0,
      endContainer:null,
      endOffset:0,
      contextmenuOptions1:[],
      contextmenuOptions:[
        {value:'Bold',id:'bold'},
        {value:'Italic',id:'italic'},
        {value:'Underline',id:'underline'},
        {value:'Justify',id:'justifyFull'},
        {value:'Left align',id:'justifyLeft'},
        {value:'Right align',id:'justifyRight'},
        {value:'Center',id:'justifyCenter'},
        {value:'Bullet order list',id:'insertUnorderedList'},
        {value:'Numbered order list',id:'insertOrderedList'},
        {value:'Strikethrough',id:'strikethrough'},
        {value:'Link',id:'link'},
        {value:'Superscript',id:'superscript'},
        {value:'Subscript',id:'subscript'}
      ],
      unique: Math.floor(Math.random() * 1000 + 1),
      duplicateContextmenuOptions:[
        {value:'Bold',id:'bold',checked:false,tag:'b'},
        {value:'Italic',id:'italic',checked:false,tag:'i'},
        {value:'Underline',id:'underline',checked:false,tag:'u'},
        {value:'Justify',id:'justifyFull',checked:false,icon:'icon-menu-regular'},
        {value:'Left align',id:'justifyLeft',checked:false,icon:'icon-menu-left-alt-regular'},
        {value:'Right align',id:'justifyRight',checked:false,icon:'icon-menu-right-alt-regular'},
        {value:'Center',id:'justifyCenter',checked:false,icon:'icon-menu-regular'},
        {value:'Bullet order list',id:'insertUnorderedList',checked:false,icon:'icon-list-regular',tag:'ul'},
        {value:'Numbered order list',id:'insertOrderedList',checked:false,icon:'icon-list-filled',tag:'ol'},
        {value:'Strikethrough',id:'strikethrough',checked:false,tag:'strike'},
        // {value:'Link',id:'link',checked:false,icon:'icon-link-alt-regular'},
        {value:'Superscript',id:'superscript',checked:false,tag:'sup'},
        {value:'Subscript',id:'subscript',checked:false,tag:'sub'}
      ],
      showContextmenu:false,
      open:false,
      modal_height:"",
      content: "",
      selectedText: "",
      selectionRange: null,
      arr: [],
      count:0,
      fontName: [
        { name: "Times new roman", checked: false, value: "Times New Roman" },
        { name: "Arial", checked: false, value: "Arial" },
        { name: "Cursive", checked: false, value: "Cursive" },
        { name: "Opensans", checked: true, value: "OpenSans" },
        { name: "Quicksand", checked: false, value: "QuickSand" },
        { name: "Pacifico", checked: false, value: "Pacifico" },
        { name: "Ostrichsans", checked: false, value: "OstrichSans" },
        { name: "Josefinsans", checked: false, value: "JosefinSans" },
      ],
      fontSize: [
        { name: "1", checked: false, value: "1" },
        { name: "2", checked: false, value: "2" },
        { name: "3", checked: true, value: "3" },
        { name: "4", checked: false, value: "4" },
        { name: "5", checked: false, value: "5" },
        { name: "6", checked: false, value: "6" },
        { name: "7", checked: false, value: "7" },
        { name: "8", checked: false, value: "8" },
        { name: "9", checked: false, value: "9" },
        { name: "10", checked: false, value: "10" },
      ],
      colorvalue:"",
      highlightvalue:"",
      url:"",
      modal_active:false,
    };
  },
  watch: {
    contextmenuOptions1:{
      handler(val){
        if(val){
          // this.handleResize()
        }
      },
      immediate:true,
      deep:true
    },
    content(newContent) {
      // Handle content changes here
      newContent
      // console.log("Content changed:", newContent);
    },
    height: {
      handler(val) {
        // console.log(val);
        this.modal_height = val;
      },
      deep:true,
      immediate: true,
    },
    prevalue: {
      handler(val) {
        if(val != '' && val)
        {
          // console.log(val,'preval');
          this.$nextTick(()=>{
            // console.log(document.getElementById('textEditor').innerHTML,'HTML');
            document.getElementById('textEditor'+this.unique).innerHTML = val
          })
        }
        // this.modal_height = val;
      },
      deep:true,
      immediate: true,
    },
    modal_active:{
       handler(val) {
          if(val == true)
          {
            // console.log('pwopw');
            // document.getElementById("link-input").focus()
          }
       },
       immediate:true,
    deep:true
    },
    arr: {
      handler(val) {
        // console.log(val);
        if(val.length != 0)
        {
          document.querySelectorAll('.functionality').forEach((e)=>{
            // console.log(e.id);
            if(val.includes(e.id) == true)
            {
              e.classList.add('texteditor-active')
              
            }
            else{
              e.classList.remove('texteditor-active')
            }
          })
        }
        else{
          document.querySelectorAll('.functionality').forEach((e)=>{
            e.classList.remove('texteditor-active')
          })
        }
      },
    immediate:true,
    deep:true
    }
  },
  mounted()
  {


    document.addEventListener('click',(elem)=>{
      if(this.showContextmenu == true){
        // console.log(document.getElementById('context-menu'+this.unique).contains(elem.target),'testttt');
      if(document.getElementById('context-menu'+this.unique).contains(elem.target) != true)
      {
        this.showContextmenu = false
      }
    }

    })

    
    if(this.contextmenuOptions){
    document.addEventListener('resize', this.handleResize);
    document.addEventListener('DOMContentLoaded',this.handleResize);
    }

    setTimeout(() => {
      this.handleResize()
    }, 500);

  },
  methods: {

    applyValues(i){
      if(i.id == 'bold'){
        // console.log(this.startContainer, this.endContainer, this.startOffset, this.endOffset,this.startOffset == this.endOffset,this.startContainer == this.endContainer,'different place cursor1');
         if(this.startContainer == this.endContainer && this.startOffset == this.endOffset){
          if (this.startContainer && this.endContainer) {
            // console.log(this.startContainer == this.endContainer && this.startOffset == this.endOffset,'same place cursor1');

          // Create a new range and set its start and end positions
          const range = document.createRange();
          range.setStart(this.endContainer, this.endOffset);
          // range.setEnd(this.endContainer, this.endOffset);
          // console.log(range,'lollll');
          
          // Get the current selection and remove any existing ranges
          const selection = window.getSelection();
          selection.removeAllRanges();
          
          // Add the new range to the selection
          selection.addRange(range);
          
          // Optionally, you can focus the contenteditable element to highlight the selection
          // document.querySelector('[contenteditable]').focus();
          this.executeCommand(i.id)
        }
          // this.executeCommand(i.id)
          // i.checked= false
          // this.contextmenuOptions[index].checked = false
        }else{
          // console.log('sdlsllsslk');
          // console.log(this.startContainer == this.endContainer && this.startOffset == this.endOffset,'different place cursor1');
          this.dragCursor(i)
          // i.checked = true
          // this.contextmenuOptions[index].checked = true
        }
      }else if(i.id == 'italic'){
        this.dragCursor(i)
        // i.checked = true
        // this.contextmenuOptions[index].checked = true
      }else if(i.id == 'underline'){

        this.dragCursor(i)



      }else if(i.id == 'justifyFull'){
        this.pointCursor(i)

      }else if(i.id == 'justifyLeft'){
        this.pointCursor(i)



        
      }else if(i.id == 'justifyCenter'){
        this.pointCursor(i)

      }else if(i.id == 'justifyRight'){
        this.pointCursor(i)




        
      }else if(i.id == 'insertUnorderedList'){
        this.pointCursor(i)



        
      }else if(i.id == 'insertOrderedList'){
        this.pointCursor(i)



        
      }else if(i.id == 'strikethrough'){
        this.dragCursor(i)




      }else if(i.id == 'link'){
        
        this.createlink()



      }else if(i.id == 'superscript'){
        this.dragCursor(i)




      }else if(i.id == 'subscript'){
        this.dragCursor(i)



      } 
      this.showContextmenu = false 
    },


    pointCursor(value){
           let editableElement = document.querySelector('#textEditor'+this.unique);
          editableElement.childNodes.forEach((node,index) => {
            // console.log(index,'index');
            // console.log(`Node type: ${node.nodeType}, Node content: ${node.textContent}`);
            let range = document.createRange();
            let sel = window.getSelection();
            range.setStart(editableElement.childNodes[index], 0);
            range.collapse(true);

            sel.removeAllRanges();
            sel.addRange(range);
            // editableElement.focus();  
            this.executeCommand(value.id)
          });
    },

    dragCursor(value){
        if (this.startContainer && this.endContainer) {
          // Create a new range and set its start and end positions
          const range = document.createRange();
          range.setStart(this.startContainer, this.startOffset);
          range.setEnd(this.endContainer, this.endOffset);
          
          // Get the current selection and remove any existing ranges
          const selection = window.getSelection();
          selection.removeAllRanges();
          
          // Add the new range to the selection
          selection.addRange(range);
          
          // Optionally, you can focus the contenteditable element to highlight the selection
        }
        this.executeCommand(value.id)
        const range = document.createRange();

        // range.setStart(this.startContainer, this.startOffset);
          range.setStart(this.endContainer, this.endOffset);
          
          // Get the current selection and remove any existing ranges
          const selection = window.getSelection();
          selection.removeAllRanges();
          
          // Add the new range to the selection
          selection.addRange(range);
          // console.log('lsdlknvlksnlks');
        document.querySelector('#textEditor'+this.unique).blur();
    },

     handleResize() {
      this.$nextTick(()=>{
        const container = document.querySelector('.text-editor-container');
        const icons = document.querySelectorAll('.icon');
        const contextMenu = document.querySelector('.context-menu');
        if (container != null) {
        let containerWidth = container?.clientWidth;
        
        let totalIconsWidth = 0;
        if(this.contextmenuOptions)
        this.duplicateContextmenuOptions = [
        {value:'Bold',id:'bold',checked:false,tag:'b'},
        {value:'Italic',id:'italic',checked:false,tag:'i'},
        {value:'Underline',id:'underline',checked:false,tag:'u'},
        {value:'Justify',id:'justifyFull',checked:false,icon:'icon-menu-regular',tag:'justifyFull'},
        {value:'Left align',id:'justifyLeft',checked:false,icon:'icon-menu-left-alt-regular',tag:'justifyLeft'},
        {value:'Right align',id:'justifyRight',checked:false,icon:'icon-menu-right-alt-regular',tag:'justifyRight'},
        {value:'Center',id:'justifyCenter',checked:false,icon:'icon-menu-regular',tag:'justifyCenter'},
        {value:'Bullet order list',id:'insertUnorderedList',checked:false,icon:'icon-list-regular',tag:'ul'},
        {value:'Numbered order list',id:'insertOrderedList',checked:false,icon:'icon-list-filled',tag:'ol'},
        {value:'Strikethrough',id:'strikethrough',checked:false,tag:'strike'},
        // {value:'Link',id:'link',checked:false,icon:'icon-link-alt-regular'},
        {value:'Superscript',id:'superscript',checked:false,tag:'sup'},
        {value:'Subscript',id:'subscript',checked:false,tag:'sub'}
      ],

      this.contextmenuOptions = []

        icons.forEach((icon,index) => {
          if (icon != null) {
        totalIconsWidth += icon?.clientWidth + parseInt(window.getComputedStyle(icon).marginRight) + parseInt(window.getComputedStyle(icon).marginLeft);
        var count = 0
        // console.log(totalIconsWidth,containerWidth,'width',Math.abs(totalIconsWidth - containerWidth),(Math.abs(totalIconsWidth - containerWidth) > 50 ));
        if (totalIconsWidth + 200  > containerWidth) {
            // console.log(this.duplicateContextmenuOptions);
            icon.style.display="none"
            this.duplicateContextmenuOptions.forEach((item)=>{
                if(item.tag && item.tag == icon.id){
                   this.contextmenuOptions.push(item)
                  contextMenu.style.display="flex" 
                 }
            })
        }else{
          icon.style.display="block"
          count+=1
        }
        if(count == index){
          contextMenu.style.display="none"
        }
          }
        })
        }
      })
      
     
    },

    displayContextmenu(id){
      this.$nextTick(()=>{
        this.containertop = document.getElementById(id).getBoundingClientRect().top 
        this.containerleft = document.getElementById(id).getBoundingClientRect().left 
      // document.getElementById('display-context-menu'+this.unique).style.top = this.containertop + 'px'
      // document.getElementById('display-context-menu'+this.unique).style.left = (this.containerleft-150) + 'px'

      // console.log(this.containertop,this.containerleft);
      if(this.showContextmenu == false){
        this.showContextmenu = true
        if(this.startContainer == this.endContainer && this.startOffset == this.endOffset&&this.startContainer != null){
          // console.log(this.startContainer == this.endContainer && this.startOffset == this.endOffset,'same place cursor');
          const range = document.createRange();
          range.setStart(this.endContainer, this.endOffset);
          range.setEnd(this.endContainer, this.endOffset);
          // console.log(range,'lollll');
          
          // Get the current selection and remove any existing ranges
          const selection = window.getSelection();
          selection.removeAllRanges();
          
          // Add the new range to the selection
          selection.addRange(range);
          
          // Optionally, you can focus the contenteditable element to highlight the selection
          // document.querySelector('[contenteditable]').focus();

        }else{
          // console.log(this.startContainer == this.endContainer && this.startOffset == this.endOffset,'different place cursor');
            this.showContextmenu = true
            let editableElement = document.querySelector('#textEditor'+this.unique);
            editableElement.childNodes.forEach((node,index) => {
            // console.log(index,'index');
            // console.log(`Node type: ${node.nodeType}, Node content: ${node.textContent}`);
            let range = document.createRange();
            let sel = window.getSelection();
            range.setStart(editableElement.childNodes[index], 0);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            // editableElement.focus();  
          });
        }
                // console.log(this.showContextmenu,'lozk');
      }else{
        this.showContextmenu = false
      }
    })
    },
    executeCommand(command) {
      document.execCommand(command, false, null);
    },
    updateContent() {
      // Handle content changes if needed
      const content = this.$refs.editor.innerHTML;
      this.$emit("custom-value",this.$refs.editor.textContent)
      this.$emit("current-change",content)
      this.count = 1
      var arr = []
      const selection = window.getSelection();
  
      const range = selection.getRangeAt(0);
  if (selection.rangeCount > 0) {
    // Get the first range of the selection
    
    // Get the start and end positions of the selection
    this.startContainer = range.startContainer;
    this.startOffset = range.startOffset;
    this.endContainer = range.endContainer;
    this.endOffset = range.endOffset;

    // Log the selection details
    // console.log('Selection start input:', this.startContainer, this.startOffset);
    // console.log('Selection end input:', this.endContainer, this.endOffset);
  }

  if(this.startContainer != this.endContainer){
        this.selectedtagValue = []
        const nodes = this.getNodesInRange(range);
        nodes.forEach(node => {
          const tagValue = this.checkNodeAndCountTags(node, 'b', 'i');
          arr = arr.concat(tagValue)
          arr.forEach((tag)=>{
            if(this.selectedtagValue.includes(tag) == false){
              this.selectedtagValue.push(tag)
            }
          })
    });
      }else{
      const selectedNodes = range.commonAncestorContainer;
      const tagValue = this.checkNodeAndCountTags(selectedNodes, 'b', 'i');
      this.selectedtagValue = []
      this.selectedtagValue = tagValue
      }
      this.contextmenuOptions = this.duplicateContextmenuOptions
      this.contextmenuOptions.forEach((item)=>{
          if(item.tag && this.selectedtagValue.includes(item.tag)){
            item.checked = true
            if(document.getElementById(item.tag)){
              document.getElementById(item.tag).classList.add('selectedtags') 
            }
          }else{
            item.checked = false
            if(document.getElementById(item.tag)){
            document.getElementById(item.tag).classList.remove('selectedtags') 
            }
          }
      })
      // console.log('Content:', typeof(this.$refs.editor.textContent),content);
      // console.log(typeof(this.$refs.editor.textContent),"work or",content,"content");
    },
    checkNodeAndCountTags(node, ...tags) {
     var tagname = []
     tags
  while (node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      if(tagname.includes(node.tagName.toLowerCase()) == false){
        tagname.push(node.tagName.toLowerCase())
      }
    }
    node = node.parentElement;
  }
  return tagname;
},
    cursorevent(event){
      event
      var arr = []
        const selection = window.getSelection();
        // console.log(selection,'selection')
  
  if (selection.rangeCount > 0) {
    // Get the first range of the selection
    const range = selection.getRangeAt(0);
    
    // Get the start and end positions of the selection
    this.startContainer = range.startContainer;
    this.startOffset = range.startOffset;
    this.endContainer = range.endContainer;
    this.endOffset = range.endOffset;

    // Log the selection details
    // console.log('Selection start:', this.startContainer, this.startOffset);
    // console.log('Selection end:', this.endContainer, this.endOffset);




    // select the color
    //  console.log(range.commonAncestorContainer.nodeType);
      if(this.startContainer != this.endContainer){
        this.selectedtagValue = []
        const nodes = this.getNodesInRange(range);
        nodes.forEach(node => {
          const tagValue = this.checkNodeAndCountTags(node, 'b', 'i');
          arr = arr.concat(tagValue)
          arr.forEach((tag)=>{
            if(this.selectedtagValue.includes(tag) == false){
              this.selectedtagValue.push(tag)
            }
          })
    });
      }else{
      const selectedNodes = range.commonAncestorContainer;
      const tagValue = this.checkNodeAndCountTags(selectedNodes, 'b', 'i');
      this.selectedtagValue = []
      this.selectedtagValue = tagValue
      }
      this.contextmenuOptions = this.duplicateContextmenuOptions
      this.contextmenuOptions.forEach((item)=>{
          if(item.tag && this.selectedtagValue.includes(item.tag)){
            item.checked = true
            if(document.getElementById(item.tag)){
              document.getElementById(item.tag).classList.add('selectedtags') 
            }
          }else{
            item.checked = false
            if(document.getElementById(item.tag)){
            document.getElementById(item.tag).classList.remove('selectedtags') 
            }
          }
      })
  }
    },
     getNodesInRange(range) {
  const startNode = range.startContainer;
  startNode
  const endNode = range.endContainer;
  endNode
  const commonAncestor = range.commonAncestorContainer;
  
  let nodes = [];
  let node;

  // Create a tree walker to iterate over all nodes within the range
  const treeWalker = document.createTreeWalker(
    commonAncestor,
    NodeFilter.SHOW_ALL,
    {
      acceptNode(node) {
        if (range.intersectsNode(node)) {
          return NodeFilter.FILTER_ACCEPT;
        }
        return NodeFilter.FILTER_REJECT;
      }
    },
    false
  );

  // Traverse the tree and collect nodes within the range
  node = treeWalker.currentNode;
  while (node) {
    nodes.push(node);
    node = treeWalker.nextNode();
  }

  return nodes;
},
    activeColor(selection)
    {
      // console.log(selection);
       if(selection.localName == 'u')
       {
          if(this.arr.includes('underline') == false)
          {
            this.arr.push('underline')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
      else if(selection.localName == 'i')
       {
          if(this.arr.includes('italic') == false)
          {
            this.arr.push('italic')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else if(selection.localName == 'b')
       {
          if(this.arr.includes('bold') == false)
          {
            this.arr.push('bold')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else if(selection.localName == 'sup')
       {
          if(this.arr.includes('superscript') == false)
          {
            this.arr.push('superscript')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else if(selection.localName == 'sub')
       {
          if(this.arr.includes('subscript') == false)
          {
            this.arr.push('subscript')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else if(selection.localName == 'strike')
       {
          if(this.arr.includes('strikethrough') == false)
          {
            this.arr.push('strikethrough')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else if(selection.localName == 'ul')
       {
          if(this.arr.includes('insertUnorderedList') == false)
          {
            this.arr.push('insertUnorderedList')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else if(selection.localName == 'ol')
       {
          if(this.arr.includes('insertOrderedList') == false)
          {
            this.arr.push('insertOrderedList')
            if(selection.parentElement.localName != 'div')
            {
              this.activeColor(selection.parentElement)
            }
          }
       }
       else{
        this.arr = []
       }
    },
    selectFontName(val)
    {
      var a = "";
      val.forEach((e) => {
        if (e.checked === true) {
          a = e.name;
        }
      });
      document.execCommand("fontName", false, a); 
    },
    selectFontSize(val)
    {
      var a = "";
      val.forEach((e) => {
        if (e.checked === true) {
          a = e.name;
        }
      });
      document.execCommand("fontSize", false, a); 
    },
    colorValue(value)
    {
      this.colorvalue = value;
      document.execCommand("foreColor", false, this.colorvalue);
    },
    highlightValue(value) {
      this.highlightvalue = value;
      document.execCommand("backColor", false, this.highlightvalue);
    },
    link()
    {
      var a = this.url;
      if(this.showContextmenu == true){
        if (this.startContainer && this.endContainer) {
          // Create a new range and set its start and end positions
          const range = document.createRange();
          range.setStart(this.startContainer, this.startOffset);
          range.setEnd(this.endContainer, this.endOffset);
          
          // Get the current selection and remove any existing ranges
          const selection = window.getSelection();
          selection.removeAllRanges();
          
          // Add the new range to the selection
          selection.addRange(range);
          
          // Optionally, you can focus the contenteditable element to highlight the selection
          // document.querySelector('[contenteditable]').focus();
        }
      }
      document.execCommand("createLink", false, a);
      this.modal_active = false;
    },
    createlink() {
      if (document.getSelection().focusNode.parentElement.tagName != "A") {
        this.modal_active = true;
        // console.log('linkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk');
        // console.log(document.querySelector(".link-input"));
        // this.$nextTick(()=>{
        //   document.getElementById("link-input").focus()
        // document.querySelector(".link-input").focus();  
        // })
      } else {
        this.Removelink();
      }
    },
    Removelink() {
      document.execCommand("unlink", false, null);
    }
  //   handleInput(event) {
  //     // Update the content when the user types or pastes
  //     this.content = event.target.innerHTML;
  //   },
  //   handleSelection() {
  //     // Update the selected text and range on selection change
  //     const selection = document.getSelection();
  //     this.selectedText = selection.toString();
  //     this.selectionRange = selection.getRangeAt(0);
  //     const selectionStart = selection.getRangeAt(0).startOffset;
  //     const selectionEnd = selection.getRangeAt(0).endOffset;

  //     console.log('Selection Start:', selectionStart,selection.getRangeAt(0).startContainer,selection.getRangeAt(0).endContainer.nodeValue,selection);
  //     console.log('Selection End:', selectionEnd);
  //     const selection1 = window.getSelection();
  //     if (selection1.rangeCount > 0) {
  //   const range = selection1.getRangeAt(0);

  //   // Check if the selection spans multiple nodes
  //   if (range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE) {
  //     const selectedNodes = range.commonAncestorContainer.childNodes;
  //     console.log(range.commonAncestorContainer.nodeType,Node.ELEMENT_NODE,Node,'kanelllllll');
     
  //     for (const node of selectedNodes) {
  //       console.log('Node Type1:', node.nodeType);
  //       console.log('Node Value1:', node.nodeValue);
  //     }
  //   } else {
  //     console.log('Node Type:', range.commonAncestorContainer.nodeType);
  //     console.log('Node Value:', range.commonAncestorContainer.nodeValue);
  //   }
  // }
  //   },
  //   formatText(command) {
  //     // Handle formatting without document.execCommand
  //     if (this.selectionRange) {

  //       if(command == 'bold')
  //       {
  //         var elem = document.querySelector(".textEditor");
  //         elem.selectionStart
  //       const bold = document.createElement("b");
  //       this.selectionRange.surroundContents(bold);
  //       } else if(command == 'italic')
  //       {
  //       const italic = document.createElement("i");
  //       this.selectionRange.surroundContents(italic);
  //       } else if(command == 'underline')
  //       {
  //         if(this.selectionRange.commonAncestorContainer.nodeName == "U")
  //         {

  //           let content = this.selectionRange.extractContents();
  //           console.log(content,'cosjsj');
  //           content = document.createTextNode(content)
  //           this.selectionRange.deleteContents()
  //           this.selectionRange.insertNode(content)
  //         } else{
  //           const underline = document.createElement("u");
  //           this.selectionRange.surroundContents(underline); 
  //         }
  //       }
  //       // Clear the selection
  //       window.getSelection().removeAllRanges();
  //     }
  //   }
  }
};
</script>

<style>
/* Add your styles as needed */
/* div[contenteditable] {
  border: 1px solid #ccc;
  min-height: 100px;
  padding: 10px;
} */
</style> 

<!-- <template>
  <div>
    <div>
      <button @click="toggleFormat('bold')">Bold</button>
      <button @click="toggleFormat('italic')">Italic</button>
      <button @click="toggleFormat('underline')">Underline</button>
    </div>
    <div contenteditable="true" @input="updateContent" ref="editor"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ''
    };
  },
  methods: {
    updateContent(event) {
      this.content = event.target.innerHTML;
    },
    toggleFormat(format) {
      const selection = window.getSelection();
      if (!selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const contents = range.cloneContents();

        // Create a new span with the desired formatting
        const span = document.createElement('span');
        if (format === 'bold') {
          span.style.fontWeight = 'bold';
        } else if (format === 'italic') {
          span.style.fontStyle = 'italic';
        } else if (format === 'underline') {
          span.style.textDecoration = 'underline';
        }
        span.appendChild(contents);

        // Replace the selected content with the new span
        range.deleteContents();
        range.insertNode(span);
      }
    }
  }
};
</script>

<style scoped>
div[contenteditable] {
  border: 1px solid #ccc;
  min-height: 100px;
  padding: 8px;
}
</style> -->

<!-- <template>
  <div>
    <div>
      <button @click="toggleFormat('bold')">Bold</button>
    </div>
    <div contenteditable="true" @input="updateContent" ref="editor"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ''
    };
  },
  methods: {
    updateContent(event) {
      this.content = event.target.innerHTML;
    },
    toggleFormat(format) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // Check if the range is collapsed (no selection)
      if (!selection.isCollapsed) {
        // Create a span element with the desired formatting
        const span = document.createElement('span');
        span.style.fontWeight = this.hasFormat(range, format) ? 'initial' : 'bold';

        // Surround the selected content with the span
        range.surroundContents(span);
      }
    },
    hasFormat(range, format) {
      const container = range.commonAncestorContainer;

      if (container.nodeType === 1) {
        // Check if the container or any of its ancestors have the specified formatting
        return container.style[format] === 'bold' || container.style.fontWeight === 'bold';
      } else {
        // If the common ancestor is a text node, check the parent element
        const parentElement = container.parentElement;
        return parentElement && parentElement.style.fontWeight === 'bold';
      }
    }
  }
};
</script>

<style scoped>
div[contenteditable] {
  border: 1px solid #ccc;
  min-height: 100px;
  padding: 8px;
}
</style> -->


<!-- <template>
  <div>
    <div>
      <button @click="toggleFormat('bold')">Bold</button>
    </div>
    <div id="area" ref="editor" contenteditable="true" @input="updateContent" @mouseup="handleSelection"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ''
    };
  },
  methods: {
    handleSelection() {
      const selection = window.getSelection();
      const selectionStart = selection.getRangeAt(0).startOffset;
      const selectionEnd = selection.getRangeAt(0).endOffset;

      console.log('Selection Start:', selectionStart,selection.getRangeAt(0).startContainer,selection.getRangeAt(0).endContainer.nodeValue,selection.focusNode.nodeName);
      console.log('Selection End:', selectionEnd);
    },
    updateContent(event) {
      this.content = event.target.innerHTML;
    },
    toggleFormat(format) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // Check if the range is collapsed (no selection)
      if (!selection.isCollapsed) {
        // Expand the range to include the entire bold element
        this.expandRangeToBold(range);

        // Create a span element with the desired formatting
        const span = document.createElement('span');
        span.style.fontWeight = this.hasFormat(range, format) ? 'initial' : 'bold';

        // Surround the selected content with the span
        range.surroundContents(span);
      }
    },
    hasFormat(range, format) {
      const container = range.commonAncestorContainer;

      if (container.nodeType === 1) {
        // Check if the container or any of its ancestors have the specified formatting
        return container.style[format] === 'bold' || container.style.fontWeight === 'bold';
      } else {
        // If the common ancestor is a text node, check the parent element
        const parentElement = container.parentElement;
        return parentElement && parentElement.style.fontWeight === 'bold';
      }
    },
    expandRangeToBold(range) {
      const startContainer = range.startContainer;
      const endContainer = range.endContainer;
      console.log(range.startContainer,range.endContainer,startContainer.nodeValue);
      
      // Expand the range backward to include the entire bold element
      console.log(startContainer.nodeValue[range.startOffset - 1]);
      while (range.startOffset > 0 && startContainer.nodeValue[range.startOffset - 1] === ' ') {
        range.setStart(startContainer, range.startOffset - 1);
      }

      // Expand the range forward to include the entire bold element
      while (
        range.endOffset < endContainer.length &&
        endContainer.nodeValue[range.endOffset] === ' '
      ) {
        range.setEnd(endContainer, range.endOffset + 1);
      }
    }
  }
};
</script>

<style scoped>
div[contenteditable] {
  border: 1px solid #ccc;
  min-height: 100px;
  padding: 8px;
}
</style> -->


<style>

</style>
