<template>
  <h1 class="component-heading">Tree</h1>
  <p class="component-description">
    A tree is a nonlinear hierarchical component that consists of nodes
    connected by edges.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    Set <b>:tree-direction</b> to view the tree in specified direction<br />
    Define tree input data in <b>:node</b>
  </p>
  <div class="component-example">
    <!-- <button class="direction1" @click="myFunction()">Change view</button> -->
    <div class="example-body custom">
      <span class="custom"
        ><hlx-button class="primary large" @click="myFunction()"
          >Change view</hlx-button
        ></span
      >
      <div class="tree-container">
        <!-- <ul class="tree" :class="tree_direction" > -->
        <hlx-tree :node="treedata" :tree-direction="tree_direction" />
        <!-- </ul> -->
      </div>
    </div>
    <div class="example-footer">
      <span id="basic-tree-icon" class="icon" @click="showCode('basic-tree')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-tree" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_tree"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Drag usage</h2>
  <p class="component-description">
    <b>isdraggable</b> to <b>true</b> make the tree draggable<br />
  </p>
  <div class="component-example">
    <!-- <button class="direction1" @click="myFunction()">Change view</button> -->
    <div class="example-body custom">
      <!-- <span class="custom"
        ><hlx-button class="primary large" @click="myFunction()"
          >Change view</hlx-button
        ></span
      > -->
      <div class="tree-container">
        <!-- <ul class="tree" :class="tree_direction" > -->
        <hlx-tree
          :node="treedata"
          :tree-direction="tree_direction"
          :is-draggable="true"
        />
        <!-- </ul> -->
      </div>
    </div>
    <div class="example-footer">
      <span id="drag-tree-icon" class="icon" @click="showCode('drag-tree')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="drag-tree" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="drag_tree"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Zoom usage</h2>
  <p class="component-description">
    Set <b>:zoomable</b> zoom the tree<br />
    and specify the <b>:zoom</b> percentage of (10 - 200) to zoom <br />
  </p>
  <section class="switcher">
    <hlx-button class="primary large" @click="zoomin()">zoom in</hlx-button>
    <hlx-button class="primary large" @click="zoomout()">zoom out</hlx-button>
  </section>
  <div class="component-example">
    <div class="example-body custom">
      <span class="custom">
        <!-- <hlx-button class="primary large" @click="myFunction()"
          >Change view</hlx-button
        > -->
      </span>
      <div class="tree-container">
        <hlx-tree
          :node="treedata"
          :tree-direction="tree_direction"
          zoomable
          :zoom="zoom"
        />
      </div>
    </div>
    <div class="example-footer">
      <span
        id="zoomable-tree-icon"
        class="icon"
        @click="showCode('zoomable-tree')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="zoomable-tree" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="zoomable_tree"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Collapse usage</h2>
  <p class="component-description">
    pass <b>:collapse-all</b> and <b>:expandAll</b> as boolean to expand and
    collapse all the tree nodes
  </p>
  <section class="switcher">
    <hlx-button class="primary large" @click="expand()">{{
      isCollapsed ? "Expand all" : "Collapse all"
    }}</hlx-button>
  </section>
  <div class="component-example">
    <div class="example-body custom">
      <!-- <span class="custom">
        <hlx-button class="primary large" @click="myFunction()"
          >Change view</hlx-button
        >
      </span> -->
      <div class="tree-container">
        <hlx-tree
          :node="treedata"
          :tree-direction="tree_direction"
          :collapse-all="expandAll"
          :expand-all="collapseAll"
        />
      </div>
    </div>
    <div class="example-footer">
      <span id="expandable-icon" class="icon" @click="showCode('expandable')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="expandable" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="expandable_tree"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Product Tree</h2>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-tree-v2
        :nodes="treedataProduct"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="type"
        :config="configValue"
        :read-only="false"
        :is-draggable="true"
        :tree-data-props="treedataval"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @updated-node="updatednode"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
    </div>
    <div class="example-footer">
      <span
        id="treedata-product-icon"
        class="icon"
        @click="showCode('treedata-product')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="treedata-product" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="treedata_product"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Service Tree</h2>
  <p class="component-description">
    Service <b>type</b> may have values such as <b>Customer Facing Service</b>,
    <b>Resource Facing Service</b>.
  </p>
  <h2 class="component-side-heading">Customer Facing Service Tree</h2>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-tree-v2
        :nodes="treedataServiceCfs"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="'service'"
        :read-only="false"
        :config="configValue"
        :is-draggable="true"
        :tree-data-props="treedataval"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
    </div>
    <div class="example-footer">
      <span
        id="treedata-service-cfs-icon"
        class="icon"
        @click="showCode('treedata-service-cfs')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="treedata-service-cfs" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="treedata_service_cfs"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Resource Facing Service Tree</h2>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-tree-v2
        :nodes="treedataServiceRfs"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="'service'"
        :read-only="service"
        :config="configValue"
        :is-draggable="true"
        :tree-data-props="treedataval"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
    </div>
    <div class="example-footer">
      <span
        id="treedata-service-rfs-icon"
        class="icon"
        @click="showCode('treedata-service-rfs')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="treedata-service-rfs" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="treedata_service_rfs"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Resource Facing Service Tree</h2>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-tree-v2
        :nodes="treedataHierarchy"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="'Hierarchy'"
        :read-only="service"
        :config="configValue"
        :is-draggable="true"
        :tree-data-props="treedataval"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
    </div>
    <div class="example-footer">
      <span
        id="treedata-service-rfs-icon"
        class="icon"
        @click="showCode('treedata-service-rfs')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="treedata-service-rfs" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="treedata_service_rfs"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in treeAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in treeAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'tabs')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tab</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'speeddial')"
      >Speed dial <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>
<script>
import hlxTree from "../components/TreeComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTreeV2 from "../components/TreeVersion2Component.vue";
import axios from "axios";
import hlxButton from "../components/ButtonComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "TreeView",
  components: {
    hlxTreeV2,
    hlxTree,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxButton,
    CodeEditor,
    hlxDivider,
  },
  data() {
    return {
      event_data: [
        {
          name: "updated-data",
          description: "emits the tree data",
          parameter: "",
        },
        {
          name: "card-nodes",
          description: "emits the tree card data",
          parameter: "",
        },
        {
          name: "context",
          description: "emits the tree context menu value",
          parameter: "",
        },
        {
          name: "zoomIn",
          description: "For zoomIn the tree",
          parameter: "",
        },
        {
          name: "zoomOut",
          description: "For zoomOut the tree",
          parameter: "",
        },
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      configValue: {
        url: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/tree",
        key: "dbee69aa047d12f22275e281ee432da3:2920ca58abb53b122a1e06c94ceabdc1323d441d980a1f19d1c7f040a5e8910af798fdaf8a7213898efbbb43b74da0e3670964b9d96a4081415cca3beeed7727",
      },
      treedataval: {},
      treedata_product: `
<template>
<hlx-tree-v2
        :nodes="treedataProduct"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="product"
        :readOnly="false"
        :isDraggable="true"
        :config="configValue"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
 
</template>

<script>
export default {
data(){
  return{
    treedataProduct: {
        service: {
          icones: "icon-box-check-regular tree-icone3",
          name: "service",
          value: "service",
        },
        specification: {
          name: "all product",
        },
      },
  }
}
methods:{
     this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
}
}
</\script>`,
      treedata_service_cfs: `
<template>
<hlx-tree-v2
        :nodes="treedataServiceCfs"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="'service'"
        :config="configValue"
        :readOnly="false"
        :isDraggable="true"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
 
</template>

<script>
export default {
data(){
  return{
    treedataServiceCfs: {
        service: {
          icones: "icon-credit-card-regular tree-icone2",
          name: "Customer Facing Service",
          value: "Customer Facing Service",
        },
        specification: {
          name: "all product",
        },
      },
  }
}
methods:{
     this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
}
}
</\script>`,
      treedata_service_rfs: `
<template>
<hlx-tree-v2
        :nodes="treedataServiceRfs"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :config="configValue"
        :zoom="zoom"
        :type="'service'"
        :readOnly="false"
        :isDraggable="true"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
 
</template>

<script>
export default {
data(){
  return{
   treedataServiceRfs: {
        service: {
          icones: "icon-shield-bolt-regular tree-icon",
          name: "Resource Facing Service",
          value: "Resource Facing Service",
        },
        specification: {
          name: "all product",
        },
      },
  }
}
methods:{
     this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
}
}
</\script>`,
      treedata_physical: `
<template>
<hlx-tree-v2
        :nodes="treedataPhysical"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="'resource'"
        :readOnly="true"
        :isDraggable="true"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
 
</template>

<script>
export default {
data(){
  return{
   treedataPhysical: {
        service: {
          icones: "icon-sd-card-regular tree-icon-Physical",
          name: "Physical Resource",
          value: "Physical Resource",
        },
        specification: {
          name: "all product",
        },
      },
  }
}
methods:{
     this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
}
}
</\script>`,
      treedata_logical: `
<template>
<hlx-tree-v2
        :nodes="treedataLogical"
        :parent-name="'all product'"
        :direction="tree_direction"
        :zoomable="true"
        :zoom="zoom"
        :type="'resource'"
        :readOnly="true"
        :isDraggable="true"
        :customer-facing-service-option="dropdown2data1"
        :resource-facing-service-option="dropdown2data2"
        :physical-resource-option="dropdown2data3"
        :logical-resource-option="dropdown2data4"
        @updated-data="dataReceived"
        @zoomIn="zoomin"
        @zoomOut="zoomout"
      />
 
</template>

<script>
export default {
data(){
  return{
   treedataLogical: {
        service: {
          icones: "icon-cpu-regular tree-icon-Logical",
          name: "Logical Resource",
          value: "Logical Resource",
        },
        specification: {
          name: "all product",
        },
      },
  }
}
methods:{
     this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
}
}
</\script>`,
      service: false,
      type: "product",
      treedataProduct: {
        service: {
          icones: "icon-box-check-regular tree-icone3",
          name: "service",
          value: "service",
          data: {},
        },
        specification: {
          name: "all product",
        },
      },
      treedataLogical: {
        service: {
          icones: "icon-cpu-regular tree-icon-Logical",

          name: "Logical Resource",

          value: "Logical Resource",
          data: {},
        },
        specification: {
          name: "all product",
        },
      },
      treedataHierarchy: {
        _id: "66253e09edb6bb6a55ae8b34",
        id: "66253e09edb6bb6a55ae8b34",
        displayID: "CM-0006",
        customName: "NMK mobiles",
        tradingName: "NMK_mobiles",
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "0 - 10",
          },
          {
            name: "market segment",
            value: "Banking/Finance",
          },
        ],
        name: "NMK_mobiles",
        status: "Active",
        otherName: [],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "ganesh@gmail.com",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: "",
            },
            preferred: false,
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
            preferred: false,
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "Konkan Division",
              unit: "",
              country: "India",
              state: "Maharashtra",
              postalCode: "400053",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description:
                "Andheri Railway Station East Northern Opas, Konkan Division, Maharashtra, India, 400053",
              address1: "Andheri Railway Station East Northern Opas",
              default: true,
              "@type": "Headquarter",
            },
            preferred: false,
          },
          {
            mediumType: "billingLocation",
            characteristic: {
              city: "Konkan Division",
              unit: "",
              country: "India",
              state: "Maharashtra",
              postalCode: "400053",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description:
                "Andheri Railway Station East Northern Opas, Konkan Division, Maharashtra, India, 400053",
              address1: "Andheri Railway Station East Northern Opas",
              default: true,
              "@type": "Headquarter",
              phone: {
                phoneNumber: "",
              },
              email: {
                emailAddress: "ganesh@gmail.com",
              },
              locationName: "NMK_mobiles",
            },
            preferred: false,
          },
          {
            mediumType: "siteLocation",
            characteristic: {
              city: "Konkan Division",
              unit: "",
              country: "India",
              state: "Maharashtra",
              postalCode: "400053",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description:
                "Andheri Railway Station East Northern Opas, Konkan Division, Maharashtra, India, 400053",
              address1: "Andheri Railway Station East Northern Opas",
              default: true,
              "@type": "Headquarter",
              phone: {
                phoneNumber: "",
              },
              email: {
                emailAddress: "ganesh@gmail.com",
              },
              locationName: "NMK_mobiles",
            },
            preferred: false,
          },
        ],
        creditRating: [],
        attachment: [],
        taxExemptionCertificate: [],
        characteristic: [],
        organizationParentRelationship: {
          id: "65d34900237a9575f79cf808",
          name: "developerHalleyx",
        },
        organizationIdentification: [],
        organizationChildRelationship: [
          {
            id: "66250fa52984bd40994beeb4",
            name: "testdb_name",
          },
          {
            id: "6620ccc753557a4ffeb105f4",
            name: "VP_Mobiles",
          },
          {
            id: "6620214fda9b2dd18082b164",
            name: "Hanubar_Manufacturing_Plant",
          },
        ],
        relatedParty: [
          {
            id: "66253e5bedb6bb6a55ae8b5d",
            name: "Gan Bal",
            role: "Upc admin",
          },
          {
            name: "Dilip param",
            id: "6620ce6153557a4ffeb106a1",
            role: "Account executive",
          },
        ],
        email: "ganesh@gmail.com",
        plan: "7 days trail",
        lastUpdate: "2024-04-21T16:23:26.629Z",
        lastVisit: null,
        createdAt: "2024-04-21T16:23:26.629Z",
        rootName: "NMK_mobiles",
        "@baseType": "customer",
        tags: ["Active"],
        paymentTerms: [],
        notes: [],
        __v: 0,
        child: [
          {
            _id: "66250fa52984bd40994beeb4",
            id: "66250fa52984bd40994beeb4",
            displayID: "CM-0004",
            customName: "test db.name",
            tradingName: "testdb_name",
            externalReference: [
              {
                name: "",
                externalIdentifierType: "internetSite",
              },
            ],
            partyCharacteristic: [
              {
                name: "number of employees",
                value: "",
              },
              {
                name: "market segment",
                value: "",
              },
            ],
            name: "testdb_name",
            status: "Active",
            otherName: [],
            contactMedium: [
              {
                preferred: false,
                mediumType: "email",
                characteristic: {
                  emailAddress: "testdb@gmail.com",
                },
              },
              {
                mediumType: "phone",
                characteristic: {
                  phoneNumber: "",
                },
                preferred: false,
              },
              {
                mediumType: "fax",
                characteristic: {
                  faxNumber: "",
                },
                preferred: false,
              },
              {
                mediumType: "postalAddress",
                characteristic: {
                  city: "",
                  country: "",
                  postCode: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  description: "",
                  address1: "",
                  default: true,
                  "@type": "",
                },
                preferred: false,
              },
            ],
            creditRating: [],
            attachment: [],
            taxExemptionCertificate: [],
            characteristic: [],
            organizationParentRelationship: {
              name: "NMK_mobiles",
            },
            organizationIdentification: [],
            organizationChildRelationship: [
              {
                id: "662510192984bd40994bef03",
                name: "testdbname_2",
              },
            ],
            relatedParty: [],
            email: "testdb@gmail.com",
            plan: "7 days trail",
            lastUpdate: "2024-04-21T13:03:33.264Z",
            lastVisit: null,
            createdAt: "2024-04-21T13:03:33.264Z",
            rootName: "NMK_mobiles",
            "@baseType": "customer",
            tags: ["Active"],
            paymentTerms: [],
            notes: [],
            __v: 0,
            child: [
              {
                _id: "662510192984bd40994bef03",
                id: "662510192984bd40994bef03",
                displayID: "CM-0005",
                customName: "test dbname.2",
                tradingName: "testdbname_2",
                externalReference: [
                  {
                    name: "",
                    externalIdentifierType: "internetSite",
                  },
                ],
                partyCharacteristic: [
                  {
                    name: "number of employees",
                    value: "",
                  },
                  {
                    name: "market segment",
                    value: "",
                  },
                ],
                name: "testdbname_2",
                status: "Active",
                otherName: [],
                contactMedium: [
                  {
                    preferred: false,
                    mediumType: "email",
                    characteristic: {
                      emailAddress: "testdb2@gmail.com",
                    },
                  },
                  {
                    mediumType: "phone",
                    characteristic: {
                      phoneNumber: "",
                    },
                    preferred: false,
                  },
                  {
                    mediumType: "fax",
                    characteristic: {
                      faxNumber: "",
                    },
                    preferred: false,
                  },
                  {
                    mediumType: "postalAddress",
                    characteristic: {
                      city: "",
                      country: "",
                      postCode: "",
                      stateOrProvince: "",
                      street1: "",
                      street2: "",
                      description: "",
                      "@type": "",
                    },
                    preferred: false,
                  },
                ],
                creditRating: [],
                attachment: [],
                taxExemptionCertificate: [],
                characteristic: [],
                organizationParentRelationship: {
                  name: "testdb_name",
                },
                organizationIdentification: [],
                organizationChildRelationship: [],
                relatedParty: [],
                email: "testdb2@gmail.com",
                plan: "7 days trail",
                lastUpdate: "2024-04-21T13:03:33.264Z",
                lastVisit: null,
                createdAt: "2024-04-21T13:03:33.264Z",
                rootName: "testdb_name",
                "@baseType": "customer",
                tags: ["Active"],
                paymentTerms: [],
                notes: [],
                __v: 0,
              },
            ],
          },
          {
            _id: "6620ccc753557a4ffeb105f4",
            id: "6620ccc753557a4ffeb105f4",
            displayID: "CM-0002",
            customName: "VP Mobiles",
            tradingName: "VP_Mobiles",
            externalReference: [
              {
                name: "www.vpmobiles.in",
                externalIdentifierType: "internetSite",
              },
            ],
            partyCharacteristic: [
              {
                name: "number of employees",
                value: "0 - 10",
              },
              {
                name: "market segment",
                value: "Retail",
              },
            ],
            name: "VP_Mobiles",
            status: "Active",
            otherName: [],
            contactMedium: [
              {
                preferred: true,
                mediumType: "email",
                characteristic: {
                  emailAddress: "pcfor62@gmail.com",
                },
              },
              {
                mediumType: "phone",
                characteristic: {
                  phoneNumber: {
                    country: {
                      name: "India",
                      flags: "https://flagcdn.com/in.svg",
                      short: "IN",
                      dial_code: "+91",
                    },
                    value: "90001 60001",
                    valid: true,
                  },
                },
                preferred: false,
              },
              {
                mediumType: "fax",
                characteristic: {
                  faxNumber: "",
                },
                preferred: false,
              },
              {
                mediumType: "postalAddress",
                characteristic: {
                  city: "Konkan Division",
                  unit: "38E",
                  country: "India",
                  state: "Maharashtra",
                  postalCode: "400047",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  description:
                    "Vallabhbhai Patel Road, 38E, Konkan Division, Maharashtra, India, 400047",
                  address1: "Vallabhbhai Patel Road",
                  default: true,
                  "@type": "Headquarter",
                },
                preferred: false,
              },
              {
                mediumType: "billingLocation",
                characteristic: {
                  city: "Konkan Division",
                  unit: "38E",
                  country: "India",
                  state: "Maharashtra",
                  postalCode: "400047",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  description:
                    "Vallabhbhai Patel Road, 38E, Konkan Division, Maharashtra, India, 400047",
                  address1: "Vallabhbhai Patel Road",
                  default: true,
                  "@type": "Headquarter",
                  phone: {
                    phoneNumber: {
                      country: {
                        name: "India",
                        flags: "https://flagcdn.com/in.svg",
                        short: "IN",
                        dial_code: "+91",
                      },
                      value: "90001 60001",
                      valid: true,
                    },
                  },
                  email: {
                    emailAddress: "pcfor62@gmail.com",
                  },
                  locationName: "VP_Mobiles",
                },
                preferred: false,
              },
              {
                mediumType: "siteLocation",
                characteristic: {
                  city: "Konkan Division",
                  unit: "38E",
                  country: "India",
                  state: "Maharashtra",
                  postalCode: "400047",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  description:
                    "Vallabhbhai Patel Road, 38E, Konkan Division, Maharashtra, India, 400047",
                  address1: "Vallabhbhai Patel Road",
                  default: false,
                  "@type": "Headquarter",
                  phone: {
                    phoneNumber: {
                      country: {
                        name: "India",
                        flags: "https://flagcdn.com/in.svg",
                        short: "IN",
                        dial_code: "+91",
                      },
                      value: "90001 60001",
                      valid: true,
                    },
                  },
                  email: {
                    emailAddress: "pcfor62@gmail.com",
                  },
                  locationName: "VP_Mobiles",
                },
                preferred: false,
              },
              {
                mediumType: "siteLocation",
                characteristic: {
                  city: "Coimbatore",
                  country: "India",
                  postCode: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  description:
                    "Kovai Kutralam Road, 34E, Coimbatore, Tamil Nadu, India, 641114",
                  address1: "Kovai Kutralam Road",
                  default: true,
                  "@type": "",
                  preferred: false,
                  postalCode: "641114",
                  unit: "34E",
                  state: "Tamil Nadu",
                  phone: {
                    mediumType: "phone",
                    phoneNumber: {
                      country: {
                        name: "India",
                        flags: "https://flagcdn.com/in.svg",
                        short: "IN",
                        dial_code: "+91",
                      },
                      value: "90002 70002",
                      valid: true,
                    },
                    preferred: false,
                  },
                  email: {
                    preferred: false,
                    mediumType: "email",
                    emailAddress: "contactkovai@vpmobiles.com",
                  },
                  locationName: "VP mobiles kovai",
                },
                preferred: false,
              },
            ],
            creditRating: [],
            attachment: [
              {
                "@type": "Profile",
                profile: {
                  name: "wp8846486-deadpool-dark-wallpapers.jpg",
                  attachment: 207109,
                  lastUpdated: 1713425601515,
                },
              },
            ],
            taxExemptionCertificate: [],
            characteristic: [],
            organizationParentRelationship: {
              name: "NMK_mobiles",
            },
            organizationIdentification: [],
            organizationChildRelationship: [],
            relatedParty: [
              {
                id: "6620ce6153557a4ffeb106a1",
                name: "Dilip param",
                role: "Account executive",
              },
              {
                id: "6620cdde53557a4ffeb10667",
                name: "Sakthi  varsan",
                role: "Account executive",
              },
              {
                name: "Christopher Wilson",
                id: "661112dafea83dd3bd538e17",
                role: "Account executive",
              },
            ],
            email: "pcfor62@gmail.com",
            plan: "7 days trail",
            lastUpdate: "2024-04-18T04:59:13.954Z",
            lastVisit: null,
            createdAt: "2024-04-18T04:59:13.954Z",
            rootName: "NMK_mobiles",
            "@baseType": "customer",
            tags: ["Retail", "Mobiles", "Demo unit"],
            paymentTerms: [],
            notes: [],
            __v: 0,
          },
          {
            _id: "6620214fda9b2dd18082b164",
            id: "6620214fda9b2dd18082b164",
            displayID: "CM-0001",
            customName: "Hanubar Manufacturing Plant",
            tradingName: "Hanubar_Manufacturing_Plant",
            externalReference: [
              {
                name: "",
                externalIdentifierType: "internetSite",
              },
            ],
            partyCharacteristic: [
              {
                name: "number of employees",
                value: "11 - 50",
              },
              {
                name: "market segment",
                value: "Food and Beverage",
              },
            ],
            name: "Hanubar_Manufacturing_Plant",
            status: "Inactive",
            otherName: [],
            contactMedium: [
              {
                preferred: false,
                mediumType: "email",
                characteristic: {
                  emailAddress: "hanubarindustries@gmail.com",
                },
              },
              {
                mediumType: "phone",
                characteristic: {
                  phoneNumber: "",
                },
                preferred: false,
              },
              {
                mediumType: "fax",
                characteristic: {
                  faxNumber: "",
                },
                preferred: false,
              },
              {
                mediumType: "postalAddress",
                characteristic: {
                  city: "Essex County",
                  country: "United States",
                  postCode: "",
                  stateOrProvince: "",
                  street1: "",
                  street2: "",
                  description:
                    "90 1st St, Essex County, New Jersey, United States,  Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States Essex County, New Jersey, United States07107-3102",
                  address1: "90 1st St",
                  default: true,
                  "@type": "Subquarter",
                  postalCode: "07107-3102",
                  unit: "",
                  state: "New Jersey",
                },
                preferred: false,
              },
            ],
            creditRating: [],
            attachment: [],
            taxExemptionCertificate: [],
            characteristic: [],
            organizationParentRelationship: {
              name: "NMK_mobiles",
            },
            organizationIdentification: [],
            organizationChildRelationship: [],
            relatedParty: [
              {
                name: "Christopher Wilson",
                id: "661112dafea83dd3bd538e17",
                role: "Account executive",
              },
            ],
            email: "hanubarindustries@gmail.com",
            plan: "7 days trail",
            lastUpdate: "2024-04-17T17:44:41.843Z",
            lastVisit: null,
            createdAt: "2024-04-17T17:44:41.843Z",
            rootName: "NMK_mobiles",
            "@baseType": "customer",
            tags: ["Active"],
            paymentTerms: [],
            notes: [],
            __v: 0,
          },
        ],
      },
      treedataPhysical: {
        service: {
          icones: "icon-sd-card-regular tree-icon-Physical",

          name: "Physical Resource",

          value: "Physical Resource",
          data: {},
        },
        specification: {
          name: "all product",
        },
      },
      treedataServiceCfs: {
        service: {
          icones: "icon-users-group-alt-filled tree-icone2",
          name: "Customer Facing Service",
          value: "Customer Facing Service",
          data: {},
        },
        specification: {
          name: "all product",
        },
      },
      treedataServiceRfs: {
        service: {
          icones: "icon-shield-bolt-regular tree-icon",

          name: "Resource Facing Service",

          value: "Resource Facing Service",
          data: {},
        },
        specification: {
          name: "all product",
        },
      },
      dropdown2data1: [],
      // dropdown2data1: [
      //   {
      //     name: "IoT Soluvec Ltd",
      //     description:
      //       "This service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segmentThis service catalog describes service that address the internet of things segment",
      //     lifecycleStatus: "Rejected",
      //     serviceSpecCharacteristic: [
      //       { name: "Resource Interaction " },
      //       { name: "Resource Abstraction" },
      //       { name: "Resource Lifecycle Management" },
      //     ],
      //   },
      //   {
      //     name: "E-commerce Website",
      //     description:
      //       "This service catalog entry represents our customer-facing e-commerce website.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Customer Support (Email)",
      //     description:
      //       "This service catalog entry covers customer support via email for our clients.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Mobile App",
      //     description:
      //       "This service catalog entry represents our customer-facing mobile application.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "In-Person Consultation",
      //     description:
      //       "This service catalog entry includes in-person consultations with our clients.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Customer Loyalty Program",
      //     description:
      //       "This service catalog entry outlines our customer-facing loyalty program.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Live Chat Support",
      //     description:
      //       "This service catalog entry provides customer support via live chat on our website.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Customer Feedback Portal",
      //     description:
      //       "This service catalog entry covers our customer-facing feedback portal.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Social Media Engagement",
      //     description:
      //       "This service catalog entry represents our customer-facing social media engagement activities.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Online Booking System",
      //     description:
      //       "This service catalog entry covers our customer-facing online booking system.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Customer Self-Serice Portal",
      //     description:
      //       "This service catalog entry represents our customer-facing self-service portal.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Product Demo Workshops",
      //     description:
      //       "This service catalog entry covers our customer-facing product demonstration workshops.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Virtual Tours",
      //     description:
      //       "This service catalog entry represents our customer-facing virtual tours of our facilities.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Customer Webinars",
      //     description:
      //       "This service catalog entry covers our customer-facing educational webinars.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Product Customization",
      //     description:
      //       "This service catalog entry represents our customer-facing product customization options.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      //   {
      //     name: "Customer Onboarding",
      //     description:
      //       "This service catalog entry covers our customer-facing onboarding process for new clients.",
      //     lifecycleStatus: "Active",
      //     characteristics: [
      //       { name: "Resource Monitoring and Alerts" },
      //       { name: "Resource Performance Optimization" },
      //       { name: "Resource Documentation" },
      //     ],
      //   },
      // ],
      dropdown2data2: [
        {
          name: "Database Management",
          description:
            "This service catalog entry represents our resource-facing database management service.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Server Monitoring",
          description:
            "This service catalog entry covers our resource-facing server monitoring and maintenance.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Network Security",
          description:
            "This service catalog entry represents our resource-facing network security measures.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Data Backup and Recovery",
          description:
            "This service catalog entry covers resource-facing data backup and recovery processes.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Hardware Procurement",
          description:
            "This service catalog entry represents our resource-facing hardware procurement process.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Cloud Resource Allocation",
          description:
            "This service catalog entry covers resource-facing cloud resource allocation and management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "IT Infrastructure Planning",
          description:
            "This service catalog entry represents our resource-facing IT infrastructure planning service.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "License Management",
          description:
            "This service catalog entry covers resource-facing software license management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Data Center Operations",
          description:
            "This service catalog entry represents resource-facing data center operations and maintenance.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Vendor Management",
          description:
            "This service catalog entry covers resource-facing vendor and supplier management.",
          lifecycleStatus: "Inactive",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Capacity Planning",
          description:
            "This service catalog entry represents resource-facing capacity planning for IT resources.",
          lifecycleStatus: "Draft",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Network Configuration",
          description:
            "This service catalog entry covers resource-facing network configuration and optimization.",
          lifecycleStatus: "Pending",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Asset Inventory Management",
          description:
            "This service catalog entry represents resource-facing asset inventory tracking.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Software Patch Management",
          description:
            "This service catalog entry covers resource-facing software patching and updates.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Server Virtualization",
          description:
            "This service catalog entry represents resource-facing server virtualization and management.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
      dropdown2data3: [
        {
          name: "Silver Loyalty Program",
          description:
            "Exclusive benefits for loyal customers at the silver level.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Gold Rewards Membership",
          description: "Our premium loyalty program with gold-level benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Bronze Loyalty Tier",
          description: "Entry-level loyalty program with bronze-tier perks.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Platinum Rewards Club",
          description:
            "Our top-tier loyalty program for platinum-level members.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Frequent Shopper Program",
          description:
            "Earn rewards for frequent shopping with our loyalty program.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Exclusive VIP Loyalty",
          description: "VIP loyalty program for exclusive customers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Youth Loyalty Program",
          description:
            "Loyalty program designed for our younger customer base.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Family Rewards Membership",
          description:
            "Loyalty program with family-oriented benefits and offers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Travel Enthusiast Program",
          description: "Loyalty program for customers passionate about travel.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Digital Rewards Club",
          description:
            "Loyalty program with digital-exclusive rewards and offers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Sports Fan Loyalty",
          description:
            "Loyalty program for sports enthusiasts with exclusive sports-related benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Green Eco Loyalty",
          description:
            "Loyalty program for environmentally conscious customers.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Tech Enthusiast Rewards",
          description:
            "Loyalty program for tech-savvy customers with tech-related rewards.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Health and Wellness Club",
          description:
            "Loyalty program promoting health and wellness with exclusive benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Gourmet Foodie Loyalty",
          description:
            "Loyalty program for food enthusiasts with gourmet-related perks.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Art and Culture Rewards",
          description:
            "Loyalty program for customers interested in art and culture.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Pet Lover Loyalty",
          description: "Loyalty program for customers who love their pets.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Fashionista Rewards",
          description:
            "Loyalty program for fashion-forward customers with exclusive fashion benefits.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Bookworm Loyalty Club",
          description: "Loyalty program for avid readers and book enthusiasts.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
      dropdown2data4: [
        {
          name: "Adventure Seeker Rewards",
          description:
            "Loyalty program for customers seeking adventurous experiences.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Home Decor Enthusiast Club",
          description:
            "Loyalty program for customers passionate about home decor.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Outdoor Fitness Rewards",
          description:
            "Loyalty program for fitness enthusiasts who prefer outdoor activities.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "DIY Project Masters",
          description:
            "Loyalty program for customers who love do-it-yourself (DIY) projects.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Car Enthusiast Rewards",
          description:
            "Loyalty program for customers passionate about cars and automotive culture.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Tech and Gaming Elite",
          description:
            "Loyalty program for customers at the intersection of technology and gaming.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Culinary Connoisseur Club",
          description:
            "Loyalty program for customers with a passion for gourmet cuisine.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Wellness and Mindfulness Rewards",
          description:
            "Loyalty program promoting wellness and mindfulness practices.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Social Media Maven Club",
          description:
            "Loyalty program for customers who are influential on social media.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Smart Home Innovators",
          description:
            "Loyalty program for customers embracing smart home technologies.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Fitness Challenge Champions",
          description:
            "Loyalty program for customers participating in fitness challenges.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Travel Photography Enthusiasts",
          description:
            "Loyalty program for customers passionate about travel photography.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Nature Explorer Rewards",
          description:
            "Loyalty program for customers exploring and appreciating nature.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Fitness Tech Innovators",
          description:
            "Loyalty program for customers adopting cutting-edge fitness technologies.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Gaming Community Rewards",
          description:
            "Loyalty program for customers actively engaged in gaming communities.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Mindful Parenting Loyalty",
          description:
            "Loyalty program for parents dedicated to mindful parenting practices.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Virtual Reality Enthusiasts Club",
          description:
            "Loyalty program for customers exploring virtual reality experiences.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Green Thumb Rewards",
          description:
            "Loyalty program for gardening enthusiasts with green thumbs.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
        {
          name: "Educational Explorer Club",
          description:
            "Loyalty program for customers dedicated to continuous learning and education.",
          lifecycleStatus: "Active",
          characteristics: [
            { name: "Resource Monitoring and Alerts" },
            { name: "Resource Performance Optimization" },
            { name: "Resource Documentation" },
          ],
        },
      ],
      expandAll: false,
      collapse: false,
      isCollapsed: false,
      collapseAll: false,
      editor_theme: "light",
      tree_direction: "vertical",
      zoom: 100,
      table_data: [
        {
          attribute: "tree-direction",
          description: "To specify the direction of tree.",
          type: "String",
          accepted_values: "horizontal,vertical",
          default: "vertical",
          mandatory: false,
        },
        {
          attribute: "is-draggable",
          description: "To make tree draggable",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "expand-all",
          description: "To expand all nodess",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "collapse-all",
          description: "To collapse all nodess",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "zoomable",
          description: "To make tree zoomable",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "config",
          description:
            "Provied the Api key for the authentication and Provied the Api for adding child. ",
          type: "String",
          accepted_values: `
config:{
  url:"https://example/tree",
key:"dbee69aa047d12f22275e281ee432da3"
  }`,
          default: "-",
          mandatory: true,
        },
        {
          attribute: "nodes",
          description: `To build a tree data and format should be like 
         service: {
          icones: "icon-credit-card-regular ",
          name: "service",
          value: "service",
          data: {},
        },
        specification: {
          name: "all product",
        }`,
          type: "Object",
          accepted_values: "Object",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "parent-name",
          description: `Pass the value of first nodes data specification name`,
          type: "Srting",
          accepted_values: "Srting",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "type",
          description: `Pass the value of tree type`,
          type: "Srting",
          accepted_values: "product/service/resource/Hierarchy",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "customer-facing-service-option",
          description: `Pass the list of items to be added in the customer-facing-service dropdown list`,
          type: "Array",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "resource-facing-service-option",
          description: `Pass the list of items to be added in the resource-facing-service-option dropdown list`,
          type: "Array",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "physical-resource-option",
          description: `Pass the list of items to be added in the physical-resource-option dropdown list`,
          type: "Array",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "logical-resource-option",
          description: `Pass the list of items to be added in the logical-resource-option dropdown list`,
          type: "Array",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "readOnly",
          description: `Disables user input`,
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "zoom",
          description: "percentage of zoom level to achieve",
          type: "Number",
          accepted_values: ">=10 and <= 200",
          default: "100",
          mandatory: false,
        },
        {
          attribute: "nodes",
          description: `
          To build a tree data and format should be like 
          {
            label: String,
            data: Object,
            child: [
                {
                label: "String",
                data: Object,
                child: [{...}]
                }
              ]`,
          type: "Object",
          accepted_values: "Object",
          default: "-",
          mandatory: true,
        },
      ],
      treeAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],

      basic_tree: `
<template>
   <ul class="tree" :class="tree_direction">
                  <hlx-tree :nodes="treedata" :tree-direction="tree_direction"/>
      </ul>
</template>
<script>

export default {
  name: "TreeView",
  components: {
    TreeChild,
  },

  watch:{
      tree_direction : function(){
        this.tree_direction
      }
  },
  methods: {

    myFunction(){
      if(this.tree_direction=="vertical"){
        this.tree_direction="horizontal"
      }
      else{
        this.tree_direction="vertical"
      }
    }
  },
  data() {
    return {
    
      tree_direction: "vertical",
      treedata: {
        label: "root",
        child: [
          {
            label: "child1",
            child: [
              {
                label: "child1.2",
                child: [
                  {
                    label: "child1.2.1",
                    child: [
                      {
                        label: "child1.2.11",
                        child: [],
                      },
                    ],
                  },
                  {
                    label: "child1.2.2",
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            label: "child2",
            child: [
              {
                label: "child2.1",
                child: [],
              },
            ],
          },
        ],
      },
    };
  },
};
<\/script>
<style lang="scss" scoped>
.example-body {
  position: relative;
  .tree-container {
    position: relative;
    height: auto;
    width: 100%;
    margin-left: auto;
    padding: 0 599.9px;
    /* border: solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &.custom {
    height: 800px;
    overflow: auto;
  }
  .custom {
    position: sticky;
    left: 0%;
  }
}
.switcher {
  display: flex;
  gap: 10px;
}
.change-btn {
  float: right;
  border: none;
  color: white;
  padding: 8px;
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin: 10px 10px;
  margin-left: auto;
  cursor: pointer;
}
</style>

      `,
      drag_tree: `
<template>
   <ul class="tree" :class="tree_direction">
                  <hlx-tree :nodes="treedata" :tree-direction="tree_direction" :isDraggable="true"/>
      </ul>
</template>
<script>

export default {
  name: "TreeView",
  components: {
    TreeChild,
  },

  watch:{
      tree_direction : function(){
        this.tree_direction
      }
  },
  methods: {

    myFunction(){
      if(this.tree_direction=="vertical"){
        this.tree_direction="horizontal"
      }
      else{
        this.tree_direction="vertical"
      }
    }
  },
  data() {
    return {
    
      tree_direction: "vertical",
      treedata: {
        label: "root",
        child: [
          {
            label: "child1",
            child: [
              {
                label: "child1.2",
                child: [
                  {
                    label: "child1.2.1",
                    child: [
                      {
                        label: "child1.2.11",
                        child: [],
                      },
                    ],
                  },
                  {
                    label: "child1.2.2",
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            label: "child2",
            child: [
              {
                label: "child2.1",
                child: [],
              },
            ],
          },
        ],
      },
    };
  },
};
<\/script>
<style lang="scss" scoped>
.example-body {
  position: relative;
  .tree-container {
    position: relative;
    height: auto;
    width: 100%;
    margin-left: auto;
    padding: 0 599.9px;
    /* border: solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &.custom {
    height: 800px;
    overflow: auto;
  }
  .custom {
    position: sticky;
    left: 0%;
  }
}
.switcher {
  display: flex;
  gap: 10px;
}
.change-btn {
  float: right;
  border: none;
  color: white;
  padding: 8px;
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin: 10px 10px;
  margin-left: auto;
  cursor: pointer;
}
</style>

      `,

      zoomable_tree: `
      <template>
   <ul class="tree" :class="tree_direction">
                  <hlx-tree :nodes="treedata" :tree-direction="tree_direction"  zoomable :zoom="this.zoom"/>
      </ul>
</template>
<script>

export default {
  name: "TreeView",
  components: {
    TreeChild,
  },

  watch:{
      tree_direction : function(){
        this.tree_direction
      }
  },
  methods: {

    myFunction(){
      if(this.tree_direction=="vertical"){
        this.tree_direction="horizontal"
      }
      else{
        this.tree_direction="vertical"
      }
    }
  },
  data() {
    return {
    
      tree_direction: "vertical",
      treedata: {
        label: "root",
        child: [
          {
            label: "child1",
            child: [
              {
                label: "child1.2",
                child: [
                  {
                    label: "child1.2.1",
                    child: [
                      {
                        label: "child1.2.11",
                        child: [],
                      },
                    ],
                  },
                  {
                    label: "child1.2.2",
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            label: "child2",
            child: [
              {
                label: "child2.1",
                child: [],
              },
            ],
          },
        ],
      },
    };
  },
};
<\/script>
<style lang="scss" scoped>
.example-body {
  position: relative;
  .tree-container {
    position: relative;
    height: auto;
    width: 100%;
    margin-left: auto;
    padding: 0 599.9px;
    /* border: solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &.custom {
    height: 800px;
    overflow: auto;
  }
  .custom {
    position: sticky;
    left: 0%;
  }
}
.switcher {
  display: flex;
  gap: 10px;
}
.change-btn {
  float: right;
  border: none;
  color: white;
  padding: 8px;
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin: 10px 10px;
  margin-left: auto;
  cursor: pointer;
}
</style>

      `,
      expandable_tree: `
      <template>
   <ul class="tree" :class="tree_direction">
                  <hlx-tree :nodes="treedata" :tree-direction="tree_direction" :collapse-all="true" :expandAll="false" zoomable :zoom="this.zoom"/>
      </ul>
</template>
<script>

export default {
  name: "TreeView",
  components: {
    TreeChild,
  },

  watch:{
      tree_direction : function(){
        this.tree_direction
      }
  },
  methods: {

    myFunction(){
      if(this.tree_direction=="vertical"){
        this.tree_direction="horizontal"
      }
      else{
        this.tree_direction="vertical"
      }
    }
  },
  data() {
    return {
    
      tree_direction: "vertical",
      treedata: {
        label: "root",
        child: [
          {
            label: "child1",
            child: [
              {
                label: "child1.2",
                child: [
                  {
                    label: "child1.2.1",
                    child: [
                      {
                        label: "child1.2.11",
                        child: [],
                      },
                    ],
                  },
                  {
                    label: "child1.2.2",
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            label: "child2",
            child: [
              {
                label: "child2.1",
                child: [],
              },
            ],
          },
        ],
      },
    };
  },
};
<\/script>
<style lang="scss" scoped>
.example-body {
  position: relative;
  .tree-container {
    position: relative;
    height: auto;
    width: 100%;
    margin-left: auto;
    padding: 0 599.9px;
    /* border: solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &.custom {
    height: 800px;
    overflow: auto;
  }
  .custom {
    position: sticky;
    left: 0%;
  }
}
.switcher {
  display: flex;
  gap: 10px;
}
.change-btn {
  float: right;
  border: none;
  color: white;
  padding: 8px;
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin: 10px 10px;
  margin-left: auto;
  cursor: pointer;
}
</style>

      `,

      treedata: {
        label: "All products",
        data: {},

        child: [
          {
            label: "Computers",
            // data: { name: "andree", age: 12 },
            child: [
              {
                label: "Apple",
              },
              {
                label: "AMD",
              },
              {
                label: "Intel",
              },
            ],
          },
          {
            label: "Books",
            // data: { name: "andree", age: 12 },
            child: [
              {
                label: "Fiction",
              },
              {
                label: "Non-fiction",
              },
            ],
          },
          {
            label: "ICs",
          },
        ],
      },
    };
  },
  watch: {
    tree_direction: function () {
      this.tree_direction;
    },
  },
  // mounted() {
  //   console.log("side");
  // },
  async mounted() {
    const spec = await axios.get(
      `https://tmf-upc-ghubgwxrcq-pd.a.run.app/tree/cfs`,
      {
        headers: {
          "hlx-api-key":
            "dbee69aa047d12f22275e281ee432da3:2920ca58abb53b122a1e06c94ceabdc1323d441d980a1f19d1c7f040a5e8910af798fdaf8a7213898efbbb43b74da0e3670964b9d96a4081415cca3beeed7727",
        },
      }
    );

    // const drop = await axios.get(
    //   `https://tmf-upc-ghubgwxrcq-pd.a.run.app/tree/65e990f4c403fbd591917e75`,
    //   {
    //     headers: {
    //       Authorization:
    //         "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZXZlbG9wZXJAaGFsbGV5eC5jb20iLCJjb21wYW55IjoiZGV2ZWxvcGVySGFsbGV5eCIsImlhdCI6MTcxMDU5OTAwNCwiZXhwIjoxNzEwNzcxODA0fQ.imZTY69_8M8CQFXJlvFk7zSdDMtlqwAM0AGxs76yT5xmsAdGcdluNtJuLpwIj3-w1SdeSrh5IfF0M5dXggF11WsCwxadWD_88G8iNuDIYRs2FCBkzVl0rWwCDQRFCHfRBiXVb-pupUEmf5NGHxV1M48X161QbWHbOs8Ve2zBdY-Z25l4Kx3d7afLSBN725pKaTT20aMFOu5fegN3apT0L1-aquHo1HPFYbzX-l8i6Chq4Jd2wsiFVhMtVKCtRjhz8fpp_imGfRr9ZMhraEVFqy0J10dcI4k4nvLXJ3Ye8E5D31d3SE44MWNuQW219q8sZyfK9oWgL71g1AvWxoQQMsfmmfrxy30AAB_3jnzZ_Q32qZiWrsElCO7b_jb6sHnIQl7yUzb3lOLdZH1w6YN6fGmjvbw-xDzDpJy-usc2ukWcMtvxiVt9jyTslKe9ZPzMgidVD4YUrlmyhpVJEbtUJPIF5nRl834yztuq7XGYSXdUIdh90VLMR_hCQL997BdpJurJFj8g3nuOaS4EM_KpNLCcb_9Qri7fG8TwmCxUQikB8MgnV1Wk__OJnLCnWhs-izFCWg2BLc_LaSFZRCSusOkoJ-gyAhzOi5tUXr1WWMsPFG9CgJDAAMc6aDETSX870nnqI9RSRx1sW3O5xvTyxfSuzLSQs0mpIhpUzoU2Smo ",
    //     },
    //   }
    // );
    // this.treedataval = drop.data;
    this.dropdown2data1 = await spec.data;
    console.log(this.dropdown2data1, "dropdown2data1");
  },
  methods: {
    dataReceived(val) {
      console.log(val);
    },
    updatednode(val) {
      console.log(val, "updatednode");
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },

    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    myFunction() {
      if (this.tree_direction == "vertical") {
        this.tree_direction = "horizontal";
      } else {
        this.tree_direction = "vertical";
      }
    },
    expand() {
      if (this.isCollapsed) {
        this.collapseAll = this.isCollapsed;
        this.expandAll = !this.isCollapsed;
      } else {
        this.collapseAll = this.isCollapsed;
        this.expandAll = !this.isCollapsed;
      }
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
<style lang="scss" scoped>
.example-body {
  position: relative;
  .tree-container {
    position: relative;
    height: auto;
    width: 100%;
    margin-left: auto;
    padding: 0 599.9px;
    /* border: solid; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &.custom {
    height: 800px;
    overflow: auto;
  }
  .custom {
    position: sticky;
    left: 0%;
  }
}
.switcher {
  display: flex;
  gap: 10px;
}
.change-btn {
  float: right;
  border: none;
  color: white;
  padding: 8px;
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin: 10px 10px;
  margin-left: auto;
  cursor: pointer;
}
</style>
