<template>
  <h1 class="component-heading">Select</h1>
  <p class="component-description">
    If the options have a clear hierarchical structure, Cascader can be used to view and select them.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    To specify input for the dropdown<b> :options</b><br />
    To display custom placeholder, add <b>:placeholder-value</b> attribute which
    takes in a string value <br />
    You can also toggle label animation to On/Off by setting
    <b>:label-animation</b> attribute to <b>true</b> To clear the selected value
    use <b>:clearable</b> to <b>true</b><br />
    The selected value can be accessed from the
    <b>v-model:value</b> attribute<br />
    The selected name can be accessed from the
    <b>v-model:name</b> attribute<br />
  </p><br>

  <!-------------------------------------------- Clearable select ------------------------------------------------>
  <h2 class="component-side-heading">
   Clearable select 
  </h2>
  <div class="component-example">
    <div class="example-body">
      <hlxselect
                      v-model:value="data['value']"   
                      v-model:name="data['key']"        
                      :options="dropdown_data"
                      :placeholder-value="'Language'"
                      :label-animation="true"
                      :label="'name'"                        
                      :prop-value="'value'"                  
                      :clearable="true"
                      @custom-change="returnOptions"              
                      @selected-value="returnSelectedObject"
      >
      </hlxselect>
    </div>
    <div class="example-footer">
      <span
        id="basic-select-icon"
        class="icon"
        @click="showCode('basic-select')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-select" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

<!-------------------------------------------------------- Prevalue ---------------------------------------->
  <h2 class="component-side-heading">Pre-populate select</h2>
    <p>
    Pre-populate the select with value of the option using the <b>:pre-value</b>
    attribute
  </p>
  <div class="component-example">
    <div class="example-body">
      <!-- <hlxselect
        v-model:value="data.key"
        :options="dropdown_data"
        :placeholder-value="'Language'"
        :label-animation="true"
        :clearable="false"
        :pre-value="prevalue"
        :required="true"
        :error="errorval"
        :custom-error="errorvalue"
        @custom-error-emit-value="checkval1"
      ></hlxselect> -->
      <hlxselect
        v-model:value="data['value']"   
        v-model:name="data['key']"        
        :pre-value="prevalue"                
        :options="dropdown_data"
        :placeholder-value="'Language'"
        :label-animation="true"
        :label="'name'"        
        :prop-value="'value'"                  
        @custom-change="returnOptions"              
        @selected-value="returnSelectedObject"
        >
      </hlxselect>
    </div>
    <div class="example-footer">
      <span
        id="pre-value-icon"
        class="icon"
        @click="showCode('pre-value')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="pre-value" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="prevalue_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>


<!----------------------------------------------------------- Error handling---------------------------------------->

<h2 class="component-side-heading"> Error handling in the select </h2>
  <div class="component-example">
    <div class="example-body">
      <hlxselect
        :options="dropdown_data"
        :placeholder-value="'Language'"
        :label-animation="true"
        :required="true"
        :clearable="true"
        :error="errorval"
        :custom-error="errorvalue"
        @custom-error-emit-value="checkval1"
      ></hlxselect>
    </div>
    <div class="example-footer">
      <span
        id="error-handling-icon"
        class="icon"
        @click="showCode('error-handling')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="error-handling" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="errorhandling_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

<!----------------------------------------------------------------- Footer ----------------------------------------------------->
<h2 class="component-side-heading"> Footer in the select </h2>
  <div class="component-example">
    <div class="example-body">
      <hlxselect
        :options="dropdown_data"
        :placeholder-value="'Language'"
        :label-animation="true"
        :footer-icon="'icon-plus-circle-regular'"
        :footer-content="'Add language'"
        :footer="true"
        @footer-click="callFooterEvent"
      ></hlxselect>
    </div>
    <div class="example-footer">
      <span
        id="footer-icon"
        class="icon"
        @click="showCode('footer')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="footer" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="footer_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>



  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Select with icons</h2>

  <p class="component-description">
    To have icon inside the select refer the table below<br />
    To specify input for the dropdown<b> :options</b><br />
    To display custom placeholder, add <b>:placeholder-value</b> attribute which
    takes in a string value <br />
    You can also toggle label animation to On/Off by setting
    <b>:label-animation</b> attribute to <b>true</b> To clear the selected value
    use <b>:clearable</b> to <b>true</b><br />
    To add country flag inside the select use <b>:icon</b> to <b>true</b>
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlxselect
        :options="new_data"
        :scroll-classname="'component-body'"
        :flag-icon="true"
        :flag-value="true"
        :placeholder-value="'Country'"
        :label-animation="true"
        :icon="true"
        :required="true"
        @custom-change="selectValue"
        @custom-error-emit-value="checkval1"
      ></hlxselect>
      <!-- <hlxselect :options="this.dropdown_data" :placeholder-value="'hello'" :label-animation="true" :clearable="true"  @custom-change="selectValue"></hlxselect> -->
    </div>
    <div class="example-footer">
      <span
        id="basic-country-select-icon"
        class="icon"
        @click="showCode('basic-country-select')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-country-select" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_country_select"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>



  <!----------------------------------------------------------- Inline search -------------------------------------------------->

  <h2 class="component-heading">Select with inline search</h2>
  <p class="component-description">
    To enable inline search in select set <b>:inline-search</b> to <b>true</b>
    <br />
    The selected value and prevalue can be accessed from the
    <b>v-model</b> attribute<br />
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlxselect
        v-model="prevalue1"
        :options="dropdown_data1"
        :placeholder-value="'Language'"
        :label="'name'"
        :prop-value="'value'"
        :label-animation="true"
        :inline-search="true"
        :search-image-tag="true"
        :single-border="false"
        :custom-search="false"
        :clearable="true"
        :required="false"
        :footer="false"
        :error="errorval"
        :custom-error="errorvalue"
        @key-press="sa"
        @inline-select-value="emits"
        @custom-error-emit-value="checkval"
        @selected-value="selectedObjValue"
      >
      </hlxselect>

      <hlxselect
        v-model="prevalue1"
        :options="dropdown_data1"
        :placeholder-value="'Language'"
        :label="'name'"
        :prop-value="'value'"
        :label-animation="true"
        :inline-search="true"
        :single-border="false"
        :custom-search="false"
        :clearable="true"
        :required="false"
        :footer="true"
        :error="errorval"
        :custom-error="errorvalue"
        @key-press="sa"
        @inline-select-value="emits"
        @custom-error-emit-value="checkval"
        @selected-value="selectedObjValue"
      >
      </hlxselect>

      <!-- <hlxselect
        v-model="prevalue"
        :slot-options="true"
        :options="dropdown_data1"
        :placeholder-value="data12"
        :label="'name'"
        :prop-value="'value'"
        :style="'css'"
        :label-animation="true"
        :search-image-tag="false"
        :sub-title="'subtitle'"
        :tag-value="'labelvalue'"
        :image-link="'link'"
        :scroll-classname="'component-body'"
        :inline-search="true"
        :required="true"
        :footer="true"
        :disabled="false"
        :swatch="true"
        :footer-icon="'icon-plus-circle-regular'"
        :footer-content="'Add schema'"
        @footer-click="abc"
      >
      <template #displayValue="value">
        <span style="padding-left:8px;display:flex;align-items: center;"> <span style="height:20px;width:20px;border-radius: 20px;background-color:blueviolet;margin-right:4px"></span> {{ value['displayLabel']['name'] }}</span>
      </template>
      <template #options="option">
      <span style="padding-left:8px;display:flex;align-items: center;">{{ option['optionsData']['name'] }}<span style="height:20px;width:20px;border-radius: 20px;background-color:blueviolet;margin-left:4px"></span></span>
    </template>
      </hlxselect> -->
    </div>
    <div class="example-footer">
      <span
        id="inline-search-icon"
        class="icon"
        @click="showCode('inline-search')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="inline-search" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="inline_search"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>


<!--------------------------------------------------------------  Inline search slot    --------------->

<h2 class="component-heading">Select with inline search using slot</h2>
  <p class="component-description">
    To enable inline search and slot in the select set <b>:inline-search</b> to <b>true</b> and <b>:slot-options</b> to <b>true</b>
    <br />
    The selected value and prevalue can be accessed from the
    <b>v-model</b> attribute<br />
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlxselect
        v-model="prevalue"
        :slot-options="true"
        :options="dropdown_data1"
        :placeholder-value="data12"
        :label="'name'"
        :prop-value="'value'"
        :label-animation="true"
        :inline-search="true"
      >
      <template #displayValue="value">
        <span style="padding-left:8px;display:flex;align-items: center;"> <span style="height:20px;width:20px;border-radius: 20px;background-color:blueviolet;margin-right:4px"></span> {{ value['displayLabel']['name'] }}</span>
      </template>
      <template #options="option">
      <span style="padding-left:8px;display:flex;align-items: center;">{{ option['optionsData']['name'] }}<span style="height:20px;width:20px;border-radius: 20px;background-color:blueviolet;margin-left:4px"></span></span>
    </template>
      </hlxselect>
    </div>
    <div class="example-footer">
      <span
        id="inline-search-slot-icon"
        class="icon"
        @click="showCode('inline-search-slot')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="inline-search-slot" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="inline_search_slot"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>



 <!-------------------------------------------------------------  Inline search tag and image --> 

 <h2 class="component-heading">Select with inline search using custom</h2>
  <p class="component-description">
    To enable inline search in select set <b>:inline-search</b> to <b>true</b>
    <br />
    The selected value and prevalue can be accessed from the
    <b>v-model</b> attribute<br />
  </p>
  <div class="component-example">
    <div class="example-body">

      <hlxselect
        v-model="prevalue"
        :slot-options="false"
        :options="dropdown_data1"
        :placeholder-value="data12"
        :label="'name'"
        :prop-value="'value'"
        :label-animation="true"
        :search-image-tag="true"
        :sub-title="'subtitle'"
        :tag-value="'labelvalue'"
        :image-link="'link'"
        :inline-search="true"
        @inline-search-value="emits"
      >
      </hlxselect>

      <hlxselect
        v-model="prevalue1"
        :slot-options="false"
        :options="dropdown_data1"
        :placeholder-value="data12"
        :label="'name'"
        :prop-value="'value'"
        :label-animation="true"
        :search-image-tag="true"
        :sub-title="'subtitle'"
        :tag-value="'labelvalue'"
        :image-link="'link'"
        :inline-search="true"
        @inline-search-value="emits"
      >
      </hlxselect>
    </div>
    <div class="example-footer">
      <span
        id="inline-search-custom-icon"
        class="icon"
        @click="showCode('inline-search-custom')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="inline-search-custom" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="inline_search_custom"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>







<h2 class="component-side-heading">Rectangle select</h2>
  <p class="component-description">
    To specify input for the <b>:options</b> <br />
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-rectangle-select
        :v-model="'name'"
        :options="optionRectangle"
        :position="'horizontal'"
        :pre-value="initialSelectedValue"
        :show-ellipsis="false"
        @selectedOption="selected"
        @hoveredOption="hovered"
      >            </hlx-rectangle-select>
    </div>
    <div class="example-footer">
      <span
        id="feature-input-icon"
        class="icon"
        @click="showCode('feature-input')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="feature-input" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="feature_input"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <divider :position="'horizontal'" :space="'20px'" />
  <!-- <hlx-table-component :tableData="this.data.table_data" :theader="this.data.columns" :border="true"/> -->
  <h2 class="component-side-heading">Select attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in alertAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in alertAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>

  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <h2 class="component-side-heading">Events</h2>
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in selectevents"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in select_events" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in selectevents"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'stepper')"
      ><i class="icon-angle-left-regular prev-icon"></i>Step control</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'multiselect')"
      >Multi select <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>

<script>
// import hlxTable from "../components/NewTable.vue";
// import hlxColumn from "../components/NewTableColumn.vue";
// import axios from 'axios'
import HlxRectangleSelect from "../components/RectangleSelectComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import divider from "../components/DividerComponent.vue";
import hlxselect from "../components/SelectComponent.vue";
import CodeEditor from "simple-code-editor";

export default {
  name: "App",
  components: {
    HlxRectangleSelect,
    hlxselect,
    divider,
    CodeEditor,
    // hlxTable,
    // hlxColumn,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
  },
  data() {
    return {
      optionRectangle: [
        { name: "Ragavi", value: "Ragavi", choise:"make her rtyuio" },
        { name: "Pooja", value: "Pooja" },
        { name: "Feroz", value: "Feroz" },
        { name: "Ganesh", value: "Ganesh" },
        { name: "Vishnu", value: "Vishnu" },
      ],
      initialSelectedValue: "Ragavi",
      feature_input: `
<template>
<hlx-rectangle-select
        :prop-value="'name'"
        :options="optionRectangle"
        :position="'horizontal'"
        :pre-value="initialSelectedValue"
        :show-ellipsis="false"
        @selectedOption="selected"
/>
 
</template>

<script>
export default {
data(){
  return{
    optionRectangle: [
        { name: "Ragavi", value: "Ragavi" },
        { name: "Pooja", value: "Pooja" },
        { name: "Feroz", value: "Feroz" },
        { name: "Ganesh", value: "Ganesh" },
        { name: "Vishnu", value: "Vishnu" },
      ],,
          initialSelectedValue: "Ragavi",

  }
}
}
</\script>`,

      data12: "",
      test: {
        if: {
          level1: {
            level_conjunction: "and",
            groups: [
              {
                group_conjunction: "and",
                group: [
                  {
                    inner_group_conjunction: "and",
                    conditions: [
                      { id: 227, lhs: "EIOP.EI.MRC", op: "==", rhs: " 0" },
                    ],
                  },
                ],
              },
            ],
          },
          level2: { level_conjunction: "and", groups: [] },
          level3: { level_conjunction: "and", groups: [] },
          level4: { level_conjunction: "and", groups: [] },
          level5: { level_conjunction: "and", groups: [] },
        },
        then: {
          level1: {
            level_conjunction: "and",
            groups: [
              {
                group_conjunction: "and",
                group: [
                  {
                    inner_group_conjunction: "and",
                    conditions: [
                      { id: 381, lhs: "EIOP.TotalMRC", op: "=", rhs: " 20" },
                    ],
                  },
                ],
              },
            ],
          },
          level2: { level_conjunction: "and", groups: [] },
          level3: { level_conjunction: "and", groups: [] },
          level4: { level_conjunction: "and", groups: [] },
          level5: { level_conjunction: "and", groups: [] },
        },
        else: {
          level1: {
            level_conjunction: "and",
            groups: [
              {
                group_conjunction: "and",
                group: [
                  {
                    inner_group_conjunction: "and",
                    conditions: [
                      { id: 660, lhs: "EIOP.TotalMRC", op: "=", rhs: " 30" },
                    ],
                  },
                ],
              },
            ],
          },
          level2: { level_conjunction: "and", groups: [] },
          level3: { level_conjunction: "and", groups: [] },
          level4: { level_conjunction: "and", groups: [] },
          level5: { level_conjunction: "and", groups: [] },
        },
      },
      data: { key: "", value: '' },
      prevalue: "",
      prevalue1:'',
      check: [],
      value12: "",
      selectvalue: "",
      prevalue_data: ["Html", "VueJs"],
      alertAttributes: [
        {
          prop: "attribute",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      selectevents: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameters",
          label: "Parameters",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      select_events: [
        {
          name: "custom-change",
          description: "Return the selected values from dropdown",
          parameters: "-",
        },
        {
          name: "selectedOption",
          description: "Return the selected values for rectangle select",
          parameter: "",
        },
        {
          name: "hoveredOption",
          description: "Return the hovered values for rectangle select",
          parameter: "",
        },
        {
          name: "search-value",
          description:
            "Emits the name of the newly added option whose inline search is true",
          parameters: "-",
        },
        {
          name: "selected-value",
          description: "Emits the selected value",
          parameters: "-",
        }
      ],
      pre_value: [
        {
          id: 7,
          name: "Stuart",
        },
      ],
      new_data: [
        {
          id: 1,
          flag: "in",
          name: "India",
          value: "India",
          checked: false,
        },
        {
          id: 2,
          flag: "ca",
          name: "Canada",
          value: "Canada",
          code: "+1",
          checked: false,
        },
        {
          id: 3,
          flag: "us",
          name: "USA",
          code: "+1",
          checked: false,
          value: "USA",
        },
        {
          id: 4,
          flag: "cn",
          name: "China",
          code: "+86",
          checked: false,
          value: "China",
        },
        {
          id: 5,
          flag: "jp",
          name: "Japan",
          code: "+81",
          checked: false,
          value: "Japan",
        },
        {
          id: 6,
          flag: "kr",
          name: "South Korea",
          code: "+82",
          checked: false,
          value: "South Korea",
        },
        {
          id: 7,
          flag: "br",
          name: "Brazil",
          code: "+55",
          checked: false,
          value: "Brazil",
        },
        {
          id: 8,
          flag: "mx",
          name: "Mexico",
          code: "+52",
          checked: false,
          value: "Mexico",
        },
        {
          id: 9,
          flag: "ru",
          name: "Russia",
          code: "+7",
          checked: false,
          value: "Russia",
        },
        {
          id: 10,
          flag: "au",
          name: "Australia",
          code: "+61",
          checked: false,
          value: "Australia",
        },
        {
          id: 11,
          flag: "gb",
          name: "United Kingdom",
          code: "+44",
          checked: false,
          value: "United Kingdom",
        },
        {
          id: 12,
          flag: "fr",
          name: "France",
          code: "+33",
          checked: false,
          value: "France",
        },
        {
          id: 13,
          flag: "de",
          name: "Germany",
          code: "+49",
          checked: false,
          value: "Germany",
        },
      ],

      table_data: [
        {
          attribute: "options",
          description:
            "Pass the list of items to be added in the dropdown list",
          type: "String",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "dropdown_data",
          description:
            "Pass the list of items to be added in the dropdown list",
          type: "String",
          accepted_values: "Array of objects",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "title",
          description: "Pass the title",
          type: "String",
          accepted_values: "enter the title",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "placeholder-value",
          description: "Pass the placeholder",
          type: "String",
          accepted_values: "enter the placeholder",
          default: "-",
          mandatory: false,
        },
          {
          attribute: "showEllipsis",
          description: "Length of the rectangle select ",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "pre-value",
          description: "Pre-populate data in single select,rectangle select ",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "inline-search",
          description: "Enable inline search dropdown",
          type: "Boolean",
          accepted_values: "true/false",
          default: false,
          mandatory: false,
        },
        {
          attribute: "clearable",
          description: "Enable clearable dropdown",
          type: "Boolean",
          accepted_values: "true/false",
          default: false,
          mandatory: false,
        },
        {
          attribute: "label",
          description: "The value or name to be displayed for options",
          type: "String",
          accepted_values: "-",
          default: "name",
          mandatory: false,
        },
        {
          attribute: "prop-value",
          description: "Refers to the value to which the options points",
          type: "String",
          accepted_values: "-",
          default: "value",
          mandatory: false,
        },
        {
          attribute: "label-animation",
          description: "Adds animation to the labels whenever the input is focused",
          type: "Boolean",
          accepted_values: "true / false",
          default: false,
          mandatory: false,
        },
         {
          attribute: "position",
          description: "Sets horizontal/vertical view for rectangle select",
          type: "String",
          accepted_values: "horizontal / vertical",
          default: "vertical",
          mandatory: false,
        },
      ],
      editor_theme: "light",
      inline_search_custom: `
                <template>
                  <hlx-select
                  v-model="value"
                  :options="dropdown_data"
                  :placeholder-value="'Language'"
                  :label="'name'"
                  :prop-value="'value'"
                  :label-animation="true"
                  :search-image-tag="true"
                  :sub-title="'subtitle'"       // Give the key for subtitle in the options
                  :tag-value="'labelvalue'"     // Give the key for tagvalue in the options
                  :image-link="'link'"          // Give the key for image in the options
                  :inline-search="true"
                  ></hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          value:''
                          dropdown_data: [
                        {
                          name: "Php",
                          value: "Php",
                          icon: "icon-database-regular",
                          subtitle: "Hypertext Preprocessor",
                          labelvalue: "Basic stack",
                        },
                        {
                          name: "VueJs",
                          value: "VueJs",
                          icon: "icon-database-regular",
                          subtitle: "VueJS",
                          labelvalue: "Basic stack",
                        },
                        {
                          name: "ReactJs",
                          value: "ReactJs",
                          icon: "icon-database-regular",
                          subtitle: "ReactJS",
                          labelvalue: "Basic stack",
                        },
                        {
                          name: "Html",
                          value: "Html",
                          icon: "icon-database-regular",
                          subtitle: "Hypertext Markup Language",
                          labelvalue: "Basic stack",
                        },
                        {
                          name: "Css",
                          value: "Css",
                          icon: "icon-database-regular",
                          subtitle: "Cascading Style Sheets",
                          labelvalue: "Basic stack",
                        },
                        {
                          name: "Scss",
                          value: "Scss",
                          icon: "icon-database-regular",
                          subtitle: "Sassy Cascading Style Sheets",
                          labelvalue: "Basic stack",
                        },
                      ],
                      }
                },
                }
      `,
      inline_search: `
                <template>
                  <hlx-select
                  v-model="value"
                  :options="dropdown_data"
                  :placeholder-value="'Language'"
                  :label="'name'"
                  :prop-value="'value'"
                  :label-animation="true"
                  :inline-search="true"
                  :required="true"
                  :error="show"
                  :custom-error="errorvalue"
                  @custom-error-emit-value="selecttheValueorNot"
                  @selected-value="selectedObjValue"
                  ></hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          value:''
                          show:false,
                          errorvalue:'',
                          dropdown_data: [
                            { name: "Php", checked: false, disabled: false, value: "Php"},
                            { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                            { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                            { name: "Html", checked: false, disabled: false, value: "Html"  },
                            { name: "Css", checked: false, disabled: false, value: "Css"  },
                            { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                            { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                            { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                            { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                            { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                          ],
                      }
                },
                methods:{
                  selecttheValueorNot(data)
                  {
                    if (this.value === "") {
                      this.errorvalue = "please fill the field";
                      this.show = true;
                    } else {
                      this.show = false;
                      this.errorvalue = "";
                    }
                  },
                  selectedObjValue(value)
                  {
                    console.log(value)   // It returns the selected object
                  }
                }
                }
      `,
      inline_search_slot: `
                <template>
                  <hlx-select
                      v-model="value"
                      :slot-options="true"
                      :options="dropdown_data"
                      :placeholder-value="'Language'"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="true"
                      :inline-search="true"
                    >
                    <template #displayValue="value">
                      <span> <span></span> {{ value['displayLabel']['name'] }}</span>
                    </template>
                    <template #options="option">
                    <span>{{ option['optionsData']['name'] }}<span></span></span>
                  </template>
                </hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          value:''
                          dropdown_data: [
                            { name: "Php", checked: false, disabled: false, value: "Php"},
                            { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                            { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                            { name: "Html", checked: false, disabled: false, value: "Html"  },
                            { name: "Css", checked: false, disabled: false, value: "Css"  },
                            { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                            { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                            { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                            { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                            { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                          ],
                      }
                },
                methods:{
                  selecttheValueorNot(data)
                  {
                    if (this.value === "") {
                      this.errorvalue = "please fill the field";
                      this.show = true;
                    } else {
                      this.show = false;
                      this.errorvalue = "";
                    }
                  },
                  selectedObjValue(value)
                  {
                    console.log(value)   // It returns the selected object
                  }
                }
                }
      `,
      basic_country_select: `
           <template>
                  <hlx-select
                  :options="this.new_data"
                  :placeholder-value="'CountryFlag'"
                  :label-animation="true"
                  :icon="true"
                  @custom-change="selectValue"
                ></hlx-select>
                </template>
                <script> 
                export default {
                      data() {
                  return{
                          new_data: [
                                      {
                                id: 1,
                                flag: 'in',
                                name: 'India',
                                code: '+91',
                                checked: false
                              },
                              {
                                id: 2,
                                flag: 'ca',
                                name: 'Canada',
                                code: '+1',
                                checked: false
                              },
                              {
                                id: 3,
                                flag: 'us',
                                name: 'USA',
                                code: '+1',
                                checked: false
                              },
                              {
                                id: 4,
                                flag: 'cn',
                                name: 'China',
                                code: '+86',
                                checked: false
                              },
                              {
                                id: 5,
                                flag: 'jp',
                                name: 'Japan',
                                code: '+81',
                                checked: false
                              },
                              {
                                id: 6,
                                flag: 'kr',
                                name: 'South Korea',
                                code: '+82',
                                checked: false
                              },
                              {
                                id: 7,
                                flag: 'br',
                                name: 'Brazil',
                                code: '+55',
                                checked: false
                              },
                              {
                                id: 8,
                                flag: 'mx',
                                name: 'Mexico',
                                code: '+52',
                                checked: false
                              },
                              {
                                id: 9,
                                flag: 'ru',
                                name: 'Russia',
                                code: '+7',
                                checked: false
                              },
                              {
                                id: 10,
                                flag: 'au',
                                name: 'Australia',
                                code: '+61',
                                checked: false
                              },
                              {
                                id: 11,
                                flag: 'gb',
                                name: 'United Kingdom',
                                code: '+44',
                                checked: false
                              },
                              {
                                id: 12,
                                flag: 'fr',
                                name: 'France',
                                code: '+33',
                                checked: false
                              },
                              {
                                id: 13,
                                flag: 'de',
                                name: 'Germany',
                                code: '+49',
                                checked: false
                              }
                            ],
                  }
                }
                }
      `,
      prevalue_select: `
                <template>
                  <hlx-select
                      v-model:name="data['key']"            // It returns the selected object of the given label value
                      v-model:value="data['value']"         // It returns the selected object of the given prop-value
                      pre-value="preValue"
                      :options="dropdown_data"
                      :placeholder-value="'Language'"
                      :label-animation="true"
                      :label="'name'"                         // The value or name to be displayed for options
                      :prop-value="'value'"                   // Refers to the value to which the options points
                      @custom-change="returnOptions"          // It returns whole options
                      @selected-value="returnSelectedObject"  // It returns the selected object
                    >
                    </hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          preValue:'Html'
                          data:{key:'', value:''}
                          dropdown_data: [
                            { name: "Php", checked: false, disabled: false, value: "Php"},
                            { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                            { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                            { name: "Html", checked: false, disabled: false, value: "Html"  },
                            { name: "Css", checked: false, disabled: false, value: "Css"  },
                            { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                            { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                            { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                            { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                            { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                          ],
                      }
                },
                methods:{
                  returnSelectedObject(selectedobj)
                  {
                    console.log(selectedobj)
                  },
                  returnOptions(options)
                  {
                    console.log(options)
                  }
                }
                }
      `,
      basic_select: `
                <template>
                  <hlx-select
                      v-model:name="data['key']"            // It returns the selected object of the given label value
                      v-model:value="data['value']"         // It returns the selected object of the given prop-value
                      :options="dropdown_data"
                      :placeholder-value="'Language'"
                      :label-animation="true"
                      :label="'name'"                         // Give the key for display value in the options
                      :prop-value="'value'"                   // Give the key for match the value in the options
                      :clearable="true"
                      @custom-change="returnOptions"          // It returns whole options
                      @selected-value="returnSelectedObject"  // It returns the selected object
                    >
                    </hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          data:{key:'', value:''}
                          dropdown_data: [
                            { name: "Php", checked: false, disabled: false, value: "Php"},
                            { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                            { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                            { name: "Html", checked: false, disabled: false, value: "Html"  },
                            { name: "Css", checked: false, disabled: false, value: "Css"  },
                            { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                            { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                            { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                            { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                            { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                          ],
                      }
                },
                methods:{
                  returnSelectedObject(selectedobj)
                  {
                    console.log(selectedobj)
                  },
                  returnOptions(options)
                  {
                    console.log(options)
                  }
                }
                }
      `,
      errorhandling_select: `
                <template>
                  <hlx-select
                    :options="dropdown_data"
                    :placeholder-value="'Language'"
                    :label-animation="true"
                    :required="true"
                    :clearable="true"
                    :error="show"
                    :custom-error="errorvalue"
                    @custom-error-emit-value="selecttheValueorNot"
                  ></hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          show:false,
                          errorvalue:'',
                          dropdown_data: [
                            { name: "Php", checked: false, disabled: false, value: "Php"},
                            { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                            { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                            { name: "Html", checked: false, disabled: false, value: "Html"  },
                            { name: "Css", checked: false, disabled: false, value: "Css"  },
                            { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                            { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                            { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                            { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                            { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                          ],
                      }
                },
                methods:{
                  selecttheValueorNot(data)
                  {
                    if (data === "") {
                      this.errorvalue = "please fill the field";
                      this.show = true;
                    } else {
                      this.show = false;
                      this.errorvalue = "";
                    }
                  },
                }
                }
      `,
      footer_select: `
                <template>
                  <hlx-select
                  :options="dropdown_data"
                  :placeholder-value="'Language'"
                  :label-animation="true"
                  :footer-icon="'icon-plus-circle-regular'"
                  :footer-content="'Add language'"
                  :footer="true"
                  @footer-click="callFooterEvent"
                  ></hlx-select>
                </template>
                <script> 
                export default {
                   data() {
                      return{
                          dropdown_data: [
                            { name: "Php", checked: false, disabled: false, value: "Php"},
                            { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                            { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                            { name: "Html", checked: false, disabled: false, value: "Html"  },
                            { name: "Css", checked: false, disabled: false, value: "Css"  },
                            { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                            { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                            { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                            { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                            { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                          ],
                      }
                },
                methods:{
                  callFooterEvent()
                  {
                      // write the code
                  },
                }
                }
      `,

      basic_multiselect_filter: `
      <template>
            <hlx-select
              :multiselect_options="this.dropdown_data"
              :multi_select="true"
              :placeholder-value="'Language'"
              :label-animation="true"
              @custom-change="selectValue"
            ></hlx-select>
            </template>

            <script> 
                export default
                {
                data() {
                  return{
                        dropdown_data: [
                          { name: "Php", checked: false, disabled: false, value: "Php"},
                          { name: "VueJs", checked: false, disabled: false, value: "VueJs"  },
                          { name: "ReactJs", checked: false, disabled: false, value: "ReactJs"  },
                          { name: "Html", checked: false, disabled: false, value: "Html"  },
                          { name: "Css", checked: false, disabled: false, value: "Css"  },
                          { name: "Scss", checked: false, disabled: false, value: "Scss"  },
                          { name: "Sass", checked: false, disabled: false, value: "Sass"  },
                          { name: "Sql", checked: false, disabled: false, value: "Sql"  },
                          { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" },
                          { name: "Javascript", checked: false, disabled: false, value: "Javascriptq"  },
                        ],
                  }
                }
                }
`,

      basic_multiselect: `
            <template>
            <hlx-select
              :multiselect_options="this.dropdown_data"
              :multi_select="true"
              :placeholder-value="'Language'"
              :searchable="true"
              :label-animation="true"
              v-model:value ="this.check"
              @custom-change="selectValue"
            ></hlx-select>
            </template>

            <script> 
                export default
                {
                  data() {
                  return{
                    dropdown_data: [
                      { name: "Php", checked: false, disabled: false, value: "Php",icon:'icon-database-regular',id:1},
                      { name: "VueJs", checked: false, disabled: false, value: "VueJs" ,icon:'icon-database-regular' ,id:2},
                      { name: "ReactJs", checked: false, disabled: false, value: "ReactJs" ,icon:'icon-database-regular' ,id:3},
                      { name: "Html", checked: false, disabled: false, value: "Html" ,icon:'icon-database-regular' ,id:4},
                      { name: "Css", checked: false, disabled: false, value: "Css" ,icon:'icon-database-regular' ,id:5},
                      { name: "Scss", checked: false, disabled: false, value: "Scss" ,icon:'icon-database-regular' ,id:6},
                      { name: "Sass", checked: false, disabled: false, value: "Sass" ,icon:'icon-database-regular' ,id:7},
                      { name: "Sql", checked: false, disabled: false, value: "Sql" ,icon:'icon-database-regular' ,id:8},
                      { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" ,icon:'icon-database-regular',id:9},
                      { name: "Javascript", checked: false, disabled: false, value: "Javascriptq" ,icon:'icon-database-regular',id:10 },
                    ],
                  }
                }

                }
      `,
      basic_multiselect1: `
            <template>
            <hlx-select
              :multiselect_options="this.dropdown_data"
              :multi_select="true"
              :multiselect_prevalue="this.prevalue_data"
              :placeholder-value="'Language'"
              :label-animation="true"
              v-model:value ="this.check"
              @custom-change="selectValue"
            ></hlx-select>
            </template>

            <script> 
                export default
                {
                  data() {
                  return{
                      prevalue_data:['Html','VueJs'],
                      dropdown_data: [
                        { name: "Php", checked: false, disabled: false, value: "Php",icon:'icon-database-regular',id:1},
                        { name: "VueJs", checked: false, disabled: false, value: "VueJs" ,icon:'icon-database-regular' ,id:2},
                        { name: "ReactJs", checked: false, disabled: false, value: "ReactJs" ,icon:'icon-database-regular' ,id:3},
                        { name: "Html", checked: false, disabled: false, value: "Html" ,icon:'icon-database-regular' ,id:4},
                        { name: "Css", checked: false, disabled: false, value: "Css" ,icon:'icon-database-regular' ,id:5},
                        { name: "Scss", checked: false, disabled: false, value: "Scss" ,icon:'icon-database-regular' ,id:6},
                        { name: "Sass", checked: false, disabled: false, value: "Sass" ,icon:'icon-database-regular' ,id:7},
                        { name: "Sql", checked: false, disabled: false, value: "Sql" ,icon:'icon-database-regular' ,id:8},
                        { name: "MongoDB", checked: false, disabled: false, value: "MongoDB" ,icon:'icon-database-regular',id:9},
                        { name: "Javascript", checked: false, disabled: false, value: "Javascriptq" ,icon:'icon-database-regular',id:10 },
                      ],
                  }
                }

                }
      `,
      title: "Name",
      placeholder: "Sortby",
      search1: "",
      singleselectvalue: [],
      dropdown_data: [
        { name: "Php", value: "Php" },
        { name: "VueJs", value: "VueJs" },
        { name: "ReactJs", value: "ReactJs" },
        { name: "Html", value: "Html" },
        { name: "Css", value: "Css" },
        { name: "Scss", value: "Scss" },
        { name: "Sass", value: "Sass" },
        { name: "Sql", value: "Sql" },
        { name: "MongoDB", value: "MongoDB" },
      ],
      dropdown_data1: [
        {
          name: "Php",
          value: "Php",
          icon: "icon-database-regular",
          subtitle: "Hypertext Preprocessor",
          labelvalue: "Basic stack",
        },
        {
          name: "VueJs",
          value: "VueJs",
          icon: "icon-database-regular",
          subtitle: "VueJS",
          labelvalue: "Basic stack",
        },
        {
          name: "ReactJs",
          value: "ReactJs",
          icon: "icon-database-regular",
          subtitle: "ReactJS",
          labelvalue: "Basic stack",
        },
        {
          name: "Html",
          value: "Html",
          icon: "icon-database-regular",
          subtitle: "Hypertext Markup Language",
          labelvalue: "Basic stack",
        },
        {
          name: "Css",
          value: "Css",
          icon: "icon-database-regular",
          subtitle: "Cascading Style Sheets",
          labelvalue: "Basic stack",
        },
        {
          name: "Scss",
          value: "Scss",
          icon: "icon-database-regular",
          subtitle: "Sassy Cascading Style Sheets",
          labelvalue: "Basic stack",
        },
        // { name: "Sass", value: "Sass", icon: "icon-database-regular",link:'https://raw.githubusercontent.com/mdn/learning-area/master/html/multimedia-and-embedding/images-in-html/dinosaur_small.jpg', subtitle:"Hypertext Markup Language", labelvalue:'Basic stack'  },
        // { name: "Sql", value: "Sql", icon: "icon-database-regular" ,link:'https://raw.githubusercontent.com/mdn/learning-area/master/html/multimedia-and-embedding/images-in-html/dinosaur_small.jpg', subtitle:"Hypertext Markup Language", labelvalue:'Basic stack' },
        // { name: "MongoDB", value: "MongoDB", icon: "icon-database-regular" ,link:'https://raw.githubusercontent.com/mdn/learning-area/master/html/multimedia-and-embedding/images-in-html/dinosaur_small.jpg', subtitle:"Hypertext Markup Language", labelvalue:'Basic stack' },
        // {
        //   name: "Javascript",
        //   value: "Javascriptq",
        //   icon: "icon-database-regular",
        // },
      ],
      pre: "==",
      // dropdown_data1:[]
      // checkval:'',
      // checkval1:''
      errorvalue: "",
      dummy: "",
      errorval: false,
    };
  },
  async mounted() {
    setTimeout(() => {
      this.dummy = this.test.if.level1.groups[0].group[0].conditions[0].op;
      this.data12 = "Language";
      // console.log(this.dummy);
    }, 1000);
  },
  methods: {
    emits(val)
    {
        console.log(val,'eud');
    }, 
    sa(val){
     console.log(val,'keypressssssssss');
    },
    selectedObjValue(val)
    {
          console.log(val,'lol');
    },
    abc(val) {
      console.log(val,'footer');
      this.dropdown_data1 = [
        { name: "Php", value: "Php" },
        { name: "VueJs", value: "VueJs" }]
        this.prevalue = 'Php'
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    selectValue(value1) {
      this.singleselectvalue = value1;
      // console.log('pop',this.check);
      // console.log(this.singleselectvalue, "check");
      // console.log(this.selectvalue);
    },
    checkval(data) {
      data
      if (this.prevalue1 === "") {
        this.errorvalue = "please fill the field";
        this.errorval = true;
      } else {
        this.errorval = false;
        this.errorvalue = "";
      }
    },
    checkval1(data1) {
      if (data1 === "") {
        this.errorvalue = "please fill the field";
        this.errorval = true;
      } else {
        this.errorval = false;
        this.errorvalue = "";
      }
    },
    setvalue() {
      // console.log(data1,'dataa');
      // console.log(this.prevalue,'yeses');
    },
    setvalue1() {
      // console.log(data1,'datdlaa');
    },
    clear() {
      // let clearvalue = value1;
      // console.log(clearvalue);
    },
  },
};
</script>

<style lang="scss" scoped>
.example-body {
  overflow: visible !important;
}
</style>
