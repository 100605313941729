<template>
  <div v-if="direction == 'vertical'" class="speed-dial">
    <div class="toggle circle" @click="func()">
      <i :class="speedDialData.parent_icon" class="ic"></i>
    </div>
    <ul class="dials">
      <li v-for="(i, index) in speedDialData.children_icon" :key="index">
        <div class="circle ico" @click="$emit('iconChosen', i)">
          <i :class="i"></i>
        </div>
      </li>
    </ul>
  </div>
  <div v-if="direction == 'horizontal'" class="speed-dial-horizontal">
    <div class="toggle circle" @click="func()">
      <i :class="speedDialData.parent_icon" class="ic"></i>
    </div>
    <ul class="dials">
      <li v-for="(i, index) in speedDialData.children_icon" :key="index">
        <div class="circle ico" @click="$emit('iconChosen', i)">
          <i :class="i"></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HlxSpeedDial",
  props:{
    speedDialData:{
      type: Object,
      default: ()=>({})
    },
    direction:{
      type: String,
      default: 'vertical'
    }
  },
  // props: ["speedDialData", "direction"],
  emits: ['iconChosen'],
  data() {
    return {};
  },
  methods: {
    // func(){
    //     document.querySelectorAll('.circle').forEach((c)=>{
    //         if(c.parentElement.parentElement.className == 'dials'){
    //             c.style.animation = 'animation: pop-in .3s ease both;'
    //         }
    //     })
    //             console.log(document.querySelector('.toggle').style.background);
    // }
  },
};
</script>
