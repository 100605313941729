<template>
  <h1>Email component</h1>
    <hlx-button class="primary sm" @click="shomailfh">
    <i class="icon-plus-circle-regular"></i>
    Email box
  </hlx-button>

  <email :email-data="emailData" :show-email="shomail" @upload-data="valuemail" @close="shomail=false" :type="'individual'"></email>
 
</template>

<script>
import email from '../components/EmailComponent.vue';
import HlxButton from '../components/ButtonComponent.vue';


export default {
  components: {
    email,
    HlxButton
  },
  data() {
    return {
      shomail:false,  
      emailData: [
        "john.doe@example.com",
        "jane.smith@example.com",
        "michael.johnson@example.com",
        "emily.wilson@example.com",
        "david.brown@example.com",
        "sarah.miller@example.com",
        "james.taylor@example.com",
        "lisa.anderson@example.com",
        "robert.thomas@example.com",
        "mary.jackson@example.com",
        "william.white@example.com",
        "jennifer.harris@example.com",
        "charles.martin@example.com",
        "amanda.thompson@example.com",
        "joseph.garcia@example.com",
        "ashley.martinez@example.com",
        "patrick.robinson@example.com",
        "elizabeth.clark@example.com",
        "daniel.rodriguez@example.com",
        "susan.lewis@example.com",
        "matthew.lee@example.com",
        "laura.walker@example.com",
        "christopher.hall@example.com",
        "karen.allen@example.com",
        "andrew.hill@example.com",
        "angela.young@example.com",
        "joshua.hernandez@example.com",
        "melissa.king@example.com",
        "kevin.wright@example.com",
        "nicole.lopez@example.com",
        "brian.hill@example.com",
        "michelle.scott@example.com",
        "edward.green@example.com",
        "tiffany.adams@example.com",
        "ryan.baker@example.com",
        "stephanie.carter@example.com",
        "timothy.gonzalez@example.com",
        "rebecca.nelson@example.com",
        "jason.mitchell@example.com",
        "christina.ross@example.com",
        "justin.campbell@example.com",
        "heather.roberson@example.com",
        "eric.phillips@example.com",
        "amber.evans@example.com",
        "jacob.cook@example.com",
        "lauren.morris@example.com",
        "brandon.wood@example.com",
        "katherine.james@example.com",
        "jonathan.bennett@example.com",
        "emma.gray@example.com",
        "aaron.kelly@example.com",
        "rachel.howard@example.com",
        "nathan.alexander@example.com",
        "samantha.jenkins@example.com",
        "jeffrey.hughes@example.com",
        "megan.cox@example.com",
        "bryan.james@example.com",
        "alicia.bailey@example.com",
        "josephine.diaz@example.com",
        "craig.richardson@example.com",
        "anna.wood@example.com",
        "gabriel.watson@example.com",
        "katelyn.parker@example.com",
        "scott.wright@example.com",
        "lindsay.bell@example.com",
        "sean.ross@example.com",
        "erica.gonzales@example.com",
        "douglas.howard@example.com",
        "chelsea.peterson@example.com",
        "dylan.ward@example.com",
        "crystal.cooper@example.com",
        "austin.reed@example.com",
        "erin.stewart@example.com",
        "andreas.harris@example.com",
        "jocelyn.butler@example.com",
        "devin.nguyen@example.com",
        "tara.ramirez@example.com",
        "lucas.foster@example.com",
        "summer.gonzalez@example.com",
        "cody.gray@example.com",
        "haley.jimenez@example.com",
        "marcus.russell@example.com",
        "kelsey.price@example.com",
        "julian.bell@example.com",
        "brittany.perez@example.com",
        "mason.collins@example.com",
        "amber.rivera@example.com",
        "jordan.wells@example.com",
        "monica.fisher@example.com",
        "evan.brooks@example.com",
        "katie.harrison@example.com",
        "alexander.hunt@example.com",
        "jillian.wood@example.com",
        "victor.fernandez@example.com",
        "paige.ward@example.com",
        "corey.cruz@example.com",
        "alison.ross@example.com",
        "marco.stewart@example.com",
        "jessica.morales@example.com",
        "miguel.butler@example.com",
      ],
      optionRectangle: ["keerthika kannan", "Option 2", "Option 3"],
      optionradio: ["Volvo keerthika kannan", "BMW", "Ford", "Mazda"],
      rectangle: "Option 2",
      selectedOption: "BMW",
    };
  },
  methods: {
    valuemail(val){
      console.log(val,"email");
    },
    shomailfh(){
this.shomail=true
    },
    handleEnter() {
      if (this.inputValue.trim() !== "") {
        this.enteredValues.push(this.inputValue); // Add entered value to the beginning of the array
        this.inputValue = ""; // Clear the input field
      }
    },
  },
};
</script>

<style>

</style>
