<template>
  <hlx-calendar-filter></hlx-calendar-filter>
</template>

<script>
import HlxCalendarFilter from '../components/CalendarFilterComponent.vue';

export default {
 components: {
    HlxCalendarFilter,
    
 }
}
</script>

<style>

</style>