<template>
  <div>
    <!-- {{ this.averageWidths }} -->
    <div v-if="options.filesArray.length > 0" class="custom-container-box">
      <div class="custom-upload_inside_email1">
        <div id="custom_wrapper" ref="customContainerBox" class="custom-content-wrapper">
          <div v-for="(item, col_index) in options.visibleItems" :key="col_index" class="custom-object-name">
            <hlx-tag v-if="type==='tag'" @tag-close="handleTagClose(item, col_index)">
              {{ item[itemKey] }}
            </hlx-tag>  
            <hlxlabel
              v-if="type==='label'"
              :options="{'background-color':'##A8DEC9','text-color':'#265E4B','border-color':'#A8DEC9'}"
              class="primary">
              {{ item[itemKey] }}
            </hlxlabel>
          </div>

          <div v-if="options.overflowCount > 0" id="custom_overflow-count" class="custom-overflow-count" :style="boxcolor"
          @mouseover="showOverflowItems" @mouseleave="hideOverflowItems">
          
          <hlxlabel v-if="type==='tag'"
          :options="{'background-color':'#54bd95','text-color':'white','border-color':'#54bd95'}"
          class="primary"
          >+{{ options.overflowCount }}</hlxlabel>
          <hlxlabel v-if="type==='label'"
          :options="{'background-color':'#A8DEC9','text-color':'#265E4B','border-color':'#A8DEC9'}"
          class="primary"
          >+{{ options.overflowCount }}</hlxlabel>
        </div>

        </div>
       
      </div>
    </div>

    <div v-if="options.showItems && options.overflowedItems.length" class="custom-overflow-items-tag">
      <hlx-tag v-if="type==='tag'" v-for="item in options.overflowedItems" :key="item[itemKey]">
        {{ item[itemKey] }}
      </hlx-tag>
      <hlxlabel
        v-if="type==='label'"
        :options="{'background-color':'##A8DEC9','text-color':'#265E4B','border-color':'#A8DEC9'}"
        v-for="item in options.overflowedItems" :key="item[itemKey]" class="primary">
        {{ item[itemKey] }}
      </hlxlabel>
    </div>
  </div>
</template>

<script>
import hlxlabel from './LabelComponent.vue'
import HlxTag from './TagComponent.vue'

export default {
  name: "HlxTagcount",
  components: {
    hlxlabel,
    HlxTag
  },
  props: {
    arrayfile: {
      type: Array,
      default: () => ([])
    },
    itemKey: {
      type: String,
      default: 'properties'
    },
    type:{
      type: String,
      required: true,
      default:"tag"
    }
  },
  data() {
    return {
      options: this.initializeOptions(),
      averageWidths: []
    };
  },
  computed:{
    boxcolor(){
      if(this.type==='tag'){
        return {background:'white',color:'white'};
      }
    }
  },
  emits:['tag-closed'],
  watch: {
    arrayfile: {
      handler(newVal) {
        this.options = this.initializeOptions(newVal);
        this.$nextTick(this.checkOverflow);
        // this.$nextTick(() => {
        //   this.calculateDynamicWidths();
        // });
      },
      deep: true
    },
    averageWidths: {
    handler(newWidths, oldWidths) {
      // console.log('Average widths changed1111111111111111111111111:');
      this.checkOverflow();
    },
    deep: true
  }
  },

  mounted() {
    window.addEventListener('resize', this.checkOverflow);
    this.$nextTick(this.checkOverflow);
    this.$nextTick(() => {
      this.calculateDynamicWidths();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkOverflow);
  },
  methods: {
    handleTagClose(item, itemIndex) {
      this.options.visibleItems.splice(itemIndex, 1);
      this.$emit('tag-closed', item);
      // console.log('Tag closed:', item);
      this.checkOverflow();
    },
    initializeOptions(newArrayFile) {
      return {
        filesArray: newArrayFile || this.arrayfile,
        overflowCount: 0,
        showItems: false,
        overflowedItems: [],
        visibleItems: []
      };
    },
    calculateDisplayItems() {
      // this.calculateDynamicWidths();
      const option = this.options;
      if (!option.filesArray.length) {
        option.visibleItems = [];
        option.overflowedItems = [];
        option.overflowCount = 0;
        return;
      }
      let totalWidth = 0;
      const maxAvailableWidth = this.$refs.customContainerBox.clientWidth;
      let visibleItems = [];
      let overflowedItems = [];

      option.filesArray.forEach(item => {
        const itemWidth =  this.averageWidths[0] || 50;
        // console.log('width is added in display',this.averageWidths[0] );
        if (totalWidth + itemWidth <= maxAvailableWidth) {
          visibleItems.push(item);
          totalWidth += itemWidth + 40;
        } else {
          overflowedItems.push(item);
        }
      });

      option.visibleItems = visibleItems;
      option.overflowedItems = overflowedItems;
      option.overflowCount = overflowedItems.length;
    },
    showOverflowItems() {
      if (this.options.overflowCount > 0) {
        this.options.showItems = true;
      }
    },
    hideOverflowItems() {
      if (this.options.overflowCount > 0) {
        this.options.showItems = false;
      }
    },
    checkOverflow() {
      const option = this.options;
      const container = this.$refs.customContainerBox;
      if (container) {
        this.calculateDisplayItems();
      } else {
        option.visibleItems = [];
        option.overflowedItems = [];
        option.overflowCount = 0;
      }
    },
    estimateWidth(item) {
      // console.log("estimated width is detected",this.averageWidths[0]);
      return this.averageWidths[0] || 44;
    },
    calculateDynamicWidths() {
      this.$nextTick(() => {
        const container = this.$refs.customContainerBox;
        // console.log("container detected",container);
        if (container) {
          const itemElements = container.querySelectorAll('.custom-object-name');
          // console.log("items detected",itemElements);
          if (itemElements.length === 0) {
            // console.log('No elements found, checking again next tick');
          } else {
            const widths = Array.from(itemElements).map(el => el.offsetWidth);
            const averageWidth = widths.reduce((acc, width) => acc + width, 0) / widths.length;
            // console.log(`Average width for container: ${averageWidth}`);
            this.averageWidths[0] = averageWidth;
            // console.log("papapapapap",this.averageWidths[0]);
            return averageWidth;
          }
        } else {
          // console.log('Container not found');
        }
      });
    }
  }
};
</script>
