<template>
  <section id="hlx-step-controler-005" class="stepper-control-container">
    <div
      class="stepper prev-stepper"
      :class="{ limited: currentCounter <= 1 }"
      @click="prevStep()"
    >
      <i class="icon-minus-filled"></i>
    </div>
    <div class="stepper counter">
      <span class="current-page">{{ currentCounter }}</span>
    </div>
    <div
      class="stepper next-stepper"
      :class="{ limited: currentCounter >= limit - 1 }"
      @click="nextStep()"
    >
      <i class="icon-plus-filled"></i>
    </div>
  </section>
</template>

<script>
export default {
  name: "HlxStepper",
  props: {
    step: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  emits: ["current-step"],
  data() {
    return {
      currentCounter: 1,
    };
  },
  methods: {
    nextStep() {
      if (this.currentCounter + this.step < this.limit) {
        this.currentCounter += this.step;
        this.$emit("current-step", this.currentCounter);
      }
    },
    prevStep() {
      if (this.currentCounter - this.step > 0) {
        this.currentCounter -= this.step;
        this.$emit("current-step", this.currentCounter);
      }
    },
  },
};
</script>
