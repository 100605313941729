<template>
    <div
     v-if="disabled == false"
    :id="'hlx-cascade-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-cascade-select-container change-color': 'hlx-cascade-select-container'"
    :style="error == true ? 'border-color:#ff5050 !important' : ''"
    @click.stop="optionContainer"
    @mouseover="clearIcon"
    @mouseout="arrowIcon"
    >
    <input v-if="selectoptioncontainer==true" :id="'hlx-cascade-input'+unique" v-model="selectedvalue" class="hlx-cascade-input" @input="searchable">
    <div v-if="selectedArray.length == 0 && selectoptioncontainer==false" class="hlx-cascade-placeholder">{{ placeholder }}<span v-if="required==true" class="asterik">{{ "*" }}</span></div>
    <div v-if="selectedArray.length != 0 && selectoptioncontainer==false" class="hlx-cascade-displayvalue">{{ displayValue }}</div>
    <div v-if="labelAnimation == true && selectedArray.length != 0" class="hlx-cascade-label-animation">{{ placeholder }}<span v-if="required==true" class="asterik">{{ "*" }}</span></div>
    <div v-if="labelAnimation == true && selectedArray.length == 0 && selectoptioncontainer == true" class="hlx-cascade-label-animation">{{ placeholder }}<span v-if="required==true" class="asterik">{{ "*" }}</span></div>
    <p v-if="error == true" class="customerror">{{ customError }}</p>
    <i :id="'arrowid' + unique"
    :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"
    ></i>
    <i
        :id="'crossid' + unique"
        class="icon-times-circle-regular"
        @click="removevalue"
      ></i>
    <hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="closeContainer">
     <template #overlay>
        <div class="hlx-cascade-fixed-container" style="display: flex;flex-direction: column;">
       <div
       :id="'hlx-cascade-select-whole-option-container'+unique" class="hlx-cascade-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%', cascadeOptionsView == false ? 'overflow-y:auto' : ''
      ]">
      <div v-if="cascadeOptionsView == true" style="display:flex;flex-direction: column;">
          <div style="display:flex">
            <span  v-for="(i,index) in Object.keys(showOptionsData)" :key="index" :style="[(index+1 == currentPageDot || index == currentPageDot) ? 'width:170px': 'width:0px',(index+1 == currentPageDot || index == currentPageDot) ? 'display:block' : 'display:none']" class="hlx-cascade-parent-container" >
        <span v-if="(index+1 == currentPageDot || index == currentPageDot)">
        <span v-for="(j,col_index) in showOptionsData[index]" :key="col_index" class="hlx-cascade-each-item"  :class="j.checked == true ? 'cascade-hover-color' : '' "  @click="hoverElements(i,j,index),cascadeValue(i,j,index)">
           <span class="hlx-cascade-each-item-icon" style="display:flex;align-items: center;">
            <p style="font-size:14px;white-space: normal;overflow-wrap: break-word;width: 138px;padding-left: 21px;">{{ j[label] }}</p>
             <i v-if="j[children] == undefined || j[children].length == 0"></i>
             <i v-else style="padding-top:3px" class="icon-angle-right-regular"></i>
            </span>
        </span>
        </span>
    </span>  
  </div>
           <!-- <div style="display: flex;justify-content: center;">
             <hlx-dotted-pagination v-model="currentPageDot" :dots="numberOfDots" @current-page="currentPage" @current-page-hover="currentPageHover" />
           </div> -->
      </div>
      <div v-else-if="searchOptions.length == 0 || options.length == 0" style="display:flex;justify-content: center;align-items: center;height:100%">No data</div>
      <div v-else class="cascading-search-option-container" >
        <span v-for="(i,index) in searchOptions" :key="index" class="cascading-search-options" :class="[i.checked == true ? 'select-value' : '']"  @click.stop="selectedPath(i)">
          <content-editable  :prevalue="highlightedText(i.originalPath)"></content-editable>
        </span>
      </div>
       </div>
       <div v-if="numberOfDots>1" class="hlx-cascade-pagination-section" style="display: flex;justify-content: center;">
             <hlx-dotted-pagination v-model="currentPageDot" :dots="numberOfDots" @current-page="currentPage" @current-page-hover="currentPageHover" />
           </div>
       </div>
   </template>
</hlx-overlay>
    </div>
    <div
     v-if="disabled == true"
    :id="'hlx-cascade-select-container'+unique"
    :class="selectoptioncontainer == true ? 'hlx-cascade-select-container change-color': 'hlx-cascade-select-container'"
    :style="error == true ? 'border-color:#ff5050 !important' : ''"
    >
    <input v-if="selectoptioncontainer==true" :id="'hlx-cascade-input'+unique" v-model="selectedvalue" class="hlx-cascade-input" @input="searchable">
    <div v-if="selectedCount == 0 && selectoptioncontainer==false" class="hlx-cascade-placeholder">{{ placeholder }}<span v-if="required==true" class="asterik">{{ "*" }}</span></div>
    <div v-if="selectedCount > 0 && selectoptioncontainer==false" class="hlx-cascade-displayvalue">{{ displayValue }}</div>
    <div v-if="labelAnimation == true && selectedCount > 0" class="hlx-cascade-label-animation">{{ placeholder }}<span v-if="required==true" class="asterik">{{ "*" }}</span></div>
    <div v-if="labelAnimation == true && selectedCount == 0 && selectoptioncontainer == true" class="hlx-cascade-label-animation">{{ placeholder }}<span v-if="required==true" class="asterik">{{ "*" }}</span></div>
    <p v-if="error == true" class="customerror">{{ customError }}</p>
    <i
    :class="selectoptioncontainer == true ? 'icon-angle-up-regular':'icon-angle-down-regular'"
    ></i>
    <hlx-overlay v-show="selectoptioncontainer === true" :show ="selectoptioncontainer" :top ="containertop" :left="containerleft" :width="containerwidth" :height="containerheight" :conditionvalue="250" :conditiondown="45" :conditionup="10" @close-overlay="closeContainer">
     <template #overlay>
        <div class="hlx-cascade-fixed-container" style="display: flex;flex-direction: column;">
       <div
       :id="'hlx-cascade-select-whole-option-container'+unique" class="hlx-cascade-select-whole-option-container"       :style="[
        widthContainer > 0 ? 'width:' + widthContainer + 'px' : 'width:100%', cascadeOptionsView == false ? 'overflow-y:auto' : ''
      ]">
      <div v-if="cascadeOptionsView == true" style="display:flex;flex-direction: column;">
          <div style="display:flex">
            <span  v-for="(i,index) in Object.keys(showOptionsData)" :key="index" :style="[(index+1 == currentPageDot || index == currentPageDot) ? 'width:170px': 'width:0px',(index+1 == currentPageDot || index == currentPageDot) ? 'display:block' : 'display:none']" class="hlx-cascade-parent-container" >
        <span v-if="(index+1 == currentPageDot || index == currentPageDot)">
        <span v-for="(j,col_index) in showOptionsData[index]" :key="col_index" class="hlx-cascade-each-item"  :class="j.checked == true ? 'cascade-hover-color' : '' "  @click="hoverElements(i,j,index),cascadeValue(i,j,index)">
           <span class="hlx-cascade-each-item-icon" style="display:flex;align-items: center;"><i style="width:21px;" :class="j.selected == true ? 'icon-check-filled':''"></i> <p style="font-size:14px;white-space: normal;overflow-wrap: break-word;width: 138px;">{{ j[label] }}</p> <i v-if="j[children]" style="padding-top:3px" class="icon-angle-right-regular"></i></span>
        </span>
        </span>
    </span>  
  </div>
           <!-- <div style="display: flex;justify-content: center;">
             <hlx-dotted-pagination v-model="currentPageDot" :dots="numberOfDots" @current-page="currentPage" @current-page-hover="currentPageHover" />
           </div> -->
      </div>
      <div v-else-if="searchOptions.length == 0 || options.length == 0" style="display:flex;justify-content: center;align-items: center;height:100%">No data</div>
      <div v-else class="cascading-search-option-container" >
        <span v-for="(i,index) in searchOptions" :key="index" class="cascading-search-options" :class="[i.checked == true ? 'select-value' : '']"  @click.stop="selectedPath(i)">
          <content-editable  :prevalue="highlightedText(i.originalPath)"></content-editable>
        </span>
      </div>
       </div>
       <div v-if="numberOfDots>1" class="hlx-cascade-pagination-section" style="display: flex;justify-content: center;">
             <hlx-dotted-pagination v-model="currentPageDot" :dots="numberOfDots" @current-page="currentPage" @current-page-hover="currentPageHover" />
           </div>
       </div>
   </template>
</hlx-overlay>
    </div>
</template>

<script>
    import hlxOverlay from '../components/OverlayComponent.vue'
    import ContentEditable from './ContentEditableComponent.vue'
    import  HlxDottedPagination  from './DottedPagination.vue';

export default{
    name: "HlxCascader",
    components:{
        hlxOverlay,
        HlxDottedPagination,ContentEditable
    },
    props:{
     placeholderValue: {
          type: String,
          default: "Cascader",
      },
      label:{
          type:String,
          default:'name',
        },
        propValue:{
          type:String,
          default:'value',
        },
        options:{
            type:Array,
            default:()=>{
                return []
            }
        },
        preValue:{
            type:Array,
            default:()=>{
                return []
            }
        },
        labelAnimation:{
          type:Boolean,
          default:true,
        },
        required:{
          type:Boolean,
          default:false, 
        },
        disabled:{
            type:Boolean,
            default:false
        },
        children:{
            type:String,
          default:'children',
        }
    },
    emits:['selected-array','selected-value'],
    data()
    {
        return{
            open:0,
            duplicateoptions:[],
            selectedCount:0,
            duplicateCount:0,
            selectoptioncontainer:false,
            containerheight:0,
            containerleft:0,
            containertop:0,
            containerwidth:0,
            cascadeOptionsView:true,
            unique: Math.floor(Math.random() * 1000 + 1),
            selectedvalue:'',
            placeholdervalue:'',
            showOptionsData:{},
            dupicateOptions:[],
            selectedArray:[],
            numberOfDots:1,
            currentPageDot:0,
            duplicateData:[],
            displayValue:'',
            previousArray:[],
            preval:[],
            count:0
        }
    },
    watch:{
        selectoptioncontainer:{
            handler(val)
            {
               console.log(val,'clode');
            },
            immediate:true,
            deep:true,
        },
       options:{
        handler(val)
        {  
            if(val && val.length != 0)
            {
                console.log(val,'lolklk');
                this.duplicateoptions = JSON.parse(JSON.stringify(val))
            }
        },
            immediate:true,
            deep:true
       },
       duplicateoptions:{
        handler(val)
        {
            if(val && val.length != 0)
            {
                console.log(val,'lol');
                let data = JSON.parse(JSON.stringify(val))
                this.addCheckedProperty(data);
                this.showOptionsData[0] = data
                if(this.preval.length != 0)
                {
                    this.preval.forEach((e,index)=>{
                        this.preValueFunc(this.showOptionsData,index,e)
                    })
                    this.duplicateCount = this.selectedCount

                }
                // console.log(this.showOptionsData,'data');
            }
        },
            immediate:true,
            deep:true
       },
       placeholderValue:{
        handler(val)
        {
            this.placeholder = val
        },
        immediate:true,
        deep:true
       },
       preValue:{
        handler(val)
        {
            this.preval = val
        },
        immediate:true,
        deep:true
       },
       preval:{
        handler(val)
        {
            if(val&&val.length != 0)
            {
                console.log(this.showOptionsData,'000');
            if(Object.keys(this.showOptionsData).length !=0)
            {
                val.forEach((e,index)=>{
                    this.preValueFunc(this.showOptionsData,index,e)
                })
                this.duplicateCount = this.selectedCount
            }
        }
        },
        immediate:true,
        deep:true
       },
       showOptionsData:{
        handler(val)
        {
           console.log(val,'show');
        },
        immediate:true,
        deep:true
       },
       displayValue:{
        handler(val)
        {
           val
        },
        immediate:true,
        deep:true
       },
       selectedArray:{
        handler(val)
        {
            val
        },
        immediate:true,
        deep:true
       }
    },
    methods:{
        // container open or close
        optionContainer(event)
        {
                console.log(this.selectedCount,this.duplicateCount,'count');
                console.log(event.target.className);
                if(event.target.className != 'icon-times-circle-regular')
                {
                    if(this.open == 0)
                    {
                        this.selectoptioncontainer = true
                        this.open = 1
                        this.selectedvalue = ''
                        this.$nextTick(()=>{
                      if(document.querySelector('#hlx-cascade-select-whole-option-container'+this.unique))
                        this.containerheight = document.querySelector('#hlx-cascade-select-whole-option-container'+this.unique).getBoundingClientRect().height
                        this.containerleft = document.querySelector('#hlx-cascade-select-container'+this.unique).getBoundingClientRect().left
                        this.containertop = document.querySelector('#hlx-cascade-select-container'+this.unique).getBoundingClientRect().top
                        this.containerwidth = document.querySelector('#hlx-cascade-select-container'+this.unique).getBoundingClientRect().width
                        document.getElementById('hlx-cascade-input'+this.unique).focus()
                    })
                    }else{
                     this.open = 0
                     this.selectoptioncontainer = false
                     this.duplicateCount = this.selectedCount
                     this.cascadeOptionsView = true
     
                    }
                }
                else{
                    console.log('lol');
                    this.preval=[]
                    this.displayValue = ''
                    this.selectedArray = []
                    this.duplicateoptions = JSON.parse(JSON.stringify(this.options))
                    this.showOptionsData = {}
                    this.numberOfDots= 1
                    this.currentPageDot= 0

                }

        //    }
        //    else{
            // this.selectoptioncontainer = false
            // this.cascadeOptionsView = true
        //    }
        },

        closeContainer(event)
        {
            // console.log('aala');
            this.selectedCount += 1
            if(document.querySelector('#hlx-cascade-select-container'+this.unique).contains(event.target) == false)
            this.open = 0
        if(event.target.className == 'outer-overlay'){
               console.log('check',event.target.className);
            this.open = 1
           }
          this.selectoptioncontainer = false
        },

        // Add checked and selected property
        addCheckedProperty(data) {
            if (Array.isArray(data)) {
                // If the data is an array, iterate through each item
                data.forEach(item => this.addCheckedProperty(item));
            } else if (typeof data === 'object' && data !== null) {
                // If the data is an object, add the 'checked' property if it's undefined
                if (data.checked === undefined) {
                data.checked = false;
                data.selected = false
                }else{
                    data.checked = false;
                    data.selected = false
                }

                // Recursively call the function for each child
                if (Array.isArray(data[this.children])) {
                this.addCheckedProperty(data[this.children]);
                }
            }
        },

        // Hover Functionality
        hoverElements(key,obj,index){
            this.open = 0
            if(obj[this.children] != undefined && obj[this.children].length != 0)
            {
                Object.keys(this.showOptionsData).forEach((e,col_index)=>{
                   if(col_index>=index)
                   {
                    if(this.showOptionsData[col_index])
                    {
                        this.showOptionsData[col_index].forEach((ele)=>{
                          ele.checked = false
                        })
                    }
                   }
                })

                Object.keys(this.showOptionsData).forEach((e)=>{
                    if(parseInt(e) > index)
                    {  
                        if(this.selectedArray.length != 0)
                        {
                            this.selectedArray.forEach((ele,index)=>{
                                // console.log(index,parseInt(e)>=1,parseInt,'lol');
                                ele
                                index
                                this.selectedArray.splice(parseInt(e),1)
                            })
                        }
                        delete this.showOptionsData[parseInt(e)]
                    }  
                })
                this.showOptionsData[index].find((data)=>{
                    if(data[this.propValue] == obj[this.propValue])
                    {
                        data.checked = true
                        this.selectedArray[index] = data
                        this.showOptionsData[index+1] = data[this.children]
                    }
                })
            }
            this.numberOfDots = Math.ceil(Object.keys(this.showOptionsData).length/2)
            this.currentPageDot = this.numberOfDots
            const result = this.selectedArray.map(obj => obj[this.label]).join(' > ')
              this.displayValue = result
            console.log(this.selectedArray,'hoverarray');
        },

        //click the last Element functionality
        cascadeValue(key,obj,index)
        {
            this.open = 0
            if(obj[this.children] == undefined || obj[this.children].length == 0)
            {
                // console.log(key,obj,index);
                this.showOptionsData[index].find((data)=>{
                if(data[this.propValue] == obj[this.propValue])
                {
                    // data.selected = true
                    this.selectedArray[index] = data
                }
              })
            let options = JSON.parse(JSON.stringify(this.options))
            this.addCheckedProperty(options);
            this.showOptionsData =JSON.parse(JSON.stringify( {}))
            this.showOptionsData[0] =JSON.parse(JSON.stringify( options ))
              let prevalue = []
              this.selectedArray.map((item)=>{
                prevalue.push(item[this.propValue])
              })
              if(Object.keys(this.showOptionsData).length !=0)
            {
                prevalue.forEach((e,index)=>{
                    this.preValueFunc(this.showOptionsData,index,e)
                })
            }
            Object.keys(this.showOptionsData).forEach((e,index)=>{
                  if(index > this.selectedArray.length-1)
                  {
                      delete this.showOptionsData[index]
                  }
                })
              const result = this.selectedArray.map(obj => obj[this.label]).join(' > ')
              this.displayValue = result
              this.numberOfDots = Math.ceil(Object.keys(this.showOptionsData).length/2)
              this.currentPageDot = this.numberOfDots
              this.selectedCount += 1
              this.selectoptioncontainer = false
              console.log(this.showOptionsData,'daata');
              let value = []
              this.selectedArray.forEach((e)=>{
                   value.push(e[this.propValue])
              })
              this.$emit('selected-value',value)
              this.$emit('selected-array',this.selectedArray)
              this.open = 1

            }
        },


        // Current page
        currentPage(val)
        {
          this.currentPageDot = val
          this.open = 0
          console.log(this.currentPageDot);
        },

        currentPageHover()
        {
            this.open = 0
        },


        //search
        searchable()
        {   if(this.selectedvalue != '')
        {
            this.cascadeOptionsView = false
            this.duplicateData = JSON.parse(JSON.stringify(this.options))
            const searchData = this.searchNestedData(this.duplicateData,this.selectedvalue)
            this.searchOptions = this.getHierarchyPathWithLastChildren(searchData)
          //  console.log(searchOptions);
        }else{
            this.cascadeOptionsView = true
        }
        },

        searchNestedData(data, query) {
        const filteredData = data.filter(item => {
            if (item[this.label].toLowerCase().includes(query.toLowerCase()) || item[this.propValue].toLowerCase().includes(query.toLowerCase())) {
            return true;
            }
            if (item[this.children]) {
            item.originalChildren = item[[this.children]]
            item[[this.children]] = this.searchNestedData(item[this.children], query);
            return item[this.children].length > 0;
            }
            return false;
        });

        return filteredData.length > 0 ? filteredData : data.filter(item => item[this.label].toLowerCase().includes(query.toLowerCase()) || item[this.propValue].toLowerCase().includes(query.toLowerCase()));
        },

        selectedPath(val)
        {
          console.log(this.searchOptions,'search');
           val.checked = true
           this.selectedvalue = val.originalPath
           this.displayValue = val.originalPath
           console.log(this.displayValue,'original');
            let data = val.path.split(" / ");
            let length = data.length-1
            Object.keys(this.showOptionsData).forEach((e,index)=>{
                if(index>length)
                {
                    delete this.showOptionsData[index]
                }
            })
            console.log(this.showOptionsData,'check',data);
            this.duplicateData = JSON.parse(JSON.stringify(this.options))
            this.count = 0
            let cascadeView = this.markItemsSelected(this.duplicateData,data)
            console.log(cascadeView,'view');
            this.cascadeOptionsView = true
            this.searchOptions = []
            this.numberOfDots = Math.ceil(Object.keys(this.showOptionsData).length/2)
            this.currentPageDot = this.numberOfDots
            this.selectedCount += 1
            this.selectoptioncontainer = false
            this.open = 0
            // this.open = 1
            let value = []
              this.selectedArray.forEach((e)=>{
                   value.push(e[this.propValue])
              })
              this.$emit('selected-value',value)
            this.$emit('selected-array',this.selectedArray)
            console.log(cascadeView,'data',this.menuObj);
        },


        getHierarchyPathWithLastChildren(data, parentPath = '', level = 0, parentValues = [], parentvalue='') {
                        let result = [];
                        
                        if (data && data.length > 0) {
                        data.forEach(item => {
                            // console.log(item,parentValues);
                            const currentvalue = `${parentvalue}${item[this.label]} > `;
                            const currentPath = `${parentPath}${item[this.label]} / `;
                            const currentData = { label: item[this.label], value: item[this.propValue], checked:false, path: currentPath.replace(/ \/ $/, ''), duplicatePath: currentPath.replace(/ \/ $/, ''), originalPath:currentvalue.replace(/>(?=[^>]*$)/, ''),  options: [...parentValues, { label: item[this.label], value: item[this.propValue], path: currentPath.replace(/ \/ $/, ''), [this.children]:item.originalChildren != undefined ? item.originalChildren : [item]}], currentChildren:item[this.children] != undefined ? item[this.children] : [item] };
                            if (item[this.children] && item[this.children].length > 0) {
                            const updatedParentValues = [...parentValues, currentData];
                            result = result.concat(this.getHierarchyPathWithLastChildren(item[this.children], currentPath, level + 1, updatedParentValues,currentvalue));
                            } else {
                            result.push(currentData);
                            }
                        });
                }

                return result;
            },

        markItemsSelected(data, labels) {
            console.log(data,labels);
            data.forEach(item => {
                console.log(this.count,'val');
                if (labels[this.count] == item[this.label]) {
                    this.selectedArray[this.count] = item
                    item.checked = true;
                    item.selected = true;
                    this.showOptionsData[this.count] = data
                    this.count += 1
                 console.log(this.showOptionsData,'showoptions');

                    if (item[this.children]) {
                        this.markItemsSelected(item[this.children], labels);
                    }
                } else {
                    if (item[this.children]) {
                        this.markItemsSelected(item[this.children], labels);
                    }
                }
            });
        },


        // Highlighted Text
        highlightedText(text) {
        if (!this.selectedvalue) return text;
        const regex = new RegExp(this.selectedvalue, 'gi');
        return text.replace(regex, match => `<span style="color: rgb(84 189 149);">${match}</span>`);
        },



        // Prevalue Function
        preValueFunc(data,index,checkvalue)
        {
            let count = 0 
            // console.log(data[index],'lololo');
            if(data[index])
            {
            data[index].find((value)=>{
                    //  console.log(data[index],value, value[this.propValue],checkvalue);
                    if(value[this.propValue] == checkvalue)
                    {
                        value.checked = true
                        value.selected = true
                        
                        if(value[this.children] && value[this.children].length != 0)
                        {
                            console.log(this.showOptionsData,index+1,value[this.children].length);
                            this.showOptionsData[index+1] = value[this.children]
                        }
                        this.selectedArray[index] = value
                         const result = this.selectedArray.map(obj => obj[this.label]).join(' > ')
                         this.displayValue = result
                         this.numberOfDots = Math.ceil(Object.keys(this.showOptionsData).length/2)
                         this.currentPageDot = this.numberOfDots
                         this.selectedCount += 1
                         count += 1
                        //  console.log(this.displayValue,'paoapapapapa');
                    }
                 })

             if(count == 0)
             {
                this.preval = []
             }
            }
        },


        // clearable functions

        clearIcon() {
      if (this.selectedArray.length != 0 && this.open == 0) {
        document.getElementById("arrowid" + this.unique).style.display = "none";
        document.getElementById("crossid" + this.unique).style.display =
          "flex";
      }
    },

    arrowIcon() {
      // console.log(event);
      if (
        document.getElementById("crossid" + this.unique).style.display ==
        "flex" || this.open == 1
      ) {
        document.getElementById("arrowid" + this.unique).style.display =
          "flex";
        document.getElementById("crossid" + this.unique).style.display = "none";
      }
    },

    removeValue(event)
    {
        console.log(event.target.className);
        if(event.target.className == 'icon-times-circle-regular')
        {
            
            console.log('evemt',event.target,this.preval,this.selectedArray);
            let value = []
            let display = ''
            this.preval = JSON.parse(JSON.stringify(value))
            this.displayValue = JSON.parse(JSON.stringify(display))
            this.selectedArray = JSON.parse(JSON.stringify(value))
            this.duplicateoptions = JSON.parse(JSON.stringify(this.options))
            
          }
    },

    }

}

</script>