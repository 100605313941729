<template>
  <div
    v-for="(item, index) in copyOfJsonData"
    :key="index"
    :class="show === true ? 'parent' : ''"
  >
    <!------------------------------------------------ Read-only mode ----------------------------------------------->

    <div
      v-if="
        type === 'read-only' &&
        typeof item === 'object' &&
        item !== null &&
        typeof item !== 'undefined'
      "
      id="obj"
      class="db-row"
    >
      <div
        v-if="
          typeof item === 'object' &&
          item !== null &&
          typeof item !== 'undefined'
        "
        class="array-index-parent"
      >
        <span v-if="typeof index === 'number'" class="array-index">{{
          index + ": "
        }}</span>
        <span v-if="typeof index === 'string'" class="array-string">
          <span v-if="checkType(index) === ''" class="ov-icon-type"
            >{{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
          <span v-if="checkType(index) === 'number'" class="ov-icon-type">
            <span class="icon-holder num">#</span>
            {{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
          <span v-if="checkType(index) === 'string'" class="ov-icon-type">
            <span class="icon-holder str">ABC</span>
            {{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
          <span v-if="checkType(index) === 'array'" class="ov-icon-type">
            <span class="icon-holder arr">[]</span>
            {{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
          <span v-if="checkType(index) === 'object'" class="ov-icon-type">
            <span class="icon-holder obj">{}</span>
            {{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
          <span v-if="checkType(index) === 'boolean'" class="ov-icon-type"
            ><span class="icon-holder bool"
              ><i class="icon-toggle-off-alt-regular"></i
            ></span>
            {{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
          <span v-if="checkType(index) === 'date'" class="ov-icon-type"
            ><span class="icon-holder date"
              ><i class="icon-calendar-clock-regular"></i
            ></span>
            {{ typeRemoved(index) + " " }}
            <span v-if="hasAsterisk(index)" class="asterisk">{{ " *" }}</span>
          </span>
        </span>
      </div>
      <div
        v-if="
          typeof item === 'object' &&
          item !== null &&
          typeof item !== 'undefined'
        "
        class="div-with-arrow"
      >
        <i
          v-if="Array.isArray(copyOfJsonData) && Object.keys(item).length !== 0"
          :class="
            showArray[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <i
          v-if="
            Array.isArray(copyOfJsonData) !== true &&
            Object.keys(item).length !== 0
          "
          :class="
            showChild[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <hlx-object-viewer
          :json-data="item"
          :show="
            Array.isArray(copyOfJsonData) ? showArray[index] : showChild[index]
          "
          :type="'read-only'"
          :ref-data="copyOfRefData"
          @updated-json="updateEmit"
        ></hlx-object-viewer>
      </div>
    </div>
    <div
      v-if="
        type === 'read-only' &&
        (typeof item === 'boolean' ||
          typeof item === 'string' ||
          typeof item === 'undefined' ||
          item === null ||
          typeof item === 'number')
      "
      id="other"
      class="db-row"
    >
      <!-- Keys of objects with type other than object -->
      <div
        v-if="
          typeof item === 'boolean' ||
          typeof item === 'string' ||
          typeof item === 'number' ||
          typeof item === 'undefined' ||
          item === null
        "
        class="colon-key"
      >
        {{ index }}
      </div>
      <!-- Values of objects with type other than object -->
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]  &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        class="colon-value"
      >
        {{ formatDate(item) }}
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]  &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        class="colon-value"
      >
        <!-- <span v-for="(ref, key) in copyOfRefData[index]" :key="key">
          <span>{{ formatDate(ref["value"]) }}</span
          ><span
            v-if="key < copyOfRefData[index].length - 1"
            style="margin-right: 5px"
            >{{ `,` }}</span
          >
        </span> -->
        <span
          v-for="(dt, key) in getArrayValues(copyOfRefData[index])"
          :key="key"
        >
          <span v-if="key <= 1">{{ formatDate(dt) }}</span>
          <span v-if="key <= 0" style="margin-right: 5px">{{ `,` }}</span>
          <span
            v-if="key == 1"
            style="margin-right: 5px"
            :data-tooltip="getCommaSeperatedDates(copyOfRefData[index])"
            data-tooltip-location="bottom"
            >{{ `...` }}</span
          >
        </span>
        <!-- {{ formatDate(item) + 'hi' }} -->
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]  &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        class="colon-value"
      >
        <span
          v-for="(str, key) in getArrayValues(copyOfRefData[index])"
          :key="key"
        >
          <span v-if="key <= 1">{{ str }}</span>
          <span v-if="key <= 0" style="margin-right: 5px">{{ `,` }}</span>
          <span
            v-if="key == 1"
            style="margin-right: 5px"
            :data-tooltip="getCommaSeperatedValues(copyOfRefData[index])"
            data-tooltip-location="bottom"
            >{{ `...` }}</span
          >
        </span>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]  &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        class="colon-value"
      >
        {{ item }}
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]  &&
          item.charAt(0) === '{' &&
          item.charAt(0) !== '['
        "
        class="colon-value"
      >
      <!-- {{item}} -->
        <span
          v-for="(str, key) in getArrayValues(copyOfRefData[index])"
          :key="key"
        >
          <span v-if="key <= 1">{{ str }}</span>
          <span v-if="key <= 0" style="margin-right: 5px">{{ `,` }}</span>
          <span
            v-if="key == 1"
            style="margin-right: 5px"
            :data-tooltip="getCommaSeperatedValues(copyOfRefData[index], true)"
            data-tooltip-location="right"
            >{{ `...` }}</span
          >
        </span>
        <!-- <div v-else>
          {{ item }}
        </div> -->
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]  &&
          item.charAt(0) !== '{' &&
          item.charAt(0) === '['
        "
        class="colon-value"
      >
      <!-- {{item}} -->
        <span
          v-for="(str, key) in getArrayValues(copyOfRefData[index])"
          :key="key"
        >
          <span v-if="key <= 1">{{ str }}</span>
          <span v-if="key <= 0" style="margin-right: 5px">{{ `,` }}</span>
          <span
            v-if="key == 1"
            style="margin-right: 5px"
            :data-tooltip="getCommaSeperatedValues(copyOfRefData[index], true)"
            data-tooltip-location="right"
            >{{ `...` }}</span
          >
        </span>
        <!-- <div v-else>
          {{ item }}
        </div> -->
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]  &&
          item.charAt(0) === '{'
        "
        class="colon-value"
      >
      {{item}}
        <!-- <span
          v-for="(str, key) in getArrayValues(copyOfRefData[index])"
          :key="key"
        >
          <span v-if="key <= 1">{{ str }}</span>
          <span v-if="key <= 0" style="margin-right: 5px">{{ `,` }}</span>
          <span
            v-if="key == 1"
            style="margin-right: 5px"
            :data-tooltip="getCommaSeperatedValues(copyOfRefData[index], true)"
            data-tooltip-location="bottom"
            >{{ `...` }}</span
          >
        </span> -->
        <!-- <div v-else>
          {{ item }}
        </div> -->
      </div>
      <div
        v-if="typeof item === 'number' && copyOfRefData[index]"
        class="colon-value"
      >
        <span
          v-for="(nb, key) in getArrayValues(copyOfRefData[index])"
          :key="key"
        >
          <span v-if="key <= 1">{{ nb }}</span>
          <span v-if="key <= 0" style="margin-right: 5px">{{ `,` }}</span>
          <span
            v-if="key == 1"
            style="margin-right: 5px"
            :data-tooltip="getCommaSeperatedValues(copyOfRefData[index])"
            data-tooltip-location="bottom"
            >{{ `...` }}</span
          >
        </span>
      </div>
      <div
        v-if="typeof item === 'number' && !copyOfRefData[index]"
        class="colon-value"
      >
        {{ item }}
      </div>
      <div v-if="typeof item === 'boolean'" class="colon-value">
        <span v-if="item">
          <hlx-input
            type="radio-buttons"
            :disabled="true"
            :name="index"
            :position="'horizontal'"
            :options="[
              {
                name: 'True',
                checked: true,
                disabled: false,
                id: 1,
              },
              { name: 'False', checked: false, disabled: false, id: 2 },
            ]"
          ></hlx-input>
        </span>
        <span v-else>
          <hlx-input
            type="radio-buttons"
            :disabled="true"
            :name="index"
            :position="'horizontal'"
            :options="[
              {
                name: 'True',
                checked: false,
                disabled: false,
                id: 1,
              },
              { name: 'False', checked: true, disabled: false, id: 2 },
            ]"
          ></hlx-input>
        </span>
      </div>
      <div v-if="typeof item === 'undefined'" class="colon-value">
        {{ "undefined" }}
      </div>
      <div v-if="item === null" class="colon-value">{{ "null" }}</div>
    </div>

    <!------------------------------------------------- Editable mode ----------------------------------------------->
    <div
      v-if="
        type === 'editable' &&
        typeof item === 'object' &&
        item !== null &&
        item !== undefined
      "
      id="obj"
      class="db-row"
    >
      <div
        v-if="typeof item === 'object' && item !== null && item !== undefined"
        class="array-index-parent"
      >
        <span v-if="typeof index === 'number'" class="array-index">{{
          index + ": "
        }}</span>
        <span v-if="typeof index === 'string'" class="array-string">{{
          index
        }}</span>
      </div>
      <div
        v-if="typeof item === 'object' && item !== null && item !== undefined"
        class="div-with-arrow"
      >
        <i
          v-if="Array.isArray(copyOfJsonData)"
          :class="
            showArray[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <i
          v-else
          :class="
            showChild[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <hlx-object-viewer
          :json-data="item"
          :show="
            Array.isArray(copyOfJsonData) ? showArray[index] : showChild[index]
          "
          :type="'editable'"
          :ref-data="copyOfRefData"
          @updated-json="updateEmit"
        ></hlx-object-viewer>
      </div>
    </div>
    <div
      v-if="
        type === 'editable' &&
        (typeof item === 'boolean' ||
          typeof item === 'string' ||
          item === null ||
          typeof item === 'undefined' ||
          typeof item === 'number')
      "
      id="other"
      class="db-row"
    >
      <!-- Keys of objects with type other than object -->
      <div
        v-if="
          typeof item === 'boolean' ||
          typeof item === 'string' ||
          typeof item === 'number' ||
          typeof item === 'undefined' ||
          item === null
        "
        class="colon-key"
      >
        {{ index }}
      </div>
      <!-- Values of objects with type other than object -->
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ formatDate(item) }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        >
        </hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :type="'date'"
          :pre-val="preValDateFormat(item)"
          @at-input="receivedDate"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf',
              'date'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ formatDate(item) }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :type="'date'"
          :pre-val="preValDateFormat(item)"
          @at-input="receivedDate"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf',
              'date'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index]
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          @at-input="updateInputValue($event, index, item, 'string')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index]
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        >
        </hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          @at-input="updateInputValue($event, index, item, 'string')"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && !copyOfRefData[index]"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && !copyOfRefData[index]"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          type="number"
          @at-input="updateInputValue($event, index, item, 'number')"
        />
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && copyOfRefData[index]"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && copyOfRefData[index]"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        ></hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          type="number"
          @at-input="updateInputValue($event, index, item, 'number')"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'boolean'"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'boolean'"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :type="'radio-buttons'"
          :position="'horizontal'"
          :options="copyOfJsonData[index] ? getTrueOption : getFalseOption"
          @selected="updateInputValue($event, index, item, 'boolean')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'undefined'"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ "undefined" }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'undefined'"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="'undefined'"
          @at-input="updateInputValue($event, index, item, 'string')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="item === null"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ "null" }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="item === null"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="'null'"
          @at-input="updateInputValue($event, index, item, 'string')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
    </div>
    <!------------------------------------------------- Add data mode ----------------------------------------------->
    <!-- <div
      v-if="
        (type === 'add-data' && item.type === 'Object') || item.type === 'Array'
      "
      id="obj"
      class="db-row"
    >
      <div
        v-if="item.type === 'Object' || item.type === 'Array'"
        class="array-index-parent"
      >
        <span v-if="typeof index === 'number'" class="array-index">{{
          index + ": "
        }}</span>
        <span v-if="typeof index === 'string'" class="array-string">{{
          index + ""
        }}</span>
      </div>
      <div
        v-if="item.type === 'Object' || item.type === 'Array'"
        class="div-with-arrow"
      >
        <i
          v-if="item.type !== 'Array'"
          :class="
            showArray[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <i
          v-if="item.type !== 'Object'"
          :class="
            showChild[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <hlx-object-viewer
          :json-data="item"
          :show="item.type === 'Object' ? showArray[index] : showChild[index]"
          :type="'add-data'"
          @updated-json="updateEmit"
        ></hlx-object-viewer>
      </div>
    </div>
    <div
      v-if="
        item.type === 'String' ||
        item.type === 'Integer' ||
        item.type === 'Boolean' ||
        item.type === 'Date'
      "
      id="other"
      class="db-row"
    >
      <div
        v-if="
          item.type === 'String' ||
          item.type === 'Integer' ||
          item.type === 'Boolean' ||
          item.type === 'Date'
        "
        class="colon-key"
      >
        {{ index }}
      </div>
      <div v-if="item.type === 'String'" class="colon-value">
        <hlx-input></hlx-input>
      </div>
      <div v-if="item.type === 'Integer'" class="colon-value">
        <hlx-input></hlx-input>
      </div>
      <div v-if="item.type === 'Boolean'" class="colon-value">
        <hlx-input></hlx-input>
      </div>
      <div v-if="item.type === 'Date'" class="colon-value">
        <hlx-input></hlx-input>
      </div>
    </div> -->
    <!------------------------------------------------- add-data-from-schema mode ----------------------------------------------->
    <div
      v-if="
        type === 'add-data-from-schema' &&
        typeof item === 'object' &&
        item !== null &&
        item !== undefined
      "
      id="obj"
      class="db-row"
    >
      <div
        v-if="typeof item === 'object' && item !== null && item !== undefined"
        class="array-index-parent"
      >
        <span v-if="typeof index === 'number'" class="array-index">
          <span @click="deleteItemFromArray(index)"
            ><i
              :style="
                index === 0
                  ? 'color: transparent; cursor: default'
                  : 'color: #ff5050;'
              "
              class="icon-trash-filled"
              style="margin-right: 0.5rem"
            ></i
          ></span>
          <span @click="addItemToArray(index)"
            ><i
              class="icon-plus-circle-filled"
              style="margin-right: 1rem; color: #54bd95"
            ></i></span
          >{{ index + ": " }}</span
        >
        <span v-if="typeof index === 'string'" class="array-string">{{
          index
        }}</span>
      </div>
      <div
        v-if="typeof item === 'object' && item !== null && item !== undefined"
        class="div-with-arrow"
      >
        <i
          v-if="Array.isArray(copyOfJsonData)"
          :class="
            showArray[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <i
          v-else
          :class="
            showChild[index]
              ? 'icon-angle-right-regular'
              : 'icon-angle-down-regular'
          "
          @click="showChildFunc(index)"
        ></i>
        <hlx-object-viewer
          :json-data="item"
          :show="
            Array.isArray(copyOfJsonData) ? showArray[index] : showChild[index]
          "
          :type="'add-data-from-schema'"
          :ref-data="copyOfRefData"
          :all-closed-prop="allClosed"
          @all-closed-emit="getAllClosedEmit"
          @updated-json="updateEmit"
        ></hlx-object-viewer>
      </div>
    </div>
    <div
      v-if="
        type === 'add-data-from-schema' &&
        (typeof item === 'boolean' ||
          typeof item === 'string' ||
          item === null ||
          typeof item === 'undefined' ||
          typeof item === 'number')
      "
      id="other"
      class="db-row"
    >
      <!-- Keys of objects with type other than object -->
      <div
        v-if="
          typeof item === 'boolean' ||
          typeof item === 'string' ||
          typeof item === 'number' ||
          typeof item === 'undefined' ||
          item === null
        "
        class="colon-key"
      >
        {{ index }}
      </div>
      <!-- Values of objects with type other than object -->
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ formatDate(item) }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        >
        </hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :type="'date'"
          :pre-val="preValDateFormat(item)"
          @at-input="receivedDate"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf',
              'date'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ formatDate(item) }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :type="'date'"
          :pre-val="preValDateFormat(item)"
          @at-input="receivedDate"
        />
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf',
              'date'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          !copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="
            copyOfJsonData[index] === '(empty)' ? '' : copyOfJsonData[index]
          "
          @at-input="updateInputValue($event, index, item, 'string')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          copyOfRefData[index] &&
          item.charAt(0) !== '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        >
        </hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          @at-input="updateInputValue($event, index, item, 'string')"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          item.charAt(0) === '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        style="align-items: flex-start;"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        <div v-if="item !== '{ }'" class="new-obj-container">
          <hlx-object-viewer :json-data="convertToObject(item)"></hlx-object-viewer>
          <!-- <span v-for="(obj, val) in convertToObject(item)" :key="val">
            <div id="other2" class="db-row2">
              <span
                class="colon-key2"
                style="width: 80px !important; margin-right: 20px"
                >{{ val }}{{ ":" }}</span
              >
              <span class="colon-value2">{{ obj }}</span>
            </div>
          </span> -->
        </div>
        <div v-else>
          {{ item }}
        </div>
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none; margin-top: 4px"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          item.charAt(0) === '{' &&
          item.charAt(0) !== '['
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        >
        </hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          @at-input="updateInputValue($event, index, item, 'string')"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          item.charAt(0) !== '{' &&
          item.charAt(0) === '['
        "
        :id="unique + index + item + 'display'"
        class="colon-value"
        style="align-items: flex-start;"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        <div v-if="item !== '[ ]'" class="new-obj-container">
          <hlx-object-viewer
          :json-data="convertToObject(item)"
          >

          </hlx-object-viewer>
          <!-- <span v-for="(obj, val) in convertToObject(item)" :key="val">
            <div id="other2" class="db-row2">
              <span
                class="colon-key2"
                style="width: 80px !important; margin-right: 20px"
                >{{ val }}{{ ":" }}</span
              >
              <span class="colon-value2">{{ obj }}</span>
            </div>
          </span> -->
        </div>
        <div v-else>
          {{ item }}
        </div>
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none; margin-top: 4px"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="
          typeof item === 'string' &&
          !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/.test(
            item
          ) &&
          item.charAt(0) !== '{' &&
          item.charAt(0) === '['
        "
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        >
        </hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          @at-input="updateInputValue($event, index, item, 'string')"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && !copyOfRefData[index]"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && !copyOfRefData[index]"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          type="number"
          @at-input="updateInputValue($event, index, item, 'number')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && copyOfRefData[index]"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'number' && copyOfRefData[index]"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-select
          :key="componentKey"
          :options="copyOfRefData[index]"
          @selected-value="updateInputValue($event, index, item, 'select')"
        ></hlx-select>
        <!-- <hlx-input
          :key="componentKey"
          :pre-val="copyOfJsonData[index]"
          type="number"
          @at-input="updateInputValue($event, index, item, 'number')"
        /> -->
        <i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'boolean'"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ item }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'boolean'"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :type="'radio-buttons'"
          :position="'horizontal'"
          :options="copyOfJsonData[index] ? getTrueOption : getFalseOption"
          @selected="updateInputValue($event, index, item, 'boolean')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'undefined'"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ "undefined" }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="typeof item === 'undefined'"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="'undefined'"
          @at-input="updateInputValue($event, index, item, 'string')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="item === null"
        :id="unique + index + item + 'display'"
        class="colon-value"
        @dblclick="
          displayAddDataInput(
            index,
            unique + index + item + 'display',
            unique + index + item + 'inputf'
          )
        "
      >
        {{ "null" }}
        <i
          v-if="allClosed"
          class="icon-edit-filled enable-edit"
          style="display: none"
          @click="
            displayAddDataInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
      <div
        v-if="item === null"
        :id="unique + index + item + 'inputf'"
        style="display: none"
        class="colon-value"
      >
        <hlx-input
          :key="componentKey"
          :pre-val="'null'"
          @at-input="updateInputValue($event, index, item, 'string')"
        /><i
          class="icon-times-square-regular cancel-edit"
          @click="
            unsetInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i
        ><i
          class="icon-check-square-regular apply-edit"
          @click="
            setInput(
              index,
              unique + index + item + 'display',
              unique + index + item + 'inputf'
            )
          "
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import HlxInput from "../components/InputComponent.vue";
import HlxSelect from "../components/SelectComponent.vue";
export default {
  name: "HlxObjectViewer",
  components: {
    HlxInput,
    HlxSelect,
  },
  props: {
    jsonData: {
      type: [Object, Array],
      default: () => ({}),
    },
    refData: {
      type: [Object, Array],
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "read-only",
    },
    allClosedProp: {
      type: Boolean,
      default: true
    }
  },
  emits: ["updated-json", "all-closed-emit"],
  data() {
    return {
      temp: {},
      showChild: {},
      showArray: [],
      showInputBoolean: false,
      showInputNumber: false,
      showInputString: false,
      copyOfJsonData: {},
      copyOfSchemaData: {},
      tempInp: "",
      tempBool: false,
      componentKey: 0,
      unique: Math.floor(Math.random() * 1000 + 1),
      trueOption: [
        { name: "True", checked: true, disabled: false, id: 1 },
        { name: "False", checked: false, disabled: false, id: 2 },
      ],
      falseOption: [
        { name: "True", checked: false, disabled: false, id: 1 },
        { name: "False", checked: true, disabled: false, id: 2 },
      ],
      allClosed: true,
      copyOfRefData: {},
    };
  },
  computed: {
    booleanHandler() {
      const opt = [];
      opt.push({ name: "true", checked: false, disabled: false, id: 1 });
      opt.push({ name: "false", checked: false, disabled: false, id: 1 });
      const option = opt.find(
        (o) => o.name === (this.tempBool ? "true" : "false")
      );
      if (option) {
        option.checked = true;
      }
      return opt;
    },
    getTrueOption() {
      return [
        { name: "True", checked: true, disabled: false, id: 1 },
        { name: "False", checked: false, disabled: false, id: 2 },
      ];
    },
    getFalseOption() {
      return [
        { name: "True", checked: false, disabled: false, id: 1 },
        { name: "False", checked: true, disabled: false, id: 2 },
      ];
    },
  },
  watch: {
    allClosedProp: {
      handler(val) {
        this.allClosed = val
      },
      immediate: true,
      deep:true
    },
    jsonData: {
      handler(val) {
        // console.log('watched')
        if (
          this.type === "read-only" ||
          this.type === "editable" ||
          this.type === "add-data-from-schema"
        ) {
          this.copyOfJsonData = val;
        } else if (this.type === "add-data") {
          this.copyOfJsonData = val;
        }
      },
      immediate: true,
      deep: true,
    },
    refData: {
      handler(val) {
        // console.log(val);
        this.copyOfRefData = val;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (document.querySelector(".array-index-parent")) {
      if (
        document
          .querySelector(".array-index-parent")
          .children[0].classList.contains("array-index")
      ) {
        document.querySelector(".array-index-parent").style.borderRight =
          "1px solid #d8d8d8";
      }
    }

    if (this.type !== "add-data") {
      if (Array.isArray(this.copyOfJsonData)) {
        for (let index = 0; index < this.jsonData.length; index++) {
          this.showArray.push(true);
        }
      } else {
        for (const index in this.copyOfJsonData) {
          if (typeof this.copyOfJsonData[index] === "object") {
            this.showChild[index] = true;
          }
        }
      }
    } else {
      // console.log(this.copyOfJsonData.type)
      if (this.copyOfJsonData.type === "Array") {
        for (let index = 0; index < this.jsonData.length; index++) {
          this.showArray.push(true);
        }
      } else {
        for (const index in this.copyOfJsonData) {
          if (this.copyOfJsonData[index] === "Object") {
            this.showChild[index] = true;
          }
        }
      }
      // console.log(this.showArray, this.showChild, 'oooo');
    }
  },
  methods: {
    getAllClosedEmit(val) {
      this.allClosed = val
      this.$emit('all-closed-emit', val)
    },
    convertToObject(item) {
      const obj = item.substring(3);
      // console.log(item, obj);
      return JSON.parse(obj);
    },
    getArrayValues(val) {
      // console.log(val);
      return val.map((obj) => obj.name);
    },
    getCommaSeperatedValues(val, ref) {
      if (ref) {
        return val.map((obj) => obj.value.replace(/\{\},|\[\],/g, '')).join(",\n");
      } else {
        return val.map((obj) => obj.name).join(", ");
      }
    },
    getCommaSeperatedDates(val) {
      const arr = val.map((obj) => obj.name);
      let newArr = [];
      arr.forEach((e) => {
        newArr.push(` ` + this.formatDate(e));
      });
      return newArr;
    },
    hasAsterisk(str) {
      return str.includes("*");
    },
    typeRemoved(value) {
      const String = value.toString();
      const startIndex = String.indexOf("(");
      const endIndex = String.indexOf(")");

      let stringWithoutBracketAndAsterisk = String;

      if (startIndex !== -1 && endIndex !== -1) {
        stringWithoutBracketAndAsterisk =
          String.slice(0, startIndex) + String.slice(endIndex + 1);
      }

      stringWithoutBracketAndAsterisk = stringWithoutBracketAndAsterisk
        .replace("*", "")
        .trim();

      return stringWithoutBracketAndAsterisk;
    },
    checkType(value) {
      const typeArr = value.split(" ");
      const type = value.split(" ")[typeArr.length - 1];
      let result = "";
      if (type === "(Boolean)") {
        result = "boolean";
      } else if (type === "(Array)") {
        result = "array";
      } else if (type === "(Object)") {
        result = "object";
      } else if (type === "(Date)") {
        result = "date";
      } else if (type === "(String)") {
        result = "string";
      } else if (type === "(Number)") {
        result = "number";
      } else {
        result = "";
      }
      return result;
    },
    receivedDate(e) {
      this.tempInp = e;
    },
    preValDateFormat(date) {
      // console.log(date);
      const datePart = date.substring(0, 10);
      return datePart.split("-").reverse().join("-");
    },
    formatDate(input) {
      const datetime = new Date(input);
      if (isNaN(datetime.getTime())) {
        return "Select a date";
      }
      const formattedDate = datetime.toLocaleDateString("en-GB");
      const formattedTime = datetime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return `${formattedDate} ${formattedTime}`;
    },
    deleteItemFromArray(index) {
      if (index !== 0) {
        this.copyOfJsonData.splice(index, 1);
      }
    },
    addItemToArray(index) {
      const obj = { ...this.copyOfJsonData[index] };
      this.copyOfJsonData.splice(index + 1, 0, obj);
    },
    updateEmit() {
      // console.log(this.copyOfJsonData, '');
      this.$emit("updated-json", this.copyOfJsonData);
    },
    updateInputValue($event, index, item, type) {
      if (type === "select") {
        this.tempInp = $event.value;
        // console.log($event.value);
      }
      if (type === "string" || type === "number") {
        this.tempInp = $event;
      }
      if (type === "boolean") {
        // console.log($event.name);
        if ($event.name.toLowerCase() === "true") {
          this.tempInp = true;
          // console.log(this.tempInp);
        } else {
          this.tempInp = false;
          // console.log(this.tempInp);
        }
      }
      // console.log($event, this.copyOfJsonData, index, item)
      // this.$emit('updated-json', this.copyOfJsonData)
    },
    displayInput(index, displayId, inputId, type) {
      const inputElements = document.querySelectorAll('[id$="inputf"]');
      inputElements.forEach((e) => {
        if (e.style.display === "flex") {
          this.allClosed = false;
        }
      });
      if (this.allClosed) {
        const displayElements = document.querySelectorAll('[id$="display"]');
        displayElements.forEach((e) => {
          e.style.display = "flex";
        });
        this.allClosed = false;
        document.getElementById(displayId).style.display = "none";
        if (type === "boolean") {
          this.tempBool = this.copyOfJsonData[index];
        } else {
          this.tempInp = this.copyOfJsonData[index];
        }
        document.getElementById(inputId).style.display = "flex";
        if (document.getElementById(inputId)) {
          if (document.getElementById(inputId).children[0]) {
            if (document.getElementById(inputId).children[0].children[0]) {
              if (
                document.getElementById(inputId).children[0].children[0]
                  .children[0]
              ) {
                document
                  .getElementById(inputId)
                  .children[0].children[0].children[0].focus();
              }
            }
          }
        }
      }
    },
    displayAddDataInput(index, displayId, inputId, type) {
      const inputElements = document.querySelectorAll('[id$="inputf"]');
      inputElements.forEach((e) => {
        if (e.style.display === "flex") {
          this.allClosed = false;
          this.$emit('all-closed-emit', this.allClosed)
        }
      });
      if (this.allClosed) {
        const displayElements = document.querySelectorAll('[id$="display"]');
        displayElements.forEach((e) => {
          e.style.display = "flex";
        });
        this.allClosed = false;
        this.$emit('all-closed-emit', this.allClosed)
        document.getElementById(displayId).style.display = "none";
        if (type === "boolean") {
          this.tempBool = this.copyOfJsonData[index];
        } else {
          this.tempInp = this.copyOfJsonData[index];
        }
        document.getElementById(inputId).style.display = "flex";
        if (document.getElementById(inputId)) {
          if (document.getElementById(inputId).children[0]) {
            if (document.getElementById(inputId).children[0].children[0]) {
              if (
                document.getElementById(inputId).children[0].children[0]
                  .children[0]
              ) {
                document
                  .getElementById(inputId)
                  .children[0].children[0].children[0].focus();
              }
            }
          }
        }
      }
    },
    setInput(index, displayId, inputId, type) {
      // console.log(type);
      // if (this.copyOfJsonData[index] !== '') {
      document.getElementById(displayId).style.display = "flex";
      document.getElementById(inputId).style.display = "none";
      if (type === "boolean") {
        this.copyOfJsonData[index] = Boolean(this.tempBool);
      } else if (type === "date") {
        if (this.tempInp.length > 20) {
          this.copyOfJsonData[index] = this.tempInp;
        } else {
          // Split the date string into day, month, and year components
          const [day, month, year] = this.tempInp.split("-");

          // Create a new Date object with the components
          const date = new Date(`${year}-${month}-${day}`);

          // Format the date and time components
          // console.log(this.tempInp.length, date);
          const formattedDate = date.toISOString().split("T")[0];
          const formattedTime = "00:00:00.000+00:00";

          // Combine the formatted date and time
          const formattedDateTime = `${formattedDate}T${formattedTime}`;
          this.copyOfJsonData[index] = formattedDateTime;
        }
      } else {
        this.copyOfJsonData[index] = this.tempInp;
      }
      this.$emit("updated-json", this.copyOfJsonData);
      this.allClosed = true;
      this.$emit('all-closed-emit', this.allClosed)
      // }
    },
    unsetInput(index, displayId, inputId, type) {
      // console.log(this.copyOfJsonData[index], this.tempInp)
      if (type === "boolean") {
        this.tempInp = "";
        this.tempBool = Boolean(this.copyOfJsonData[index]);
      } else {
        this.tempInp = "";
        this.tempInp = this.copyOfJsonData[index];
      }
      this.componentKey += 1;
      document.getElementById(displayId).style.display = "flex";
      document.getElementById(inputId).style.display = "none";
      this.allClosed = true;
      this.$emit('all-closed-emit', this.allClosed)
    },
    showChildFunc(val) {
      // console.log(this.copyOfJsonData[val].type)
      if (this.type !== "add-data") {
        if (Array.isArray(this.copyOfJsonData)) {
          if (this.showArray[val] === true) {
            this.showArray[val] = false;
          } else {
            this.showArray[val] = true;
          }
        } else {
          const i = val;
          if (this.showChild[i] === true) {
            this.showChild[i] = false;
          } else {
            this.showChild[i] = true;
          }
        }
      } else {
        if (
          this.copyOfJsonData[val].type === "Array" ||
          this.copyOfJsonData[val].type === "Object"
        ) {
          if (this.showArray[val] === true) {
            this.showArray[val] = false;
          } else {
            this.showArray[val] = true;
          }
        } else {
          const i = val;
          if (this.showChild[i] === true) {
            this.showChild[i] = false;
          } else {
            this.showChild[i] = true;
          }
        }
      }
    },
  },
};
</script>

<style></style>
