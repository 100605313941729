<template>
  <h1 class="component-heading">Import progress bar</h1>
  <p class="component-description">
    ProgressBar Component provides a way to show the progress of any
    tasks/activity to the user in the form of the progress bar.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    Set <b>:tool-tip</b> to <b>true</b> view the percentage of loader<br />
    Define strokeWidth specify <b>:strokeWidth</b> and <b> :progress</b> to
    describe the import progress stage<br />
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-import-progress
        :tool-tip="true"
        :progress="progress"
        :stroke-width="20"
      />
      <hlx-import-progress
        :tool-tip="true"
        :progress="progress"
        :stroke-width="20"
      />
      <hlx-import-progress
        :tool-tip="true"
        :progress="progress"
        :stroke-width="20"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-importProgress-icon"
        class="icon"
        @click="showCode('basic-importProgress')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-importProgress" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="importProgress_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in progressbarAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in progressbarAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'loader')"
      ><i class="icon-angle-left-regular prev-icon"></i>Loader</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'draggables')"
        >Draggable <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>
<script>
import HlxImportProgress from "../components/ImportProgressComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import CodeEditor from "simple-code-editor";
import hlxDivider from "../components/DividerComponent.vue";

export default {
  name: "ImportProgressView",
  components: {
    CodeEditor,
    HlxImportProgress,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxDivider
  },
  data() {
    return {
      editor_theme: "light",
      progress: 10,
      table_data: [
        {
          attribute: "progress",
          description: "To describe the import progress stage",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "tool-tip",
          description: "To enable the tooltip",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "stroke-width",
          description: "The width of progress bar",
          type: "Number",
          accepted_values: "-",
          default: "20",
          mandatory: false,
        },
      ],
      progressbarAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],

      importProgress_source: `
<template>
  <hlx-import-progress
  :tool-tip="true"
  :progress="this.progress"
  :strokeWidth="20"
  />
</template>

<script>
export default {
  data(){
    return{
      progress:1,
    }
  },
  mounted() {
    let interval = setInterval(() => {
      if (this.progress < 75) {
        this.progress += 2;
      } else {
        this.progress -= 5;
      }
    }, 200);
    setTimeout(() => {
      // this.progress = 100;
      clearInterval(interval);
    }, 15000);
  },
}
<\/script>

<style>

</style>
        `,
    };
  },
  mounted() {
    let interval = setInterval(() => {
      if (this.progress < 75) {
        this.progress += 2;
      } else {
        this.progress -= 5;
      }
    }, 200);
    setTimeout(() => {
      // this.progress = 100;
      clearInterval(interval);
    }, 15000);
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
