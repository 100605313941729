<template>
  <h1 class="component-heading">Email</h1>
  <p class="component-description">
    <b>Email</b> has become an essential communication channel for individuals and businesses a like
  </p>

  <h2 class="component-side-heading">Email</h2>
  <p class="component-description">
    Attribute <b>type</b> may have values such as <b>individual</b>,
    <b>multi</b>.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-button class="primary sm" @click="shomailfh">
        <i class="icon-plus-circle-regular"></i>
        Email box
      </hlx-button>

      <hlx-email
        :recipient="emailData"
        :show="shomail"
        :type="'individual'"
        :default-to="defaultTo"
        @upload-data="valuemail"
        @close="shomail = false"
      ></hlx-email>
    </div>
    <div class="example-footer">
      <span id="email-icon" class="icon" @click="showCode('email')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="email" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="email"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in treeAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in treeAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'tabs')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tab</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'speeddial')"
      >Speed dial <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>
<script>
import HlxEmail from "../components/EmailComponent.vue";

import CodeEditor from "simple-code-editor";
import hlxButton from "../components/ButtonComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "HlxEmailView",
  components: {
    HlxEmail,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxButton,
    CodeEditor,
    hlxDivider,
  },
  data() {
    return {
      shomail: false,
      emailData: [
        "john.doe@example.com",
        "jane.smith@example.com",
        "michael.johnson@example.com",
        "emily.wilson@example.com",
        "david.brown@example.com",
        "sarah.miller@example.com",
        "james.taylor@example.com",
        "lisa.anderson@example.com",
        "robert.thomas@example.com",
        "mary.jackson@example.com",
        "william.white@example.com",
        "jennifer.harris@example.com",
        "charles.martin@example.com",
        "amanda.thompson@example.com",
        "joseph.garcia@example.com",
        "ashley.martinez@example.com",
        "patrick.robinson@example.com",
        "elizabeth.clark@example.com",
        "daniel.rodriguez@example.com",
        "susan.lewis@example.com",
        "matthew.lee@example.com",
        "laura.walker@example.com",
        "christopher.hall@example.com",
        "karen.allen@example.com",
        "andrew.hill@example.com",
        "angela.young@example.com",
        "joshua.hernandez@example.com",
        "melissa.king@example.com",
        "kevin.wright@example.com",
        "nicole.lopez@example.com",
        "brian.hill@example.com",
        "michelle.scott@example.com",
        "edward.green@example.com",
        "tiffany.adams@example.com",
        "ryan.baker@example.com",
        "stephanie.carter@example.com",
        "timothy.gonzalez@example.com",
        "rebecca.nelson@example.com",
        "jason.mitchell@example.com",
        "christina.ross@example.com",
        "justin.campbell@example.com",
        "heather.roberson@example.com",
        "eric.phillips@example.com",
        "amber.evans@example.com",
        "jacob.cook@example.com",
        "lauren.morris@example.com",
        "brandon.wood@example.com",
        "katherine.james@example.com",
        "jonathan.bennett@example.com",
        "emma.gray@example.com",
        "aaron.kelly@example.com",
        "rachel.howard@example.com",
        "nathan.alexander@example.com",
        "samantha.jenkins@example.com",
        "jeffrey.hughes@example.com",
        "megan.cox@example.com",
        "bryan.james@example.com",
        "alicia.bailey@example.com",
        "josephine.diaz@example.com",
        "craig.richardson@example.com",
        "anna.wood@example.com",
        "gabriel.watson@example.com",
        "katelyn.parker@example.com",
        "scott.wright@example.com",
        "lindsay.bell@example.com",
        "sean.ross@example.com",
        "erica.gonzales@example.com",
        "douglas.howard@example.com",
        "chelsea.peterson@example.com",
        "dylan.ward@example.com",
        "crystal.cooper@example.com",
        "austin.reed@example.com",
        "erin.stewart@example.com",
        "andreas.harris@example.com",
        "jocelyn.butler@example.com",
        "devin.nguyen@example.com",
        "tara.ramirez@example.com",
        "lucas.foster@example.com",
        "summer.gonzalez@example.com",
        "cody.gray@example.com",
        "haley.jimenez@example.com",
        "marcus.russell@example.com",
        "kelsey.price@example.com",
        "julian.bell@example.com",
        "brittany.perez@example.com",
        "mason.collins@example.com",
        "amber.rivera@example.com",
        "jordan.wells@example.com",
        "monica.fisher@example.com",
        "evan.brooks@example.com",
        "katie.harrison@example.com",
        "alexander.hunt@example.com",
        "jillian.wood@example.com",
        "victor.fernandez@example.com",
        "paige.ward@example.com",
        "corey.cruz@example.com",
        "alison.ross@example.com",
        "marco.stewart@example.com",
        "jessica.morales@example.com",
        "miguel.butler@example.com",
      ],
      email: `
<template>

    <hlx-email
        :recipient="emailData"
        :show="shomail"
        :type="'individual'"
        @upload-data="valuemail"
        @close="shomail = false"
      />
  
</template>

<script>
export default {
data(){
  return{
    shomail: false,
      emailData: [
        "john.doe@example.com",
        "jane.smith@example.com",
        "michael.johnson@example.com",
        "emily.wilson@example.com",
        "david.brown@example.com",
        "sarah.miller@example.com",
        "james.taylor@example.com",
        "lisa.anderson@example.com",
        "robert.thomas@example.com"
      ],
  }
}
}
</\script>`,
      editor_theme: "light",
      event_data: [
        {
          name: "close",
          description: "Triggers when the close button is clicked or when clicked outside the modal window",
          parameter: "",
        },
        {
          name: "upload-data",
          description: "emits the value",
          parameter: "",
        },
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      defaultTo:"keerthika.k2002@gmail.com",
      table_data: [
        {
          attribute: "recipient",
          description:
            "Adds animation to the labels whenever the input is focused",
          type: "Array",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "show",
          description:
            "Define a boolean variable in data section to open and close the email",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: true,
        },
        {
          attribute: "type",
          description: "For send single or mutlti email",
          type: "String ",
          accepted_values: "individual/multi",
          default: "-",
          mandatory: false,
        },
      ],
      treeAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
    };
  },
  methods: {
    valuemail(val) {
      console.log(val, "email");
    },
    shomailfh() {
      this.shomail = true;
    },
    handleEnter() {
      if (this.inputValue.trim() !== "") {
        this.enteredValues.push(this.inputValue); // Add entered value to the beginning of the array
        this.inputValue = ""; // Clear the input field
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
