<template>
  <div>
    <ul>
      <li v-for="(field, index) in fields" :key="index">
        <strong>{{ field.name }}:</strong> {{ field.value }}
        <tree-view v-if="field.childFields" :fields="field.childFields" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HlxSchemaBuilder",
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
