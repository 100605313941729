<template>

 <div
    v-if="type === 'slide-preview-upload'"
    :style="
      position === 'horizontal'
        ? 'display: flex; gap: 2%;'
        : 'display: flex;     gap:2%'

    "
    
  >
    <span v-show="options.length > 0" class="carousel-container" >
      <span
        class="carousel"
        :style="{
          transform:
            position === 'horizontal'
              ? `translateX(${offset}px)`
              : `translateY(${offset}px)`,
          display: position === 'horizontal' ? 'flex' : 'flex',
          flexDirection: position === 'horizontal' ? 'row' : 'column',
          flexFlow: position === 'horizontal' ? 'row ' : 'column',
          gap: `1.5%`,
        }"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="slideuplod-label"
          @click="selectOption(option[value], option)"
        >
          <div
            @mouseenter="hoverOption(option[value], option)"
            @mouseleave="leavemouse(option[value], option)"
          >
            <img
              class="imagePreve"
              :class="{ selected: selectedOption === option[value] }"
              :src="option[value]"
              :alt="image"
              accept="image/*"
              :height="height"
              :width="width"
              style="object-fit: fill"
            />
          </div>
        </div>
      </span>
      <span v-if="position === 'horizontal'">
        <span v-if="!atStart">
          <button class="prev" @click="moveCarousel(-2)">
            <i class="icon-angle-left-filled"></i>
          </button>
        </span>
        <span v-if="!atEnd">
          <button class="next" @click="moveCarousel(2)">
            <i class="icon-angle-right-regular"></i>
          </button>
        </span>
      </span>
      <span v-if="position === 'vertical'">
        <span v-if="!atStart">
          <button class="prevtop" @click="previewImagvertival(-2)">
            <i class="icon-angle-up-filled"></i>
          </button>
        </span>
        <span v-if="!atEnd">
          <button class="nextbuttom" @click="previewImagvertival(2)">
            <i class="icon-angle-down-filled"></i>
          </button>
        </span>
      </span>
    </span>
    <div>
      <img
        class="imagePreve"
        :src="previewImag"
        :alt="image"
        accept="image/*"
        :height="parentWidth"
        :width="parentheight"
        style="object-fit: fill"
      />
    </div>
  </div>

  <div
    v-if="type === 'slide-upload'"
    class="main-content-body-upload"
    :style="
      position === 'horizontal'
        ? 'display: flex; gap: 2%;'
        : 'display: flex;     flex-direction: row-reverse;gap:2%'
    "
  >
    <div
      v-if="!uploaded"
      v-cloak
      class="upload-panel"
      @drop.prevent="addFiles"
      @dragover.prevent
    >
      <div class="panel-items">
        <i
          class="icon-cloud-upload-regular"
          style="font-size: 50px; color: #ccc"
          @click="tiggersliderUpload"
        ></i>
        <span
          class="drag-text"
          style="font-size: 12px; color: #191a15"
          @click="tiggersliderUpload"
        >
          <span style="color: #54bd9a">Select files</span> or drag and drop
        </span>
        <span
          class="or-text"
          style="font-size: 12px; margin-top: 8px; color: #a6a6a6"
          @click="tiggersliderUpload"
        >
        </span>
        <input
          id="fileUpload"
          ref="fileInput"
          autocomplete="off"
          type="file"
          accept="image/*"
          name="fileUpload[]"
          style="display: none"
          multiple
          @change="handleFilesliderUpload"
        />
      </div>
    </div>
    <div v-if="uploaded" class="upload-panel containere">
      <!-- Display uploaded files -->
    </div>
    <span class="upload-footer">{{ err_msg }}</span>

    <span v-show="previewImages.length > 0" class="carousel-container">
      <span
        class="carousel"
        :style="{
          transform:
            position === 'horizontal'
              ? `translateX(${offset}px)`
              : `translateY(${offset}px)`,

          display: position === 'horizontal' ? 'flex' : 'flex',
          flexDirection: position === 'horizontal' ? 'row' : 'column',
          flexFlow: position === 'horizontal' ? 'row ' : 'column',
          gap: `1.5%`,
        }"
      >
        <span
          v-for="(image, index) in previewImages"
          :key="index"
          class="carousel-item"
        >
          <img
            :class="{ imagepreve: image.name === activeDefaultIndex }"
            class="imagePreve"
            :src="image.data"
            :alt="image.name"
            accept="image/*"
            :height="height"
            :width="width"
            style="object-fit: fill"
          />

          <span
            class="feature-value-tools"
            style="
              display: flex;
              gap: 5%;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <span
              class="feature-value-default-icon-bg"
              :class="{ activepreve: image.name === activeDefaultIndex }"
            >
              <!-- '--active':element.value == featureObject.default, -->

              <i
                class="icon-badge-check-regular default-icon"
                @click="handleDefaultFeatureValue(index, image)"
              ></i>
            </span>
            <i
              class="icon-trash-regular delete-feature"
              @click="deleteFeatureValue(index, image)"
            ></i>

            <!-- v-if=" featureObject.productSpecCharacteristicValue
                                      ?.length > 1
                                  " -->
          </span>
        </span>
      </span>

      <span v-if="position === 'horizontal'">
        <span v-if="!atStart">
          <button class="prev" @click="previewImaghorizontal(-2)">
            <i class="icon-angle-left-filled"></i>
          </button>
        </span>
        <span v-if="!atEnd">
          <button class="next" @click="previewImaghorizontal(2)">
            <i class="icon-angle-right-regular"></i>
          </button>
        </span>
      </span>

      <span v-if="position === 'vertical'">
        <span v-if="!atStart">
          <button class="prevtop" @click="moveCarouselvertical(-2)">
            <i class="icon-angle-up-filled"></i>
          </button>
        </span>
        <span v-if="!atEnd">
          <button class="nextbuttom" @click="moveCarouselvertical(2)">
            <i class="icon-angle-down-filled"></i>
          </button>
        </span>
      </span>
    </span>
  </div>

  <div v-if="type == 'regular'" class="main-content-body">
    <div class="main-content-body-upload">
      <div
        v-cloak
        class="upload-panel"
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div
          v-if="buploadfile == true && file_name.length <= 0"
          class="panel-items"
        >
          <i class="icon-cloud-upload-regular"></i>
          <span class="drag-text">Drag and drop your files</span>
          <span class="or-text">or</span>
          <hlx-button
            class="secondary sm"
            style="display: block"
            onclick="document.getElementById('fileUpload1').click()"
          >
            Choose file
          </hlx-button>
          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              id="fileUpload1"
              ref="file"
              autocomplete="off"
              type="file"
              :accept="fileType"
              name="fileUpload"
              style="display: none"
              multiple
              @change="chooseFiles"
            />
          </form>
        </div>
        <div
          v-if="auploadfile == true || file_name.length > 0"
          class="upload-file"
        >
          <div class="upload-file-container">
            <div class="filename-container">
              <i class="icon-file-check-regular"></i>
              <div class="cont">
                <span>{{ file_name }}</span
                ><i
                  v-if="customIcon == undefined"
                  class="icon-trash-regular"
                  @click="deleteFilename"
                ></i>
              </div>
            </div>
          </div>
          <div v-if="customIcon != undefined" class="icons">
            <i :class="customIcon" @click="previewFile()"></i>
            <i class="icon-trash-regular" @click="deleteFilename()"></i>
          </div>
        </div>
      </div>
      <!-- <span class="upload-footer">Maximum file size is {{this.fileSizeLimit.toUpperCase()}}</span>  -->
      <span class="upload-footer">{{ err_msg }}</span>
    </div>
  </div>

  <div v-if="type == 'button'" class="button-type-upload">
    <hlx-button
      v-if="file_name == ''"
      class="secondary sm"
      style="display: block"
      onclick="document.getElementById('fileUpload2').click()"
    >
      <span><i :class="icon"></i></span> <span>{{ content }}</span>
    </hlx-button>
    <form enctype="multipart/form-data" @submit="saveDocuments">
      <input
        id="fileUpload2"
        ref="file"
        autocomplete="off"
        type="file"
        :accept="fileType"
        name="fileUpload"
        style="display: none"
        multiple
        @change="chooseFiles"
      />
    </form>
    <div v-if="file_name != ''" class="filename-container">
      <div class="cont">
        <span>{{ file_name }}</span>
        <i
          v-if="customIcon != undefined"
          :class="customIcon"
          @click="previewFile"
        ></i
        ><i class="icon-trash-regular" @click="deleteFilename"></i>
      </div>
      <!-- <div class="icons" v-if="this.customIcon!=undefined">
          
          <i :class="this.customIcon" @click="previewFile"></i>
          <i class="icon-trash-regular" @click="deleteFilename"></i>
         </div> -->
    </div>
  </div>
  <div v-if="type == 'link-button'" class="button-type-upload">
    <span
      style="display: block; cursor: pointer"
      onclick="document.getElementById('fileUpload3').click()"
    >
      <span class="text-btn-icon"><i :class="icon"></i></span>
      <span class="text-btn-upload">{{ content }}</span>
    </span>
    <form enctype="multipart/form-data" @submit="saveDocuments">
      <input
        id="fileUpload3"
        ref="file"
        autocomplete="off"
        type="file"
        :accept="fileType"
        name="fileUpload"
        style="display: none"
        multiple
        @change="chooseFiles"
      />
    </form>
  </div>

  <div
    v-if="type == 'profile-upload'"
    class="main-content-body-upload"
    style="height: fit-content !important"
  >
    <div>
      <div
        v-if="!profileuploaded && !isValid"
        v-cloak
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div
          class="upload-panel containere"
          :style="{
            border: styleBackground.border
              ? 'none'
              : `1px solid  ${styleBackground.borderColor}`,
            height: `${height}px`,
            width: `${width}px`,
            borderRadius: uploadtype === 'Circle' ? '100%' : '6px',
            backgroundColor: styleBackground.backgroundColor,
          }"
        >
          <div
            :style="{
              color: styleBackground.bacgroundtTextColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: `${styleBackground.TextfontSize}px`,
            }"
          >
            {{ initials }}
          </div>
          <div
            v-if="!readOnly"
            class="overlay"
            :style="{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              borderRadius: uploadtype === 'Circle' ? '100%' : '5px',
            }"
            @click="openFileInput"
          >
            <i
              class="icon-camera-filled"
              style="font-size: 24px; cursor: pointer"
            ></i>
          </div>

          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              id="fileUpload24"
              ref="fileprofile"
              autocomplete="off"
              type="file"
              accept="image/*"
              name="fileUpload"
              style="display: none"
              @change="handleFileInputChange"
            />
          </form>
        </div>
      </div>
      <div
        v-if="profileuploaded && !isValid"
        v-cloak
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div
          class="upload-panel containere"
          :style="{
            border: styleBackground.border
              ? 'none'
              : `1px solid  ${styleBackground.borderColor}`,
            height: `${height}px`,
            width: `${width}px`,
            borderRadius: uploadtype === 'Circle' ? '100%' : '6px',
            backgroundColor: styleBackground.backgroundColor,
          }"
        >
          <div
            :style="{
              color: styleBackground.bacgroundtTextColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: `${styleBackground.TextfontSize}px`,
            }"
          >
            {{ initials }}
          </div>
          <div
            v-if="!readOnly"
            class="overlay"
            :style="{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              borderRadius: uploadtype === 'Circle' ? '100%' : '5px',
            }"
            @click="openFileInput"
          >
            <i
              class="icon-camera-filled"
              style="font-size: 24px; cursor: pointer"
              @click="deleteImg"
            ></i>
          </div>

          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              id="fileUpload24"
              ref="fileprofile"
              autocomplete="off"
              type="file"
              accept="image/*"
              name="fileUpload"
              style="display: none"
              @change="handleFileInputChange"
            />
          </form>
        </div>
      </div>
      <div
        v-if="profileuploaded && isValid"
        style="border: 2px solid var(--hlx-border-color); overflow: hidden"
        class="upload-panel containere"
        :style="{
          height: `${height}px`,
          width: `${width}px`,
          borderRadius: uploadtype === 'Circle' ? '100%' : '6px',
        }"
      >
        <img height="150" :src="profilePicPreviewData" style="width: 100%" />
        <!-- height: 100%;
          object-fit: cover;
          border-radius: 100%; -->
        <div
          v-if="!readOnly"
          class="overlay"
          :style="{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            borderRadius: uploadtype === 'Circle' ? '100%' : '5px',
          }"
        >
          <i
            class="icon-trash-regular"
            style="font-size: 24px; cursor: pointer"
            @click="deleteImgprofile"
          ></i>
        </div>
      </div>

      <span class="upload-footer">{{ err_msg }}</span>
    </div>
  </div>

  <div
    v-if="type == 'profile-upload-preview'"
    class="main-content-body-upload"
    style="height: fit-content !important"
  >
    <div>
      <div v-if="!profileuploaded && !isValid">
        <div
          class="upload-panel containere"
          :style="{
            border: styleBackground.border
              ? 'none'
              : `1px solid  ${styleBackground.borderColor}`,
            height: `${height}px`,
            width: `${width}px`,
            borderRadius: uploadtype === 'Circle' ? '100%' : '6px',
            backgroundColor: styleBackground.backgroundColor,
          }"
        >
          <div
            :style="{
              color: styleBackground.bacgroundtTextColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: `${styleBackground.TextfontSize}px`,
            }"
          >
            {{ initials }}
          </div>
        </div>
      </div>
      <div v-if="profileuploaded && !isValid">
        <div
          class="upload-panel containere"
          :style="{
            border: styleBackground.border
              ? 'none'
              : `1px solid  ${styleBackground.borderColor}`,
            height: `${height}px`,
            width: `${width}px`,
            borderRadius: uploadtype === 'Circle' ? '100%' : '6px',
            backgroundColor: styleBackground.backgroundColor,
          }"
        >
          <div
            :style="{
              color: styleBackground.bacgroundtTextColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: `${styleBackground.TextfontSize}px`,
            }"
          >
            {{ initials }}
          </div>
        </div>
      </div>
      <div
        v-if="profileuploaded && isValid"
        style="border: 2px solid var(--hlx-border-color); overflow: hidden"
        class="upload-panel containere"
        :style="{
          height: `${height}px`,
          width: `${width}px`,
          borderRadius: uploadtype === 'Circle' ? '100%' : '6px',
        }"
      >
        <img height="150" :src="profilePicPreview" style="width: 100%" />
      </div>
    </div>
  </div>

  <div v-if="type == 'upc-regular'" class="main-content-body">
    <div class="main-content-body-upload">
      <div
        v-if="!uploaded"
        v-cloak
        class="upload-panel"
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div class="panel-items">
          <i
            class="icon-cloud-upload-regular"
            style="font-size: 50px; color: #ccc"
            onclick="document.getElementById('fileUpload4').click()"
          ></i>
          <span
            class="drag-text"
            style="font-size: 12px; color: #191a15"
            onclick="document.getElementById('fileUpload4').click()"
            ><span style="color: #54bd9a">Select file</span> or drag and
            drop</span
          >
          <span
            class="or-text"
            style="font-size: 12px; margin-top: 8px; color: #a6a6a6"
            onclick="document.getElementById('fileUpload4').click()"
            >{{ fileType.replaceAll(".", " ").toUpperCase() }} Max
            {{ fileSizeLimit.toUpperCase() }}</span
          >
          <!-- <i class="icon-cloud-upload-regular" style="font-size: 30px;color:#ccc"  onclick="document.getElementById('fileUpload4').click()"></i>
          <span class="drag-text" style="font-size: 12px; color:#a6a6a6"  onclick="document.getElementById('fileUpload4').click()">Drop your image here</span>
          <span class="or-text" style="font-size: 12px; color:#a6a6a6"  onclick="document.getElementById('fileUpload4').click()">or</span> -->
          <!-- <span
            class="text-btn-upload"
            style="display: block;font-size: 12px"
            onclick="document.getElementById('fileUpload4').click()"
          >
            Click to browse
    </span> -->
          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              id="fileUpload4"
              ref="file"
              autocomplete="off"
              type="file"
              :accept="fileType"
              name="fileUpload[]"
              style="display: none"
              multiple
              @change="chooseFiles"
            />
          </form>
        </div>
        <!-- <div
          v-if="auploadfile == true || file_name.length > 0"
          class="upload-file"
        >
          <div class="upload-file-container">
            <div class="filename-container">
              <i class="icon-file-check-regular"></i>
              <div class="cont">
                <span>{{ file_name }}</span
                ><i
                  v-if="customIcon == undefined"
                  class="icon-trash-regular"
                  @click="deleteFilename"
                ></i>
              </div>
            </div>
          </div>
          <div v-if="customIcon != undefined" class="icons">
            <i :class="customIcon" @click="previewFile()"></i>
            <i class="icon-trash-regular" @click="deleteFilename()"></i>
          </div>
        </div> -->
      </div>
      <div v-if="uploaded" class="upload-panel containere">
        <img
          id="output"
          height="150"
          :src="imgSrc"
          style="width: 100%; height: 100%; object-fit: contain"
        />
        <div
          class="overlay"
          style="display: flex; justify-content: space-between"
        >
          <i
            class="icon-download-regular"
            style="font-size: 24px; cursor: pointer"
            @click="downloadImg"
          ></i
          ><i
            class="icon-trash-regular"
            style="font-size: 24px; cursor: pointer"
            @click="deleteImg"
          ></i>
        </div>
      </div>

      <!-- <span class="upload-footer">Maximum file size is {{this.fileSizeLimit.toUpperCase()}}</span>  -->
      <span class="upload-footer">{{ err_msg }}</span>
    </div>
  </div>

  <div v-if="type == 'upc-regular-v2'" class="main-content-body">
    <div class="main-content-body-upload">
      <div
        v-cloak
        class="upload-panel"
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div class="panel-items">
          <i
            class="icon-cloud-upload-regular"
            style="font-size: 30px; color: #ccc"
            onclick="document.getElementById('fileUpload9').click()"
          ></i>
          <span
            class="drag-text"
            style="font-size: 12px; color: #191a15"
            onclick="document.getElementById('fileUpload9').click()"
            ><span style="color: #54bd9a">Select file</span> or drag and
            drop</span
          >
          <span
            class="or-text"
            style="font-size: 12px; margin-top: 8px; color: #a6a6a6"
            onclick="document.getElementById('fileUpload9').click()"
            >{{ fileType.replaceAll(".", " ").toUpperCase() }} Max
            {{ fileSizeLimit.toUpperCase() }}</span
          >
          <!-- <i class="icon-cloud-upload-regular" style="font-size: 30px;color:#ccc"  onclick="document.getElementById('fileUpload4').click()"></i>
      <span class="drag-text" style="font-size: 12px; color:#a6a6a6"  onclick="document.getElementById('fileUpload4').click()">Drop your image here</span>
      <span class="or-text" style="font-size: 12px; color:#a6a6a6"  onclick="document.getElementById('fileUpload4').click()">or</span> -->
          <!-- <span
        class="text-btn-upload"
        style="display: block;font-size: 12px"
        onclick="document.getElementById('fileUpload9').click()"
      >
        Click to browse
</span> -->
          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              id="fileUpload9"
              ref="file"
              autocomplete="off"
              type="file"
              :accept="fileType"
              name="fileUpload9"
              style="display: none"
              multiple
              @change="chooseFiles"
            />
          </form>
        </div>
        <!-- <div
      v-if="auploadfile == true || file_name.length > 0"
      class="upload-file"
    >
      <div class="upload-file-container">
        <div class="filename-container">
          <i class="icon-file-check-regular"></i>
          <div class="cont">
            <span>{{ file_name }}</span
            ><i
              v-if="customIcon == undefined"
              class="icon-trash-regular"
              @click="deleteFilename"
            ></i>
          </div>
        </div>
      </div>
      <div v-if="customIcon != undefined" class="icons">
        <i :class="customIcon" @click="previewFile()"></i>
        <i class="icon-trash-regular" @click="deleteFilename()"></i>
      </div>
    </div> -->
      </div>
      <!-- <div v-if="uploaded" class="upload-panel containere" >
    <img id="output" height="0" :src="imgSrc"/>	
    <div class="overlay"><i class="icon-trash-regular" style="font-size:24px; cursor:pointer" @click="deleteImg"></i></div>
  </div> -->

      <!-- <span class="upload-footer">Maximum file size is {{this.fileSizeLimit.toUpperCase()}}</span>  -->
      <span class="upload-footer">{{ err_msg }}</span>
      <img id="outputt" height="0" :src="imgSrc" />
    </div>
  </div>

  <div v-if="type == 'upc-regular-sm'" class="main-content-body">
    <div class="main-content-body-upload">
      <div
        v-cloak
        v-if="!uploaded"
        class="upload-panel sm"
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div class="panel-items">
          <!-- <i class="icon-cloud-upload-regular" style="font-size: 20px;color:#ccc; padding-bottom: 0"  onclick="document.getElementById('fileUpload6').click()"></i>
          <span class="drag-text" style="font-size: 12px; color:#a6a6a6"  onclick="document.getElementById('fileUpload6').click()">Drop your image here</span> -->
          <span class="or-text" style="font-size: 12px" @click="clickFunc"
            ><i
              style="font-size: 24px; color: #54bd9a"
              class="icon-plus-circle-filled"
            ></i
          ></span>
          <!-- <span
            class="text-btn-uploadd"
            style="display: block;font-size: 12px"
            onclick="document.getElementById('fileUpload6').click()"
          >
            Click to browse
    </span> -->
          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              :id="`fileUpload6${unique}`"
              ref="file"
              autocomplete="off"
              type="file"
              :accept="fileType"
              name="fileUpload"
              style="display: none"
              multiple
              @change="chooseFiles"
            />
          </form>
        </div>
        <!-- <div
          v-if="auploadfile == true || file_name.length > 0"
          class="upload-file"
        >
          <div class="upload-file-container">
            <div class="filename-container">
              <i class="icon-file-check-regular"></i>
              <div class="cont">
                <span>{{ file_name }}</span
                ><i
                  v-if="customIcon == undefined"
                  class="icon-trash-regular"
                  @click="deleteFilename"
                ></i>
              </div>
            </div>
          </div>
          <div v-if="customIcon != undefined" class="icons">
            <i :class="customIcon" @click="previewFile()"></i>
            <i class="icon-trash-regular" @click="deleteFilename()"></i>
          </div>
        </div> -->
      </div>
      <div v-if="uploaded" class="upload-panel containere sm">
        <img :id="`output1${unique}`" height="70" :src="imgSrc" />
        <div class="overlay" style="padding: 5px">
          <i
            class="icon-trash-regular"
            style="font-size: 20px; cursor: pointer"
            @click="deleteImg"
          ></i>
        </div>
      </div>

      <!-- <span class="upload-footer">Maximum file size is {{this.fileSizeLimit.toUpperCase()}}</span>  -->
      <span class="upload-footer">{{ err_msg }}</span>
    </div>
  </div>
  <div v-if="type == 'upc-regular-sm-v2'" class="main-content-body">
    <div class="main-content-body-upload">
      <div
        v-cloak
        class="upload-panel sm"
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div class="panel-items">
          <!-- <i class="icon-cloud-upload-regular" style="font-size: 20px;color:#ccc; padding-bottom: 0"  onclick="document.getElementById('fileUpload6').click()"></i>
          <span class="drag-text" style="font-size: 12px; color:#a6a6a6"  onclick="document.getElementById('fileUpload6').click()">Drop your image here</span> -->
          <span class="or-text" style="font-size: 12px" @click="clickFunc1"
            ><i
              style="font-size: 24px; color: #54bd9a"
              class="icon-plus-circle-filled"
            ></i
          ></span>
          <!-- <span
            class="text-btn-uploadd"
            style="display: block;font-size: 12px"
            onclick="document.getElementById('fileUpload6').click()"
          >
            Click to browse
    </span> -->
          <form enctype="multipart/form-data" @submit="saveDocuments">
            <input
              :id="`fileUpload8${unique}`"
              ref="file"
              autocomplete="off"
              type="file"
              :accept="fileType"
              name="fileUpload"
              style="display: none"
              multiple
              @change="chooseFiles"
            />
          </form>
        </div>
        <!-- <div
          v-if="auploadfile == true || file_name.length > 0"
          class="upload-file"
        >
          <div class="upload-file-container">
            <div class="filename-container">
              <i class="icon-file-check-regular"></i>
              <div class="cont">
                <span>{{ file_name }}</span
                ><i
                  v-if="customIcon == undefined"
                  class="icon-trash-regular"
                  @click="deleteFilename"
                ></i>
              </div>
            </div>
          </div>
          <div v-if="customIcon != undefined" class="icons">
            <i :class="customIcon" @click="previewFile()"></i>
            <i class="icon-trash-regular" @click="deleteFilename()"></i>
          </div>
        </div> -->
      </div>
      <!-- <div class="upload-panel containere sm" v-if="uploaded"> -->
      <img id="output-sm" height="0" :src="imgSrc" />
      <!-- <div class="overlay" style="padding:5px"><i class="icon-trash-regular" style="font-size:20px; cursor:pointer" @click="deleteImg"></i></div> -->
      <!-- </div> -->

      <!-- <span class="upload-footer">Maximum file size is {{this.fileSizeLimit.toUpperCase()}}</span>  -->
      <!-- <span class="upload-footer">{{ err_msg }}</span> -->
    </div>
  </div>

  <div v-if="type == 'upc-link-button'" class="main-content-body">
    <div @click="modal()">
      <span class="text-btn-icon"><i :class="icon"></i></span>
      <span class="text-btn-upload">{{ content }}</span>
    </div>

    <hlx-modal
      :modal-active="modal_active"
      :height="'600px'"
      :width="'400px'"
      :modal="true"
      @close="modal_active = false"
    >
      <template #header>
        <div>Upload file</div>
      </template>
      <template #content>
        <div
          class="modal-content"
          style="display: flex; flex-direction: column"
        >
          <div style="height: 40%">
            <div class="main-content-body-upload">
              <div
                v-cloak
                class="upload-panel"
                @drop.prevent="dragFiles"
                @dragover.prevent
              >
                <div class="panel-items">
                  <i
                    class="icon-cloud-upload-regular"
                    style="font-size: 30px; color: #ccc"
                    onclick="document.getElementById('fileUpload5').click()"
                  ></i>
                  <span
                    class="drag-text"
                    style="font-size: 12px; color: #191a15"
                    onclick="document.getElementById('fileUpload5').click()"
                    ><span style="color: #54bd9a">Select file</span> or drag and
                    drop</span
                  >
                  <span
                    class="or-text"
                    style="font-size: 12px; color: #a6a6a6"
                    onclick="document.getElementById('fileUpload5').click()"
                    >{{ fileType.replaceAll(".", " ").toUpperCase() }} Max
                    {{ fileSizeLimit.toUpperCase() }}</span
                  >
                  <!-- <span
            class="text-btn-upload"
            style="display: block;font-size: 12px"
            onclick="document.getElementById('fileUpload5').click()"
          >
            {{ this.fileType }}
    </span> -->
                  <form enctype="multipart/form-data" @submit="newDocuments">
                    <input
                      id="fileUpload5"
                      ref="file"
                      autocomplete="off"
                      type="file"
                      :accept="fileType"
                      name="fileUpload"
                      style="display: none"
                      multiple
                      @change="clickFiles"
                    />
                  </form>
                </div>
              </div>
              <span class="upload-footer">{{ err_msg }}</span>
            </div>

            <div class="file-items">
              <div
                v-for="(item, index) in fileItems"
                :key="index"
                class="item"
                :style="
                  index != fileItems.length - 1
                    ? 'margin-bottom: 8px'
                    : 'margin-bottom: 0px'
                "
              >
                <div v-if="item.name.includes('.pdf')" class="left">
                  <img src="../assets/svg/pdf.svg" alt="" />
                </div>
                <div v-else-if="item.name.includes('.doc')" class="left">
                  <img src="../assets/svg/word.svg" alt="" />
                </div>
                <div v-else class="left">
                  <img src="../assets/svg/file.svg" alt="" />
                </div>
                <div class="right">
                  <span class="title"
                    ><span style="font-weight: 600">{{ item.name }}</span
                    ><span
                      ><i
                        class="icon-times-filled"
                        style="cursor: pointer"
                        @click="deleteFileItem(index)"
                      ></i></span
                  ></span>
                  <span class="desc">{{ fileSizeLimit }}</span>
                  <span v-if="display[index]" class="progress"
                    ><span class="leftt"
                      ><hlx-import-progress
                        :tool-tip="false"
                        :progress="progress[index]"
                        :stroke-width="5"
                      />
                    </span>
                    <span
                      v-if="progress[index] == 100"
                      style="color: #02b55c"
                      class="rightt"
                      >{{ progress[index] }}%</span
                    >
                    <span v-else class="rightt">{{ progress[index] }}%</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button
          class="secondary sm"
          style="margin-right: 16px; width: 100%"
          @click="cancel"
          >Cancel</hlx-button
        >
        <hlx-button class="primary sm" style="width: 100%" @click="upload"
          >Upload</hlx-button
        >
      </template>
    </hlx-modal>
  </div>

  <span v-if="type == 'upc-link-button-v2'" style="width: 16px">
    <span
      class="text-btn-icon"
      onclick="document.getElementById('fileUpload5').click()"
      ><i :class="icon"></i
    ></span>

    <form enctype="multipart/form-data" @submit="newDocuments">
      <input
        id="fileUpload5"
        ref="file"
        autocomplete="off"
        type="file"
        :accept="fileType"
        name="fileUpload5"
        style="display: none"
        multiple
        @change="chooseFiles"
      />
    </form>
  </span>
</template>

<script>
import hlxButton from "./ButtonComponent.vue";
import hlxModal from "./ModalwindowComponent.vue";
import HlxImportProgress from "../components/ImportProgressComponent.vue";

// import hlxAlertNotification from "../components/AlertComponent.vue";
export default {
  name: "HlxFileUpload",
  components: {
    // hlxAlertNotification,
    hlxButton,
    hlxModal,
    HlxImportProgress,
  },
  // props:['fileType','fileSizeLimit','customIcon', 'type'],
  props: {
    setDefalt: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "100",
    },
    height: {
      type: String,
      default: "100",
    },
    position: {
      type: String,
      default: "horizontal",
    },
    styleBackground: {
      type: Object,
      default: function () {
        return {
          backgroundColor: "transparent",
          bacgroundtTextColor: "Black",
          border: false,
          borderColor: "#d8d8d8",
          TextfontSize: 24,
        };
      },
    },
    parentWidth: {
      type: String,
      default: "400",
    },
    prefferedImage: {
      type: String,
      default: "Bar-Headed Goose",
    },
    parentHeight: {
      type: String,
      default: "400",
    },

    value: {
      type: String,
      default: "data",
    },

    options: {
      type: Array,
      default: () => [],
    },
    sliderOptions: {
      type: Array,
      default: () => [],
    },
    preValue: {
      type: String,
      default: "",
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    nameDisplay: {
      type: String,
      default: () => "",
    },
    nameBackgroundColor: {
      type: String,
      default: () => "white",
    },
    nameSize: {
      type: Number,
      default: 15,
    },
    backgroundColor: {
      type: String,
      default: () => "transparent",
    },
    uploadtype: {
      type: String,
      default: () => "Square",
    },

    preset: {
      type: Object,
      default: () => ({}),
    },
    src: {
      type: String,
      default: () => "",
    },
    presetFiles: {
      type: Array,
      default: () => [],
    },
    fileType: {
      type: String,
      default: "",
    },
    fileSizeLimit: {
      type: String,
      default: "",
    },
    customIcon: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "regular",
    },
    profilePicPreview: {
      type: String,
      default: "",
    },
  },
  emits: [
    "fileData",
    "previewFile",
    "deleteFile",
    "filesArray",
    "deleteImage",
    "downloadImage",
    "native-data",
    "default-image",
    "selected-value",
    "hovered-value",
  ],
  data() {
    return {
      overflow: false,
      previewImages: [],
      previewImagesevent: [],
      currentIndex: 0,
      offset: 0,
      selectedOption: "",
      hoverImg: "",
      selecImg: "",
      activeDefaultIndex: null,

      profilePicPreviewData: "",
      uploaded: false,
      profileuploaded: false,
      fileItems: [],
      progress: [0, 0, 0],
      display: [true, true, true],
      modal_active: false,
      unique: Math.floor(Math.random() * 1000 + 1),
      err_msg: "",
      buploadfile: false,
      auploadfile: false,
      file_data: {},
      files: [],
      file_name: "",
      uploaddata: false,
      disablebutton: true,
      ftype: true,
      fsize: "",
      fsizee: true,
      fact: "",
      preview_data: "",
      cancelled: false,
      hover: false,
      isValid: false,
      isLoading: false,
    };
  },
  computed: {
    atStart() {
      return this.currentIndex === 0;
    },
    atEnd() {
      return this.currentIndex === this.previewImages.length - 1;
    },
    previewImag() {
      if (this.hoverImg.length > 0 && this.type === "slide-preview-upload") {
        return this.hoverImg;
      } else if (
        this.selecImg.length > 0 &&
        this.type === "slide-preview-upload"
      ) {
        return this.selecImg;
      } else {
        return ""; // Handle the case when neither hoverImg nor selecImg has a value
      }
    },
  },

  watch: {
    options: {
      handler(val) {
        if (
          window.innerWidth > this.width * val.length &&
          this.type === "slide-preview-upload"
        ) {
          this.overflow = true;
        } else {
          this.overflow = false;
        }
        console.log(
          val,
          val.length,
          this.width * val.length,
          window.innerWidth,
          this.overflow,
          "previewImages"
        );

        if (val.length > 0) {
          val.sort((a, b) => (b.preferred ? 1 : 0) - (a.preferred ? 1 : 0));
          this.initializeSelectedOption();
        }
      },
      immediate: true,
      deep: true,
    },
    preValue: {
      handler(val) {
        // Find the object in options array where value matches preValue
        const selectedObject = this.options.find(
          (option) => option[this.value] === val
        );
        if (selectedObject && this.type === "slide-preview-upload") {
          this.selectedOption = selectedObject[this.value];
        } else {
          // If no match is found, set selectedOption to empty string
          this.selectedOption = "";
        }
      },
      immediate: true, // This ensures that the watcher runs immediately when the component is created
    },
    previewImages: {
      handler(val) {
        if (window.innerWidth > this.width * val) {
          this.overflow = true;
        } else {
          this.overflow = false;
        }
      },
      immediate: true,
      deep: true,
    },
    sliderOptions: {
      handler(val) {
        this.previewImages = val;
      },
      immediate: true,
      deep: true,
    },
    prefferedImage: {
      handler(val) {
        this.activeDefaultIndex = val;
      },
      immediate: true,
      deep: true,
    },
    nameDisplay: {
      handler(val) {
        const firstTwoWords = val.split(" ").slice(0, 2);
        this.initials = firstTwoWords
          .map((word) => word.charAt(0))
          .join("")
          .toUpperCase();
        console.log(val, "ll", this.initials);
      },
      deep: true,
      immediate: true,
    },
    preset: {
      handler(val) {
        if (val != undefined && Object.keys(val).length > 0) {
          console.log(val);
          this.uploaded = true;
          this.file_name = val?.name;
          this.preview_data = val?.data;
          if (this.type == "upc-regular") {
            var doc = this.preview_data;
            console.log(doc, "mfskm");
            setTimeout(() => {
              var imageData = doc; // Your binary data string here

              // Decode the Base64-encoded data
              var binaryData = atob(imageData);

              // Create a Blob object representing the image data
              var blob = new Blob([binaryData], { type: "image/png" });

              // Create a data URL representing the Blob object
              var imageUrl = URL.createObjectURL(blob);

              // Create an image element and set its source to the data URL
              var img = document.createElement("img");
              img.src = imageUrl;

              // Append the image to a container element in the HTML document
              document.body.appendChild(img);

              // console.log(doc,"preset")
            }, 300);
          }
          if (this.type == "profile-upload") {
            var doc = this.preview_data;
            console.log(doc, "mfskm");
            setTimeout(() => {
              var imageData = doc; // Your binary data string here

              // Decode the Base64-encoded data
              var binaryData = atob(imageData);

              // Create a Blob object representing the image data
              var blob = new Blob([binaryData], { type: "image/png" });

              // Create a data URL representing the Blob object
              var imageUrl = URL.createObjectURL(blob);

              // Create an image element and set its source to the data URL
              var img = document.createElement("img");
              img.src = imageUrl;

              // Append the image to a container element in the HTML document
              document.body.appendChild(img);

              // console.log(doc,"preset")
            }, 300);
          }
          if (this.type == "upc-regular-v2") {
            var doc = this.preview_data;
            setTimeout(() => {
              var image = document.getElementById("outputt");

              image.src = URL.createObjectURL(doc);
            }, 300);
          } else if (this.type == "upc-regular-sm") {
            var doc = this.preview_data;
            setTimeout(() => {
              var image = document.getElementById(`output1${this.unique}`);
              // console.log(image,"iiii")
              image.src = URL.createObjectURL(doc);
              // console.log(image,"imgggg")
            }, 300);
          } else if (this.type == "upc-regular-sm-v2") {
            var doc = this.preview_data;
            setTimeout(() => {
              var image = document.getElementById("output-sm");
              // console.log(image,"iiii")
              image.src = URL.createObjectURL(doc);
              // console.log(image,"imgggg")
            }, 300);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    profilePicPreview: {
      handler(val) {
        if (val && val.length > 0 && val != undefined) {
          this.checkImage(this.profilePicPreview);
        }
        this.profilePicPreviewData = val;
        this.profileuploaded = true;
      },
      deep: true,
      immediate: true,
    },
    isValid: {
      handler(val) {
        console.log(val);
      },
      deep: true,
      immediate: true,
    },

    src: {
      handler(val) {
        if (val.length > 0) {
          this.uploaded = true;
          this.imgSrc = val;
        } else {
          this.uploaded = false;
          this.imgSrc = "";
        }
      },
      deep: true,
      immediate: true,
    },
    imageUrl(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.checkImage();
      }
    },
  },
  mounted() {
    this.initializeSelectedOption();

    if (
      this.profilePicPreview != undefined &&
      this.profilePicPreview.length > 0
    ) {
      this.checkImage(this.profilePicPreview);
      this.profileuploaded = true;
    } else {
      this.profileuploaded = false;
    }
    if (this.preset != undefined && Object.keys(this.preset).length > 0) {
      this.uploaded = true;
      // console.log(this.preset,"preset")
      this.file_name = this.preset?.name;
      this.preview_data = this.preset?.data;
      if (this.type == "upc-regular") {
        var doc = this.preview_data.properties;
        setTimeout(() => {
          var image = document.getElementById("output");
          if (image != undefined || image != null) {
            image.src = URL.createObjectURL(doc);
          }
        }, 300);
      } else if (this.type == "profile-upload") {
        var doc = this.preview_data.properties;
        setTimeout(() => {
          var image = document.getElementById("output");
          if (image != undefined || image != null) {
            image.src = URL.createObjectURL(doc);
          }
        }, 300);
      } else if (this.type == "upc-regular-v2") {
        var doc = this.preview_data.properties;
        setTimeout(() => {
          var image = document.getElementById("outputt");
          if (image != undefined || image != null) {
            image.src = URL.createObjectURL(doc);
          }
        }, 300);
      } else if (this.type == "upc-regular-sm") {
        var doc = this.preview_data.properties;
        setTimeout(() => {
          if (image != undefined || image != null) {
            var image = document.getElementById(`output1${this.unique}`);
          }
          image.src = URL.createObjectURL(doc);
        }, 300);
      } else if (this.type == "upc-regular-sm-v2") {
        var doc = this.preview_data.properties;
        setTimeout(() => {
          if (image != undefined || image != null) {
            var image = document.getElementById("output-sm");
          }
          image.src = URL.createObjectURL(doc);
        }, 300);
      }
    }
    this.uploadfile = true;
    this.disablebutton = true;
    this.buploadfile = true;
    this.fsize = parseInt(this.fileSizeLimit.replace("mb", "")) * 1000000;
    // this.fileItems = [
    //   {name:"Doc1.pdf",fileData:""},
    //   {name:"Doc2.doc",fileData:""},
    //   {name:"Doc3.png",fileData:""}
    // ]
    let abc = JSON.stringify(this.presetFiles);
    this.fileItems = JSON.parse(abc);
  },
  methods: {
    initializeSelectedOption() {
      // Check if sliderOptions is defined and not empty
      if (!this.options || this.options.length === 0) {
        console.error("sliderOptions is either undefined or empty");
        return;
      }

      // Find the preferred option
      let preferredOption = this.options.find(
        (option) => option.preferred === true
      );
      let preferredOptionprefer = preferredOption[this.value];
      console.log(preferredOption, "preferredOption");
      // Set the selected option to the preferred option if it exists, otherwise set to the first option
      this.selectedOption = preferredOptionprefer
        ? preferredOptionprefer
        : this.options[0][this.value];
      this.selecImg = this.selectedOption;
    },
    previewImagvertival(direction) {
      const numImages = this.options.length;
      const windowHeight = window.innerHeight;
      const carouselItemHeight = windowHeight / numImages;

      let newIndex = this.currentIndex + direction;

      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex >= numImages) {
        newIndex = numImages - 1;
      }

      this.currentIndex = newIndex;
      this.offset = -this.currentIndex * carouselItemHeight;

      const startingPoint = this.currentIndex;
      const endingPoint =
        (this.currentIndex + Math.floor(windowHeight / carouselItemHeight)) %
        numImages;

      console.log("Starting Point:", startingPoint);
      console.log("Ending Point:", endingPoint);
    },
    moveCarousel(direction) {
      const numImages = this.options.length;
      const windowWidth = window.innerWidth;
      console.log(numImages, windowWidth);
      const carouselItemWidth = windowWidth / numImages;

      // Calculate the new index
      let newIndex = this.currentIndex + direction;

      // Ensure newIndex stays within bounds
      if (newIndex < 0) {
        newIndex = 0; // Prevent from going before the first image
      } else if (newIndex >= numImages) {
        newIndex = numImages - 1; // Prevent from going beyond the last image
      }

      this.currentIndex = newIndex;
      this.offset = -this.currentIndex * carouselItemWidth;

      // Calculate starting point and ending point
      const startingPoint = this.currentIndex;
      const endingPoint =
        (this.currentIndex + Math.floor(windowWidth / carouselItemWidth)) %
        numImages;

      console.log("Starting Point:", startingPoint);
      console.log("Ending Point:", endingPoint);
    },
    selectOption(option, optionVal) {
      this.selectedOption = option;
      this.$emit("selected-value", optionVal);
      console.log("Selected option:", this.selectedOption, optionVal);
      this.selecImg = option;
    },
    hoverOption(option, optionVal) {
      this.hoverImg = option;

      this.$emit("hovered-value", optionVal);
      console.log("Hovered option:", optionVal);
    },
    leavemouse() {
      this.hoverImg = "";
    },
    handleDefaultFeatureValue(index, image) {
      this.activeDefaultIndex = image.name;
      console.log(this.activeDefaultIndex, "this.activeDefaultIndex");
      const DefaultItem = { indexVal: index, imageVal: image };
      this.$emit("default-image", DefaultItem);
    },
    deleteFeatureValue(index, image) {
      if (this.activeDefaultIndex === index) {
        this.activeDefaultIndex = null;
      }

      this.previewImages.splice(index, 1);
      const deletedItem = { indexVal: index, imageVal: image };
      this.$emit("deleteImage", deletedItem);
    },
    moveCarouselvertical(direction) {
      const numImages = this.previewImages.length;
      const windowHeight = window.innerHeight;
      const carouselItemHeight = windowHeight / numImages;

      let newIndex = this.currentIndex + direction;

      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex >= numImages) {
        newIndex = numImages - 1;
      }

      this.currentIndex = newIndex;
      this.offset = -this.currentIndex * carouselItemHeight;

      const startingPoint = this.currentIndex;
      const endingPoint =
        (this.currentIndex + Math.floor(windowHeight / carouselItemHeight)) %
        numImages;

      console.log("Starting Point:", startingPoint);
      console.log("Ending Point:", endingPoint);
    },
    previewImaghorizontal(direction) {
      const numImages = this.previewImages.length;
      const windowWidth = window.innerWidth;
      console.log(numImages, windowWidth);
      const carouselItemWidth = windowWidth / numImages;

      // Calculate the new index
      let newIndex = this.currentIndex + direction;

      // Ensure newIndex stays within bounds
      if (newIndex < 0) {
        newIndex = 0; // Prevent from going before the first image
      } else if (newIndex >= numImages) {
        newIndex = numImages - 1; // Prevent from going beyond the last image
      }

      this.currentIndex = newIndex;
      this.offset = -this.currentIndex * carouselItemWidth;

      // Calculate starting point and ending point
      const startingPoint = this.currentIndex;
      const endingPoint =
        (this.currentIndex + Math.floor(windowWidth / carouselItemWidth)) %
        numImages;

      console.log("Starting Point:", startingPoint);
      console.log("Ending Point:", endingPoint);
    },
    tiggersliderUpload() {
      this.$refs.fileInput.click();
    },
    handleFilesliderUpload(event) {
      this.$emit("native-data", event);
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        if (i < 10) {
          const file = files[i];

          if (!file.type.startsWith("image/")) {
            console.error("File type not supported:", file?.type);
            continue;
          }
          let isDuplicated = this?.previewImages.some(
            (item) => item?.name === file?.name
          );
          if (isDuplicated) {
            console.log("Duplicate found", file?.name);
            continue;
          }
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              const obj = {
                data: e.target.result,
                name: file?.name,
              };
              this.previewImages?.push(obj);
            };
            img.onerror = () => {
              console.error("Invalid image file:", file.name);
            };
            img.src = e.target.result;
          };

          reader.readAsDataURL(file);
        } else {
          // If the limit is reached, you can alert the user or handle it in any other way
          alert("You can only upload up to 10 images.");
          break; // Exit the loop
        }
      }
    },
    checkImage(imageUrl) {
      console.log(imageUrl);

      this.isLoading = true;
      var img = new Image();
      img.onload = () => {
        this.isValid = true;
        this.isLoading = false;
      };
      img.onerror = () => {
        this.isValid = false;
        this.isLoading = false;
      };
      img.src = imageUrl;
    },
    isBinaryData(data) {
      // Check if the data is a string
      if (typeof data !== "string") {
        return false;
      }

      // Check if the string contains non-printable characters
      for (let i = 0; i < data.length; i++) {
        const charCode = data.charCodeAt(i);
        if (charCode < 32 || charCode > 126) {
          return true;
        }
      }

      return false;
    },
    clickFunc() {
      document.getElementById("fileUpload6" + this.unique).click();
    },
    clickFunc1() {
      document.getElementById("fileUpload8" + this.unique).click();
    },
    deleteImg() {
      this.$emit("deleteImage", this.preview_data);
      this.uploaded = false;
      this.profileuploaded = false;
    },
    deleteImgprofile() {
      console.log(this.profilePicPreviewData);
      this.$emit("deleteImage", this.profilePicPreviewData);
      this.uploaded = false;
      this.profileuploaded = false;
      this.isValid = false;
      this.profilePicPreviewData = "";
    },
    downloadImg() {
      // console.log("llll",this.preview_data)
      this.$emit("downloadImage", this.preview_data);
    },
    cancel() {
      this.fileItems = this.presetFiles;
      this.modal_active = false;
      // this.cancelled = true
      // if(this.presetFiles.length != 0){

      //   this.display[this.fileItems.length-1] = false
      // }
    },
    upload() {
      this.$emit("filesArray", this.fileItems);
      this.modal_active = false;
    },
    modal() {
      this.cancelled = true;
      this.modal_active = !this.modal_active;

      // console.log(this.fileItems,this.display)
      //   let interval = setInterval(() => {
      //   if (this.progress < 100) {
      //     this.progress += 2;
      //   }
      // }, 200);
      // setTimeout(() => {
      //   clearInterval(interval);
      // }, 5000);
      // console.log(this.modal_active)
    },
    deleteFileItem(index) {
      this.fileItems.splice(index, 1);
    },
    chooseFiles(event) {
      this.$emit("native-data", event);
      this.uploaded = true;
      this.profileuploaded = true;
      this.ftype = true;
      this.fsizee = true;
      var doc;
      if (this.type == "regular") {
        doc = document.getElementById("fileUpload1").files[0];
      } else if (this.type == "button") {
        doc = document.getElementById("fileUpload2").files[0];
      } else if (this.type == "link-button") {
        doc = document.getElementById("fileUpload3").files[0];
      } else if (this.type == "upc-regular") {
        doc = document.getElementById("fileUpload4").files[0];
        setTimeout(() => {
          var image = document.getElementById("output");
          // console.log(image,"imgggg")
          image.src = URL.createObjectURL(doc);
        }, 300);
      } else if (this.type == "upc-regular-v2") {
        doc = document.getElementById("fileUpload9").files[0];
        setTimeout(() => {
          var image = document.getElementById("outputt");
          // console.log(image,"imgggg")
          image.src = URL.createObjectURL(doc);
        }, 300);
      } else if (this.type == "upc-regular-sm") {
        doc = document.getElementById(`fileUpload6${this.unique}`).files[0];
        setTimeout(() => {
          var image = document.getElementById(`output1${this.unique}`);
          image.src = URL.createObjectURL(doc);
        }, 300);
      } else if (this.type == "upc-regular-sm-v2") {
        doc = document.getElementById(`fileUpload8${this.unique}`).files[0];
        setTimeout(() => {
          var image = document.getElementById("output-sm");
          image.src = URL.createObjectURL(doc);
        }, 300);
      } else if (this.type == "upc-link-button") {
        doc = document.getElementById("fileUpload5").files[0];
      } else if (this.type == "upc-link-button-v2") {
        doc = document.getElementById("fileUpload5").files[0];
      }

      this.file_data = doc;
      this.file_name = this.file_data.name;
      // console.log(doc,"zeee")
      let obj = {};
      // if (doc) {
      let events = {};
      var reader = new FileReader();
      reader.readAsText(doc, "UTF-8");
      reader.onload = function (evt) {
        events = evt;
        this.fact = evt.target.result;
        obj["properties"] = doc;
        obj["data"] = this.fact;
        // this.file_data = this.fact
        // console.log(obj,"ooo");
      };
      // }
      setTimeout(() => {
        console.log(events, obj);
        if (this.type === "profile-upload") {
          this.$emit("fileData", obj);
        } else {
          this.$emit("fileData", obj);
        }
        this.preview_data = obj;
      }, 300);

      var regex = /\./gi,
        result,
        indices = [];
      while ((result = regex.exec(this.file_data.name))) {
        indices.push(result.index);
      }
      if (this.files.length < 1) {
        if (doc.size < this.fsize) {
          if (
            this.fileType.includes(
              this.file_data.name.slice(
                indices[indices.length - 1],
                this.file_data.name.length
              )
            )
          ) {
            if (this.type == "regular") {
              let a = document.querySelector(".upload-panel");
              a.classList.remove("err");
            }
            this.err_msg = "";
            this.files.push(this.file_data);

            this.file_name = this.file_data.name;

            this.buploadfile = false;

            this.auploadfile = true;

            this.disablebutton = false;
          } else {
            this.fileTypee(this.file_data.name);
          }
        } else {
          this.fileSize(doc.size);
        }
      }
    },
    openFileInput() {
      this.$refs.fileprofile.click();
    },
    handleFileInputChange(event) {
      console.log("fmvfkmvkj");
      this.$emit("native-data", event);
      this.profileuploaded = true;
      this.isValid = true;
      const fileInput = event.target;
      const uploadedFile = fileInput.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.profilePicPreviewData = e.target.result;
        // Update the preview image source
        this.$emit("fileData", this.profilePicPreviewData);
      };

      if (uploadedFile) {
        reader.readAsDataURL(uploadedFile);
      }
    },
    addFile(e) {
      if (this.readOnly === true) {
        return;
      }
      this.$emit("native-data", e);
      this.uploaded = true;
      this.profileuploaded = true;
      this.ftype = true;
      this.fsizee = true;
      this.files = [];
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.file_data = f;
        let obj = {};
        // if (doc) {
        var reader = new FileReader();
        reader.readAsText(f, "UTF-8");
        reader.onload = function (evt) {
          this.fact = evt.target.result;
          obj["properties"] = f;
          obj["data"] = this.fact;

          // this.file_data = this.fact
          // console.log(obj,"ooo");
        };
        // }
        setTimeout(() => {
          // console.log(obj,"ppppp");
          this.$emit("fileData", obj);
          this.preview_data = obj;
          if (this.type == "upc-regular") {
            var doc = this.preview_data.properties;
            var image = document.getElementById("output");
            image.src = URL.createObjectURL(doc);
          } else if (this.type == "profile-upload") {
            var doc = this.preview_data.properties;
            var image = document.getElementById("output");
            image.src = URL.createObjectURL(doc);
          } else if (this.type == "upc-regular-v2") {
            var doc = this.preview_data.properties;
            var image = document.getElementById("outputt");
            image.src = URL.createObjectURL(doc);
          } else if (this.type == "upc-regular-sm") {
            var doc = this.preview_data.properties;

            var image = document.getElementById(`output1${this.unique}`);
            image.src = URL.createObjectURL(doc);
          } else if (this.type == "upc-regular-sm-v2") {
            var doc = this.preview_data.properties;

            var image = document.getElementById("output-sm");
            image.src = URL.createObjectURL(doc);
          }
        }, 1000);

        // this.$emit('file_data',this.file_data)

        this.file_name = this.file_data.name;
        var regex = /\./gi,
          result,
          indices = [];
        while ((result = regex.exec(this.file_name))) {
          indices.push(result.index);
        }
        if (this.files.length < 1) {
          if (f.size < this.fsize) {
            if (
              this.fileType.includes(
                this.file_name.slice(
                  indices[indices.length - 1],
                  this.file_name.length
                )
              )
            ) {
              // console.log("jeje");
              let a = document.querySelector(".upload-panel");
              a.classList.remove("err");
              this.err_msg = "";

              this.buploadfile = false;
              this.auploadfile = true;
            } else {
              this.fileTypee(this.file_data.name);
            }
          } else {
            this.fileSize(this.file_data.size);
          }
        } else if (this.files.length >= 1) {
          // this.filesLimit();
        }
      });
      // if(this.files.length!=0){
      this.files.push(this.file_data);
      // this.disablebutton = false
      this.files.push(this.file_data);
      // }
      // console.log("files",this.files);
    },
    clickFiles() {
      this.cancelled = false;

      let interval = setInterval(() => {
        if (this.progress[this.fileItems.length - 1] < 100) {
          this.progress[this.fileItems.length - 1] += 2;
        }
      }, 200);
      // if(this.fileItems.length != 0){
      //   this.fileItems.forEach((i,index)=>{

      //     this.display[index] = false
      //   })
      // }
      this.progress[this.fileItems.length] = 0;
      // setTimeout(() => {
      //   clearInterval(interval);
      // }, 5000);
      // console.log(this.progress,this.progress.length-1,"iiii")
      let finalObj = {};
      this.ftype = true;
      this.fsizee = true;
      var doc = document.getElementById("fileUpload5").files[0];

      this.file_data = doc;
      this.file_name = this.file_data.name;

      // this.fileItems.push(finalObj)
      let obj = {};
      // if (doc) {
      var reader = new FileReader();
      reader.readAsText(doc, "UTF-8");
      reader.onload = function (evt) {
        this.fact = evt.target.result;
        obj["properties"] = doc;
        obj["data"] = this.fact;
        // this.file_data = this.fact
        // console.log(obj,"ooo");
      };
      // }
      setTimeout(() => {
        // console.log(obj,"kkkk")
        this.$emit("fileData", obj);
        this.preview_data = obj;
        console.log(obj);

        if (this.fileItems.length < 4) {
          finalObj["name"] = this.file_name;
          finalObj["fileData"] = this.preview_data;
          this.fileItems.push(finalObj);

          // if(!this.cancelled){
          //       this.progress[this.fileItems.length-1] = 0
          //       this.display[this.fileItems.length-1] = true
          //       }
        }
        // console.log(this.fileItems,"oooo")
      }, 1000);
      var regex = /\./gi,
        result,
        indices = [];
      while ((result = regex.exec(this.file_data.name))) {
        indices.push(result.index);
      }
      if (this.files.length < 1) {
        if (doc.size < this.fsize) {
          if (
            this.fileType.includes(
              this.file_data.name.slice(
                indices[indices.length - 1],
                this.file_data.name.length
              )
            )
          ) {
            if (this.type == "regular") {
              let a = document.querySelector(".upload-panel");
              a.classList.remove("err");
            }
            this.err_msg = "";
            this.files.push(this.file_data);

            this.file_name = this.file_data.name;

            this.buploadfile = false;

            this.auploadfile = true;

            this.disablebutton = false;
          } else {
            this.fileTypee(this.file_data.name);
          }
        } else {
          this.fileSize(doc.size);
        }
      }
    },
    dragFiles(e) {
      this.cancelled = false;
      //   if(this.fileItems.length != 0){
      //   this.fileItems.forEach((i,index)=>{

      //     this.display[index] = false
      //   })
      // }

      let interval = setInterval(() => {
        if (this.progress[this.fileItems.length - 1] < 100) {
          this.progress[this.fileItems.length - 1] += 2;
        }
      }, 200);
      this.progress[this.fileItems.length] = 0;
      this.ftype = true;
      this.fsizee = true;
      this.files = [];

      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.file_data = f;
        let obj = {};
        // if (doc) {
        var reader = new FileReader();
        reader.readAsText(f, "UTF-8");
        reader.onload = function (evt) {
          this.fact = evt.target.result;
          obj["properties"] = f;
          obj["data"] = this.fact;
          // this.file_data = this.fact
          // console.log(obj,"ooo");
        };
        // }
        setTimeout(() => {
          // console.log(obj,"ppppp");
          this.$emit("fileData", obj);
          this.preview_data = obj;
        }, 1000);
        // this.$emit('file_data',this.file_data)

        this.file_name = this.file_data.name;
        let finalObj = { name: this.file_name, data: this.preview_data };
        if (this.fileItems.length < 4) {
          this.fileItems.push(finalObj);
          //     if(!this.cancelled){
          // this.progress[this.fileItems.length-1] = 0
          // this.display[this.fileItems.length-1] = true
          // }
        }
        var regex = /\./gi,
          result,
          indices = [];
        while ((result = regex.exec(this.file_name))) {
          indices.push(result.index);
        }
        if (this.files.length < 1) {
          if (f.size < this.fsize) {
            if (
              this.fileType.includes(
                this.file_name.slice(
                  indices[indices.length - 1],
                  this.file_name.length
                )
              )
            ) {
              // console.log("jeje");
              let a = document.querySelector(".upload-panel");
              a.classList.remove("err");
              this.err_msg = "";

              this.buploadfile = false;
              this.auploadfile = true;
            } else {
              this.fileTypee(this.file_data.name);
            }
          } else {
            this.fileSize(this.file_data.size);
          }
        } else if (this.files.length >= 1) {
          // this.filesLimit();
        }
      });
      // if(this.files.length!=0){
      this.files.push(this.file_data);
      // this.disablebutton = false
      this.files.push(this.file_data);
      // }
      // console.log("files",this.files);
    },

    fileTypee(name) {
      let a = document.querySelector(".upload-panel");
      var regex = /\./gi,
        result,
        indices = [];
      while ((result = regex.exec(name))) {
        indices.push(result.index);
      }
      if (
        this.fileType
          .toLowerCase()
          .includes(name.slice(indices[indices.length - 1], name.length))
      ) {
        this.ftype = true;
        a.classList.remove("err");
        this.err_msg = "";
      } else {
        this.ftype = false;
        a.classList.add("err");
        this.err_msg = `Supported file types are ${this.fileType}`;
        // console.log(a,"err");
      }
    },

    fileSize(size) {
      let a = document.querySelector(".upload-panel");
      if (size <= this.fsize) {
        this.fsizee = true;
        a.classList.remove("err");
        this.err_msg = "";
      } else {
        this.fsizee = false;
        a.classList.add("err");
        this.err_msg = `Maximum file size is ${this.fileSizeLimit.toUpperCase()}`;
      }
    },
    deleteContainer(event) {
      event.target.parentElement.parentElement.remove();
    },
    previewFile() {
      console.log(this.preview_data);
      this.$emit("previewFile", this.preview_data);
    },
    deleteFilename() {
      console.log("ooo");
      this.ftype = true;
      this.fsizee = true;
      this.files = [];
      this.file_data = {};
      this.file_name = "";
      this.auploadfile = false;
      this.buploadfile = true;
      this.disablebutton = true;
      this.$emit("deleteFile", this.file_data);
    },
  },

  //    triggerFileInput() {
  //     this.$refs.fileInput.click();
  //   },
  //   handleFileInputChange(event) {
  //     console.log("dc skn");
  //   const fileInput = event.target;
  //   const uploadedFile = fileInput.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     this.profilePicPreviewData = e.target.result; // Update the preview image source
  //   };

  //   if (uploadedFile) {
  //     reader.readAsDataURL(uploadedFile);
  //   }
  // },
};
</script>
<style lang="scss">
.slideuplod-label {
  .imagePreve {
    &.selected {
      border-color: #54bd95;
      //   box-shadow: 0 0 0 2px ;
      //   font-weight: 600;
      //   color: #54bd95 !important;
    }
  }
}
.activepreve {
  color: #54bd95;
}
.imagepreve {
  border: 2px solid #54bd95 !important;
}
.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
}
.imagePreve {
  border: 1px solid #d8d8d8;
  border-radius: 6px;
}

.carousel-item {
  /* flex: 0 0 100%; */
}
.default-icon:hover {
  cursor: pointer;
}
.delete-feature:hover {
  color: #ff5050;
  cursor: pointer;
}
.nextbuttom {
  position: absolute;
  /* left: 36%; */
  font-size: 22px;
  width: 100px;
  height: 8%;
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 8px;
  z-index: 1;
}
.prevtop {
  height: 8%;
  font-size: 22px;
  background-color: white;
  position: absolute;
  /* right: 36%; */
  border: none;
  cursor: pointer;
  padding: 0px;
  width: 100px;
}

.prevtop {
  top: 0;
}

.nextbuttom {
  top: 92%;
}
.prev,
.next {
  font-size: 22px;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  padding: 0px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
</style>
