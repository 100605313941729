<template>
  <h1 class="component-heading">Status</h1>
  <p class="component-description">
    The Status component serves as a visual cue to quickly convey the current
    status of an item, whether it's active or draft. It offers a clear and
    intuitive way for users to grasp the state of individual items within a
    system or application.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    Set <b>props</b> for <b>statusValue</b>, <b>statuscolor</b> &
    <b>pointColor</b> to view status in specified. <br />
  </p>

  <!----------------------------------------- space -------------------------------------------->
  <div class="component-example">
    <div class="example-body">
      <div>
        <HlxStatus :type="'active'" /><br />
        <HlxStatus :type="'inactive'" /><br />
        <HlxStatus :type="'pending'" /><br />
        <HlxStatus :type="'draft'" /><br />
        <!-- <HlxStatus :type="'Draft'"/><br> -->
        <HlxStatus :type="'validated'" /><br />
        <hlx-status :type="'custom'" :point-color="'red'" :status-value="'done'" />
        <br><br>
        <HlxStatus :type="'cancelled'" /><br />
        <HlxStatus :type="'rejected'" /><br />
        <HlxStatus :type="'inprogress'" /><br />
        <HlxStatus :type="'partial'" /><br />
        <!-- <HlxStatus :type="'Draft'"/><br> -->
        <HlxStatus :type="'held'" /><br />
        <HlxStatus :type="'acknowledged'" /><br />
        
        <HlxStatus :type="'failed'" /><br />
        <HlxStatus :type="'completed'" /><br />

      </div>
    </div>

    <div class="example-footer">
      <span id="status1-icon" class="icon" @click="showCode('status1')">
        <i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <!----------------------------------------- space -------------------------------------------->
  <div id="status1" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="status_code1"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <!----------------------------------------- space -------------------------------------------->

  <h2 class="component-side-heading">Status without prevalue</h2>

  <p class="component-description">
    <!-- Set <b>attribute</b> for <b>:type</b> to <b>select-status</b> and <b>:statusOption</b> to <b>array of object</b> where
    the dropdown data have to be defined, and every object from the array must have three keys
    <b>{name:data,value:data,color:data}</b> to define dropdown data.<br /> -->
  </p>

  <!----------------------------------------- space -------------------------------------------->

  <div class="component-example">
    <div class="example-body">
      <HlxStatus
        :type="'select-status'"
        :status-option="dropdown_data1"
        @emit-select="handleStatus"
      />
    </div>

    <div class="example-footer">
      <span id="status2-icon" class="icon" @click="showCode('status2')">
        <i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <!----------------------------------------- space -------------------------------------------->
  <div id="status2" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="status_code2"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <!----------------------------------------- space -------------------------------------------->

  <h2 class="component-side-heading">Readonly status</h2>

  <p class="component-description">
    <!-- Set <b>attribute</b> for <b>:type</b> to <b>select-status</b> and <b>:statusOption</b> to <b>array of object</b> where
    the dropdown data have to be defined, and every object from the array must have three keys
    <b>{name:data,value:data,color:data}</b> to define dropdown data.<br /> -->
  </p>

  <!----------------------------------------- space -------------------------------------------->

  <div class="component-example">
    <div class="example-body">
      <HlxStatus
        :type="'select-status'"
        :status-option="dropdown_data1"
        :prevalue="meth1"
        @emit-select="handleStatus"
        :readonly="true"
      />
    </div>

    <div class="example-footer">
      <span id="status4-icon" class="icon" @click="showCode('status4')">
        <i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <!----------------------------------------- space -------------------------------------------->
  <div id="status4" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="status_code4"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <!----------------------------------------- space -------------------------------------------->

  <h2 class="component-side-heading">Select with prevalue</h2>

  <p class="component-description">
    <!-- Additionally set <b>attribute</b> for <b>prevalue</b> from dropdown data to pre-define the status from the
    dropdown.<br /> -->
  </p>
  <!----------------------------------------- space -------------------------------------------->
{{ dropdown_data2 }}
{{ methy }}
  <div class="component-example">
    <div class="example-body">
      <HlxStatus
        :type="'select-status'"
        :status-option="dropdown_data2"
        :prevalue="methy"
        @emit-select="emitStatus"
        :label-animation="true"
      />
    </div>

    <div class="example-footer">
      <span id="status3-icon" class="icon" @click="showCode('status3')">
        <i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <!----------------------------------------- space -------------------------------------------->
  <div id="status3" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="status_code3"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <!----------------------------------------- space -------------------------------------------->

  <h2 class="component-side-heading">Status attributes</h2>

  <!----------------------------------------- space -------------------------------------------->

  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in statusAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in statusAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!----------------------------------------- space -------------------------------------------->

  <h2 class="component-side-heading">Events</h2>

  <!----------------------------------------- space -------------------------------------------->

  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in statusAttributes2"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data2" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in statusAttributes2"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <!----------------------------------------- space -------------------------------------------->
</template>

<script>
import HlxStatus from "../components/StatusComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";

export default {
  name: "StatusView",
  components: {
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    HlxStatus,
    CodeEditor,
  },

  data() {
    return {
      methy: "Pending",
      meth1: "Pending",
      dropdown_data1: [
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
      ],
      dropdown_data2: [
      {
          name: "inprogress",
          value: "inprogress",
          color: "#007242",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
      ],
      editor_theme: "light",
      status_code1: `
  <template>
    <div>
        <hlx-status :type="'Draft'"></hlx-status><br>
        <hlx-status :type="'Pending'"></hlx-status><br>
        <hlx-status :type="'Active'"></hlx-status><br>
        <hlx-status :type="'Inactive'"></hlx-status><br>
        <hlx-status :type="'validated'"></hlx-status><br>
      </div>
  </template>
  <script>
    export default {
      data() {
        return {
        }
      }
    }
  <\/script>
`,
      status_code2: `
  <template>
    <hlx-status
            :type="'select-status'"
            :status-option="dropdown_data1"
            :prevalue="meth"
            @emit-select="emitStatus1"
          >
          </hlx-status>
  </template>
  <script>
    export default {
      data() {
        return {
          meth:"",
          dropdown_data1: [
          {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        }
      ]
    }
  },
      method:{
        emitStatus(val1) {
      const emitvalue = val1["value"];
      console.log(emitvalue);
      this.meth = emitvalue;
    },
      
        
      }
    }
  <\/script>
`,
      status_code4: `
      <template>
  <hlx-status
      :type="'select-status'"
      :status-option="dropdown_data1"
      :prevalue="meth"
      @emit-select="emitStatus"
      :readonly="true"
    >
    </hlx-status>
</template>

<script>
export default {
  data(){
    return{
      meth:"Pending",
      dropdown_data1: [
          {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        }
      ]
    }
  },
  methods:{
    emitStatus(val1) {
      const emitvalue = val1["value"];
      console.log(emitvalue);
      this.meth = emitvalue;
    },
  }
}
<\/script>
`,
      status_code3: `
      <template>
  <hlx-status
      :type="'select-status'"
      :status-option="dropdown_data1"
      :prevalue="meth"
      @emit-select="emitStatus"
    >
    </hlx-status>
</template>

<script>
export default {
  data(){
    return{
      meth:"Draft",
      dropdown_data1: [
          {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        }
      ]
    }
  },
  methods:{
    emitStatus(val1) {
      const emitvalue = val1["value"];
      console.log(emitvalue);
      this.meth = emitvalue;
    },
  }
}
<\/script>
`,

      statusAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          name: "type",
          description: "to define the select status",
          type: "String",
          accepted_values: "label-status, select-status",
          default: "label-status",
          mandatory: true,
        },
        {
          name: "prevalue",
          description: "value which shows before select",
          type: "String",
          accepted_values: "Active, Inactive, Pending, Draft",
          default: "null",
          mandatory: false,
        },
        {
          name: "labelAnimation",
          description: "to display the placeholder in top of the select box",
          type: "Boolean",
          accepted_values: "true, false",
          default: "#true",
          mandatory: false,
        },
        {
          name: "statusOption",
          description: "data which show in the select option",
          type: "Array of object",
          accepted_values: "[{name:data, value:data, color:data},...]",
          default: "[]",
          mandatory: true,
        },
        {
          name: "placeholderValue",
          description: "placeholder to display in the select box",
          type: "String",
          accepted_values: "any",
          default: "Status",
          mandatory: false,
        },
        {
          name: "statusValue",
          description: "name of the option",
          type: "String",
          accepted_values: "Active, Inactive, Pending, Draft",
          default: "Active",
          mandatory: true,
        },
        {
          name: "pointColor",
          description: "color of the point",
          type: "String",
          accepted_values: "hex value of color",
          default: "#02b55c",
          mandatory: true,
        },
        {
          name: "statuscolor",
          description: "color of the text value",
          type: "String",
          accepted_values: "hex value of color",
          default: "#000000",
          mandatory: true,
        },
        {
          name:"readonly",
          description: "to disable showing dropdown ",
          type: "boolean",
          accepted_values: "true, false",
          default: "#false",
          mandatory: true,
        }
      ],

      statusAttributes2: [
        {
          prop: "name",
          label: "Attributes",
          width: 10,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 20,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Parameters",
          width: 50,
          type: "string",
          format: "",
        },
      ],
      table_data2: [
        {
          name: "emitStatus",
          description: "which emits the selected data from the options",
          type: "-",
          accepted_values: "-",
          default: "true",
        },
      ],
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.dropdown_data2 = [
        
    //   ]
    // }, 3000);
    
    setTimeout(() => {
      this.methy = "inprogress";
    }, 5000);
  },
  methods: {
    emitStatus(val1) {
      const emitvalue = val1["value"];
      console.log(emitvalue);
      this.methy = emitvalue;
    },
    handleStatus(val) {
      console.log("Selected status:", val);
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    showCode3(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
  updated() {
    console.log("asif", this.methy);
  },
};
</script>