<!-- <template>
  <hlx-text-editor
    :height="'400px'"
    :border="true"
    :prevalue="'jkkjsn<b>kjn</b>zkjnzd'"
    :read-only="false"
    :email-component ="false"
    @current-change="texteditorvalue"
  >
  </hlx-text-editor>
</template>

<script>
import HlxTextEditor from "../components/TexteditorComponent.vue";
export default {
  components: {
    HlxTextEditor,
  },
};
</script> -->

<template>
  <h1 class="component-heading">Text Editor</h1>
  <p class="component-description">
    A text editor is a type of program used for editing plain text files.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic modal</h2>
  <p class="component-description">
    To specify the list of fonts use the attribute <b> :setFont</b>
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-text-editor
    :height="'400px'"
    :border="true"
    :prevalue="'jkkjsn<b>kjn</b>zkjnzd'"
    :read-only="false"
    :email-component ="false"
    @current-change="texteditorvalue"
  ></hlx-text-editor>
    </div>
    <div class="example-footer">
      <span id="basic-model-icon" class="icon" @click="showCode('basic-model')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-model" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_modal"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Events</h2>
  <!-- <hlx-table
    :data="this.event_data"
    :border="true"
    theme="secondary"
    :rowHover="true"
    :bold_header="true"
  >
    <hlx-column :prop="'Name'" :label="'Name'" :type="'string'" />
    <hlx-column :prop="'Description'" :label="'Description'" :type="'string'" />
    <hlx-column :prop="'Parameters'" :label="'Parameters'" :type="'string'" />
  </hlx-table> -->
  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in alertAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in alertAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'Colorpalette')"
      ><i class="icon-angle-left-regular prev-icon"></i>Color palette</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'object-viewer')"
        >Object viewer <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import divider from "../components/DividerComponent.vue";
import HlxTextEditor from "../components/TexteditorComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import CodeEditor from "simple-code-editor";

export default {
  name: "TexteditorView",
  components: {
    divider,
    HlxTextEditor,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    CodeEditor,
  },
  data() {
    return {
      basic_modal: `
      <template>      
      <hlxtexteditor @current-change = check :setFont="this.fontvalue"></hlxtexteditor>
      </template>

      <script>
            data() {
              return {
              fontvalue:[
              {name:'Times New Roman',id:'24',checked:false},
              {name:'Arial',id:'28',checked:false},
              {name:'Cursive',id:'278',checked:false},
              {name:'OpenSans',id:'2844',checked:true},
              {name:'QuickSand',id:'2896',checked:false},
              {name:'OstrichSans',id:'8560',checked:false}
            ],
              },
            methods:{
              check(val){
                console.log(val,'Texteditor value');
              },
        }
`,
      editor_theme: "light",
      fontvalue: [
        { name: "Times New Roman", id: "24", checked: false },
        { name: "Arial", id: "28", checked: false },
        { name: "Cursive", id: "278", checked: false },
        { name: "OpenSans", id: "2844", checked: true },
        { name: "QuickSand", id: "2896", checked: false },
        { name: "OstrichSans", id: "8560", checked: false },
      ],
      event_data: [
        {
          Name: "custom-value",
          Description: "To return the value inside the texteditor",
          Parameters: "-",
        },
        {
          Name: "current-change",
          Description: "Return the value when being typed",
          Parameters: "-",
        },
        {
          Name: "input",
          Description:
            "triggers when content is being typed inside the texteditor",
          Parameters: "-",
        },
      ],
      alertAttributes: [
        {
          prop: "Name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "Description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "Parameters",
          label: "Parameters",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      input_events_data: [
        {
          Name: "input",
          Description:
            "triggers when content is being typed inside the texteditor",
          Parameters: "-",
        },
      ],
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    check() {
      // console.log(val,'Texteditor value');
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>

<style scoped>
.example-body {
  overflow: none;
}
</style>