<template>
  <h1 class="component-heading">Date Picker</h1>
  <p class="component-description">
    <b>DatePickerRange</b> is a component for rendering calendars from which
    users can select a range of dates.
  </p>
 
  <h2 class="component-side-heading">DatePicker</h2>
  <p class="component-description">
    To display custom placeholder, add <b>:label-value</b> attribute which takes in a string value<br>
    You can also toggle <b>label animation</b> to On/Off by setting :label-animation attribute to <b>true</b>
  </p>
 <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >

    <hlx-date-picker
    :pre-value="receivedData1"
    from-key="startDateTime"
    to-key="endDateTime"
    :label-animation="true"
    :read-only="false"
    :label-value="'Pick a date'"
    :custom-background="'linear-gradient(181deg, #f7f7f7)'"
    :top="409"
    :left="320"
    icon="calendar-regular" 
    icon-position="right"
    required="true"
    @selected-range="handleCurrentDate"
  ></hlx-date-picker>
    </div>
    <div class="example-footer">
      <span id="date-picker-icon" class="icon" @click="showCode('date-picker')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
 <div id="date-picker" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="date_picker"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  

  
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in treeAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in treeAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'tabs')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tab</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'speeddial')"
      >Speed dial <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>
<script>
import HlxTimePicker from "../components/DateAndTimePickerComponent.vue"
import HlxDatePicker from "../components/datePickerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxButton from "../components/ButtonComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "TreeView",
  components: {
    HlxDatePicker,
    HlxTimePicker,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxButton,
    CodeEditor,
    hlxDivider,
  },
  data() {
    return {
      date_picker:`
<template>
<hlx-date-picker
    :pre-value="receivedData1"
    from-key="startDateTime"
    to-key="endDateTime"
    :label-animation="true"
    :read-only="false"
    :label-value="'Pick a date'"
    :custom-background="'linear-gradient(181deg, #f7f7f7)'"
    :top="1308"
    :left="320"
    icon="calendar-regular" 
    icon-position="right"
    required="true"
    @selected-range="handleCurrentDate"
  />
</template>

<script>
export default {
data(){
}
}
</\script>`,
editor_theme: "light",
      event_data: [
        {
          name: "selected-range",
          description: "emits the range",
          parameter: "",
        }
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          attribute: "label-animation",
          description: "Adds animation to the labels whenever the input is focused",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "label-value",
          description: "Label value acts as the placeholder value for the input field",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "pre-value",
          description: "Used to pre-populate the daate field",
          type: "String / Number",
          accepted_values: "String / Number",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "read-only",
          description: "Disables the input field with default cursor",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "disabled",
          description: `Disables the input field with cursor showing "not-allowed"`,
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "custom-background",
          description: "Adds custom background color for label and input fields",
          type: "String",
          accepted_values: "any CSS color value",
          default: "#ffffff",
          mandatory: false,
        },
        {
          attribute: "icon",
          description: `Add icons to your input fields to give a better user experience`,
          type: "String",
          accepted_values: "refer to our iconography page",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "icon-position",
          description: `Specify thr position of the icon. can be used only when icon name is specified`,
          type: "Srting",
          accepted_values: "left / right",
          default: "-",
          mandatory: `true (if icon is true)`,
        },
        {
          attribute: "from-key",
          description: `startDateTime`,
          type: "Srting",
          accepted_values: "startDateTime",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "to-key",
          description: `endDateTime`,
          type: "String",
          accepted_values: "endDateTime",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "top",
          description: `To postion the calender top`,
          type: "number",
          accepted_values: "number",
          default: "-",
          mandatory: false,
        },
        {
          attribute: "left",
          description: `To postion the calender left`,
          type: "number",
          accepted_values: "number",
          default: "-",
          mandatory: false,
        }
      ],
      treeAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
    };
  },
    methods: {
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
