<template>
  <h1 class="component-heading">Slider</h1>
  <p class="component-description">
    Sliders allow users to make selections from a range of values. Sliders
    reflect a range of values along a bar, from which users may select a single
    value. They are ideal for adjusting settings such as volume, brightness, or
    applying image filters.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    To specify certain theme for slider use the attribute <b> :config </b> to
    specify color<br />
    To define maximum and minimum level of range in slider use<b> :max</b> &
    <b> :min</b> attributes
  </p>
  <br />
  <div class="notes">
    <p class="note-title">Note:</p>
    <p class="note-body">
      use <b> v-model:data </b> Get the current slide value
    </p>
  </div>
  <div class="component-example">
    <div class="example-body">
      <hlx-slider
        v-model="data"
        :config="{
          fill: '#54bd95',
          background: '#F0EEEC',
        }"
        :max="200"
        :min="-10"
        @change="change"
      />
      {{ data }}
    </div>
    <div class="example-footer">
      <span
        id="basic-slider-icon"
        class="icon"
        @click="showCode('basic-slider')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-slider" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="slider_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
    <h1 class="component-heading">Minmax slider</h1>
      <p class="component-description">
    To define maximum and minimum level of range in slider use<b> :max</b> &
    <b> :min</b> attributes
  </p>
    <div class="notes">
    <p class="note-title">Note:</p>
    <p class="note-body">
      use <b> v-model:min-value </b> Get the current slide minimum value
    </p>
        <p class="note-body">
      use <b> v-model:max-value </b> Get the current slide maximum value
    </p>
  </div>

    <div class="component-example">
    <div class="example-body">
    <HlxMinMaxSlider
    v-model:min-value="sliderMin"
    v-model:max-value="sliderMax"
        :min="0"
        :max="50000"
        @slidervalue="sliderval"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-slider1-icon"
        class="icon"
        @click="showCode('basic-slider1')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
    <div id="basic-slider1" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="minmaxslider_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  
  
  
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in sliderAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in sliderAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Events</h2>
  <!-- Events -->
  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in slidingEvents"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in slidingEvents"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'button')"
      ><i class="icon-angle-left-regular prev-icon"></i>Button</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'stepper')"
        >Step control <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import HlxMinMaxSlider from '@/components/MinMaxRangeSliderComponent.vue';
import hlxSlider from "../components/SliderComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "SliderView",
  components: {
    HlxMinMaxSlider,
    hlxSlider,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    CodeEditor,
    hlxDivider
  },
  data() {
    return {
      sliderMin:1000,
      sliderMax:14000,
      editor_theme: "light",
      data: "",
      slider_source: ` 
<template>
  <hlx-slider
        :config="{
          fill: '#54bd95',
          background: '#F0EEEC',
        }"
        :max="200"
        :min="-10"
        v-model="data"
        @change="change"
      />
</template>

<script>
export default {
  data(){
    return{
      data:0
    }
  },
  methods: {
    change(data){console.log(data,'from change')},

  },
}
<\/script>

<style>

</style> 
      `,
      minmaxslider_source:`
<template>
      <HlxMinMaxSlider
    v-model:min-value="sliderMin"
    v-model:max-value="sliderMax"
        :min="0"
        :max="50000"
        @slidervalue="sliderval"
      />
</template>

<script>
export default {
  data(){
    return{
      data:0
    }
  },
  methods: {
    sliderval(val1,val2)
        {
            console.log(val1,val2);
        }

  },
}
<\/script>

<style>

</style> `,
      sliderAttributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      slidingEvents: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          name: "v-model",
          description: "Get the current slide value",
          type: "Number",
          accepted_values: "-infinite to infinite",
          default: "-",
          mandatory: false,
        },
        {
          name: "min",
          description: "To specify the minimum level of range",
          type: "Number",
          accepted_values: "0 to -infinite",
          default: "0",
          mandatory: false,
        },
        {
          name: "max",
          description: "To specify the maximum level of range",
          type: "Number",
          accepted_values: "0 to infinite",
          default: "100",
          mandatory: false,
        },
        {
          name: "config",
          description: "To specify the range fill and background color for slider",
          type: "Object",
          accepted_values: `
          {
            fill: "color-code",
            background: "color-code"
          }
          `,
          default: "100",
          mandatory: false,
        },
      ],
      event_data: [
        {
          name: "change",
          description: "emit the event with data while slide change",
          parameter: "slide count",
        },
        {
          name: "slidervalue",
          description: "emit the event with data while slide change in the minmax slider",
          parameter: "slide count",
        }
      ],
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    change() {
      // console.log(d, "from change");
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
