<template>
  <div class="gallery-parent-container">
    <div class="image-container">
      <img :src="selected_img.url" :alt="selected_img.url" />
      <div
        v-if="imageViewType == 'panel' && panelPosition == 'bottom'"
        class="slider-parent"
      >
        <div class="icon">
          <i
            ref="left-icon"
            class="icon-angle-left-regular"
            @click="prevImg()"
          ></i>
        </div>
        <div class="slide-container">
          <img
            v-for="(i, index) in imageData"
            :key="index"
            :src="i.url"
            :alt="i.url"
            :class="'img' + index"
            class="slider-image"
            @click="chooseImg(i, index)"
          />
        </div>
        <div class="icon">
          <i
            ref="right-icon"
            class="icon-angle-right-regular"
            @click="nextImg()"
          ></i>
        </div>
      </div>
      <div
        v-if="imageViewType == 'panel' && panelPosition == 'left'"
        class="slider-parent-left"
      >
        <div class="icon">
          <i
            ref="left-icon"
            class="icon-angle-left-regular"
            @click="prevImg()"
          ></i>
        </div>
        <div class="slide-container">
          <img
            v-for="(i, index) in imageData"
            :key="index"
            :src="i.url"
            :alt="i.url"
            :class="'img' + index"
            class="slider-image"
            @click="chooseImg(i, index)"
          />
        </div>
        <div class="icon">
          <i
            ref="right-icon"
            class="icon-angle-right-regular"
            @click="nextImg()"
          ></i>
        </div>
      </div>
      <div
        v-if="imageViewType == 'panel' && panelPosition == 'right'"
        class="slider-parent-right"
      >
        <div class="icon">
          <i
            ref="left-icon"
            class="icon-angle-left-regular"
            @click="prevImg()"
          ></i>
        </div>
        <div class="slide-container">
          <img
            v-for="(i, index) in imageData"
            :key="index"
            :src="i.url"
            :alt="i.url"
            :class="'img' + index"
            class="slider-image"
            @click="chooseImg(i, index)"
          />
        </div>
        <div class="icon">
          <i
            ref="right-icon"
            class="icon-angle-right-regular"
            @click="nextImg()"
          ></i>
        </div>
      </div>

      <div
        v-if="imageViewType == 'panel' && panelPosition == 'bottom'"
        class="description"
      >
        <div
          class="title"
          :style="[
            selected_img.font_color == 'dark'
              ? { color: '#111' }
              : { color: '#ebebeb' },
          ]"
        >
          {{ selected_img.title }}
        </div>
        <div
          class="sub-title"
          :style="[
            selected_img.font_color == 'dark'
              ? { color: '#111' }
              : { color: '#ebebeb' },
          ]"
        >
          {{ selected_img.description }}
        </div>
      </div>
      <div
        v-if="imageViewType == 'panel' && panelPosition == 'left'"
        class="description-right"
      >
        <div
          class="title"
          :style="[
            selected_img.font_color == 'dark'
              ? { color: '#111' }
              : { color: '#ebebeb' },
          ]"
        >
          {{ selected_img.title }}
        </div>
        <div
          class="sub-title"
          :style="[
            selected_img.font_color == 'dark'
              ? { color: '#111' }
              : { color: '#ebebeb' },
          ]"
        >
          {{ selected_img.description }}
        </div>
      </div>
      <div
        v-if="imageViewType == 'panel' && panelPosition == 'right'"
        class="description-left"
      >
        <div
          class="title"
          :style="[
            selected_img.font_color == 'dark'
              ? { color: '#111' }
              : { color: '#ebebeb' },
          ]"
        >
          {{ selected_img.title }}
        </div>
        <div
          class="sub-title"
          :style="[
            selected_img.font_color == 'dark'
              ? { color: '#111' }
              : { color: '#ebebeb' },
          ]"
        >
          {{ selected_img.description }}
        </div>
      </div>
    </div>
    <div
    v-if="imageViewType == 'dotted pagination' && controlType == 'hover'"
    class="pagination-parent"
    >
      <hlx-dotted-pagination
        :dots="imageData.length"
        @current-page-hover="currentImg"
        @current-page="currentImg"
              />
    </div>
    <div
      v-if="imageViewType == 'dotted pagination' && controlType == 'click'"
      class="pagination-parent"
    >
      <hlx-dotted-pagination
        :dots="imageData.length"
        @current-page="currentImg"
      />
    </div>
  </div>
</template>

<script>
import hlxDottedPagination from "../components/DottedPagination.vue";

export default {
  name: "HlxImageGallery",
  components: {
    hlxDottedPagination,
  },
  props: {
    imageData: {
      type: Array,
      default: () => [],
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    imageViewType: {
      type: String,
      default: "dotted pagination",
    },
    controlType: {
      type: String,
      default: "click",
    },
    panelPosition: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      selected_img: "",
      timeout: "",
    };
  },
  mounted() {
    this.selected_img = this.imageData[0];
    if (this.autoPlay == true) {
      this.autoPlayy();
      setTimeout(() => {
        this.autoPlayy();
      }, 3000 * this.imageData.length - 3000);
    }
    if (this.imageViewType == "panel") {
      document.querySelectorAll(".slider-image").forEach((i) => {
        i.style.opacity = 0.3;
      });

      document.querySelector(
        `.img${this.imageData.indexOf(this.selected_img)}`
      ).style.opacity = 1;
    }
  },
  methods: {
    chooseImg(img, index) {
      this.selected_img = img;
      document.querySelectorAll(".slider-image").forEach((i) => {
        i.style.opacity = 0.3;
      });

      document.querySelector(`.img${index}`).style.opacity = 1;
    },
    nextImg() {
      if (
        this.imageData.indexOf(this.selected_img) + 1 <
        this.imageData.length
      ) {
        this.selected_img =
          this.imageData[this.imageData.indexOf(this.selected_img) + 1];
        document.querySelectorAll(".slider-image").forEach((i) => {
          i.style.opacity = 0.3;
        });
        document.querySelector(
          `.img${this.imageData.indexOf(this.selected_img)}`
        ).style.opacity = 1;

        if (
          this.imageData.indexOf(this.selected_img) + 1 >
          this.imageData.length - 1
        ) {
          this.$refs["right-icon"].style.cursor = "not-allowed";
          this.$refs["left-icon"].style.cursor = "pointer";
        }
      }
    },
    prevImg() {
      if (this.imageData.indexOf(this.selected_img) > 0) {
        this.selected_img =
          this.imageData[this.imageData.indexOf(this.selected_img) - 1];
          
        document.querySelectorAll(".slider-image").forEach((i) => {
          i.style.opacity = 0.3;
        });
        document.querySelector(
          `.img${this.imageData.indexOf(this.selected_img)}`
        ).style.opacity = 1;
        if (this.imageData.indexOf(this.selected_img) <= 0) {
          this.$refs["left-icon"].style.cursor = "not-allowed";
          this.$refs["right-icon"].style.cursor = "pointer";
        }
      }
    },
    autoPlayy() {
      // this.interval = setInterval(()=>{
      for (var i in this.imageData) {
        ((i) => {
          this.timeout = setTimeout(() => {
            this.selected_img = this.imageData[i];
            this.currentImg;
            if (this.imageViewType == "panel") {
              document.querySelectorAll(".slider-image").forEach((i) => {
                i.style.opacity = 0.3;
              });
              if (document.querySelector(`.img${i}`) != undefined) {
                document.querySelector(`.img${i}`).style.opacity = 1;
              }
            }
          }, 3000 * i);
        })(i);
      }
      // },3000*this.imageData.length)
    },
    currentImg(img) {
      this.selected_img = this.imageData[img - 1];
      console.log("iiii")
    },
  },
};
</script>
