<template>
  <h1 class="component-heading">Iconography</h1>
  <div class="component-description">
    On your application, icons make it simpler to identify and find elements.
    Any inline HTML element can receive an icon by adding the icon class name.
    The name of the icon class should now begin with "icon-."
    <h1 class="component-side-heading">Standard font-sizes for icons</h1>

    <ul class="icon-size">
      <li>$icon-xs: 1rem</li>

      <li>$icon-sm: 1.25rem</li>

      <li>var(--hlx-icon-size-md): 1.5rem</li>

      <li>$icon-lr: 2rem</li>

      <li>$icon-xl: 2.25rem</li>
    </ul>
  </div>

  <section class="icon-search-area">
    <span class="search-bar">
      <hlx-search @search-key="search" />
    </span>
  </section>

  <divider :position="'horizontal'" :space="'20px'" />

  <div class="icon-container">
    <div v-for="icon in filterdData" :key="icon" class="icon-card">
      <section class="icon-space">
        <i :class="'icon-' + icon"></i>
      </section>
      <section class="icon-name-space">
        <p class="icon-name">{{ icon }}</p>
        <span @click="copyColorCode($event, icon)"
          ><i class="icon-copy-regular"></i
        ></span>
      </section>
    </div>
  </div>
</template>

<script>
import { icons } from "../../icons";
import divider from "../components/DividerComponent.vue";
import hlxSearch from "../components/SearchComponent.vue";
export default {
  name: "IconographyView",
  components: {
    divider,
    hlxSearch
  },
  data() {
    return {
      searched: "",
      filterdData: []
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    debounceSearch(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    sortAndFilter(filterBy) {
      if (filterBy != "") {
        let tempData = icons.iconsNew
          .sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
          .filter((data) => data.includes(filterBy));
          let i=0;
          let j=0;
          setInterval(() => {
            j=i
            i+=10;
            this.filterdData = this.filterdData.concat(tempData.slice(j,i));
            
          }, 600);
        return 
      }
      let i=0;
          let j=0;
      setInterval(() => {
            j=i
            i+=10;
            this.filterdData = this.filterdData.concat(icons.iconsNew.slice(j,i));
            
          }, 600);
      // this.filterdData = icons.iconsNew;
    },
    search(data) {
      if (!data) {
        let i=0;
          let j=0;
      setInterval(() => {
            j=i
            i+=10;
            this.filterdData = this.filterdData.concat(icons.iconsNew.slice(j,i));
            
          }, 700);
        return;
      }
      this.debounceSearch(() => this.sortAndFilter(data?.trim()))();
    },
    copyColorCode($event, icon_name) {
      // const Text = $event.target.textContent
      $event.target.classList.add("copied");
      navigator.clipboard.writeText("icon-" + icon_name);
      setTimeout(() => {
        $event.target.classList.remove("copied");
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/var.scss";

.icon-search-area {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  align-content: flex-end;
  flex-direction: column;
  .search-bar {
    max-width: 300px;
    min-width: 300px;
  }
  //margin-left: 70%;
}
.icon-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 72px;

  .icon-card {
    width: 150px;
    height: 150px;
    //box-shadow: 1px 12px 24px -18px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
    display: flex;
    flex-direction: column;
    background: #f6f6f6;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $primary-color;
      cursor: pointer;
      transform: scale(1.2);
      z-index: 500;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      box-shadow: 0px 7px 34px -20px;
      border-radius: 7%;
      .icon-space > i {
        color: white;
      }
    }
    .icon-space {
      width: 100%;
      height: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      i {
        font-size: 30px;
        color: rgb(107, 107, 107);
      }
    }
    .icon-name-space {
      background: white;
      width: 100%;
      height: 25%;
      width: 100%;
      font-size: 12px;
      position: relative;
      padding: 5px;
      display: flex;
      align-items: center;

      justify-content: space-between;

      i {
        margin-left: 5px;
        font-size: 14px;
      }
      .icon-name {
        margin: 0;
        text-overflow: ellipsis;
        font-family: "OpenSans" !important;
        /* height: 28px;
        <!-- max-width: 78px; -->
        
        
         */
      }
      span {
        position: relative;
        width: 25%;
        &:before {
          content: "";
          display: none;
          position: absolute;
          z-index: 9998;
          top: 35px;
          width: 0;
          left: 50%;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid rgba(0, 0, 0, 0.72);
        }
        &:after {
          content: "Copy to Clipboard";
          display: none;
          position: absolute;
          z-index: 9999;
          top: 40px;
          left: -40px;
          width: 114px;
          height: 36px;
          color: #fff;
          font-size: 10px;
          line-height: 36px;
          text-align: center;
          background: rgba(0, 0, 0, 0.72);
          border-radius: 3px;
        }
        &:hover {
          &:before,
          &:after {
            display: block;
          }
        }
        &:active,
        &:focus {
          outline: none;

          &:after {
            content: "Copied!";
          }
        }
      }
    }
  }
}
</style>
