<template>
  <h1 class="component-heading">Visual rule builder</h1>

  <p class="component-description">
    Provides visualization of the rule or expression to simplify the creation of
    the business logic.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <!-- <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    To switch between viewing multiple/single expanded item, change the value to
    true/false in builder attribute.
  </p> -->
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    Choose the type of your business logic (rule / expression) using the
    <b>:type</b> attribute.
  </p>
  <div class="component-example">
    <div class="example-body">
      <!-- <hlx-visual-rule-builder :type="'expression'" :lhs-data="this.lhs_data" :rhs-data="this.rhs_data" :op-data="this.op_data" @emitExp="emitExp"/> -->
      <hlx-visual-rule-builder
        :type="'rule'"
        :lhs-data="lhs_data"
        :rhs-data="rhs_data"
        :op-data="op_data"
        :intellisense-data="list"
        :if-obj="if_obj"
        :then-obj="then_obj"
        :else-obj="else_obj"
        @emit-rule="emitRule"
        @emit-obj="emitObj"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-builder-icon"
        class="icon"
        @click="showCode('basic-builder')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-builder" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="builder_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table-v2
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table-v2>
  <h2 class="component-side-heading">Events</h2>
  <hlx-table-v2
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table-v2>
</template>

<script>
import hlxVisualRuleBuilder from "../components/VisualRuleBuilderComponent.vue";
import hlxTableV2 from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";

import divider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";

export default {
  components: {
    hlxTableCell,
    hlxTableHead,
    hlxTableV2,
    hlxVisualRuleBuilder,
    divider,
    CodeEditor,
  },
  data() {
    return {
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: "600",
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: "200",
          type: "string",
          format: "",
        },
      ],
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: "50",
          type: "string",
          format: "",
        },
      ],
      switch_items: [
        { name: "value", checked: true },
        { name: "Expression", checked: false },
      ],
      editor_theme: "light",

      if_obj: {
        level1: {
          level_conjunction: "or",
          groups: [
            {
              group_conjunction: "and",
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: [{ id: 221, lhs: "a", op: ">", rhs: 10 }],
                },
              ],
            },
            {
              group_conjunction: "and",
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: [{ id: 142, lhs: "b", op: "<", rhs: 20 }],
                },
              ],
            },
          ],
        },
        level2: {
          level_conjunction: "and",
          groups: [
            {
              group_conjunction: "and",
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: [{ id: 646, lhs: "c", op: "=", rhs: 30 }],
                },
              ],
            },
          ],
        },
        level3: {
          level_conjunction: "and",
          groups: [
            {
              group_conjunction: "and",
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: [{ id: 113, lhs: "a", op: ">", rhs: 10 }],
                },
              ],
            },
          ],
        },
        level4: { level_conjunction: "and", groups: [] },
        level5: { level_conjunction: "and", groups: [] },
      },

      list: {
        schema: [
          { label: "quote.agreement", type: "string" },
          { label: "quote.attachment", type: "string" },
          { label: "quote.quoteDate", type: "date" },
          { label: "quote.quoteItem", type: "Array" },
        ],
        keywords: [
          { label: "if" },
          { label: "then" },
          { label: "else" },
          { label: "and" },
          { label: "or" },
          { label: "function" },
          { label: "filter" },
          { label: "reduce" },
          { label: "map" },
          { label: "Date" },
          { label: "Math" },
          { label: "slice" },
          { label: "substr" },
          { label: "concat" },
          { label: "true" },
          { label: "false" },
        ],
        methods: {
          array: [
            {
              label: "filter",
              syntax:
                "array.filter(function(currentValue, index, arr), thisValue)",
            },
            {
              label: "reduce",
              syntax:
                "array.reduce(function(total, currentValue, currentIndex, arr), initialValue)",
            },
            {
              label: "map",
              syntax:
                "array.map(function(currentValue, index, arr), thisValue)",
            },
          ],
          date: [
            {
              label: "getDay",
              syntax: "getDay()",
            },
            {
              label: "getDate",
              syntax: "getDate()",
            },
            {
              label: "getTime",
              syntax: "getTime()",
            },
            {
              label: "getMonth",
              syntax: "getMonth()",
            },
            {
              label: "getFullYear",
              syntax: "getFullYear()",
            },
            {
              label: "setDate",
              syntax: "setDate()",
            },
            {
              label: "setTime",
              syntax: "setTime()",
            },
            {
              label: "setMonth",
              syntax: "setMonth()",
            },
            {
              label: "setFullYear",
              syntax: "setFullYear()",
            },
          ],
          integer: [
            {
              label: "random",
              syntax: "Math.random()",
            },
            {
              label: "round",
              syntax: "Math.round(x)",
            },
          ],
          string: [
            { label: "charAt", syntax: "string.charAt(index)" },
            { label: "slice", syntax: "string.slice(start, end)" },
          ],
        },
      },
      sample: {
        level1: {
          level_arr: [
            {
              level2: [
                {
                  arr: [{ obj: "habibi" }, { obj: "come to dubai" }],
                },
              ],
            },
            {
              level3: [
                {
                  arr: [{ obj: "habibi" }, { obj: "come to dubai" }],
                },
              ],
            },
          ],
        },
      },
      table_data: [
        {
          name: "lhs_data",
          description: "Provide data for LHS part of your expression.",
          type: "Array",
          accepted_values: "[]",
          default: "-",
          mandatory: true,
        },
        {
          name: "op_data",
          description: "Provide data for operator part of your expression.",
          type: "Array",
          accepted_values: "[]",
          default: "-",
          mandatory: true,
        },
        {
          name: "rhs_data",
          description: "Provide data for RHS part of your expression.",
          type: "Array",
          accepted_values: "[]",
          default: "-",
          mandatory: true,
        },
        {
          name: "type",
          description: "Choose the type of business logic",
          type: "String",
          accepted_values: "rule / expression",
          default: "rule",
          mandatory: false,
        },
      ],
      lhs_data: [
        {
          name: "a",
          value: "a",
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
        {
          name: "b",
          value: "b",
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
        {
          name: "c",
          value: "c",
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
      ],
      rhs_data: [
        {
          name: 10,
          value: 10,
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
        {
          name: 20,
          value: 20,
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
        {
          name: 30,
          value: 30,
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
      ],
      op_data: [
        {
          name: ">",
          value: ">",
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
        {
          name: "<",
          value: "<",
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
        {
          name: "=",
          value: "=",
          checked: false,
          disabled: false,
          icon: "icon-database-regular",
        },
      ],
      event_data: [
        {
          name: "emitRule",
          description: "Emits the rule in string format",
          parameters: "-",
        },
        {
          name: "emitExp",
          description: "Emits the expression in string format",
          parameters: "-",
        },
        {
          name: "emitObj",
          description: "Emits the rule in JSON format",
          parameters: "-",
        },
      ],
      builder_source: `
<template>
  <hlx-visual-rule-builder :type="'rule'" :lhs-data="this.lhs_data" :rhs-data="this.rhs_data" :op-data="this.op_data" @emit-rule="emitRule"/>
</template>

<script>
export default{
    data(){
        return{
          lhs_data: [
        { name: "a", value: "a", checked: false, disabled: false },
        { name: "b", value: "b", checked: false, disabled: false },
        { name: "c", value: "c", checked: false, disabled: false },
      ],
      rhs_data: [
        { name: 10, value: 10, checked: false, disabled: false },
        { name: 20, value: 20, checked: false, disabled: false },
        { name: 30, value: 30, checked: false, disabled: false },
      ],
      op_data: [
        { name: ">", value: ">", checked: false, disabled: false },
        { name: "<", value: "<", checked: false, disabled: false },
        { name: "=", value: "=", checked: false, disabled: false },
      ],
        }
    }
}
}
</\script>

<style>
.acc-container{
    display: flex;
    flex-direction: column;
    gap:0 !important;
    width: 100%;
    border: 1px solid #d8d8d8;
    border-bottom: 0;
    border-radius: 5px;
  }
</\style>`,
    };
  },

  mounted() {
    localStorage.removeItem("if_obj");
    localStorage.setItem("my_obj", JSON.stringify(this.my_obj));
    this.my_obj_dummy = this.my_obj;
    // console.log(JSON.parse(localStorage.getItem('my_obj')), "myobj");
  },
  methods: {
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    click() {
      console.log(this.my_obj, "click");
    },
  },
};
</script>
