<template>
  <div v-show="title == selectedTitle" class="tab-content">
    <slot />
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "HlxTabPane",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup() {
    const selectedTitle = inject("selectedTitle");

    return {
      selectedTitle,
    };
  },
};
</script>

<style scoped></style>
