<template>
  <h1 class="component-heading">Feature Input Component</h1>
  <p class="component-description">
    <b>Feature Input Component</b> the input component use select the single
    option
  </p>

  <h2 class="component-side-heading">Rectangle select</h2>
  <p class="component-description">
    To specify input for the <b>:options</b> <br />
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-rectangle-select
        :options="optionRectangle"
        :pre-value="rectangle"
        :showEllipsis="false"
        @selectedOption="selected"
      />
    </div>
    <div class="example-footer">
      <span
        id="feature-input-icon"
        class="icon"
        @click="showCode('feature-input')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="feature-input" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="feature_input"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Border Radio Button</h2>
  <p class="component-description">
    To specify input for the <b>:options</b> <br />
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-input :options="options4" :type="'border-radio-buttons'" />
    </div>
    <div class="example-footer">
      <span
        id="border-radio-buttons-icon"
        class="icon"
        @click="showCode('border-radio-buttons')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
<div id="border-radio-buttons" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="border_radio_buttons"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in treeAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in treeAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'tabs')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tab</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'speeddial')"
      >Speed dial <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>
<script>
import HlxRectangleSelect from "../components/RectangleSelectComponent.vue";
import HlxInput from "../components/InputComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxButton from "../components/ButtonComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";

export default {
  name: "TreeView",
  components: {
    HlxRectangleSelect,
    HlxInput,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxButton,
    CodeEditor,
    hlxDivider,
  },
  data() {
    return {
      editor_theme: "light",
      feature_input: `
<template>
<hlx-rectangle-select
        :options="optionRectangle"
        :pre-value="rectangle"
        :showEllipsis="false"
        @selectedOption="selected"
/>
 
</template>

<script>
export default {
data(){
  return{
    optionRectangle: ["Ragavi", "Pooja", "Feroz", "Ganesh", "Vishnu"],
  }
}
}
</\script>`,

border_radio_buttons:`
<template>
<hlx-input 
       :options="options4" 
       :type="'border-radio-buttons'
/>
</template>

<script>
export default {
data(){
  return{
   options4: [
        {
          value: "Racheal",
          name: "Racheal",
          checked: true,
          disabled: true,
          id: 1,
        },
        { value: "Ross", name: "Ross", checked: false, disabled: true, id: 2 },
        {
          value: "Monica",
          name: "Monica",
          checked: false,
          disabled: true,
          id: 3,
        },
        {
          value: "Chandler",
          name: "Chandler",
          checked: false,
          disabled: true,
          id: 4,
        },
        { value: "Joey", name: "Joey", checked: false, disabled: true, id: 5 },
        {
          value: "Phoebe",
          name: "Phoebe",
          checked: false,
          disabled: true,
          id: 6,
        },
      ],
  }
}
}
</\script>`,
      options4: [
        {
          value: "Racheal",
          name: "Racheal",
          checked: true,
          disabled: true,
          id: 1,
        },
        { value: "Ross", name: "Ross", checked: false, disabled: true, id: 2 },
        {
          value: "Monica",
          name: "Monica",
          checked: false,
          disabled: true,
          id: 3,
        },
        {
          value: "Chandler",
          name: "Chandler",
          checked: false,
          disabled: true,
          id: 4,
        },
        { value: "Joey", name: "Joey", checked: false, disabled: true, id: 5 },
        {
          value: "Phoebe",
          name: "Phoebe",
          checked: false,
          disabled: true,
          id: 6,
        },
      ],
      optionRectangle: [{name:"Ragavi",value:"Ragavi"},{name:"Pooja",value:"Pooja"},{name:"Feroz",value:"Feroz"},{name:"Ganesh",value:"Ganesh"},{name:"Vishnu",value:"Vishnu"} ],
      event_data: [
        {
          name: "selectedOption",
          description: "Return the selected values",
          parameter: "",
        },
        {
          name: "at-input",
          description: "Triggers when the input value is changed",
          parameter: "",
        },
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          attribute: "options",
          description:
            "Pass the list of items to be added in the list in Rectangle Select",
          type: "Array",
          accepted_values: "Array",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "options",
          description:
            "Pass the list of items to be added in the list in Border Radio Button",
          type: "Array of object",
          accepted_values: "Array of object ",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "type",
          description: "Pass the value of input type",
          type: "String",
          accepted_values: "border-radio-buttons",
          default: "-",
          mandatory: true,
        },
        {
          attribute: "showEllipsis",
          description: "Length of the rectangle select ",
          type: "Boolean",
          accepted_values: "true,false",
          default: "false",
          mandatory: false,
        },
        {
          attribute: "pre-value",
          description: "Used to pre-populate the daate field",
          type: "String / Number",
          accepted_values: "String / Number",
          default: "-",
          mandatory: false,
        },
      ],
      treeAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
    };
  },
  methods: {
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
