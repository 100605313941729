<template>
  <div
    :id="'sidenav-margin' + unique"
    :class="'sidenav-margin-' + position"
    @click="closeDrawer"
  ></div>

  <div :id="'mySidenav' + unique" :class="'sidenav-' + position">
    <span v-if="position === 'right' && showCloseIcon === true"
      style="
        display: flex;
        width: 25px;
        height: 25px;
        position: fixed;
        background: white;
        z-index: 1000;
        top: 0;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: -2px 0px 6px 0px #b9b6b6;
        cursor: pointer;
      "
      :style="{ right: width + 'px' }"
      @click="closeDrawer"
    >
      <i class="icon-times-regular"></i>
    </span>
    <div v-if="header === true" :class="'header-panel-' + position">
      <span v-if="title !== undefined" :class="'title-' + position">
        {{ title }}
      </span>
      <span v-else :class="'title-' + position">
        <header>
          <slot name="header">Drawer title</slot>
        </header>
      </span>
      <span :class="'drawer-close-' + position" @click="closeDrawer">
        <i class="icon-cross"></i>
      </span>
    </div>
    <main :class="'body-panel-' + position">
      <slot name="body">Drawer body</slot>
    </main>
    <footer v-if="footer === true" :class="'footer-panel-' + position">
      <slot name="footer">Drawer footer</slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HlxDrawer",
  props: {
    title: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "",
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["close"],
  data() {
    return {
      unique: Math.floor(Math.random() * 100),
    };
  },
  watch: {
    show: {
      handler(val) {
        this.$nextTick(() => {
          if (
            val === true &&
            document.getElementById("mySidenav" + this.unique) !== null &&
            document.getElementById("mySidenav" + this.unique) !== undefined
          ) {
            if (this.position === "left" || this.position === "right") {
              document.getElementById("mySidenav" + this.unique).style.display =
                "block";
              document.getElementById("mySidenav" + this.unique).style[
                this.position
              ] = "0px";
              document.getElementById("sidenav-margin" + this.unique).style[
                this.position
              ] = this.width + "px";
              document.getElementById(
                "sidenav-margin" + this.unique
              ).style.width = "100%";
            } else if (this.position === "top" || this.position === "bottom") {
              document.getElementById("mySidenav" + this.unique).style[
                this.position
              ] = "0px";
              document.getElementById("sidenav-margin" + this.unique).style[
                this.position
              ] = this.height + "px";
              document.getElementById(
                "sidenav-margin" + this.unique
              ).style.height = "100%";
              document.getElementById(
                "sidenav-margin" + this.unique
              ).style.width = "100%";
            }

            if (!this.header) {
              this.$nextTick(() => {
                document
                  .getElementById("mySidenav" + this.unique)
                  .style.setProperty("height", "100vh", "important");
                let e = document.getElementsByClassName(
                  "body-panel-" + this.position
                );
                e[0].style.setProperty("height", "91vh", "important");
              });
            }
          } else if (
            val === false &&
            document.getElementById("mySidenav" + this.unique) !== null &&
            document.getElementById("mySidenav" + this.unique) !== undefined
          ) {
            if (this.position === "left" || this.position === "right") {
              document.getElementById("mySidenav" + this.unique).style.display =
                "none";
              document.getElementById("mySidenav" + this.unique).style[
                this.position
              ] = "0px";
              document.getElementById("sidenav-margin" + this.unique).style[
                this.position
              ] = "0px";
              document.getElementById(
                "sidenav-margin" + this.unique
              ).style.width = "0%";
            }
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // console.log(this.title)
    if (this.position === "left" || this.position === "right") {
      document.getElementById("mySidenav" + this.unique).style[this.position] =
        "-" + this.width + "px";
      document.getElementById("mySidenav" + this.unique).style.width =
        this.width + "px";
    } else if (this.position === "top" || this.position === "bottom") {
      document.getElementById("mySidenav" + this.unique).style[this.position] =
        "-" + this.height + "px";
      document.getElementById("mySidenav" + this.unique).style.height =
        this.height + "px";
    }
  },
  methods: {
    closeDrawer() {
      this.closeNav();
      this.$emit("close");
    },
    closeNav() {
      if (this.position === "left" || this.position === "right") {
        document.getElementById("mySidenav" + this.unique).style[
          this.position
        ] = "-" + this.width + "px";
        document.getElementById("sidenav-margin" + this.unique).style.width =
          "0";
      } else if (this.position === "top" || this.position === "bottom") {
        document.getElementById("mySidenav" + this.unique).style[
          this.position
        ] = "-" + this.height + "px";
        document.getElementById("sidenav-margin" + this.unique).style.height =
          "0";
        document.getElementById("sidenav-margin" + this.unique).style.width =
          "0";
      }
    },
  },
};
</script>

<style lang="scss">
.drawer-close-left,
.drawer-close-right,
.drawer-close-top,
.drawer-close-bottom {
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
