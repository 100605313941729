<template>
  <!-- <div class="accordion-container"> -->
  <div
    :id="['item-' + unique]"
    :key="unique"
    class="accordion-item"
    :multiple="multiple"
  >
    <div
      :id="['header-' + unique]"
      class="accordion-header"
      @click="toggleView($event,unique, multiple)"
    >
      <div v-if="direction == 'left'" style="display:flex">
        <i
          :id="['arrow-' + unique]"
          class="right icon-angle-right-regular"
        ></i>
      </div>
      <header style="width:100%">
        <slot name="item-title"></slot>
      </header>
      <div v-if="direction == 'right'" style="display:flex" >
        <i
          :id="['arrow-' + unique]"
          class="right icon-angle-right-regular"
        ></i>
      </div>
    </div>

    <div :id="['content-' + unique]" class="accordion-content">
      <slot name="item-body"></slot>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "HlxAccordion",
  props: {
    accordionData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    direction:{
      type: String,
      default: 'right'
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    headerBorder: {
      type: Boolean,
      default: false,
    },
  },
  emits:['accordionopen','accordionclose'],
  data() {
    return {
      unique: Math.floor(Math.random() * 1000 + 1),
    };
  },
  mounted(){
    if(this.expand){
      document.querySelectorAll('.accordion-item')?.forEach((i)=>{
        i?.children[0]?.children[1]?.children[0]?.classList?.remove('icon-angle-right-regular')
              i?.children[0]?.children[1]?.children[0]?.classList?.add('icon-angle-down-regular');
              i.children[1].style.display = 'flex'
                    this.$emit('accordionopen')
      })
    }
  },
  methods: {
    toggleView(event,index, multiple) {
          // console.log("here");
      if (multiple == false) {
        if(document.querySelector('#arrow-'+index).className === 'right icon-angle-right-regular')
        {
            if(this.headerBorder == false){
              document.querySelectorAll('.accordion-header').forEach((r)=>{
                r.classList.remove('noo-border')
              })
              if(document.querySelector("#header-"+index)){
                document.querySelector("#header-"+index).classList.add('noo-border')
              }
            }
            document.querySelectorAll('.icon-angle-down-regular').forEach((e)=>{
              // console.log(e.className);
              if(e.className == 'right icon-angle-down-regular')
              {
                // console.log(e.style.display);
                document.querySelectorAll('.accordion-content').forEach((e)=>{
                  e.style.display = 'none'
                })
                e.classList.remove('icon-angle-down-regular')
                e.classList.add('icon-angle-right-regular')
              }
            })
            document.querySelector('#arrow-'+index).classList.remove('icon-angle-right-regular')
            document.querySelector('#arrow-'+index).classList.add('icon-angle-down-regular')
            document.querySelector('#content-'+index).style.display = 'flex'
                  this.$emit('accordionopen')

          }
          else if(document.querySelector('#arrow-'+index).className === 'right icon-angle-down-regular'){
            if(this.headerBorder == false){
              document.querySelectorAll('.accordion-header').forEach((r)=>{
                r.classList.remove('noo-border')
              })
              if(document.querySelector("#header-"+index)){
                document.querySelector("#header-"+index).classList.add('noo-border')
              }
            }
            // console.log(document.querySelector('#arrow-'+index).classList,"iiiiiiii")
            document.querySelector('#arrow-'+index).classList.remove('icon-angle-down-regular')
            document.querySelector('#arrow-'+index).classList.add('icon-angle-right-regular')
            document.querySelector('#content-'+index).style.display = 'none'
            this.$emit('accordionclose')

          }
        // let content = document.querySelector(`#content-${index}`);
        // let arrow = document.querySelector(`#arrow-${index}`);
        // document.querySelectorAll(".accordion-header").forEach((b) => {
        //   b.classList.remove("bg");
        // });
        // document
        //   .querySelector(`#arrow-${index}`)
        //   .parentElement.parentElement.classList.add("bg");

        // if (arrow.classList.contains("right")) {
        //   document
        //     .querySelectorAll(".icon-angle-down-regular .right")
        //     .forEach((arrowdown) => {
        //       arrowdown.classList.remove("icon-angle-down-regular");
        //       arrowdown.classList.add("icon-angle-right-regular");
        //     });
        //   arrow.classList.remove("icon-angle-right-regular");
        //   arrow.classList.add("icon-angle-down-regular");
        //   document.querySelectorAll(".accordion-content").forEach((item) => {
        //     item.style.display = "none";
        //   });
        //   content.style.display = "flex";
        // } else if (arrow.classList.contains("icon-angle-down-regular right")) {
        //   console.log("yyy",arrow);
        //   document.querySelectorAll(".accordion-header").forEach((b) => {
        //     b.classList.remove("bg");
        //   });
        //   document.querySelectorAll(".accordion-content").forEach((item) => {
        //     item.style.display = "none";
        //   });
        //   arrow.classList.remove("icon-angle-down-regular");
        //   arrow.classList.add("icon-angle-right-regular");
        //   content.style.display = "none";
        // }
      } else {
        // console.log("kii")
    
        if(document.querySelector('#arrow-'+index).className === 'right icon-angle-right-regular')
        {
            // console.log(event.target,'event');
            if(this.headerBorder == false){
              // document.querySelectorAll('.accordion-header').forEach((r)=>{
              //   r.classList.remove('noo-border')
              // })
              if(document.querySelector("#header-"+index)){
                document.querySelector("#header-"+index).classList.add('noo-border')
              }
            }
  
            document.querySelector('#arrow-'+index).classList.remove('icon-angle-right-regular')
            document.querySelector('#arrow-'+index).classList.add('icon-angle-down-regular')
            document.querySelector('#content-'+index).style.display = 'flex'
            this.$emit('accordionopen')

          }
          else if(document.querySelector('#arrow-'+index).className === 'right icon-angle-down-regular'){
            if(document.querySelector("#header-"+index)){
                document.querySelector("#header-"+index).classList.remove('noo-border')
              }
            document.querySelector('#arrow-'+index).classList.remove('icon-angle-down-regular')
            document.querySelector('#arrow-'+index).classList.add('icon-angle-right-regular')
            document.querySelector('#content-'+index).style.display = 'none'
            this.$emit('accordionclose')
          }
          // let content = document.querySelector(`#content-${index}`);
          // let arrow = document.querySelector(`#arrow-${index}`);
          // document.querySelector('#arrow-'+index)
          
          
          // if (arrow.classList.contains("icon-angle-right-regular right")) {
            //   document
        //     .querySelector(`#arrow-${index}`)
        //     .parentElement.parentElement.classList.add("bg");

        //   arrow.classList.remove("icon-angle-right-regular");
        //   arrow.classList.add("icon-angle-down-regular");
        //   content.style.display = "flex";
        // } else if (arrow.classList.contains("icon-angle-down-regular right")) {
        //   document
        //     .querySelector(`#arrow-${index}`)
        //     .parentElement.parentElement.classList.remove("bg");

        //   arrow.classList.remove("icon-angle-down-regular");
        //   arrow.classList.add("icon-angle-right-regular");
        //   content.style.display = "none";
        // }
      }

      // console.log(index,"ooooo")
    },
  },
};
</script>
