<template>
  <br />
  <!-- <hlx-select
                    v-model="prevalue"
                    :options="dropdown_data1"
                    :placeholder-value="'Language'"
                    :label="'name'"
                    :prop-value="'value'"
                    :label-animation="false"
                    :inline-search="true"
                    @selected-value="setvalue1"
                    @custom-error-emit-value="checkval1"
                  >
                  </hlx-select> -->
  <hlx-date-picker
    :pre-value="receivedData1"
    from-key="DateTime"
    :label-animation="true"
    :read-only="false"
    :label-value="'Pick a date and time'"
    :custom-background="'linear-gradient(181deg, #f7f7f7)'"
    :top="130"
    :left="320"
    icon="calendar-regular"
    icon-position="right"
    required="true"
    @selected-range="handleCurrentDate"
  ></hlx-date-picker>
  <!-- is-label -->
  <!--  -->
</template>

<script>
import HlxDatePicker from "../components/DateAndTimePickerComponent.vue";
// import hlxSelect from "../components/SelectComponent.vue";

export default {
  components: {
    HlxDatePicker,
    // hlxSelect,
  },
  data() {
    return {
      receivedData1: {},
      dropdown_data1: [
        {
          name: "maara",
        },
        {
          name: "maara",
        },
        {
          name: "maara",
        },
        {
          name: "maara",
        },
        {
          name: "maara",
        },
      ],
    };
  },
  mounted() {
    this.receivedData1 = {
      DateTime: new Date("Dec 25, 2002 7:24 AM"),
    };
  },
  methods: {
    handleCurrentDate(date) {
      console.log(
        "🚀 ~ file: datePickerView.vue:61 ~ handleCurrentDate ~ date:",
        date
      );
    },
  },
};
</script>
