<template>
  <span
    :id="'hlx-tag-' + Math.floor(Math.random() * 1000 + 1)"
    ref="hlx-tag"
    class="hlx-tag"
  >
    <span :class="{ 'content': !limited, 'contentfull': limited }">
      <slot></slot>
    </span>
    <i class="icon-times-regular normal" @click="removeTag"></i>
  </span>
</template>

<script>
export default {
  props: {
    limited: {
      type: Boolean,
      default: false,
    },
  },
  name: "HlxTag",
  emits: ["tag-close"],
  methods: {
    removeTag($event) {
      let tag = this.$refs["hlx-tag"];
      // let tag = $event.target.parentElement;
      console.log(tag, "taggg");
      if (tag) {
        // tag.remove();
        this.$emit("tag-close", $event);
      }
    },
  },
};
</script>
