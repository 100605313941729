<template>
  <h1 class="component-heading">File upload</h1>
  <p class="component-description">
    File uploading is a facility for accepting and managing user files,
    including photos, videos, PDFs, and other documents.
  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <!-- <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    Specify the type of file and file size limit using the <b>:file_type</b> and
    <b>:file_size_limit</b> attributes respectively.
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-file-upload
        :file-type="'.xlsx,.png,.json'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'button'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        :preset="file_data"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-file-upload-icon"
        class="icon"
        @click="showCode('basic-file-upload')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-file-upload2" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="file_upload_source"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div> -->

  <!-- <h2 class="component-side-heading">Example</h2>
  <p class="component-description">
    Specify the type of upload bys setting the<b> :type </b>attribute to
    'button' or 'regular'.
  </p>
  <div class="component-example">
    <div class="example-body">
      <hlx-file-upload
        :file-type="'.xlsx,.png,.json'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'regular'"
      />
    </div>
    <div class="example-footer">
      <span
        id="basic-file-upload-icon2"
        class="icon"
        @click="showCode('basic-file-upload2')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-file-upload2" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="file_upload_source2"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div> -->

  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    Specify the type of upload by setting the<b> :type </b>attribute to 'button'
    or 'regular'.<br />
    Specify the type of file and file size limit using the <b>:file-type</b> and
    <b>:file-size-limit</b> attributes respectively.
  </p>
  <div class="component-example">
    <div class="example-body">
      <!-- <hlx-file-upload
        :file-type="'.xlsx,.png,.json'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'upc-regular-sm-v2'"
      /> -->
      <hlx-file-upload
        :file-type="'.jpg,.jpeg,.png'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'upc-regular'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        @delete-image="func"
        @download-image="func1"
      />
      <!-- <hlx-file-upload
        :file-type="'.xlsx,.png,.json,.pdf'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'upc-link-button-v2'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Add attachment'"
        />  -->
      <!-- :presetFiles="fileItems"  -->
    </div>
    <div class="example-footer">
      <span
        id="basic-file-upload-icon2"
        class="icon"
        @click="showCode('basic-file-upload2')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-file-upload2" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="file_upload_source2"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <div id="basic-tree" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_tree"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Muti File Upload</h2>
  <p class="component-description">
    Specify the type of file and file size limit using the <b>:file-type</b> and
    <b>:file-size-limit</b> attributes respectively.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <multiUploadComponentVue
        :type="'muliti-upload-drawer'"
        :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
        :file-size-limit="'1mb'"
        :upload-button-name="'Attachment'"
        @uplodedFiles="getvaluearr"
      ></multiUploadComponentVue>

      <hlx-divider :position="'horizontal'" :space="'20px'" />

      <multiUploadComponentVue
        :type="'single-line-multi-upload'"
        :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
        :file-size-limit="'1GB'"
        @SingleuplodedFiles="getvaluearrsingle"
      ></multiUploadComponentVue>
    </div>
    <div class="example-footer">
      <span
        id="Muti-File-Upload-icon"
        class="icon"
        @click="showCode('Muti-File-Upload')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="Muti-File-Upload" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="Muti_File_Upload"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Profile Upload</h2>
  <p class="component-description">
    Specify the type of file and file size limit using the <b>:file-type</b> and
    <b>:file-size-limit</b> attributes respectively.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-file-upload
        :file-type="'.jpg,.jpeg,.png'"
        :file-size-limit="'20mb'"
        :uploadtype="'Rectangle'"
        :style-background="backgrounStyleupload"
        :height="50"
        :width="50"
        :read-only="false"
        :custom-icon="icon"
        :type="'profile-upload'"
        :name-display="nameDisplay"
        :content="'Import'"
        :profile-pic-preview="'data:image/png;base64,PD94bWwgdmVyc2lvbj0nMS4wJyBlbmNvZGluZz0nVVRGLTgnPz48RXJyb3I+PENvZGU+Tm9TdWNoS2V5PC9Db2RlPjxNZXNzYWdlPlRoZSBzcGVjaWZpZWQga2V5IGRvZXMgbm90IGV4aXN0LjwvTWVzc2FnZT48RGV0YWlscz5ObyBzdWNoIG9iamVjdDogc3RvcmFnZWhhbGxleXgvdW5kZWZpbmVkPC9EZXRhaWxzPjwvRXJyb3I+'"
        @fileData="profiledata"
      />
    </div>
    <div class="example-footer">
      <span
        id="profile-upload-icon"
        class="icon"
        @click="showCode('profile-upload')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="profile-upload" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="profile_upload"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">slide Upload</h2>
  <p class="component-description">
    customizable and capable to display images as slideshow with controls.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-file-upload
        :type="'slide-upload'"
        :width="'100px'"
        :height="'100px'"
        :position="'horizontal'"
        :slider-options="slideroption"
        @native-data="dataevent"
        @default-image="defaltImg"
        @deleteImage="deleteImage"
      />
      <hlx-file-upload
        :type="'slide-preview-upload'"
        :options="slideroption"
        :parent-height="'300px'"
        :parent-width="'300px'"
        :width="'100px'"
        :height="'100px'"
        :position="'vertical'"
        @selected-value="selecImg"
        @hovered-value="hoverImg"
      />
    </div>
    <div class="example-footer">
      <span
        id="slide-upload-icon"
        class="icon"
        @click="showCode('slide-upload')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>

  <div id="slide-upload" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="sliderUpload"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!-- <hlxTable :data="this.fileuploadAttributes"
    :border="true"
    theme="secondary"
    :rowHover="true"
    :ellipses="5"
    :bold_header="true">
    <hlx-column :prop="'name'" :label="'Name'" :type="'string'" />
    <hlx-column :prop="'description'" :label="'Description'" :type="'string'" />
    <hlx-column :prop="'default'" :label="'Default'" :type="'string'" />
    <hlx-column :prop="'type'" :label="'Type'" :type="'string'" :width="200"/>
    <hlx-column :prop="'accepted_values'" :label="'Accepted values'" :type="'string'"/>
    <hlx-column :prop="'mandatory'" :label="'Mandatory'" :type="'string'" />
</hlxTable> -->
  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!-- <hlx-table
    :data="this.fileuploadEvents"
    :border="true"
    theme="secondary"
    :rowHover="true"
    :bold_header="true"
  >
    <hlx-column :prop="'Name'" :label="'Name'" :type="'string'" />
    <hlx-column :prop="'Description'" :label="'Description'" :type="'string'" :width="400"/>
    <hlx-column :prop="'Parameters'" :label="'Parameters'" :type="'string'" />
  </hlx-table> -->
</template>

<script>
import hlxFileUpload from "../components/FileUploadComponent.vue";
import multiUploadComponentVue from "@/components/multiUploadComponent.vue";
import HlxDivider from "../components/DividerComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";

import divider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";

export default {
  name: "App",
  components: {
    multiUploadComponentVue,
    hlxFileUpload,
    HlxDivider,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    divider,
    CodeEditor,
  },
  data() {
    return {
      Muti_File_Upload: `
<template>
   <hlx-multi-upload
        @uplodedFiles="getvaluearr"
        :type="'muliti-upload-drawer'"
        :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
        :file-size-limit="'1GB'"
      ></hlx-multi-upload>

 <hlx-divider :position="'horizontal'" :space="'20px'" />

      <hlx-multi-upload
      :type="'single-line-multi-upload'"
        @uplodedFiles="getvaluearr"
        :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
        :file-size-limit="'1GB'"
      ></hlx-multi-upload>
</template>

<script>
export default {
data(){
}
}
</\script>`,

      profile_upload: `
<template>
     <hlx-file-upload
        :file-type="'.jpg,.jpeg,.png'"
        :file-size-limit="'20mb'"
        :uploadtype="'Rectangle'"
        :style-background="backgrounStyleupload"
        :height="50"
        :width="50"
        :read-only="true"
        :custom-icon="icon"
        :type="'profile-upload'"
        :name-display="nameDisplay"
        :content="'Import'"
        :profile-pic-preview="'https://th.bing.com/th/id/OIP.3l2nfzcHhMemSZooiH3B3AHaFj?w=225&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7'"
      />
</template>

<script>
export default {
data(){
  return {
   backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
  }
}
}
</\script>
`,

      sliderUpload: `
<template>
     <hlx-file-upload
        :type="'slide-upload'"
        :width="'100px'"
        :height="'100px'"
        :position="'horizontal'"
        @native-data="dataevent"
        @default-image="defaltImg"
        @deleteImage="deleteImage"
      />
      
      <dr>
      
      
        <hlx-file-upload
       :type="'slide-preview-upload'"
        :options="slideroption"
        :parent-height="'300px'"
        :parent-width="'300px'"
        :width="'100px'"
        :height="'100px'"
        :position="'vertical'"
        @selected-value="selecImg"
        @hovered-value="hoverImg"
      />
</template>

<script>
export default {
data(){
  return {
slideroption:[
 
   {
      name: "Canada Geese in Sunrise",
      data:             "https://media.istockphoto.com/id/1140180508/photo/two-canada-geese-in-the-light-of-the-rising-sun.jpg?s=1024x1024&w=is&k=20&c=nbC74G2JDEupwAriQ0BQU_-7DNsnS_bS6klVQTr_jEM=",

   },
   {
      name: "Greylag Goose Spreading Wings",
      data:             "https://media.istockphoto.com/id/1674107129/photo/the-greylag-goose-spreading-its-wings-on-water-anser-anser-is-a-species-of-large-goose.jpg?s=1024x1024&w=is&k=20&c=Y3RWeowSnXY-gWxkTQchjXQOZ9X6Yg5fYNVVUByb5dM=",

   },
   {
      name: "Bar-Headed Goose in Forest",
      data: "https://media.istockphoto.com/id/1363559825/photo/bar-headed-goose-duck-in-the-forest.jpg?s=1024x1024&w=is&k=20&c=i3Bhsr7VRQWlZ8cE8JVu77ko9elU6FiwbrGnXjU9ATo=",
   },
   {
      name: "Bar-Headed Goose",
      data: "https://media.istockphoto.com/id/649733656/photo/bar-headed-goose.jpg?s=1024x1024&w=is&k=20&c=3bBSDmyxEtWE0LPRCrn4Xh1qlZXEUN6Ql-D1sO3kYiM=",
   },
   {
      name: "Goose by the Lake",
      data:  "https://cdn.pixabay.com/photo/2024/05/05/05/55/goose-8740266_1280.jpg",
        
   },
   {
      name: "Canada Goose",
      data: "https://media.istockphoto.com/id/1471181605/photo/bernache-du-canada-canada-goose.jpg?s=1024x1024&w=is&k=20&c=t0utlpCEMwCB7saX7BxRJ7xk0AcPHqfIVsSwu6FnEpM=",
      preferred: true,
   },
   {
      name: "Countryside Clouds",
      data: "https://images.pexels.com/photos/236047/pexels-photo-236047.jpeg?cs=srgb&dl=clouds-cloudy-countryside-236047.jpg&fm=jpg",
   },
],
  }
}
}
</\script>
`,

      keerthiupload: "",
      slideroptionupoad: [],
      slideroption: [],

      lasted: 0,
      fileItems: [
        { name: "Doc1.pdf", fileData: "" },
        { name: "Doc2.doc", fileData: "" },
        { name: "Doc3.png", fileData: "" },
      ],
      file_data: {
        name: "test.json",
        data: "abcd",
      },
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: "600",
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: "200",
          type: "string",
          format: "",
        },
      ],
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: "50",
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: "50",
          type: "string",
          format: "",
        },
      ],
      icon: "icon-eye-regular",
      editor_theme: "light",
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      nameDisplay: "Profile",
      table_data: [
        {
          name: "file-type",
          description: "Specify the file extension",
          type: "String",
          accepted_values: "",
          default: "-",
          mandatory: true,
        },
        {
          name: "type",
          description: "Specify the style of upload you want",
          type: "String",
          accepted_values:
            "regular, button, link-button, upc-regular, upc-regular-sm, upc-link-button, upc-regular-v2,profile-upload",
          default: "-",
          mandatory: true,
        },
        {
          name: "file-size-limit",
          description: "Specify the size limit for files (in mb)",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: true,
        },
        {
          name: "icon",
          description: "Specify the custom icon",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "content",
          description: "Specify the button content",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "style-background",
          description: "Customize style for profile upload ",
          type: "object ",
          accepted_values: `
          backgrounStyleupload: {
       backgroundColor: "transparent",
          bacgroundtTextColor: "Black",
          border: false,
          borderColor: "#eee",
          borderWidth:'2px',
          TextfontSize: 24,
      },`,
          default: "-",
          mandatory: false,
        },

        {
          name: "custom-icon",
          description: "Specify the icon for custom function",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "height",
          description: "set the height for profile upload",
          type: "number",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "width",
          description: "set the width for profile upload",
          type: "number",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "uploadtype",
          description: "Specify the style of profile upload you want",
          type: "String",
          accepted_values: "Rectangle,Circle",
          default: "-",
          mandatory: true,
        },
        {
          name: "name-display",
          description:
            "Specify the style of name inside profile upload you want",
          type: "String",
          accepted_values: "",
          default: "-",
          mandatory: true,
        },
        {
          name: "read-only",
          description: `Disables user upload the file`,
          type: "Boolean",
          accepted_values: "true/false",
          default: "false",
          mandatory: false,
        },
        {
          name: "preset",
          description: "Pre-populate file",
          type: "Object",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "profilePicPreview",
          description: "Pre-populate file for profile picture",
          type: "string",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
        {
          name: "presetFiles",
          description:
            "Pre-populate array of files for the 'upc-link-button' type file upload",
          type: "Object",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
      ],
      event_data: [
        {
          name: "fileData",
          description: "emits the uploaded file data",
          parameter: "",
        },
        {
          name: "native-data",
          description: "emits the uploaded event file data",
          parameter: "",
        },
        {
          name: "SingleuplodedFiles",
          description: "emits the multi file uploaded data",
          parameter: "",
        },
        {
          name: "uplodedFiles",
          description: "emits the multi file uploaded data",
          parameter: "",
        },
        {
          name: "deleteFile",
          description: "emits the empty file data",
          parameter: "",
        },
        {
          name: "previewFile",
          description: "emits the file data",
          parameter: "",
        },
      ],
      file_upload_source: `
<template>
 <hlx-file-upload :file-type="'.xlsx,.png'" :file-size-limit="'20mb'" :type="'button'" :icon="'icon-arrow-down-to-line-filled'" :content="'Import'" :preset="this.file_data"/>
 export default {
 data() {
    return {
      file_data:{
        name: "test.json",
        data: "abcd"
      },
    }
  }
</template>`,
      file_upload_source2: `
<template>
 <hlx-file-upload :file-type="'.xlsx,.png'" :file-size-limit="'20mb'" :type="'regular'"/>
</template>`,
    };
  },
  mounted() {
    setTimeout(() => {
      this.slideroption = [
        {
          name: "Greylag Goose Spreading Wings",
          data: "https://media.istockphoto.com/id/1674107129/photo/the-greylag-goose-spreading-its-wings-on-water-anser-anser-is-a-species-of-large-goose.jpg?s=1024x1024&w=is&k=20&c=Y3RWeowSnXY-gWxkTQchjXQOZ9X6Yg5fYNVVUByb5dM=",
        },
       
        {
          name: "Bar-Headed Goose",
          data: "https://media.istockphoto.com/id/649733656/photo/bar-headed-goose.jpg?s=1024x1024&w=is&k=20&c=3bBSDmyxEtWE0LPRCrn4Xh1qlZXEUN6Ql-D1sO3kYiM=",
        },
        {
          name: "Goose by the Lake",
          data: "https://cdn.pixabay.com/photo/2024/05/05/05/55/goose-8740266_1280.jpg",
        },
        {
          name: "Canada Goose",
          data: "https://media.istockphoto.com/id/1471181605/photo/bernache-du-canada-canada-goose.jpg?s=1024x1024&w=is&k=20&c=t0utlpCEMwCB7saX7BxRJ7xk0AcPHqfIVsSwu6FnEpM=",
          preferred: true,
        },
        {
          name: "Countryside Clouds",
          data: "https://images.pexels.com/photos/236047/pexels-photo-236047.jpeg?cs=srgb&dl=clouds-cloudy-countryside-236047.jpg&fm=jpg",
        },
      ];
    }, 3000);
  },
  methods: {
    profiledata(val) {
      console.log(val, "profile");
      this.keerthiupload = val;
    },
    getvaluearrsingle(val) {
      console.log(val);
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    // deleteFile(file){
    //   console.log(file,"pp");
    // }
  },
};
</script>
