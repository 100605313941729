<template>
    <div :id="'contenteditable'+unique" contenteditable="false" class="content-editable-select" style="height:30px;display: flex;align-items: center;"></div>
</template>

<script>
export default{
    name: "ContentEditable",
    props:{
        prevalue:{
      type: String,
      default:''
    },
    },
    data()
    {
        return{
            unique: Math.floor(Math.random() * 1000 + 1),
         }
    },
    watch:{
        prevalue: {
      handler(val) {
        if(val != '' && val)
        {
          // console.log(val,'preval');
          this.$nextTick(()=>{
            // console.log(document.getElementById('textEditor').innerHTML,'HTML');
            document.getElementById('contenteditable'+this.unique).innerHTML = val
          })
        }
        // this.modal_height = val;
      },
      deep:true,
      immediate: true,
    },
    }

}
</script>