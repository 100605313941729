<template>
  <h1 class="component-heading">Draggables</h1>
  <p class="component-description">
    Draggable component allows drag-and-drop and synchronization with the
    reordering of associated array elements.
  </p>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">Hold and drag to arrange the list</p>
  <p class="component-description">
    Whenever an item is re arranged, the new re arranged array is emitted as a
    v-model value
    <br />
    Pass the list of options as an array of object to the
    <b>:list</b> attribute.
  </p>
  <div class="component-example">
    <div class="example-body3">
      <hlx-draggables :list="list" @change="handleChange" />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="basic-draggables-icon"
        class="icon"
        @click="showCode('basic-draggables')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-draggables" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_draggables"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Selectable</h2>
  <p class="component-description">
    Use check boxes to select items, and re arrange them
  </p>
  <p class="component-description">
    Bind <b>:selectable="true"</b> to add checkboxes
  </p>
  <span class="component-description">
    Each object must contain the following attributes to function properly :
    <br />
    <li class="component-description">
      name: (String) label name for each checkbox
    </li>
    <li class="component-description">
      checked: (true / false) sets whether the option must be pre-selected or
      not
    </li>
    <li class="component-description">
      disabled: (true / false) sets whether the option must be disabled or not
    </li>
    <li class="component-description">
      id: (unique id) distinguishes fields from each other
    </li>
    <br />
  </span>
  <div class="component-example">
    <div class="example-body3">
      <hlx-draggables :list="list2" :selectable="true" @change="handleChange" />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="selectable-draggables-icon"
        class="icon"
        @click="showCode('selectable-draggables')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="selectable-draggables" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="selectable_draggables"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Draggables attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in dragAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in dragAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'importProgressBar')"
      ><i class="icon-angle-left-regular prev-icon"></i>Import progress bar</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'errorpage')"
        >Error page <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import HlxDraggables from "../components/DraggableComponent.vue";
import HlxDivider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";

export default {
  components: {
    HlxDraggables,
    HlxDivider,
    CodeEditor,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
  },
  data() {
    return {
      example_code: "``",
      editor_theme: "light",
      list: [
        { name: "Rachel", id: 1 },
        { name: "Ross", id: 2 },
        { name: "Chandler", id: 3 },
        { name: "Monica", id: 4 },
      ],
      list2: [
        { name: "checked", checked: true,  readOnly: false,disabled: false, id: 1 },
        { name: "unchecked", checked: false, readOnly: false, disabled: false, id: 2 },
        { name: "checked & read only", checked: true, readOnly: true, id: 3 },
        { name: "checked & disabled", checked: true, disabled: true, id: 4 },
        { name: "unchecked & read only", checked: false, readOnly: true, id: 5 },
        { name: "unchecked and disabled", checked: false, disabled: true, id: 6 },
      ],
      list3: [
        { name: "Rachel", id: 1, checked: true, disabled: false },
        { name: "Ross", id: 2, checked: true, disabled: false },
        { name: "Chandler", id: 3, checked: false, disabled: false },
        { name: "Monica", id: 4, checked: true, disabled: false },
      ],
      dragAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      DraggablesAttributes: {
        columns: [
          {
            name: "attribute",
            label: "Attributes",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "description",
            label: "Description",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "type",
            label: "Type",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "accepted_values",
            label: "Accepted values",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "default",
            label: "Default",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "mandatory",
            label: "Mandatory",
            width: "50",
            type: "string",
            format: "",
          },
        ],
        table_data: [
          {
            attribute: "list",
            description: "pass the list of items to the draggable component",
            type: "Array",
            accepted_values: "Array",
            default: "-",
            mandatory: true,
          },
          {
            attribute: "selectable",
            description: "adds check boxes to select the items from the array",
            type: "Boolean",
            accepted_values: "true / false",
            default: "false",
            mandatory: false,
          },
        ],
      },
      table_data: [
        {
          name: "list",
          description: "Pass the list of items to the draggable component",
          type: "Array",
          accepted_values: "Array",
          default: "-",
          mandatory: true,
        },
        {
          name: "selectable",
          description: "Adds check boxes to select the items from the array",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: false,
        },
      ],
      basic_draggables: `
<template>
  <hlx-draggables :list="this.list"  @change="handleChange" />
</template>

<script>
export default {
  data () {
    return {
      list: [
        { name: 'Rachel', id: 1 },
        { name: 'Ross', id: 2 },
        { name: 'Chandler', id: 3 },
        { name: 'Monica', id: 4 }
      ]
    }
  },
  methods: {
    handleChange (val) {
      // console.log(val)
    }
  }
}
</\script>
      `,
      selectable_draggables: `
<template>
  <hlx-draggables :list="this.list2" :selectable="true"  @change="handleChange"/>
</template>

<script>
export default {
  data () {
    return {
      list2: [
        { name: 'Rachel', id: 1, checked: true, disabled: false },
        { name: 'Ross', id: 2, checked: true, disabled: false },
        { name: 'Chandler', id: 3, checked: true, disabled: false },
        { name: 'Monica', id: 4, checked: true, disabled: false }
      ]
    }
  },
  methods: {
    handleChange (val) {
      // console.log(val)
    }
  }
}
</\script>
      `,
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    handleChange(val) {
      console.log(val, "emit list");
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>

<style></style>
