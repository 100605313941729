<template>
  <main class="error-page-container">
    <section class="section--image">
      <img src="../assets/img/illustration1.png" alt="" />
    </section>
    <section class="section--content">
      <h5>{{ errorCode }}</h5>
      <h4>{{ errorMessage }}</h4>
      <p>
        The page you are looking for might be removed or is temporarily
        unavailable.
      </p>
      <hlx-button class="primary sm" @click="$router.push('/')"
        >Back to homepage</hlx-button
      >
    </section>
  </main>
</template>

<script>
import hlxButton from "./ButtonComponent.vue";
export default {
  name: "HlxErrorPage",
  components: { hlxButton },
  props: {
    errorCode: {
      type: Number,
      default: 400,
    },
    errorMessage: {
      type: String,
      default: "Error",
    },
  },
};
</script>

<style lang="scss" scoped></style>
