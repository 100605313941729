<template>
  <input-col-map-modal
    :show-input-col-map="shwInpMap"
    :pre-populate="tableHeadDetails"
    :index="inputIndex"
    :drop-down-data="intellisensePayload"
    @save="saveInputThead"
    @close="shwInpMap = false"
    @delete="deleteInputThead"
  >
  </input-col-map-modal>
  <output-col-map-modal
    :show-output-col-map="shwOutMap"
    :pre-populate="tableHeadDetails"
    :index="outputIndex"
    :drop-down-data="intellisensePayload"
    @save="saveOutputThead"
    @close="shwOutMap = false"
    @delete="deleteOutputhead"
  >
  </output-col-map-modal>
  <div class="example-body" style="display: flex">
    <div :id="'grid-table'" :class="'grid-table-component'">
      <hlx-table
        :column-count="theads.input.length + theads.output.length"
        :border="['table', 'header', 'vertical', 'horizontal']"
        theme="grey"
      >
        <template #thead>
          <hlx-table-head
            v-for="(i, index) in theads.input"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="185"
            style="height: 60px !important"
            :style="i.type === 'input' ? { background: '#fff' } : {}"
            @dragenter="columnenter(serialNumber(index))"
            @dragend="columnend('input')"
            @mouseover="columnover(index)"
            @mouseleave="columnleave(index)"
          >
            <span
              :id="'add-input-column-left-' + index"
              class="add-column-left"
              @mouseenter="showIcon(index, 'left', 'input-')"
              @mouseleave="hideIcon(index, 'left', 'input-')"
            >
              <span
                :id="'add-input-column-icon-left-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addInputColumn(index, 'left')"
                ></i>
              </span>
            </span>
            <span v-if="i.type === 'input'" class="thead-container">
              <span class="input-column-name"
                ><span class="column-name">{{ i.labelName }}</span>
                <span class="data-type">{{ i.dataType }}</span></span
              >
              <span class="column-drag-container">
                <span
                  :id="'columndrag' + index"
                  class="columndrag"
                  draggable="true"
                  @dragstart="columnstart(serialNumber(index))"
                >
                  <i class="icon-more-vertical-filled rotate"></i>
                </span>
              </span>
              <span
                class="drag-icon-vertical"
                @click="openTheadMappingModal('input', i)"
                ><i class="icon-more-vertical-filled"></i
              ></span>
            </span>
            <span
              :id="'add-input-column-right-' + index"
              class="add-column-right"
              @mouseenter="showIcon(index, 'right', 'input-')"
              @mouseleave="hideIcon(index, 'right', 'input-')"
            >
              <span
                :id="'add-input-column-icon-right-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addInputColumn(index, 'right')"
                ></i>
              </span>
            </span>
          </hlx-table-head>
          <hlx-table-head
            v-for="(i, index) in theads.output"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="185"
            style="height: 60px !important"
            :style="i.type === 'input' ? { background: '#fff' } : {}"
            @dragenter="columnenter(serialNumber(index))"
            @dragend="columnend('output')"
            @mouseover="columnoutputover(index)"
            @mouseleave="columnoutputleave(index)"
          >
            <span v-if="i.type === 'output'" class="thead-container">
              <span class="output-column-name"
                ><span class="column-name">{{ i.labelName }}</span>
                <span class="data-type">{{ i.dataType }}</span></span
              >

              <span class="column-drag-container">
                <span
                  :id="'columndragoutput' + index"
                  class="columndrag"
                  draggable="true"
                  @dragstart="columnstart(serialNumber(index))"
                >
                  <i class="icon-more-vertical-filled rotate"></i>
                </span>
              </span>
              <span
                style="cursor: pointer"
                @click="openTheadMappingModal('output', i)"
                ><i
                  class="icon-more-vertical-filled"
                  style="padding-top: 3px"
                ></i
              ></span>
            </span>
            <span
              :id="'add-output-column-right-' + index"
              class="add-column-right"
              @mouseenter="showIcon(index, 'right', 'output-')"
              @mouseleave="hideIcon(index, 'right', 'output-')"
            >
              <span
                :id="'add-output-column-icon-right-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addOutputColumn(index, 'right', $event)"
                ></i>
              </span>
            </span>
          </hlx-table-head>
        </template>
        <template #tbody>
          <tr
            v-for="(i, index) in tBody"
            id="index"
            :key="index"
            @mouseover="rowdraggable(index)"
            @mouseout="outrowdraggable"
          >
            <hlx-table-cell
              v-for="(j, col_index) in headvalue"
              :key="col_index"
              @dragenter="enter(serialNumber(index))"
            >
              <span
                v-if="col_index == 0"
                :id="'rowdraggable-icon' + index"
                class="rowdraggable-icon"
                draggable="true"
                @dragstart="start(serialNumber(index))"
                @dragenter="enter(serialNumber(index))"
                @dragend="end()"
              >
                <span class="row-drag-icon-container">
                  <i class="icon-more-vertical-filled"></i>
                </span>
              </span>
              <div v-if="j.type === 'input'" class="row-cell">
                <span class="input-row-dropdown-container">
                  <dropdown
                    v-model:value="i[j.prop].operators"
                    v-model="i[j.prop].operators"
                    :options="op_data"
                    :placeholder-value="''"
                    :label="'name'"
                    :prop-value="'value'"
                    :label-animation="false"
                    :inline-search="true"
                    :footer="false"
                    :border="false"
                    @inline-value="pushOperators($event, j.prop)"
                  ></dropdown>
                </span>
                <span class="input-row-input-container">
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'string'
                    "
                    class="manual-string"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="input-text"
                      type="text"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer') &&
                      i[j.prop].operators !== 'between'
                    "
                    class="manual-number"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="input-number"
                      type="number"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer') &&
                      i[j.prop].operators === 'between'
                    "
                    class="manual-between"
                  >
                    <span class="left-between">
                      <input
                        v-model="i[j.prop].value1"
                        class="left-between-input"
                        type="number"
                        @mouseleave="blurInput($event)"
                        @mouseover="focusInput($event)"
                        @focus="focusInput($event, 'bw')"
                        @blur="blurInput($event, 'bw')"
                      />
                    </span>
                    <div class="between-seperator"></div>
                    <span class="right-between">
                      <input
                        v-model="i[j.prop].value2"
                        class="right-between-input"
                        type="number"
                        @mouseleave="blurInput($event)"
                        @mouseover="focusInput($event)"
                        @focus="focusInput($event, 'bw')"
                        @blur="blurInput($event, 'bw')"
                      />
                    </span>
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'boolean'
                    "
                    class="manual-boolean"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      :options="[
                        { name: 'true', value: true },
                        { name: 'false', value: false },
                      ]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span v-if="j.source.toLowerCase() === 'reference data'">
                    <!-- {{ j.dataType }}
                    {{ j.source }} -->
                    {{ "placeholder for ref data" }}
                  </span>
                </span>
              </div>
              <div v-if="j.type === 'output'" class="row-cell">
                <span class="output-cell-container">
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'string'
                    "
                    class="output-manual-string"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="string-input"
                      type="text"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer')
                    "
                    class="output-manual-number"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="number-output"
                      type="number"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'boolean'
                    "
                    class="output-manual-boolean"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      :options="[
                        { name: 'true', value: true },
                        { name: 'false', value: false },
                      ]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span
                    v-if="j.source.toLowerCase() === 'reference data'"
                    class="output-ref"
                  >
                    <!-- {{ j.dataType }}
                    {{ j.source }} -->
                    {{ "placeholder for ref data" }}
                  </span>
                  <span
                    v-if="j.source.toLowerCase() === 'computed'"
                    class="output-computed"
                  >
                    <!-- {{ j.dataType }}
                    {{ j.source }} -->
                    {{ "f(x)" }}
                  </span>
                </span>
              </div>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
    <div class="crud-container">
      <div class="crud-container-margin">
        <span v-for="(item, index) in tBody" :key="index" class="crud-item">
          <span class="add" @click="rowadddata(index + 1)">
            <i class="icon-plus-regular"></i
          ></span>
          <span class="delete" @click="deleterowdata(index)"
            ><i v-if="tBody.length > 1" class="icon-trash-regular"></i
          ></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import HlxTable from "../components/table/HlxTable.vue";
import HlxTableCell from "../components/table/HlxTableCell.vue";
import HlxTableHead from "../components/table/HlxTableHead.vue";
// import HlxPagination from "../components/PaginationComponent.vue";
// import { VueDraggableNext } from "vue-draggable-next";
import dropdown from "../components/DropdowncontainerComponent.vue";

import inputColMapModal from "../components/Grid/inputColumnMappingModal.vue";
import outputColMapModal from "../components/Grid/outputColumnMappingModal.vue";

export default {
  components: {
    HlxTable,
    HlxTableCell,
    HlxTableHead,
    inputColMapModal,
    outputColMapModal,
    // HlxPagination,
    // draggable:VueDraggableNext,
    dropdown,
  },
  data() {
    return {
      prevalue: "==",
      columndragstart: null,
      columndragend: null,
      gridData: {},
      intellisensePayload: [
        {
          label: "EICALC.Access Type",
          value: "EICALC.Access Type",
          type: "String",
        },
        {
          label: "EICALC.Band 0 MRC",
          value: "EICALC.Band 0 MRC",
          type: "Boolean",
        },
        {
          label: "EICALC.Band 1 MRC",
          value: "EICALC.Band 1 MRC",
          type: "Number",
        },
        {
          label: "EICALC.Band 2 MRC",
          value: "EICALC.Band 2 MRC",
          type: "Number",
        },
        {
          label: "EICALC.Band 3 MRC",
          value: "EICALC.Band 3 MRC",
          type: "Number",
        },
        {
          label: "EICALC.Band 4 MRC",
          value: "EICALC.Band 4 MRC",
          type: "Number",
        },
        {
          label: "EICALC.Band 4S MRC",
          value: "EICALC.Band 4S MRC",
          type: "Number",
        },
        {
          label: "EICALC.Diversity Options",
          value: "EICALC.Diversity Options",
          type: "String",
        },
        {
          label: "EICALC.Installation NRC",
          value: "EICALC.Installation NRC",
          type: "Number",
        },
        {
          label: "EICALC.Speed",
          value: "EICALC.Speed",
          type: "String",
        },
        {
          label: "EIOP.EI",
          value: "EIOP.EI",
          type: "Object",
        },
        {
          label: "EIOP.EI.AccessType",
          value: "EIOP.EI.AccessType",
          type: "String",
        },
        {
          label: "EIOP.EI.Band",
          value: "EIOP.EI.Band",
          type: "String",
        },
        {
          label: "EIOP.EI.InstallationNRC",
          value: "EIOP.EI.InstallationNRC",
          type: "Number",
        },
        {
          label: "EIOP.EI.LAG",
          value: "EIOP.EI.LAG",
          type: "Object",
        },
        {
          label: "EIOP.EI.LAG.InstallationNRC",
          value: "EIOP.EI.LAG.InstallationNRC",
          type: "Number",
        },
        {
          label: "EIOP.EI.LAG.MRC",
          value: "EIOP.EI.LAG.MRC",
          type: "Number",
        },
        {
          label: "EIOP.EI.MRC",
          value: "EIOP.EI.MRC",
          type: "Number",
        },
        {
          label: "EIOP.EI.PostalCode",
          value: "EIOP.EI.PostalCode",
          type: "Number",
        },
        {
          label: "EIOP.EI.Speed",
          value: "EIOP.EI.Speed",
          type: "String",
        },
        {
          label: "EIOP.EI.Term",
          value: "EIOP.EI.Term",
          type: "Number",
        },
        {
          label: "EIOP.EI.isLAG",
          value: "EIOP.EI.isLAG",
          type: "Boolean",
        },
        {
          label: "EIOP.TotalMRC",
          value: "EIOP.TotalMRC",
          type: "Number",
        },
        {
          label: "EIOP.TotalNRC",
          value: "EIOP.TotalNRC",
          type: "Number",
        },
        {
          label: "Item.price",
          value: "Item.price",
          type: "Number",
        },
        {
          label: "Item.quantity",
          value: "Item.quantity",
          type: "Number",
        },
        {
          label: "Item.totalPrice",
          value: "Item.totalPrice",
          type: "Number",
        },
        {
          label: "add.locatiob",
          value: "add.locatiob",
          type: "Array",
        },
        {
          label: "akss.Afd",
          value: "akss.Afd",
          type: "Array",
        },
        {
          label: "checkdemo1.Location",
          value: "checkdemo1.Location",
          type: "String",
        },
        {
          label: "checkdemo1.Province",
          value: "checkdemo1.Province",
          type: "String",
        },
        {
          label: "demo1.Location",
          value: "demo1.Location",
          type: "Array",
        },
        {
          label: "demo2.province",
          value: "demo2.province",
          type: "Array",
        },
        {
          label: "demo3.Location",
          value: "demo3.Location",
          type: "Array",
        },
        {
          label: "demo4.Location",
          value: "demo4.Location",
          type: "Object",
        },
        {
          label: "demotest1.location",
          value: "demotest1.location",
          type: "Array",
        },
        {
          label: "demotest1.province",
          value: "demotest1.province",
          type: "String",
        },
        {
          label: "demotest1test2.Location",
          value: "demotest1test2.Location",
          type: "String",
        },
        {
          label: "demotest1test2.province",
          value: "demotest1test2.province",
          type: "String",
        },
        {
          label: "quote.agreement",
          value: "quote.agreement",
          type: "Array",
        },
        {
          label: "quote.attachment",
          value: "quote.attachment",
          type: "Array",
        },
        {
          label: "quote.authorization",
          value: "quote.authorization",
          type: "Array",
        },
        {
          label: "quote.billingAccount",
          value: "quote.billingAccount",
          type: "Array",
        },
        {
          label: "quote.category",
          value: "quote.category",
          type: "String",
        },
        {
          label: "quote.contactMedium",
          value: "quote.contactMedium",
          type: "Array",
        },
        {
          label: "quote.description",
          value: "quote.description",
          type: "String",
        },
        {
          label: "quote.effectiveQuoteCompletionDate",
          value: "quote.effectiveQuoteCompletionDate",
          type: "Date",
        },
        {
          label: "quote.expectedFulfillmentStartDate",
          value: "quote.expectedFulfillmentStartDate",
          type: "Date",
        },
        {
          label: "quote.expectedQuoteCompletionDate",
          value: "quote.expectedQuoteCompletionDate",
          type: "Date",
        },
        {
          label: "quote.externalId",
          value: "quote.externalId",
          type: "String",
        },
        {
          label: "quote.note",
          value: "quote.note",
          type: "Array",
        },
        {
          label: "quote.price",
          value: "quote.price",
          type: "Number",
        },
        {
          label: "quote.productOfferingQualification",
          value: "quote.productOfferingQualification",
          type: "Array",
        },
        {
          label: "quote.productRef",
          value: "quote.productRef",
          type: "Object",
        },
        {
          label: "quote.quoteDate",
          value: "quote.quoteDate",
          type: "Date",
        },
        {
          label: "quote.quoteHistory",
          value: "quote.quoteHistory",
          type: "Array",
        },
        {
          label: "quote.quoteItem",
          value: "quote.quoteItem",
          type: "Array",
        },
        {
          label: "quote.quoteItem.price",
          value: "quote.quoteItem.price",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.agreement",
          value: "quote.quoteItem.productOffering.agreement",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.attachment",
          value: "quote.quoteItem.productOffering.attachment",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.bundledProductOffering",
          value: "quote.quoteItem.productOffering.bundledProductOffering",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.category",
          value: "quote.quoteItem.productOffering.category",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.channel",
          value: "quote.quoteItem.productOffering.channel",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.description",
          value: "quote.quoteItem.productOffering.description",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.isBundle",
          value: "quote.quoteItem.productOffering.isBundle",
          type: "Boolean",
        },
        {
          label: "quote.quoteItem.productOffering.isSellable",
          value: "quote.quoteItem.productOffering.isSellable",
          type: "Boolean",
        },
        {
          label: "quote.quoteItem.productOffering.lastUpdate",
          value: "quote.quoteItem.productOffering.lastUpdate",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.lifecycleStatus",
          value: "quote.quoteItem.productOffering.lifecycleStatus",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.marketSegment",
          value: "quote.quoteItem.productOffering.marketSegment",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.name",
          value: "quote.quoteItem.productOffering.name",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.place",
          value: "quote.quoteItem.productOffering.place",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.prodSpecCharValueUse",
          value: "quote.quoteItem.productOffering.prodSpecCharValueUse",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.productOfferingPrice",
          value: "quote.quoteItem.productOffering.productOfferingPrice",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.productOfferingTerm",
          value: "quote.quoteItem.productOffering.productOfferingTerm",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.productSpecification",
          value: "quote.quoteItem.productOffering.productSpecification",
          type: "Object",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.attachment",
          value:
            "quote.quoteItem.productOffering.productSpecification.attachment",
          type: "Array",
        },
        {
          label: "quote.quoteItem.productOffering.productSpecification.brand",
          value: "quote.quoteItem.productOffering.productSpecification.brand",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.bundledProductSpecification",
          value:
            "quote.quoteItem.productOffering.productSpecification.bundledProductSpecification",
          type: "Boolean",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.description",
          value:
            "quote.quoteItem.productOffering.productSpecification.description",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.isBundle",
          value:
            "quote.quoteItem.productOffering.productSpecification.isBundle",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.lastUpdate",
          value:
            "quote.quoteItem.productOffering.productSpecification.lastUpdate",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.productSpecification.name",
          value: "quote.quoteItem.productOffering.productSpecification.name",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.category",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.category",
          type: "Array",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.catlogtype",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.catlogtype",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.description",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.description",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.id",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.id",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.lastUpdate",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.lastUpdate",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.lifecycleStatus",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.lifecycleStatus",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.name",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.name",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.relatedParty",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.relatedParty",
          type: "Array",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.unitPrice",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.unitPrice",
          type: "String",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productRef.validFor",
          value:
            "quote.quoteItem.productOffering.productSpecification.productRef.validFor",
          type: "Object",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productSpecCharacteristic",
          value:
            "quote.quoteItem.productOffering.productSpecification.productSpecCharacteristic",
          type: "Array",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.productSpecificationRelationship",
          value:
            "quote.quoteItem.productOffering.productSpecification.productSpecificationRelationship",
          type: "Array",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.relatedParty",
          value:
            "quote.quoteItem.productOffering.productSpecification.relatedParty",
          type: "Array",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.resourceSpecification",
          value:
            "quote.quoteItem.productOffering.productSpecification.resourceSpecification",
          type: "Array",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.serviceSpecification",
          value:
            "quote.quoteItem.productOffering.productSpecification.serviceSpecification",
          type: "Object",
        },
        {
          label:
            "quote.quoteItem.productOffering.productSpecification.validFor",
          value:
            "quote.quoteItem.productOffering.productSpecification.validFor",
          type: "Object",
        },
        {
          label: "quote.quoteItem.productOffering.productSpecification.version",
          value: "quote.quoteItem.productOffering.productSpecification.version",
          type: "String",
        },
        {
          label: "quote.quoteItem.productOffering.resourceCandidate",
          value: "quote.quoteItem.productOffering.resourceCandidate",
          type: "Object",
        },
        {
          label: "quote.quoteItem.productOffering.serviceCandidate",
          value: "quote.quoteItem.productOffering.serviceCandidate",
          type: "Object",
        },
        {
          label: "quote.quoteItem.productOffering.serviceLevelAgreement",
          value: "quote.quoteItem.productOffering.serviceLevelAgreement",
          type: "Object",
        },
        {
          label: "quote.quoteItem.productOffering.validFor",
          value: "quote.quoteItem.productOffering.validFor",
          type: "Object",
        },
        {
          label: "quote.quoteItem.productOffering.version",
          value: "quote.quoteItem.productOffering.version",
          type: "String",
        },
        {
          label: "quote.quoteItem.quantity",
          value: "quote.quoteItem.quantity",
          type: "String",
        },
        {
          label: "quote.quoteItem.tax",
          value: "quote.quoteItem.tax",
          type: "String",
        },
        {
          label: "quote.quoteItem.unitPrice",
          value: "quote.quoteItem.unitPrice",
          type: "String",
        },
        {
          label: "quote.quoteTotalPrice",
          value: "quote.quoteTotalPrice",
          type: "Array",
        },
        {
          label: "quote.relatedParty",
          value: "quote.relatedParty",
          type: "Array",
        },
        {
          label: "quote.state",
          value: "quote.state",
          type: "String",
        },
        {
          label: "quote.tax",
          value: "quote.tax",
          type: "Number",
        },
        {
          label: "quote.totalPrice",
          value: "quote.totalPrice",
          type: "Number",
        },
        {
          label: "quote.userId",
          value: "quote.userId",
          type: "String",
        },
        {
          label: "quote.validFor",
          value: "quote.validFor",
          type: "Object",
        },
        {
          label: "quote.version",
          value: "quote.version",
          type: "String",
        },
        {
          label: "tesaaaaa.Location",
          value: "tesaaaaa.Location",
          type: "Array",
        },
        {
          label: "test04.apo",
          value: "test04.apo",
          type: "Object",
        },
        {
          label: "test1.location",
          value: "test1.location",
          type: "Array",
        },
        {
          label: "test10.Location",
          value: "test10.Location",
          type: "Array",
        },
        {
          label: "test1test2.Location",
          value: "test1test2.Location",
          type: "Array",
        },
        {
          label: "test24.aka",
          value: "test24.aka",
          type: "Array",
        },
        {
          label: "test5.location",
          value: "test5.location",
          type: "Array",
        },
        {
          label: "testtt.qq",
          value: "testtt.qq",
          type: "Object",
        },
      ],
      op_data: [
        {
          type: "number",
          name: "greater than",
          value: ">",
          checked: false,
          disabled: false,
        },
        {
          type: "number",
          name: "less than",
          value: "<",
          checked: false,
          disabled: false,
        },
        {
          type: "number",
          name: "greater than equal to",
          value: ">=",
          checked: false,
          disabled: false,
        },
        {
          type: "number",
          name: "less than equal to",
          value: "<=",
          checked: false,
          disabled: false,
        },
        {
          type: "number",
          name: "equal to",
          value: "==",
          checked: false,
          disabled: false,
        },
        {
          type: "number",
          name: "not equal to",
          value: "!=",
          checked: false,
          disabled: false,
        },
        {
          type: "boolean",
          name: "equal to",
          value: "==",
          checked: false,
          disabled: false,
        },
        {
          type: "boolean",
          name: "not equal to",
          value: "!=",
          checked: false,
          disabled: false,
        },
        {
          type: "number",
          name: "between",
          value: "between",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "is",
          value: "is",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "contains",
          value: "contains",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "isNot",
          value: "isNot",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "isNull",
          value: "isNull",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "isNotNull",
          value: "isNotNull",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "startsWith",
          value: "startsWith",
          checked: false,
          disabled: false,
        },
        {
          type: "string",
          name: "endsWith",
          value: "endsWith",
          checked: false,
          disabled: false,
        },
      ],
      inputIndex: null,
      outputIndex: null,
      tableHeadDetails: {},
      shwOutMap: false,
      shwInpMap: false,
      theads: {
        input: [
          {
            id: 1,
            objectMapping: {
              label: "",
              type: "",
            },
            dataType: "Data type",
            type: "input",
            operators: {},
            labelName: "Input label 1",
            source: "Manual",
            mode: "Manual",
            from: "",
            to: "",
          },
        ],
        output: [
          {
            id: 1,
            objectMapping: {
              label: "",
              type: "",
            },
            dataType: "Data type",
            type: "output",
            labelName: "Output label 1",
            source: "Manual",
            mode: "Manual",
            from: "",
            to: "",
            expression: "",
          },
        ],
      },
      tBody: [
        {
          "Input label 1": {
            value: "",
            value1: "",
            value2: "",
            operators: "",
            betweenValues: [],
          },
          "Output label 1": {
            value: "",
            expression: "",
          },
        },
        {
          "Input label 1": {
            value: "",
            value1: "",
            value2: "",
            operators: "",
            betweenValues: [],
          },
          "Output label 1": {
            value: "",
            expression: "",
          },
        },
      ],
      headvalue: [
        {
          prop: "Input label 1",
          type: "input",
          dataType: "String",
          source: "manual",
        },
        {
          prop: "Output label 1",
          type: "output",
          dataType: "String",
          source: "manual",
        },
      ],
      tableData: [
        {
          name: "vijay",
          cartoon: "pikachu",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Brock",
          cartoon: "charizard",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Misty",
          cartoon: "ninja",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "May",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Feroz",
          cartoon: "pikachu",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Ganesh",
          cartoon: "charizard",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Mathan",
          cartoon: "ninja",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Pooja",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Raags",
          cartoon: "pikachu",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Gopi",
          cartoon: "charizard",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Sudhar",
          cartoon: "ninja",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Thanos",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Kang",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "MODOK",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: "Loki",
          cartoon: "charizard",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
      ],
      currPage: 1,
      rowsPerPage: 5,
      startdata: {},
      enddata: {},
      rowdragstart: null,
      rowdragend: null,
      timeoutId: null,
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    paginatedData1() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData1.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    tBody: {
      handler(val) {
        // this.bindExpression(this.theads.output, val);
        // this.processTBody(val);
        this.handleTBodyAndTheads(val, this.theads.output);
      },
      immediate: true,
      deep: true,
    },
    theads: {
      handler(val) {
        // this.bindExpression(val.output, this.tBody);
        // this.processTBody(this.tBody);
        this.handleTBodyAndTheads(this.tBody, val.output);
      },
      immediate: true,
      deep: true,
    },
    headvalue: {
      handler(Val) {
        Val;
      },
      immediate: true,
      deep: true,
    },
  },
  // mounted() {
  //   const gridData = {
  //     decisions: [
  //       {
  //         "Band 3 MRC": {
  //           value: 90,
  //           value1: "",
  //           value2: "",
  //           operators: ">",
  //           betweenValues: [],
  //         },
  //         Speed: {
  //           value: "over speed",
  //           expression: "",
  //         },
  //         "Band 2 MRC": {
  //           value: 80,
  //           value1: "",
  //           value2: "",
  //           operators: "<",
  //           betweenValues: [],
  //         },
  //       },
  //       {
  //         "Band 3 MRC": {
  //           value: 50,
  //           value1: "",
  //           value2: "",
  //           operators: "<",
  //           betweenValues: [],
  //         },
  //         Speed: {
  //           value: "slow",
  //           expression: "",
  //         },
  //         "Band 2 MRC": {
  //           value: 70,
  //           value1: "",
  //           value2: "",
  //           operators: ">",
  //           betweenValues: [],
  //         },
  //       },
  //     ],
  //     inputMapping: [
  //       {
  //         id: 1,
  //         objectMapping: {
  //           label: "EICALC.Band 3 MRC",
  //           type: "Number",
  //         },
  //         dataType: "Number",
  //         type: "input",
  //         operators: {
  //           ">": [],
  //           "<": [],
  //         },
  //         labelName: "Band 3 MRC",
  //         source: "Manual",
  //         mode: "Manual",
  //         from: "EICALC.Band 3 MRC",
  //         to: "Band 3 MRC",
  //       },
  //       {
  //         id: 41981,
  //         objectMapping: {
  //           label: "EICALC.Band 2 MRC",
  //           type: "Number",
  //         },
  //         dataType: "Number",
  //         type: "input",
  //         operators: {
  //           "<": [],
  //           ">": [],
  //         },
  //         labelName: "Band 2 MRC",
  //         source: "Manual",
  //         mode: "Manual",
  //         from: "EICALC.Band 2 MRC",
  //         to: "Band 2 MRC",
  //       },
  //     ],
  //     outputMapping: [
  //       {
  //         id: 1,
  //         labelName: "Speed",
  //         dataType: "String",
  //         objectMapping: {
  //           label: "EICALC.Speed",
  //           type: "String",
  //         },
  //         operators: {},
  //         source: "Manual",
  //         type: "output",
  //         mode: "Manual",
  //         from: "EICALC.Speed",
  //         to: "Speed",
  //         expression: "",
  //       },
  //     ],
  //   };
  //   this.destructureGridData(gridData);
  // },
  methods: {
    destructureGridData(payload) {
      this.theads.input = payload.inputMapping;
      this.theads.output = payload.outputMapping;
      this.tBody = payload.decisions;
      const concatenatedArray = [
        ...payload.inputMapping,
        ...payload.outputMapping,
      ];

      // Add a new key named "prop" to each object and assign the value from "labelName" to "prop"
      const modifiedArray = concatenatedArray.map((item) => ({
        ...item,
        prop: item.labelName,
      }));

      // Assign the modified array to the headvalue variable
      this.headvalue = modifiedArray;
    },
    pushOperators(e, val) {
      this.theads.input.filter((item) => {
        item.labelName === val ? (item.operators[e] = []) : "";
      });
    },
    rowadddata(index) {
      const firstObject = this.tBody[0];
      const duplicatedObject = JSON.parse(JSON.stringify(firstObject)); // Deep copy of the first object

      // Set all non-array values to ""
      for (const key in duplicatedObject) {
        for (const innerKey in duplicatedObject[key]) {
          if (!Array.isArray(duplicatedObject[key][innerKey])) {
            duplicatedObject[key][innerKey] = "";
          }
        }
      }
      this.tBody.splice(index, 0, duplicatedObject);
      // this.tBody.push(duplicatedObject)
    },
    deleterowdata(index) {
      this.tBody.splice(index, 1);
    },
    focusInput($event, bw) {
      if (!bw) {
        $event.target.parentElement.style.border = "1px solid #54bd95";
      } else {
        $event.target.parentElement.style.border = "1px solid #54bd95";
      }
    },
    blurInput($event, bw) {
      const active = $event.target === document.activeElement;
      if (!active) {
        if (!bw) {
          $event.target.parentElement.style.border = "1px solid transparent";
        } else {
          $event.target.parentElement.style.border = "1px solid transparent";
        }
      }
    },
    handleTBodyAndTheads(tBodyVal, theadsVal) {
      if (this.timeoutId === null) {
        this.timeoutId = setTimeout(() => {
          // Perform your logic here
          this.bindExpression(theadsVal, tBodyVal);
          this.processTBody(tBodyVal);

          // Reset the timeoutId after the execution
          this.timeoutId = null;
        }, 0);
      }
    },
    bindExpression(output, tbody) {
      for (const item of output) {
        const labelName = item.labelName;
        const expression = item.expression;

        for (const tbodyItem of tbody) {
          if (tbodyItem[labelName]) {
            tbodyItem[labelName].expression = expression;
            break;
          }
        }
      }
    },
    processTBody(originalPayload) {
      const payloadCopy = JSON.parse(JSON.stringify(originalPayload)); // Create a deep copy of the original payload

      // payloadCopy.forEach((item) => {
      //   const arr = Object.keys(item);
      //   arr.forEach((e) => {
      //     if (item[e].operators === "between") {
      //       const value = Number(item[e].value);
      //       const value1 = Number(item[e].value1);

      //       if (!isNaN(value) && !isNaN(value1)) {
      //         // Convert the values to numbers before sorting in descending order
      //         const sortedValues = [value, value1].sort((a, b) => b - a);

      //         item[e].betweenValues = sortedValues;
      //       }
      //     }
      //   });
      // });
      this.gridData["decisions"] = payloadCopy;
      this.gridData["inputMapping"] = this.theads.input;
      this.gridData["outputMapping"] = this.theads.output;
      console.log(this.gridData);
    },
    findHighestNumberLabel(payload) {
      let highestNumber = 0;
      // let highestNumberLabel = null;

      payload.forEach((item) => {
        const label = item.labelName;
        const numberMatchArr = label.split(" ");
        // Regular expression to extract numbers from the end of the label
        const numberMatch = Number(numberMatchArr[numberMatchArr.length - 1]);
        // console.log(label, numberMatch);
        if (numberMatch) {
          const number = parseInt(numberMatch, 10);
          if (number > highestNumber) {
            highestNumber = number;
            // highestNumberLabel = label;
          }
        }
      });

      return Number(highestNumber);
    },
    rowdraggable(index) {
      document.querySelectorAll(".rowdraggable-icon").forEach((e) => {
        e.style.display = "none";
      });
      document.querySelector("#rowdraggable-icon" + index).style.display =
        "flex";
    },
    outrowdraggable() {
      document.querySelectorAll(".rowdraggable-icon").forEach((e) => {
        e.style.display = "none";
      });
    },
    deleteInputThead(val) {
      const index = this.theads.input.findIndex((item) => item.id === val.id);
      this.theads.input.splice(index, 1);
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      const index2 = inputColumnItems.findIndex(
        (item) => item.prop === val.labelName
      );
      inputColumnItems.splice(index2, 1);
      this.headvalue = inputColumnItems.concat(outputColumnItems);

      this.tBody.forEach((e) => {
        delete e[val.labelName];
      });
    },
    deleteOutputhead(val) {
      const index = this.theads.output.findIndex((item) => item.id === val.id);
      this.theads.output.splice(index, 1);
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      const index2 = outputColumnItems.findIndex(
        (item) => item.prop === val.labelName
      );
      outputColumnItems.splice(index2, 1);
      this.headvalue = inputColumnItems.concat(outputColumnItems);

      this.tBody.forEach((e) => {
        delete e[val.labelName];
      });
    },
    addInputColumn(index, pos) {
      const idNumber = this.findHighestNumberLabel(this.theads.input) + 1;
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      // console.log(inputColumnItems, outputColumnItems);
      const newItem = {
        id: Math.floor(Math.random() * 100000),
        objectMapping: {
          label: "",
          type: "",
        },
        dataType: "Data type",
        type: "input",
        operators: {},
        labelName: "Input label " + idNumber,
        source: "Manual",
        mode: "Manual",
        from: "",
        to: "",
      };
      const headProp = {
        prop: "Input label " + idNumber,
        type: "input",
        dataType: "String",
        source: "manual",
      };
      const tbodyItem = {
        value: "",
        value1: "",
        value2: "",
        operators: "",
        betweenValues: [],
      };
      if (pos === "left") {
        this.theads.input.splice(index, 0, newItem);
        inputColumnItems.splice(index, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else if (pos === "right") {
        this.theads.input.splice(index + 1, 0, newItem);
        inputColumnItems.splice(index + 1, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else {
        // Handle invalid direction here if needed
        console.error('Invalid direction provided. Use "left" or "right".');
      }
      // console.log(index);

      const keyName = "Input label " + idNumber;
      // this.headvalue.push(headProp);

      this.tBody.forEach((e) => {
        e[keyName] = tbodyItem;
      });
      this.hideIcon(index, pos, "input-");
    },
    addOutputColumn(index, pos, $event) {
      const idNumber = this.findHighestNumberLabel(this.theads.output) + 1;
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      if ($event.target.parentElement.id === "add-output-column-icon-right-0") {
        $event.target.parentElement.parentElement.parentElement.parentElement.style.borderTopRightRadius =
          "0px";
      }
      const newItem = {
        id: Math.floor(Math.random() * 100000),
        objectMapping: {
          label: "",
          type: "",
        },
        dataType: "Data type",
        type: "output",
        labelName: "Output label " + idNumber,
        source: "Manual",
        mode: "Manual",
        from: "",
        to: "",
        expression: "",
      };
      const headProp = {
        prop: "Output label " + idNumber,
        type: "output",
        dataType: "String",
        source: "manual",
      };
      const tbodyItem = {
        value: "",
        value1: "",
        value2: "",
        operators: "",
        betweenValues: [],
      };
      if (pos === "left") {
        this.theads.output.splice(index, 0, newItem);
        outputColumnItems.splice(index, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else if (pos === "right") {
        this.theads.output.splice(index + 1, 0, newItem);
        outputColumnItems.splice(index + 1, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else {
        // Handle invalid direction here if needed
        console.error('Invalid direction provided. Use "left" or "right".');
      }

      const keyName = "Output label " + idNumber;
      this.tBody.forEach((e) => {
        e[keyName] = tbodyItem;
      });
      this.hideIcon(index, pos, "output-");
    },
    showIcon(index, pos, type) {
      const icon = document.querySelector(
        "#add-" + type + "column-icon-" + pos + "-" + index
      );
      icon.style.display = "flex";
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, right } = parent.getBoundingClientRect();

      // Calculate the top and right positions for the icon
      const iconTop = top - icon.offsetHeight;
      const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      icon.style.top = iconTop + "px";
      if (pos === "right") {
        icon.style.right = iconRight - 10 + "px";
      } else {
        icon.style.right = iconRight - 4 + "px";
      }
    },
    columnover(index) {
      const icon = document.getElementById("columndrag" + index);
      icon.style.display = "flex";
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, right } = parent.getBoundingClientRect();

      // Calculate the top and right positions for the icon
      const iconTop = top - icon.offsetHeight;
      const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      icon.style.top = iconTop + 7 + "px";
      // if (pos === "right") {
      icon.style.right = iconRight + 7 + "px";
      // } else {
      // icon.style.right = iconRight - 4 + "px";
      // }
    },
    columnleave(index) {
      const icon = document.getElementById("columndrag" + index);
      icon.style.display = "none";
    },
    columnoutputover(index) {
      const icon = document.getElementById("columndragoutput" + index);
      icon.style.display = "flex";
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, right } = parent.getBoundingClientRect();

      // Calculate the top and right positions for the icon
      const iconTop = top - icon.offsetHeight;
      const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      icon.style.top = iconTop + 7 + "px";
      // if (pos === "right") {
      icon.style.right = iconRight + 7 + "px";
      // } else {
      // icon.style.right = iconRight - 4 + "px";
      // }
    },
    columnoutputleave(index) {
      const icon = document.getElementById("columndragoutput" + index);
      icon.style.display = "none";
    },

    hideIcon(index, pos, type) {
      const icon = document.querySelector(
        "#add-" + type + "column-icon-" + pos + "-" + index
      );
      icon.style.display = "none";
    },
    handleIconClick() {
      // Your logic when the icon is clicked goes here
      // console.log("Icon clicked!");
    },
    saveOutputThead(data) {
      // console.log(data);
      let beforeChange = this.theads.output.map((item) => {
        return item.id === data.id ? item.labelName : "no data";
      });
      let newVal = "";
      for (let index = 0; index < beforeChange.length; index++) {
        const element = beforeChange[index];
        if (element !== "no data") {
          newVal = element;
        }
      }
      beforeChange = newVal;
      const afterchange = data.labelName;
      // console.log(beforeChange, afterchange);
      this.replaceValuesFunc(
        beforeChange,
        afterchange,
        data.dataType,
        data.source,
        this.headvalue,
        this.tBody
      );
      const index = this.theads.output.findIndex((item) => item.id === data.id);
      // console.log(index);
      if (index !== -1) {
        this.theads.output = this.theads.output.map((item) => {
          return item.id === data.id
            ? JSON.parse(JSON.stringify(data))
            : JSON.parse(JSON.stringify(item));
        });
      }
    },
    saveInputThead(data) {
      // console.log(data, 'IP DATA ADD');
      let beforeChange = this.theads.input.map((item) => {
        return item.id === data.id ? item.labelName : "no data";
      });
      let newVal = "";
      for (let index = 0; index < beforeChange.length; index++) {
        const element = beforeChange[index];
        if (element !== "no data") {
          newVal = element;
        }
      }
      beforeChange = newVal;
      const afterchange = data.labelName;
      console.log(beforeChange, afterchange);
      this.replaceValuesFunc(
        beforeChange,
        afterchange,
        data.dataType,
        data.source,
        this.headvalue,
        this.tBody
      );
      const index = this.theads.input.findIndex((item) => item.id === data.id);
      // console.log(index);
      if (index !== -1) {
        this.theads.input = this.theads.input.map((item) => {
          return item.id === data.id
            ? JSON.parse(JSON.stringify(data))
            : JSON.parse(JSON.stringify(item));
        });
      }
    },
    replaceValuesFunc(
      beforeChange,
      afterChange,
      dataType,
      source,
      array1,
      array2
    ) {
      // console.log(beforeChange, afterChange, array1, array2);
      const index = array1.findIndex((item) => item.prop === beforeChange);
      if (index !== -1) {
        array1[index].prop = afterChange;
        array1[index].dataType = dataType;
        array1[index].source = source;
      }
      array2.forEach((obj) => {
        if (beforeChange in obj) {
          obj[afterChange] = JSON.parse(JSON.stringify(obj[beforeChange]));
          if (afterChange !== beforeChange) {
            delete obj[beforeChange];
          }
        }
      });
      // console.log(index, array1, array2);
    },
    openTheadMappingModal(type, val) {
      // console.log(type);
      if (type === "input") {
        const index = this.theads.input.length - 1;
        this.inputIndex = index;
        this.shwInpMap = true;
        this.tableHeadDetails = JSON.parse(JSON.stringify(val));
      } else if (type === "output") {
        const index = this.theads.output.length - 1;
        this.outputIndex = index;
        this.shwOutMap = true;
        this.tableHeadDetails = JSON.parse(JSON.stringify(val));
      }
    },
    columnstart(index) {
      console.log(index, "start");
      this.columndragstart = index;
    },
    columnenter(index) {
      console.log(index, "enter");
      this.columndragend = index;
    },
    columnend(value) {
      if (value === "input") {
        let input = [...this.theads.input];
        const elementToMove = input.splice(this.columndragstart - 1, 1)[0];
        input.splice(this.columndragend - 1, 0, elementToMove);
        this.theads.input = input;

        let inputColumnItems = this.headvalue.filter(
          (item) => item.type === "input"
        );
        console.log(inputColumnItems);
        let outputColumnItems = this.headvalue.filter(
          (item) => item.type === "output"
        );

        const elementToMove1 = inputColumnItems.splice(
          this.columndragstart - 1,
          1
        )[0];
        inputColumnItems.splice(this.columndragend - 1, 0, elementToMove1);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
        // console.log(this.headvalue);
      } else {
        let output = [...this.theads.output];
        const elementToMove = output.splice(this.columndragstart - 1, 1)[0];
        output.splice(this.columndragend - 1, 0, elementToMove);
        this.theads.output = output;

        let inputColumnItems = this.headvalue.filter(
          (item) => item.type === "input"
        );
        console.log(inputColumnItems);
        let outputColumnItems = this.headvalue.filter(
          (item) => item.type === "output"
        );

        const elementToMove1 = outputColumnItems.splice(
          this.columndragstart - 1,
          1
        )[0];
        outputColumnItems.splice(this.columndragend - 1, 0, elementToMove1);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
        // console.log(this.headvalue);
      }
    },
    start(index) {
      this.rowdragstart = index - 1;
      // this.startindex = index - 1;
    },
    enter(index) {
      this.rowdragend = index - 1;
      // this.endindex = index - 1;
    },
    end() {
      const elementToMove1 = this.tBody.splice(this.rowdragstart, 1)[0];
      this.tBody.splice(this.rowdragend, 0, elementToMove1);
    },
    applyColumns(val) {
      this.headvalue = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.headvalue.push(e);
        }
      });
    },
    arrange(val) {
      // console.log('arrange', val)
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      // console.log(count)
      this.rowsPerPage = count;
    },
    getDate(format) {
      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const date = ("0" + dateObj.getDate()).slice(-2);

      if (format === "number") {
        return `${year}-${month}-${date}`;
      } else if (format === "string") {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const monthName = monthNames[dateObj.getMonth()];
        return `${monthName} ${date}, ${year}`;
      } else {
        return "Invalid argument. Please enter 'number' or 'string'.";
      }
    },
  },
};
</script>
