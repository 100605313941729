<template>
  <h1 class="component-heading">Tags</h1>
  <p class="component-description">
    Users can rapidly identify key information about items by using tags to
    organise and categorise them. They graphically identify items with brief
    descriptions or information about their condition, frequently together with
    keywords that assist in classifying and organising the items.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    Set <b>class</b> to view tag in specific theme. <br />
    To specify text inside tag<b> :data</b><br />
  </p>
  <div class="component-example">
    <div class="example-body">
      <section style="display: flex; gap: 10px; flex-wrap: wrap;">
        <hlx-tag>Default</hlx-tag>
        <hlx-tag class="normal">Disabled</hlx-tag>
        <hlx-tag class="info">Information</hlx-tag>
        <hlx-tag class="danger">Danger maximum message</hlx-tag>
        <hlx-tag class="warning">Warning</hlx-tag>
      </section>
    </div>
    <div class="example-footer">
      <span id="basic-tags-icon" class="icon" @click="showCode('basic-tags')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-tags" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_tags"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Tag with close call</h2>

  <p class="component-description">
    Set <b>class</b> to view tag in specific theme. <br />
    To recieve a event use<b> : @tag-close</b><br />
  </p>
  <div class="component-example">
    <div class="example-body">
      <section style="display: flex; gap: 10px">
        <hlx-tag @tag-close="removed">Event check</hlx-tag>
      </section>
    </div>
    <div class="example-footer">
      <span
        id="event-basic-tags-icon"
        class="icon"
        @click="showCode('event-basic-tags')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="event-basic-tags" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="event_tag"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Tag attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in tagAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in tagAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <h2 class="component-side-heading">Tag Events</h2>
  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in tagEvents"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in tagEvents"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'modal')"
      ><i class="icon-angle-left-regular prev-icon"></i>Modal</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'tooltip')"
        >Tooltip <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import hlxTag from "../components/TagComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "TagView",
  components: {
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    hlxTag,
    CodeEditor,
    hlxDivider
  },
  data() {
    return {
      editor_theme: "light",
      tagAttributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      tagEvents: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameters",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          name: "class",
          description:
            "Pass the class name to utilize the variety of tag styles",
          type: "String",
          accepted_values: "info , warning , success , danger, disable",
          default: "primary",
          mandatory: true,
        },
      ],
      event_data: [
        {
          name: "tag-close",
          description: "Emit the value of closed tag",
          parameter: "Tag value",
        },
      ],
      basic_tags: `
 <template>
   <section style="display:flex;gap:10px;">
        <hlx-tag>Default</hlx-tag>
        <hlx-tag class="disable">Disabled</hlx-tag>
        <hlx-tag class="info">Information</hlx-tag>
        <hlx-tag class="danger">Danger maximum message</hlx-tag>
        <hlx-tag class="warning">Warning</hlx-tag>
      </section>
</template>

<script>
export default {
data(){
  return {

  }
}
}
<\/script>
`,
      event_tag: `
 <template>
       <section style="display:flex;gap:10px;">
            <hlx-tag @tag-close="removed">Event check</hlx-tag>
      </section>
</template>

<script>
export default {
methods: {
    removed(data){
      console.log(data)
    },
    }
}
<\/script>
`,
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    removed(data) {
      console.log("🚀 ~ file: TagView ~ removed ~ data:", data);
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
