<template>
  <h1 class="component-heading">Dropdown Color picker</h1>
  <p class="component-description">
    <b>Dropdown Color picker</b> Help the user picking beautiful colors and component that
    allows the user to choose a color
  </p>
  <br />

  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-dropdown-color-picker :config="configdata" @updated-value="selectedvalue" @deleteswatch="deleteswatch" :options="dropdown_data1" />
    </div>
    <div class="example-footer">
      <span id="email-icon" class="icon" @click="showCode('email')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="email" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="email"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in treeAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in treeAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'tabs')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tab</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'speeddial')"
      >Speed dial <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>
<script>
import HlxDropdownColorPicker from "@/components/dropdownColorPickerComponent.vue";

import CodeEditor from "simple-code-editor";
// import hlxButton from "../components/ButtonComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "HlxDropdownColorPickerView",
  components: {
    HlxDropdownColorPicker,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    // hlxButton,
    CodeEditor,
    hlxDivider,
  },
  data() {
    return {
      configdata: {
        key: "dbee69aa047d12f22275e281ee432da3:2920ca58abb53b122a1e06c94ceabdc1323d441d980a1f19d1c7f040a5e8910af798fdaf8a7213898efbbb43b74da0e3670964b9d96a4081415cca3beeed7727",
        postUrl: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/swatch",
        getUrl: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/swatch/search?search=",
       
      },
      email: `
<template>


  
</template>

<script>
export default {
data(){
  return{
   
  }
}
}
</\script>`,
dropdown_data1:[
    {
        "_id": "65fcf9285f39d07ee7793d1d",
        "name": "Acai Juice",
        "hex": "#89608C",
        "hsl": {
            "h": 301,
            "s": 63.53591,
            "l": 35.4902
        },
        "lab": {
            "l": 36.8331,
            "a": 56.2966,
            "b": -36.44397
        },
        "luminance": 51.13028,
        "luminanceWCAG": 0.0949,
        "bestContrast": "white",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=942193&name=Acai%20Juice",
            "svg": "/v1/swatch/?color=942193"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d20",
        "name": "Acanthus Leafvertical",
        "hex": "#A7D12E",
        "rgb": {
            "r": 167,
            "g": 209,
            "b": 46
        },
        "hsl": {
            "h": 74,
            "s": 12.23629,
            "l": 53.52941
        },
        "lab": {
            "l": 61.28177,
            "a": -6.55108,
            "b": 14.44993
        },
        "luminance": 99.51768,
        "luminanceWCAG": 0.29468,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=90977a&name=Acanthus%20Leaf",
            "svg": "/v1/swatch/?color=90977a"
        },
        "updatedAt": "2024-05-31T11:03:41.669Z"
    },
    {
        "_id": "65fcf9285f39d07ee7793d26",
        "name": "Accent Green Blue",
        "hex": "#208468",
        "rgb": {
            "r": 32,
            "g": 132,
            "b": 104
        },
        "hsl": {
            "h": 163,
            "s": 60.97561,
            "l": 32.15686
        },
        "lab": {
            "l": 49.13237,
            "a": -34.65655,
            "b": 6.69659
        },
        "luminance": 78.9676,
        "luminanceWCAG": 0.17809,
        "bestContrast": "white",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=208468&name=Accent%20Green%20Blue",
            "svg": "/v1/swatch/?color=208468"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d29",
        "name": "Accolade",
        "hex": "#7c94b2",
        "hsl": {
            "h": 213,
            "s": 25.96154,
            "l": 59.21569
        },
        "lab": {
            "l": 60.25996,
            "a": -3.36885,
            "b": -18.71523
        },
        "luminance": 96.61177,
        "luminanceWCAG": 0.28679,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=7c94b2&name=Accolade",
            "svg": "/v1/swatch/?color=7c94b2"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d2a",
        "name": "Accursed Black",
        "hex": "#090807",
        "rgb": {
            "r": 9,
            "g": 8,
            "b": 7
        },
        "hsl": {
            "h": 30,
            "s": 12.5,
            "l": 3.13725
        },
        "lab": {
            "l": 2.23778,
            "a": 0.16779,
            "b": 0.47765
        },
        "luminance": 5.4709,
        "luminanceWCAG": 0.00247,
        "bestContrast": "white",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=090807&name=Accursed%20Black",
            "svg": "/v1/swatch/?color=090807"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d2b",
        "name": "Ace",
        "hex": "#D1D4E7",
        "rgb": {
            "r": 209,
            "g": 212,
            "b": 231
        },
        "hsl": {
            "h": 231,
            "s": 40,
            "l": 84.31373
        },
        "lab": {
            "l": 82.292,
            "a": 2.22288,
            "b": -14.02454
        },
        "luminance": 136.28438,
        "luminanceWCAG": 0.61097,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=c7cce7&name=Ace",
            "svg": "/v1/swatch/?color=c7cce7"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d2c",
        "name": "Aceituna Picante",
        "hex": "#727a5f",
        "hsl": {
            "h": 78,
            "s": 12.4424,
            "l": 42.54902
        },
        "lab": {
            "l": 49.96513,
            "a": -6.91396,
            "b": 13.79182
        },
        "luminance": 80.04817,
        "luminanceWCAG": 0.18323,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=727a5f&name=Aceituna%20Picante",
            "svg": "/v1/swatch/?color=727a5f"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d2f",
        "name": "Acid Blond",
        "hex": "#efedd7",
        "hsl": {
            "h": 55,
            "s": 42.85714,
            "l": 89.01961
        },
        "lab": {
            "l": 93.46585,
            "a": -2.05755,
            "b": 10.95638
        },
        "luminance": 158.30828,
        "luminanceWCAG": 0.83825,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=efedd7&name=Acid%20Blond",
            "svg": "/v1/swatch/?color=efedd7"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d39",
        "name": "Acini Peanent",
        "hex": "#C5884C",
        "rgb": {
            "r": 197,
            "g": 136,
            "b": 76
        },
        "hsl": {
            "h": 30,
            "s": 100,
            "l": 84.70588
        },
        "lab": {
            "l": 88.9898,
            "a": 10.0112,
            "b": 24.6197
        },
        "luminance": 149.32067,
        "luminanceWCAG": 0.73546,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=ffd8b1&name=Acini%20di%20Pepe",
            "svg": "/v1/swatch/?color=ffd8b1"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d3a",
        "name": "Aconite Purpless",
        "hex": "#7249d6",
        "hsl": {
            "h": 257,
            "s": 63.2287,
            "l": 56.27451
        },
        "lab": {
            "l": 42.14884,
            "a": 42.9696,
            "b": -67.73761
        },
        "luminance": 59.94354,
        "luminanceWCAG": 0.13198,
        "bestContrast": "white",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=7249d6&name=Aconite%20Purple",
            "svg": "/v1/swatch/?color=7249d6"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d3d",
        "name": "Acorn Nut",
        "hex": "#A15D22",
        "rgb": {
            "r": 161,
            "g": 93,
            "b": 34
        },
        "hsl": {
            "h": 28,
            "s": 61.9469,
            "l": 55.68627
        },
        "lab": {
            "l": 64.14088,
            "a": 25.18748,
            "b": 46.39505
        },
        "luminance": 102.72597,
        "luminanceWCAG": 0.32356,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=d48948&name=Acorn%20Nut",
            "svg": "/v1/swatch/?color=d48948"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d43",
        "name": "Actinic Light",
        "hex": "#ff44ee",
        "rgb": {
            "r": 255,
            "g": 68,
            "b": 238
        },
        "hsl": {
            "h": 305,
            "s": 100,
            "l": 63.33333
        },
        "lab": {
            "l": 62.99181,
            "a": 81.75234,
            "b": -46.61376
        },
        "luminance": 90.23709,
        "luminanceWCAG": 0.31567,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=ff44ee&name=Actinic%20Light",
            "svg": "/v1/swatch/?color=ff44ee"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d46",
        "name": "Active Turquoise",
        "hex": "#006f72",
        "rgb": {
            "r": 0,
            "g": 111,
            "b": 114
        },
        "hsl": {
            "h": 182,
            "s": 100,
            "l": 22.35294
        },
        "lab": {
            "l": 41.8691,
            "a": -26.65844,
            "b": -9.77383
        },
        "luminance": 66.44043,
        "luminanceWCAG": 0.12584,
        "bestContrast": "white",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=006f72&name=Active%20Turquoise",
            "svg": "/v1/swatch/?color=006f72"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d48",
        "name": "Actor’s Star",
        "hex": "#a7a6a3",
        "rgb": {
            "r": 167,
            "g": 166,
            "b": 163
        },
        "hsl": {
            "h": 45,
            "s": 2.22222,
            "l": 64.70588
        },
        "lab": {
            "l": 68.13454,
            "a": -0.04769,
            "b": 1.69001
        },
        "luminance": 111.05647,
        "luminanceWCAG": 0.38132,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=a7a6a3&name=Actor%E2%80%99s%20Star",
            "svg": "/v1/swatch/?color=a7a6a3"
        }
    },
    {
        "_id": "65fcf9285f39d07ee7793d4a",
        "name": "Adamite Green",
        "hex": "#3b845e",
        "rgb": {
            "r": 59,
            "g": 132,
            "b": 94
        },
        "hsl": {
            "h": 149,
            "s": 38.2199,
            "l": 37.45098
        },
        "lab": {
            "l": 49.72922,
            "a": -30.98018,
            "b": 13.38991
        },
        "luminance": 80.18608,
        "luminanceWCAG": 0.1824,
        "bestContrast": "black",
        "swatchImg": {
            "svgNamed": "/v1/swatch/?color=3b845e&name=Adamite%20Green",
            "svg": "/v1/swatch/?color=3b845e"
        }
    }
],
      editor_theme: "light",
      event_data: [
        {
          name: "updated-value",
          description: "emits the value",
          parameter: "",
        },
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          attribute: "config",
          description:
            "Provied the Api key for the authentication and Post ,get API for add & get values ",
          type: "Object",
          accepted_values: ` config: {
        key:"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9",
        postUrl: "https://example/swatch",
        getUrl:"https://example/swatch/search?search=",
      },`,
          default: "-",
          mandatory: true,
        },
        {
          attribute: "pre-value",
          description: "Pre-populate data in swatch",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
      ],
      treeAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
    };
  },
  methods: {
    selectedvalue(val) {
      console.log(val);
    },
    handleEnter() {
      if (this.inputValue.trim() !== "") {
        this.enteredValues.push(this.inputValue); // Add entered value to the beginning of the array
        this.inputValue = ""; // Clear the input field
      }
    },
    deleteswatch(val){
      console.log(val,"deleteswatch");
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
