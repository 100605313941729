<template>
  <li
    @mousedown="mouseDown"
    @mouseup="mouseUp"
    @mousemove="mouseMove"
    @dblclick="mouseDown"
  >
    <hlx-card
      :seperator="false"
      :card_title="false"
      :card_footer="false"
      :hover="true"
    >
      <template #card-body>
        <div
          v-if="type === 'Hierarchy' || type === 'orderTree'"
          style="
            padding: 0;
            height: 55px;
            width: 298px;
            align-items: center;
            display: flex;
            justify-content: center;
          "
          class="tree-card-upc"
        >
          <div class="header grid card" style="padding: 0 9px; width: 100%">
            <span v-if="type === 'Hierarchy'" :style="avatarStyle">
              {{ initials }}
            </span>
            <span style="padding: 0 0 0 9px">
              <span v-if="nodes['@type'] === 'productOrder'"
                ><i class="icon-box-check-regular tree-icone3 odertree"
              /></span>
              <span v-if="nodes['@type'] === 'serviceOrder'"
                ><i class="icon-users-group-alt-regular tree-icone2 odertree"
              /></span>
              <span v-if="nodes['@type'] === 'resourceOrder'"
                ><i class="icon-shield-bolt-regular tree-icon odertree"
              /></span>
              <span
                v-if="
                  nodes['@type'] === 'resourceOrderItem' ||
                  nodes['@type'] === 'serviceOrderItem' ||
                  nodes['@type'] === 'productOrderItem'
                "
              >
                <i class="icon-tag-regular tree-icon-Logical odertree"
              /></span>
            </span>
            <span
              style="
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
              "
            >
              <span
                class="Card-name-tree"
                :class="{ 'margin-6px': hasDescriptionOrAddress }"
                style="
                  display: flex;
                  justify-content: space-evenly;
                  min-width: 63px;
                  max-width: 200px;

                  text-decoration: none !important;
                "
              >
                <span style="display: flex; justify-content: center">
                  <span
                    style="
                      font-size: 12px;
                      text-align: left;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    "
                  >
                    <span v-if="type === 'Hierarchy'">{{
                      nodes?.customName
                    }}</span>

                    <span @click="oderdetails">
                      <span
                      
                        v-if="
                          nodes['@type'] === 'productOrder' ||
                          nodes['@type'] === 'serviceOrder' ||
                          nodes['@type'] === 'resourceOrder'
                        "
                        class="carddetailoderdetails"
                        @click="odermove"
                        >{{ nodes?.displayID }}</span
                      >
                      <span
                        v-if="
                          nodes['@type'] === 'resourceOrderItem' ||
                          nodes['@type'] === 'serviceOrderItem'
                        "
                         class="carddetailoderdetails"
                        @click="odermove"
                        >{{ nodes?.specification?.name }}</span
                      >
                      <span
                        v-if="nodes['@type'] === 'productOrderItem'"
                        class="carddetailoderdetails"
                        @click="odermove"
                        >{{ nodes.productOffering.name }}</span
                      ></span
                    >
                  </span>
                  <span
                    v-if="type === 'Hierarchy'"
                    :style="{
                      display: HierarchytagName !== '' ? 'block' : 'none',
                    }"
                  >
                    <hlx-label
                      class="primary sm"
                      style="margin-left: 6px; height: 14px; font-size: 8px"
                    >
                      {{ HierarchytagName }}
                    </hlx-label>
                  </span>
                  <span v-if="type === 'orderTree' && nodes.state" style="margin-left: 6px;">
                    <hlx-status :type="nodes.state" />
                  </span>
                </span>
              </span>

              <span
                v-if="type === 'Hierarchy'"
                class="card-address"
                style="
                  font-size: 8px;
                  display: -webkit-box;
                  max-width: 179px;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-align: left;
                  font-family: Opensans;
                  font-weight: 100;
                "
              >
                <span>{{ Hierarchydescription }}</span>
              </span>
              <span
                v-if="type === 'orderTree'"
                class="card-address"
                style="
                  font-size: 8px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-align: left;
                  font-family: Opensans;
                  font-weight: 100;
                "
              >
                <span>{{ nodes?.description }}</span>
              </span>
            </span>
          </div>
          <div
            v-for="key in Object.keys(nodes.data ? nodes.data : {}) || []"
            :key="key"
            class="grid"
          >
            <span class="key">{{ key }}:</span>
            <span class="value">{{ nodes.data[`${key}`] }}</span>
          </div>
          <span
            :class="
              nodes.child !== undefined && nodes.child?.length > 0
                ? 'collapseIcon'
                : ''
            "
            style="right: 48.3% !important"
            ><i
              :ref="'icon-' + unique"
              :class="
                nodes.child !== undefined && nodes.child?.length > 0
                  ? collapse
                  : ''
              "
              @click="nodesToggler"
            ></i
          ></span>
          <i
            v-if="type == 'Hierarchy'"
            class="tempp-container icon-more-vertical-filled"
            :class="{ ' active-context': show_add_menu === true }"
            @click.stop="showHierarchy(nodes)"
          >
          </i>
          <span
            style="
              position: absolute;
              left: 247px;
              top: 66px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <i v-if="type == 'Hierarchy'" class="icon-user-circle-regular"></i>
            <span
              v-if="type == 'Hierarchy'"
              style="font-size: 12px; margin-left: 3px"
            >
              {{ Hierarchycontactcount }}
            </span>
            <span v-if="nodes.quantity">
              <i
                v-if="
                  nodes['@type'] === 'resourceOrderItem' ||
                  nodes['@type'] === 'serviceOrderItem' ||
                  nodes['@type'] === 'productOrderItem'
                "
                class="icon-shopping-cart-regular"
              ></i>
              <span
                v-if="
                  nodes['@type'] === 'resourceOrderItem' ||
                  nodes['@type'] === 'serviceOrderItem' ||
                  nodes['@type'] === 'productOrderItem'
                "
                style="font-size: 12px; margin-left: 3px"
              >
                {{ nodes.quantity }}
              </span>
            </span>
          </span>
          <hlx-context-menu
            class="tree-context-menu"
            style="
                z-index: 1000 !important;
                width: 125px;
                position: absolute;
                height: (add_context_options.length>1) ? 119px : 50px;
                overflow: hidden;
              "
            :options="add_context_options"
            :show="show_add_menu"
            @chosen="fromAddContextMenuHierarchy($event, nodes)"
          ></hlx-context-menu>
        </div>
        <div
          v-if="type != 'Hierarchy' && type != 'orderTree'"
          style="
            padding: 0;
            height: 55px;
            width: 200px;
            align-items: center;
            display: flex;
            justify-content: center;
          "
          class="tree-card-upc"
        >
          <div
            class="header grid card"
            style="padding: 0 9px"
            @click.stop="card"
          >
            <span><i :class="nodes?.service?.icones"></i></span>
            <span class="Card-name-tree" @click="cardDetail">{{
              nodes?.specification?.name
            }}</span>
          </div>
          <div
            v-for="key in Object.keys(nodes.data ? nodes.data : {}) || []"
            :key="key"
            class="grid"
          >
            <span class="key">{{ key }}:</span>
            <span class="value">{{ nodes.data[`${key}`] }}</span>
          </div>
          <span
            :class="
              nodes.child !== undefined && nodes.child?.length > 0
                ? 'collapseIcon'
                : ''
            "
            ><i
              :ref="'icon-' + unique"
              :class="
                nodes.child !== undefined && nodes.child?.length > 0
                  ? collapse
                  : ''
              "
              @click="nodesToggler"
            ></i
          ></span>
          <i
            class="tempp-container icon-more-vertical-filled"
            :class="{ ' active-context': show_add_menu === true }"
            @click.stop="show(nodes)"
          >
          </i>

          <hlx-context-menu
            class="tree-context-menu"
            style="
                z-index: 1000 !important;
                width: 115px;
                position: absolute;
                height: (add_context_options.length>1) ? 119px : 50px;
                overflow: hidden;
              "
            :options="add_context_options"
            :show="show_add_menu"
            @chosen="fromAddContextMenu($event, nodes)"
          ></hlx-context-menu>
        </div>

        <!-- </div> -->
      </template>
    </hlx-card>

    <div v-if="isOpen" :class="'tree-child-card'" style="z-index: 1000">
      <div class="inner-card-details">
        <i :class="nodes?.service?.icones + ' size-tree-icon'"></i>

        <span v-if="nodes?.specification?.lifecycleStatus?.length > 0">
          <hlx-status :type="nodes?.specification?.lifecycleStatus" />
        </span>
        <!-- <hlx-status
          v-if=" == 'Active'"
          :type="'active'"
          >
        </hlx-status>
        <hlx-label
          v-else-if="nodes?.specification?.lifecycleStatus == 'Launched'"
          class="success sm"
        >
          Launched
        </hlx-label>
        <hlx-label
          v-else-if="nodes?.specification?.lifecycleStatus == 'Retired'"
          class="info sm"
        >
          Retired
        </hlx-label>
        <hlx-label
          v-else-if="nodes?.specification?.lifecycleStatus == 'Rejected'"
          class="error sm"
        >
          Rejected
        </hlx-label>
        <hlx-label
          v-else-if="nodes?.specification?.lifecycleStatus == 'Obsolete'"
          class="warning sm"
        >
          Obsolete
        </hlx-label>
        <hlx-label
          v-else-if="nodes?.specification?.lifecycleStatus == 'In test'"
          class="warning sm"
          :options="{
            'background-color': '#F4F4F4',
            'text-color': '#4B4B4B',
            'border-color': '#4B4B4B',
          }"
        >
          In test
        </hlx-label>
        <hlx-label
          v-else-if="nodes?.specification?.lifecycleStatus == 'In design'"
          class="warning sm"
          :options="{
            'background-color': '#FFFDE9',
            'text-color': '#897E06',
            'border-color': '#897E06',
          }"
        >
          In design
        </hlx-label>
        <hlx-label
          v-else
          class="warning sm"
          :options="{
            'background-color': '#E9ECFF',
            'text-color': '#354592',
            'border-color': '#354592',
          }"
        >
          In study
        </hlx-label> -->
      </div>
      <b style="font-size: 14px">{{ nodes?.specification?.name }}</b>
      <br />

      <p
        style="
          margin: 10px 0;
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        "
      >
        {{ nodes?.specification?.description }}
      </p>

      <span :style="getStyleObject">
        <b :style="getStyleObject" style="font-size: 14px">Characteristics</b>

        <div
          v-if="
            nodes?.service?.name === 'Customer Facing Service' &&
            nodes?.specification?.serviceSpecCharacteristic !== undefined &&
            nodes?.specification?.serviceSpecCharacteristic?.length > 0
          "
          style="margin: 10px 0; font-size: 12px"
        >
          <div class="tree-dot">
            <span style="font-size: 1rem; display: flex; align-items: center">
              <span
                style="
                  width: 8px;
                  height: 8px;
                  background: rgba(84, 189, 149, 1);
                  border-radius: 100px;
                "
              >
              </span>
              <span style="font-size: 12px !important; margin-left: 5px">{{
                nodes?.specification?.serviceSpecCharacteristic[0]?.name
              }}</span></span
            >
          </div>

          <div class="tree-dot">
            <span style="font-size: 1rem; display: flex; align-items: center">
              <span
                style="
                  width: 8px;
                  height: 8px;
                  background: rgba(84, 189, 149, 1);
                  border-radius: 100px;
                "
              >
              </span>
              <span style="font-size: 12px !important; margin-left: 5px">{{
                nodes?.specification?.serviceSpecCharacteristic[1]?.name
              }}</span></span
            >
          </div>
        </div>
        <div
          v-if="
            (nodes?.service?.name === 'Logical Resource' ||
              nodes?.service?.name === 'Physical Resource' ||
              nodes?.service?.name === 'Resource Facing Service' ||
              nodes?.service?.name === 'Resource Facing Service') &&
            nodes?.specification?.resourceSpecCharacteristic?.length > 0
          "
          style="margin: 10px 0; font-size: 12px"
        >
          <div class="tree-dot">
            <span style="font-size: 1rem; display: flex; align-items: center">
              <span
                style="
                  width: 8px;
                  height: 8px;
                  background: rgba(84, 189, 149, 1);
                  border-radius: 100px;
                "
              >
              </span>
              <span style="font-size: 12px !important; margin-left: 5px">{{
                nodes?.specification?.resourceSpecCharacteristic[0]?.name
              }}</span></span
            >
          </div>

          <div class="tree-dot">
            <span style="font-size: 1rem; display: flex; align-items: center">
              <span
                style="
                  width: 8px;
                  height: 8px;
                  background: rgba(84, 189, 149, 1);
                  border-radius: 100px;
                "
              >
              </span>
              <span style="font-size: 12px !important; margin-left: 5px">{{
                nodes?.specification?.resourceSpecCharacteristic[1]?.name
              }}</span></span
            >
          </div>
        </div>
      </span>
      <div
        class="link-tree"
        style="font-size: 12px; color: rgba(67, 129, 249, 1)"
        @click="Cardemit(nodes)"
      >
        View details
      </div>
    </div>
    <div
      v-if="oderdetail && type === 'orderTree'"
      :class="'ordertreedetilecard'"
      style="z-index: 1000"
    >
      <div class="inner-card-details oder-card-details">
        <span>
          <span v-if="nodes['@type'] === 'productOrder'"
            ><i class="icon-box-check-regular tree-icone3 odertree"
          /></span>
          <span v-if="nodes['@type'] === 'serviceOrder'"
            ><i class="icon-users-group-alt-regular tree-icone2 odertree"
          /></span>
          <span v-if="nodes['@type'] === 'resourceOrder'"
            ><i class="icon-shield-bolt-regular tree-icon odertree"
          /></span>
          <span
            v-if="
              nodes['@type'] === 'resourceOrderItem' ||
              nodes['@type'] === 'serviceOrderItem' ||
              nodes['@type'] === 'productOrderItem'
            "
          >
            <i class="icon-tag-regular tree-icon-Logical odertree"
          /></span>
        </span>

        <span v-if="type === 'orderTree' && nodes.state">
          <hlx-status :type="nodes.state" />
        </span>
      </div>
      <b style="font-size: 14px">
        <span
          v-if="
            nodes['@type'] === 'productOrder' ||
            nodes['@type'] === 'serviceOrder' ||
            nodes['@type'] === 'resourceOrder'
          "
          @click="odermove"
          >{{ nodes?.displayID }}</span
        >
        <span
          v-if="
            nodes['@type'] === 'resourceOrderItem' ||
            nodes['@type'] === 'serviceOrderItem'
          "
          @click="odermove"
          >{{ nodes?.specification?.name }}</span
        >
        <span v-if="nodes['@type'] === 'productOrderItem'" @click="odermove">{{
          nodes.productOffering.name
        }}</span></b
      >
      <div
        v-if="nodes?.specification?.category?.length > 0"
        style="font-size: 9px; color: rgba(112, 112, 112, 1)"
      >
        {{
          nodes?.specification?.category[
            nodes?.specification?.category.length - 1
          ]
        }}
      </div>
      <br />

      <div
        style="
          margin: 10px 0;
          font-size: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        "
      >
        {{ nodes?.description }}
      </div>
      <div
        class="link-tree"
        style="font-size: 12px; color: rgba(67, 129, 249, 1)"
        @click="Cardemit(nodes)"
      >
        View details
      </div>
    </div>

    <ul v-if="nodes && nodes.child && nodes.child?.length > 0" :ref="'ul'">
      <hlx-tree-child-v2
        v-for="child in nodes.child || nodes.child[0]"
        :key="child"
        :nodes="child"
        :type="type"
        :parent-name="parentName"
        @context="fromAddContextMenuHierarchy"
      >
        <slot></slot>
      </hlx-tree-child-v2>
    </ul>
  </li>
</template>

<script>
import hlxCard from "./CardsComponent.vue";
import hlxContextMenu from "../components/Treecontextmenu.vue";
import HlxStatus from "../components/StatusComponent.vue";
import HlxLabel from "../components/LabelComponent.vue";
// import { HlxLabel } from 'production/dist/cjs';
export default {
  name: "HlxTreeChildV2",
  components: {
    HlxLabel,
    hlxCard,
    HlxStatus,
    hlxContextMenu,
  },

  props: {
    type: {
      type: String,
      default: () => "product",
    },
    nodes: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isDraggable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    parentInst: {
      type: String,
      default: undefined,
    },
    parentName: {
      type: String,
      default: "",
    },
  },
  emits: [
    "context",
    "mouseeDown",
    "mouseeUp",
    "mouseeMove",
    "delete-nodes",
    "card-nodes",
    "currentdata",
    "specification",
    "currenteditdata",
    "ParentDetail",
  ],
  data() {
    return {
      oderdetail: false,
      isOpen: false,
      collapse: "icon-minus-circle-filled downIcon",
      unique: "",
      treeUnique: "",
      disableTreeButton: "",
      add_context_options: [],
      show_add_menu: false,
      HierarchytagName: "",
      Hierarchydescription: "",
      Hierarchycontactcount: 0,
    };
  },

  computed: {
    hasDescriptionOrAddress() {
      return this.nodes.description || this.Hierarchydescription;
    },
    initials() {
      const firstTwoWords = this.nodes?.tradingName?.split(" ")?.slice(0, 2);
      return firstTwoWords
        ?.map((word) => word.charAt(0))
        ?.join("")
        ?.toUpperCase();
    },
    avatarStyle() {
      const backgroundColor = this.generateColor(this?.HierarchytagName);
      return {
        width: `35px`,
        height: `35px`,
        borderRadius: "50%",
        background: backgroundColor,
        color: "#ffffff", // Customize the text color
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: `15px`,
      };
    },
    getStyleObject() {
      return {
        display:
          (this.nodes?.service?.name === "Customer Facing Service" &&
            this.nodes?.specification?.serviceSpecCharacteristic?.length > 0) ||
          ((this.nodes?.service?.name === "Logical Resource" ||
            this.nodes?.service?.name === "Physical Resource" ||
            this.nodes?.service?.name === "Resource Facing Service" ||
            this.nodes?.service?.name === "Resource Facing Service") &&
            this.nodes?.specification?.resourceSpecCharacteristic?.length > 0)
            ? "block"
            : "none",
      };
    },
  },
  watch: {
    type: {
      handler(val) {
        // // console.log (this.nodes);
        if (val === "product") {
          this.placeholderValue = "Choose the service";
        } else if (val === "resource") {
          this.placeholderValue = "Type of resource";
        } else if (val === "service") {
          this.placeholderValue = "Choose resource";
        } else {
          this.placeholderValue = "Type of resource";
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.unique = Math.random() * 1000;
    window.addEventListener("click", this.hide);
    window.addEventListener("click", this.odercard);


    const postalAddressContactMedium = this.nodes?.contactMedium?.find(
      (medium) => medium?.mediumType === "postalAddress"
    );

    console.log(postalAddressContactMedium, "postalAddressContactMedium");
    if (postalAddressContactMedium) {
      this.HierarchytagName =
        postalAddressContactMedium?.characteristic["@type"];
      this.Hierarchydescription =
        postalAddressContactMedium?.characteristic?.description;
      const postCode = postalAddressContactMedium?.postCode;

      console.log("City:", this.HierarchytagName);
      console.log("Country:", this.Hierarchydescription);
      console.log("Post Code:", postCode);
    } else {
      console.log("No contactMedium with mediumType 'postalAddress' found.");
    }

    const relatedParty = this.nodes.relatedParty;
    if (relatedParty && relatedParty.length > 0) {
      let count = 0;
      for (let i = 0; i < relatedParty.length; i++) {
        const role = relatedParty[i].role;
        if (role !== "Account executive") {
          count++;
        }
      }
      this.Hierarchycontactcount = count;
    }
  },
  methods: {
    oderdetails() {
      this.oderdetail = !this.oderdetail;
      this.isOpen = !this.isOpen;

      console.log("fjmglfmrglfkml", this.isOpen);
    },
    odermove() {
      const treeChildCards = document?.querySelectorAll(".ordertreedetilecard");
      treeChildCards?.forEach((card) => {
        card.style.display = "none";
      });
    },
    generateColor(name) {
      const hashCode = this.hashCode(name);
      const hue = Math.abs(hashCode % 360);

      // Exclude specific hues for black, white, red, and dark colors
      const excludedHues = [0, 360, 240, 0, 10, 20, 30]; // Add more hues as needed

      // Check if the hue is within the excluded range
      if (excludedHues.includes(hue)) {
        // If it matches, adjust the hue slightly
        return `hsl(${(hue + 45) % 360}, 70%, 50%)`; // Adjust the hue by 45 degrees
      }

      return `hsl(${hue}, 70%, 50%)`;
    },
    hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    cardDetail() {
      const treeChildCards = document?.querySelectorAll(".tree-child-card");
      treeChildCards?.forEach((card) => {
        card.style.display = "none";
      });
    },

    Cardemit(nodes) {
      console.log(nodes,"Cardemit");
      this.$emit("card-nodes", nodes);

    },

    showHierarchy() {
      this.cardDetail();
      const treeChildCards = document?.querySelectorAll(".tree-context-menu");
      treeChildCards?.forEach((card) => {
        card.style.display = "none";
      });
      if (
        this.type == "Hierarchy" ||
        (this.type != "orderTree" &&
          this.nodes.organizationParentRelationship === undefined)
      ) {
        this.add_context_options = [
          {
            label: "Link a child",
            icon: "icon-plus-circle-filled",
          },
        ];
      } else {
        this.add_context_options = [
          {
            label: "Link a child",
            icon: "icon-plus-circle-filled",
          },
          {
            label: "Remove",
            icon: "icon-trash-regular",
          },
        ];
      }
      if (this.show_add_menu == false) {
        this.show_add_menu = true;
      } else {
        this.show_add_menu = false;
        // document.querySelector(".tempp-container").style.display = "none";
      }
    },
    show(val) {
      console.log(val, "card details");

      this.$emit("ParentDetail", val);
      this.cardDetail();
      const treeChildCards = document?.querySelectorAll(".tree-context-menu");
      treeChildCards?.forEach((card) => {
        card.style.display = "none";
      });
      console.log(this.parentName, "parentName");
      if (this.nodes?.specification?.name === this.parentName) {
        this.add_context_options = [
          {
            label: "Add",
            icon: "icon-plus-circle-filled",
          },
        ];
      } else if (
        this.nodes?.service?.name === "Physical Resource" ||
        this.nodes?.service?.name === "Logical Resource"
      ) {
        this.add_context_options = [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } else {
        this.add_context_options = [
          {
            label: "Add",
            icon: "icon-plus-circle-filled",
          },
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }
      if (this.show_add_menu == false) {
        this.show_add_menu = true;
      } else {
        this.show_add_menu = false;
      }
    },

    card() {
      this.show_add_menu = false;
      this.isOpen = !this.isOpen;
    },
    fromAddContextMenuHierarchy(val, nodes) {
      this.$emit("context", val, nodes, nodes?.service?.name);
    },
    fromAddContextMenu(val, nodes) {
      this.show_add_menu = false;
      this.$emit("context", val, nodes, nodes?.service?.name);
    },

    nodesToggler(event) {
      // const ul = event.target.parentElement.nextElementSibling
      const ul = this.$refs["ul"];
      const targetClassList = event.target.classList;
      const ulClassList = ul.classList;
      const isRoot = event.target.parentElement.classList.contains("root");
      const icon = document?.querySelector(".tree-container > i");

      if (
        targetClassList.contains("icon-minus-circle-filled") &&
        targetClassList.contains("downIcon")
      ) {
        event.target.setAttribute("class", "icon-plus-circle-filled downIcon");
      } else {
        event.target.setAttribute("class", "icon-minus-circle-filled downIcon");
      }

      ulClassList.toggle("hide");

      if (isRoot) {
        if (!targetClassList.contains("icon-minus-circle-filled")) {
          icon.setAttribute("class", "icon-full-page");
        } else {
          icon.setAttribute("class", "icon-vEdge");
        }
      }
    },
    hide() {
      if (event.target.classList.contains("tempp-container") == false) {
        this.show_add_menu = false;
        this.isOpen = false;
      }
    },

    odercard(){
      // console.log(event.target.classList.contains("carddetailoderdetails"),"event.target.classList");
      if(event.target.classList.contains("carddetailoderdetails") == false){
        this.oderdetail=false
      }
    },

    display() {
      if (event.target.classList.contains("tempp-container") == true) {
        if (this.clicked == true) {
          this.clicked = false;
        } else {
          this.clicked = true;
        }
      } else {
        this.clicked = false;
      }
    },
  },
};
</script>
<style lang="scss">
.order-status-display {
  .status-wrapper {
    .status {
      margin-left: 24px !important;
    }
  }
}
.margin-6px {
  margin-bottom: 6px;
}
@media only screen and (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .card {
    user-select: none !important;
    transform: translate(0px, 0px) scale(0.7) !important;
  }
  .hlx-tree-area-upc.vertical li div.card {
    margin: 22px 17.5px;
    min-width: 150px;
    min-height: 90px;
    display: flex;
    justify-content: center;
  }
}
.edit-select-tree-disable {
  .hlx-select-container {
    .hlx-select-input {
      cursor: not-allowed;
    }
    cursor: not-allowed;
    .hlx-select-icon {
      cursor: not-allowed;
      .icon-angle-down-regular {
        cursor: not-allowed;
      }
    }
  }
}

.Card-name-tree {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.Card-name-tree:hover {
  text-decoration: underline;
}
.link-tree:hover {
  text-decoration: underline;
}
.tree-child-button-container {
  display: flex;
  gap: 10%;
  width: 100%;
}
.tempp-container {
  display: none;
}
.cardhover:hover {
  .tempp-container {
    display: block;
  }
}

.active-context {
  display: block;
}
.tempp-container {
  pointer-events: auto;
  cursor: pointer;
  margin: 0 5px;
  position: absolute;
  right: 4px;
  top: 13px;
}
.tree-context-menu {
  pointer-events: auto;
  cursor: pointer;
  margin: 0 5px;
  position: absolute;
  right: -100px;
  top: 32px;
  .icon-plus-circle-filled {
    color: #54bd95 !important;
  }
  .icon-trash-regular {
    color: #ff5050 !important;
  }
  .icon-file-text-edit-regular {
    color: #2879b7 !important;
  }
}
.tree-child-card {
  #hlx-button-0009.hlx-button {
    padding: 0px 16px;
    height: 33px;
  }

  .inner-card-details {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 21px;
    .size-tree-icon {
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
    .lifecycleStatus-tree {
      border: 1px solid rgba(38, 94, 75, 1);
      background-color: rgba(168, 222, 201, 1);
      padding: 2px 10px;
      max-width: 120px;
      font-size: 12px;
      min-width: 50px;
      text-align: center;
      border-radius: 6px;
    }
  }
  .tree-icon {
    width: 29px;
    height: 29px;
  }
  z-index: 1000;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(84, 189, 149, 1);
  max-width: 290px;
  max-height: 500px;
  min-width: 290px;
  position: absolute;
  z-index: 1;
  top: 0;
  right: -300px;
  padding: 22px;
}

.tree-icon-Logical {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(175, 140, 233, 0.45);
  color: #af8ce9;
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icon-Physical {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(182, 78, 82, 0.16);
  color: rgba(165, 88, 118, 1);

  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icon-legent {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(101, 155, 185, 0.23);
  color: rgba(66, 144, 189, 1);

  // : #e286c3;
  font-size: 12px;
  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(101, 155, 185, 0.23);
  color: rgba(66, 144, 189, 1);

  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-service {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(159, 162, 251, 0.45);
  color: #8c90e9;

  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icone2-legent {
  // border: 1px solid #adc4ff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(246, 218, 237, 0.7);
  color: rgba(237, 104, 187, 1);
  font-size: 12px;
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icone2 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(246, 218, 237, 0.7);
  color: rgba(237, 104, 187, 1);
  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icone3 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(173, 196, 255, 0.5);

  color: rgba(68, 119, 255, 1);
  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}

.tree-icone3-legent {
  // border: 1px solid #fee1b6;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(173, 196, 255, 0.5);

  color: rgba(68, 119, 255, 1);
  font-size: 12px;
  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-icon-Logical-legent {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(175, 140, 233, 0.45);
  color: #af8ce9;

  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.odertree {
  width: 30px !important;
  height: 30px !important;
}
.tree-icon-Physical-legent {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: rgba(182, 78, 82, 0.16);

  color: rgba(165, 88, 118, 1);

  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.tree-service-legent {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(159, 162, 251, 0.45);
  color: #8c90e9;

  /* position: absolute; */
  align-content: flex-end;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.hlx-tree-area-upc li > div.card {
  border-radius: 15px;
}

.tree-panel {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  width: 100%;
  height: 100%;
  // padding: 20px;
  // .custom-dropdown-whole-container{
  //   top:-142px !important;
  //   .customdropdown-option-container{
  //     max-height: 132px !important;
  //   }
  // }
  // overflow-y: auto;
  .tree-select {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  // .hierarchyBody{
  //   width: 373px;
  //   align-items: center;
  //   display: flex;
  //   /* position: absolute; */
  //   justify-content: center;
  //   /* overflow: hidden; */
  //   padding: 0px;
  //   margin: auto;
  // }
}

.ordertreedetilecard {
  #hlx-button-0009.hlx-button {
    padding: 0px 16px;
    height: 33px;
  }

  .inner-card-details {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 6px;
    .size-tree-icon {
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
    .lifecycleStatus-tree {
      border: 1px solid rgba(38, 94, 75, 1);
      background-color: rgba(168, 222, 201, 1);
      padding: 2px 10px;
      max-width: 120px;
      font-size: 12px;
      min-width: 50px;
      text-align: center;
      border-radius: 6px;
    }
  }
  .tree-icon {
    width: 29px;
    height: 29px;
  }
  z-index: 1000;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(84, 189, 149, 1);
  max-width: 290px;
  max-height: 500px;
  min-width: 290px;
  position: absolute;
  z-index: 1;

  padding: 22px;
  top: 37px;
  /* right: -300px; */
  margin-left: 616px;
}
</style>
