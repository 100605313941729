<template>
  <Layout />
</template>
<script>
import Layout from "../components/LayoutComponent.vue";
export default {
  name: "LayoutView",
  components: {
    Layout,
  },
  mounted() {},
};
</script>
