<template>
  <div
    class="card"
    :style="'width:' + width + 'px; height:' + height + 'px;'"
    :class="{
      cardhover: hover,
      cardshadow: shadow,
      padding: padding,
      cardActive: active,
    }"
  >
    <div v-if="cardTitle == true" class="card-title">
      <header>
        <slot name="card-title">Card title</slot>
      </header>
    </div>
    <div
      class="card-body"
      :style="cardTitle && cardFooter ? { height: height - headerHeight - footerHeight + 'px' } : {height: '100%'}"
    >
      <!-- {{this.card_body}} -->
      <main>
        <slot name="card-body">Card body</slot>
      </main>
    </div>
    <div v-if="cardFooter == true" class="card-footer">
      <!-- {{this.cardFooter}} -->
      <footer>
        <slot name="card-footer">Card footer</slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "HlxCard",
  components: {},
  props: {
    cardTitle: {
      type: Boolean,
      default: false,
    },
    cardFooter: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: null,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  computed: {
    headerHeight() {
      return this.cardTitle ? 60 : 0;
    },
    footerHeight() {
      return this.cardFooter ? 60 : 0;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
