<template id="vue-toggle">
  <div class="hlx-btn-switch-group">
    <button
      v-for="(key, i) in switchItems"
      :key="key[valueProp] + i"
      type="button" 
      :disabled="readOnly"
      :aria-readonly="readOnly"
      :class="[
        'hlx-switch-btn',
        {'hlx-switch-btn-read-only':readOnly},
        {
          'hlx-switch-btn-active':
            // selected && selected === key[valueProp] ||
            modelValue && modelValue == key[valueProp],
          'hlx-switch-btn-default':
          //  selected==undefined||  selected !== key[valueProp]||
            !modelValue || modelValue == key[valueProp]
        },
        { '--border-bottom': tabView }
      ]"
      @click="handleSelection(key)"
      >
      {{ key[displayProp] }}
    </button>
  </div>
  <input :value="selected" style="display: none" />
</template>
<script>
export default {
  name: "HlxSwitch",
  props: {
    readOnly:{
      type:Boolean,
      default:false
    },
    modelValue: {
      type: String,
      default: () => ""
    },
    switchItems: {
      type: Array,
      default: () => []
    },
    default: {
      type: String,
      default: () => ""
    },
    valueProp: {
      type: String,
      default: () => "value"
    },
    displayProp: {
      type: String,
      default: () => "label"
    },
    tabView: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ["change", "update:modelValue"],
  data() {
    return {
      selected: ""
    };
  },
  watch:{
    modelValue:{
      handler(value){
        if(!value) return
        let item =this.switchItems.find(item => item[this.valueProp] == value)
        console.log("🚀 ~ file: SwitchComponent.vue:71 ~ handler ~ value:", value)
        this.$emit("update:modelValue", value);
      this.$emit("change", item);
      }
    }
  },
  methods: {
    handleSelection: function (val) {
      if(this.readOnly == true) {
        return
      }
      this.selected = val[this.valueProp];
      this.$emit("update:modelValue", this.selected);
      this.$emit("change", val);
    }
  }
};
</script>

<style></style>
