import { createApp } from "vue";
import App from "./App.vue";
// import hlxKit from 'halley-kit'
// import './registerServiceWorker'
import router from "./router";
import VueColor from '@ckpack/vue-color';
import "./assets/icons/icon-style.css";
import customPlugin from "./plugin/customPlugin";

// import 'halley-kit/dist/library.mjs.css'
import store from "./store";
// import {} from 'vue3-click-away'
const app = createApp(App);
app.use(store).use(router).use(customPlugin).use(VueColor).mount("#app");
