<template>
  <div v-if="type === 'muliti-upload-drawer'">
    <hlx-button class="primary sm" @click="show_multiUpload"
      ><i
                  class="icon-plus-circle-regular"
                  style="margin-right: 5px"
                ></i
                >{{uploadButtonName}}</hlx-button
    >
    <div class="Mulitiupload_drawer">
      <hlx-drawer
        :show="show_multiUpload_pannel"
        :width="400"
        position="right"
        :footer="true"
        @close="closeMultiUplod"
      >
        <template #body>
          <div style="padding: 4px">
            <span
              style="
                display: flex;
                width: 25px;
                height: 25px;
                position: fixed;
                right: 400px;
                background: white;
                z-index: 1000;
                top: 0;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                box-shadow: -2px 0px 6px 0px #b9b6b6;
                cursor: pointer;
              "
              @click="closeMultiUplod"
            >
              <i class="icon-times-regular"></i>
            </span>
            <b style="font-size: 16px"> {{`Add ${uploadButtonName}`}}</b>
          </div>
          <div
            style="
              border-top: 1px solid rgb(216, 216, 216);
              margin-top: 15px;
              padding-top: 21px;
              display: flex;
              height: 100%;
              flex-direction: column;
            "
          >
            <div class="main-content-body-upload" style="height: 170px">
              <div
                v-if="!uploaded"
                v-cloak
                class="upload-panel"
                @drop.prevent="addFiles"
                @dragover.prevent
              >
                <div class="panel-items">
                  <i
                    class="icon-cloud-upload-regular"
                    style="font-size: 50px; color: #ccc"
                    @click="triggerFileInput"
                  ></i>
                  <span
                    class="drag-text"
                    style="font-size: 12px; color: #191a15"
                    @click="triggerFileInput"
                  >
                    <span style="color: #54bd9a">Select files</span> or drag and
                    drop
                  </span>
                  <span
                    class="or-text"
                    style="font-size: 12px; margin-top: 8px; color: #a6a6a6"
                    @click="triggerFileInput"
                  >
                    {{ fileType.replaceAll(".", " ").toUpperCase() }} Max
                    {{ fileSizeLimit.toUpperCase() }}
                  </span>
                  <input
                    id="fileUpload"
                    ref="fileInput"
                    autocomplete="off"
                    type="file"
                    :accept="fileType"
                    name="fileUpload[]"
                    style="display: none"
                    multiple
                    @change="chooseFiles"
                  />
                </div>
              </div>
              <div v-if="uploaded" class="upload-panel containere">
                <!-- Display uploaded files -->
              </div>
              <span class="upload-footer">{{ err_msg }}</span>
            </div>
            <div class="box-attachment-supplier-parent">
              <div
                v-for="(box, index) in filesArray"
                :key="index"
                class="box-attachment-supplier"
              >
                <span
                  class="closeIconeSupplier"
                  @click="handleClose(box, index)"
                  ><i class="icon-times-regular"></i
                ></span>
                <div>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'txt'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/text.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'docx'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/word.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'png'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/png.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'pdf'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/pdf.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'json'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/json.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'jpg'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/jpg.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'jpeg'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/jpeg.svg"
                      alt=""
                  /></span>
                  <span
                    v-if="getFileExtension(box.properties.name) === 'xlsx'"
                    class="upload_component_icon"
                    ><img
                      class="file-icon"
                      src="../assets/svg/multiUpload/excel.svg"
                      alt=""
                  /></span>
                </div>
                <div style="display: flex; flex-direction: column">
                  <span
                    style="font-weight: 700; font-size: 14px; color: #575361   white-space: nowrap;
                  width: 260px;
                  overflow: hidden;
                  text-overflow: ellipsis;"
                  >
                    {{ box.properties.name }} </span
                  ><span
                    style="
                      font-weight: 550;
                      font-size: 12px;
                      font-family: 'Opensans';
                      color: #857e95;
                    "
                    >{{ formatFileSize(box.properties.size) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #footer>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              padding: 19px;
              border-top: 1px solid #d8d8d8;
            "
          >
            <hlx-button class="secondary sm" @click="closeMultiUplod"
              >Cancel</hlx-button
            >
            <hlx-button
              class="primary sm"
              style="margin-left: 10px"
              @click="addMultiUplod"
              >Add</hlx-button
            >
          </div>
        </template>
      </hlx-drawer>
    </div>
  </div>

  <div
    v-if="type === 'single-line-multi-upload'"
    class="single-line-multi-upload"
  >
    
    <div class="single_line_upload_parent">
      <div
        v-for="(box, index) in filesArray"
        :key="index"
        class="single_line_upload"
      >
        <div>
          <span
            v-if="getFileExtension(box.properties.name) === 'txt'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/text.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'docx'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/word.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'png'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/png.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'pdf'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/pdf.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'json'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/json.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'jpg'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/jpg.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'jpeg'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/jpeg.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.properties.name) === 'xlsx'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/excel.svg"
              alt=""
          /></span>
        </div>
        <span class="closeIconesinglelineMulti" @click="handleClose(box, index)"
          ><i class="icon-times-regular"></i
        ></span>
      </div>
      <div
      class="addBoder"
      @drop.prevent="addFiles"
      @dragover.prevent
      @click="triggerFileInput"
    >
      <span class="plus_icone_upload"><i class="icon-plus-filled"></i></span>
      <input
        id="fileUpload"
        ref="fileInput"
        autocomplete="off"
        type="file"
        :accept="fileType"
        name="fileUpload[]"
        style="display: none"
        multiple
        @change="chooseFiles"
      />
    </div>
    </div>
  </div>
</template>

<script>
// import hlxFileUpload from "../components/multiUploadHead.vue";
import hlxButton from "../components/ButtonComponent.vue";
import HlxDrawer from "../components/DrawerComponent.vue";

export default {
  name: "HlxMultiUpload",
  components: {
    // hlxFileUpload,
    hlxButton,
    HlxDrawer,
  },
  props: {
    uploadButtonName:{
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "muliti-upload-drawer",
    },
    fileType: {
      type: String,
      default: "",
    },
    fileSizeLimit: {
      type: String,
      default: "",
    },
    PreValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["uplodedFiles", 'SingleuplodedFiles', 'native-data'],
  // watch:{
  // preValue: {
  //     handler(val) {
  //       this.filesArray = val;
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
  data() {
    return {
      eventData:[],
      uploaded: false,
      err_msg: "",
      filesArray: [],
      show_multiUpload_pannel: false,

      fileItems: [
        { name: "Doc1.pdf", fileData: "" },
        { name: "Doc2.doc", fileData: "" },
        { name: "Doc3.png", fileData: "" },
      ],
      file_data: {},
      arr: [],
      icon: "icon-eye-regular",
      editor_theme: "light",
      imgSrc: "",
    };
  },
  methods: {
    addMultiUplod() {
      this.show_multiUpload_pannel = false;
       this.$emit("uplodedFiles", this.filesArray);
       this.$emit("native-data", this.eventData);
      this.filesArray = [];
    },
    closeMultiUplod() {
      this.err_msg = "";
      this.filesArray = [];
      this.show_multiUpload_pannel = false;
    },
    handleClose(val, index) {
      this.filesArray.splice(index, 1);
      
      console.log(this.filesArray);
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    getFileExtension(filename) {
      const parts = filename.split(".");

      const fileExtension = parts[parts.length - 1];

      return fileExtension;
    },
    show_multiUpload() {
      this.show_multiUpload_pannel = true;
    },

    reciverdata(val) {
      // console.log(val, "uploared daata");
      this.file_data = {
        name: val.properties.name,
        data: val.data,
      };
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    chooseFiles(event) {
      
    
      const files = event.target.files;
      if (files && files.length > 0) {
        const currentTime = new Date().toJSON(); // Get current date and time
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          this.eventData.push(file)

          if (this.isValidFileType(file) && this.isValidFileSize(file)) {
            const reader = new FileReader();
            reader.onload = () => {
              const fileData = reader.result;
              const fileInfo = {
                properties: file,
                data: fileData,
                lastUploaded: currentTime, // Store current date and time when adding the file
              };
              this.filesArray.push(fileInfo);
            };
            reader.readAsText(file);
          } else {
            if (!this.isValidFileType(file)) {
             
              this.err_msg = `Invalid file type. Please select files of type ${this.fileType}.`;
            } else {
              this.err_msg = `File size exceeds the limit. Maximum size allowed is ${this.fileSizeLimit.toUpperCase()}.`;
            }
          }
        }
        console.log("Files array:", this.filesArray);
         this.$emit("SingleuplodedFiles", this.filesArray);
      }
    },
    addFiles(event) {
      this.eventData.push(event)
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        const currentTime = new Date().toJSON(); // Get current date and time
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (this.isValidFileType(file) && this.isValidFileSize(file)) {
            const reader = new FileReader();
            reader.onload = () => {
              const fileData = reader.result;
              const fileInfo = {
                properties: file,
                data: fileData,
                lastUploaded: currentTime, // Store current date and time when adding the file
              };
              this.filesArray.push(fileInfo);
            };
            reader.readAsText(file);
          } else {
            if (!this.isValidFileType(file)) {
              this.err_msg = `Invalid file type. Please select files of type ${this.fileType}.`;
            } else {
              this.err_msg = `File size exceeds the limit. Maximum size allowed is ${this.fileSizeLimit.toUpperCase()}.`;
            }
          }
        }
        console.log("Files array:", this.filesArray);
      }
    },

    isValidFileType(file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return this.fileType.split(",").includes("." + fileExtension);
    },
 isValidFileSize(file) {
  const fileSize = file.size;
  const limitString = this.fileSizeLimit.toLowerCase(); // Convert to lowercase to handle case insensitivity
  const sizeParts = limitString.match(/^(\d+(?:\.\d+)?)\s*([kmgtp]b)?$/); // Extract numerical value and unit

  if (!sizeParts) {
    throw new Error("Invalid file size limit format");
  }

  let maxSizeInBytes;
  const sizeValue = parseFloat(sizeParts[1]);
  const sizeUnit = sizeParts[2];

  switch (sizeUnit) {
    case 'kb':
      maxSizeInBytes = sizeValue * 1024;
      break;
    case 'mb':
      maxSizeInBytes = sizeValue * 1024 * 1024;
      break;
    case 'gb':
      maxSizeInBytes = sizeValue * 1024 * 1024 * 1024;
      break;
    // Add more cases for other units if needed
    default:
      throw new Error("Invalid file size unit");
  }

  return fileSize <= maxSizeInBytes;
}

  },
};
</script>
<style lang="scss">
.single-line-multi-upload {
  .addBoder {
    cursor: pointer;
    border: 2px dashed #d8d8d8;
    border-radius: 6px;
    padding: 25px;
    display: flex;
    width: 80px;
    height: 50px;
    align-items: center;
    justify-content: center;
    .plus_icone_upload {
      color: rgb(168, 168, 168);
      font-size: 18px;
    }
  }

  .single_line_upload_parent {
    overflow-y: auto;
    height: 72%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .file-icon {
      height: 58px;
    }
    .single_line_upload {
      position: relative;
    }
    .closeIconesinglelineMulti {
      position: absolute;
      right: 8px;
      top: 4px;
      background: black;

      color: wheat;
      height: 13px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      width: 13px;
      border-radius: 2px;
      align-items: center;
    }
  }
}
.Mulitiupload_drawer {
  .sidenav-right {
    overflow: hidden;
  }
  // .body-panel-right {
  //   height: 100% !important;
  // }
}
.box-attachment-supplier-parent {
  overflow-y: auto;
  height: 72%;
  .box-attachment-supplier {
    position: relative;
    width: 99%;
    display: flex;
    height: auto;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    padding: 6px 8px 6px 8px;
    margin: 10px 0 10px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    .closeIconeSupplier {
      position: absolute;
      left: 353px;
      height: 37px;
      color: #a6a6a6;
      cursor: pointer;
    }
    .upload_component_icon {
      height: 40px;
      align-items: center;
      display: flex;
      .file-icon {
        height: 28px;
        width: 32px;
      }
    }
  }
}
</style>
