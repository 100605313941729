<template>
  <div v-show="showColorPicker" id="colorpicker" class="colorpicker_extenstion">
    <Sketch v-model="colors" @input="updateValue" />
    <div class="color_picker_name_color">
      <label><b>Color name</b></label
      ><br />
      <div style="margin-top: 10px; width: 277px">
        <hlx-input
          v-model:value="colorName"
          :label-animation="false"
          type="text"
          :clearable="true"
          :pre-val="colorName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Sketch } from "@ckpack/vue-color";
import HlxInput from "../components/InputComponent.vue";

export default {
  name: "HlxNativeColorPicker",
  components: {
    Sketch,
    HlxInput,
  },
  props: {
    showColorPicker: {
      type: Boolean,
      default: false,
    },
    editmode: {
      type: Boolean,
      default: false,
    },
    editPayload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    colorNameprop: {
      type: String,
      default: "",
    },
  },
  emits: ["close", "updated-value", "edit-value"],
  data() {
    return {
      colors: "#fafafa",
      colorName: "",
      selectColor: {},
    };
  },
  watch: {
    colorNameprop: {
      handler(val) {
        console.log(val, "colorNameprop");
        this.colorName = val;
      },
    },
    editPayload: {
      handler(val) {
        console.log(val);

        this.colorName = val.name;
        this.colors = val.hex;

        console.log(val, this.colorName, this.colors);
      },
    },
    showColorPicker: {
      handler(val) {
        console.log(val);
        if (val === true) {
          this.closefunction(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const el = document.getElementsByClassName("vc-input__input");
    el[0].setAttribute("maxLength", 7);
    el[1].setAttribute("maxLength", 3);
    el[2].setAttribute("maxLength", 3);
    el[3].setAttribute("maxLength", 3);
    // const elv = document.getElementsByClassName("vc-input__label");
    // elv.style.fontSize = "16px !important";

    console.log(el, "fds");
    this.$watch(
      "colors",
      (val) => {
        console.log(val);
        this.selectColor = val;
        console.log(this.selectColor);
        console.log({
          name: this.colorName,
          hex: this?.selectColor?.hex,
          rgb: {
            r: this.selectColor?.rgba?.r,
            g: this.selectColor?.rgba?.g,
            b: this.selectColor?.rgba?.b,
          },
        });
      },
      { immediate: true, deep: true }
    );
    console.log(this.$refs.sketch);
  },
  methods: {
    newColor() {},
    editColor() {},
    closefunction(val) {
      console.log();
      let count = 0;
      let closefun = val;
      document.addEventListener("click", (e) => {
        // //console.log(e,"element clode ");
        if (closefun == true) {
          const elem = document
            .getElementById("colorpicker")
            .contains(e.target);
          // console.log(elem);

          count += 1;
          if (count > 1 && elem === false) {
            if (this.colorName === "") {
              this.$emit("updated-value", elem, "close");
              this.$emit("edit-value", elem, "close");
              count = 0;
              closefun = false;
            } else if (this.editmode === true) {
              this.$emit(
                "edit-value",
                elem,
                "edit",
                {
                  name: this.colorName,
                  hex: this.selectColor.hex,
                  rgb: {
                    r: this.selectColor?.rgba?.r,
                    g: this.selectColor?.rgba?.g,
                    b: this.selectColor?.rgba?.b,
                  },
                },
                this.selectColor.hex,
                this.editPayload._id
              );
              count = 0;
              closefun = false;
            } else {
              this.$emit(
                "updated-value",
                elem,
                "updated",

                {
                  name: this.colorName,
                  hex: this.selectColor.hex,
                  rgb: {
                    r: this.selectColor?.rgba?.r,
                    g: this.selectColor?.rgba?.g,
                    b: this.selectColor?.rgba?.b,
                  },
                },
                this.selectColor.hex
              );

              count = 0;
              closefun = false;
            }
            console.log(count);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.colorpicker_extenstion {
  z-index: 100000 I !important;
  margin-top: 4% !important;
  max-width: 320px !important;
  width: -moz-max-content !important;
  width: max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
  /* padding: 20px; */
  border-radius: 20px !important;
  padding: 10px 10px 17px 10px !important;
  position: absolute !important;
  background: white !important;
  position: relative !important;
  position: relative !important;
  .color_picker_name_color {
    position: absolute !important;
    top: 276px !important;
    left: 21px !important;
  }
  .vc-input__label {
    color: #222 !important;
    display: block !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    /* font-size: 488px; */
    position: absolute;
    top: -34px !important;
    padding-bottom: 4px !important;
    padding-left: 4px !important;
    padding-top: 3px !important;
    text-align: left !important;
    text-transform: capitalize !important;
  }
  .vc-input__input {
    border: solid 1px #d8d8d8 !important;
    box-shadow: none !important;

    padding: 4px 0 3px 10% !important;
    width: 90% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 0px !important;
    width: 100% !important;
    height: 36px !important;
    color: #565553 !important;
    background: transparent !important;
    border-radius: 5px !important;

    transition: all 0.3s ease !important;
    font-size: 14px !important;
    padding: 10px 12px 10px 12px !important;
    line-height: 18.15px !important;
    letter-spacing: 0.5% !important;
  }
  .vc-input__input:hover {
    /* Apply border when hovered over */
    border: 1px solid #54bd95 !important;
  }
  .vc-input__input:focus {
    /* Apply border when hovered over */
    border: 1px solid #54bd95 !important;
  }
  .vc-sketch {
    .vc-sketch-sliders {
      flex: 1 !important;
      padding: 20px 0 !important;
    }
    background: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    box-sizing: initial !important;
    padding: 10px 10px 0 !important;
    position: relative !important;
    width: 280px !important;
    .vc-sketch-field {
      display: flex !important;
      padding-top: 110px !important;
    }
    .vc-saturation-circle {
      width: 9px !important;
      height: 9px !important;
    }

    .vc-sketch-alpha-wrap {
      display: none !important;
    }
    .vc-sketch-presets {
      display: none !important;
    }
    .vc-sketch-color-wrap {
      display: none !important;
    }
    .vc-hue {
      border-radius: 6px !important;
    }
    .vc-sketch-field--single:last-of-type {
      display: none !important;
    }
    .vc-hue-picker {
      background: #fff !important;
      border-radius: 6px !important;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.6) !important;
      cursor: pointer !important;
      height: 10px !important;
      margin-top: 0px !important;
      transform: translateX(-2px) !important;
      width: 10px !important;
    }
  }
}
</style>
