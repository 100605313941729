<template>
  <div v-if="view == 'horizontal' && type == 'regular'" class="t-parent">
    <div v-for="(e, index) in timelineData" :key="index" class="t-sub-parent">
      <div class="t-cont" @click="emit(e)">
        <div
          id="circle"
          :class="{
            'active-circle': e.visited == true,
            circle: e.visted == false,
            'pri-bg': e.active == true,
          }"
        >
          <i
            :class="
              e.visited == true
                ? 'icon-check-filled'
                : 'icon-more-horizontal-filled'
            "
          ></i>
        </div>
      </div>
      <div v-show="index < timelineData.length - 1" class="t-line">
        <hr :class="e.active == true ? 't-active' : 't-inactive'" />
      </div>
    </div>
  </div>
  <div v-if="view == 'horizontal' && type == 'regular'" class="t-parent">
    <div v-for="(e, index) in timelineData" :key="index" class="t-sub-parent">
      <div class="t-cont" @click="emit(e)">
        <div class="t-title" style="margin-top: -8px; margin-left: -70px">
          {{ e.title }}
          <span
            v-if="e.description != undefined && e.description != ''"
            class="bg-default border"
            :data-tooltip="e.description"
            data-tooltip-location="right"
            ><i class="icon-info-circle-regulart"></i
          ></span>
        </div>
        <div class="t-sub-title" style="margin-left: -70px">
          {{ e.subtitle }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="view == 'horizontal' && type == 'new'" class="t-parent">
    <div
      v-for="(e, index) in timelineData"
      :key="index"
      class="t-sub-parent-new"
    >
      <div
        v-if="e.disabled == false || e.disabled == undefined"
        class="t-cont-new"
        @click="emit(e)"
      >
        <div
          v-if="e.visited == true"
          id="circle-new"
          :class="{
            'active-circle-new': e.visited == true,
            'circle-new': e.visited == false,
            'pri-bg': e.active == true,
          }"
        >
          <i
            v-if="e.visited == true && e.active == false"
            class="icon-check-filled"
          ></i>
          <span
            v-if="e.visited != true && e.active != false"
            class="inner-circle-new"
          ></span>
        </div>
        <div
          v-if="e.visited != true && e.active == false"
          id="circle-neww"
          :class="{
            'active-circle-new': e.visited == true,
            'circle-new': e.visited == false,
            'pri-bg': e.active == true,
          }"
        >
          <i
            v-if="e.visited == true && e.active == false"
            class="icon-check-filled"
          ></i>
          <span
            v-if="e.visited != true && e.active != false"
            class="inner-circle-new"
          ></span>
        </div>
        <div
          v-if="e.active == true"
          id="circle-new"
          :class="{
            'active-circle-new': e.visited == true,
            'circle-new': e.visited == false,
            'pri-bg-new': e.active == true,
          }"
        >
          <i
            v-if="e.visited == true && e.active == false"
            class="icon-check-filled"
          ></i>
          <span
            v-if="e.visited != true && e.active != false"
            class="inner-circle-new"
          ></span>
        </div>
      </div>
      <div v-else class="t-cont-new">
        <div
          v-if="e.visited == true"
          id="circle-new"
          :class="{
            'active-circle-new': e.visited == true,
            'circle-new': e.visited == false,
            'pri-bg': e.active == true,
          }"
        >
          <i
            v-if="e.visited == true && e.active == false"
            class="icon-check-filled"
          ></i>
          <span
            v-if="e.visited != true && e.active != false"
            class="inner-circle-new"
          ></span>
        </div>
        <div
          v-if="e.visited != true && e.active == false"
          id="circle-neww"
          :class="{
            'active-circle-new': e.visited == true,
            'circle-new': e.visited == false,
            'pri-bg': e.active == true,
          }"
        >
          <i
            v-if="e.visited == true && e.active == false"
            class="icon-check-filled"
          ></i>
          <span
            v-if="e.visited != true && e.active != false"
            class="inner-circle-new"
          ></span>
        </div>
        <div
          v-if="e.active == true"
          id="circle-new"
          :class="{
            'active-circle-new': e.visited == true,
            'circle-new': e.visited == false,
            'pri-bg-new': e.active == true,
          }"
        >
          <i
            v-if="e.visited == true && e.active == false"
            class="icon-check-filled"
          ></i>
          <span
            v-if="e.visited != true && e.active != false"
            class="inner-circle-new"
          ></span>
        </div>
      </div>
      <div
        v-if="e.visited == true"
        v-show="index < timelineData.length - 1"
        class="t-line-new"
      >
        <hr :class="e.active == true ? 't-active' : 't-inactive'" />
      </div>
      <div
        v-if="e.visited == false"
        v-show="index < timelineData.length - 1"
        class="t-line-neww"
      >
        <hr :class="e.active == true ? 't-active' : 't-inactive'" />
      </div>
    </div>
  </div>
  <div v-if="view == 'horizontal' && type == 'new'" class="t-parent">
    <div v-for="(e, index) in timelineData" :key="index" class="t-sub-parent">
      <div
        v-if="e.disabled == false || e.disabled == undefined"
        class="t-cont"
        @click="emit(e)"
      >
        <div class="t-title" style="margin-top: -8px; margin-left: -95px">
          {{ e.title }}
          <span
            v-if="e.description != undefined && e.description != ''"
            class="bg-default border"
            :data-tooltip="e.description"
            data-tooltip-location="right"
            ><i class="icon-info-circle-regulart"></i
          ></span>
        </div>
        <div class="t-sub-title" style="margin-left: -95px">
          {{ e.subtitle }}
        </div>
      </div>
      <div v-else class="t-cont">
        <div class="t-title" style="margin-top: -8px; margin-left: -95px">
          {{ e.title }}
          <span
            v-if="e.description != undefined && e.description != ''"
            class="bg-default border"
            :data-tooltip="e.description"
            data-tooltip-location="right"
            ><i class="icon-info-circle-regulart"></i
          ></span>
        </div>
        <div class="t-sub-title" style="margin-left: -95px">
          {{ e.subtitle }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="view == 'horizontal' && type == 'numbered'" class="t-parent">
    <div v-for="(e, index) in timelineData" :key="index" class="t-sub-parent">
      <div class="t-cont" @click="emit(e)">
        <div
          id="circle"
          :class="{
            'active-circle': e.visited == true,
            circle: e.visted == false,
            'pri-bg': e.active == true,
          }"
          class="t-circle"
        >
          {{ index + 1 }}
        </div>
      </div>
      <div v-show="index < timelineData.length - 1" class="t-line">
        <hr />
      </div>
    </div>
  </div>
  <div v-if="view == 'horizontal' && type == 'numbered'" class="t-parent">
    <div v-for="(e, index) in timelineData" :key="index" class="t-sub-parent">
      <div class="t-cont" @click="emit(e)">
        <div class="t-title" style="margin-top: -8px; margin-left: -70px">
          {{ e.title }}
          <span
            v-if="e.description != undefined && e.description != ''"
            class="bg-default border"
            :data-tooltip="e.description"
            data-tooltip-location="right"
            ><i class="icon-info-circle-regulart"></i
          ></span>
        </div>
        <div class="t-sub-title" style="margin-left: -70px">
          {{ e.subtitle }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="view == 'vertical' && type == 'regular'" class="t-parent-v">
    <div
      v-for="(e, index) in timelineData"
      :key="index"
      class="t-sub-parent-v"
    >
      <div class="t-cont-v" @click="emit(e)">
        <div
          id="circle"
          :class="{
            'active-circle': e.visited == true,
            circle: e.visted == false,
            'pri-bg': e.active == true,
          }"
        >
          <i
            :class="
              e.visited == true
                ? 'icon-check-filled'
                : 'icon-more-horizontal-filled'
            "
          ></i>
        </div>
        <div class="t-content-v" :style="[
            e.subtitle.length>1
              ? { margin: '20 0 0 0' }
              : { margin: '0 0 0 0' },
          ]" style="margin-left:8px !important">
          <div class="t-title-v">
            {{ e.title }}
            <span
              v-if="e.description != undefined && e.description != ''"
              class="bg-default border"
              :data-tooltip="e.description"
              data-tooltip-location="right"
              ><i class="icon-info-circle-regulart"></i
            ></span>
          </div>
          <div class="t-sub-title-v">{{ e.subtitle }}</div>
        </div>
      </div>
      <div v-show="index < timelineData.length - 1" class="t-line-v" style="margin-left: -6px" :style="e.subtitle.length<1 ? 'margin-top:16px' : ''">
        <hr :class="e.visited == true ? 't-active' : 't-inactive'" />
      </div>
    </div>
  </div>
  <div v-if="view == 'vertical' && type == 'numbered'" class="t-parent-v">
    <div
      v-for="(e, index) in timelineData"
      :key="index"
      class="t-sub-parent-v"
    >
      <div class="t-cont-v" @click="emit(e)" >
        <div
          id="circle"
          :class="{
            'active-circle': e.visited == true,
            circle: e.visted == false,
            'pri-bg': e.active == true,
          }"
        >
          {{ index + 1 }}
        </div>
        <div class="t-content-v"  :style="[
            e.subtitle.length>1
              ? { margin: '20 0 0 0' }
              : { margin: '0 0 0 0' },
          ]" style="margin-left:8px !important">
          <div class="t-title-v">
            {{ e.title }}
            <span
              v-if="e.description != undefined && e.description != ''"
              class="bg-default border"
              :data-tooltip="e.description"
              data-tooltip-location="right"
              ><i class="icon-info-circle-regulart"></i
            ></span>
          </div>
          <div class="t-sub-title-v" >{{ e.subtitle }}</div>
        </div>
      </div>
      <div v-show="index < timelineData.length - 1" class="t-line-v" style="margin-left: -6px" :style="e.subtitle.length<1 ? 'margin-top:16px' : ''">
        <hr :class="e.visited == true ? 't-active' : 't-inactive'" />
      </div>
    </div>
  </div>
  <div v-if="view == 'vertical' && type == 'slot'" class="timeline-parent-container">
    <div class="timeline-single-container">
      <div class="timeline-single-container-icon-side">
        <div class="timeline-single-container-icon-side-icon-container">
          <slot name="icon"></slot>
        </div>
        <div v-if="currIndex != totalLength-1" class="timeline-single-container-icon-side-vertical-line-container">
         <span  class="timeline-single-container-icon-side-vertical-line-container-vertical-line"></span>
        </div>
      </div>
      <div class="timeline-single-container-content-side">
        <div class="timeline-single-container-content-side-first-slot">
          <slot name="first-slot"></slot>
        </div>
        <div class="timeline-single-container-content-side-second-slot">
          <div class="timeline-single-container-content-side-second-slot-with-accordion">
             <i  class="right icon-angle-right-regular" @click="accordionFunctionality"></i>
             <slot name="title"></slot>
          </div>
          <div :id="'timeline-single-container-content-side-second-slot-with-accordion-content' + unique" class="timeline-single-container-content-side-second-slot-with-accordion-content">
            <slot name="content"></slot>
          </div>
          <div class="timeline-single-container-content-side-footer-slot">
             <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p v-if="view == 'vertical' && type == 'slot' && currIndex == dataLength-1 && dataLength-1 != totalLength-1" class="link-timeline" @click="loadmore">Load more</p>
</template>

<script>
export default {
  name: "HlxTimeline",
  props: {
    timelineData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    view: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    currIndex:{
       type:Number,
       default:0
    },
    dataLength:{
       type:Number,
       default:0
    },
    totalLength:{
       type:Number,
       default:0
    },
    options:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  emits: ["emit-data","load-more","accordionOpen","accordionClose"],
  data() {
    return {
      unique: Math.floor(Math.random() * 1000 + 1),
      // width: 0
    };
  },
  watch:{
    currIndex:{
      handler(){

      },
      immediate:true,
      deep:true
    },
    dataLength:{
      handler(){

      },
      immediate:true,
      deep:true
    },
  },
  mounted() {
    // this.width = document.querySelector(".t-parent").offsetWidth;
    // let a = document.querySelectorAll('.t-line')
    // a[a.length-1].style.display = 'none'
  },
  methods: {
    emit(val) {
      // console.log(val);
      this.$emit("emit-data", val);
    },
    loadmore(){
      this.$emit("load-more",'')
    },
    accordionFunctionality(event) {
      // console.log(event.target.className);
      if(event.target.className == "right icon-angle-right-regular")
      {
        event.target.classList.remove('icon-angle-right-regular')
        event.target.classList.add('icon-angle-down-regular')
        event.target.parentElement.nextElementSibling.style.display = 'flex' 
        this.$emit('accordionOpen')
      }
      else if(event.target.className == "right icon-angle-down-regular"){
        event.target.classList.remove('icon-angle-down-regular')
        event.target.classList.add('icon-angle-right-regular')
        event.target.parentElement.nextElementSibling.style.display = 'none' 
        this.$emit('accordionClose')
      }
         
    },
  },
};
</script>

<style lang="scss">
// .t-active{

// }
</style>
