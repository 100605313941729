<template>
  <h1 class="component-heading">Swatch</h1>
  <p class="component-description">
    <b>Swatch</b> Help the user picking beautiful colors and component that
    allows the user to choose a color
  </p>
  <br />

  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-swatch
        :options="swatchData"
        :v-model="colorval"
        :label="'Color'"
        :value="'name'"
        @selected-value="handleSelectedColor"
        @hovered-value="handlehoverColor"
      >
        <div style="margin-bottom: 10px">
          Colour: <b>{{ displayHeaderName }}</b>
        </div>
      </hlx-swatch>
    </div>
    <div class="example-footer">
      <span id="email-icon" class="icon" @click="showCode('email')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="email" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="email"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in treeAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in treeAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>

  <h2 class="component-side-heading">Events</h2>
  <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="3"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'tabs')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tab</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'speeddial')"
      >Speed dial <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>
<script>
import HlxSwatch from "../components/swatchComponent.vue";

import CodeEditor from "simple-code-editor";
// import hlxButton from "../components/ButtonComponent.vue";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "HlxSwatchView",
  components: {
    HlxSwatch,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    // hlxButton,
    CodeEditor,
    hlxDivider,
  },
  data() {
    return {
      colorval:"",
      swatchData: [
        { name: "primary-success", value: "primary-success", Color: "#02b55c" },
        { name: "primary-error", value: "primary-error", Color: "#ff5050" },
        { name: "primary-info", value: "primary-info", Color: "#2879b7" },
        { name: "primary-warning", value: "primary-warning", Color: "#f8a523" },
        { name: "primary-color", value: "primary-color", Color: "#54bd95" },
        {
          name: "primary-disabled",
          value: "primary-disabled",
          Color: "#ece9f1",
        },
        {
          name: "primary-text-color",
          value: "primary-text-color",
          Color: "#fbfbfb",
        },
        {
          name: "primary-tree-text",
          value: "primary-tree-text",
          Color: "#222f2b",
        },
        {
          name: "primary-bg-light",
          value: "primary-bg-light",
          Color: "#A8DEC9",
        },
        {
          name: "secondary-color-green-light",
          value: "secondary-color-green-light",
          Color: "#dcfae9",
        },
        {
          name: "primary-color-dark",
          value: "primary-color-dark",
          Color: "#265E4B",
        },
      ],
      preselectedColor: "primary-warning",
      hoveredSwatch: null,
      headerName: null,
      email: `
<template>
<hlx-swatch
        :options="swatchData"
        :v-model="preselectedColor"
        :label="'name'"
        :value="'Color'"
        @selected-value="handleSelectedColor"
        @hovered-value="handlehoverColor"
      >
        <div style="margin-bottom: 10px">
          Colour: <b>{{ displayHeaderName }}</b>
        </div>
      </hlx-swatch>

  
</template>

<script>
export default {
data(){
  return{
   swatchData: [
        { name: "primary-success", value: "primary-success", Color: "#02b55c" },
        { name: "primary-error", value: "primary-error", Color: "#ff5050" },
        { name: "primary-info", value: "primary-info", Color: "#2879b7" },
        { name: "primary-warning", value: "primary-warning", Color: "#f8a523" },
        { name: "primary-color", value: "primary-color", Color: "#54bd95" },
        {
          name: "primary-disabled",
          value: "primary-disabled",
          Color: "#ece9f1",
        },
        {
          name: "primary-text-color",
          value: "primary-text-color",
          Color: "#fbfbfb",
        },
        {
          name: "primary-tree-text",
          value: "primary-tree-text",
          Color: "#222f2b",
        },
        {
          name: "primary-bg-light",
          value: "primary-bg-light",
          Color: "#A8DEC9",
        },
        {
          name: "secondary-color-green-light",
          value: "secondary-color-green-light",
          Color: "#dcfae9",
        },
        {
          name: "primary-color-dark",
          value: "primary-color-dark",
          Color: "#265E4B",
        },
      ],
      preselectedColor: "primary-warning",
      hoveredSwatch: null,
      headerName: null,
  },
   computed: {
    displayHeaderName() {
      return this.hoveredSwatch ? this.hoveredSwatch.name : this.headerName;
    },
  },
  watch: {
    preselectedColor(newVal) {
      this.headerName = newVal;
    },
  },
  created() {
    this.headerName = this.preselectedColor;
  },
    methods: {
    handleSelectedColor(color) {
      console.log("Selected Color:", color);
      this.headerName = color.name;
      this.hoveredSwatch = color;
    },

    handlehoverColor(color) {
      this.hoveredSwatch = color;
      console.log("hovered Color:", color);
    }
    }
}
}
</\script>`,
      editor_theme: "light",
      event_data: [
        {
          name: "selected-value",
          description: "emits the selected value",
          parameter: "",
        },
        {
          name: "hovered-value",
          description: "emits the hovered value",
          parameter: "",
        },
      ],
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          attribute: "options",
          description:
            "Pass the list of items to be added in the  list ",
          type: "Array",
          accepted_values: 'Array of objects',
          default: "-",
          mandatory: true,
        },
        {
          attribute: "v-model",
          description: "Pre-populate data in swatch",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: false,
        },
       {
          attribute: "value",
          description: "Refers to the value to which the options points",
          type: "String",
          accepted_values: "-",
          default: "value",
          mandatory: false,
        },
           {
          attribute: "label",
          description: "The value or name to be displayed for options",
          type: "String",
          accepted_values: "-",
          default: "name",
          mandatory: false,
        },
         {
          attribute: "position",
          description: "Sets horizontal/vertical view for rectangle select",
          type: "String",
          accepted_values: "horizontal / vertical",
          default: "vertical",
          mandatory: false,
        },
      ],
      treeAttributes: [
        {
          prop: "attribute",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
    };
  },
  computed: {
    displayHeaderName() {
      return this.hoveredSwatch ? this.hoveredSwatch.name : this.headerName;
    },
  },
  watch: {
    preselectedColor(newVal) {
      this.headerName = newVal;
    },
  },
  created() {
 setTimeout(() => {
    this.headerName = this.preselectedColor;
     }, 3000);

  },
  mounted(){
     setTimeout(() => {
    this.colorval = "primary-success";
         }, 3000);
  },
  methods: {
    handleSelectedColor(color) {
      console.log("Selected Color:", color);
      this.headerName = color.name;
      this.hoveredSwatch = color;
    },

    handlehoverColor(color) {
      this.hoveredSwatch = color;
      console.log("hovered Color:", color);
    },
    selectedvalue(val) {
      console.log(val);
    },
    handleEnter() {
      if (this.inputValue.trim() !== "") {
        this.enteredValues.push(this.inputValue); // Add entered value to the beginning of the array
        this.inputValue = ""; // Clear the input field
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
