<template>
  <div class="body">
    <h1 class="component-heading">Introduction</h1>

    <p class="component-side-heading">
      <b
        >Hey there! We are excited to introduce our latest adventure - Halleyx
        UI Framework</b
      >
    </p>
    <br />
    <p style="margin-bottom: 10px; line-height: 1.8">
      Halleyx is a UI component framework completely designed by and for
      developers using Vuejs. We made this framework from scratch, so it's
      suitable for all types of developers - whether you're a frontend lover or
      a backend developer.
    </p>
    <p style="margin-bottom: 10px; line-height: 1.8">
      We believe that a programmer can save some time if they find a library
      that works as desired and does not have to change its structure in
      accordance with the project.
    </p>
    <p style="margin-bottom: 10px; line-height: 1.8">
      Our main goal is to make programming less tedious by providing fully-built
      and customizable components that are simple to integrate. Big things come
      in small packages. We have created 40+ flexible, modular components that
      accelerate development time and simplify the process of creating visually
      stunning projects, without compromising the developer's independence and
      personalization
    </p>
    <p style="margin-bottom: 10px; line-height: 1.8">
      So start designing with ease!
    </p>
    <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span></span>
      <span class="nxt-btn" @click="prevNextPage('next', 'installation')"
        >Installation <i class="icon-angle-right-regular"></i
      ></span>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: "HomeView",
  components: {
    hlxDivider,
  },
  data() {
    return {
      value: ref(""),
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/var.scss";

// .body{
//     /* margin-top: 50px; */
//     display: flex;
//     height: 100%;
//     width: 100%;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     img{
//       // margin-left: -90px;
//     }
//     h1{
//       // margin-left: -90px;
//       margin-bottom: 30px;
//       font-family: QuickSand;
//     }
//     p{
//       text-align: center;
//     }
// }
</style>
