<template>
  <div id="hlx-slide-control-0003" class="range-slider">
    <input
      id="hlx-range-slider"
      ref="hlx-slider"
      v-model="currentValue"
      class="range-slider__range"
      type="range"
      :min="min"
      :max="max"
      @input="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: "HlxSlider",
  props: {
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => {
        return {
          fill: "#F6991D",
          background: "#d4d4d4",
        };
      },
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      currentValue: 0,
    };
  },
  computed: {},
  mounted() {
    let slider = this.$refs["hlx-slider"];
    this.currentValue = this.min;
    const percentage =
      100 * ((this.currentValue - this.min) / (this.max - this.min));
    // now we'll create a linear gradient that separates at the above point
    // Our background color will change here
    const bg = `linear-gradient(90deg, ${this.config.fill} ${percentage}%, ${
      this.config.background
    } ${percentage + 0.1}%)`;
    slider.style.background = bg;
  },
  methods: {
    handleChange($event) {
      this.$emit("update:modelValue", this.currentValue);
      this.$emit("change", this.currentValue);
      const percentage =
        100 * ((this.currentValue - this.min) / (this.max - this.min));
      // now we'll create a linear gradient that separates at the above point
      // Our background color will change here
      const bg = `linear-gradient(90deg, ${this.config.fill} ${percentage}%, ${
        this.config.background
      } ${percentage + 0.1}%)`;
      $event.target.style.background = bg;
    },
  },
};
</script>
