<template>
  <div
    id="hlx-loaders-006"
    class="load-container"
    :class="loadType ? loadType : 'spinner'"
  >
    <!-- spin loader -->
    <div
      v-if="loadType == 'spinner' || loadType == undefined"
      id="spin-loader"
      class="spin-loader"
    ></div>
    <!-- dotted jumper -->
    <div v-if="loadType == 'dotted-jumper'" class="dotted-jump-loader">
      <div class="ball"></div>
      <div class="ball"></div>
      <div class="ball"></div>
    </div>
    <!-- load bar -->
    <div v-if="loadType == 'horizontal-loop'" class="loading-horz-bar"></div>
  </div>
</template>

<script>
export default {
  name: "HlxLoader",
  props: {
    loadType: {
      type: String,
      default: 'spinner'
    },
    size: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  watch: {
    size: {
      handler(size) {
        let loader = document.querySelector("#spin-loader");
        if (size) {
          switch (this.loadType) {
            case "dotted":
              break;

            default:
              loader = document.querySelector("#spin-loader");
              if (loader) {
                loader.style.size = this.size + "px";
                loader.style.height = this.size + "px";
              }
              break;
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {
    let loader = document.querySelector("#spin-loader");
    if (Number(this.size)) {
      switch (this.loadType) {
        case "dotted":
          break;

        default:
          loader = document.querySelector("#spin-loader");
          if (loader) {
            loader.style.width = this.size + "px";
            loader.style.height = this.size + "px";
          }
          break;
      }
    }
  },
};
</script>
