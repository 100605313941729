<template>

  <h1 class="component-heading">Tag+count</h1>
  <p class="component-description">
    The Tagcount component, is designed to manage and display a list of items with a dynamic handling of overflow based
    on the available space within its container.
  </p>
  <h2 class="component-side-heading">Basic usage</h2>

  <p class="component-description">
    Set <b>props</b> for <b>type</b> to view in custom specified. <br />
  </p>

  <!----------------------------------------- space -------------------------------------------->
  <div class="component-example">
    <div class="example-body">

        <div style="display: flex; flex-direction: column;">



        <div style="width: 100%;">
          <HlxTagcount :arrayfile="data1" />
          <!-- <HlxTagcount :arrayfile="data1" />   -->

        </div>
        <div style="width: 100%;">
          <HlxTagcount :arrayfile="data1"  :type="'label'"/>
          <!-- <HlxTagcount :arrayfile="data1" />   -->

        </div>

         
      </div>


    </div>

    <div class="example-footer">
      <span id="status1-icon" class="icon" @click="showCode2('status1')">
        <i class="icon-code-regular"></i></span>
    </div>
  </div>

  <!----------------------------------------- space -------------------------------------------->
  <div id="status1" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor :id="'editor'" :display_language="false" :value="status_code1" :read_only="true"
          :theme="editor_theme" :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]" />
      </div>
    </section>
  </div>


  <!----------------------------------------- space -------------------------------------------->

  <h2 class="component-side-heading">TagCount Attributes</h2>


  <!----------------------------------------- space -------------------------------------------->

  <hlx-table :column-count="6" :border="['table', 'header', 'vertical', 'horizontal']" :bold-headers="false"
    :row-hover="false" theme="grey" :striped-rows="false">
    <template #thead>
      <hlx-table-head v-for="(i, index) in statusAttributes" :key="index" :width="i.width">{{ i.label
        }}</hlx-table-head>
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell v-for="(j, col_index) in statusAttributes" :key="col_index" :align="'left'">
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!----------------------------------------- space -------------------------------------------->

</template>

<script>
import HlxTagcount from '../components/TagcountComponent.vue';
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";

export default {
  name: "StatusView",
  components: {
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    HlxTagcount,
    CodeEditor
  },

  data() {
    return {
      editor_theme: "light",
      data1: [
        { properties: 'eleven' },
        { properties: 'twelve' },
        { properties: 'thirteen' },
        { properties: 'fourteen' },
        { properties: 'fifteen' },
        { properties: 'sixteen' },
        { properties: 'seventeen' }
      ],
      status_code1: `
    <template>
      <div>
        <HlxTagcount :arrayfile="data1" />
      </div>
    </template>
    
    <script>
      export default {
        data() {
          return {
            data1:[
        { properties: 'commercial' },
        { properties: 'Enterprise' },
        { properties: 'retailer' },
        { properties: 'industry' },
        { properties: 'small business' }
      ]
          }
        }
      }
    <\/script>
  `,
      statusAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        }
      ],
      table_data: [

        {
          name: "arrayfile",
          description: "to customize the data inside the tag",
          type: "array of object",
          accepted_values: "any",
          default: [],
          mandatory: true
        },
        {
          name: "itemKey",
          description: "to define the object key",
          type: "Object",
          accepted_values: "any",
          default: "properties",
          mandatory: true
        },
        {
          name: "type",
          description: "to define the theme",
          type: "String",
          accepted_values: "label or tag",
          default: "tag",
          mandatory: true
        },
        {
          name: "type",
          description: "which emit the particular data when click the cross in tag",
          type: "String",
          accepted_values: "label or tag",
          default: "tag",
          mandatory: true
        }
      ]



    }
  },

  methods: {
    showCode2(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    }
  },



};
</script>