<template>
  <section class="dotted-pagination-container">
    <i class="icon-angle-left-regular" :disabled="left_disable" @click="backward"></i>
    <div v-for="dot in dots" :key="dot" class="pagination-dot-circle" :class="{ active: dot == currentPage }"
      @mouseover="currentPositionbyHover(dot)" @click="currentPosition(dot)"></div>
    <i class="icon-angle-right-regular" :disabled="right_disable" @click="forward"></i>
    <input class="--hide-it" :value="currentPage" type="number" @input="handleCurentPageModelValue" />
  </section>
</template>

<script>
export default {
  name: "HlxDottedPagination",
  props: {
    dots: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 1
    },
  },
  emits: ["current-page", "current-page-hover", 'update:modelValue'],
  data() {
    return {
      currentPage: 1,
      right_disable: false,
      left_disable: false,
    };
  },
  watch: {
    modelValue: {
      handler(page) {
        if (page && page > 0) {
          this.currentPage = page;
        } else if (page && page <= 0) {
          this.currentPage = 0
        }

      },
      immediate: true
    },
    currentPage: {
      handler(page) {
        if (page != undefined && page > 0 && page <= this.dots) {
          this.currentPage = page;
        }
        else if (page != undefined && page > this.dots) {
          this.currentPage = this.dots
        }
        else if (page != undefined && page <= 0) {
          this.currentPage = 1
        }
        this.$emit('update:modelValue', page)
        this.right_disable = false;
        this.left_disable = false;
        this.$emit("current-page", page);
        if (page >= this.dots) {
          this.right_disable = true;
          // console.log('watch right disa')
          return;
        }
        if (page <= 1) {
          this.left_disable = true;
          // console.log('watch')
          return;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleCurentPageModelValue($event) {
      if ($event) {
        // if ($event.target.value && $event.target.value > 0 && $event.target.value <= this.dots) {
        this.currentPage = $event.target.value;
        // }
        // else if ($event.target.value && $event.target.value > this.dots) {
        //   this.currentPage = this.dots
        // }
        // else if ($event.target.value && $event.target.value <= 0) {
        //   this.currentPage = 1
        // }
        // this.$emit('update:modelValue', $event.target.value)
      } else {
        throw new Error('No value cached')
      }
    },
    forward() {
      if (this.currentPage >= this.dots) {
        this.right_disable = true;
        // console.log('right disa')
        return;
      }
      this.right_disable = false;
      ++this.currentPage;
      // console.log(this.currentPage,'in for');
      return;
    },
    backward() {
      if (this.currentPage <= 1) {
        this.left_disable = true;
        return;
      }
      this.left_disable = false;
      --this.currentPage;
      // console.log(this.currentPage,'in back');
      return;
    },
    currentPosition(pos) {
      this.currentPage = pos;
      this.$emit("current-page", pos);
    },
    currentPositionbyHover(pos) {
      this.currentPage = pos;
      this.$emit("current-page-hover", pos);
    },
  },
};
</script>
<!-- 
<style lang="scss" scoped>

</style> -->
