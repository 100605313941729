<template>
  <multiUploadComponentVue
    @uplodedFiles="getvaluearr"
    :file-type="'.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png'"
    :file-size-limit="'1GB'"
  ></multiUploadComponentVue>
</template>

<script>
import multiUploadComponentVue from "@/components/multiUploadComponent.vue";

export default {
  components: {
    multiUploadComponentVue,
  },
  methods: {
    getvaluearr(val) {
      console.log(val);
    },
  },
};
</script>

<style></style>
