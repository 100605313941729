<template>
  <div class="dataview-parent-container">
    <div class="header-panel">
      <div class="left">
        <slot name="nav"></slot>
      </div>
      <div class="search-container-fk">
        <slot name="search"></slot>
      </div>
      <div class="right view-switch-fk">
        <div class="list" @click="listview()">
          <i class="icon-menu-filled"></i>
        </div>
        <div class="grid" @click="gridview()">
          <i class="icon-grid-square-regular"></i>
        </div>
      </div>
    </div>
    <div
      v-if="grid_view == true"
      class="body-panel"
      :style="
        grid_view == true
          ? 'grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr))'
          : ''
      "
    >
      <card
        v-for="(card, index) in cardData"
        :key="index"
        :seperator="false"
        :card-title="true"
        :card-footer="true"
      >
        <template #card-title>
          <div class="card-header">
            <div class="group-tag">
              <i class="icon-tag-regular"></i
              ><span>{{ card[categoryProp] }}</span>
            </div>
            <hlx-label
              v-if="card[labelProp]?.toLowerCase() == 'instock'"
              class="primary sm"
              style="font-weight: 200"
              >Instock</hlx-label
            >
            <hlx-label
              v-if="card[labelProp]?.toLowerCase() == 'low stock'"
              class="warning sm"
              style="font-weight: 200"
              >Low stock</hlx-label
            >
            <hlx-label
              v-if="card[labelProp]?.toLowerCase() == 'out of stock'"
              class="error sm"
              style="font-weight: 200"
              >Out of stock</hlx-label
            >
            <hlx-label
              v-if="card[labelProp]?.toLowerCase() == 'active'"
              class="primary sm"
              style="font-weight: 200"
              >Active</hlx-label
            >
            <hlx-label
              v-if="card[labelProp]?.toLowerCase() == 'inactive'"
              class="error sm"
              style="font-weight: 200"
              >Inactive</hlx-label
            >
            <hlx-label
              v-if="card[labelProp]?.toLowerCase() == 'draft'"
              class="warning sm"
              style="font-weight: 200"
              >Draft</hlx-label
            >
          </div>
        </template>
        <template #card-body>
          <div class="card-body">
            <img v-if="card?.img_url !== ''" :src="card?.img_url" />
            <img v-else src="../assets/img/no_image.jpg" />
            <div class="product-title">
              {{ card[titleProp] }}
            </div>
            <div class="product-sub-title" style="font-size: 12px;">
              {{ subTitleProp.charAt(0).toUpperCase() + subTitleProp.slice(1) + ': ' + card[subTitleProp] }}
            </div>
            <div class="product-description" style="width: 200px">
              {{ card[descriptionProp] }}
            </div>
            <!-- <hlx-rating :rating="card?.card_body?.product_rating"></hlx-rating> -->
            <!-- price -->
          </div>
        </template>
        <template #card-footer>
          <div class="card-footerr">
            <!-- {{ card.currency }}
            {{ card.price }} -->
            <div class="price">
              <sup>{{
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: card?.currency,
                })
                  .format(card?.price?.whole)
                  .charAt(0)
              }}</sup
              >{{
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: card?.currency,
                })
                  .format(card?.price?.whole)
                  .substring(1)
                  .slice(0, -3)
              }}<sup>.{{ card?.price?.fraction }}</sup>
            </div>
            <!-- <div class="price"><sup>$</sup>{{card.card_footer.currency}}<sup>.00</sup></div> -->
            <hlx-button class="primary sm" @click="emitCardData(card)">
              <span style="display: flex; align-items: center; gap: 4px">
                <i :class="footerIcon"></i>
                <span>{{ footerText }}</span>
              </span>
            </hlx-button>
          </div>
        </template>
      </card>
      <!-- </div> -->
      <!-- </div> -->
    </div>
    <div v-if="list_view == true" class="body-panel">
      <div
        v-for="(card, index) in cardData"
        :key="index"
        class="list-container"
      >
        <div class="left">
          <img v-if="card?.img_url !== ''" :src="card?.img_url" />
          <img v-else src="../assets/img/no_image.jpg" />
          <div class="details">
            <div class="product-titlee" style="display: flex; align-items: center; gap: 8px; width: 100%;">
              {{ card[titleProp] }}
              <hlx-label
            v-if="card[labelProp]?.toLowerCase() == 'instock'"
            class="primary sm"
            style="font-weight: 200"
            >Instock</hlx-label
          >
          <hlx-label
            v-if="card[labelProp]?.toLowerCase() == 'low stock'"
            class="warning sm"
            style="font-weight: 200"
            >Low stock</hlx-label
          >
          <hlx-label
            v-if="card[labelProp]?.toLowerCase() == 'out of stock'"
            class="error sm"
            style="font-weight: 200"
            >Out of stock</hlx-label
          >
          <hlx-label
            v-if="card[labelProp]?.toLowerCase() == 'active'"
            class="primary sm"
            style="font-weight: 200"
            >Active</hlx-label
          >
          <hlx-label
            v-if="card[labelProp]?.toLowerCase() == 'inactive'"
            class="error sm"
            style="font-weight: 200"
            >Inactive</hlx-label
          >
          <hlx-label
            v-if="card[labelProp]?.toLowerCase() == 'draft'"
            class="warning sm"
            style="font-weight: 200"
            >Draft</hlx-label
          >
            </div>
            <div class="group-tag" style="font-size: 12px; color: #a6a6a6; font-weight: 100; display: flex; align-items: center; gap: 8px">
            <span style="display: flex; align-items: center">
              <i class="icon-tag-regular"></i
              ><span>{{ card[categoryProp] }}</span>
            </span>
            <div style="border-right: 1px solid #a6a6a6;
    height: 100%;"></div>
            <span style="display: flex; align-items: center">
              {{ subTitleProp.charAt(0).toUpperCase() + subTitleProp.slice(1) + ': ' + card[subTitleProp] }}
            </span>
            </div>
            <div
              class="product-descriptionn"
              style="
                font-size: var(--hlx-font-content-size-sm);
                margin: 2.5px;
                color: var(--hlx-text-color-primary);
                /* width: 100%; */
                white-space: normal;
                text-align: left;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ card[descriptionProp] }}
            </div>
            <!-- <hlx-rating :rating="'half-rated'" style="margin-left: -5px"></hlx-rating> -->
          </div>
        </div>
        <div style="border: 1px solid #d8d8d8; margin-left: 5%"></div>
        <div class="right">
          <div class="price">
            <sup>{{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: card.currency,
              })
                .format(card.price.whole)
                .charAt(0)
            }}</sup
            >{{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: card.currency,
              })
                .format(card.price.whole)
                .substring(1)
                .slice(0, -3)
            }}<sup>.{{ card.price.fraction }}</sup>
          </div>
          <!-- <button><i class="icon-save"></i><span class="btn-text">Add to cart</span></button> -->
          <hlx-button class="primary sm" @click="emitCardData(card)">
            <span style="display: flex; align-items: center; gap: 4px">
              <i :class="footerIcon"></i>
              <span>{{ footerText }}</span>
            </span>
          </hlx-button>
        </div>
      </div>
    </div>
    <!-- <div v-if="tbodyRows.length === 0" class="body-panel">
      <div class="no-data">No data</div>
    </div> -->
    <div v-if="cardData.length !== 0" class="footer-panel footer-panel-fk">
      <!-- <hlx-pagination
        :background="false"
        :total="total"
        :pager-count="3"
        :rows-per-page-list="[10]"
        @current-page="currentPage"
      /> -->
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import card from "./CardsComponent.vue";
import hlxLabel from "./LabelComponent";
// import hlxselect from "./SelectComponent.vue";
// import hlxRating from "./RatingComponent.vue";
// import hlxSearch from "./SearchComponent.vue";
// import hlxPagination from "./PaginationComponent.vue";
import HlxButton from "./ButtonComponent.vue";

export default {
  name: "HlxDataview",
  components: {
    card,
    hlxLabel,
    // hlxselect,
    // hlxRating,
    // hlxSearch,
    // hlxPagination,
    HlxButton,
  },
  props: {
    cardData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    titleProp: {
      type: String,
      default: "name",
    },
    subTitleProp: {
      type: String,
      default: "brand",
    },
    descriptionProp: {
      type: String,
      default: "description",
    },
    categoryProp: {
      type: String,
      default: "category",
    },
    categoryIconProp: {
      type: String,
      default: "icon-tag-regular",
    },
    labelProp: {
      type: String,
      default: "active",
    },
    footerIcon: {
      type: String,
      default: "icon-plus-circle-regular",
    },
    footerText: {
      type: String,
      default: "Add",
    },
  },
  emits: ['card-data'],
  data() {
    return {
      current_page: 1,
      rowPerPage: 10,
      total: "",
      // card_data_dummy: [
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex boardd",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "low stock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "abc",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "60",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "63",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      //   {
      //     card_title: "Accessories",
      //     card_body: {
      //       product_img_url:
      //         "https://images.ctfassets.net/osq47g2esuw5/4NItQKQad7O2gRL21esLJ/af523f89def19b5592ff3a073055a233/Cisco_Webex_Board_Pro_-_Floor_Stand_-_angle_transparent.png?w=1440&h=810&q=50&fm=png",
      //       product_title: "Webex board",
      //       product_description: "Webex board",
      //       product_rating: "4.5",
      //       product_stock: "instock"
      //     },
      //     card_footer: {
      //       price: "65",
      //     },
      //   },
      // ],
      dropdown_data: [
        { name: "Low to high", checked: false },
        { name: "High to low", checked: false },
      ],
      list_view: false,
      grid_view: true,
      paginated_data: [],
      searchkey: "",
      searched_data: [],
      card_data_dummy: [],
      tbodyRows: [],
    };
  },
  computed: {
    // tbodyRows() {
    //             var start = (this.current_page - 1) * this.rowPerPage;
    //   var end = start + this.rowPerPage;
    //     if(!this.searchkey){
    //   this.paginated_data = this.card_data_dummy.slice(start, end);
    //   this.total = this.card_data_dummy.length
    //   }else{
    //     if(this.searched_data.length>= start){
    //     // console.log("ss",start,end);
    //     this.paginated_data = this.searched_data.slice(start, end);
    //     }else{
    //       this.paginated_data = this.searched_data
    //     }
    //     this.total = this.searched_data.length
    //   }
    //   // console.log(this.searched_data,this.paginated_data,"here");
    //   return this.paginated_data;
    // },
  },
  mounted() {
    this.card_data_dummy = this.cardData;
    this.gridview();
    this.calc();
  },
  methods: {
    emitCardData(card) {
      this.$emit('card-data', card)
    },
    calc() {
      var start = (this.current_page - 1) * this.rowPerPage;
      var end = start + this.rowPerPage;
      if (!this.searchkey) {
        this.paginated_data = this.card_data_dummy.slice(start, end);
        this.total = this.card_data_dummy.length;
        console.log(this.total);
      } else {
        if (this.searched_data.length >= start) {
          // console.log("ss",start,end);
          this.paginated_data = this.searched_data.slice(start, end);
        } else {
          this.paginated_data = this.searched_data;
        }
        this.total = this.searched_data.length;
      }
      this.tbodyRows = this.paginated_data;
      // console.log(this.searched_data,this.paginated_data,"here");
      // return this.paginated_data;
    },
    listview() {
      document.querySelector(".list").classList.add("primary-bg");
      document.querySelector(".grid").classList.remove("primary-bg");
      document.querySelectorAll(".rating-group").forEach((r) => {
        r.style.marginLeft = "-5px";
      });
      document
        .querySelector(".icon-grid-square-regular")
        .classList.add("white-bg");
      document.querySelector(".icon-menu-filled").classList.remove("white-bg");
      this.list_view = true;
      this.grid_view = false;
    },
    gridview() {
      document.querySelector(".grid").classList.add("primary-bg");
      document.querySelector(".list").classList.remove("primary-bg");
      document
        .querySelector(".icon-grid-square-regular")
        .classList.remove("white-bg");
      document.querySelector(".icon-menu-filled").classList.add("white-bg");
      this.list_view = false;
      this.grid_view = true;
    },
    currentPage(val) {
      this.current_page = val;
      this.calc();
      // this.tbodyRows;
    },
    search(val) {
      this.searchkey = val;
      this.searched_data = this.card_data_dummy.filter(
        (o) =>
          o.card_title.toLowerCase().includes(val) ||
          o.card_body.product_title.toLowerCase().includes(val) ||
          o.card_body.product_description.toLowerCase().includes(val) ||
          o.card_body.product_stock.toLowerCase().includes(val) ||
          o.card_footer.price.whole.includes(val)
      );
      // this.card_data_dummy.filter(o=>console.log(o.card_footer.price))
      //  console.log(this.card_data_dummy);
      this.calc();
      //  this.tbodyRows
    },
    selectValue(val) {
      let chosen;
      val.forEach((v) => {
        if (v.checked == true) {
          chosen = v.name;
        }
      });
      if (chosen.toLowerCase() == "low to high") {
        this.card_data_dummy = this.card_data_dummy.sort(function (a, b) {
          return (
            parseFloat(a.card_footer.price.whole) -
            parseFloat(b.card_footer.price.whole)
          );
        });
      } else {
        this.card_data_dummy = this.card_data_dummy.sort(function (a, b) {
          return (
            parseFloat(b.card_footer.price.whole) -
            parseFloat(a.card_footer.price.whole)
          );
        });
      }
    },
  },
};
</script>
