<template>
  <h1 class="component-heading">Inputs</h1>
  <p class="component-description">
    The input element represents a typed data field, usually with a form control
    to allow the user to edit the data. The type attribute controls the data
    type of the element. It is an enumerated attribute.
  </p>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>
  <p class="component-description">
    set <b>:type</b> to <b>text</b> for basic text input field. <br />
    To display custom placeholder, add <b>:label-value</b> attribute which takes
    in a string value <br />
    You can also toggle label animation to On/Off by setting
    <b>:label-animation</b> attribute to <b>true</b>
  </p>
  <br />
  <div class="notes">
    <p class="note-title">Note:</p>
    <p class="note-body">
      use <b> v-model:value </b> to store your input values
    </p>
  </div>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <!-- <hlx-input
        v-model:value="x"
        :pre-val="x"
        :label-animation="true"
        label-value="Location"
        type="location"
        :clearable="false"
        :location-data-prop="locationData"
        :disabled="false"
        :display-error="true"
        :show-new-address-option="showNewAddressOption"
        :custom-background="'linear-gradient(181deg, #f7f7f7)'"
        name="username"
        @at-input="getLocations"
        @add-new-location="addNewLocation"
      /> -->
      <div style="display: flex">
      <hlx-input
        :label-animation="true"
        label-value="Username"
        :single-border="false"
        type="text"
        :clearable="false"
        :character-limit="8"
        :read-only="true"
        :disabled="false"
        :display-error="true"
        :custom-error="false"
        :custom-error-message="true"
        :pre-val="'heueo'"
        :custom-background="'linear-gradient(181deg, #f7f7f7)'"
        name="username"
        @at-input="log"
      /> <hlx-select :inline-search="true"></hlx-select>
      </div>
      <hlx-input
        :label-animation="true"
        type="text"
        :disabled="false"
        :read-only="true"
        :pre-val="'kshdals'"
        label-value="Placeholder"
        :clearable="false"

        name="placeholder"
        :min-length="5"
      />
    </div>
    <div class="example-footer">
      <span id="basic-input-icon" class="icon" @click="showCode('basic-input')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-input" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_input"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Mixed input fields</h2>
  <p class="component-description">
    Set <b>:type</b> to <b>mixed-text</b> for mixed text input field. <br />
    Set <b>:type</b> to <b>mixed-number</b> for mixed number input field. <br />
    Use slot to distribute elements that prepend or append to Input. Set
    <b>#prefix</b> to prepend and <b>#suffix</b> to append
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-input
        :label-animation="false"
        label-value="username"
        type="mixed-text"
        :disabled="false"
        :pre-val="''"
        :clearable="true"
        name="username"
        :suffix="true"
        :prefix="false"
      >
        <template #suffix> @gmail.com </template>
      </hlx-input>
      <br />
      <hlx-input
        :label-animation="true"
        label-value="Multi"
        type="mixed-text"
        :disabled="false"
        :pre-val="'hello world'"
        :clearable="true"
        name="username"
        :suffix="false"
        :prefix="false"
        :suffixselect="true"
      >
        <template #suffix-select>
          <hlx-select
            :options="dropdown_data"
            :inline-search ="true"
            :border="false"
            :placeholder-value="'Language'"
            :label-animation="false"
            :label="'name'"
            :prop-value="'value'"
            :background-color="'#f9fafb'"
          >
          </hlx-select>
        </template>
      </hlx-input>
      <br />
      <hlx-input
        :label-animation="false"
        label-value="Website"
        type="mixed"
        :disabled="false"
        :clearable="true"
        name="username"
        :prefix="true"
      >
        <template #prefix> https:// </template>
        <!-- <template #suffix> 
          halleyx.com
        </template> -->
      </hlx-input>
    </div>
    <div class="example-footer">
      <span id="mixed-input-icon" class="icon" @click="showCode('mixed-input')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="mixed-input" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="mixed_input"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Text area</h2>
  <p class="component-description">
    Set <b>:type</b> attribute to <b>textarea</b> to access textarea input
    field. You can also control character limit of the text area by setting
    <b>:max-length</b> attribute with an integer value. But to display the
    validation you have to set <b>:display-error</b> attribute to <b>true</b>
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-input
        :label-animation="false"
        label-value="Description"
        type="textarea"
        :max-length="500"
        :display-error="true"
        :read-only="true"
        :required="true"
        pre-val="Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis illum aspernatur iure. Illo cum, ut esse dignissimos facere rem omnis commodi adipisci distinctio, neque quo soluta vel rerum sint at.dsasd"
      />
      <!-- <hlx-input
        :single-border="false"
        :label-animation="false"
        label-value="Description"
        type="textarea"
        :max-length="800"
        :display-error="true"
        :read-only="false"
        :required="true"
        pre-val="Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis illum aspernatur iure. Illo cum, ut esse dignissimos facere rem omnis commodi adipisci distinctio, neque quo soluta vel rerum sint at.dsasd"
      /> -->
      <hlx-input
        :label-animation="true"
        label-value="Description"
        type="textarea"
        :max-length="500"
        :display-error="true"
        :read-only="false"
        :required="true"
        pre-val="Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis illum aspernatur iure. Illo cum, ut esse dignissimos facere rem omnis commodi adipisci distinctio, neque quo soluta vel rerum sint at."
      />

      <!-- <hlx-input
        :label-animation="false"
        type="textarea"
        :disabled="true"
        :pre-val="'dwawda'"
        label-value="Please share your valuable feedback here"
      /> -->
    </div>
    <div class="example-footer">
      <span id="text-area-icon" class="icon" @click="showCode('text-area')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="text-area" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="textarea_input"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Input with icons</h2>
  <p class="component-description">
    Add icons to the input field by specifying <b>:icon</b> attribute with the
    icon name (refer to our iconography page for icon names) and setting
    <b>:icon-position</b> attribute to either <b>left or right</b>
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-input
        :label-animation="true"
        label-value="Firstname"
        type="text"
        :clearable="true"
        :disabled="false"
        :pre-val="'Alexander'"
        icon="university-regular"
        icon-position="left"
      />
      <br />
      <hlx-input
        :label-animation="true"
        label-value="Lastname"
        type="text"
        :clearable="false"
        :disabled="false"
        :pre-val="'Rybak'"
        icon="user-alt-regular"
        icon-position="right"
      />
    </div>
    <div class="example-footer">
      <span id="icon-input-icon" class="icon" @click="showCode('icon-input')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="icon-input" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="icon_input"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Email & password</h2>
  <p class="component-description">
    Most commonly used form fields like email and password with inbuilt
    validations can also be used by setting <b>:type</b> attribute. <br />
    Set <b>:password-condition</b> attribute to true to display the validation
    box.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px; overflow: visible"
    >
      <hlx-input
        :label-animation="true"
        label-value="Email"
        type="email"
        :clearable="true"
        :display-error="true"
        :disabled="false"
        :read-only="false"
        :pre-val="'dsd@gmail.com'"
      />
      <!-- <hlx-input
        :single-border="true"
        :label-animation="false"
        label-value="Email"
        type="email"
        :clearable="true"
        :display-error="true"
        :disabled="false"
        :read-only="true"
        :pre-val="'dsds'"
      /> -->
      <hlx-input
        :label-animation="true"
        type="password"
        :password-condition="true"
        label-value="Password"
        :clearable="false"
        :display-error="true"
      />
      <!-- <hlx-input
        :label-animation="true"
        type="confirmpassword"
        :password-condition="false"
        label-value=""
        :clearable="false"
      /> -->
    </div>
    <div class="example-footer">
      <span
        id="email-password-icon"
        class="icon"
        @click="showCode('email-password')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="email-password" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="email_password"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Date, number & phone number</h2>
  <p class="component-description">
    Other input fields like date and number can also be used. <br />
    Set <b>:type</b> attribute to <b>phonenumber</b> enable phone number field.
    Supports validation for India, Canada & USA.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px; overflow: visible"
    >
      <hlx-input
        :label-animation="false"
        :single-border="true"
        :label-value="'Pick a date'"
        :date-format="'dd (short month), yyyy'"
        type="date"
        
        :clearable="false"
        icon="calendar-regular"
        pre-val=""
        icon-position="right"
        :disable-weekend="true"
        :start-year="1990"
        :display-error="true"
        :custom-error-message="'Please set a default value'"
        :custom-error="false"
        :end-year="2100"
        :holiday-list="holidays"
        @formattedDate="calendervalue"
      />
      <hlx-input
      v-model:value="dateInput"
        :label-animation="true"
        :single-border="false"
        :label-value="'Pick a date'"
        :date-format="'dd (short month), yyyy'"
        type="date"
        :clearable="false"
        icon="calendar-regular"
        :pre-val="dateInput"
        icon-position="right"
        :disable-weekend="true"
        :start-year="1995"
        :display-error="true"
        :custom-error-message="'Please set a default value'"
        :custom-error="false"
        :end-year="2100"
        :holiday-list="holidays"
        @formattedDate="calendervalue"
      />
      <hlx-input
        :label-animation="true"
        type="number"
        label-value="Number"
        :clearable="false"
        :display-error="false"
        :read-only="false"
        :custom-error-message="'Please set a default value'"
        :custom-error="false"
        :pre-val="'879798798'"
        :symbol="'₹'"
      />
      <br/>
      <hlx-input
        :label-animation="false"
        type="number"
        label-value="Number"
        :clearable="false"
        :display-error="false"
        :custom-error-message="'Please set a default value'"
        :custom-error="false"
        :pre-val="''"
        :symbol="'£'"
      />
      <br />
      <hlx-input
        :label-animation="true"
        type="phonenumber"
        label-value="Phone number"
        :country-code="countries"
        :clearable="false"
        :read-only="false"
        :display-error="true"
        :disabled="false"
        :pre-val="'9898989'"
      />

      <hlx-date-picker
        :pre-value="receivedData1"
        from-key="startDateTime"
        to-key="endDateTime"
        :label-animation="true"
        :read-only="false"
        :label-value="'Pick a date'"
        :custom-background="'linear-gradient(181deg, #f7f7f7)'"
        :top="409"
        :left="320"
        icon="calendar-regular"
        icon-position="right"
        required="true"
        @selected-range="handleCurrentDate"
      ></hlx-date-picker>
    </div>
    <div class="example-footer">
      <span id="date-phone-icon" class="icon" @click="showCode('date-phone')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="date-phone" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="date_phone"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Check box</h2>
  <p class="component-description">
    The payload for generating the checkbox options are sent in using the
    <b>:options</b> attribute which takes in an array of object. <br />
    Each object must contain the following attributes to function properly :
    <br />
  </p>
  <li class="component-description">
    name: (String) label name for each checkbox
  </li>
  <li class="component-description">
    checked: (true / false) sets whether the option must be pre-selected or not
  </li>
  <li class="component-description">
    disabled: (true / false) sets whether the option must be disabled or not
  </li>
  <li class="component-description">
    id: (unique id) distinguishes fields from each other
  </li>
  <br />
  <span class="component-description"
    >Set <b>:show-select-all</b> attribute to true to display select all check
    box as shown in second example below.</span
  >
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-input :options="options1" :type="'checkbox'" />
      <hlx-divider :position="'horizontal'" :space="'20px'" />
      <!-- check this out shakthi -->
      <hlx-input
        :options="options2"
        :type="'checkbox'"
        :show-select-all="true"
        :custom-slot="true"
        :checkbox-title-prop="'name'"
        :checkbox-sub-title-prop="'_id'"
        :checkbox-description-prop="'category'"
        :checkbox-label-prop="'price'"
        @check-box-event="log"
        @selected="log2"
      />
      <hlx-divider :position="'horizontal'" :space="'20px'" />
      <hlx-input
        :options="options3"
        :type="'checkbox'"
        :show-select-all="true"
      />
      <hlx-divider :position="'horizontal'" :space="'20px'" />
      <span class="component-description">Single checkbox example</span>
      <hlx-input
        :checked="true"
        :type="'single-checkbox'"
        :label-value="'checked'"
      />
      <hlx-input
        :checked="false"
        :type="'single-checkbox'"
        :label-value="'unchecked'"
      />
      <hlx-input
        :checked="true"
        :type="'single-checkbox'"
        :read-only="true"
        :label-value="'checked & read only'"
      />
      <hlx-input
        :checked="true"
        :type="'single-checkbox'"
        :read-only="false"
        :disabled="true"
        :label-value="'checked & disabled'"
      />
      <hlx-input
        :checked="false"
        :type="'single-checkbox'"
        :read-only="true"
        :label-value="'unchecked & read only'"
      />
      <hlx-input
        :checked="false"
        :type="'single-checkbox'"
        :read-only="false"
        :disabled="true"
        :label-value="'unchecked & disabled'"
      />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="basic-checkbox-icon"
        class="icon"
        @click="showCode('basic-checkbox')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-checkbox" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_checkbox"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Radio buttons</h2>
  <p class="component-description">
    Radio button uses the Joeye format of payload. Set <b>:type</b> to radio to
    display checkboxes and your payload to <b>:options</b> attribute
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <hlx-input
        :options="options4"
        :type="'radio-buttons'"
        pre-val="Racheal"
        :position="'horizontal'"
      />
      <hlx-divider :position="'horizontal'" :space="'20px'" />
      <hlx-input
        :options="options4"
        :type="'radio-buttons'"
        pre-val="Racheal"
      />
      <hlx-divider :position="'horizontal'" :space="'20px'" />
      
      <hlx-input
      :options="options4"
      :position="'horizontal'"
      :type="'border-radio-buttons'"
      />
      <hlx-divider :position="'horizontal'" :space="'20px'" />
      <!-- <hlx-divider :position="'horizontal'" :space="'20px'" /> -->
      <!-- <span class="component-description">Single radio example</span> -->
      <!-- <hlx-input
        :checked="false"
        :type="'single-radio'"
        :name="'dmn'"
        :bell-theme="true"
        :label-value="'Bell theme'"
      /> -->
      <hlx-input
        :checked="true"
        :type="'single-radio'"
        :name="'dmn'"
        :icon="'user-alt-regular'"
        :label-value="'Default theme'"
      />
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span id="basic-radio-icon" class="icon" @click="showCode('basic-radio')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-radio" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_radio"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <h2 class="component-side-heading">Toggle switch</h2>
  <p class="component-description">
    Switch is a much simpler input element that directly takes in boolean value
    in <b>:checked</b> attribute.
  </p>
  <div class="component-example">
    <div
      class="example-body3"
      style="background-color: white; border-radius: 5px"
    >
      <div style="display: flex; align-items: center; margin-bottom: 25px">
        false
        <hlx-input
          :checked="true"
          :type="'switch'"
          :disabled="false"
          :read-only="false"
        />
        true
      </div>
      <div style="display: flex; align-items: center">
        false
        <hlx-input
          :checked="true"
          :type="'switch'"
          :disabled="true"
          :read-only="false"
        />
        true
      </div>
    </div>
    <div class="example-footer">
      <!-- Replace your id wherever required -->
      <span
        id="basic-switch-icon"
        class="icon"
        @click="showCode('basic-switch')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-switch" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="basic_switch"
          :read_only="true"
          :theme="editor_theme"
          :languages="[
            ['Javascript', 'Vue'],
            ['javascript', 'JS'],
            ['python', 'Python'],
          ]"
        />
      </div>
    </section>
  </div>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Input attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in inputAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in inputAttributes"
          :key="col_index"
          :align="'left'"
          :width="i.width"
          style="word-wrap: break-word"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Input slots</h2>
  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in inputSlots"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data_slots" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in inputSlots"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :position="'horizontal'" :space="'20px'" />

  <h2 class="component-side-heading">Input events</h2>

  <hlx-table
    :column-count="3"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in inputEvents"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in input_events_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in inputEvents"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider" />
  <div class="mobile-next-page-nav">
    <span class="prev-btn" @click="prevNextPage('prev', 'rating')"
      ><i class="icon-angle-left-regular prev-icon"></i>Rating</span
    >
    <span class="nxt-btn" @click="prevNextPage('next', 'button')"
      >Button <i class="icon-angle-right-regular"></i
    ></span>
  </div>
</template>

<script>
import HlxDatePicker from "../components/datePickerComponent.vue";

import HlxInput from "../components/InputComponent.vue";
import HlxDivider from "../components/DividerComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import hlxSelect from "../components/SelectComponent.vue";
import axios from "axios";
export default {
  components: {
    HlxDatePicker,
    HlxInput,
    HlxDivider,
    hlxTableCell,
    hlxTableHead,
    hlxTable,
    CodeEditor,
    hlxSelect,
  },
  data() {
    return {
      dateInput: "12-12-2000",
      dropdown_data: [
        {
          name: "Php",
          checked: false,
          disabled: false,
          value: "Php",
          icon: "icon-database-regular",
          id: 1,
        },
        {
          name: "VueJs",
          checked: false,
          disabled: false,
          value: "VueJs",
          icon: "icon-database-regular",
          id: 2,
        },
        {
          name: "ReactJs",
          checked: false,
          disabled: false,
          value: "ReactJs",
          icon: "icon-database-regular",
          id: 3,
        },
        {
          name: "Html",
          checked: false,
          disabled: false,
          value: "Html",
          icon: "icon-database-regular",
          id: 4,
        },
        {
          name: "Css",
          checked: false,
          disabled: false,
          value: "Css",
          icon: "icon-database-regular",
          id: 5,
        },
        {
          name: "Scss",
          checked: false,
          disabled: false,
          value: "Scss",
          icon: "icon-database-regular",
          id: 6,
        },
        {
          name: "Sass",
          checked: false,
          disabled: false,
          value: "Sass",
          icon: "icon-database-regular",
          id: 7,
        },
        {
          name: "Sql",
          checked: false,
          disabled: false,
          value: "Sql",
          icon: "icon-database-regular",
          id: 8,
        },
        {
          name: "MongoDB",
          checked: false,
          disabled: false,
          value: "MongoDB",
          icon: "icon-database-regular",
          id: 9,
        },
        {
          name: "Javascript",
          checked: false,
          disabled: false,
          value: "Javascriptq",
          icon: "icon-database-regular",
          id: 10,
        },
      ],
      cc: {
        name: "India",
        dial_code: "+91",
        short: "IN",
        flags: "https://flagcdn.com/in.svg",
      },
      locationData: [],
      address_params:
        "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete/",
      x: "hello",
      showNewAddressOption: false,
      holidays: ["17-10-2022", "21-10-2022", "4-11-2022"],
      countries: {
                        "name": "United Kingdom",
                        "flags": "https://flagcdn.com/gb.svg",
                        "short": "GB"
                    },
      options1: [
        { name: "Racheal", checked: false, disabled: false, id: 1 },
        { name: "Joey", checked: false, disabled: false, id: 2 },
        { name: "Ross", checked: false, disabled: false, id: 3 },
        { name: "Phoebe", checked: false, disabled: false, id: 4 },
        { name: "Monica", checked: false, disabled: false, id: 5 },
        { name: "Gunther", checked: false, disabled: false, id: 6 },
      ],
      options2: [
        { name: "SAMSUNG Galaxy S24 Ultra 5G (Black, 256 GB)  (12 GB RAM)", checked: false, disabled: false, id: 1, category: 'Mobiles', '_id': '(ID: 89786676)', price: '$300 CAD' },
        { name: "Anker Powerport Wireless Charger", checked: false, disabled: false, id: 2, category: 'Mobiles', '_id': '(ID: 89786676)', price: '$300 CAD' },
        { name: "Apple Airpods", checked: false, disabled: false, id: 3, category: 'Mobiles', '_id': '(ID: 89786676)', price: '$300 CAD' },
        { name: "PopSockets PopGrip", checked: false, disabled: false, id: 4, category: 'Mobiles', '_id': '(ID: 89786676)', price: '$300 CAD' },
        { name: "Spigen Tough Armour Case", checked: false, disabled: false, id: 5, category: 'Mobiles', '_id': '(ID: 89786676)', price: '$300 CAD' },
        { name: "Redmi K20 Pro", checked: false, disabled: false, id: 6 , category: 'Mobiles', '_id': '(ID: 89786676)', price: '$300 CAD'},
      ],
      options3: [
        {
          name: "checked",
          checked: true,
          readOnly: false,
          disabled: false,
          id: 1,
        },
        {
          name: "unchecked",
          checked: false,
          readOnly: false,
          disabled: false,
          id: 2,
        },
        { name: "checked & read only", checked: true, readOnly: true, id: 3 },
        { name: "checked & disabled", checked: true, disabled: true, id: 4 },
        {
          name: "unchecked & read only",
          checked: false,
          readOnly: true,
          id: 5,
        },
        {
          name: "unchecked and disabled",
          checked: false,
          disabled: true,
          id: 6,
        },
      ],
      options4: [
        {
          value: "Racheal",
          name: "Racheal",
          checked: false,
          icon: 'university-regular',
          disabled: true,
          id: 1,
        },
        { value: "Ross", name: "Ross",
        icon: 'university-regular', checked: false, disabled: true, id: 2 },
        {
          value: "Monica",
          name: "Monica",
          icon: 'university-regular',
          checked: true,
          disabled: true,
          id: 3,
        },
        {
          value: "Chandler",
          name: "Chandler",
          icon: 'university-regular',
          checked: false,
          disabled: true,
          id: 4,
        },
        { value: "Joey", name: "Joey",
        icon: 'university-regular', checked: false, disabled: true, id: 5 },
        {
          value: "Phoebe",
          name: "Phoebe",
          icon: 'university-regular',
          checked: false,
          disabled: true,
          id: 6,
        },
      ],
      editor_theme: "light",
      inputEvents: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: null,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: null,
          type: "string",
          format: "",
        },
      ],
      input_events_data: [
        {
          name: "iso-date",
          description: "Emits the selected date from date input in ISO format",
          parameter: "(value: string)",
        },
        {
          name: "at-enter",
          description: "Triggers when the enter key is pressed",
          parameter: "(Object{event, value})",
        },
        {
          name: "at-input",
          description: "Triggers when the input value is changed",
          parameter: "(value: string|number)",
        },
        {
          name: "clear",
          description: "Triggers when the input value is cleared",
          parameter: "-",
        },
        {
          name: "selected",
          description: "Emits the value when checkbox or radio is selected",
          parameter: "(object of the selected option)",
        },
        {
          name: "focus-out",
          description: "Emits the value when the input looses the focus",
          parameter: "(value: string|number)",
        },
        {
          name: "change",
          description:
            "Emits the payload in sigle-checkbox when the value is changed",
          parameter: "(object of the single-checkbox)",
        },
      ],
      input_attributes_data: [
        {
          Name: "type",
          Description: "Sets the type of the input field",
          Type: "String",
          accepted_values:
            "text / textarea / email / password / confirmpassword / number / phonenumber / date",
          mandatory: "yes",
          Default: "text",
        },
        {
          Name: "label-value",
          Description: "Sets placeholder value for the inout field",
          Type: "String",
          accepted_values: "-",
          mandatory: "no",
          Default: "-",
        },
        {
          Name: "label-animation",
          Description:
            "Adds an animation to the label value when the input field is active",
          Type: "Boolean",
          accepted_values: "true / false",
          mandatory: "no",
          Default: "false",
        },
        {
          Name: "clearable",
          Description:
            "Shows a clear icon on the right end of the field to clear the input value",
          Type: "Boolean",
          accepted_values: "true / false",
          mandatory: "no",
          Default: "false",
        },
        {
          Name: "icon",
          Description:
            "Choose a name of the icon from our iconography page to display in the input field",
          Type: "String",
          accepted_values: "names from iconography page",
          mandatory: "no",
          Default: "false",
        },
        {
          Name: "icon-position",
          Description: "Sets position for the icon whether on left or right",
          Type: "String",
          accepted_values: "left / right",
          mandatory: "no",
          Default: "right",
        },
      ],
      inputAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: null,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: null,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: null,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 200,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: null,
          type: "string",
          format: "",
        },
      ],
      table_data: [
        {
          name: "value",
          description:
            "Specify the attribute to be displayed in checkboxes and radio buttons",
          type: "String",
          accepted_values: "-",
          default: "name",
          mandatory: false,
        },
        {
          name: "date-format",
          description: "Specify the format for date input",
          type: "String",
          accepted_values:
            "dd-mm-yyyy, mm-dd-yyyy, dd-(short month)-yyyy, dd-(long month)-yyyy, yyyy-mm-dd",
          default: "text",
          mandatory: false,
        },
        {
          name: "type",
          description:
            "Specify the type of the input field, for example text, text area, number. You can also use specifc types like email, password and date",
          type: "String",
          accepted_values:
            "text / mixed / textarea / email / password / confirmpassword / date / number / phonenumber / single-checkbox / single-radio",
          default: "text",
          mandatory: false,
        },
        {
          name: "label-animation",
          description:
            "Adds animation to the labels whenever the input is focused",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: false,
        },
        {
          name: "label-value",
          description:
            "Label value acts as the placeholder value for the input field",
          type: "String",
          accepted_values: "-",
          default: "-",
          mandatory: true,
        },
        {
          name: "clearable",
          description:
            "Adds a clear icon to the regular input fields that clear the input value when clicked",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: false,
        },
        {
          name: "icon",
          description:
            "Add icons to your input fields to give a better user experience",
          type: "String",
          accepted_values: "refer to our iconography page",
          default: "-",
          mandatory: false,
        },
        {
          name: "icon-position",
          description:
            "Specify thr position of the icon. can be used only when icon name is specified",
          type: "String",
          accepted_values: "left / right",
          default: "-",
          mandatory: "true (if icon is true)",
        },
        {
          name: "min-limit",
          description: "Set minimum limit for the number input field",
          type: "Number",
          accepted_values: "-",
          default: "0",
          mandatory: false,
        },
        {
          name: "max-limit",
          description: "Set maximum limit for the number input field",
          type: "Number",
          accepted_values: "-",
          default: "1000",
          mandatory: false,
        },
        {
          name: "password-condition",
          description: "Shows a popover for validating the password",
          type: "Boolean",
          accepted_values: "true / false",
          default: "true",
          mandatory: false,
        },
        {
          name: "min-length",
          description: "Set minimum character limit for input text",
          type: "Number",
          accepted_values: "> 0",
          default: "-",
          mandatory: false,
        },
        {
          name: "max-length",
          description: "Set maximum character limit for input text",
          type: "Number",
          accepted_values: "> 0",
          default: "-",
          mandatory: false,
        },
        {
          name: "disable-weekend",
          description:
            "Specify to enable / disable the option to select the weekend days in date input field",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "start-year",
          description: "Set starting year in the date input",
          type: "Number",
          accepted_values: "integer",
          default: "1950",
          mandatory: "false",
        },
        {
          name: "end-year",
          description: "Set ending year in the date input",
          type: "Number",
          accepted_values: "integer",
          default: "2050",
          mandatory: "false",
        },
        {
          name: "options",
          description:
            "Array of items to be displayed on the checkbox/radio buttons",
          type: "Array",
          accepted_values: "-",
          default: "-",
          mandatory: true,
        },
        {
          name: "show-select-all",
          description: "Toggle if you want select all option or not",
          type: "Boolean",
          accepted_values: "true / false",
          default: "-",
          mandatory: true,
        },
        {
          name: "position",
          description: "Sets horizontal/vertical view for radio buttons",
          type: "String",
          accepted_values: "horizontal / vertical",
          default: "vertical",
          mandatory: false,
        },
        {
          name: "country-codes",
          description:
            "Supply the list of country codes for phone number component",
          type: "Array",
          accepted_values: "[{}]",
          default: `[
        {
          id: 1,
          flag: 'in',
          name: 'India',
          code: '+91',
          checked: false
        },
        {
          id: 2,
          flag: 'ca',
          name: 'Canada',
          code: '+1',
          checked: false
        },
        {
          id: 3,
          flag: 'us',
          name: 'USA',
          code: '+1',
          checked: false
        }
]`,
          mandatory: false,
        },
        {
          name: "prefix",
          description: "Enables prefix slot for mixed input type",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "true (if prefix slot is used in mixed input is true)",
        },
        {
          name: "suffix",
          description: "Enables suffix slot for mixed input type",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "true (if prefix slot is used in mixed input is true)",
        },
        {
          name: "suffixselect",
          description: "Enables suffix-select slot for mixed input type",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "true (if prefix and suffix slot is used in mixed input is false)",
        },
        {
          name: "custom-background",
          description:
            "Adds custom background color for label and input fields",
          type: "String",
          accepted_values: "any CSS color value",
          default: "#ffffff",
          mandatory: "false",
        },
        {
          name: "pre-val",
          description: "Used to pre-populate the input field",
          type: "String / Number",
          accepted_values: "String / Number",
          default: "-",
          mandatory: "false",
        },
        {
          name: "disabled",
          description:
            'Disables the input field with cursor showing "not-allowed"',
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "read-only",
          description: "Disables the input field with default cursor",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "display-error",
          description:
            "Enables a 20px area below the input field to show the error message",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory:
            "true (if customError is true / if min-length or max-length is used)",
        },
        {
          name: "custom-error",
          description: "Highlights the input field as error",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "custom-error-message",
          description: "Displays the custom error message",
          type: "String",
          accepted_values: "String",
          default: "-",
          mandatory: "true (if customError is true)",
        },
        {
          name: "required",
          description:
            "shows an indicator (asterisk) on the label when the field is focussed",
          type: "Boolean",
          accepted_values: "true / false",
          default: "false",
          mandatory: "false",
        },
        {
          name: "character-limit",
          description:
            'Limits the characters that can be typed (works in type "text" input)',
          type: "Number",
          accepted_values: ">0",
          default: "-",
          mandatory: "false",
        },
      ],
      inputSlots: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 650,
          type: "string",
          format: "",
        },
        {
          prop: "fallback",
          label: "Fallback",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      table_data_slots: [
        {
          name: "#prefix",
          description: 'Displays text in front of type "mixed" input',
          fallback: "-",
        },
        {
          name: "#suffix",
          description: 'Displays text at the end of type "mixed" input',
          fallback: "-",
        },
        {
          name: "#suffix-select",
          description: 'Displays text at the end of type "mixed" select',
          fallback: "-",
        }
      ],
      input_Attributes: {
        columns: [
          {
            name: "attribute",
            label: "Attributes",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "description",
            label: "Description",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "type",
            label: "Type",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "accepted_values",
            label: "Accepted values",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "default",
            label: "Default",
            width: "50",
            type: "string",
            format: "",
          },
          {
            name: "mandatory",
            label: "Mandatory",
            width: "50",
            type: "string",
            format: "",
          },
        ],
        table_data: [
          {
            name: "type",
            description:
              "Specify the type of the input field, for example text, text area, number. You can also use specifc types like email, password and date",
            type: "String",
            accepted_values:
              "text / textarea / email / password / confirmpassword / date / number / phonenumber",
            default: "text",
            mandatory: false,
          },
          {
            name: "label-animation",
            description:
              "Adds animation to the labels whenever the input is focused",
            type: "Boolean",
            accepted_values: "true / false",
            default: "false",
            mandatory: false,
          },
          {
            name: "label-value",
            description:
              "Label value acts as the placeholder value for the input field",
            type: "String",
            accepted_values: "-",
            default: "-",
            mandatory: true,
          },
          {
            name: "clearable",
            description:
              "Adds a clear icon to the regular input fields that clear the input value when clicked",
            type: "Boolean",
            accepted_values: "true / false",
            default: "false",
            mandatory: false,
          },
          {
            name: "icon",
            description:
              "Add icons to your input fields to give a better user experience",
            type: "String",
            accepted_values: "refer to our iconography page",
            default: "-",
            mandatory: false,
          },
          {
            name: "icon-position",
            description:
              "Specify thr position of the icon. can be used only when icon name is specified",
            type: "String",
            accepted_values: "left / right",
            default: "-",
            mandatory: "true (if icon is true)",
          },
          {
            name: "min-limit",
            description: "Set minimum limit for the number input field",
            type: "Number",
            accepted_values: "-",
            default: "0",
            mandatory: false,
          },
          {
            name: "max-limit",
            description: "Set maximum limit for the number input field",
            type: "Number",
            accepted_values: "-",
            default: "1000",
            mandatory: false,
          },
          {
            name: "password-condition",
            description: "Shows a popover for validating the password",
            type: "Boolean",
            accepted_values: "true / false",
            default: "true",
            mandatory: false,
          },
          {
            name: "min-length",
            description: "Set minimum character limit for input text",
            type: "Number",
            accepted_values: "> 0",
            default: "-",
            mandatory: false,
          },
          {
            name: "max-length",
            description: "Set maximum character limit for input text",
            type: "Number",
            accepted_values: "> 0",
            default: "-",
            mandatory: false,
          },
          {
            name: "disable-weekend",
            description:
              "Specify to enable / disable the option to select the weekend days in date input field",
            type: "Boolean",
            accepted_values: "true / false",
            default: "false",
            mandatory: "false",
          },
        ],
      },
      basic_input: `
<template>
    <hlx-input
          :label-animation="true"
          label-value="Username"
          type="text"
          :clearable="true"
        />
    <hlx-input
          :label-animation="false"
          type="text"
          label-value="Placeholder"
          :clearable="false"
        />
</template>

<script>
export default {

}
</\script>
`,
      mixed_input: `
<template>
    <hlx-input
      :disabled="false"
        :label-animation="true"
        label-value="Multi"
        type="mixed"
        :clearable="true"
        name="username"
        :suffix="true"
        >
        <template #suffix>
          www.
        </template>
      </hlx-input>


      // Using slot for dropdown
      <hlx-input
        :label-animation="true"
        label-value="Multi"
        type="mixed-text"
        :disabled="false"
        :pre-val="'hello world'"
        :clearable="true"
        name="username"
        :suffix="false"
        :prefix="false"
        :suffixselect="true"
      >
        <template #suffix-select>
          <hlx-select
            :options="dropdown_data"
            :placeholder-value="'Language'"
            :label-animation="false"
            :label="'name'"
            :prop-value="'value'"
            :background-color="'#f9fafb'"
          >
          </hlx-select>
        </template>
      </hlx-input>



      <hlx-input
        :label-animation="false"
        label-value="Multi"
        type="mixed"
        :clearable="true"
        name="username"
        :prefix="true"
        :suffix="true"
        >
        <template #prefix>
          www.
        </template>
        <template #suffix>
          @halleyx.com
        </template>
      </hlx-input>
</template>

<script>
export default {

}
</\script>
`,
      textarea_input: `
<template>
    <hlx-input
          :label-animation="true"
          label-value="Feedback box"
          type="textarea"
          :clearable="true"
        />
    <hlx-input
          :label-animation="false"
          type="textarea"
          label-value="Please share your valuable feedback here"
          :clearable="false"
        />
</template>

<script>
export default {

}
</\script>
`,
      icon_input: `
<template>
    <hlx-input
          :label-animation="true"
          label-value="Firstname"
          type="text"
          :clearable="true"
          icon="font"
          iconposition="left"
        />
    <hlx-input
          :label-animation="true"
          label-value="Lastname"
          type="text"
          :clearable="false"
          icon="human"
          iconposition="right"
        />
</template>

<script>
export default {

}
<//script>
`,
      email_password: `
<template>
    <hlx-input
          :label-animation="true"
          label-value="Email"
          type="email"
          :clearable="true"
        />
    <hlx-input
          :label-animation="true"
          type="password"
          :password-condition="true"
          label-value="Password"
          :clearable="false"
        />
    <hlx-input
          :label-animation="true"
          type="confirmpassword"
          :password-condition="true"
          label-value="Confirm password"
          :clearable="false"
        />
</template>

<script>
export default {

}
</\script>
`,
      date_phone: `
<template>
    <hlx-input
          :label-animation="false"
          type="date"
          :clearable="false"
          icon="calendar"
          iconposition="right"
        />
    <hlx-input
          :label-animation="true"
          type="number"
          label-value="Number"
          :clearable="false"
        />
    <hlx-input
          :label-animation="true"
          type="phonenumber"
          label-value="Phone number"
          :clearable="false"
        />
</template>

<script>
export default {

}
</\script>
`,
      basic_checkbox: `
<template>
  <hlx-input :options="this.options1" :type="'checkbox'"/>
  <hlx-input :options="this.options2" :type="'checkbox'" :show-select-all="true"/>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <span class="component-description">Single checkbox example</span>
  <hlx-input
    :checked="false"
    :type="'single-checkbox'"
  />
</template>

<script>

export default {
  data () {
    return {
      options1: [
        { name: 'Racheal', checked: false, disabled: false },
        { name: 'Joey', checked: false, disabled: false },
        { name: 'Ross', checked: false, disabled: false },
        { name: 'Phoebe', checked: true, disabled: false },
        { name: 'Monica', checked: false, disabled: false },
        { name: 'Gunther', checked: true, disabled: true }
      ],
      options2: [
        { name: 'Racheal', checked: true, disabled: false, id: 1 },
        { name: 'Joey', checked: false, disabled: false, id: 2 },
        { name: 'Ross', checked: false, disabled: false, id: 3 },
        { name: 'Phoebe', checked: false, disabled: false, id: 4 },
        { name: 'Monica', checked: false, disabled: false, id: 5 },
        { name: 'Gunther', checked: false, disabled: false, id: 6 }
      ]
    }
  }
}
</\script>
      `,
      basic_radio: `
<template>
  <hlx-input :options="this.options3" :type="'radio-buttons'"/>
  <hlx-input :options="this.options4" :type="'radio-buttons'"/>
  <hlx-divider :position="'horizontal'" :space="'20px'" />
  <span class="component-description">Single radio example</span>
  <hlx-input :checked="false" :type="'single-radio'" :name="'dmn'"/>
  <hlx-input :checked="false" :type="'single-radio'" :name="'dmn'"/>
  <hlx-input :options="options4" 
      :position="'horizontal'"
      :type="'border-radio-buttons'" />
</template>

<script>

export default {

  data () {
    return {
      options3: [
        { name: 'Racheal', checked: false, disabled: false, id: 1 },
        { name: 'Ross', checked: false, disabled: false, id: 2 },
        { name: 'Monica', checked: false, disabled: false, id: 3 },
        { name: 'Chandler', checked: false, disabled: false, id: 4 },
        { name: 'Joey', checked: true, disabled: false, id: 5 },
        { name: 'Phoebe', checked: false, disabled: false, id: 6 }
      ],
      options4: [
        { name: 'Racheal', checked: true, disabled: false, id: 1 },
        { name: 'Ross', checked: false, disabled: false, id: 2 },
        { name: 'Monica', checked: false, disabled: false, id: 3 },
        { name: 'Chandler', checked: false, disabled: false, id: 4 },
        { name: 'Joey', checked: false, disabled: false, id: 5 },
        { name: 'Phoebe', checked: false, disabled: false, id: 6 }
      ]
    }
  }
}
</\script>
      `,
      basic_switch: `
<template>
  <hlx-input :checked="false" :type="'switch'"/>
  <hlx-input :checked="true" :type="'switch'"/>
</template>

<script>

export default {

}
</\script>
      `,
    };
  },
  computed: {},
  methods: {
    async getLocations() {
      const input = this.x;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await axios.get(
          `https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete/${input}`
        );
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && this.x !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    addNewLocation(val) {
      console.log(val);
      this.showNewAddressOption = false;
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    log(val) {
      this.x = val;
      // console.log('checkBoxEvent', )
    },
    log2() {
      // console.log('selected', val)
    },
    calendervalue(val){
console.log(
  val,"calendervalue"
);
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>

<style></style>
