<template id="vue-toggle">
  <div class="hlx-btn-group">
    <button
      v-for="(key,i) in values"
      :key="key[valueProp]+i"
      type="button"
      :class="[
        'hlx-g-btn',
        { 'hlx-g-btn-active': selected === key[valueProp], 'hlx-g-btn-default': selected !== key[valueProp] },
        { '--border-bottom': tabView }
      ]"
      @click="changeSelectVal(key[valueProp])"
    >
      {{ key[displayProp] }}
    </button>
  </div>
  <slot></slot>
</template>
<script>
export default {
  name: "HlxButtonGroup",
 
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    default: {
      type: String,
      default: () => '',
    },
    valueProp: {
      type: String,
      default: () => 'value',
    },
    displayProp: {
      type: String,
      default: () => 'label',
    },
    tabView: {
      type: Boolean,
      default: () => false,
    }
  }, 
  emits:['change'],
  data() {
    return {
      selected: '',
    };
  },
  mounted() {
    this.selected = this.default;
  },
  methods: {
    changeSelectVal: function (val) {
      // console.log("🚀 ~ file: ButtonGroupComponent.vue:66 ~ val:", val)
      this.selected = val
      this.$emit('change',val)
    },
  },
};
</script>

<style>

</style>
