<template>
  <h1 class="component-heading">Alert</h1>
  <p class="component-description">
    An alert displays a short, important message in a way that attracts the
    user's attention without interrupting the user's task.
  </p>
  <h2 class="component-side-heading">Test alert</h2>

  <p class="component-description">
    Just give <b>notify</b> prop as object with <b>type</b> and
    <b>message</b> properties to toast your notification. Give dynamic time
    range to close notification, use <b>:delay</b> prop
  </p>
  <div class="component-example">
    <div class="example-body">
      <div class="button">
        <hlx-button class="primary sm" @click="PushNotification"
          >Push notification</hlx-button
        >
      </div>
      <hlx-alert-notification :notify="notification" :auto-close="true" />
    </div>
    <div class="example-footer">
      <span id="basic-cards-icon" class="icon" @click="showCode('basic-cards')"
        ><i class="icon-code-regular"></i
      ></span>
    </div>
  </div>
  <div id="basic-cards" class="source-code" style="display: none">
    <section class="example-source-wrapper">
      <div class="source">
        <CodeEditor
          :id="'editor'"
          :display_language="false"
          :value="alert_source"
          :read_only="true"
          :theme="editor_theme"
        />
      </div>
    </section>
  </div>

  <h2 class="component-side-heading">Alert attributes</h2>
  <hlx-table
    :column-count="6"
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in alertAttributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in alertAttributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'typography')"
      ><i class="icon-angle-left-regular prev-icon"></i>Typography</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'badge')"
        >Badge <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import hlxAlertNotification from "../components/AlertComponent.vue";
import hlxButton from "@/components/ButtonComponent.vue";
import CodeEditor from "simple-code-editor";
import hlxDivider from "../components/DividerComponent.vue";
// import hlxTable from "../components/NewTable.vue";
import HlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
// import hlxColumn from "../components/NewTableColumn.vue";
export default {
  name: "AlertView",
  components: {
    hlxTableCell,
    hlxTableHead,
    HlxTable,
    hlxAlertNotification,
    CodeEditor,
    hlxButton,
    hlxDivider,
  },
  data() {
    return {
      notification: {},
      editor_theme: "light",
      showAlert: false,
      type: "Success",
      showAlertDelay: false,
      typeDelay: "Success",
      showAlertAuto: false,
      typeAuto: "Success",
      table_data: [
        {
          name: "notify",
          description: "To display the kind of alert",
          type: "Object",
          accepted_values: `{type:'primary',message:''}                         {type:'info',message:''}                      {type:'danger',message:''}                           {type:'warning',message:''}`,
          default: "-",
          mandatory: "true",
        },
        {
          name: "auto-close",
          description: "Close the alert automatically or manually",
          type: "Boolean",
          accepted_values: "true,false",
          default: "true",
          mandatory: "false",
        },
        {
          name: "delay",
          description: "To extend/short the time of alert showing",
          type: "Number",
          accepted_values: ">3000",
          default: "3000",
          mandatory: "false",
        },
      ],
      alertAttributes: [
        {
          prop: "name",
          label: "Attributes",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],

      alert_source: `
<template>
    <div class="button">
        <hlx-button class="primary" @click="PushNotification"
          >Success</hlx-button
        >
        
      </div>
      <hlx-alert-notification
        :notify="notification"
        :auto-close="true"
      />
</template>
<script>
export default {
  data() {
    return {
          showAlert: false,
          notification:{}
          }
      },
  methods: {
     methods: {
    PushNotification(data){
      let types = ["success","danger","warning","primary"]
      let obj = {}
      // obj[Math.floor(Math.random()*4)]
      this.notification = {type:types[Math.floor(Math.random()*4)],message:"Notification"+Math.floor(Math.random()*100)}
    },
   
  },
};
<\/script>

<style lang="scss" scoped>
.button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  margin: inherit;
  gap: 20px;
}
</style>
 `,
    };
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    PushNotification() {
      let types = ["success", "danger", "warning", "primary"];
      // let obj = {};
      // obj[Math.floor(Math.random()*4)]
      this.notification = {
        type: types[Math.floor(Math.random() * 4)],
        message: "Notification" + Math.floor(Math.random() * 100),
      };
      console.log(
        "🚀 ~ file: AlertView.vue:505 ~ PushNotification ~ this.notification:",
        this.notification
      );
    },
    Success(key) {
      switch (key) {
        case "delay":
          this.typeDelay = "Success";
          this.showAlertDelay = true;
          break;
        case "auto":
          this.typeAuto = "Success";
          this.showAlertAuto = true;
          break;
        default:
          (this.notification = { type: "success", message: "adfdfdsf" }),
            { type: "success", message: "adfdfdsf" };
          // this.type = "Success";
          // this.showAlert = true;
          break;
      }
    },
    Danger(key) {
      switch (key) {
        case "delay":
          this.typeDelay = "danger";
          this.showAlertDelay = true;
          break;
        case "auto":
          this.typeAuto = "danger";
          this.showAlertAuto = true;
          break;
        default:
          (this.notification = { type: "success", message: "adfdfdsf" }),
            { type: "success", message: "Danger" };
          // this.type = "danger";
          // this.showAlert = true;
          break;
      }
    },
    Success1(key) {
      switch (key) {
        case "delay":
          this.typeDelay = "Success";
          this.showAlertDelay = true;
          break;
        case "auto":
          this.typeAuto = "Success";
          this.showAlertAuto = true;
          break;
        default:
          (this.notification = { type: "success", message: "adfdfdsf" }),
            { type: "success", message: "adfdfdsf" };
          // this.type = "Success";
          // this.showAlert = true;
          break;
      }
    },
    Danger1(key) {
      switch (key) {
        case "delay":
          this.typeDelay = "danger";
          this.showAlertDelay = true;
          break;
        case "auto":
          this.typeAuto = "danger";
          this.showAlertAuto = true;
          break;
        default:
          (this.notification = { type: "success", message: "adfdfdsf" }),
            { type: "success", message: "adfdfdsf" };
          // this.type = "danger";
          // this.showAlert = true;
          break;
      }
    },
    Warning(key) {
      switch (key) {
        case "delay":
          this.typeDelay = "Warning";
          this.showAlertDelay = true;
          break;
        case "auto":
          this.typeAuto = "Warning";
          this.showAlertAuto = true;
          break;
        default:
          this.type = "Warning";
          this.showAlert = true;
          break;
      }
    },
    Primary(key) {
      switch (key) {
        case "delay":
          this.typeDelay = "Primary";
          this.showAlertDelay = true;
          break;
        case "auto":
          this.typeAuto = "Primary";
          this.showAlertAuto = true;
          break;
        default:
          this.type = "Primary";
          this.showAlert = true;
          break;
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  margin: inherit;
  gap: 20px;
}
</style>
