<template>
  <div
    v-if="type.toLowerCase() == 'rule'"
    :id="'visual-builder' + unique"
    class="builder-parent"
  >
    <div class="parent-container">
      <div class="top">
        <div class="left"></div>
        <div class="right">
          <div class="top-1">
            <div></div>
            <div
              v-for="(level, level_index) in reactive_rule"
              :key="level_index"
              class="level"
            >
              <!-- {{ level }} -->
              <!-- <div class="left-1"><hr></div> -->
              <div class="right-1">
                <!-- <div class="left-4" v-if="level.groups.length>1"><div class="left-4-inner" ></div></div> -->
                <div
                  v-if="level.groups.length > 1"
                  class="left-4"
                  :style="`padding-top:${padding_top1}px; padding-bottom:${padding_bottom1}px; `"
                >
                  <div v-if="level.groups.length > 1" class="left-4-inner">
                    <span
                      v-if="level.groups.length > 1"
                      id="level1"
                      class="boxx"
                    >
                      <div
                        class="grp-and selected-grp"
                        @click="
                          toggle('and', level_index, groups_index, group_index)
                        "
                      >
                        AND
                      </div>
                      <div
                        class="grp-or"
                        @click="
                          toggle('or', level_index, groups_index, group_index)
                        "
                      >
                        OR
                      </div></span
                    >
                  </div>
                </div>
                <div v-if="level.groups.length >= 1" class="right-4">
                  <div
                    v-for="(groups, groups_index) in level.groups"
                    :key="groups_index"
                    class="groups"
                  >
                    <div
                      v-if="
                        level.groups.length > 1 &&
                        groups.group[0].conditions.length > 0
                      "
                      class="left-5"
                    >
                      <hr />
                    </div>
                    <div
                      v-if="groups.group[0].conditions.length > 0"
                      class="right-5"
                    >
                      <div class="top-right-5">
                        <div
                          v-if="
                            groups.group.length > 1 &&
                            groups.group[0].conditions.length > 0
                          "
                          class="left-6"
                          :style="`padding-top:${padding_top2[groups_index]}px; padding-bottom:${padding_bottom2[groups_index]}px; `"
                        >
                          <div class="left-6-inner">
                            <span class="boxxx level11"
                              ><div
                                class="grp-and selected-grp"
                                @click="
                                  toggle('and', level_index, groups_index)
                                "
                              >
                                AND
                              </div>
                              <div
                                class="grp-or"
                                @click="toggle('or', level_index, groups_index)"
                              >
                                OR
                              </div></span
                            >
                          </div>
                        </div>
                        <div
                          v-if="groups.group[0].conditions.length > 0"
                          class="right-6"
                        >
                          <div
                            v-for="(group, group_index) in groups.group"
                            :key="group_index"
                            class="ggroup"
                          >
                            <div v-if="groups.group.length > 1" class="left-7">
                              <hr />
                            </div>
                            <div
                              v-if="group.conditions.length > 0"
                              class="right-7"
                              :style="
                                group.conditions.length > 1
                                  ? 'background:#f7f7f7'
                                  : 'background:#fff'
                              "
                            >
                              <div class="trial">
                                <div
                                  v-if="group.conditions.length > 1"
                                  class="left-3"
                                >
                                  <div class="left-3-inner">
                                    <span class="box"
                                      ><div
                                        class="grp-and selected-grp"
                                        @click="
                                          toggle(
                                            'and',
                                            level_index,
                                            groups_index,
                                            group_index
                                          )
                                        "
                                      >
                                        AND
                                      </div>
                                      <div
                                        class="grp-or"
                                        @click="
                                          toggle(
                                            'or',
                                            level_index,
                                            groups_index,
                                            group_index
                                          )
                                        "
                                      >
                                        OR
                                      </div></span
                                    >
                                  </div>
                                </div>
                                <div class="right-3">
                                  <div
                                    v-for="(
                                      condition, condition_index
                                    ) in group.conditions"
                                    :key="condition_index"
                                    class="conditions"
                                  >
                                    <div
                                      v-if="group.conditions.length > 1"
                                      class="left-2"
                                    >
                                      <hr />
                                    </div>
                                    <div class="right-2">
                                      <div
                                        :id="'exp-container' + unique"
                                        class="exp-container"
                                      >
                                        <div class="exp-set">
                                          <div class="select-lhs">
                                            <hlx-select
                                              v-model="condition.lhs"
                                              :inline_search="true"
                                              :options="lhs_data"
                                              :placeholder_value="'LHS'"
                                              :clearable="true"
                                              @custom-change="selectValue"
                                              @searchvalue="addLHS"
                                            ></hlx-select>
                                          </div>
                                          <div class="select-op">
                                            <!-- <div class="equal">
          <div>{{ condition.op }}</div>
        </div> -->

                                            <hlx-select
                                              v-model="condition.op"
                                              :inline_search="true"
                                              :options="op_data"
                                              :placeholder_value="'Operator'"
                                              :clearable="true"
                                              @custom-change="selectValue"
                                              @searchvalue="addOP"
                                            ></hlx-select>
                                          </div>
                                          <div class="select-rhs">
                                            <hlx-code-editor
                                              v-model:value="condition.rhs"
                                              :intellisense-data="
                                                intellisense_data
                                              "
                                              :pre_value="condition.rhs"
                                              :line-number="false"
                                              :rows="1"
                                              @code-changes="selectValue"
                                            ></hlx-code-editor>
                                          </div>
                                          <div class="actions">
                                            <i
                                              class="icon-plus-circle-filled"
                                              @click="
                                                addCondition(
                                                  level_index,
                                                  groups_index,
                                                  group_index
                                                )
                                              "
                                            ></i>
                                            <i
                                              class="icon-trash-regular"
                                              @click="
                                                deleteCondition(
                                                  level_index,
                                                  groups_index,
                                                  group_index,
                                                  condition_index
                                                )
                                              "
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="level.groups.length > 1"
                        class="bottom-right-5"
                      >
                        <hlx-button
                          class="secondary sm"
                          @click="addInnerGroup(level_index, groups_index)"
                          ><i class="icon-plus-circle-regular"></i> Inner
                          group</hlx-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-1"></div>
        </div>
      </div>
    </div>
    <div v-show="reactive_level2.groups.length > 0" class="level2-container">
      <div
        v-if="border2 != 0 && borderr2 != 0"
        class="left-left"
        :style="`padding-top:${border2}px; padding-bottom:${borderr2}px;`"
      >
        <div class="border-2">
          <span id="level2" class="boxxxx"
            ><div
              class="grp-and selected-grp"
              @click="toggle('and', 2, groups_index, group_index)"
            >
              AND
            </div>
            <div
              class="grp-or"
              @click="toggle('or', 2, groups_index, group_index)"
            >
              OR
            </div></span
          >
        </div>
      </div>
      <div class="right-right">
        <div
          v-for="(groups, groups_index) in reactive_level2.groups"
          :key="groups_index"
          class="groups"
        >
          <div v-if="reactive_level2.groups.length > 1" class="left-5">
            <hr />
          </div>
          <div v-if="groups.group[0].conditions.length > 0" class="right-5">
            <div class="top-right-5">
              <div
                v-if="groups.group.length > 1"
                class="left-6"
                :style="`padding-top:${padding_top3[groups_index]}px; padding-bottom:${padding_bottom3[groups_index]}px; `"
              >
                <div class="left-6-inner">
                  <span class="boxxx level22"
                    ><div
                      class="grp-and selected-grp"
                      @click="toggle('and', 2, groups_index, group_index)"
                    >
                      AND
                    </div>
                    <div
                      class="grp-or"
                      @click="toggle('or', 2, groups_index, group_index)"
                    >
                      OR
                    </div></span
                  >
                </div>
              </div>
              <div class="right-6">
                <div
                  v-for="(group, group_index) in groups.group"
                  :key="group_index"
                  class="ggroup"
                >
                  <div v-if="groups.group.length > 1" class="left-7">
                    <hr />
                  </div>
                  <div class="right-7">
                    <div class="trial">
                      <div v-if="group.conditions.length > 1" class="left-3">
                        <div class="left-3-inner">
                          <span class="box"
                            ><div
                              class="grp-and selected-grp"
                              @click="
                                toggle('and', 2, groups_index, group_index)
                              "
                            >
                              AND
                            </div>
                            <div
                              class="grp-or"
                              @click="
                                toggle('or', 2, groups_index, group_index)
                              "
                            >
                              OR
                            </div></span
                          >
                        </div>
                      </div>
                      <div class="right-3">
                        <div
                          v-for="(
                            condition, condition_index
                          ) in group.conditions"
                          :key="condition_index"
                          class="conditions"
                        >
                          <div
                            v-if="group.conditions.length > 1"
                            class="left-2"
                          >
                            <hr />
                          </div>
                          <div class="right-2">
                            <div
                              :id="'exp-container' + unique"
                              class="exp-container"
                            >
                              <div class="exp-set">
                                <div class="select-lhs">
                                  <hlx-select
                                    v-model="condition.lhs"
                                    :inline_search="true"
                                    :options="lhs_data"
                                    :placeholder_value="'LHS'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addLHS"
                                  ></hlx-select>
                                </div>
                                <div class="select-op">
                                  <!-- <div class="equal">
          <div>{{ condition.op }}</div>
        </div> -->

                                  <hlx-select
                                    v-model="condition.op"
                                    :inline_search="true"
                                    :options="op_data"
                                    :placeholder_value="'Operator'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addOP"
                                  ></hlx-select>
                                </div>
                                <div class="select-rhs">
                                  <hlx-code-editor
                                    v-model:value="condition.rhs"
                                    :intellisense-data="intellisense_data"
                                    :line-number="false"
                                    :pre_value="condition.rhs"
                                    :rows="1"
                                    @code-changes="selectValue"
                                  ></hlx-code-editor>
                                </div>
                                <div class="actions">
                                  <i
                                    class="icon-plus-circle-filled"
                                    @click="
                                      addCondition(2, groups_index, group_index)
                                    "
                                  ></i>
                                  <i
                                    class="icon-trash-regular"
                                    @click="
                                      deleteCondition(
                                        2,
                                        groups_index,
                                        group_index,
                                        condition_index
                                      )
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-right-5">
              <hlx-button
                class="secondary sm"
                @click="addInnerGroup(2, groups_index)"
                ><i class="icon-plus-circle-regular"></i> Inner
                group</hlx-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="reactive_level3.groups.length > 0" class="level3-container">
      <div
        v-if="border3 != 0 && borderr3 != 0"
        class="left-left-left"
        :style="`padding-top:${border3}px; padding-bottom:${borderr3}px;`"
      >
        <div class="border-3">
          <span id="level3" class="boxxxx"
            ><div
              class="grp-and selected-grp"
              @click="toggle('and', 3, groups_index, group_index)"
            >
              AND
            </div>
            <div
              class="grp-or"
              @click="toggle('or', 3, groups_index, group_index)"
            >
              OR
            </div></span
          >
        </div>
      </div>

      <div class="right-right-right">
        <div
          v-for="(groups, groups_index) in reactive_level3.groups"
          :key="groups_index"
          class="groups"
        >
          <div v-if="reactive_level3.groups.length > 1" class="left-5">
            <hr />
          </div>
          <div v-if="groups.group[0].conditions.length > 0" class="right-5">
            <div class="top-right-5">
              <div
                v-if="groups.group.length > 1"
                class="left-6"
                :style="`padding-top:${padding_top4[groups_index]}px; padding-bottom:${padding_bottom4[groups_index]}px; `"
              >
                <div class="left-6-inner">
                  <span class="boxxx level33"
                    ><div
                      class="grp-and selected-grp"
                      @click="toggle('and', 3, groups_index, group_index)"
                    >
                      AND
                    </div>
                    <div
                      class="grp-or"
                      @click="toggle('or', 3, groups_index, group_index)"
                    >
                      OR
                    </div></span
                  >
                </div>
              </div>
              <div class="right-6">
                <div
                  v-for="(group, group_index) in groups.group"
                  :key="group_index"
                  class="ggroup"
                >
                  <div v-if="groups.group.length > 1" class="left-7">
                    <hr />
                  </div>
                  <div class="right-7">
                    <div class="trial">
                      <div v-if="group.conditions.length > 1" class="left-3">
                        <div class="left-3-inner">
                          <span class="box"
                            ><div
                              class="grp-and selected-grp"
                              @click="
                                toggle('and', 3, groups_index, group_index)
                              "
                            >
                              AND
                            </div>
                            <div
                              class="grp-or"
                              @click="
                                toggle('or', 3, groups_index, group_index)
                              "
                            >
                              OR
                            </div></span
                          >
                        </div>
                      </div>
                      <div class="right-3">
                        <div
                          v-for="(
                            condition, condition_index
                          ) in group.conditions"
                          :key="condition_index"
                          class="conditions"
                        >
                          <div
                            v-if="group.conditions.length > 1"
                            class="left-2"
                          >
                            <hr />
                          </div>
                          <div class="right-2">
                            <div
                              :id="'exp-container' + unique"
                              class="exp-container"
                            >
                              <div class="exp-set">
                                <div class="select-lhs">
                                  <hlx-select
                                    v-model="condition.lhs"
                                    :inline_search="true"
                                    :options="lhs_data"
                                    :placeholder_value="'LHS'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addLHS"
                                  ></hlx-select>
                                </div>
                                <div class="select-op">
                                  <!-- <div class="equal">
          <div>{{ condition.op }}</div>
        </div> -->

                                  <hlx-select
                                    v-model="condition.op"
                                    :inline_search="true"
                                    :options="op_data"
                                    :placeholder_value="'Operator'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addOP"
                                  ></hlx-select>
                                </div>
                                <div class="select-rhs">
                                  <hlx-code-editor
                                    v-model:value="condition.rhs"
                                    :intellisense-data="intellisense_data"
                                    :line-number="false"
                                    :pre_value="condition.rhs"
                                    :rows="1"
                                    @code-changes="selectValue"
                                  ></hlx-code-editor>
                                </div>
                                <div class="actions">
                                  <i
                                    class="icon-plus-circle-filled"
                                    @click="
                                      addCondition(
                                        3,
                                        groups_index,

                                        group_index
                                      )
                                    "
                                  ></i>
                                  <i
                                    class="icon-trash-regular"
                                    @click="
                                      deleteCondition(
                                        3,
                                        groups_index,

                                        group_index,
                                        condition_index
                                      )
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-right-5">
              <hlx-button
                class="secondary sm"
                @click="addInnerGroup(3, groups_index)"
                ><i class="icon-plus-circle-regular"></i> Inner
                group</hlx-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="reactive_level4.groups.length > 0" class="level4-container">
      <div
        v-if="border4 != 0 && borderr4 != 0"
        class="left-left-left-left"
        :style="`padding-top:${border4}px; padding-bottom:${borderr4}px;`"
      >
        <div class="border-4">
          <span id="level4" class="boxxxx"
            ><div
              class="grp-and selected-grp"
              @click="toggle('and', 4, groups_index, group_index)"
            >
              AND
            </div>
            <div
              class="grp-or"
              @click="toggle('or', 4, groups_index, group_index)"
            >
              OR
            </div></span
          >
        </div>
      </div>

      <div class="right-right-right-right">
        <div
          v-for="(groups, groups_index) in reactive_level4.groups"
          :key="groups_index"
          class="groups"
        >
          <div v-if="reactive_level4.groups.length > 1" class="left-5">
            <hr />
          </div>
          <div v-if="groups.group[0].conditions.length > 0" class="right-5">
            <div class="top-right-5">
              <div
                v-if="groups.group.length > 1"
                class="left-6"
                :style="`padding-top:${padding_top5[groups_index]}px; padding-bottom:${padding_bottom5[groups_index]}px; `"
              >
                <div class="left-6-inner">
                  <span class="boxxx level44"
                    ><div
                      class="grp-and selected-grp"
                      @click="toggle('and', 4, groups_index, group_index)"
                    >
                      AND
                    </div>
                    <div
                      class="grp-or"
                      @click="toggle('or', 4, groups_index, group_index)"
                    >
                      OR
                    </div></span
                  >
                </div>
              </div>
              <div class="right-6">
                <div
                  v-for="(group, group_index) in groups.group"
                  :key="group_index"
                  class="ggroup"
                >
                  <div v-if="groups.group.length > 1" class="left-7">
                    <hr />
                  </div>
                  <div class="right-7">
                    <div class="trial">
                      <div v-if="group.conditions.length > 1" class="left-3">
                        <div class="left-3-inner">
                          <span class="box"
                            ><div
                              class="grp-and selected-grp"
                              @click="
                                toggle('and', 4, groups_index, group_index)
                              "
                            >
                              AND
                            </div>
                            <div
                              class="grp-or"
                              @click="
                                toggle('or', 4, groups_index, group_index)
                              "
                            >
                              OR
                            </div></span
                          >
                        </div>
                      </div>
                      <div class="right-3">
                        <div
                          v-for="(
                            condition, condition_index
                          ) in group.conditions"
                          :key="condition_index"
                          class="conditions"
                        >
                          <div
                            v-if="group.conditions.length > 1"
                            class="left-2"
                          >
                            <hr />
                          </div>
                          <div class="right-2">
                            <div
                              :id="'exp-container' + unique"
                              class="exp-container"
                            >
                              <div class="exp-set">
                                <div class="select-lhs">
                                  <hlx-select
                                    v-model="condition.lhs"
                                    :inline_search="true"
                                    :options="lhs_data"
                                    :placeholder_value="'LHS'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addLHS"
                                  ></hlx-select>
                                </div>
                                <div class="select-op">
                                  <!-- <div class="equal">
          <div>{{ condition.op }}</div>
        </div> -->

                                  <hlx-select
                                    v-model="condition.op"
                                    :inline_search="true"
                                    :options="op_data"
                                    :placeholder_value="'Operator'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addOP"
                                  ></hlx-select>
                                </div>
                                <div class="select-rhs">
                                  <hlx-code-editor
                                    v-model:value="condition.rhs"
                                    :intellisense-data="intellisense_data"
                                    :line-number="false"
                                    :pre_value="condition.rhs"
                                    :rows="1"
                                    @code-changes="selectValue"
                                  ></hlx-code-editor>
                                </div>
                                <div class="actions">
                                  <i
                                    class="icon-plus-circle-filled"
                                    @click="
                                      addCondition(4, groups_index, group_index)
                                    "
                                  ></i>
                                  <i
                                    class="icon-trash-regular"
                                    @click="
                                      deleteCondition(
                                        4,
                                        groups_index,
                                        group_index,
                                        condition_index
                                      )
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-right-5">
              <hlx-button
                class="secondary sm"
                @click="addInnerGroup(4, groups_index)"
                ><i class="icon-plus-circle-regular"></i> Inner
                group</hlx-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="reactive_level5.groups.length > 0" class="level5-container">
      <div
        v-if="border5 != 0 && borderr5 != 0"
        class="left-left-left-left-left"
        :style="`padding-top:${border5}px; padding-bottom:${borderr5}px;`"
      >
        <div class="border-5">
          <span id="level5" class="boxxxx"
            ><div
              class="grp-and selected-grp"
              @click="toggle('and', 5, groups_index, group_index)"
            >
              AND
            </div>
            <div
              class="grp-or"
              @click="toggle('or', 5, groups_index, group_index)"
            >
              OR
            </div></span
          >
        </div>
      </div>

      <div class="right-right-right-right-right">
        <div
          v-for="(groups, groups_index) in reactive_level5.groups"
          :key="groups_index"
          class="groups"
        >
          <div v-if="reactive_level5.groups.length > 1" class="left-5">
            <hr />
          </div>
          <div v-if="groups.group[0].conditions.length > 0" class="right-5">
            <div class="top-right-5">
              <div
                v-if="groups.group.length > 1"
                class="left-6"
                :style="`padding-top:${padding_top6[groups_index]}px; padding-bottom:${padding_bottom6[groups_index]}px; `"
              >
                <div class="left-6-inner">
                  <span class="boxxx level55"
                    ><div
                      class="grp-and selected-grp"
                      @click="toggle('and', 5, groups_index, group_index)"
                    >
                      AND
                    </div>
                    <div
                      class="grp-or"
                      @click="toggle('or', 5, groups_index, group_index)"
                    >
                      OR
                    </div></span
                  >
                </div>
              </div>
              <div class="right-6">
                <div
                  v-for="(group, group_index) in groups.group"
                  :key="group_index"
                  class="ggroup"
                >
                  <div v-if="groups.group.length > 1" class="left-7">
                    <hr />
                  </div>
                  <div class="right-7">
                    <div class="trial">
                      <div v-if="group.conditions.length > 1" class="left-3">
                        <div class="left-3-inner">
                          <span class="box"
                            ><div
                              class="grp-and selected-grp"
                              @click="
                                toggle('and', 5, groups_index, group_index)
                              "
                            >
                              AND
                            </div>
                            <div
                              class="grp-or"
                              @click="
                                toggle('or', 5, groups_index, group_index)
                              "
                            >
                              OR
                            </div></span
                          >
                        </div>
                      </div>
                      <div class="right-3">
                        <div
                          v-for="(
                            condition, condition_index
                          ) in group.conditions"
                          :key="condition_index"
                          class="conditions"
                        >
                          <div
                            v-if="group.conditions.length > 1"
                            class="left-2"
                          >
                            <hr />
                          </div>
                          <div class="right-2">
                            <div
                              :id="'exp-container' + unique"
                              class="exp-container"
                            >
                              <div class="exp-set">
                                <div class="select-lhs">
                                  <hlx-select
                                    v-model="condition.lhs"
                                    :inline_search="true"
                                    :options="lhs_data"
                                    :placeholder_value="'LHS'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addLHS"
                                  ></hlx-select>
                                </div>
                                <div class="select-op">
                                  <!-- <div class="equal">
          <div>{{ condition.op }}</div>
        </div> -->

                                  <hlx-select
                                    v-model="condition.op"
                                    :inline_search="true"
                                    :options="op_data"
                                    :placeholder_value="'Operator'"
                                    :clearable="true"
                                    @custom-change="selectValue"
                                    @searchvalue="addOP"
                                  ></hlx-select>
                                </div>
                                <div class="select-rhs">
                                  <hlx-code-editor
                                    v-model:value="condition.rhs"
                                    :intellisense-data="intellisense_data"
                                    :line-number="false"
                                    :pre_value="condition.rhs"
                                    :rows="1"
                                    @code-changes="selectValue"
                                  ></hlx-code-editor>
                                </div>
                                <div class="actions">
                                  <i
                                    class="icon-plus-circle-filled"
                                    @click="
                                      addCondition(5, groups_index, group_index)
                                    "
                                  ></i>
                                  <i
                                    class="icon-trash-regular"
                                    @click="
                                      deleteCondition(
                                        5,
                                        groups_index,
                                        group_index,
                                        condition_index
                                      )
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-right-5">
              <hlx-button
                class="secondary sm"
                @click="addInnerGroup(5, groups_index)"
                ><i class="icon-plus-circle-regular"></i> Inner
                group</hlx-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <hlx-button
        class="primary sm"
        style="margin-left: auto"
        @click="addGroup()"
        ><i class="icon-plus-circle-regular"></i> Group</hlx-button
      >
    </div>
    <!-- <div @click="emit()">Click</div> -->
  </div>

  <div
    v-if="type.toLowerCase() == 'expression'"
    :id="'exp-container' + unique"
    class="exp-container-1"
  >
    <div class="exp-set">
      <div class="select-lhs">
        <hlx-select
          v-model="expression.lhs"
          :inline_search="true"
          :options="lhs_data"
          :placeholder_value="'LHS'"
          :clearable="true"
          @custom-change="selectValue"
          @searchvalue="addLHS"
        ></hlx-select>
      </div>
      <div class="select-op">
        <!-- <div class="equal">
          <div>{{ this.expression.op }}</div>
        </div> -->
        <hlx-select
          v-model="expression.op"
          :inline_search="true"
          :options="op_data"
          :placeholder_value="'Operator'"
          :clearable="true"
          @custom-change="selectValue"
          @searchvalue="addOP"
        ></hlx-select>
      </div>
      <div class="select-rhs">
        <hlx-code-editor
          v-model:value="expression.rhs"
          :intellisense-data="intellisense_data"
          :line-number="false"
          :pre_value="expression.rhs"
          :rows="1"
          @code-changes="selectValue"
        ></hlx-code-editor>

        <!-- 
<hlx-select 
@custom-change="selectValue"
          @searchvalue="addRHS"
          :inline_search="true"
          :options="this.rhs_data"
          v-model:value="this.expression.rhs"
          :prevalue="this.expression.rhs"
          :placeholder_value="'RHS'"
          :clearable="true"
         
        ></hlx-select>
 -->
      </div>
    </div>
  </div>
</template>

<script>
import HlxSelect from "./SelectComponent.vue";
import HlxButton from "../components/ButtonComponent.vue";
import HlxCodeEditor from "../components/CodeEditorComponent.vue";
// import { reactive } from "vue";

export default {
  name: "HlxVisualRuleBuilder",
  // components: { HlxSelect, HlxButton},
  components: { HlxSelect, HlxButton, HlxCodeEditor },
  props: {
    type: {
      type: String,
      default: "",
    },
    lhsData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rhsData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    opData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    myRule: {
      type: String,
      default: "",
    },
    myExp: {
      type: String,
      default: "",
    },
    ifObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    thenObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    elseObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    intellisenseData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["emitObj", "emitRule", "emitExp"],
  data() {
    return {
      unique: Math.floor(Math.random() * 1000 + 1),
      custom_obj: {},

      my_lhs_data: [],

      my_rule_obj: {},
      my_exp_obj: {},
      final_rule: "",
      final_exp: "",
      expression: {
        id: Math.floor(Math.random() * 1000 + 1),
        lhs: "",
        op: "=",
        rhs: "",
      },
      border2: 0,
      border3: 0,
      border4: 0,
      border5: 0,
      borderr2: 0,
      borderr3: 0,
      borderr4: 0,
      borderr5: 0,
      padding_top1: 0,
      padding_bottom1: 0,
      padding_top2: {},
      padding_bottom2: {},
      padding_top3: {},
      padding_bottom3: {},
      padding_top4: {},
      padding_bottom4: {},
      padding_top5: {},
      padding_bottom5: {},
      padding_top6: {},
      padding_bottom6: {},
      rule: {
        level1: {
          level_conjunction: "and",
          groups: [
            {
              group_conjunction: "and",
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: [
                    {
                      id: Math.floor(Math.random() * 1000 + 1),
                      lhs: "",
                      op: "",
                      rhs: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },

      level2: {
        level_conjunction: "and",
        groups: [],
      },
      level3: {
        level_conjunction: "and",
        groups: [],
      },
      level4: {
        level_conjunction: "and",
        groups: [],
      },
      level5: {
        level_conjunction: "and",
        groups: [],
      },
      reactive_rule: {
        level1: {
          level_conjunction: "and",
          groups: [
            {
              group_conjunction: "and",
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: [
                    {
                      id: Math.floor(Math.random() * 1000 + 1),
                      lhs: "",
                      op: "",
                      rhs: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },

      reactive_level2: {
        level_conjunction: "and",
        groups: [],
      },
      reactive_level3: {
        level_conjunction: "and",
        groups: [],
      },
      reactive_level4: {
        level_conjunction: "and",
        groups: [],
      },
      reactive_level5: {
        level_conjunction: "and",
        groups: [],
      },
    };
  },
  mounted() {
    if (this.type.toLowerCase() == "rule") {
      if (this.if_obj != undefined) {
        localStorage.setItem("if_obj", JSON.stringify(this.if_obj));

        if (JSON.parse(localStorage.getItem("if_obj")) != undefined) {
          this.reactive_rule = {
            level1: JSON.parse(localStorage.getItem("if_obj")).level1,
          };
          this.reactive_level2 = JSON.parse(
            localStorage.getItem("if_obj")
          ).level2;
          this.reactive_level3 = JSON.parse(
            localStorage.getItem("if_obj")
          ).level3;
          this.reactive_level4 = JSON.parse(
            localStorage.getItem("if_obj")
          ).level4;
          this.reactive_level5 = JSON.parse(
            localStorage.getItem("if_obj")
          ).level5;

          this.test();
          setTimeout(() => {
            this.reactive_rule = {
              level1: JSON.parse(localStorage.getItem("if_obj")).level1,
            };
            this.reactive_level2 = JSON.parse(
              localStorage.getItem("if_obj")
            ).level2;
            this.reactive_level3 = JSON.parse(
              localStorage.getItem("if_obj")
            ).level3;
            this.reactive_level4 = JSON.parse(
              localStorage.getItem("if_obj")
            ).level4;
            this.reactive_level5 = JSON.parse(
              localStorage.getItem("if_obj")
            ).level5;
          }, 300);
        }
      }
      if (this.then_obj != undefined) {
        localStorage.setItem("then_obj", JSON.stringify(this.then_obj));

        if (JSON.parse(localStorage.getItem("then_obj")) != undefined) {
          this.reactive_rule = {
            level1: JSON.parse(localStorage.getItem("then_obj")).level1,
          };
          this.reactive_level2 = JSON.parse(
            localStorage.getItem("then_obj")
          ).level2;
          this.reactive_level3 = JSON.parse(
            localStorage.getItem("then_obj")
          ).level3;
          this.reactive_level4 = JSON.parse(
            localStorage.getItem("then_obj")
          ).level4;
          this.reactive_level5 = JSON.parse(
            localStorage.getItem("then_obj")
          ).level5;

          this.test();
          setTimeout(() => {
            this.reactive_rule = {
              level1: JSON.parse(localStorage.getItem("then_obj")).level1,
            };
            this.reactive_level2 = JSON.parse(
              localStorage.getItem("then_obj")
            ).level2;
            this.reactive_level3 = JSON.parse(
              localStorage.getItem("then_obj")
            ).level3;
            this.reactive_level4 = JSON.parse(
              localStorage.getItem("then_obj")
            ).level4;
            this.reactive_level5 = JSON.parse(
              localStorage.getItem("then_obj")
            ).level5;
          }, 300);
        }
      }
      if (this.else_obj != undefined) {
        localStorage.setItem("else_obj", JSON.stringify(this.else_obj));

        if (JSON.parse(localStorage.getItem("else_obj")) != undefined) {
          this.reactive_rule = {
            level1: JSON.parse(localStorage.getItem("else_obj")).level1,
          };
          this.reactive_level2 = JSON.parse(
            localStorage.getItem("else_obj")
          ).level2;
          this.reactive_level3 = JSON.parse(
            localStorage.getItem("else_obj")
          ).level3;
          this.reactive_level4 = JSON.parse(
            localStorage.getItem("else_obj")
          ).level4;
          this.reactive_level5 = JSON.parse(
            localStorage.getItem("else_obj")
          ).level5;

          this.test();
          setTimeout(() => {
            this.reactive_rule = {
              level1: JSON.parse(localStorage.getItem("else_obj")).level1,
            };
            this.reactive_level2 = JSON.parse(
              localStorage.getItem("else_obj")
            ).level2;
            this.reactive_level3 = JSON.parse(
              localStorage.getItem("else_obj")
            ).level3;
            this.reactive_level4 = JSON.parse(
              localStorage.getItem("else_obj")
            ).level4;
            this.reactive_level5 = JSON.parse(
              localStorage.getItem("else_obj")
            ).level5;
          }, 300);
        }
      }

      if (this.my_rule != "") {
        this.emitObjForRule();
      }
      setInterval(() => {
        this.emit();
        this.emitObj();
        //     if( JSON.parse(localStorage.getItem('if_obj')) != undefined){
        //       this.custom_obj = {level1:  this.reactive_rule.level1,
        //       level2:  this.reactive_level2,
        //       level3:  this.reactive_level3,
        //       level4:  this.reactive_level4,
        //       level5:  this.reactive_level5}
        // }
      }, 3000);
    } else {
      if (this.my_exp != "") {
        this.emitObjForExp();
      }
      setInterval(() => {
        this.emitExp();
      }, 3000);
    }
  },
  methods: {
    and_or() {
      if (
        JSON.parse(localStorage.getItem("if_obj")).level1[
          "level_conjunction"
        ] == "or"
      ) {
        if (
          document
            .querySelector("#level1")
            .children[0].classList.contains("selected-grp")
        ) {
          document
            .querySelector("#level1")
            .children[0].classList.remove("selected-grp");
        }
        document
          .querySelector("#level1")
          .children[1].classList.add("selected-grp");
      } else if (
        JSON.parse(localStorage.getItem("if_obj")).level1[
          "level_conjunction"
        ] == "and"
      ) {
        if (
          document
            .querySelector("#level1")
            .children[1].classList.contains("selected-grp")
        ) {
          document
            .querySelector("#level1")
            .children[1].classList.remove("selected-grp");
        }
        document
          .querySelector("#level1")
          .children[0].classList.add("selected-grp");
      }
    },
    removeTrailingOperators(expression) {
      const operatorsRegex = /(and|or)\s*$/i;
      let result = expression.trim().replace(operatorsRegex, "");
      while (operatorsRegex.test(result)) {
        result = result.replace(operatorsRegex, "");
      }
      return result.trim();
    },
    addLHS(value) {
      // console.log(value);
      if (value != "") {
        let obj = {
          name: value,
          value: value,
          checked: true,
          disabled: false,
          icon: "icon-database-regular",
        };
        this.lhs_data.push(obj);
      }
      // console.log(this.lhs_data);
    },
    addOP(value) {
      let obj = {
        name: value,
        value: value,
        checked: true,
        disabled: false,
        icon: "icon-database-regular",
      };
      this.op_data.push(obj);
    },
    addRHS(value) {
      let obj = {
        name: value,
        value: value,
        checked: true,
        disabled: false,
        icon: "icon-database-regular",
      };
      this.rhs_data.push(obj);
    },
    addCondition(level, group, inner_group) {
      // this.padding_top2 = 0
      let condition = {
        id: Math.floor(Math.random() * 1000 + 1),
        lhs: "",
        op: "",
        rhs: "",
      };
      if (level == "level1") {
        this.reactive_rule[level]["groups"][group]["group"][inner_group][
          "conditions"
        ].push(condition);
        setTimeout(() => {
          this.padding_top1 =
            document.querySelectorAll(".top-right-5")[0].offsetHeight / 2 + 8;
          this.padding_bottom1 =
            document.querySelector(".right-1").children[1].children[
              document.querySelector(".right-1").children[1].children.length - 1
            ].offsetHeight - 38;
          this.padding_top2[group] =
            document.querySelectorAll(".right-6")[group].children[0]
              .offsetHeight / 2;
          this.padding_bottom2[group] =
            document.querySelectorAll(".right-6")[group].children[
              document.querySelectorAll(".right-6")[group].children.length - 1
            ].offsetHeight / 2;
        }, 100);
      } else if (level == 2) {
        this.reactive_level2["groups"][group]["group"][inner_group][
          "conditions"
        ].push(condition);
        setTimeout(() => {
          this.padding_top3[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom3[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border2 =
            document.querySelector(".right-right").children[0].offsetHeight / 2;
          this.borderr2 =
            document.querySelector(".right-right").children[1].children[0]
              .children[0].offsetHeight /
              2 +
            48;
        }, 100);
      } else if (level == 3) {
        this.reactive_level3["groups"][group]["group"][inner_group][
          "conditions"
        ].push(condition);
        setTimeout(() => {
          this.padding_top4[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom4[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border3 =
            document.querySelector(".right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr3 =
            document.querySelector(".right-right-right").children[1].children[0]
              .children[0].offsetHeight /
              2 +
            48;
        }, 100);
      } else if (level == 4) {
        this.reactive_level4["groups"][group]["group"][inner_group][
          "conditions"
        ].push(condition);
        setTimeout(() => {
          this.padding_top5[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom5[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border4 =
            document.querySelector(".right-right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr4 =
            document.querySelector(".right-right-right-right").children[1]
              .children[0].children[0].offsetHeight /
              2 +
            48;
        }, 100);
      } else if (level == 5) {
        this.reactive_level5["groups"][group]["group"][inner_group][
          "conditions"
        ].push(condition);
        setTimeout(() => {
          this.padding_top6[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom6[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border5 =
            document.querySelector(".right-right-right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr5 =
            document.querySelector(".right-right-right-right-right").children[1]
              .children[0].children[0].offsetHeight /
              2 +
            48;
        }, 100);
      }
      this.emit();
    },
    addGroup() {
      // this.padding_top = 0
      // this.padding_bottom = 0

      let group = {
        group_conjunction: "and",
        group: [
          {
            inner_group_conjunction: "and",
            conditions: [
              {
                id: Math.floor(Math.random() * 1000 + 1),
                lhs: "",
                op: "",
                rhs: "",
              },
            ],
          },
        ],
      };
      if (this.reactive_rule.level1.groups.length < 2) {
        this.reactive_rule["level1"]["groups"].push(group);
        setTimeout(() => {
          // console.log("pi",document.querySelectorAll('.top-right-5')[0].offsetHeight);
          this.padding_top1 =
            document.querySelectorAll(".top-right-5")[0].offsetHeight / 2 + 8;
          this.padding_bottom1 =
            document.querySelector(".right-1").children[1].children[
              document.querySelector(".right-1").children[1].children.length - 1
            ].offsetHeight - 38;
          this.padding_top2[0] =
            document.querySelectorAll(".right-6")[0].children[0].offsetHeight /
            2;
          this.padding_bottom2[0] =
            document.querySelectorAll(".right-6")[0].children[
              document.querySelectorAll(".right-6")[0].children.length - 1
            ].offsetHeight / 2;
        }, 100);
      } else if (this.reactive_level2.groups.length == 0) {
        let level2 = document.querySelector(".top");
        document
          .querySelector(".right-right")
          .insertBefore(
            level2,
            document.querySelector(".right-right").firstChild
          );
        this.reactive_level2.groups.push(group);
        setTimeout(() => {
          this.border2 =
            document.querySelector(".right-right").children[0].offsetHeight / 2;
          this.borderr2 =
            document.querySelector(".right-right").children[1].children[0]
              .children[0].offsetHeight /
              2 +
            50;
        }, 100);
      } else if (this.reactive_level3.groups.length == 0) {
        let level3 = document.querySelector(".level2-container");
        document
          .querySelector(".right-right-right")
          .insertBefore(
            level3,
            document.querySelector(".right-right-right").firstChild
          );
        this.reactive_level3.groups.push(group);
        setTimeout(() => {
          this.border3 =
            document.querySelector(".right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr3 =
            document.querySelector(".right-right-right").children[1].children[0]
              .children[0].offsetHeight /
              2 +
            50;
        }, 100);
      } else if (this.reactive_level4.groups.length == 0) {
        let level4 = document.querySelector(".level3-container");
        document
          .querySelector(".right-right-right-right")
          .insertBefore(
            level4,
            document.querySelector(".right-right-right-right").firstChild
          );
        this.reactive_level4.groups.push(group);
        setTimeout(() => {
          this.border4 =
            document.querySelector(".right-right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr4 =
            document.querySelector(".right-right-right-right").children[1]
              .children[0].children[0].offsetHeight /
              2 +
            50;
        }, 100);
      } else if (this.reactive_level5.groups.length == 0) {
        let level5 = document.querySelector(".level4-container");
        document
          .querySelector(".right-right-right-right-right")
          .insertBefore(
            level5,
            document.querySelector(".right-right-right-right-right").firstChild
          );
        this.reactive_level5.groups.push(group);
        setTimeout(() => {
          this.border5 =
            document.querySelector(".right-right-right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr5 =
            document.querySelector(".right-right-right-right-right").children[1]
              .children[0].children[0].offsetHeight /
              2 +
            50;
        }, 100);
      }

      // if (this.reactive_rule[level]["groups"].length > 2 && this.padding_bottom != 66) {
      //   console.log("ooo");
      //   this.padding_bottom += 140;
      // } else if (
      //   this.reactive_rule[level]["groups"].length == 2 &&
      //   this.reactive_rule[level]["groups"][0]["group"][0]["conditions"].length == 2
      // ) {
      //   this.padding_top += 130;
      // }

      // console.log();
      this.emit();
    },
    addInnerGroup(level, group) {
      let inner_group = {
        inner_group_conjunction: "and",
        conditions: [
          {
            id: Math.floor(Math.random() * 1000 + 1),
            lhs: "",
            op: "",
            rhs: "",
          },
        ],
      };
      if (level == "level1") {
        this.reactive_rule[level]["groups"][group]["group"].push(inner_group);
        setTimeout(() => {
          this.padding_top1 =
            document.querySelectorAll(".top-right-5")[0].offsetHeight / 2 + 8;

          // this.padding_bottom1 =
          //   (document.querySelectorAll(".top-right-5")[
          //     document.querySelectorAll(".top-right-5").length - 1
          //   ].offsetHeight /
          //     2 )

          this.padding_bottom1 =
            (document.querySelectorAll(".right-5")[1].offsetHeight + 61) / 2;

          console.log(document.querySelectorAll(".right-5")[1].offsetHeight);
          this.padding_top2[group] =
            document.querySelectorAll(".right-6")[group].children[0]
              .offsetHeight / 2;
          this.padding_bottom2[group] =
            document.querySelectorAll(".right-6")[group].children[
              document.querySelectorAll(".right-6")[group].children.length - 1
            ].offsetHeight / 2;
        }, 100);
      } else if (level == 2) {
        this.reactive_level2["groups"][0]["group"].push(inner_group);
        setTimeout(() => {
          this.padding_top1 =
            document.querySelectorAll(".top-right-5")[0].offsetHeight / 2 + 8;
          // this.padding_bottom1 =
          //   document.querySelector(".right-1").children[1].children[
          //     document.querySelector(".right-1").children[1].children.length - 1
          //   ].offsetHeight - 38;

          this.padding_top3[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom3[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border2 =
            document.querySelector(".right-right").children[0].offsetHeight / 2;
          this.borderr2 =
            document.querySelector(".right-right").children[1].children[0]
              .children[0].offsetHeight /
              2 +
            48;
        }, 100);
      } else if (level == 3) {
        this.reactive_level3["groups"][0]["group"].push(inner_group);
        setTimeout(() => {
          console.log(
            document.querySelectorAll(".top-right-5")[3].offsetHeight
          );
          this.padding_top4[group] = 30;
          this.padding_bottom4[group] = 30;
          // this.padding_top4[group] =
          // (document.querySelectorAll(".top-right-5")[3]
          //     .offsetHeight + 61)/ 2;
          //     console.log((document.querySelectorAll(".top-right-5")[3]
          //     .offsetHeight + 61)/ 2)
          // this.padding_bottom4[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[0].children[
          //     document.querySelectorAll(".top-right-5")[2].children[0].children
          //       .length - 1
          //   ].offsetHeight / 2;

          this.border3 =
            document.querySelector(".right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr3 =
            document.querySelector(".right-right-right").children[1].children[0]
              .children[0].offsetHeight /
              2 +
            48;
        }, 100);
      } else if (level == 4) {
        this.reactive_level4["groups"][0]["group"].push(inner_group);
        setTimeout(() => {
          this.padding_top5[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom5[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border4 =
            document.querySelector(".right-right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr4 =
            document.querySelector(".right-right-right-right").children[1]
              .children[0].children[0].offsetHeight /
              2 +
            48;
        }, 100);
      } else if (level == 5) {
        this.reactive_level5["groups"][0]["group"].push(inner_group);
        setTimeout(() => {
          this.padding_top6[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom6[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;

          this.border5 =
            document.querySelector(".right-right-right-right-right").children[0]
              .offsetHeight / 2;
          this.borderr5 =
            document.querySelector(".right-right-right-right-right").children[1]
              .children[0].children[0].offsetHeight /
              2 +
            48;
        }, 100);
      }
      this.emit();
    },
    deleteCondition(level, group, inner_group, condition_index) {
      if (level == "level1") {
        this.reactive_rule.level1.groups[group]["group"][inner_group][
          "conditions"
        ].splice(condition_index, 1);
        if (
          this.reactive_rule.level1.groups[group]["group"][inner_group][
            "conditions"
          ] == 0
        ) {
          // this.reactive_rule.level1.groups.splice(group,1)
          this.reactive_rule.level1.groups[group]["group"].splice(
            inner_group,
            1
          );
          // console.log("ll", this.reactive_rule.level1.groups);
        }
        if (this.reactive_rule.level1.groups[group]["group"].length == 0) {
          this.reactive_rule.level1.groups.splice(group, 1);
        }
      } else if (level == 2) {
        if (
          this.reactive_level2.groups[group]["group"][inner_group]["conditions"]
            .length == 1 &&
          this.reactive_level3.groups.length == 0
        ) {
          this.reactive_level2.groups[group]["group"][inner_group][
            "conditions"
          ].splice(condition_index, 1);
          if (
            this.reactive_level2.groups[group]["group"][inner_group][
              "conditions"
            ].length == 0
          ) {
            this.reactive_level2.groups[group]["group"].splice(inner_group, 1);
          }
          if (this.reactive_level2.groups[0]["group"].length == 0) {
            document
              .querySelector(".parent-container")
              .appendChild(document.querySelector(".top"));

            this.reactive_level2.groups = [];
          }
          setTimeout(() => {
            // console.log(
            //   "ki",
            //   document.querySelector(".right-1").children[1].children[
            //     document.querySelector(".right-1").children[1].children.length -
            //       1
            //   ].offsetHeight
            // );
            this.padding_top1 =
              document.querySelectorAll(".top-right-5")[0].offsetHeight / 2 + 8;
            this.padding_bottom1 =
              document.querySelector(".right-1").children[1].children[
                document.querySelector(".right-1").children[1].children.length -
                  1
              ].offsetHeight - 38;

            this.padding_top3[group] =
              document.querySelectorAll(".top-right-5")[2].children[1]
                .children[0].offsetHeight / 2;
            this.padding_bottom3[group] =
              document.querySelectorAll(".top-right-5")[2].children[1].children[
                document.querySelectorAll(".top-right-5")[2].children[1]
                  .children.length - 1
              ].offsetHeight / 2;

            this.border2 =
              document.querySelector(".right-right").children[0].offsetHeight /
              2;
            this.borderr2 =
              document.querySelector(".right-right").children[1].children[0]
                .children[0].offsetHeight /
                2 +
              48;
          }, 100);
        } else {
          // console.log("hi");
          // if(this.reactive_level2.groups[0]["group"].length == 1){
          //   document.querySelector('.level3-container').appendChild(document.querySelector('.level2-container'))
          //   this.reactive_level2.groups = []
          //   this.reactive_level2.groups = this.reactive_level3.groups
          //   this.reactive_level3.groups = []
          // }
        }
      } else if (level == 3) {
        if (
          this.reactive_level3.groups[group]["group"][inner_group]["conditions"]
            .length == 1 &&
          this.reactive_level4.groups.length == 0
        ) {
          this.reactive_level3.groups[group]["group"][inner_group][
            "conditions"
          ].splice(condition_index, 1);
          if (
            this.reactive_level3.groups[group]["group"][inner_group][
              "conditions"
            ].length == 0
          ) {
            this.reactive_level3.groups[group]["group"].splice(inner_group, 1);
          }
          if (this.reactive_level3.groups[0]["group"].length == 0) {
            document
              .querySelector(".parent-container")
              .appendChild(document.querySelector(".level2-container"));

            this.reactive_level3.groups = [];
          }
          setTimeout(() => {
            this.padding_top4[group] =
              document.querySelectorAll(".top-right-5")[2].children[1]
                .children[0].offsetHeight / 2;
            this.padding_bottom4[group] =
              document.querySelectorAll(".top-right-5")[2].children[1].children[
                document.querySelectorAll(".top-right-5")[2].children[1]
                  .children.length - 1
              ].offsetHeight / 2;

            this.border3 =
              document.querySelector(".right-right-right").children[0]
                .offsetHeight / 2;
            this.borderr3 =
              document.querySelector(".right-right-right").children[1]
                .children[0].children[0].offsetHeight /
                2 +
              48;
          }, 100);
        }
      } else if (level == 4) {
        if (
          this.reactive_level4.groups[group]["group"][inner_group]["conditions"]
            .length == 1 &&
          this.reactive_level5.groups.length == 0
        ) {
          this.reactive_level4.groups[group]["group"][inner_group][
            "conditions"
          ].splice(condition_index, 1);
          if (
            this.reactive_level4.groups[group]["group"][inner_group][
              "conditions"
            ].length == 0
          ) {
            this.reactive_level4.groups[group]["group"].splice(inner_group, 1);
          }
          if (this.reactive_level4.groups[0]["group"].length == 0) {
            document
              .querySelector(".parent-container")
              .appendChild(document.querySelector(".level3-container"));

            this.reactive_level4.groups = [];
          }
          setTimeout(() => {
            this.padding_top5[group] =
              document.querySelectorAll(".top-right-5")[2].children[1]
                .children[0].offsetHeight / 2;
            this.padding_bottom5[group] =
              document.querySelectorAll(".top-right-5")[2].children[1].children[
                document.querySelectorAll(".top-right-5")[2].children[1]
                  .children.length - 1
              ].offsetHeight / 2;

            this.border4 =
              document.querySelector(".right-right-right-right").children[0]
                .offsetHeight / 2;
            this.borderr4 =
              document.querySelector(".right-right-right-right").children[1]
                .children[0].children[0].offsetHeight /
                2 +
              48;
          }, 100);
        }
      } else if (level == 5) {
        this.reactive_level5.groups[group]["group"][inner_group][
          "conditions"
        ].splice(condition_index, 1);
        if (
          this.reactive_level5.groups[group]["group"][inner_group]["conditions"]
            .length == 0
        ) {
          this.reactive_level5.groups[group]["group"].splice(inner_group, 1);
        }
        if (this.reactive_level5.groups[0]["group"].length == 0) {
          document
            .querySelector(".parent-container")
            .appendChild(document.querySelector(".level4-container"));

          this.reactive_level5.groups = [];
        }
        // this.reactive_level5["groups"][0]["group"].push(inner_group);
        setTimeout(() => {
          this.padding_top6[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[0]
              .offsetHeight / 2;
          this.padding_bottom6[group] =
            document.querySelectorAll(".top-right-5")[2].children[1].children[
              document.querySelectorAll(".top-right-5")[2].children[1].children
                .length - 1
            ].offsetHeight / 2;
          if (this.reactive_level5.groups.length != 0) {
            this.border5 =
              document.querySelector(".right-right-right-right-right")
                .children[0].offsetHeight / 2;
            this.borderr5 =
              document.querySelector(".right-right-right-right-right")
                .children[1].children[0].children[0].offsetHeight /
                2 +
              48;
          }
        }, 100);
      }
      this.emit();
    },
    toggle(op, level_index, groups_index, group_index) {
      console.log(op, level_index, groups_index, group_index);
      if (op == "or") {
        if (event != undefined) {
          if (
            event.target.previousElementSibling.classList.contains(
              "selected-grp"
            )
          ) {
            event.target.previousElementSibling.classList.remove(
              "selected-grp"
            );
          }
          event.target.classList.add("selected-grp");
        }
      } else if (op == "and") {
        if (
          event.target.nextElementSibling.classList.contains("selected-grp")
        ) {
          event.target.nextElementSibling.classList.remove("selected-grp");
        }
        event.target.classList.add("selected-grp");
      }

      if (
        level_index == "level1" &&
        groups_index != undefined &&
        group_index != undefined
      ) {
        this.reactive_rule.level1.groups[groups_index]["group"][group_index][
          "inner_group_conjunction"
        ] = op;
      }
      if (
        level_index == "level1" &&
        groups_index != undefined &&
        group_index == undefined
      ) {
        this.reactive_rule.level1.groups[groups_index]["group_conjunction"] =
          op;
      }
      if (
        level_index == "level1" &&
        groups_index == undefined &&
        group_index == undefined
      ) {
        this.reactive_rule.level1["level_conjunction"] = op;
      }

      if (
        level_index == 2 &&
        groups_index != undefined &&
        group_index != undefined
      ) {
        this.reactive_level2.groups[groups_index]["group"][group_index][
          "inner_group_conjunction"
        ] = op;
      }
      if (
        level_index == 2 &&
        groups_index != undefined &&
        group_index == undefined
      ) {
        this.reactive_level2.groups[groups_index]["group_conjunction"] = op;
      }
      if (
        level_index == 2 &&
        groups_index == undefined &&
        group_index == undefined
      ) {
        this.reactive_level2["level_conjunction"] = op;
      }

      if (
        level_index == 3 &&
        groups_index != undefined &&
        group_index != undefined
      ) {
        this.reactive_level3.groups[groups_index]["group"][group_index][
          "inner_group_conjunction"
        ] = op;
      }
      if (
        level_index == 3 &&
        groups_index != undefined &&
        group_index == undefined
      ) {
        this.reactive_level3.groups[groups_index]["group_conjunction"] = op;
      }
      if (
        level_index == 3 &&
        groups_index == undefined &&
        group_index == undefined
      ) {
        this.reactive_level3["level_conjunction"] = op;
      }

      if (
        level_index == 4 &&
        groups_index != undefined &&
        group_index != undefined
      ) {
        this.reactive_level4.groups[groups_index]["group"][group_index][
          "inner_group_conjunction"
        ] = op;
      }
      if (
        level_index == 4 &&
        groups_index != undefined &&
        group_index == undefined
      ) {
        this.reactive_level4.groups[groups_index]["group_conjunction"] = op;
      }
      if (
        level_index == 4 &&
        groups_index == undefined &&
        group_index == undefined
      ) {
        this.reactive_level4["level_conjunction"] = op;
      }

      if (
        level_index == 5 &&
        groups_index != undefined &&
        group_index != undefined
      ) {
        this.reactive_level5.groups[groups_index]["group"][group_index][
          "inner_group_conjunction"
        ] = op;
      }
      if (
        level_index == 5 &&
        groups_index != undefined &&
        group_index == undefined
      ) {
        this.reactive_level5.groups[groups_index]["group_conjunction"] = op;
      }
      if (
        level_index == 5 &&
        groups_index == undefined &&
        group_index == undefined
      ) {
        this.reactive_level5["level_conjunction"] = op;
      }

      // console.log(this.reactive_level3);
      this.emit();
    },
    selectValue() {
      if (this.type == "rule") {
        this.emit();
      } else {
        this.emitExp();
      }
    },
    emit() {
      this.reactive_rule.level1.level_conjunction =
        this.reactive_rule.level1.groups[0].group_conjunction;
      let obj = {
        level1: this.reactive_rule.level1,
        level2: this.reactive_level2,
        level3: this.reactive_level3,
        level4: this.reactive_level4,
        level5: this.reactive_level5,
      };
      function convertToExpression(obj) {
        let expression = "";
        let levels = ["level1", "level2", "level3", "level4", "level5"];
        for (let i = 0; i < levels.length; i++) {
          let level = levels[i];
          let levelObj = obj[level];
          if (!levelObj) continue;
          // expression += "(";
          for (let j = 0; j < levelObj.groups.length; j++) {
            let group = levelObj.groups[j];
            expression += "(";
            for (let k = 0; k < group.group.length; k++) {
              let conditions = group.group[k].conditions;
              for (let l = 0; l < conditions.length; l++) {
                let condition = conditions[l];
                expression += "(";
                expression += `${condition.lhs} ${condition.op} ${condition.rhs}`;
                expression += ")";
                if (l < conditions.length - 1) {
                  expression += ` ${group.group[k].inner_group_conjunction} `;
                }
              }
              if (k < group.group.length - 1) {
                expression += ` ${group.group_conjunction} `;
              }
            }
            expression += ")";
            if (j < levelObj.groups.length - 1) {
              expression += ` ${group.group_conjunction} `;
            }
          }
          // expression += ")";
          if (i < levels.length - 1 && levelObj.level_conjunction) {
            expression += ` ${levelObj.level_conjunction} `;
          }
        }
        return expression;
      }
      this.final_rule = convertToExpression(obj);
      // console.log(this.removeTrailingOperators(this.final_rule).replace(/\((\([^()]+\))\)/g, '$1'),"pppp")
      this.$emit(
        "emitRule",
        this.removeTrailingOperators(this.final_rule).replace(
          /\((\([^()]+\))\)/g,
          "$1"
        )
      );
    },

    emitExp() {
      this.final_exp =
        this.expression.lhs +
        " " +
        this.expression.op +
        " " +
        this.expression.rhs;
      console.log(this.final_exp, this.expression.lhs, "pp");
      this.$emit("emitExp", this.final_exp);
    },
    emitObjForRule() {
      function convertExpressionToJSON(expression) {
        const levels = expression.split(/\)\sand\s\(/);
        const json = {};
        let i = 1;

        levels.forEach((level) => {
          const groupMatches = level.match(/\((.*?)\)/g);
          const levelObj = {
            level_conjunction: "and",
            groups: [],
          };
          groupMatches.forEach((group) => {
            let groupConjunction = group.match(/and|or|ze/);
            if (!groupConjunction) {
              // Default conjunction if none is found
              groupConjunction = "and";
            } else {
              groupConjunction = groupConjunction[0];
            }
            const conditions = group
              .replace(/(\(|\))/g, "")
              .split(/\sand\s|\sor\s|\sze\s/)
              .map((condition) => {
                const [lhs, op, rhs] = condition.split(/\s/);
                return {
                  id: Math.floor(Math.random() * 1000),
                  lhs,
                  op,
                  rhs: Number(rhs),
                };
              });

            levelObj.groups.push({
              group_conjunction: groupConjunction,
              group: [
                {
                  inner_group_conjunction: "and",
                  conditions: conditions,
                },
              ],
            });
          });
          json[`level${i}`] = levelObj;
          i++;
        });

        return json;
      }

      // example usage
      let expression = this.my_rule;
      let json = convertExpressionToJSON(expression);
      if (json.level1 != undefined) {
        this.reactive_rule.level1 = json.level1;
      }
      if (json.level2 != undefined) {
        this.reactive_level2 = json.level2;
      }
      if (json.level3 != undefined) {
        this.reactive_level3 = json.level3;
      }
      if (json.level4 != undefined) {
        this.reactive_level4 = json.level4;
      }
      if (json.level5 != undefined) {
        this.reactive_level5 = json.level5;
      }
    },
    emitObj() {
      let obj = {
        level1: this.reactive_rule.level1,
        level2: this.reactive_level2,
        level3: this.reactive_level3,
        level4: this.reactive_level4,
        level5: this.reactive_level5,
      };
      this.$emit("emitObj", obj);
    },
    emitObjForExp() {
      this.my_exp.split("=");
      this.expression.lhs = this.my_exp.split("=")[0].trim();
      this.expression.op = " = ";
      this.expression.rhs = this.my_exp.split("=")[1].trim();
    },

    test() {
      if (
        document.querySelector(".top") != undefined &&
        document.querySelector(".top") != null &&
        this.reactive_level2.groups.length > 0
      ) {
        let level2 = document.querySelector(".top");
        document
          .querySelector(".right-right")
          .insertBefore(
            level2,
            document.querySelector(".right-right").firstChild
          );

        setTimeout(() => {
          if (document.querySelector(".right-right") != undefined) {
            if (
              document.querySelector(".right-right").children[0] != undefined
            ) {
              this.border2 =
                document.querySelector(".right-right").children[0]
                  .offsetHeight / 2;
              if (
                document.querySelector(".right-right").children[1] !=
                  undefined &&
                document.querySelector(".right-right").children[1]
                  .children[0] != undefined &&
                document.querySelector(".right-right").children[1].children[0]
                  .children[0] != undefined
              ) {
                this.borderr2 =
                  document.querySelector(".right-right").children[1].children[0]
                    .children[0].offsetHeight /
                    2 +
                  50;
              }
            }
          }
        }, 100);
      }
      if (
        document.querySelector(".right-right-right") != undefined &&
        document.querySelector(".right-right-right") != null &&
        this.reactive_level3.groups.length > 0
      ) {
        let level3 = document.querySelector(".level2-container");
        document
          .querySelector(".right-right-right")
          .insertBefore(
            level3,
            document.querySelector(".right-right-right").firstChild
          );
        setTimeout(() => {
          if (
            document.querySelector(".right-right-right").children[0] !=
            undefined
          ) {
            this.border3 =
              document.querySelector(".right-right-right").children[0]
                .offsetHeight / 2;
            if (
              document.querySelector(".right-right-right").children[1] !=
              undefined
            ) {
              this.borderr3 =
                document.querySelector(".right-right-right").children[1]
                  .children[0].children[0].offsetHeight /
                  2 +
                50;
            }
          }
        }, 100);
      }

      if (
        document.querySelector(".right-right-right-right") != undefined &&
        document.querySelector(".right-right-right-right") != null &&
        this.reactive_level4.groups.length > 0
      ) {
        let level4 = document.querySelector(".level3-container");
        document
          .querySelector(".right-right-right-right")
          .insertBefore(
            level4,
            document.querySelector(".right-right-right-right").firstChild
          );
        setTimeout(() => {
          // console.log(document.querySelector(".right-right-right-right").children[1],"looo");
          if (
            document.querySelector(".right-right-right-right").children[0] !=
            undefined
          ) {
            this.border4 =
              document.querySelector(".right-right-right-right").children[0]
                .offsetHeight / 2;
            if (
              document.querySelector(".right-right-right-right").children[1] !=
                undefined &&
              document.querySelector(".right-right-right-right").children[1]
                .children[0] != undefined &&
              document.querySelector(".right-right-right-right").children[1]
                .children[0].children[0] != undefined
            ) {
              this.borderr4 =
                document.querySelector(".right-right-right-right").children[1]
                  .children[0].children[0].offsetHeight /
                  2 +
                50;
            }
          }
        }, 100);
      }

      if (
        document.querySelector(".right-right-right-right-right") != undefined &&
        document.querySelector(".right-right-right-right-right") != null &&
        this.reactive_level5.groups.length > 0
      ) {
        let level5 = document.querySelector(".level4-container");
        document
          .querySelector(".right-right-right-right-right")
          .insertBefore(
            level5,
            document.querySelector(".right-right-right-right-right").firstChild
          );
        setTimeout(() => {
          if (
            document.querySelector(".right-right-right-right-right")
              .children[0] != undefined
          ) {
            this.border5 =
              document.querySelector(".right-right-right-right-right")
                .children[0].offsetHeight / 2;
            if (
              document.querySelector(".right-right-right-right-right")
                .children[1] != undefined &&
              document.querySelector(".right-right-right-right-right")
                .children[1].children[0] != undefined &&
              document.querySelector(".right-right-right-right-right")
                .children[1].children[0].children[0] != undefined
            ) {
              this.borderr5 =
                document.querySelector(".right-right-right-right-right")
                  .children[1].children[0].children[0].offsetHeight /
                  2 +
                50;
            }
          }
        }, 100);
      }

      if (
        document.querySelectorAll(".top-right-5")[0] != undefined &&
        document.querySelectorAll(".top-right-5")[0] != null
      ) {
        setTimeout(() => {
          if (
            (this.padding_top1 =
              document.querySelectorAll(".top-right-5")[0] != undefined)
          ) {
            this.padding_top1 =
              document.querySelectorAll(".top-right-5")[0].offsetHeight / 2 + 8;
            if (
              document.querySelector(".right-1").children[1].children[
                document.querySelector(".right-1").children[1].children.length -
                  1
              ] != undefined
            ) {
              this.padding_bottom1 =
                document.querySelector(".right-1").children[1].children[
                  document.querySelector(".right-1").children[1].children
                    .length - 1
                ].offsetHeight - 38;
            }
          }
          // this.padding_top2[group] =
          //   document.querySelectorAll(".right-6")[group].children[0]
          //     .offsetHeight / 2;
          // this.padding_bottom2[group] =
          //   document.querySelectorAll(".right-6")[group].children[
          //     document.querySelectorAll(".right-6")[group].children.length - 1
          //   ].offsetHeight / 2;
        }, 100);
      }

      if (
        document.querySelectorAll(".top-right-5")[2] != undefined &&
        document.querySelectorAll(".top-right-5")[2] != null
      ) {
        setTimeout(() => {
          // this.padding_top3[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[0]
          //     .offsetHeight / 2;
          // this.padding_bottom3[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[
          //     document.querySelectorAll(".top-right-5")[2].children[1].children
          //       .length - 1
          //   ].offsetHeight / 2;
          if (document.querySelector(".right-right").children[0] != undefined) {
            this.border2 =
              document.querySelector(".right-right").children[0].offsetHeight /
              2;
            if (
              document.querySelector(".right-right").children[1] != undefined &&
              document.querySelector(".right-right").children[1].children[0] !=
                undefined &&
              document.querySelector(".right-right").children[1].children[0]
                .children[0] != undefined
            ) {
              this.borderr2 =
                document.querySelector(".right-right").children[1].children[0]
                  .children[0].offsetHeight /
                  2 +
                48;
            }
          }
        }, 100);
      }
      if (
        document.querySelectorAll(".top-right-5")[3] != undefined &&
        document.querySelectorAll(".top-right-5")[3] != null
      ) {
        setTimeout(() => {
          // this.padding_top4[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[0]
          //     .offsetHeight / 2;
          // this.padding_bottom4[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[
          //     document.querySelectorAll(".top-right-5")[2].children[1].children
          //       .length - 1
          //   ].offsetHeight / 2;
          if (
            document.querySelector(".right-right-right").children[0] !=
            undefined
          ) {
            this.border3 =
              document.querySelector(".right-right-right").children[0]
                .offsetHeight / 2;
            if (
              document.querySelector(".right-right-right").children[1] !=
                undefined &&
              document.querySelector(".right-right-right").children[1]
                .children[0] != undefined &&
              document.querySelector(".right-right-right").children[1]
                .children[0].children[0] != undefined
            ) {
              this.borderr3 =
                document.querySelector(".right-right-right").children[1]
                  .children[0].children[0].offsetHeight /
                  2 +
                48;
            }
          }
        }, 100);
      }
      if (
        document.querySelectorAll(".top-right-5")[4] != undefined &&
        document.querySelectorAll(".top-right-5")[4] != null
      ) {
        setTimeout(() => {
          // this.padding_top5[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[0]
          //     .offsetHeight / 2;
          // this.padding_bottom5[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[
          //     document.querySelectorAll(".top-right-5")[2].children[1].children
          //       .length - 1
          //   ].offsetHeight / 2;
          if (
            document.querySelector(".right-right-right-right").children[0] !=
            undefined
          ) {
            this.border4 =
              document.querySelector(".right-right-right-right").children[0]
                .offsetHeight / 2;
            if (
              document.querySelector(".right-right-right-right").children[1] !=
                undefined &&
              document.querySelector(".right-right-right-right").children[1]
                .children[0] != undefined &&
              document.querySelector(".right-right-right-right").children[1]
                .children[0].children[0] != undefined
            ) {
              this.borderr4 =
                document.querySelector(".right-right-right-right").children[1]
                  .children[0].children[0].offsetHeight /
                  2 +
                48;
            }
          }
        }, 100);
      }
      if (
        document.querySelectorAll(".top-right-5")[5] != undefined &&
        document.querySelectorAll(".top-right-5")[5] != null
      ) {
        setTimeout(() => {
          // this.padding_top6[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[0]
          //     .offsetHeight / 2;
          // this.padding_bottom6[group] =
          //   document.querySelectorAll(".top-right-5")[2].children[1].children[
          //     document.querySelectorAll(".top-right-5")[2].children[1].children
          //       .length - 1
          //   ].offsetHeight / 2;
          if (
            document.querySelector(".right-right-right-right-right")
              .children[0] != undefined
          ) {
            this.border5 =
              document.querySelector(".right-right-right-right-right")
                .children[0].offsetHeight / 2;
            if (
              document.querySelector(".right-right-right-right-right")
                .children[1] != undefined &&
              document.querySelector(".right-right-right-right-right")
                .children[1].children[0] != undefined &&
              document.querySelector(".right-right-right-right-right")
                .children[1].children[0].children[0] != undefined
            ) {
              this.borderr5 =
                document.querySelector(".right-right-right-right-right")
                  .children[1].children[0].children[0].offsetHeight /
                  2 +
                48;
            }
          }
        }, 100);
      }
    },
  },
};
</script>
