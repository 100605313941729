<template>
  <div id="hlx-progress-bar-001hlx"  class="progress-bar-container">
    <span
    ref="hlx-import-progress-bar-loader"
      class="bar"
      :progress-percentage="progress"
      :progress-data="progress + '%'"
      :class="{ 'tooltip-track': toolTip }"
    >
    </span>
  </div>
  <span class="perentage-default"></span>
</template>

<script>
export default {
  name: "HlxImportProgress",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    strokeWidth: {
      type: Number,
      default: 0,
    },
    toolTip: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    progress: {
      handler() {
        // let progress_bar = document.querySelector(
        //   "#hlx-progress-bar-001hlx > span.bar"
        // );
        let progressBar = this.$refs['hlx-import-progress-bar-loader']
        // console.log("🚀 ~ file: ImportProgressComponent.vue:39 ~ handler ~ progressBar:", progressBar)
        if(progressBar) {
          progressBar.style.width = this.progress + "%";
        }
        // if (progress_percentage && this.progress_bar) {}
        // this.progress = progress_percentage
      },
      immediate:true
    },
  },
  mounted() {
    // console.log(this.progress, this.strokeWidth, this.importTitle);
    let progress_bar = document.querySelector(
      "#hlx-progress-bar-001hlx > span.bar"
    );
    progress_bar.style.width = this.progress + "%";
    // if (this.progress && this.progress_bar) {}
  },
};
</script>
