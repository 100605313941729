<template>
  <div
    v-if="position == 'horizontal'"
    class="hlx-horizontal-divider"
    :class="customClass"
    :style="'height:' + space + '; margin-bottom:' + space"
  ></div>
  <div
    v-if="position == 'vertical'"
    class="hlx-vertical-divider"
    :class="customClass"
    :style="'width:' + space + '; margin-right:' + space"
  ></div>
</template>

<script>
export default {
  name: "HlxDivider",
  props: {
    position: {
      type: String,
      default: "horizontal",
    },
    space: {
      type: String,
      default: "",
    },
    class: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      customClass: ''
    }
  },
  watch: {
    class: {
      handler(val) {
        this.customClass = val
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
