<template>
  <div
    :id="'navbar-' + Math.floor(Math.random() * 1000)"
    class="hlx-sidebar-container"
    :class="{
      'collapse-shrink': collapseState,
      collapsible: collapsible
    }"
  >
    <div class="nav_brand">
      <div class="toggler" @click="toggleCollapse('navbar')">
        <i
          id="nav-toggle"
          name="menu-outline"
          :class="
            collapseState
              ? 'icon-angle-right-regular'
              : 'icon-angle-left-regular'
          "
        ></i>
      </div>
      <div
        class="logo-container"
        :class="collapseState ? 'collapsed' : 'extended'"
        @click="goToHome"
      >
        <!-- <i class="nav_logo" :class="iLogo"></i> -->
        <slot v-if="collapseState == false" name="expand"></slot>
        <slot v-else-if="collapseState == true" name="collapse"></slot>
        <!-- <i name="logo-only" class="icon-hamburger-menu" v-else-if="this.collapseState == true"></i> -->
        <!-- <span class="shrink">{{ title }}</span> -->
      </div>
    </div>
    <nav class="nav">
      <ul class="nav-items">
        <li
          v-for="(item, index) in navList"
          :key="item.name"
          :class="
            item.separator == true
              ? 'separator'
              : item.separator == undefined
              ? ''
              : ''
          "
          @click="navTo(item, index, $event)"
        >
          <a
            ref="nav-link"
            href="#"
            :aria-label="bylingualName(item)"
            class="nav_link"
            :class="{ active: item.active }"
          >
            <i name="home-outline" :class="item.icon"></i>
            <span class="nav_name shrink">{{ bylingualName(item) }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HlxSidebar",
  props: {
    navItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    lang: {
      type: String,
      default: "en-US"
    },
    title: {
      type: String,
      default: "Title"
    },
    iLogo: {
      type: String,
      default: "logo"
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    },
    collapseOnly: {
      type: Boolean,
      default: false
    },
    expandOnly: {
      type: Boolean,
      default: false
    },
    configNavs: {
      type: Array,
      default: () => {
        return ["default"];
      }
    }
  },
  emits: ["navTo", "goToHome"],
  data() {
    return {
      currentIndex: null,
      is_extended: true,
      navList: [],

      // collapsible: false,
      collapseState: false
    };
  },
  computed: {
    comuptedCollapse() {
      return this.collapse;
    },
    computedCollapsible() {
      return this.collapsible;
    },
    navListItems() {
      // let count = 0;

      return this.navItems.map((item, i) => {
        if (i !== this.currentIndex) {
          item.active = false;
        }
        return item;
      });
    }
  },
  watch: {
    collapse: {
      handler(type) {
        // if (this.collapseOnly == false && this.expandOnly == false) {

        this.collapseState = type == true ? true : false;
        // }
      },
      immediate: true
    },
    // navItems:{
    //   handler(data) {
    //     this.navList = this.navItems

    //   },
    //   immediate:true,
    //   deep:true
    // },
    collapsible: {
      handler() {
        if (this.collapseOnly == false && this.expandOnly == false) {
          // this.collapseState = type ? true : false;
        }
      },
      immediate: true
    }
  },
  mounted() {
    let count = 0;
    this.navList = this.navItems.map((item) => {
      if (item.active && count == 0) {
        count = 1;
      } else if (item.active && count == 1) {
        item.active = false;
      }
      return item;
    });
  },
  created() {
    this.$watch("collapse", () => {});
    // this.collapseState = this.collapse ? true : false;
  },
  methods: {
    bylingualName(item) {
      switch (this.lang.toLowerCase()) {
        case "en-us":
          return item.label;
        case "en-fr":
          return item.label_fr || item.label;
      }
    },
    toggleCollapse() {
      // const navbar = document.getElementById(id);
      // navbar.classList.toggle('collpase-shrink')
      this.collapseState = !this.collapseState;
    },

    navTo(item, index, $event) {
      let ele = $event.target;
      this.$refs["nav-link"];
      // const links = [...document.querySelectorAll(".nav .nav_link")];
      const links = this.$refs["nav-link"];
      links.filter((link) => {
        link.classList.remove("active");
      });
      ele =
        ele.tagName == "I"
          ? ele.parentElement.classList.add("active")
          : ele.tagName == "SPAN"
          ? ele.parentElement.classList.add("active")
          : ele.tagName == "LI"
          ? ele.classList.children[0].add("active")
          : ele.classList.add("active");
      ele;
      this.navList = this.navList.map((nav, i) => {
        if (i == index) {
          nav.active = true;
        } else {
          nav.active = false;
        }
        return nav;
      });
      this.$emit("navTo", item);
    },
    goToHome() {
      this.$emit("goToHome");
    }
  }
};
</script>
