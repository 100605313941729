<template>
  <hlx-tree-v2
    :nodes="treedataorderTree"
    :direction="tree_direction"
    :zoomable="true"
    :type="'orderTree'"
    
    :read-only="false"
    :is-draggable="true"
  />
</template>

<script>
import hlxTreeV2 from "../components/TreeVersion2Component.vue";
export default {
  components: {
    hlxTreeV2,
  },
  data() {
    return {
      treedataorderTree: {
        description: "VP test internet order 001",
        id: "6653fa4c5e207f2a81610ae6",
        displayID: "ODR-0001",
        "@type": "productOrder",
        state:"cancelled",
        child: [
          {
            displayID: "ODR-0002",
            "@type": "productOrderItem",
            quantity:40,
            productOffering: {
              _id: "66536dae150db5fc7bf8d15e",
              name: "Internet service for vp testing",
              href: "https://tmf-upc-ghubgwxrcq-pd.a.run.app/catalogManagement/productSpecification",
              bundledProductSpecification: [],
              isBundle: false,
              lifecycleStatus: "Active",
              unitOfMeasure: { name: "" },
              category: ["Connectivity services", "Internet"],
              description: "",
              id: "66536dae150db5fc7bf8d15e",
              brand: "Aruba Networks Inc",
            },
            child: [
              {
                description: "Service order of Access CFS,Internet CFS",
                id: "665974db13bcecd739e27723",
                "@type": "serviceOrder",
                displayID: "ODR-0003",
                child: [
                  {
                    id: "665974d813bcecd739e2771b",
                    "@type": "serviceOrderItem",
                    state:"acknowledged",
                    displayID: "ODR-0004",
                    specification: {
                      name: "Access CFS",
                      description: "",
                      category: ["Internet", "Business internet"],
                      "@type": "Customer Facing Service",
                      id: "663633620d187ea20235de5e",
                    },
                    child: [
                      {
                        description:
                          "Service order of Site Access Fibre RFS,Access CPE RFS,Site Access Copper RFS,Access Edge LTE RFS",
                        id: "665974df13bcecd739e27744",
                        "@type": "serviceOrder",
                        displayID: "ODR-0005",
                        child: [
                          {
                            id: "665974de13bcecd739e27736",
                            "@type": "serviceOrderItem",
                            quantity:44,
                            displayID: "ODR-0006",
                            specification: {
                              name: "Site Access Fibre RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66363a650d187ea20235e282",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of Broadband Fiber,Data,Access Circuit",
                                id: "665974e413bcecd739e2778b",
                                "@type": "resourceOrder",
                                displayID: "ODR-0007",
                                child: [
                                  {
                                    id: "665974e213bcecd739e27768",
                                    "@type": "resourceOrderItem",
                                    quantity:340,
                                    displayID: "ODR-0008",
                                    specification: {
                                      id: "66367119f321c12bf31982b8",
                                      name: "Broadband Fiber",
                                      description: "",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                      displayID: "ODR-0009",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27769",
                                    "@type": "resourceOrderItem",
                                    quantity:451,
                                    displayID: "ODR-00010",
                                    specification: {
                                      name: "Data",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e2776a",
                                    "@type": "resourceOrderItem",
                                    quantity:926,
                                    displayID: "ODR-0011",
                                    specification: {
                                      name: "Access Circuit",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e27737",
                            "@type": "serviceOrderItem",
                            quantity:430,
                            displayID: "ODR-0012",
                            specification: {
                              name: "Access CPE RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "663671cff321c12bf3198357",
                            },
                            child: [
                              {
                                description: "Resource order of CPE",
                                id: "665974e313bcecd739e27788",
                                "@type": "resourceOrder",
                                displayID: "ODR-0013",
                                child: [
                                  {
                                    id: "665974e213bcecd739e2776b",
                                    "@type": "resourceOrderItem",
                                    quantity:4230,
                                    displayID: "ODR-0014",
                                    specification: {
                                      name: "CPE",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e27738",
                            "@type": "serviceOrderItem",
                            quantity:10,
                            displayID: "ODR-0015",
                            specification: {
                              name: "Site Access Copper RFS",
                              description: "",
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66366fd1f321c12bf31981d1",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of Broadband Copper,Data,Access Circuit",
                                id: "665974e513bcecd739e277a5",
                                "@type": "resourceOrder",
                                displayID: "ODR-0016",
                                child: [
                                  {
                                    id: "665974e213bcecd739e2776c",
                                    "@type": "resourceOrderItem",
                                    quantity:430,
                                    displayID: "ODR-0017",
                                    specification: {
                                      name: "Broadband Copper",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e2776d",
                                    "@type": "resourceOrderItem",
                                    quantity:9890,
                                    displayID: "ODR-0018",
                                    specification: {
                                      name: "Data",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e2776e",
                                    "@type": "resourceOrderItem",
                                    quantity:40,
                                    displayID: "ODR-0019",
                                    specification: {
                                      name: "Access Circuit",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: "665974de13bcecd739e27739",
                            "@type": "serviceOrderItem",
                            quantity:920,
                            displayID: "ODR-0020",
                            specification: {
                              name: "Access Edge LTE RFS",
                              description: null,
                              category: ["Internet", "Business internet"],
                              "@type": "Resource Facing Service",
                              id: "66363a2a0d187ea20235e22a",
                            },
                            child: [
                              {
                                description:
                                  "Resource order of PDP Context,Static IP,Subscriber Record",
                                id: "665974e513bcecd739e277a8",
                                "@type": "resourceOrder",
                                displayID: "ODR-0021",
                                child: [
                                  {
                                    id: "665974e213bcecd739e2776f",
                                    "@type": "resourceOrderItem",
                                    quantity:70,
                                    specification: {
                                      name: "PDP Context",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27770",
                                    "@type": "resourceOrderItem",
                                    quantity:5,
                                    displayID: "ODR-0022",
                                    specification: {
                                      id: "66367194f321c12bf3198318",
                                      name: "Static IP",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                  {
                                    id: "665974e213bcecd739e27771",
                                    "@type": "resourceOrderItem",
                                  quantity:48,
                                    displayID: "ODR-0023",
                                    specification: {
                                      name: "Subscriber Record",
                                      category: [
                                        "Internet",
                                        "Business internet",
                                      ],
                                      "@type": "Logical Resource",
                                    },
                                    child: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                 
                ],
              },
            ],
          },
        ],
      },
    };
  },
};
</script>

<style></style>
