<template>
  <th
    v-if="resizable == true"
    class="table-header"
    :class="'fixed-column-' + fixed"
    :style="{
      width: width + 'px',
      minWidth: width + 'px',
      maxWidth: width + 'px',
      textAlign: align,
    }"
    @mouseup="resizewidth"
  >
    <span class="table-head"
      ><slot></slot
      ><span v-if="sortable === true" class="table-sort"
        ><i
          class="icon-angle-up-small-filled"
          style="position: relative; top: 1px"
          @click="sorting"
        ></i
        ><i
          class="icon-angle-down-small-filled"
          style="position: relative; top: -3px"
          @click="sorting"
        ></i></span
    ></span>
  </th>
  <th
    v-else
    :class="'fixed-column-' + fixed"
    :style="{
      width: width + 'px',
      minWidth: width + 'px',
      maxWidth: width + 'px',
      textAlign: align,
    }"
  >
    <span class="table-head"
      ><slot></slot
      ><span v-if="sortable === true" class="table-sort"
        ><i
          class="icon-angle-up-small-filled"
          style="position: relative; top: 1px"
          @click="sorting"
        ></i
        ><i
          class="icon-angle-down-small-filled"
          style="position: relative; top: -3px"
          @click="sorting"
        ></i></span
    ></span>
  </th>
</template>

<script>
export default {
  name: "HlxTableHead",
  props: {
    width: {
      type: Number,
      default: null,
    },
    align: {
      type: String,
      default: "center",
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    prop: {
      type: String,
      default: "",
    },
    fixed: {
      type: String,
      default: "",
    },
  },
  emits: ["resizewidth", "sorting_func"],
  data() {
    return {
      widthsize: 0,
      label: "",
    };
  },
  mounted() {
    (function () {
      let thElm = null;
      let startOffset = null;

      Array.prototype.forEach.call(
        document.querySelectorAll(".table-header"),
        function (th) {
          const grip = document.createElement("div");
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.style.zIndex = "3";
          grip.classList.add("grip");
          grip.addEventListener("mousedown", function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });

          th.appendChild(grip);
        }
      );

      document.addEventListener("mousemove", function (e) {
        // console.log('abc');
        if (thElm) {
          thElm.style.minWidth = startOffset + e.pageX + "px";
          this.widthsize = startOffset + e.pageX;
          this.label = thElm.children[0].innerText;
        }
      });

      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    })();
  },
  methods: {
    resizewidth(event) {
      // console.log('hello',event.target.offsetWidth,event.target.parentElement);
      if (event.target.className === "grip") {
        // event.target.parentElement.offsetWidth
        this.$emit(
          "resizewidth",
          event.target.parentElement.offsetWidth,
          this.prop
        );
      } else {
        this.$emit("resizewidth", event.target.offsetWidth, this.prop);
      }
    },
    sorting(event) {
      console.log(this.prop);
      // console.log(event.target.className,'check');
      if (event.target.className === "icon-angle-up-small-filled") {
        document
          .querySelectorAll(".icon-angle-up-small-filled")
          .forEach((e) => {
            e.classList.remove("sorting-icon");
          });
        document
          .querySelectorAll(".icon-angle-down-small-filled")
          .forEach((e) => {
            e.classList.remove("sorting-icon");
          });
        event.target.classList.add("sorting-icon");
        // if( event.target.nextSibling.className === 'icon-angle-down-small-filled sorting-icon')
        // event.target.nextSibling.classList.remove('sorting-icon')
      } else if (event.target.className === "icon-angle-down-small-filled") {
        document
          .querySelectorAll(".icon-angle-up-small-filled")
          .forEach((e) => {
            // console.log(e);
            e.classList.remove("sorting-icon");
            // console.log(e,'op');
          });
        document
          .querySelectorAll(".icon-angle-down-small-filled")
          .forEach((e) => {
            // console.log(e);
            e.classList.remove("sorting-icon");
          });
        event.target.classList.add("sorting-icon");
        // console.log(event.target.previousSibling.className,'okk');
        // if( event.target.previousSibling.className === 'icon-angle-up-small-filled sorting-icon')
        // event.target.previousSibling.classList.remove('sorting-icon')
      } else if (
        event.target.className === "icon-angle-up-small-filled sorting-icon"
      ) {
        event.target.classList.remove("sorting-icon");
      } else if (
        event.target.className === "icon-angle-down-small-filled sorting-icon"
      ) {
        event.target.classList.remove("sorting-icon");
      }
      this.$emit("sorting_func", event.target.className, this.prop);
    },
  },
};
</script>

<style></style>
