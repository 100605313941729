<template>
<h1 class="component-heading">Search
</h1>
  <p class="component-description">
    The Search component gives a website search functionality and displays search results so that users may quickly find relevant material and view results.  </p>
  <divider :position="'horizontal'" :space="'20px'" />
  <h2 class="component-side-heading">Basic usage</h2>
    <p class="component-description">To customise the style of search bar set <b>:search_style</b> to "style1" or "style2" or "style3".</p>
    <p class="component-description">Access the input value using "v-model".</p>
    <!-- <br> -->
  <div class="component-example">
    <div class="example-body">
      <div class="box">
<hlx-search :clearable="true" @search-key="search" />
</div>
<div class="box">
<hlx-search :search-style="'style2'" @search-key="search"/>
</div>
<div class="box">
<hlx-search :search-style="'style3'" @search-key="search"/>
</div>
</div>
    <div class="example-footer">
      <span id="basic-search-icon" class="icon" @click="showCode('basic-search')"><i class="icon-code-regular"></i></span>
    </div>
  </div>
<h1 class="component-side-heading">Example
</h1>
  <p class="component-description">
   Use the <b>@searchkey</b> event to access the text captured by the search bar. 
  </p>
  <div class="component-example">
    <div class="example-body">
<div class="box">
<hlx-search :search-style="'style3'" @blur-value="search"/>
<div style="margin-top:20px;margin-left:20px">
<ul v-for="(i,index) in data_arr_clone" :key="index" style="font-size:14px;color:#191a15;margin-top:5px">
<li>{{i}}</li>
</ul>
</div>
</div>
</div>
    <div class="example-footer">
      <span id="basic-search-icon" class="icon" @click="showCode('basic-search')"><i class="icon-code-regular"></i></span>
    </div>
  </div>
  <div id="basic-search" class="source-code" style="display: none">
    <section class="example-source-wrapper">
        <div class="source">
          <CodeEditor
             :id="'editor'"  :display_language="false"
            :value="search_source"
            :read_only="true"
            :theme="editor_theme"
            :languages="[
              ['Javascript', 'Vue'],
              ['javascript', 'JS'],
              ['python', 'Python'],
            ]"
          />
        </div>
      </section>
  </div>
  <divider :position="'horizontal'" :space="'20px'" />
<h2 class="component-side-heading">Attributes</h2>
<hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :striped-rows="false"
    :column-count="6"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in attributes"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in table_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in attributes"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <!-- <hlx-table-component :tableData="this.searchAttributes.table_data" :theader="this.searchAttributes.columns" :height="'max-content'"/> -->

    <h2 class="component-side-heading">Events</h2>
    <hlx-table
    :border="['table', 'header', 'vertical', 'horizontal']"
    :bold-headers="false"
    :row-hover="false"
    theme="grey"
    :column-count="3"
    :striped-rows="false"
  >
    <template #thead>
      <hlx-table-head
        v-for="(i, index) in events"
        :key="index"
        :width="i.width"
        >{{ i.label }}</hlx-table-head
      >
    </template>
    <template #tbody>
      <tr v-for="(i, index) in event_data" id="" :key="index">
        <hlx-table-cell
          v-for="(j, col_index) in events"
          :key="col_index"
          :align="'left'"
        >
          {{ i[j.prop] }}
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
  <divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'treeSelect')"
      ><i class="icon-angle-left-regular prev-icon"></i>Tree select</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'switch')"
        >Switch <i class="icon-angle-right-regular"></i
      ></span>
    </div>
  </template>

<script>
import hlxSearch
 from '../components/SearchComponent.vue'
 import hlxTable from "../components/table/HlxTable.vue";
import hlxTableHead from "../components/table/HlxTableHead.vue";
import hlxTableCell from "../components/table/HlxTableCell.vue";
import divider from '../components/DividerComponent.vue'
import CodeEditor from 'simple-code-editor'

export default {
  components: {
    hlxSearch
,
hlxTableCell,
    hlxTableHead,
    hlxTable,
    divider,
    CodeEditor
  },

  data () {
    return {
      
      events: [
        {
          prop: "name",
          label: "Event name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 350,
          type: "string",
          format: "",
        },
        {
          prop: "parameter",
          label: "Parameter",
          width: 100,
          type: "string",
          format: "",
        },
      ],
      attributes: [
        {
          prop: "name",
          label: "Name",
          width: 100,
          type: "string",
          format: "",
        },
        {
          prop: "description",
          label: "Description",
          width: 250,
          type: "string",
          format: "",
        },
        {
          prop: "type",
          label: "Type",
          width: 50,
          type: "string",
          format: "",
        },
        {
          prop: "accepted_values",
          label: "Accepted values",
          width: 150,
          type: "string",
          format: "",
        },
        {
          prop: "default",
          label: "Default",
          width: 80,
          type: "string",
          format: "",
        },
        {
          prop: "mandatory",
          label: "Mandatory",
          width: 90,
          type: "string",
          format: "",
        },
      ],
      editor_theme: "light",
       searchkey:'',
       data_arr:["JavaScript","Python","Java","PHP","Swift","Ruby","SQL"],
       data_arr_clone:["JavaScript","Python","Java","PHP","Swift","Ruby","SQL"],
             table_data:[{
        name:'search-style',
        description:'Choose your style for searchbar',
        type:'String',
        accepted_values:'syle1, style2, style3',
        default:'style1',
        mandatory:false
      },
      {
        name:'clearable',
        description:'Erase the content in your input field',
        type:'Boolean',
        accepted_values:'true, false',
        default:'false',
        mandatory:false
      }],
          event_data:[
      {
        name:'searchKey',
        description:'Emits the keyword to search with debounce',
        parameter:'(text searched)'
      },
      {
        name:'focusValue',
        description:'Emits the keyword to search when the field is in focus',
        parameter:'(text searched)'
      },
      {
        name:'blurValue',
        description:'Emits the keyword to search when the field is out of focus',
        parameter:'(text searched)'
      },
    ],
      search_source:
      `
<template>
<hlx-search @searchkey="search" :search-style="'style1'"/> </template>

<script>
export default{
    data(){
        return{
           searchkey:''
        }
    },
    methods:{
        search(key){
          this.searchkey = key
        }
    }
}
</\script>`,
direction: 'vertical'

    }
  },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode (val) {
      document.getElementById(val + '-icon').classList.toggle('active-icon')
      if (document.getElementById(val).style.display === 'none') {
        document.getElementById(val).style.display = 'block'
      } else if (document.getElementById(val).style.display === 'block') {
        document.getElementById(val).style.display = 'none'
      }
    },
      search(key){
      this.searchkey = key
      if(this.searchkey!=''){
        this.data_arr_clone = this.data_arr_clone.filter(function (entry) { return entry.toLowerCase().includes(key.toLowerCase())})
      }else{
        this.data_arr_clone = this.data_arr
      }
      console.log(key,"This is the keyword to search")
    }
  }
}
</script>


