<template>
  <span
    id="hlx-label-0013"
    :class="'hlx-label'"
    :style="{
      height,
      width,
      background: options['background-color'],
      color: options['text-color'],
      'border-color': options['border-color'],
    }"
  >
    <slot> </slot>
  </span>
</template>

<script>
export default {
  name: "HlxLabel",
  props: {
    label: {
      type: String,
      default: "Default",
    },
    height: {
      type: String,
      default: "Default",
    },
    width: {
      type: String,
      default: "Default",
    },
    options: {
      type: Object,
      default: () => {
        return { background: undefined, color: undefined };
      },
    },
  },
};
</script>
