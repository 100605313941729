<template>

  <div>
    <slot></slot>
    <div class="swatch-container" :style="
      position === 'horizontal'
        ? 'display: flex; flex-d irection: row; flex-flow: row wrap;'
        : 'display: flex; flex-direction: column'
    "
    style="gap: 10px">
      <div v-for="(swatch, key, index) in options" :key="index">

        <div
          :style="{ backgroundColor: swatch[label] }"
          :class="{ selected: headerName === swatch[prevalue] }"
          class="swatch"
          @mouseover="hoveredColor = swatch"
          @mouseleave="mouseLeave()"
          @click="selectColor(swatch)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HlxSwatch",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    vModel: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "Color",
    },
    position: {
      type: String,
      default: "horizontal",
    },
  },
emits: [ 'hovered-value', 'selected-value'],
  data() {
    return {
      headerName:"",
      hoveredColor: "",
      prevalue: "",
    };
  },

  watch: {
    value: {
      handler(val) {
        this.prevalue = val;
        console.log(this.prevalue);
        console.log(val, "value");
      },
      immediate: true,
      deep: true,
    },
    vModel: {
      handler(val) {
       this.headerName=val
      },
      immediate: true,
      deep: true,
    },
    hoveredColor(value) {
      this.$emit("hovered-value", value);
    },
   
    
  },
  methods: {
    selectColor(color) {
      this.headerName = color[this.prevalue];
      this.$emit("selected-value", color);
    },
    mouseLeave() {
      this.hoveredColor = "";
    },
  },
};
</script>
<style lang="scss">
.swatch-container {
  display: flex;
  margin-left: -5px;
}

.swatch {
  outline: 2px solid white;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  transition: border-color 0.3s, box-shadow 0.3s;

  &.selected {
    box-shadow: 0 0 0 3px #54bd95;
    color: #54bd95 !important;
  }


}
</style>