<template>
  <td
    :style="{ textAlign: align, whiteSpace: 'break-spaces' }"
    :class="'fixed-column-' + fixed"
  >
    <slot></slot>
  </td>
</template>
<script>
export default {
  name: "HlxTableCell",
  props: {
    align: {
      type: String,
      default: "center",
    },
    fixed: {
      type: String,
      default: "",
    },
  },
};
</script>
