<template>
  <div v-if="showOutputColMap" class="ip-col-map-container">
    <div class="ip-col-map-items">
      <span class="title">Output column mapping</span>
      <hlx-input
        v-model:value="payload.labelName"
        :pre-val="payload.labelName === 'Output label' ? '' : payload.labelName"
        :label-value="'Label name'"
        :label-animation="true"
      ></hlx-input>
      <hlx-select
        v-model="payload.objectMapping.label"
        :placeholder-value="'Object mapping'"
        :options="dropDownOptions.schema"
        :inline-search="true"
        :label="'label'"
        :label-animation="true"
        :prop-value="'value'"
        @selected-value="selectedObjectMappingOption"
      >
      </hlx-select>
      <span class="title-sm">Data source</span>
      <hlx-input
        type="radio-buttons"
        position="vertical"
        :options="dataSourceOptions"
        @selected="setSource"
      >
      </hlx-input>
      <hlx-code-editor
        v-if="payload.source === 'Computed'"
        v-model:value="payload.expression"
        :pre-value="payload.expression"
        :rows="3"
        :line-number="true"
      ></hlx-code-editor>
      <div class="footer-buttons">
        <hlx-button
          class="primary sm"
          style="width: 100% !important"
          @click="saveColumnData"
          >Save</hlx-button
        >
        <hlx-button
          class="secondary sm"
          style="width: 100% !important"
          @click="cancelColumnData"
          >Cancel</hlx-button
        >
        <button
          v-if="index !== 0"
          class="text-error-sm"
          @click="deleteColumnData"
        >
          Delete
        </button>
        <!-- <span>
          <hlx-button
            v-if="index !== 0"
            class="secondary-error sm"
            @click="deleteColumnData"
            >Delete</hlx-button
          >
        </span>
        <span class="save-cancel">
          <hlx-button class="secondary sm" @click="cancelColumnData"
            >Cancel</hlx-button
          >
          <hlx-button class="primary sm" @click="saveColumnData"
            >Save</hlx-button
          >
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import HlxButton from "../ButtonComponent.vue";
import HlxInput from "../InputComponent.vue";
import HlxSelect from "../SelectComponent.vue";
import HlxCodeEditor from "../CodeEditorComponent.vue";

export default {
  components: {
    HlxButton,
    HlxInput,
    HlxSelect,
    HlxCodeEditor,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    showOutputColMap: {
      type: Boolean,
      default: false,
    },
    prePopulate: {
      type: Object,
      default: () => {},
    },
    dropDownData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close", "delete", "save", "cancel"],
  data() {
    return {
      payload: {
        id: "",
        labelName: "",
        dataType: "",
        objectMapping: {},
        operators: [],
        source: "",
        type: "",
        mode: "",
        from: "",
        to: "",
        expression: ""
      },
      dropDownOptions: {
        schema: [
          {
            label: "EICALC.Access Type",
            value: "EICALC.Access Type",

            type: "String",
          },

          {
            label: "EICALC.Band 0 MRC",
            value: "EICALC.Band 0 MRC",

            type: "Number",
          },

          {
            label: "EICALC.Band 1 MRC",
            value: "EICALC.Band 1 MRC",

            type: "Number",
          },

          {
            label: "EICALC.Band 2 MRC",
            value: "EICALC.Band 2 MRC",

            type: "Number",
          },

          {
            label: "EICALC.Band 3 MRC",
            value: "EICALC.Band 3 MRC",

            type: "Number",
          },

          {
            label: "EICALC.Band 4 MRC",
            value: "EICALC.Band 4 MRC",

            type: "Number",
          },

          {
            label: "EICALC.Band 4S MRC",
            value: "EICALC.Band 4S MRC",

            type: "Number",
          },

          {
            label: "EICALC.Diversity Options",
            value: "EICALC.Diversity Options",

            type: "String",
          },

          {
            label: "EICALC.Installation NRC",
            value: "EICALC.Installation NRC",

            type: "Number",
          },

          {
            label: "EICALC.Speed",
            value: "EICALC.Speed",

            type: "String",
          },

          {
            label: "EIOP.EI",
            value: "EIOP.EI",

            type: "Object",
          },

          {
            label: "EIOP.EI.AccessType",
            value: "EIOP.EI.AccessType",

            type: "String",
          },

          {
            label: "EIOP.EI.Band",
            value: "EIOP.EI.Band",

            type: "String",
          },

          {
            label: "EIOP.EI.InstallationNRC",
            value: "EIOP.EI.InstallationNRC",

            type: "Number",
          },

          {
            label: "EIOP.EI.LAG",
            value: "EIOP.EI.LAG",

            type: "Object",
          },

          {
            label: "EIOP.EI.LAG.InstallationNRC",
            value: "EIOP.EI.LAG.InstallationNRC",

            type: "Number",
          },

          {
            label: "EIOP.EI.LAG.MRC",
            value: "EIOP.EI.LAG.MRC",

            type: "Number",
          },

          {
            label: "EIOP.EI.MRC",
            value: "EIOP.EI.MRC",

            type: "Number",
          },

          {
            label: "EIOP.EI.PostalCode",
            value: "EIOP.EI.PostalCode",

            type: "Number",
          },

          {
            label: "EIOP.EI.Speed",
            value: "EIOP.EI.Speed",

            type: "String",
          },

          {
            label: "EIOP.EI.Term",
            value: "EIOP.EI.Term",

            type: "Number",
          },

          {
            label: "EIOP.EI.isLAG",
            value: "EIOP.EI.isLAG",

            type: "Boolean",
          },

          {
            label: "EIOP.TotalMRC",
            value: "EIOP.TotalMRC",

            type: "Number",
          },

          {
            label: "EIOP.TotalNRC",
            value: "EIOP.TotalNRC",

            type: "Number",
          },

          {
            label: "Item.price",
            value: "Item.price",

            type: "Number",
          },

          {
            label: "Item.quantity",
            value: "Item.quantity",

            type: "Number",
          },

          {
            label: "Item.totalPrice",
            value: "Item.totalPrice",

            type: "Number",
          },

          {
            label: "add.locatiob",
            value: "add.locatiob",

            type: "Array",
          },

          {
            label: "akss.Afd",
            value: "akss.Afd",

            type: "Array",
          },

          {
            label: "checkdemo1.Location",
            value: "checkdemo1.Location",

            type: "String",
          },

          {
            label: "checkdemo1.Province",
            value: "checkdemo1.Province",

            type: "String",
          },

          {
            label: "demo1.Location",
            value: "demo1.Location",

            type: "Array",
          },

          {
            label: "demo2.province",
            value: "demo2.province",

            type: "Array",
          },

          {
            label: "demo3.Location",
            value: "demo3.Location",

            type: "Array",
          },

          {
            label: "demo4.Location",
            value: "demo4.Location",

            type: "Object",
          },

          {
            label: "demotest1.location",
            value: "demotest1.location",

            type: "Array",
          },

          {
            label: "demotest1.province",
            value: "demotest1.province",

            type: "String",
          },

          {
            label: "demotest1test2.Location",
            value: "demotest1test2.Location",

            type: "String",
          },

          {
            label: "demotest1test2.province",
            value: "demotest1test2.province",

            type: "String",
          },

          {
            label: "quote.agreement",
            value: "quote.agreement",

            type: "Array",
          },

          {
            label: "quote.attachment",
            value: "quote.attachment",

            type: "Array",
          },

          {
            label: "quote.authorization",
            value: "quote.authorization",

            type: "Array",
          },

          {
            label: "quote.billingAccount",
            value: "quote.billingAccount",

            type: "Array",
          },

          {
            label: "quote.category",
            value: "quote.category",

            type: "String",
          },

          {
            label: "quote.contactMedium",
            value: "quote.contactMedium",

            type: "Array",
          },

          {
            label: "quote.description",
            value: "quote.description",

            type: "String",
          },

          {
            label: "quote.effectiveQuoteCompletionDate",
            value: "quote.effectiveQuoteCompletionDate",

            type: "Date",
          },

          {
            label: "quote.expectedFulfillmentStartDate",
            value: "quote.expectedFulfillmentStartDate",

            type: "Date",
          },

          {
            label: "quote.expectedQuoteCompletionDate",
            value: "quote.expectedQuoteCompletionDate",

            type: "Date",
          },

          {
            label: "quote.externalId",
            value: "quote.externalId",

            type: "String",
          },

          {
            label: "quote.note",
            value: "quote.note",

            type: "Array",
          },

          {
            label: "quote.price",
            value: "quote.price",

            type: "Number",
          },

          {
            label: "quote.productOfferingQualification",
            value: "quote.productOfferingQualification",

            type: "Array",
          },

          {
            label: "quote.productRef",
            value: "quote.productRef",

            type: "Object",
          },

          {
            label: "quote.quoteDate",
            value: "quote.quoteDate",

            type: "Date",
          },

          {
            label: "quote.quoteHistory",
            value: "quote.quoteHistory",

            type: "Array",
          },

          {
            label: "quote.quoteItem",
            value: "quote.quoteItem",

            type: "Array",
          },

          {
            label: "quote.quoteItem.price",
            value: "quote.quoteItem.price",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.agreement",
            value: "quote.quoteItem.productOffering.agreement",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.attachment",
            value: "quote.quoteItem.productOffering.attachment",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.bundledProductOffering",
            value: "quote.quoteItem.productOffering.bundledProductOffering",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.category",
            value: "quote.quoteItem.productOffering.category",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.channel",
            value: "quote.quoteItem.productOffering.channel",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.description",
            value: "quote.quoteItem.productOffering.description",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.isBundle",
            value: "quote.quoteItem.productOffering.isBundle",

            type: "Boolean",
          },

          {
            label: "quote.quoteItem.productOffering.isSellable",
            value: "quote.quoteItem.productOffering.isSellable",

            type: "Boolean",
          },

          {
            label: "quote.quoteItem.productOffering.lastUpdate",
            value: "quote.quoteItem.productOffering.lastUpdate",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.lifecycleStatus",
            value: "quote.quoteItem.productOffering.lifecycleStatus",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.marketSegment",
            value: "quote.quoteItem.productOffering.marketSegment",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.name",
            value: "quote.quoteItem.productOffering.name",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.place",
            value: "quote.quoteItem.productOffering.place",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.prodSpecCharValueUse",
            value: "quote.quoteItem.productOffering.prodSpecCharValueUse",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.productOfferingPrice",
            value: "quote.quoteItem.productOffering.productOfferingPrice",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.productOfferingTerm",
            value: "quote.quoteItem.productOffering.productOfferingTerm",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.productSpecification",
            value: "quote.quoteItem.productOffering.productSpecification",

            type: "Object",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.attachment",
            value:
              "quote.quoteItem.productOffering.productSpecification.attachment",

            type: "Array",
          },

          {
            label: "quote.quoteItem.productOffering.productSpecification.brand",
            value: "quote.quoteItem.productOffering.productSpecification.brand",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.bundledProductSpecification",
            value:
              "quote.quoteItem.productOffering.productSpecification.bundledProductSpecification",

            type: "Boolean",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.description",
            value:
              "quote.quoteItem.productOffering.productSpecification.description",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.isBundle",
            value:
              "quote.quoteItem.productOffering.productSpecification.isBundle",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.lastUpdate",
            value:
              "quote.quoteItem.productOffering.productSpecification.lastUpdate",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.productSpecification.name",
            value: "quote.quoteItem.productOffering.productSpecification.name",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.category",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.category",

            type: "Array",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.catlogtype",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.catlogtype",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.description",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.description",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.id",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.id",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.lastUpdate",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.lastUpdate",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.lifecycleStatus",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.lifecycleStatus",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.name",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.name",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.relatedParty",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.relatedParty",

            type: "Array",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.unitPrice",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.unitPrice",

            type: "String",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productRef.validFor",
            value:
              "quote.quoteItem.productOffering.productSpecification.productRef.validFor",

            type: "Object",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productSpecCharacteristic",
            value:
              "quote.quoteItem.productOffering.productSpecification.productSpecCharacteristic",

            type: "Array",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.productSpecificationRelationship",
            value:
              "quote.quoteItem.productOffering.productSpecification.productSpecificationRelationship",

            type: "Array",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.relatedParty",
            value:
              "quote.quoteItem.productOffering.productSpecification.relatedParty",

            type: "Array",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.resourceSpecification",
            value:
              "quote.quoteItem.productOffering.productSpecification.resourceSpecification",

            type: "Array",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.serviceSpecification",
            value:
              "quote.quoteItem.productOffering.productSpecification.serviceSpecification",

            type: "Object",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.validFor",
            value:
              "quote.quoteItem.productOffering.productSpecification.validFor",

            type: "Object",
          },

          {
            label:
              "quote.quoteItem.productOffering.productSpecification.version",
            value:
              "quote.quoteItem.productOffering.productSpecification.version",

            type: "String",
          },

          {
            label: "quote.quoteItem.productOffering.resourceCandidate",
            value: "quote.quoteItem.productOffering.resourceCandidate",

            type: "Object",
          },

          {
            label: "quote.quoteItem.productOffering.serviceCandidate",
            value: "quote.quoteItem.productOffering.serviceCandidate",

            type: "Object",
          },

          {
            label: "quote.quoteItem.productOffering.serviceLevelAgreement",
            value: "quote.quoteItem.productOffering.serviceLevelAgreement",

            type: "Object",
          },

          {
            label: "quote.quoteItem.productOffering.validFor",
            value: "quote.quoteItem.productOffering.validFor",

            type: "Object",
          },

          {
            label: "quote.quoteItem.productOffering.version",
            value: "quote.quoteItem.productOffering.version",

            type: "String",
          },

          {
            label: "quote.quoteItem.quantity",
            value: "quote.quoteItem.quantity",

            type: "String",
          },

          {
            label: "quote.quoteItem.tax",
            value: "quote.quoteItem.tax",

            type: "String",
          },

          {
            label: "quote.quoteItem.unitPrice",
            value: "quote.quoteItem.unitPrice",

            type: "String",
          },

          {
            label: "quote.quoteTotalPrice",
            value: "quote.quoteTotalPrice",

            type: "Array",
          },

          {
            label: "quote.relatedParty",
            value: "quote.relatedParty",

            type: "Array",
          },

          {
            label: "quote.state",
            value: "quote.state",

            type: "String",
          },

          {
            label: "quote.tax",
            value: "quote.tax",

            type: "Number",
          },

          {
            label: "quote.totalPrice",
            value: "quote.totalPrice",

            type: "Number",
          },

          {
            label: "quote.userId",
            value: "quote.userId",

            type: "String",
          },

          {
            label: "quote.validFor",
            value: "quote.validFor",

            type: "Object",
          },

          {
            label: "quote.version",
            value: "quote.version",

            type: "String",
          },

          {
            label: "tesaaaaa.Location",
            value: "tesaaaaa.Location",

            type: "Array",
          },

          {
            label: "test04.apo",
            value: "test04.apo",

            type: "Object",
          },

          {
            label: "test1.location",
            value: "test1.location",

            type: "Array",
          },

          {
            label: "test10.Location",
            value: "test10.Location",

            type: "Array",
          },

          {
            label: "test1test2.Location",
            value: "test1test2.Location",

            type: "Array",
          },

          {
            label: "test24.aka",
            value: "test24.aka",

            type: "Array",
          },

          {
            label: "test5.location",
            value: "test5.location",

            type: "Array",
          },

          {
            label: "testtt.qq",
            value: "testtt.qq",

            type: "Object",
          },
        ],
      },
      dataSourceOptions: [
        {
          name: "Manual",
          value: "Manual",
          checked: false,
          disabled: false,
          id: 1,
        },
        {
          name: "Reference data",
          value: "Reference data",
          checked: false,
          disabled: false,
          id: 2,
        },
        {
          name: "Computed",
          value: "Computed",
          checked: false,
          disabled: false,
          id: 3,
        },
      ],
    };
  },
  watch: {
    dropDownData: {
      handler(val) {
        if (val.length > 0) {
          this.dropDownOptions.schema = val;
        }
      },
      immediate: true,
      deep: true,
    },
    prePopulate: {
      handler(val) {
        this.payload.id = JSON.parse(JSON.stringify(val.id));
        this.payload.labelName = JSON.parse(JSON.stringify(val.labelName));
        this.payload.source = JSON.parse(JSON.stringify(val.source));
        this.payload.type = JSON.parse(JSON.stringify(val.type));
        this.payload.dataType = JSON.parse(JSON.stringify(val.dataType));
        if (this.payload.expression) {
          this.payload.expression = JSON.parse(JSON.stringify(val.expression));
        }
        if (val.operators) {
          this.payload.operators = JSON.parse(JSON.stringify(val.operators));
        }
        this.payload.objectMapping = JSON.parse(
          JSON.stringify(val.objectMapping)
        );
        this.payload.mode = JSON.parse(JSON.stringify(val.mode));
        this.payload.from = JSON.parse(JSON.stringify(val.from));
        this.payload.to = JSON.parse(JSON.stringify(val.to));
        this.dataSourceOptions.filter((item) => { item.value === JSON.parse(JSON.stringify(val.source)) ? item.checked = true : item.checked = false})
      },
    },
  },
  mounted() {
    // console.log("op col map modal mounted");
  },
  methods: {
    setSource(val) {
      this.payload.source = val.value
      this.payload.mode = val.value
      // console.log(this.payload);
    },
    selectedObjectMappingOption(val) {
      const option = val;
      this.payload.objectMapping.label = option.label;
      this.payload.objectMapping.type = option.type;
      this.payload.dataType = option.type;
      const parts = val.label.split(".");
      this.payload.labelName =
        parts.length > 1 ? parts[parts.length - 1] : val.label;
        this.payload.from = option.label;
      this.payload.to = parts.length > 1 ? parts[parts.length - 1] : val.label;
    },
    deleteColumnData() {
      this.$emit("delete", this.payload);
      this.$emit("close", true);
    },
    cancelColumnData() {
      this.payload.id = JSON.parse(JSON.stringify(this.prePopulate.id));
      this.payload.labelName = JSON.parse(
        JSON.stringify(this.prePopulate.labelName)
      );
      this.payload.source = JSON.parse(JSON.stringify(this.prePopulate.source));
      this.payload.type = JSON.parse(JSON.stringify(this.prePopulate.type));
      this.payload.dataType = JSON.parse(
        JSON.stringify(this.prePopulate.dataType)
      );
      this.payload.objectMapping = JSON.parse(
        JSON.stringify(this.prePopulate.objectMapping)
      );
       this.payload.mode = JSON.parse(JSON.stringify(this.prePopulate.mode));
      this.payload.from = JSON.parse(JSON.stringify(this.prePopulate.from));
      this.payload.to = JSON.parse(JSON.stringify(this.prePopulate.to));
      if (this.prePopulate.expression) {
        this.payload.expression = JSON.parse(JSON.stringify(this.prePopulate.expression));
      }
      if (this.prePopulate.operators) {
        this.payload.operators = JSON.parse(JSON.stringify(this.prePopulate.operators));
      }
      this.$emit("cancel", "cancelled");
      this.$emit("close", true);
    },
    saveColumnData() {
      console.log("save");
      this.$emit("save", this.payload);
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="scss">
.ip-col-map-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(24, 24, 27, 0.2);
  align-items: center;
  justify-content: center;
  z-index: 2000;
  .ip-col-map-items {
    width: 310px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: max-content;
    .title {
      font-weight: bold;
    }
    .title-sm {
      font-size: 14px;
      font-weight: bold;
    }
    .footer-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      .text-error-sm {
        width: 100% !important;
        color: #ff5050;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .save-cancel {
        display: flex;
        gap: 10px;
      }
    }
  }
}
</style>
