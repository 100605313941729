<template>
  <div class="calendar-container">
    <div class="calendar-filter-dropdown">
    <span><hlx-select
        :options="dropdown_data"
        :pre-value="'This'"></hlx-select></span>
    <span><hlx-select
    :options="dropdown_data1"
    :pre-value="'day'" 
    @selected-value="selectedDate"></hlx-select></span>
    </div>
        <header class="calendar-header">
            <p class="calendar-current-date"></p>
            <div class="calendar-navigation">
                <span id="calendar-prev" class="material-symbols-rounded"><i class="icon-angle-left-filled"></i></span>
                <span id="calendar-next" class="material-symbols-rounded"><i class="icon-angle-right-filled"></i></span>
            </div>
        </header>
        <div class="calendar-body">
            <ul class="calendar-weekdays">
                <li>Sun</li>
                <li>Mon</li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
            </ul>
            <ul class="calendar-dates"></ul>
        </div>
    </div>
</template>

<script>
import hlxSelect from "../components/SelectComponent.vue";

export default {
    name:'HlxCalendarFilter',
    components: {
    hlxSelect,
  },
      data() {
    return {

        months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"],
        dropdown_data: [
        { name: "Past", value: "Past" },
        { name: "This", value: "This" },
        { name: "Next", value: "Next" },
      ],
      dropdown_data1: [
        { name: "day", value: "day" },
        { name: "week", value: "week" },
        { name: "month", value: "month" },
        { name: "year", value: "year" },
      ],
    }
      },
    mounted()
    {
            var prenexIcons= document.querySelectorAll(".calendar-navigation span i")
            var date=new Date()
            var year=date.getFullYear();
            var month=date.getMonth();
            var day= document.querySelector(".calendar-dates")
            var currdate= document.querySelector(".calendar-current-date")
            var dayone=new Date(year, month, 1).getDay();
            var lastdate=new Date(year, month + 1, 0).getDate();
            var dayend=new Date(year, month, lastdate).getDay();
            var monthlastdate=new Date(year, month, 0).getDate();
            var lit=""; 
            for (var i=dayone; i > 0; i--) {
        lit+=`<li class="inactive">${monthlastdate - i + 1}</li>`;
            }
            for (let i=1; i <=lastdate; i++) {
        var isToday=i===date.getDate() && month===new Date().getMonth() && year===new Date().getFullYear() ? "active": "";
        lit+=`<li class="${isToday}">${i}</li>`;
            }
                    for (let i=dayend; i < 6; i++) {
        lit+=`<li class="inactive">${i - dayend + 1}</li>`
            }
            currdate.innerText=`${this.months[month]} ${year}`;
            day.innerHTML=lit;


//                 var date=new Date()
//  var year=date.getFullYear();
//  var month=date.getMonth();
            prenexIcons.forEach(icon=> {
        
        icon.addEventListener("click", ()=> {
                month=icon.className==="icon-angle-left-filled" ? month - 1 : month + 1;
                if (month < 0 || month > 11) {
                    date=new Date(year, month, new Date().getDate());
                    year=date.getFullYear();
                    month=date.getMonth();
                }
        
                else {
                    date=new Date();
                }
                 var day= document.querySelector(".calendar-dates")
                var currdate= document.querySelector(".calendar-current-date")
                var dayone=new Date(year, month, 1).getDay();
                var lastdate=new Date(year, month + 1, 0).getDate();
                var dayend=new Date(year, month, lastdate).getDay();
                var monthlastdate=new Date(year, month, 0).getDate();
                var lit="";
            for (var i=dayone; i > 0; i--) {
        lit+=`<li class="inactive">${monthlastdate - i + 1}</li>`;
            }
                    for (let i=1; i <=lastdate; i++) {
        var isToday=i===date.getDate() && month===new Date().getMonth() && year===new Date().getFullYear() ? "active": "";
        lit+=`<li class="${isToday}">${i}</li>`;
            }
            for (let i=dayend; i < 6; i++) {
        lit+=`<li class="inactive">${i - dayend + 1}</li>`
            }
            currdate.innerText=`${this.months[month]} ${year}`;
            day.innerHTML=lit;

            });
            });
        
    },
    methods:
    {
        selectedDate(val)
        {
            console.log(val);
        }
    }
}
</script>

<style>

</style>