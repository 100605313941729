const install = function (Vue) {
  // 1. add global method or property
  Vue.myGlobalMethod = function () {
    // some logic ...
  }

  // 2. add a global asset
  // Vue.directive('my-directive', {
  //   bind (el, binding, vnode, oldVnode) {
  //     // some logic ...
  //   }
  //   ...
  // })
  // By varsan
  const originalConsoleLog = console.log
  // 3. inject some component options
  Vue.mixin({
    methods: {
      isConsole (isEnable) {
        if (!isEnable) {
          console.log = function () {}
        } else {
          console.log = originalConsoleLog
        }
      }
    }
  })

  // 4. add an instance method
  // Vue.prototype.$myMethod = function (methodOptions) {
  //
  //   // some logic ...
  // }
}

const customPlugin = {
  install
}
export default customPlugin
