<template>
  {{checkedByUsers}}

  <section class="filter-serach-cover">
    <i class="icon-search-regular search-logo"></i>
    <input
    
      ref="inputBox"
      v-model="search"
      class="filter-search-input-box"
      placeholder="Search catalogue"
      :style="{ 'display': isTag ? 'none' : 'block'}"
      @click="viewDropDown"
      @keyup.enter="closeDropDown"
    />
    <span class="filter-search-tag" :style="{'width': !isTag? '0px':'90%'}">
      <HlxTagcount v-if="!isSearch" v-show="isTag" :style="{'display': !isTag ? 'none' : 'block'}" :arrayfile="checkedByUsers" item-key="name" @tag-closed="removeTag"></HlxTagcount>
      </span>


    <section class="filter" :style="{ 'background-color': isActiveFilter  ? '#DCF9EA' : 'white' }" :class="{ active: isActiveFilter }">
      <i class="icon-filter-regular filter-logo" @click="showFilter" >
        
      </i>
      <span class="filter-total-count">{{
          // eslint-disable-next-line vue/no-parsing-error
          labelValues.length <= 0 ? "" : labelValues.length
        }}</span>
    </section>
  </section>
  <section v-show="isSearch" ref="dropdown" class="search-drop-down">
    <section
      ref="activefilter"
      class="active-filter"
      :style="{ display: labelValues.length > 0 ? 'flex' : 'none' }"
    >
      <span class="active-filter-header">Active filters :</span>

      <!--Label values indicated an array of values that checked in the filter 

For example Array looks like:
[ 
{ "count": 1, "value": { "value": "Operating Temperature", "type": "String", "valueSuffix": "Each" } }, 
{ "count": 1, "value": { "value": "Mobile to TV", "id": "", "type": "string", "valueSuffix": "Each" } } 
]

-->

      <section
        v-for="(value, index) in labelValues"
        :key="index"
        class="active-filter-tag"
      >
        <hlx-tag @tag-close="removed(value)">{{
          value[filterValueObjectName].value || "Others"
        }}</hlx-tag>
        <!-- <hlx-tag  @tag-close="removed(value)">{{labelValues}}</hlx-tag> -->
      </section>
    </section>
    <section class="search-checkbox">
      <hlx-input
        :options="receivedOptions"
        :type="'checkbox'"
        :show-select-all="true"
        @check-box-event="checked"
      />
    </section>
  </section>

  <section v-show="isFilter" ref="filter" class="filter-icon" >
    <section class="filter-top" >
      <span class="filter-heading">Filters</span>
      <i class="icon-times-regular" @click="closeFilter" ></i>
    </section>

    <div class="filter-search-accordion">
      <hlx-accordion
        v-for="(values, key) in filterCheckedData"
        :key="key"
        :accordion-data="receivedFilterData"
        :multiple="false"
      >
        <template #item-title>
          <section class="filter-title-cover">
          <section class="filter-title">
            {{ values[keyValueFromQueryArray] || "Others" }}

            <span class="selected-filter-count">{{ selectedFilterCounts[values[keyValueFromQueryArray]]|| "" }}</span>
            
          </section>
          </section>
        </template>
        <template #item-body>
          <section
            v-for="(item, index) in receivedFilterData[
              values[keyValueFromQueryArray]
            ]"
            :key="index"
            class="filter-content"
          >
            <p class="filter-content-header">
              {{ item[filterValueObjectName].value || "Others" }}
              <span class="filter-content-count">{{ item.count || "Others" }}</span>
            </p>

            <section class="filter-check-box">
              <hlx-input
                v-model:value="checkedval"
                :checked="isThisChecked(item)"
                :type="'single-checkbox'"
                @selected="
                  checkedDataInFilter(
                    item,
                    values.key,
                    values[keyValueFromQueryArray],
                    values.fieldtoFilter
                  )
                "
              />
            </section>
            <!-- Add additional data display as needed -->
          </section>
        </template>
      </hlx-accordion>
    </div>
  </section>
</template>

<script>

import hlxAccordion from "./AccordionComponent.vue";
import hlxInput from "./InputComponent.vue";
import hlxTag from "./TagComponent.vue";
import HlxTagcount from './TagcountComponent.vue'
export default {
  name: "HlxFilterSearch",
  components: {
    hlxInput,
    hlxAccordion,
    hlxTag,
    HlxTagcount
  },
  // In props module represent name of the flow.E.g: module:ProductOffering,
  // module is one of the keyvalue that we emit while typing in searchbox.

  //In filterSearchOptions:Array of value need to be in checkbox

  props: {
    module: {
      type: String,
      default: "ProductOffering",
    },
    filterSearchOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterCheckedData: {
      type: Array,
      default: () => {
        return [];
      },
    },

    keyValueFromQueryArray: {
      //   {
      // key:"Multi device feature",
      // fieldtoFilter:"multidevicefeature.mediumtype"
      //     },

      type: String,
      default: () => {
        return "key";
      },
    },
    filterValues: {
      // Environment: [ // Environment represents the filter headervalue
      //     {
      //       count: 1,
      //       value: {  //filterValueObjectName props
      //         value:
      //           "Operating Temperature", // Represents the filtervalues props inside the Environment
      //         type: "String",
      //         valueSuffix: "Each",
      //       },
      //     },
      //   ],
      type: String,
      default: () => {
        return "value";
      },
    },
    filterValueObjectName: {
      type: String,
      default: () => {
        return "value";
      },
    },
  },
  emits:['searched-value'],
  data() {
    return {
      selectedFilterCounts:{},
      checkedval: {},
      labelValues: [],
      eventValues: [],
      search: "",
      queryType: [],
      isSearch: false,
      isTag:false,
      receivedOptions: [],
      checkedByUsers: [],
      isFilter: false,
      isActiveFilter: false,
      receivedFilterData: {},

      filterQueryFormat:{
        type: 'filter-search',
        module: this.module,
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          // "@baseType": "customer",
        },
        searchQuery: "",
        company: "developerHalleyx",
      }
      
    };
  },

  computed: {
    //   filteredOptions() {
    //     if (!this.search.trim()) {
    //       return this.receivedOptions;
    //     }
    //     const searchedValue = this.search.toLowerCase();
    //     return this.searchedCheckBox(searchedValue);
    //   },
  },
  watch: {
    filterSearchOptions: {
      handler(val) {
        if(val)
        {
          val.forEach((e) => {
            e.checked = false;
            e.disabled = false;
          });
          // val[2].checked = true
          this.receivedOptions = JSON.parse(JSON.stringify(val));
          console.log("watching :", this.receivedOptions);
        }
      },
      immediate: true,
      deep: true,
    },

    filterData: {
      handler(val) {
        if(val){
          console.log("From filter data watch");
          this.receivedFilterData = JSON.parse(JSON.stringify(val));
          console.log(this.receivedFilterData);
          // receivedFilterData is an object of arrays
  
          console.log("I am duplicate filter ", this.receivedFilterData);
        }
      },
      immediate: true,
      deep: true,
    },
    search: {
      handler(val) {
        if(val)
        {
          this.searchedCheckBox(val);
          console.log("Hi...");
        }
      },
      immediate: true,
      deep: true,
    },
    queryType: {
      handler(newVal) {
      
          console.log("queryType has changed:", newVal);
          this.queryTypeMethod(newVal);
       
      },
      immediate:true,
      deep: true,
    },
  },

  mounted() {

    // this.filterSearchOptions.forEach((option) => {
    //   option.checked = false;
    //   console.log("Mounted :",option);
    // });
    const filterSearchTag = document.querySelector('.filter-search-tag');
  if (filterSearchTag) {
    filterSearchTag.addEventListener('click', this.handleFilterSearchTagClick);
  }


   console.log("from filter query mounted :",this.filterQueryFormat); 
    console.log("send");
  },
  methods: {

    handleFilterSearchTagClick(event) {
    const isIconClick = event.target.classList.contains('icon-times-regular');
    if (!isIconClick) {
      this.isTag = false;
    }
  },

    removeTag(value){
      console.log("removed ",value)
      console.log("removed name ",value.name)
      const index = this.checkedByUsers.findIndex(product => product.name === value.name);
        if (index !== -1) {
          this.checkedByUsers[index].checked = false;
        }
      this.checkedByUsers=this.checkedByUsers.filter(product=>product.name!==value.name)
      console.log("remove checked",this.checkedByUsers)
    },

    queryTypeMethod(queryType) {
     
        if ( queryType && queryType.length > 0) {
          console.log("I am query type lenght :", queryType.length);
          return 'filter-search';
        } else if(queryType && queryType.length < 0) {
          console.log("I am from query method :", queryType);
          return queryType.toString();
        }
      
      else{
        return 'filter'
      }
     
    },

    
    

    closeFilter() {
      this.isFilter = !this.isFilter;
      this.isActiveFilter = !this.isActiveFilter;
      // this.isTag=true
      this.isTag = this.checkedByUsers.length > 0;
    },

    removed(data) {
      // console.log("key ",key);
      console.log("Event Values",this.eventValues);
      console.log("I am clicked");
      console.log("Data removed from tag :", data);
      console.log("Data removed from tag :", data.value.value);
      this.labelValues = this.labelValues.filter(
        (label) => label.value.value != data.value.value
      );
      console.log(this.labelValues, "labelvalues");


      Object.keys(this.filterQueryFormat.filterQuery).forEach((filtername) => {
        let filterArray =
          this.filterQueryFormat.filterQuery[filtername] &&
          this.filterQueryFormat.filterQuery[filtername]["$in"];
        console.log("fname ", filtername);
        console.log("get");
        console.log(filterArray);
        if (filterArray) {
          console.log("set");
          const indexToRemove = filterArray.indexOf(data.value.value);
          if (indexToRemove !== -1) {
            console.log("ready");
            filterArray.splice(indexToRemove, 1);
            if (filterArray.length === 0) {
              delete this.filterQueryFormat.filterQuery[filtername];
            }
          }
        }
      });

      Object.keys(this.receivedFilterData).forEach(element => {

  const removedDataExists = this.receivedFilterData[element].some(item => {
    return item.value.value === data.value.value; 
  });


  if (removedDataExists && this.selectedFilterCounts[element]) {
    this.selectedFilterCounts[element]--;
  }
});





      console.log("Lenght of labelValues fromtag :",this.labelValues.length);
     
      console.log(this.filterQueryFormat);
      console.log("Array after removed :", this.labelValues);
         
      this.$emit("searched-value", this.filterQueryFormat);
      console.log("I am filters data props :", this.filterCheckedData);
    },
    
    checkedDataInFilter(val, event, filterkeyname, filtername) {
      if (this.checkedval === true) {
        this.labelValues.push(val);
        this.eventValues.push(event);
        console.log("Event ", event);

        console.log("filtername ",filterkeyname)

        if (!this.selectedFilterCounts[filterkeyname]) {
          console.log("object :",filterkeyname);
          this.selectedFilterCounts[filterkeyname] = 1;
    
          console.log("Whole object :",this.selectedFilterCounts);
          console.log("slected filtercount :",this.selectedFilterCounts[filterkeyname]);
          console.log("number of selected :",  this.selectedFilterCounts[filterkeyname]);
        } else {
          this.selectedFilterCounts[filterkeyname]++;
          console.log("number of selected :",  this.selectedFilterCounts[filterkeyname]);
        }
      } else {
        const index = this.labelValues.indexOf(val);
        const indexOfEvent = this.eventValues.indexOf(event);

        if (this.selectedFilterCounts[filterkeyname]) {
          console.log("number of selected :",  this.selectedFilterCounts[filterkeyname]);
          this.selectedFilterCounts[filterkeyname]--;
        }

        console.log(index);
        if (index !== -1) {
          this.labelValues.splice(index, 1);

          this.isCheckedInFilter(val, filtername);
        }
        if (indexOfEvent !== -1) {
          this.eventValues.splice(indexOfEvent, 1);
        }
      }

      
      if (this.labelValues.length > 0) {
        if (this.queryType.includes("filter")) {
          this.queryType;
        } else {
          this.queryType.push("filter");
        }
      }
      if (this.labelValues.length <= 0) {
        if (this.queryType.includes("filter")) {
          this.queryType = this.queryType.filter((item) => item !== "filter");
          console.log("This all removed from ", this.queryType);
          this.queryType;
        }
      }

      console.log("Querytype ", this.queryType);

      console.log("I am val", val);
      console.log("I am event", event);
      console.log("I am checkedval", this.checkedval);
      console.log("--------*-------*---------");
      console.log("Header data in filter :", this.eventValues);
      console.log("Checked data in filter :", this.labelValues);
      console.log("--------*-------*---------");


     

      // this.labelValues.forEach((values, e) => {
      //   console.log("for each :", e);
      //   console.log("for each val :", values.value.value);
      //   console.log("Value :", filtername);
      // });

      // console.log("Value from labelValues array :",this.labelValues[1].value);
  // {
  //      "category.name":{"$exists":false}
  //   },
  // "$or":[
  //           {
  //               "category.name":{"$exists":false}
  //           },
  //           {
  //           "category.name":{"$in":["Cloud"]}
  //           }
  //       ]  

      if (filtername in this.filterQueryFormat.filterQuery) {

        console.log("I am already exits");
        console.log( val.value.value,"Val Value")
        if (this.checkedval === true) {
          this.filterQueryFormat.filterQuery[filtername]["$in"].push(
            val.value.value
          );
          console.log(this.filterQueryFormat, " FilterQueryformat");
        }
        // if (this.checkedval === true &&  val.value.value.toLowerCase()==="others") {
        //   this.filterQueryFormat.filterQuery[filtername]["$in"].push("");
        //   console.log(this.filterQueryFormat, " FilterQueryformat");
        // }

        
      } else {
        console.log("I am not exits");
        if (this.checkedval === true) {
          this.filterQueryFormat.filterQuery[filtername] = {
            $in: [val.value.value],
          };
        }
        // if(this.checkedval === true &&  val.value.value.toLowerCase()==="others"){
        //   this.filterQueryFormat.filterQuery[filtername] = {
        //     $in: [""],
        //   };
        //   }


        console.log("Filter name:", filtername);
        console.log(
          "Keys in filterQuery:",
          Object.keys(this.filterQueryFormat.filterQuery)
        );
      }
       this.queryTypeMethod(this.queryType)
       this.$emit("searched-value", this.filterQueryFormat);
      console.log("I am last query :", this.filterQueryFormat);
    },
    isCheckedInFilter(val, filtername) {
      if (filtername in this.filterQueryFormat.filterQuery) {
        let filterArray = this.filterQueryFormat.filterQuery[filtername]["$in"];
        console.log(filterArray, " filteraray");

        console.log("Index in fqf :", filterArray.indexOf(val.value.value));

        //  filterArray=filterArray.filter(item=>item!==val.value.value)
        //  console.log("NEW filter array :",filterArray);
        const indexToRemove = filterArray.indexOf(val.value.value);
        if (indexToRemove !== -1) {
          filterArray.splice(indexToRemove, 1);
          if (filterArray.length === 0) {
            delete this.filterQueryFormat.filterQuery[filtername];
          }
        }

        console.log("i am filtequery ", val.value.value);

        console.log("i am filtequery", this.filterQueryFormat);
      }
    },

    isThisChecked(value) {
      return this.labelValues.includes(value);
    },
    viewDropDown(e) {
      console.log(e.target);
      this.isActiveFilter = false;
      this.isFilter = false;
      this.isTag=false
      console.log("from view drop down");
      // this.isSearch = !this.isSearch;
      if (!this.isSearch) {
        this.isSearch = true;
        document.addEventListener("click", this.isSearchOutside);
      }
    },
    searchedCheckBox(searchedValue) {

      console.log("I am searchedValaue :",searchedValue.trim().length>0);

      if (searchedValue.trim().length > 0) {
        if (this.queryType.includes("search")) {
          this.queryType;
        } else {
          this.queryType.push("search");
        }
      }
      if (searchedValue.trim().length <= 0) {
        if (this.queryType.includes("search")) {
          this.queryType = this.queryType.filter((item) => item !== "search");
          console.log("This all removed from ", this.queryType);
          this.queryType;
        }
      }
      console.log("Querytype ", this.queryType);


  this.filterQueryFormat.searchQuery=searchedValue

 this.queryTypeMethod(this.queryType)

      console.log("searched-value",this.filterQueryFormat);
      this.$emit("searched-value", this.filterQueryFormat);
      return this.filterQueryFormat;
    },
    checked(value) {
      //  this.receivedOptions=value;
      //   console.log("Checked :",value);
      console.log("Checked value top :", value);
      // this.checkedByUsers = [];
      value.forEach((e) => {
        if (e.name) {
          console.log("Name :", e.name);
        }
      });

      value.forEach((element) => {
    console.log("hi");
    if (element.checked === true && !this.checkedByUsers.some(e => e.name === element.name)) {
      this.checkedByUsers.push(element);
    } else if (element.checked === false && this.checkedByUsers.some(e => e.name === element.name)) {
      // let index = this.checkedByUsers.findIndex(e => e.name === element.name);
      // this.checkedByUsers.splice(index, 1);
      this.checkedByUsers=this.checkedByUsers.filter(e=>e.name!==element.name)
      console.log("I am unchecked ", element);
    }
    console.log("I am checked :", this.checkedByUsers);
  });
    },
    showFilter() {
      console.log("From showFilter");
      this.isActiveFilter = !this.isActiveFilter;
      // this.isFilter = !this.isFilter;
      this.isSearch = false;
      // this.isTag=true
      
      this.isTag = this.checkedByUsers.length > 0;
      if(!this.isFilter){
        this.isFilter = true;
        document.addEventListener("click",this.isFilterOutside)
      }
   
    },
    closeDropDown() {
      this.isSearch = false;
      // this.isTag=true
      this.isTag = this.checkedByUsers.length > 0;
      this.$refs.inputBox.blur();
    },

    isSearchOutside(event) {
      // In this function used refs and contains
      //   if (!this.$refs.dropdown || !this.$refs.inputBox) return;
      if (
        this.$refs.dropdown &&
        this.$refs.inputBox &&
        this.$refs.activefilter &&
        !this.$refs.dropdown.contains(event.target) &&
        !this.$refs.activefilter.contains(event.target) &&
        event.target !== this.$refs.inputBox &&
        event.target !== this.$refs.filter &&
        event.target !== this.$refs.activefilter
      ) {

        const isClickOnTag = event.target.classList.contains("active-filter-tag");
    const isClickOnCloseIcon = event.target.classList.contains("icon-times-regular");

    if (!isClickOnTag && !isClickOnCloseIcon) {
      this.isSearch = false;
      // this.isTag=true
      this.isTag = this.checkedByUsers.length > 0;
      document.removeEventListener("click", this.isSearchOutside);
    }
    
      }
    },
    isFilterOutside(event) {
      console.log("Event is :",event);
  // Check if the click event is outside of the filter area
  if (
    this.$refs.filter &&
    !this.$refs.filter.contains(event.target) &&
    !event.target.classList.contains("filter-logo")
  ) {
    // Toggle off the filter if clicked outside
    this.isFilter = false;
    this.isActiveFilter=false;
    // this.isTag=true
    this.isTag = this.checkedByUsers.length > 0;
    // Remove the event listener once the filter is closed
    document.removeEventListener("click", this.isFilterOutside);
  }
}
  },
};
</script>
