<template>
<div style="width:100px">
  <HlxMinMaxSlider
  v-model:min-value="sliderMin"
  v-model:max-value="sliderMax"
      :min="0"
      :max="50000"
      @slidervalue="sliderval"
    />
</div>
</template>
<script>
import HlxMinMaxSlider from '@/components/MinMaxRangeSliderComponent.vue';
export default{
    name:'MinMaxView',
    components:{
        HlxMinMaxSlider
    },
    data(){
        return{
            sliderMin:1000,
            sliderMax:14000
        }
    },
    methods:{
        sliderval(val1,val2)
        {
            val1
            val2
            // console.log(val1,val2,'aaa');
        }
    }
}
</script>