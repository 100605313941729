<template>
  <h1 class="component-heading">Accessibility</h1>
  <h3>Our Goal</h3>
  <p style="line-height: 3; font-size: 14px; margin-top: 10px">
    We want everyone who visits the Halleyx website and uses our platform to
    feel welcome and find the experience rewarding<br />
  </p>
  <ul>
    <li style="font-size: 14px; line-height: 1.8; margin-left: 20px">
      To help us ensure a positive experience for everyone, we've been using the
      Web Content Accessibility Guidelines (WCAG) 2.1
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1; margin-left: 20px">
      These guidelines explain how to make web content more accessible for
      people with disabilities, and user-friendly for everyone
    </li>
    <br />

    <li style="font-size: 14px; line-height: 1.8; margin-left: 20px">
      The guidelines have three levels of accessibility (A, AA, and AAA) We've
      chosen Level AA as the target for the Halleyx website and platform
    </li>
    <br />

    <li style="font-size: 14px; line-height: 1.8; margin-left: 20px">
      By ensuring a Level AA accessibility conformance, we allow for assistive
      technology such as screen readers, braille displays, plugins, and browser
      extensions to add further functionality needed by the individual user
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1; margin-left: 20px">
      Halleyx website is considered to be WCAG 2.1 Level AA compliant
    </li>
    <br />
  </ul>
  <b style="margin-bottom: 10px">What are we doing?</b><br />

  <p style="line-height: 3; font-size: 14px; margin-bottom: 10px">
    At Halleyx, we are committed to ensuring a good experience for all users,
    and we have taken several measures to achieve this:<br />
  </p>
  <ul>
    <li
      style="
        font-size: 14px;
        line-height: 1;
        margin-left: 20px;
        margin-top: 10px;
      "
    >
      We have stated our commitment to accessibility in internal employee
      policies
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1; margin-left: 20px">
      We offer accessibility training to all employees
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1.8; margin-left: 20px">
      We have developed an internal design system of reusable components that
      have been holistically tested for accessibility
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1; margin-left: 20px">
      We use our own automated accessibility checks for testing everything we
      build
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1; margin-left: 20px">
      We have accessibility professionals conduct manual audits of all new
      features released
    </li>
    <br />
    <li style="font-size: 14px; line-height: 1.6; margin-left: 20px">
      We give out a monthly Accessibility Award to internal teams that have made
      a special effort to ensure our products are inclusive; this improves our
      own culture around building accessible products
    </li>
    <br />
  </ul>
  <p style="font-size: 14px; line-height: 2.6">
    Upon request, Halleyx will arrange for the provision of accessible formats
    and communication supports for persons with disabilities in a timely manner
    that takes into account each person's accessibility needs due to
    disability<br />
  </p>

  <p style="font-size: 14px; line-height: 2.6">
    <b>We would love your feedback</b><br />
  </p>

  <p style="font-size: 14px; line-height: 2.6">
    If you enjoyed using the Halleyx website or our products, or if you
    encountered trouble with any parts of it, please let us know While we're
    working hard to fully achieve and maintain our goal of Level AA
    accessibility, we realize there are always opportunities to improve You can
    contact us in to discuss further<br />
  </p>
  <ul>
    <li style="font-size: 14px; line-height: 2.6; margin-left: 40px">
      Email us at <b>**contact@halleyx.com**</b><br />
    </li>
  </ul>
  <p style="font-size: 14px; line-height: 2.6">
    <b>The Halleyx Accessibility Pledge</b><br />
  </p>

  <p style="font-size: 14px; line-height: 2.6">
    "We champion global inclusion by empowering people to do more through
    digital accessibility, which is at the heart of our company.We are committed
    to ensuring that digital solutions and content developed, or acquired, by
    Halleyx meet a high level of accessibility This means either conformance
    with level AA of the Web Content Accessibility Guidelines (WCAG) 2.1 or
    ensuring that the solutions are effective, efficient, engaging,
    error-tolerant, and easy to learn for users of all abilities We pledge that
    should a conflict arise between release deadlines, aesthetics, and the
    production of accessible solutions and content that accessibility will
    remain a priority"
  </p>
  <hlx-divider :space="'20px'" class="mobile-nav-divider"/>
    <div class="mobile-next-page-nav">
      <span class="prev-btn" @click="prevNextPage('prev', 'quickstart')"
      ><i class="icon-angle-left-regular prev-icon"></i>Quickstart</span
    >
      <span class="nxt-btn" @click="prevNextPage('next', 'custom_theming')"
        >Custom theming <i class="icon-angle-right-regular"></i
      ></span>
    </div>
</template>

<script>
import hlxDivider from "../components/DividerComponent.vue";
export default {
  name: 'AccessibilityPage',
  components: { hlxDivider },
  methods: {
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
  }
};
</script>

<style></style>
