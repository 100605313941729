<template>
  <div class="swatch_delete">
    <hlx-modal
      :modal-active="modal_active_delete"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      :close-icon="false"
      :outer-layer-close-option="true"
    >
      <template #header>
        <div>Delete color</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div style="margin-left: 11px">
            Are you sure you want to delete
            <b
              >{{ payload?.name }}
              {{ payload?.hex ? payload?.hex.toUpperCase() : "" }}
            </b>

            ?
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button
          class="secondary-success sm"
          @click="modal_active_delete = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="deleteswatch(payload._id)"
          >Delete</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <div class="swatch_select">
    <hlxselect
      :key="selectcomponentkey"
      v-model="searchSelect"
      :slot-options="true"
      :options="dropdown_data1"
      :placeholder-value="'Search color or Hex code'"
      :label="'name'"
      :prop-value="'hex'"
      :style="'css'"
      :swatch="true"
      :label-animation="false"
      :inline-search="true"
      :footer="footerselect"
      :footer-icon="'icon-plus-circle-regular'"
      :footer-content="'Add new colour'"
      @dropdownopen="dropdownopen"
      @dropdownclose="dropdownclose"
      @selected-value="swatchSelectOption"
      @footer-click="fotervalue"
    >
      <template #displayValue="value">
        <span style="display: flex; gap: 10px; align-items: center">
          <span
            :style="{
              height: '20px',
              width: '20px',
              borderRadius: '20px',
              backgroundColor: value.displayLabel.hex,
              marginLeft: '4px',
            }"
          ></span>
          <span>{{ value["displayLabel"]["name"] }}</span>
          <span style="color: #757575; font-size: 12px">
            {{
              value["displayLabel"]["hex"]
                ? value["displayLabel"]["hex"].toUpperCase()
                : ""
            }}
          </span>
        </span>
      </template>
      <template #options="option">
        <div style="display: flex; align-items: center; width: 100%">
          <div
            style="
              padding-left: 16px;
              display: flex;
              gap: 10px;
              align-items: center;
              width: 99%;
            "
          >
            <div
              :style="{
                height: '20px',
                width: '20px',
                borderRadius: '20px',
                backgroundColor: option.optionsData.hex,
                marginLeft: '4px',
              }"
            ></div>
            <div style="font-size: 14px">
              {{ option["optionsData"]["name"] }}
            </div>
            <div style="color: #757575; font-size: 12px">
              {{
                option["optionsData"]["hex"]
                  ? option["optionsData"]["hex"].toUpperCase()
                  : ""
              }}
            </div>
          </div>
          <div class="Swatch_edit_delete">
            <div>
              <i
                class="icon-edit-filled"
                style="color: #0065b2"
                @click="editOpen(option.optionsData, index)"
              ></i>
            </div>

            <div>
              <i
                class="icon-trash-regular"
                style="color: #dd2025"
                @click.stop="deleteNoteModal(option.optionsData)"
              ></i>
            </div>
          </div>
        </div>
      </template>
    </hlxselect>
  </div>

  <hlx-native-color-picker
    :show-color-picker="showcolor"
    :colorNameprop="colorprop"
    :edit-payload="payload"
    :editmode="editMode"
    @updated-value="showoffColor"
    @edit-value="editColor"
  />
</template>

<script>
import hlxselect from "./SelectComponent.vue";
import HlxNativeColorPicker from "./nativeColorPickerComponent.vue";
import hlxModal from "../components/ModalwindowComponent.vue";
import HlxButton from "../components/ButtonComponent.vue";

// import axios from "axios";

export default {
  name: "HlxDropdownColorPicker",
  components: {
    HlxButton,
    hlxModal,
    hlxselect,
    HlxNativeColorPicker,
  },
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          key: "",
          postUrl: "",
          getUrl: "",
          editUrl: "",
        };
      },
    },
options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    prevalue: {
      type: String,
      default: "",
    },
  },
  emits: ["updated-value", 'searchApi', 'searchalldata', 'editColor', 'showoffColor', 'deleteswatch'],
  data() {
    return {
      selectcomponentkey: 0,
      editMode: false,
      modal_active_delete: false,
      showcolor: false,
      searchSelectcolor: "",
      colorprop: "",
      footerselect: false,
      searchSelect: "",
      searchvalue: "",
      dropdown_data1: [],
      payload: {},
    };
  },

  watch: {
    searchSelect: {
      handler(val) {
        // console.log(val, val.length);
        if (val?.length > 0) {
          let hashData = val.includes("#");
          let hexapi = hashData ? val.substring(1) : val;
          this.searchApi(hexapi);
        } else {
          this.searchalldata();
        }
      },
      immediate: true,
      deep: true,
    },
    options:{
       handler(val) {
        this.dropdown_data1=val
      },
      immediate: true,
      deep: true,
    },
    dropdown_data1: {
      handler(val) {
        console.log(val.length,"gbfvdcs");
        this.footerselect = Array.isArray(val) && val.length === 0;
      },
      immediate: true,
      deep: true,
    },
    prevalue: {
      handler(val) {
        this.searchSelect = val;
      },
      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    this.searchalldata();
    
  },
  methods: {
    dropdownopen(val) {
      if (val === "dropdownopen") {
        this.searchSelect = "";
        console.log("dropdownopen");
      }
    },
    // dropdownclose(val) {
    //   this.selectcomponentkey += 1;
    //   console.log("dropdownclose");

    //   if (val === "dropdownclose" && this.searchvalue !='') {

    //     console.log(this.searchvalue,"this.searchvalue");
    //    this.searchSelect = this.searchvalue;

    //   }
    // },
    async editOpen(val) {
      console.log(val);
      this.showcolor = true;
      this.payload = val;
      this.editMode = true;
    },
    async deleteswatch(val) {
      this.searchSelect = "";
      this.modal_active_delete = false;
      console.log(val,"deleteswatch");
    this.$emit('deleteswatch',val)
      this.searchalldata();
    },
    deleteNoteModal(val) {
      this.modal_active_delete = true;
      this.payload = val;
      console.log(val, "deleteNoteModal");
    },
    swatchSelectOption(val) {
      this.dropdown_data1.push(val);
      console.log(this.dropdown_data1);
      
      this.searchSelect = "";
      this.$emit("updated-value", val);
      this.payload = val;
    },
    async editColor(val, updated, obect, namecolor, id) {
      console.log(obect, "objectedit");
      if (updated === "edit") {
        this.selectcomponentkey += 1;
        this.showcolor = val;
        this.editMode = val;
        try {
        
          this.$emit("editColor",id,obect,namecolor)
          this.searchSelect = namecolor;
          this.searchalldata();
          // Do something with the response 'spec' here
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        console.log(val, obect, "hgfds");
      }
    },
    async showoffColor(val, updated, obect, namecolor, id) {
      console.log(val, updated, obect, namecolor, id, this.editMode, "create");

      if (updated === "updated") {
        this.selectcomponentkey += 1;
        this.showcolor = val;
        this.editMode = val;
        try {
        this.$emit("showoffColor",obect,namecolor,val)
          this.searchSelect = namecolor;
          this.searchvalue = namecolor;
          console.log(this.searchvalue, "this.searchvalue");
          this.searchalldata(namecolor);
          

          // Do something with the response 'spec' here
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        console.log(val, obect, "hgfds");
      }

      console.log(this.searchSelectcolor);
      if (updated === "close") {
        this.selectcomponentkey += 1;
        this.showcolor = val;
        this.editMode = val;
      }
    },

    fotervalue(val) {
      const propsname = this.searchSelect;
      this.selectcomponentkey += 1;
      this.searchSelect = "";
      this.colorprop = propsname;
      this.showcolor = true;
      console.log(propsname,val, "fotervalue");
    },
    async searchApi(val) {
      console.log(val);
      if (val != undefined) {
       this.$emit('searchApi',val)
      }
    },
    async searchalldata() {
      // console.log(val);
      this.$emit('searchalldata')
    },
  },
};
</script>

<style lang="scss">
.swatch_delete {
  z-index: 100000000;
}
.swatch_select {
  .outer-overlay {
    .container-overlay {
      .custom-dropdown-whole-container {
        .customdropdown-option-container {
          .option {
            .Swatch_edit_delete {
              display: none;
            }
          }
          .option:hover .Swatch_edit_delete {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
